import Bugsnag from "@bugsnag/js";
import ENV from "glesys-controlpanel/config/environment";

export function initialize() {
  if (ENV.environment === "production") {
    Bugsnag.start(ENV.bugsnag);
  }
}

export default {
  name: "bugsnag",
  initialize,
};
