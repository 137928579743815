import ApplicationAdapter from "./application";

export default class VpnUserAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return `${this.baseUri()}/vpn/createuser`;
  }

  urlForUpdateRecord() {
    return `${this.baseUri()}/vpn/edituser`;
  }

  urlForDeleteRecord(id, _, snapshot) {
    return `${this.baseUri()}/vpn/deleteuser?username=${id}&projectkey=${snapshot.belongsTo("project").id}`;
  }
}
