import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class OrganizationsCreateController extends Controller {
  @service router;
  @service store;

  get countries() {
    return this.model.countries;
  }

  get sortedCountries() {
    return this.countries.sort((a, b) => a.name.localeCompare(b.name));
  }

  get canSubmitForm() {
    return !(!!this.selectedOrganizationName && !!this.selectedCountry?.code && !!this.selectedOrganizationType);
  }

  @tracked selectedOrganizationName = null;
  @tracked selectedOrganizationType = null;
  @tracked selectedCountry = null;

  constructor() {
    super(...arguments);
    this.setDefaults();
  }

  setDefaults() {
    this.selectedOrganizationName = "";
    this.selectedOrganizationType = "";
    this.selectedCountry = null;
  }

  onChange = ({ selectedOrganizationName }) => (this.selectedOrganizationName = selectedOrganizationName);

  createOrganizationTask = task(async () => {
    let organization = this.store.createRecord("organization", {
      name: this.selectedOrganizationName,
      country: this.selectedCountry.code,
      type: this.selectedOrganizationType,
    });

    await organization.save().catch((error) => {
      organization.unloadRecord();
      throw error;
    });

    this.setDefaults();

    this.router.transitionTo("organization", organization.get("id"));
  });

  @action
  countryChanged(country) {
    this.selectedCountry = country;
  }

  @action
  typeChanged(option) {
    this.selectedOrganizationType = option;
  }
}
