import ApplicationSerializer from "./application";

export default class DnsRecordSerializer extends ApplicationSerializer {
  usesProxy = true;

  primaryKey = "recordid";

  attrs = {
    rdata: { key: "data" },
  };
}
