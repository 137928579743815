import { modifier } from "ember-modifier";
import { twMerge } from "tailwind-merge";

const autoTrack = (args: [unknown?]) => args.forEach(() => {});

const mergeClasses = modifier((element: HTMLElement | SVGElement, args: [unknown?]) => {
  autoTrack(args);
  element.setAttribute("class", twMerge(element.getAttribute("class")));
});

export default mergeClasses;

declare module "@glint/environment-ember-loose/registry" {
  export default interface Registry {
    "merge-classes": typeof mergeClasses;
  }
}
