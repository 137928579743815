/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { createPopper } from "@popperjs/core";
import { action } from "@ember/object";
import { arg } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { bool, oneOf } from "prop-types";
import { tracked } from "@glimmer/tracking";
import { restartableTask, timeout } from "ember-concurrency";
import styles from "./styles.css";
import { modifier } from "ember-modifier";

export default class Tooltip extends Component {
  @arg(oneOf[("right", "left")]) placement = "right";

  @arg(bool) debounce = false;

  @arg(stringable) attachTo;

  styles = styles;

  attachToElement;
  @tracked isVisible = false;
  tooltip;
  popperInstance;

  get arrowPlacement() {
    return this.placement === "right" ? this.styles.arrowRight : this.styles.arrowLeft;
  }

  @action
  handleMouseEnter() {
    this.popperInstance = createPopper(this.attachToElement, this.tooltip, {
      placement: this.placement,
      strategy: "fixed",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [-16, 10],
          },
        },
      ],
    });

    this.debounceVisible.perform();
  }

  @action
  handleMouseLeave() {
    this.debounceVisible.cancelAll();
    this.isVisible = false;
    this.popperInstance.destroy();
  }

  registerListeners = modifier((tooltip) => {
    this.tooltip = tooltip;

    this.attachToElement = this.attachTo ? document.querySelector(this.attachTo) : tooltip.parentElement;
    this.attachToElement.addEventListener("mouseenter", this.handleMouseEnter);
    this.attachToElement.addEventListener("mouseleave", this.handleMouseLeave);

    return () => {
      this.attachToElement.removeEventListener("mouseenter", this.handleMouseEnter);
      this.attachToElement.removeEventListener("mouseleave", this.handleMouseLeave);
    };
  });

  debounceVisible = restartableTask(async () => {
    if (this.debounce) {
      await timeout(700);
    }
    this.isVisible = true;
  });
}
