import Model, { attr, hasMany } from "@ember-data/model";

export default class UserModel extends Model {
  @attr username;
  @attr("string-trim") name;
  @attr twoFactorMethod;
  @attr createdAt;
  @attr("yesno") superuser;

  @hasMany("organization", { async: true, inverse: "user" }) organizations;

  get defaultServerUsername() {
    return this.isIndividual ? this.username.split(/[^\w]/, 1)[0] : this.username;
  }

  get isIndividual() {
    return `${this.username}`.indexOf("@") !== -1;
  }

  get hasTwoFactorMethod() {
    return this.twoFactorMethod !== "none";
  }

  get isSuperUser() {
    return this.superuser;
  }
}
