import Route from "@ember/routing/route";
import { service } from "@ember/service";
import { task } from "ember-concurrency";
import { NotFoundError } from "glesys-controlpanel/utils/errors";

export default class ShipmentsRoute extends Route {
  @service store;
  @service abilities;

  model() {
    return {
      createShipmentTask: this.createShipmentTask,
    };
  }

  beforeModel() {
    let { organization } = this.modelFor("organization");

    if (this.abilities.cannot("access shipments for organization", organization)) {
      throw new NotFoundError();
    }
  }

  createShipmentTask = task(async (dataCenter, trackingId) => {
    let { project } = this.modelFor("organization");
    let shipment = this.store.createRecord("shipment", {
      project,
      datacenter: dataCenter.id,
      trackingid: trackingId,
    });

    await shipment.save();

    return true;
  });
}
