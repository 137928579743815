import ApplicationAdapter from "./application";

export default class ProjectAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return `${this.baseUri()}/customer/createproject`;
  }

  urlForUpdateRecord() {
    return `${this.baseUri()}/project/edit`;
  }

  urlForDeleteRecord(id) {
    return `${this.baseUri()}/project/delete?projectkey=${id}`;
  }
}
