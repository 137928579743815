import Model, { attr, belongsTo } from "@ember-data/model";

export default class DnsRecordModel extends Model {
  getFromRdata(index) {
    return this.rdata.split(/\s/).splice(index, 1)[0];
  }

  setRdata(value, index) {
    let formattedValue = typeof value === "string" ? value.trim().split(/\s/).splice(0, 1) : value;

    let parts = this.rdata.split(/\s/);
    parts[index] = formattedValue;

    this.rdata = parts.join(" ");
  }

  @attr("string") host;
  @attr("string") type;
  @attr("string-trim", { defaultValue: "" }) rdata;
  @attr("number", { defaultValue: 3600 }) ttl;

  @belongsTo("dns-domain", { async: false, inverse: "records" }) domain;

  // MX records => {0:preference} {1:exchange}
  get preference() {
    return this.getFromRdata(0);
  }

  set preference(value) {
    this.setRdata(value, 0);
  }

  get exchange() {
    return this.getFromRdata(1);
  }

  set exchange(value) {
    this.setRdata(value, 1);
  }

  // SRV records => {0:priority} {1:weight} {2:port} {3:target}
  get priority() {
    return this.getFromRdata(0);
  }

  set priority(value) {
    this.setRdata(value, 0);
  }

  get weight() {
    return this.getFromRdata(1);
  }

  set weight(value) {
    this.setRdata(value, 1);
  }

  get port() {
    return this.getFromRdata(2);
  }

  set port(value) {
    this.setRdata(value, 2);
  }

  get target() {
    return this.getFromRdata(3);
  }

  set target(value) {
    this.setRdata(value, 3);
  }

  get isNotNew() {
    return !this.isNew;
  }
}
