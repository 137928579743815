import { template as template_940828830d8a412ea4074b79f9671d2b } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import TEMPLATES from "./templates";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { func } from "prop-types";
import Label from "glesys-controlpanel/components/label";
import Box from "glesys-controlpanel/components/box";
import SelectMenu from "glesys-controlpanel/components/select/menu";
import canManage from "glesys-controlpanel/helpers/can-manage";
import { and, eq } from "ember-truth-helpers";
import { t } from "ember-intl";
export default @forbidExtraArgs
class DnsGroup extends Component {
    @arg(stringable.isRequired)
    type;
    @arg(func.isRequired)
    onTemplate;
    get templates() {
        return TEMPLATES[this.type] || [];
    }
    @action
    template(template) {
        this.onTemplate(template);
    }
    get hasTemplates() {
        return Boolean(this.templates.length);
    }
    static{
        template_940828830d8a412ea4074b79f9671d2b(`
    <Box @showFooter={{and (canManage) this.hasTemplates}} ...attributes>
      <:default>
        <h4 class="flex justify-center leading-normal text-gray-darker" data-test-dns-group-type>
          {{this.type}}
        </h4>

        <table class="min-w-full text-left text-base mt-4 mb-2">
          <thead>
            <tr>
              <th></th>
              <th>
                <Label>
                  {{t "label.host"}}
                </Label>
              </th>
              <th>
                <Label>
                  {{! template-lint-disable no-bare-strings }}
                  TTL
                </Label>
              </th>
              {{#if (eq this.type "MX")}}
                <th>
                  <Label>
                    {{t "label.prio"}}
                  </Label>
                </th>
              {{/if}}
              {{#if (eq this.type "SRV")}}
                <th>
                  <Label>
                    {{t "label.prio"}}
                  </Label>
                </th>
                <th>
                  <Label>
                    {{t "label.weight"}}
                  </Label>
                </th>
                <th>
                  <Label>
                    {{t "label.port"}}
                  </Label>
                </th>
                <th>
                  <Label>
                    {{t "label.target"}}
                  </Label>
                </th>
              {{else}}
                <th>
                  <Label>
                    {{t "label.data"}}
                  </Label>
                </th>
              {{/if}}
              <th></th>
            </tr>
          </thead>

          <tbody>
            {{yield}}
          </tbody>
        </table>
      </:default>

      <:footer>
        {{#if (and (canManage) this.hasTemplates)}}
          <SelectMenu
            @onChange={{this.template}}
            class="bg-white border border-gray-light hover:border-gray hover:bg-gray-lightest px-4 py-2 rounded text-gray-darker hover:text-gray-dark font-sans"
            data-test-dns-group-template
            as |Menu|
          >
            <Menu.Header class="text-sm">
              {{t "button.templates"}}
            </Menu.Header>
            <Menu.Dropdown @items={{this.templates}} @key="label" @prefersPosition="right" class="-mx-4" />
          </SelectMenu>
        {{/if}}
      </:footer>
    </Box>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
