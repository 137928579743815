/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { arg } from "ember-arg-types";
import { arrayOf, number, shape } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { htmlSafe } from "@ember/template";

export default class EntityTable extends Component {
  @arg(
    arrayOf(
      shape({
        header: stringable.isRequired,
        value: stringable,
        subValue: stringable,
        billing: shape({
          currency: stringable,
          price: number,
          total: number,
        }),
      }),
    ),
  )
  entity;

  @arg(stringable) minWidthForColumn = "100px";
  @arg(stringable) maxWidthForColumn = "1fr";

  get gridTemplateStyle() {
    let sizes = this.entity.map((row) => row.size ?? `minmax(${this.minWidthForColumn}, ${this.maxWidthForColumn})`);

    return htmlSafe(`grid-template-columns: ${sizes.join(" ")}`);
  }
}
