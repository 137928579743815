/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { action } from "@ember/object";
import Fuse from "fuse.js";
import groupBy from "glesys-controlpanel/utils/group-by";
import { tracked } from "@glimmer/tracking";
import { modifier } from "ember-modifier";
import { task } from "ember-concurrency";

export default class HeaderSearch extends Component {
  @service http;
  @service keyboard;
  @service intl;
  @service router;

  @tracked isOpen = false;
  @tracked data = [];
  @tracked query = "";
  @tracked selectedIndex = 0;

  get fuse() {
    return new Fuse(this.data, {
      distance: 1000,
      findAllMatches: true,
      keys: ["id", "name", "keywords"],
      location: 0,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      shouldSort: true,
      threshold: 0.1,
    });
  }

  @action
  open() {
    this.isOpen = true;
    setTimeout(() => this.searchInput?.focus(), 0);
  }

  @action
  close() {
    this.isOpen = false;
    this.query = "";
    this.searchInput.blur();
  }

  @action
  keydownOnSearchInput(event) {
    if (event.key === "Escape") {
      this.close();
      event.target.blur();
    }
  }

  @action
  keyDown(e) {
    let resultsLength = this.results?.length;
    let nextIndex = (this.selectedIndex + 1) % resultsLength;
    let previousIndex = this.selectedIndex == 0 ? resultsLength - 1 : (this.selectedIndex - 1) % resultsLength;

    // Enter
    if (e.keyCode === 13) {
      this.go();
    }

    // Escape
    if (e.keyCode === 27) {
      this.close();
    }

    // Up
    if (e.keyCode === 38) {
      this.selectedIndex = previousIndex;
    }

    // Down
    if (e.keyCode === 40) {
      this.selectedIndex = nextIndex;
    }
  }

  storeSearchInput = modifier((element) => (this.searchInput = element));

  registerListeners = modifier(() => {
    this.keyboard.listenFor({ key: "/", preventDefault: true }, () => {
      this.open();
    });

    return () => this.keyboard.stopListenFor({ key: "/" });
  });

  registerNavigationListeners = modifier(() => {
    document.addEventListener("keydown", this.keyDown);

    return () => document.removeEventListener("keydown", this.keyDown);
  });

  translationForGroup(group) {
    switch (group) {
      case "archive":
        return this.intl.t("component.header-search.group.archive");
      case "domain":
        return this.intl.t("component.header-search.group.domain");
      case "emailaccount":
        return this.intl.t("component.header-search.group.emailaccount");
      case "file-storage-volume":
        return this.intl.t("component.header-search.group.file-storage-volume");
      case "loadbalancer":
        return this.intl.t("component.header-search.group.loadbalancer");
      case "server":
        return this.intl.t("component.header-search.group.server");
      case "vpn":
        return this.intl.t("component.header-search.group.vpn");
      case "object-storage-instance":
        return this.intl.t("component.header-search.group.object-storage-instance");
      default:
        return "";
    }
  }

  get groupedResults() {
    return this.groupedByType
      .map((result) => ({
        items: result.items.sort((a, b) => a.name?.localeCompare(b.name)),
        translatedGroupName: this.translationForGroup(result.value),
        ...result,
      }))
      .sort((a, b) => a.translatedGroupName.toString().localeCompare(b.translatedGroupName.toString()));
  }

  get groupedByType() {
    return groupBy(this.results, "type");
  }

  get results() {
    let fuse = this.fuse;
    let query = this.query;
    let topFiveWithBestScore = fuse
      .search(query)
      .slice(0, 5)
      .map((x) => x.item);
    return topFiveWithBestScore;
  }

  get selectedResult() {
    let results = this.groupedResults ? this.groupedResults.map((x) => x.items).flat() : this.results;
    let selectedIndex = this.selectedIndex;

    if (results.length > selectedIndex) {
      return results.at(selectedIndex);
    }

    return results.at(0);
  }

  @action
  go() {
    let selectedResult = this.selectedResult;
    if (selectedResult) {
      this.router.transitionTo(selectedResult.route, ...selectedResult.models, {
        queryParams: {
          project: selectedResult.meta.project,
        },
      });
      this.close();
    }
  }

  @action
  search(event) {
    this.selectedIndex = 0;
    this.query = event.target.value;
  }

  loadData = task(async () => {
    let response = await this.http.request({ url: "/user/searchdata" });

    this.data = response.content.search
      .map((item) => {
        let route, models, name, description;
        switch (item.type) {
          case "archive":
            route = "archive";
            models = [item.meta.organization, item.id];
            name = item.id;
            break;
          case "domain":
            route = "dns.domain";
            models = [item.meta.organization, item.id];
            name = item.name;
            break;
          case "emailaccount":
            route = "email.domain.account";
            models = [item.meta.organization, item.meta.domain, item.id];
            name = item.name;
            break;
          case "file-storage-volume":
            route = "file-storage-volumes.details";
            models = [item.meta.organization, item.id];
            name = item.name;
            break;
          case "object-storage-instance":
            route = "object-storage";
            models = [item.meta.organization];
            name = item.id;
            description = item.name;
            break;
          case "loadbalancer":
            route = "load-balancer";
            models = [item.meta.organization, item.id];
            name = item.name;
            description = item.id;
            break;
          case "server":
            route = "server";
            models = [item.meta.organization, item.id];
            name = item.name;
            description = item.id;
            break;
          case "vpn":
            route = "vpn";
            models = [item.meta.organization];
            name = item.id;
            break;
          default:
            // Not supported right now
            return null;
        }

        return { ...item, route, models, name, description };
      })
      .filter(Boolean);
  });
}
