import { template as template_07668d1ea5fd4c6da940055e2db0c430 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool } from "prop-types";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsLoadingSpinner from "glesys-controlpanel/components/icons/loading-spinner";
import Tour from "glesys-controlpanel/components/tour";
export default @forbidExtraArgs
class DashboardContent extends Component {
    @service
    modal;
    @service
    tour;
    @arg(bool)
    isLoading = false;
    static{
        template_07668d1ea5fd4c6da940055e2db0c430(`
    <div class="relative bg-gray-lightest" {{mergeClasses}} ...attributes>
      {{#if this.isLoading}}
        <div class="absolute z-50 top-0 left-0 right-0 bottom-0 bg-gray-lightest opacity-60"></div>
        <div class="absolute z-50 top-0 left-0 right-0 bottom-0">
          <div class="flex h-screen mt-[-144px] justify-center">
            <div class="flex items-center -mt-16 -ml-16 mr-12">
              <div class="p-2 rounded-full bg-gray-lighter">
                <IconsLoadingSpinner class="w-8 text-center text-gray-dark" />
              </div>
            </div>
          </div>
        </div>
      {{/if}}

      {{yield}}

      {{#if this.tour.isOpen}}
        <Tour />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
