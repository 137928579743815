import ApplicationAdapter from "./application";

export default class EmailDomainAdapter extends ApplicationAdapter {
  buildURL(modelName, id, snapshot, requestType, query) {
    switch (requestType) {
      case "query":
        return this.urlForQuery(query, modelName);
      case "queryRecord":
        return this.urlForQueryRecord(query, modelName);
    }
    return super.buildURL(modelName, id, snapshot, requestType, query);
  }

  urlForQuery(query) {
    return `${this.baseUri()}/email/overview?context=${query.project}`;
  }

  fetchMailServer(model) {
    let url = `${this.baseUri()}/email/mailserver?domainname=${model.domainname}`;

    return this.ajax(url, "GET")
      .then((emailDomain) => {
        return this.store.pushPayload(model.constructor.modelName, {
          emailDomain,
        });
      })
      .catch(() => {});
  }
}
