import { task } from "ember-concurrency";
import ApplicationAdapter, { projectChildMixin } from "./application";

export default class ServerAdapter extends ApplicationAdapter.extend(projectChildMixin) {
  uriSuffix = "vps/servers";

  changeRootPassword(server, rootpassword) {
    let baseUri = this._baseUri(server);
    let url = `${baseUri}/rootpassword`;

    return this.ajax(url, "POST", {
      data: { rootpassword },
    });
  }

  clone(server, data) {
    let baseUri = this._baseUri(server);
    let url = `${baseUri}/clone`;

    return this.ajax(url, "POST", { data }).then((server) => {
      return this.store.pushPayload("server", { server });
    });
  }

  console(server) {
    let url = `/io.php/server/console?serverid=${server.id}`;

    return this.ajax(url, "GET");
  }

  fetchStatusTask = task(async (server) => {
    let url = `${this.baseUri()}/server/status?serverid=${server.id}`;

    await this.ajax(url, "GET").then((data) => {
      if (data) {
        try {
          this.store.push(this.store.normalize("server-status", { server: server.id, ...data.server }));
        } catch (e) {
          if (
            `${e.message}`.match(/Attempted to handle event `pushedData` on .* while in state root.deleted.inFlight./)
          ) {
            // The record may be `inFlight` when we try to push data. If that's the case
            // we will not be able to update the record.
            return;
          }
          throw e;
        }
      }
    });
  });

  fetchDetailsTask = task(async (server) => {
    let baseUri = this._baseUri(server);
    let url = `${baseUri}`;

    await this.ajax(url, "GET")
      .then((data) => {
        if (data) {
          try {
            this.store.push(this.store.normalize("server", data));
          } catch (e) {
            if (
              `${e.message}`.match(/Attempted to handle event `pushedData` on .* while in state root.deleted.inFlight./)
            ) {
              // The record may be `inFlight` when we try to push data. If that's the case
              // we will not be able to update the record.
              return;
            }
            throw e;
          }
        }
      })
      .catch((e) => {
        // Swallow the error if the server was just deleted.
        if (!server.get("isDeleted")) {
          throw e;
        }
      });
  });

  power(server, type) {
    let endpoint = "";
    let data = {};

    switch (type) {
      case "on":
        endpoint = "server/start";
        data = { bios: false };
        break;
      case "on-bios":
        endpoint = "server/start";
        data = { bios: true };
        break;
      case "reboot":
        endpoint = "server/stop";
        data = { type: "reboot" };
        break;
      case "off":
        endpoint = "server/stop";
        data = { type: "soft" };
        break;
      case "off-forced":
        endpoint = "server/stop";
        data = { type: "hard" };
        break;
      case "reset":
        endpoint = "server/reset";
        break;
    }

    return this.ajax(`${this.baseUri()}/${endpoint}`, "POST", {
      data: { serverid: server.id, ...data },
    });
  }

  buildURL(type, id, snapshot, requestType) {
    let url = super.buildURL(...arguments);
    if (requestType === "deleteRecord") {
      let keepReservedIps = snapshot?.adapterOptions?.keepReservedIps ?? true;
      url += `/${keepReservedIps}`;
    }
    return url;
  }
}
