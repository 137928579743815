/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { arrayOf, shape, string } from "prop-types";
import { modifier } from "ember-modifier";

@forbidExtraArgs
export default class extends Component {
  @service keyboard;
  @service router;

  @arg(
    arrayOf(
      shape({
        id: string,
        name: string,
        organization: shape({
          id: string,
        }),
      }).isRequired,
    ),
  )
  projects;

  @tracked visible = false;
  @tracked searchTerm = "";
  @tracked index = 0;

  get shouldShow() {
    return this.visible;
  }

  get isSearchingForOnlyProject() {
    return this.searchTerm.startsWith("is:project");
  }

  get isSearchingForOnlyOrganization() {
    return this.searchTerm.startsWith("is:organization");
  }

  get filteredProjects() {
    let filter = this.searchTerm.toLowerCase().replace("is:project", "").trim();

    return this.projects.filter((project) => {
      return project.name.toLowerCase().includes(filter);
    });
  }

  get organizationsFromProjects() {
    let organizations = this.projects.map((project) => project.organization);
    return [...new Set(organizations)];
  }

  get filteredOrganizations() {
    let filter = this.searchTerm.toLowerCase().replace("is:organization", "").trim();

    return this.organizationsFromProjects.filter((organization) => {
      return organization.name.toLowerCase().includes(filter);
    });
  }

  get selectedEntity() {
    if (this.index < this.filteredProjects.length) {
      return this.filteredProjects[this.index];
    }
    return this.filteredOrganizations[this.index - this.filteredProjects.length];
  }

  get noResults() {
    return this.filteredProjects.length == 0 && this.filteredOrganizations.length == 0;
  }

  onInputChange = (event) => (this.searchTerm = event.target.value);

  @action
  close() {
    this.visible = false;
  }

  @action
  handleClick(project) {
    this.goToURL(project);
  }

  @action
  setSearchTerm(searchTerm) {
    this.searchTerm = searchTerm;
    document.querySelector("[data-jumpto-input]").focus();
  }

  goToURL(entity) {
    entity = entity ?? this.selectedEntity;

    if (!entity) {
      return;
    }

    let isProject = entity.id.startsWith("cl");

    if (isProject) {
      if (entity.locked) {
        return;
      }

      let findJumpable = function (route) {
        if (route.metadata?.jumpable) {
          return route.name;
        }
        if (route.parent) {
          return findJumpable(route.parent);
        }
        return "organization";
      };

      let routeName = findJumpable(this.router.currentRoute);
      this.router.transitionTo(routeName, entity.organization.id, { queryParams: { project: entity.id } });
    } else {
      this.router.transitionTo("organization", entity.id);
    }

    this.visible = false;
    this.searchTerm = "";
  }

  @action
  keyDown(e) {
    let resultsLength = this.filteredProjects.length + this.filteredOrganizations.length;

    // Enter
    if (e.keyCode === 13) {
      this.goToURL();
    }

    // Up
    if (e.keyCode === 38) {
      if (this.index > 0) {
        this.index = this.index - 1;
      }
      return false;
    }

    // Down
    if (e.keyCode === 40) {
      if (this.index < resultsLength - 1) {
        this.index = this.index + 1;
      }
      return false;
    }

    this.index = 0;
  }

  constructor() {
    super(...arguments);
    this.keyboard.listenFor({ key: "p", preventDefault: true }, () => {
      this.visible = !this.visible;
    });
    this.keyboard.listenFor({ metaKey: true, code: "KeyK", preventDefault: true }, () => {
      this.visible = !this.visible;
    });
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.keyboard.stopListenFor({ key: "p" });
    this.keyboard.stopListenFor({ metaKey: true, code: "KeyK" });
  }

  registerListeners = modifier(() => {
    document.addEventListener("keydown", this.keyDown);
    this.keyboard.listenFor({ key: "Escape" }, () => {
      this.searchTerm = "";
      this.visible = false;
    });

    this.keyboard.listenFor({ ctrlKey: true, code: "KeyP", ignoreFormFieldFocus: true }, () => {
      this.searchTerm = "is:project";
    });

    this.keyboard.listenFor({ ctrlKey: true, code: "KeyO", ignoreFormFieldFocus: true }, () => {
      this.searchTerm = "is:organization";
    });

    return () => {
      document.removeEventListener("keydown", this.keyDown);
      this.keyboard.stopListenFor({ key: "Escape" });
      this.keyboard.stopListenFor({ ctrlKey: true, code: "KeyP" });
      this.keyboard.stopListenFor({ ctrlKey: true, code: "KeyO" });
    };
  });
}
