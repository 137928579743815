/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool, instanceOf } from "prop-types";
import server from "glesys-controlpanel/models/server";
import { isNone } from "@ember/utils";

@forbidExtraArgs
export default class ServerActions extends Component {
  @service router;
  @service intl;
  @service notification;
  @service store;
  @service errorHandler;

  @arg(instanceOf(server).isRequired) server;
  @arg(bool) manageEnabled = false;

  get powerAction() {
    return this.server.supportsPowerOn ? "on" : "off";
  }

  get hasIpAddresses() {
    return this.server.ipsVersion4.length > 0 || this.server.ipsVersion6.length > 0;
  }

  get isLockedOrNotRunning() {
    return this.server.isLocked || !this.server.isRunning;
  }

  get powerTranslationString() {
    return this.server.supportsPowerOn
      ? this.intl.t("component.server-actions.power-on")
      : this.intl.t("component.server-actions.power-off");
  }

  get serverStatusNotAvailable() {
    return isNone(this.server.isLocked) && isNone(this.server.isRunning);
  }

  get powerOnBiosIsDisabled() {
    return this.server.isLocked || this.serverStatusNotAvailable || !this.server.supportsPowerOn;
  }

  get consoleIsDisabled() {
    return this.server.isSuspended ? false : !this.server.isRunning;
  }

  @action
  async trigger(type) {
    const powerActions = ["on", "off", "off-forced", "on-bios", "reboot", "reset"];

    if (powerActions.includes(type)) {
      if (this.server.isLocked) {
        return;
      }

      try {
        this.server.lock(type);
        await this.server.power(type);
        this.notification.add(
          "success",
          this.intl.t("server.notifications.server-status.title"),
          this.intl.t("server.notifications.server-status.text"),
        );
      } catch (error) {
        this.server.unlock();
        this.errorHandler.handleError(error);
      }
    }
  }

  @action
  transitionTo(route, id) {
    this.router.transitionTo(route, id);
  }
}
