import { template as template_ce0d85b27d3c46a681d969a56d64ccd9 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { keepLatestTask } from "ember-concurrency";
import { service } from "@ember/service";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import DataLoader from "glesys-controlpanel/components/data-loader";
import IconsLoadingSpinner from "glesys-controlpanel/components/icons/loading-spinner";
import Cost from "glesys-controlpanel/components/cost";
import InfoPopup from "glesys-controlpanel/components/info-popup";
import { t } from "ember-intl";
import gMibToGib from "glesys-controlpanel/helpers/g-mibtogib";
export default class extends Component {
    @service
    ajax;
    get config() {
        return this.args.config;
    }
    get project() {
        return this.args.project;
    }
    getServerCostTask = keepLatestTask(async ()=>{
        let projectId = this.project?.id;
        let url = "/io.php/server/estimatedcost";
        let data = {
            projectkey: projectId,
            ...this.config,
            numberofbackupimagestokeep: 0
        };
        try {
            let response = await this.ajax.post(url, {
                data
            });
            return response.billing;
        } catch  {
            return {
                price: null
            };
        }
    });
    static{
        template_ce0d85b27d3c46a681d969a56d64ccd9(`
    <div class="flex items-center flex-col flex-wrap" data-test-appliance-cost {{mergeClasses}} ...attributes>
      <DataLoader @load={{this.getServerCostTask}}>
        <:loading>
          <IconsLoadingSpinner class="w-8 mb-3" />
        </:loading>
        <:default as |cost|>
          <Cost @currency={{this.project.currency}} @price={{cost.price}} @discountedPrice={{cost.total}} />
        </:default>
      </DataLoader>
      <p class="w-full text-center mt-4 text-sm text-gray-darker">
        <span class="flex items-center justify-center">
          <span class="pr-1">{{t "appliance.create.cost-for-ip"}}</span>
          <InfoPopup>{{t "appliance.create.tooltip-excluding"}}</InfoPopup>
        </span>
        {{t
          "appliance.create.basic-configuration"
          cpu=this.config.cpucores
          memory=(gMibToGib this.config.memorysize)
          disk=this.config.disksize
          bandwidth=this.config.bandwidth
        }}
      </p>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
