import { template as template_9de4651d1521485dae573a53ebfc3691 } from "@ember/template-compiler";
import type { TOC } from "@ember/component/template-only";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import autofocusable from "glesys-controlpanel/modifiers/autofocusable";
interface GInputSignature {
    Element: HTMLInputElement;
}
const GInput: TOC<GInputSignature> = template_9de4651d1521485dae573a53ebfc3691(`
  <input
    type="text"
    class="w-full border text-sm border-gray-light focus:border-powder-blue rounded p-3 mb-4"
    data-test-g-input
    {{autofocusable}}
    {{mergeClasses}}
    ...attributes
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default GInput;
declare module "@glint/environment-ember-loose/registry" {
    export default interface Registry {
        GInput: typeof GInput;
    }
}
