import { template as template_8add85ba155642bb86fa98ff63b62693 } from "@ember/template-compiler";
import { LinkTo } from "@ember/routing";
export default template_8add85ba155642bb86fa98ff63b62693(`
  <LinkTo @route={{@route}} @models={{@models}} @query={{@query}} data-test-header-search-result ...attributes>
    <div
      class="flex flex-1 text-sm cursor-pointer hover:bg-gray-lighter leading-4 border-t border-gray-lighter p-6
        {{if @active 'bg-gray-lighter active'}}"
      data-test-search-result
    >
      <div class="mr-2 text-gray-darker font-mono" data-test-search-result-name>
        {{@name}}
      </div>
      {{#if @description}}
        <div class="text-gray" data-test-search-result-description>
          {{@description}}
        </div>
      {{/if}}
    </div>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
