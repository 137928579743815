"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GetOperands = GetOperands;
var ecma402_abstract_1 = require("@formatjs/ecma402-abstract");
/**
 * http://ecma-international.org/ecma-402/7.0/index.html#sec-getoperands
 * @param s
 */
function GetOperands(s) {
    (0, ecma402_abstract_1.invariant)(typeof s === 'string', "GetOperands should have been called with a string");
    var n = (0, ecma402_abstract_1.ToNumber)(s);
    (0, ecma402_abstract_1.invariant)(n.isFinite(), 'n should be finite');
    var dp = s.indexOf('.');
    var iv;
    var f;
    var v;
    var fv = '';
    if (dp === -1) {
        iv = n;
        f = ecma402_abstract_1.ZERO;
        v = 0;
    }
    else {
        iv = s.slice(0, dp);
        fv = s.slice(dp, s.length);
        f = (0, ecma402_abstract_1.ToNumber)(fv);
        v = fv.length;
    }
    var i = (0, ecma402_abstract_1.ToNumber)(iv).abs();
    var w;
    var t;
    if (!f.isZero()) {
        var ft = fv.replace(/0+$/, '');
        w = ft.length;
        t = (0, ecma402_abstract_1.ToNumber)(ft);
    }
    else {
        w = 0;
        t = ecma402_abstract_1.ZERO;
    }
    return {
        Number: n,
        IntegerDigits: i.toNumber(),
        NumberOfFractionDigits: v,
        NumberOfFractionDigitsWithoutTrailing: w,
        FractionDigits: f.toNumber(),
        FractionDigitsWithoutTrailing: t.toNumber(),
    };
}
