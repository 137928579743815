import { service } from "@ember/service";
import Route from "@ember/routing/route";
import RSVP from "rsvp";

export default class DnsRegisterRoute extends Route {
  @service ajax;

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { project } = this.modelFor("organization");
    let { pricelist } = this.modelFor("dns");

    return RSVP.hash({
      project,
      countries: this.ajax.request("/io.php/country/list"),
      organization: project.get("organization"),
      pricelist,
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setProperties({
      availableDomains: [],
      selectedDomains: [],
      hasSearched: false,
    });
  }
}
