import ApplicationAdapter from "./application";

export default class FileStorageVolumeAdapter extends ApplicationAdapter {
  urlForQueryRecord() {
    return `${this.baseUri()}/filestorage/volumedetails`;
  }

  urlForCreateRecord() {
    return `${this.baseUri()}/filestorage/createvolume`;
  }

  urlForUpdateRecord(id) {
    return `${this.baseUri()}/filestorage/editvolume?volumeid=${id}`;
  }

  urlForDeleteRecord(id, _, snapshot) {
    return `${this.baseUri()}/filestorage/deletevolume?volumeid=${id}&projectkey=${snapshot.belongsTo("project").id}`;
  }
}
