import AdapterError, { NotFoundError as EmberDataAdapterNotFoundError } from "@ember-data/adapter/error";

export class HttpError extends Error {
  constructor(status, message) {
    super(message);

    this.status = status;
    this.name = "HttpError";
  }
}

export class NotFoundError extends HttpError {
  constructor(message = "") {
    super(404, message);
    this.name = "NotFoundError";
  }
}

export const isNotFoundError = (error) => {
  return (
    error instanceof NotFoundError ||
    error instanceof EmberDataAdapterNotFoundError ||
    error?.response?.status === 404 ||
    error?.errors?.at(0).status === 404
  );
};

export const isHttpError = (error) => {
  return (
    error instanceof HttpError ||
    error instanceof AdapterError ||
    Number.isInteger(error?.response?.status) ||
    Number.isInteger(error?.errors?.at(0)?.status)
  );
};
