/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { func, instanceOf } from "prop-types";
import { action } from "@ember/object";
import Pagination from "../pagination-dto";

@forbidExtraArgs
export default class TablePagination extends Component {
  @arg(instanceOf(Pagination).isRequired) pagination;
  @arg(func.isRequired) onPageChange;

  @action
  onNext() {
    this.onPageChange(this.pagination.page + 1);
  }

  @action
  onPrevious() {
    this.onPageChange(this.pagination.page - 1);
  }

  @action
  onFirstPage() {
    this.onPageChange(1);
  }

  @action
  onLastPage() {
    this.onPageChange(this.pagination.numberOfPages);
  }
}
