/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool, number } from "prop-types";
import { modifier } from "ember-modifier";

@forbidExtraArgs
export default class SecureContent extends Component {
  @arg(bool) hint = false;
  @arg(number) hiddenCharacters = 20;

  @tracked visible = false;
  @tracked element;

  get securedContent() {
    let numberOfCharactersToShow = this.hint ? 3 : 0;
    let content = this.element?.textContent.trim() ?? "";

    if (content.length < numberOfCharactersToShow) {
      return "*".repeat(this.hiddenCharacters);
    }

    return content.slice(0, numberOfCharactersToShow) + "*".repeat(this.hiddenCharacters - numberOfCharactersToShow);
  }

  storeElement = modifier((element) => {
    this.element = element;
  });

  @action
  toggleVisible() {
    this.visible = !this.visible;
  }
}
