import ApplicationSerializer from "./application";

export default class EmailAccountSerializer extends ApplicationSerializer {
  usesProxy = true;

  primaryKey = "emailaccount";

  normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
    let normalizedPayload = {
      ...payload,
      emailaccounts: payload.list.emailaccounts,
    };

    delete normalizedPayload.list;

    return super.normalizeArrayResponse(store, primaryModelClass, normalizedPayload, id, requestType);
  }
}
