import ApplicationSerializer from "./application";

export default class VpnUserSerializer extends ApplicationSerializer {
  usesProxy = true;

  primaryKey = "username";

  attrs = {
    project: "projectkey",
  };
}
