/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { next } from "@ember/runloop";
import { modifier } from "ember-modifier";

export default class ResourceSlider extends Component {
  get disabled() {
    return this.args.disabled || false;
  }

  get options() {
    return this.args.options;
  }

  get selected() {
    return this.args.selected;
  }

  get onUpdate() {
    return this.args.onUpdate;
  }

  get max() {
    return this.options ? this.options.length - 1 : 0;
  }

  get value() {
    if (this.options && this.selected) {
      return this.options.indexOf(this.selected);
    }

    return 0;
  }

  handleDefault = modifier(() => {
    if (!this.options?.length) {
      return;
    }

    if (!this.options.includes(this.selected)) {
      let option = this.options.find((option) => option.value === this.selected?.value) || this.options[0];
      next(() => this.onUpdate(option));
    }
  });

  @action
  update(index) {
    this.onUpdate(this.options ? this.options[index] : null);
  }
}
