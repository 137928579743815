/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { tracked } from "@glimmer/tracking";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import Component from "@glimmer/component";

export default class extends Component {
  @service notification;
  @service http;
  @service intl;
  @service modal;

  @tracked description = "";

  get project() {
    return this.args.params.project;
  }

  get isCreating() {
    return !this.createNewCredentials.lastSuccessful;
  }

  get instanceId() {
    return this.args.params.instance.id;
  }

  get onCredentialCreated() {
    return this.args.params.onCredentialCreated;
  }

  get createdCredentials() {
    return this.createNewCredentials.lastSuccessful.value;
  }

  onFormChange = ({ description }) => (this.description = description);

  fetchInstanceCredentials = task(async () => {
    let {
      content: { instance },
    } = await this.http.request({
      url: `/objectstorage/instancedetails?instanceid=${this.instanceId}`,
    });
    return instance.credentials;
  });

  createNewCredentials = task(async () => {
    let {
      content: { credential },
    } = await this.http.request({
      url: "/objectstorage/createcredential",
      method: "POST",
      body: {
        instanceid: this.instanceId,
        description: this.description,
      },
    });

    if (typeof credential !== "undefined") {
      await this.fetchInstanceCredentials.perform();

      this.onCredentialCreated(credential);

      await this.notification.add(
        "success",
        this.intl.t("object-storage.notifications.credentials-created.title"),
        this.intl.t("object-storage.notifications.credentials-created.text"),
      );
      return credential;
    }
  });
}
