import { template as template_25dcb0600c074b1b88bc16b21df6987c } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf } from "prop-types";
import ColocationRackModel from "glesys-controlpanel/models/colocation-rack";
import Box from "glesys-controlpanel/components/box";
import EntityTable from "glesys-controlpanel/components/entity/table";
import { dateFromNow } from "glesys-controlpanel/helpers/date-from-now";
export default @forbidExtraArgs
class EntityColocationRack extends Component {
    @service
    intl;
    @service
    abilities;
    @arg(instanceOf(ColocationRackModel))
    entity;
    get rack() {
        return this.entity;
    }
    get showEnergyConsumption() {
        return this.abilities.can("access colocation rack energy consumption for organization", this.entity.project.organization);
    }
    get tableData() {
        let energyConsumption = null;
        if (this.showEnergyConsumption) {
            energyConsumption = {
                header: this.intl.t("label.energy-consumption"),
                value: this.rack.latestwatthour?.value ? this.intl.t("colocation-racks.energy-consumption", {
                    value: this.rack.latestwatthour.value
                }) : "N/A",
                subValue: this.rack.latestwatthour?.time ? dateFromNow(this.rack.latestwatthour.time, this.intl.primaryLocale) : ""
            };
        }
        return [
            {
                header: this.intl.t("label.description"),
                value: this.rack.description
            },
            energyConsumption,
            {
                header: this.intl.t("label.cost"),
                billing: this.rack.billing
            }
        ].filter(Boolean);
    }
    static{
        template_25dcb0600c074b1b88bc16b21df6987c(`
    <Box @collapsable={{true}} data-test-colocation-rack={{this.rack.id}} ...attributes>
      <:header>
        <div class="flex items-center gap-2" data-test-colocation-rack-header>
          {{this.rack.id}}
          <span class="text-gray truncate max-w-64">{{this.rack.description}}</span>
        </div>
      </:header>
      <:default>
        <EntityTable @entity={{this.tableData}} />
      </:default>
    </Box>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
