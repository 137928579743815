import { template as template_e1f8f7d53fba435da4ce4817ff3b2fc0 } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import colors from "glesys-controlpanel/helpers/colors";
export default template_e1f8f7d53fba435da4ce4817ff3b2fc0(`
  <span
    class="text-2xs px-1 leading-none py-1 font-bold uppercase tracking-wide rounded {{colors 'blue'}}"
    {{mergeClasses}}
    ...attributes
  >
    {{@text}}
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
