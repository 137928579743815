/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { tracked } from "@glimmer/tracking";
import { Task, task } from "ember-concurrency";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import PrivateNetworkModel from "../../../models/private-network";
import { instanceOf, shape } from "prop-types";

@forbidExtraArgs
export default class ModalsPrivateNetworkEdit extends Component {
  @service modal;

  @arg(
    shape({
      privateNetwork: instanceOf(PrivateNetworkModel).isRequired,
      task: instanceOf(Task).isRequired,
    }).isRequired,
  )
  params;

  @tracked name = this.privateNetwork.name;

  get privateNetwork() {
    return this.params.privateNetwork;
  }

  get task() {
    return this.params.task;
  }

  onChange = ({ name }) => (this.name = name);

  submitTask = task(async () => {
    await this.task.perform(this.name);

    this.modal.close();
  });
}
