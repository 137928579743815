/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class ModalsInviteSend extends Component {
  @service modal;

  get task() {
    return this.args.params.task;
  }

  email;

  onFormChange = ({ email }) => (this.email = email);

  submitTask = task(async () => {
    let task = this.task;
    let email = this.email;

    await task.perform(email);

    this.modal.close();
  });
}
