import { template as template_9cd2777e410c432ca87973eedf31e36b } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { arrayOf, func, oneOfType, shape, string } from "prop-types";
import { countryCodeEmoji } from "country-code-emoji";
import Select from "glesys-controlpanel/components/select";
import { t } from "ember-intl";
export default @forbidExtraArgs
class CountrySelect extends Component {
    @arg(arrayOf(shape({
        code: string.isRequired,
        name: string.isRequired
    })).isRequired)
    countries;
    @arg(oneOfType([
        string,
        shape({
            code: string,
            name: string
        })
    ]))
    value;
    @arg(func.isRequired)
    onChange;
    @action
    countrySelected(value) {
        this.onChange(value);
    }
    countryFlagFor(country) {
        try {
            return countryCodeEmoji(country);
        } catch  {
            return "";
        }
    }
    static{
        template_9cd2777e410c432ca87973eedf31e36b(`
    <Select
      @items={{this.countries}}
      @selected={{this.value}}
      @onChange={{this.countrySelected}}
      @placeholder={{t "component.country-select.default"}}
      @searchable={{true}}
      @key="name"
      data-test-country-select
      ...attributes
      as |country|
    >
      {{this.countryFlagFor country.code}}
      {{country.name}}
    </Select>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
