import { template as template_3afb2abdebb44022aa81499b04cd37f1 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { restartableTask, timeout } from "ember-concurrency";
import ENV from "glesys-controlpanel/config/environment";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { func, number } from "prop-types";
export default @forbidExtraArgs
class InactivityTimer extends Component {
    domEvents = Object.freeze([
        "mousedown",
        "click",
        "keypress",
        "touchstart"
    ]);
    @arg(number)
    minutesToExpire = 60;
    @arg(func.isRequired)
    onInactive;
    @action
    handleInactivity() {
        this.inactivityTask.perform();
    }
    constructor(){
        super(...arguments);
        this.domEvents.forEach((event)=>window.addEventListener(event, this.handleInactivity));
        this.handleInactivity();
    }
    willDestroy() {
        super.willDestroy(...arguments);
        this.domEvents.forEach((event)=>window.removeEventListener(event, this.handleInactivity));
    }
    inactivityTask = restartableTask(async ()=>{
        let timeToExpire = this.minutesToExpire * 60 * 1000;
        if (ENV.environment === "test") {
            return;
        }
        await timeout(timeToExpire);
        this.onInactive();
    });
    static{
        template_3afb2abdebb44022aa81499b04cd37f1(`
    <div ...attributes>
      {{yield}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
