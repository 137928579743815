/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";

export default class InlineMessage extends Component {
  get message() {
    return this.args.message;
  }

  get iconUrl() {
    let icon = this.args.icon || "info";
    return `/assets/images/${icon}.svg`;
  }
}
