import Model, { attr, belongsTo, hasMany } from "@ember-data/model";
import { task } from "ember-concurrency";

export default class PrivateNetworkSegmentModel extends Model {
  @attr name;
  @attr platform;
  @attr ipv4subnet;
  @attr ipv6subnet;

  @belongsTo("private-network", { async: false, inverse: "segments" }) privateNetwork;
  @belongsTo("data-center", { async: false, inverse: null }) dataCenter;
  @hasMany("network-adapter", { async: false, inverse: "segment" }) networkAdapters;

  get type() {
    return "Virtual";
  }

  deleteTask = task(async () => {
    this.deleteRecord();

    await this.save({ adapterOptions: { context: this.privateNetwork.project.id } });
  });

  updateTask = task(async (name) => {
    this.name = name;

    await this.save({ adapterOptions: { context: this.privateNetwork.project.id } });
  });
}
