/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class ModalsFileStorageVolumesUpdateSettings extends Component {
  @service modal;

  get plans() {
    return this.args.params.plans;
  }

  get task() {
    return this.args.params.updateSettingsTask;
  }

  get volume() {
    return this.args.params.volume;
  }

  @tracked selectedPlan = this.volume?.plan;
  @tracked selectedAutoPromotion = this.volume?.hasAutoPromotion;

  @action
  onFormChange({ selectedAutoPromotion }) {
    this.selectedAutoPromotion = Boolean(selectedAutoPromotion);
  }

  @action
  selectPlan(plan) {
    this.selectedPlan = plan;
  }

  submitTask = task(async () => {
    let task = this.task;
    let selectedPlan = this.selectedPlan;
    let selectedAutoPromotion = this.selectedAutoPromotion;

    await task.perform(selectedPlan, selectedAutoPromotion);

    this.modal.close();
  });
}
