import Route from "@ember/routing/route";
import { service } from "@ember/service";
import { action } from "@ember/object";
import { updateUrlFor } from "glesys-controlpanel/utils/url";

export default class OrganizationOverviewRoute extends Route {
  @service router;
  @service errorHandler;

  async model() {
    return this.modelFor("organization");
  }

  afterModel({ project }, transition) {
    if (!project && transition.to.name !== "organization.overview.dashboard") {
      this.router.replaceWith("organization.overview.dashboard");
    }
  }

  @action
  error(error, transition) {
    this.errorHandler.handleError(error);
    updateUrlFor(transition, this.router);
    this.intermediateTransitionTo("organization.overview.error", error);
    return false;
  }
}
