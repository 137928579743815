import ApplicationSerializer from "./application";

export default class UserSerializer extends ApplicationSerializer {
  usesProxy = true;
  primaryKey = "username";

  attrs = {
    createdAt: "createdat",
    twoFactorMethod: "twofactormethod",
  };

  normalize(modelClass, resourceHash, prop) {
    resourceHash.links = {
      organizations: `/io.php/user/listorganizations`,
    };

    return super.normalize(modelClass, resourceHash, prop);
  }
}
