import RequestManager from "@ember-data/request";
import { service } from "@ember/service";
import ENV from "glesys-controlpanel/config/environment";
import { CacheHandler } from "@ember-data/store";
import Fetch from "@ember-data/request/fetch";

export const isIoUrl = (url) => new RegExp(/\/?io\.php/).test(url);

export const ioApiBaseUri = ENV.ioApiBaseUri || document.location.origin;

const isApiUri = (url) => url.startsWith(ioApiBaseUri) || url.startsWith(ENV.apiBaseUri);

const WithDefaultHeaders = {
  async request({ request }, next) {
    let headers = new Headers(request.headers);
    headers.append("Content-Type", "application/json");
    headers.append("Accept", "application/json");

    return next(Object.assign({}, request, { headers }));
  },
};

const WithAuthToken = (session) => ({
  async request({ request }, next) {
    let headers = new Headers(request.headers);

    if (session.basicToken && isApiUri(request.url)) {
      headers.set("Authorization", `Basic ${session.basicToken}`);
    }

    return next(Object.assign({}, request, { headers }));
  },
});

const WithApiBaseUri = {
  async request(context, next) {
    let apiBaseUri = isIoUrl(context.request.url) ? ioApiBaseUri : ENV.apiBaseUri;

    let url = new URL(context.request.url, apiBaseUri).toString();

    return next(Object.assign({}, context.request, { url }));
  },
};

const ParseApiResponse = {
  async request(context, next) {
    let response = await next(context.request);

    let content = response.content?.response ?? response.content;
    return { ...response, content };
  },
};

export default class Http extends RequestManager {
  @service session;

  constructor(createArgs) {
    super(createArgs);
    this.use([WithDefaultHeaders, WithApiBaseUri, WithAuthToken(this.session), ParseApiResponse, Fetch]);
    this.useCache(CacheHandler);
  }

  withContext(context) {
    this.session.context = context;

    return this;
  }

  request(requestInfo) {
    const request = Object.assign({}, requestInfo);
    if (request.body) {
      request.body = JSON.stringify(request.body);
    }

    return super.request(request);
  }
}
