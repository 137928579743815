import { template as template_f9931f52bf2649e794aba626d7c9f30e } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsError from "glesys-controlpanel/components/icons/error";
import { t } from "ember-intl";
export default template_f9931f52bf2649e794aba626d7c9f30e(`
  <div
    class="flex flex-col items-center justify-center h-full"
    data-test-route-error-default
    {{mergeClasses}}
    ...attributes
  >
    <IconsError class="w-20 h-20" />
    <h1 class="text-xl text-gray-darker pt-4">
      {{t "component.route-error.default"}}
    </h1>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
