import { template as template_ae4e84b53b404a4bb37aad2b49c2131b } from "@ember/template-compiler";
import gButtonColor from "glesys-controlpanel/helpers/g-button-color";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsConsole from "../icons/console";
const isNotCompact = (display)=>display !== "compact";
export default template_ae4e84b53b404a4bb37aad2b49c2131b(`
  <div
    class="inline-flex rounded text-sm px-4 py-2 mt-6 mb-2 {{gButtonColor 'outline'}}"
    {{mergeClasses}}
    ...attributes
  >
    <a href={{@href}} class="flex items-center text-gray-darker" target="_blank" rel="noopener noreferrer">
      <IconsConsole class="w-4 mr-2 {{if (isNotCompact @display) '-ml-1'}} -mt-px text-rebeccapurple" />
      <span>
        {{yield}}
      </span>
    </a>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
