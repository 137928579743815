import ApplicationSerializer from "./application";
import { EmbeddedRecordsMixin } from "@ember-data/serializer/rest";

export default class ShipmentSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  usesProxy = true;

  primaryKey = "key";

  attrs = {
    project: "projectkey",
    remoteHandsRequest: { key: "remotehandsrequest", serialize: false, deserialize: "records" },
  };

  normalize(modelClass, resourceHash, prop) {
    // Embedded relationships needs to have a id property,
    // even if their own serializer specifies another primaryKey.
    if (resourceHash.remotehandsrequest) {
      resourceHash.remotehandsrequest.id = resourceHash.remotehandsrequest.key;
    }

    return super.normalize(modelClass, resourceHash, prop);
  }
}
