import ApplicationSerializer from "./application";
import DasherizedMixin from "./_dasherized-mixin";

export default class NetworkSerializer extends ApplicationSerializer.extend(DasherizedMixin) {
  usesProxy = true;

  primaryKey = "networkid";

  attrs = {
    dataCenter: "datacenter",
    isPublic: "public",
  };
}
