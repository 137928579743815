/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { Task, task } from "ember-concurrency";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf, object, shape } from "prop-types";

@forbidExtraArgs
export default class ModalsDiskEditSize extends Component {
  @service modal;

  @arg(
    shape({
      disk: object.isRequired,
      limits: object.isRequired,
      getServerDiskCostTask: instanceOf(Task).isRequired,
      task: instanceOf(Task).isRequired,
    }).isRequired,
  )
  params;

  @tracked diskSize = this.disk.sizeingib;

  get getServerDiskCostTask() {
    return this.args.params.getServerDiskCostTask;
  }

  get disk() {
    return this.args.params.disk;
  }

  get task() {
    return this.args.params.task;
  }

  get hasChangedSize() {
    return this.disk.sizeingib < this.diskSize;
  }

  get sizeOptions() {
    let diskType = this.args.params.limits.disktypes.find((disk) => disk.type === this.disk.type);

    return diskType.sizes;
  }

  get filteredSizeOptions() {
    let currentSize = this.disk.sizeingib;
    return [
      ...new Set(
        this.sizeOptions
          .filter((size) => size >= currentSize)
          .concat([currentSize])
          .sort((a, b) => a - b),
      ),
    ];
  }

  get diskName() {
    return this.disk.name;
  }

  submitTask = task(async () => {
    await this.task.perform(this.disk, this.diskSize);
    this.modal.close();
  });

  @action
  onUpdate(value) {
    this.diskSize = value;
    let filteredSize = this.diskSize - this.disk.sizeingib;

    if (filteredSize) {
      this.getServerDiskCostTask.perform(filteredSize, this.disk.type);
    }
  }
}
