/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import EmberObject, { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { isEqual, isNone } from "@ember/utils";
import { next } from "@ember/runloop";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { array, bool, func, instanceOf, oneOf, oneOfType, string } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { PromiseManyArray } from "@ember-data/model/-private";
import { modifier } from "ember-modifier";

@forbidExtraArgs
export default class extends Component {
  @arg(bool) searchable = false;

  @arg(bool) disabled = false;

  @arg(func) onChange;

  @arg(oneOf(["click", "hover"])) event = "click";

  get eventTrigger() {
    return this.event;
  }

  @arg(stringable) placeholder;

  @arg(string) key = "value";

  @arg(bool) multiple = false;

  get valueKey() {
    return this.key;
  }

  @arg(oneOfType([array, instanceOf(EmberObject), instanceOf(PromiseManyArray)]).isRequired) items = [];

  @arg selected;

  get parsedItems() {
    return this.items;
  }

  get hasGroups() {
    return this.parsedItems[0]?.items !== undefined;
  }

  get allItemsFlattened() {
    return this.parsedItems?.flatMap((item) => {
      return item.items ? item.items.map((i) => i) : item;
    });
  }

  @tracked selectedItem;

  get renderedSelectedItem() {
    if (!this.selectedItem) {
      return "";
    }
    return this.multiple
      ? this.selectedItem.map((item) => item[this.valueKey]).join(", ")
      : this.selectedItem[this.valueKey];
  }

  @tracked isOpen = false;

  isFormField(event) {
    return ["input", "textarea", "select", "button"].includes(event.target.nodeName.toLowerCase());
  }

  registerKeydownListenerOnOpen = modifier(() => {
    if (this.isOpen) {
      document.addEventListener("keydown", this.keyDown, { capture: true });
    } else {
      document.removeEventListener("keydown", this.keyDown, { capture: true });
    }

    return () => {
      document.removeEventListener("keydown", this.keyDown, { capture: true });
    };
  });

  registerListener = modifier((element) => {
    switch (this.eventTrigger) {
      case "hover":
        element.addEventListener("mouseenter", this.open);
        element.addEventListener("mouseleave", this.close);
        break;
      case "click":
      default:
        element.addEventListener("click", this.handleClick);
    }

    return () => {
      switch (this.eventTrigger) {
        case "hover":
          element.removeEventListener("mouseenter", this.open);
          element.removeEventListener("mouseleave", this.close);
          break;
        case "click":
        default:
          element.removeEventListener("click", this.handleClick);
      }
    };
  });

  handleSelected = modifier(() => {
    let hasSelected = this.multiple ? Boolean(this.selected?.length) : !isNone(this.selected);

    if (hasSelected) {
      this.selectedItem = this.multiple
        ? this.selected
        : this.allItemsFlattened.find((item) =>
            isEqual(item[this.valueKey], this.selected[this.valueKey] || this.selected),
          );
    } else if (!this.placeholder && !this.multiple) {
      next(() => {
        this.selectItem(this.hasGroups ? this.parsedItems[0].items[0] : this.parsedItems[0]);
      });
    }
  });

  @action
  handleClick(event) {
    if (this.isFormField(event)) {
      return;
    }

    this.toggle();
  }

  @action
  keyDown(event) {
    if (event.key === "Escape") {
      event.stopPropagation();
      this.close();
    }
  }

  @action
  open() {
    this.isOpen = true;
  }

  @action
  close() {
    this.isOpen = false;
  }

  @action
  toggle() {
    this.isOpen = !this.isOpen;
  }

  @action
  selectItem(item) {
    if (this.multiple) {
      if (this.selectedItem?.includes(item)) {
        this.selectedItem = this.selectedItem.filter((i) => i !== item);
      } else {
        this.selectedItem = [...(this.selectedItem ?? []), item];
      }
    } else {
      this.selectedItem = item;
    }

    this.onChange?.(item);

    if (!this.multiple) {
      this.close();
    }
  }
}
