import { hash } from "rsvp";
import Route from "@ember/routing/route";
import { service } from "@ember/service";
import ENV from "glesys-controlpanel/config/environment";
import { all, task, timeout } from "ember-concurrency";

export default class ServerRoute extends Route {
  @service router;
  @service store;

  model(params) {
    let { organization, project } = this.modelFor("organization");
    let { servers } = this.modelFor("servers");
    let server = servers.find((server) => server.id === params.server_id);

    return hash({
      organization,
      project,
      server,
      arguments: server?.platformArguments,
    });
  }

  afterModel({ server }, transition) {
    if (!server) {
      !!transition.sequence || transition.abort();
      this.router.replaceWith("servers");
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    this.serverStatusAndDetailsLoop.perform(model.server);
  }

  resetController() {
    super.resetController(...arguments);
    this.serverStatusAndDetailsLoop.cancelAll();
  }

  serverStatusAndDetailsLoop = task(async (server) => {
    let serverAdapter = this.store.adapterFor("server");

    try {
      while (true) {
        await all([
          serverAdapter.get("fetchStatusTask").perform(server),
          serverAdapter.get("fetchDetailsTask").perform(server),
        ]);

        if (ENV.environment === "test") {
          return;
        }

        await timeout(2 * 1000);
      }
    } catch (e) {
      let statusCode = e?.errors?.at(0)?.status;
      let stateName = server.get("currentState.stateName");

      if (statusCode == 404 && !stateName.startsWith("root.deleted.")) {
        this.intermediateTransitionTo("not-found", null);
      }
    }
  });
}
