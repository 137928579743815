import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { TrackedObject } from "tracked-built-ins";
import { action } from "@ember/object";

export default class ObjectStorageDetailsController extends Controller {
  @service intl;
  @service http;
  @service notification;
  @service router;

  @tracked instance;
  @tracked wantsToDeleteCredential = false;

  get project() {
    return this.model.project;
  }

  get instanceId() {
    return this.model.instanceId;
  }

  onDescriptionChange = (description) => {
    this.instance.description = description;
  };

  get deleteCredentialText() {
    if (this.deleteCredential.isRunning) {
      return this.intl.t("label.deleting");
    }

    return this.wantsToDeleteCredential ? this.intl.t("label.are-you-sure") : this.intl.t("label.delete");
  }

  @action
  confirmDeleteCredential(credential, event) {
    event.stopPropagation();

    if (this.wantsToDeleteCredential && !this.deleteCredential.isRunning) {
      this.deleteCredential.perform(credential.id);
      this.wantsToDeleteCredential = false;
    } else {
      this.wantsToDeleteCredential = true;
    }

    setTimeout(() => {
      this.wantsToDeleteCredential = false;
    }, 6000);
  }

  credentialCreated = (credential) => {
    this.instance.credentials.push(credential);
  };

  deleteCredential = task(async (credentialId) => {
    await this.http.request({
      url: `/objectstorage/deletecredential?instanceid=${this.instanceId}&credentialid=${credentialId}`,
      method: "POST",
    });

    this.instance.credentials = this.instance.credentials.filter((credential) => credential.id !== credentialId);

    this.notification.add(
      "success",
      this.intl.t("object-storage.notifications.credentials-deleted.title"),
      this.intl.t("object-storage.notifications.credentials-deleted.text"),
    );
  });

  fetchInstance = task(async () => {
    let response = await this.http.request({
      url: `/objectstorage/instancedetails?instanceid=${this.instanceId}`,
    });

    this.instance = new TrackedObject(response.content.instance);
    this.instance.credentials = new TrackedObject(this.instance.credentials);
  });

  deleteInstance = task(async () => {
    await this.http.request({
      url: `/objectstorage/deleteinstance?instanceid=${this.instance.id}`,
      method: "POST",
    });

    this.notification.add(
      "success",
      this.intl.t("object-storage.notifications.instance-deleted.title"),
      this.intl.t("object-storage.notifications.instance-deleted.text"),
    );

    this.router.transitionTo("object-storage.overview");
  });
}
