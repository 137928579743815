import ApplicationAdapter from "./application";

export default class UserAdapter extends ApplicationAdapter {
  urlForQueryRecord() {
    return `${this.apiBaseUri}/user/details`;
  }

  urlForFindRecord() {
    return `${this.apiBaseUri}/user/details`;
  }

  urlForUpdateRecord() {
    return `${this.baseUri()}/user/edit`;
  }
}
