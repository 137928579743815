import ApplicationAdapter from "./application";

export default class LoadBalancerCertificateAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return `${this.baseUri()}/loadbalancer/addcertificate`;
  }

  urlForDeleteRecord(id, _, snapshot) {
    return `${this.baseUri()}/loadbalancer/removecertificate?certificatename=${id}&loadbalancerid=${snapshot.belongsTo("loadBalancer").id}`;
  }
}
