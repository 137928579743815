import Route from "@ember/routing/route";
import { task } from "ember-concurrency";

export default class ShipmentsOverviewRoute extends Route {
  model() {
    return {
      loadShipments: this.loadShipments,
    };
  }

  loadShipments = task(async () => {
    let { project } = this.modelFor("organization");
    return await project.get("shipments");
  });
}
