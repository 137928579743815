import { service } from "@ember/service";
import Controller from "@ember/controller";
import { dropTask } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";

export default class SignupController extends Controller {
  @service intl;
  @service ajax;
  @service metrics;
  @service current;
  @service errorHandler;

  @tracked name = null;
  @tracked email = null;
  @tracked emailWasSentTo = null;
  @tracked password = null;
  @tracked approved = false;

  @tracked showSuccess = false;

  get submitDisabled() {
    return !this.name || !this.email || !this.password || !this.approved;
  }

  resetForm() {
    this.status = null;
    this.showSuccess = false;
    this.name = null;
    this.email = null;
    this.password = null;
    this.approved = false;
  }

  @action
  onFormChange({ name, email, password, approved }) {
    this.name = name;
    this.email = email;
    this.password = password;
    this.approved = Boolean(approved);
  }

  signupTask = dropTask(async () => {
    let data = {
      name: this.name,
      email: this.email,
      password: this.password,
      termsofservice: this.approved ? "yes" : "no",
      locale: this.intl.primaryLocale,
      utm: this.current.get("utm"),
      useragent: navigator.userAgent,
    };

    this.showSuccess = false;

    await this.ajax.post("/io.php/user/signup", { data });

    this.metrics.trackEvent("GoogleTagManager", {
      event: "cloud-signup",
    });

    this.resetForm();

    this.showSuccess = true;
    this.emailWasSentTo = data.email;
  });
}
