import ApplicationSerializer from "./application";

export default class DnsDomainSerializer extends ApplicationSerializer {
  usesProxy = true;

  primaryKey = "domainname";

  normalize(modelClass, resourceHash, prop) {
    resourceHash.links = {
      records: `/io.php/domain/listrecords?domainname=${resourceHash.domainname}`,
    };

    return super.normalize(modelClass, resourceHash, prop);
  }
}
