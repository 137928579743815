/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { Task, task } from "ember-concurrency";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { capitalize } from "@ember/string";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf, object, shape, number, string } from "prop-types";

@forbidExtraArgs
export default class ModalsServerAddDisk extends Component {
  @service modal;
  @service intl;

  @arg(
    shape({
      limits: object.isRequired,
      currentNumberOfDisks: number.isRequired,
      name: string.isRequired,
      getServerDiskCostTask: instanceOf(Task).isRequired,
      task: instanceOf(Task).isRequired,
    }).isRequired,
  )
  params;

  @tracked name = this.defaultDiskName;
  @tracked selectedSize = this.minSize;
  @tracked selectedType = { value: "Gold", id: "gold" };

  constructor() {
    super(...arguments);
    this.getServerDiskCostTask.perform(this.selectedSize, this.selectedType.id);
  }

  get task() {
    return this.args.params.task;
  }

  get getServerDiskCostTask() {
    return this.args.params.getServerDiskCostTask;
  }

  get limits() {
    return this.args.params.limits;
  }

  get currentLimit() {
    return this.limits.disktypes.find((disk) => disk.type === this.selectedType.id);
  }

  get minSize() {
    return this.currentLimit.minsizeingib;
  }

  get sizeOptions() {
    return this.currentLimit.sizes;
  }

  get maxNumberOfDisks() {
    return this.limits.maxnumdisks;
  }

  get currentNumberOfDisks() {
    return this.args.params.currentNumberOfDisks;
  }

  get cantCreateMoreAdditionalDisks() {
    return this.maxNumberOfDisks <= this.currentNumberOfDisks;
  }

  get defaultDiskName() {
    return this.args.params.name;
  }

  get diskTypes() {
    return this.limits.disktypes.map((disk) => ({
      value: !disk.issupported
        ? this.intl.t("server.disks.additional-disks.not-supported", { type: capitalize(disk.type) }).toString()
        : capitalize(disk.type),
      id: disk.type,
      disabled: !disk.issupported,
    }));
  }

  onChange = ({ name }) => (this.name = name);

  submitTask = task(async () => {
    await this.task.perform(this.name, this.selectedSize, this.selectedType.id);
    this.modal.close();
  });

  @action
  selectType(type) {
    this.selectedType = type;
    this.getServerDiskCostTask.perform(this.selectedSize, this.selectedType.id);
  }

  @action
  selectSize(size) {
    this.selectedSize = size;
    this.getServerDiskCostTask.perform(this.selectedSize, this.selectedType.id);
  }
}
