import ApplicationAdapter from "./application";

export default class EmailAccountAdapter extends ApplicationAdapter {
  urlForCreateRecord(_, snapshot) {
    return `${this.baseUri()}/email/createaccount?context=${snapshot.belongsTo("domain").belongsTo("project").id}`;
  }

  urlForUpdateRecord() {
    let [, , snapshot] = arguments;
    return `${this.baseUri()}/email/editaccount?context=${snapshot.belongsTo("domain").belongsTo("project").id}`;
  }

  urlForDeleteRecord(id, _, snapshot) {
    return `${this.baseUri()}/email/delete?email=${id}&context=${snapshot.belongsTo("domain").belongsTo("project").id}`;
  }
}
