import ApplicationAdapter from "./application";

export default class ArchiveAdapter extends ApplicationAdapter {
  coalesceFindRequests = false;

  urlForFindRecord(id) {
    return `${this.baseUri()}/archive/details?username=${id}`;
  }

  urlForCreateRecord() {
    return `${this.baseUri()}/archive/create`;
  }

  urlForDeleteRecord(id) {
    return `${this.baseUri()}/archive/delete?username=${id}`;
  }
}
