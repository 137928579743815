import { hash } from "rsvp";
import { service } from "@ember/service";
import Route from "@ember/routing/route";
import { enqueueTask, restartableTask, all, timeout } from "ember-concurrency";
import ENV from "glesys-controlpanel/config/environment";

export default class LoadBalancerRoute extends Route {
  @service ajax;

  model(params) {
    let { project } = this.modelFor("organization");

    return project.get("loadBalancers").then((loadBalancers) => {
      let loadBalancer = loadBalancers.find((loadBalancer) => loadBalancer.id === params.load_balancer_id);

      return hash({
        project,
        loadBalancer,
        certificates: loadBalancer.get("certificates"),
        servers: project.get("servers"),
      });
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    let { loadBalancer } = model;
    this.targetStatusLoopTask.perform(loadBalancer);
  }

  resetController() {
    super.resetController(...arguments);
    this.targetStatusLoopTask.cancelAll();
  }

  targetStatusLoopTask = restartableTask(async (loadBalancer) => {
    while (true) {
      let listeners = (await loadBalancer?.get("listeners"))?.filter(
        (listener) => !listener.isNew && !listener.isDeleted,
      );
      await all(listeners.map((listener) => this.fetchTargetStatusTask.perform(listener)));

      /* istanbul ignore else */
      if (ENV.environment === "test") {
        return;
      }

      /* istanbul ignore next */
      await timeout(5 * 1000);
    }
  });

  fetchTargetStatusTask = enqueueTask({ maxConcurrency: 2 }, async (listener) => {
    await listener.fetchTargetStatus();
  });
}
