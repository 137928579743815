import { template as template_c4c1b041fbf54813a669891ffe4891e2 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { oneOf } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsWarning from "glesys-controlpanel/components/icons/warning";
import IconsCheckCircle from "glesys-controlpanel/components/icons/check-circle";
import IconsInfo from "glesys-controlpanel/components/icons/info";
export default @forbidExtraArgs
class AlertMessage extends Component {
    @arg(stringable)
    message;
    @arg(oneOf([
        "confirmation",
        "information",
        "warning",
        "error"
    ]))
    type;
    get classes() {
        switch(this.type){
            case "confirmation":
                return "bg-ocean-green-lightest text-ocean-green-darker border rounded border-ocean-green";
            case "information":
                return "bg-powder-blue-lightest text-powder-blue-darker border rounded border-powder-blue-light";
            case "warning":
                return "bg-orange-lightest text-orange-darker border rounded border-orange-lighter";
            case "error":
                return "bg-red-lightest text-red-dark border rounded border-red-lighter";
            default:
                return "bg-white text-gray-darker border rounded border-gray-light";
        }
    }
    get isWarning() {
        return this.type === "warning";
    }
    get isError() {
        return this.type === "error";
    }
    get isConfirmation() {
        return this.type === "confirmation";
    }
    static{
        template_c4c1b041fbf54813a669891ffe4891e2(`
    <div class="{{this.classes}} flex shadow-base text-sm px-4 py-3" role="alert" {{mergeClasses}} ...attributes>
      <div class="max-h-8 min-w-20 flex items-center mr-3">
        {{#if this.isWarning}}
          <IconsWarning class="stroke-current size-4" />
        {{else if this.isError}}
          <IconsWarning class="stroke-current size-4" />
        {{else if this.isConfirmation}}
          <IconsCheckCircle class="stroke-current stroke-1 size-5" />
        {{else}}
          <IconsInfo class="stroke-current size-4" />
        {{/if}}
      </div>
      {{#if this.message}}
        <div data-test-alert-message={{this.type}}>
          <p>
            {{this.message}}
          </p>
        </div>
      {{else}}
        {{#if (has-block)}}
          {{yield}}
        {{/if}}
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
