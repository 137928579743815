/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { arg } from "ember-arg-types";
import { func, instanceOf, string } from "prop-types";
import ProjectModel from "glesys-controlpanel/models/project";
import { all, keepLatestTask, restartableTask, task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";

export default class ServersCreateIpSelectionBox extends Component {
  @service intl;
  @service store;

  @arg(instanceOf(ProjectModel).isRequired) project;
  @arg(string.isRequired) platform;
  @arg(string.isRequired) dataCenter;
  @arg(func.isRequired) onChange;

  @tracked availableIps = [];
  @tracked reservedIps = [];

  @tracked selectedIpV4;
  @tracked selectedIpV6;

  get reservedIpsV4() {
    return this.reservedIps.filter((ip) => ip.version === 4);
  }

  get reservedIpsV6() {
    return this.reservedIps.filter((ip) => ip.version === 6);
  }

  get availableIpsV4() {
    return this.availableIps.filter((ip) => ip.version === 4);
  }

  get availableIpsV6() {
    return this.availableIps.filter((ip) => ip.version === 6);
  }

  get selectableIpsV4() {
    let noIpOption = [{ label: this.intl.t("label.no-ipv4").toString(), id: "none" }];
    let ips = [
      { groupName: this.intl.t("label.reserved-ips"), items: this.reservedIpsV4 },
      { groupName: this.intl.t("label.available-ips"), items: this.availableIpsV4 },
    ].filter((group) => group.items.length);

    return noIpOption.concat(ips);
  }

  get selectableIpsV6() {
    let noIpOption = [{ label: this.intl.t("label.no-ipv6").toString(), id: "none" }];
    let ips = [
      { groupName: this.intl.t("label.reserved-ips"), items: this.reservedIpsV6 },
      { groupName: this.intl.t("label.available-ips"), items: this.availableIpsV6 },
    ].filter((group) => group.items.length);

    return noIpOption.concat(ips);
  }

  fetchAvailableIpsTask = task(async () => {
    return all(
      [4, 6].map((version) => {
        return this.store.query("available-ip", {
          projectkey: this.project.id,
          platform: this.platform,
          datacenter: this.dataCenter,
          ipversion: version,
        });
      }),
    );
  });

  setAvailableIpsTask = restartableTask(async () => {
    let availableIps = await this.fetchAvailableIpsTask.perform();
    this.availableIps = [].concat(...availableIps);
  });

  setDefaultIpAddressTask = keepLatestTask(async (version) => {
    let availableIps = this.availableIps.filter((ip) => ip.version === version);
    let reservedIps = (await this.reservedIps).filter((ip) => ip.version === version);

    let defaultIp;

    if (reservedIps.length) {
      defaultIp = reservedIps[0];
    }

    if (!defaultIp) {
      defaultIp = availableIps[Math.floor(Math.random() * availableIps.length)];
    }

    if (version === 4) {
      this.selectedIpV4 = defaultIp;
    } else {
      this.selectedIpV6 = defaultIp;
    }
  });

  setIpsTask = restartableTask(async () => {
    await this.setAvailableIpsTask.perform();
    await all([this.setDefaultIpAddressTask.perform(4), this.setDefaultIpAddressTask.perform(6)]);

    this.onChange(this.selectedIpV4, this.selectedIpV6);
  });

  setReservedIpsTask = restartableTask(async () => {
    let reservedIps = await this.project.reservedIps;

    this.reservedIps = reservedIps.filter(
      (ip) => ip.dataCenter === this.dataCenter && ip.platforms.includes(this.platform) && ip.serverId === null,
    );
  });

  loadData = task(async () => {
    this.selectedIpV4 = null;
    this.selectedIpV6 = null;

    return await all([this.setReservedIpsTask.perform(), this.setIpsTask.perform()]);
  });

  @action
  setIpV4(ip) {
    this.selectedIpV4 = ip;
    this.onChange(this.selectedIpV4, this.selectedIpV6);
  }

  @action
  setIpV6(ip) {
    this.selectedIpV6 = ip;
    this.onChange(this.selectedIpV4, this.selectedIpV6);
  }
}
