/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";

@forbidExtraArgs
export default class LoadBalancerTarget extends Component {
  @arg servers;
  @arg target;

  get loadBalancer() {
    return this.target.listener.get("loadBalancer");
  }

  get hostname() {
    let serversMatchingTargetIp = [];
    let hostname = this.servers.filter((server) => {
      for (const ip of server.ipsVersion4) {
        if (ip.address == this.target.ip) {
          serversMatchingTargetIp.push(server.hostname);
        }
      }
      for (const ip of server.ipsVersion6) {
        if (ip.address == this.target.ip) {
          serversMatchingTargetIp.push(server.hostname);
        }
      }
      return serversMatchingTargetIp.includes(server.hostname);
    });
    return hostname.length > 0 ? hostname[0].hostname : "";
  }

  get ips() {
    return this.servers
      .filter((server) => server.ipsVersion4.length > 0 || server.ipsVersion6.length > 0)
      .map((server) => {
        let ips = server.ipsVersion4
          .map((ip) => ({ value: ip.address }))
          .concat(server.ipsVersion6.map((ip) => ({ value: ip.address })));

        return {
          groupName: server.hostname,
          items: ips,
        };
      });
  }

  @action
  updateTargetIpFromServer(ip) {
    this.target.set("ip", ip.value);
  }

  onIpChange = (event) => (this.target.ip = event.target.value);

  onPortChange = (event) => (this.target.port = parseInt(event.target.value));

  onWeightChange = (event) => (this.target.weight = parseInt(event.target.value));

  @action
  activeChange(event) {
    this.target.active = event.target.checked;
  }

  @action
  deleteTarget() {
    let target = this.target;

    if (target.get("isDeleted")) {
      target.rollbackAttributes();
    } else {
      target.deleteRecord();
    }
  }
}
