/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { Task, task } from "ember-concurrency";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf, string, shape } from "prop-types";
import { tracked } from "@glimmer/tracking";

@forbidExtraArgs
export default class ModalsDiskEditName extends Component {
  @service modal;

  @arg(
    shape({
      disk: shape({ name: string }).isRequired,
      task: instanceOf(Task).isRequired,
    }).isRequired,
  )
  params;

  get disk() {
    return this.args.params.disk;
  }

  get task() {
    return this.args.params.task;
  }

  get currentDiskName() {
    return this.disk.name;
  }

  @tracked name = this.disk.name;

  submitTask = task(async () => {
    await this.task.perform(this.disk, this.name);
    this.modal.close();
  });

  onChange = ({ name }) => (this.name = name);
}
