/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { emberDataArrayOf } from "glesys-controlpanel/utils/prop-types";
import { number, shape, string } from "prop-types";
import { tracked } from "@glimmer/tracking";
import { task } from "ember-concurrency";
import styles from "./styles.css";

@forbidExtraArgs
export default class OrganizationOverviewBoxCollaborators extends Component {
  @arg(
    emberDataArrayOf(
      shape({
        username: string,
      }),
    ),
  )
  collaborators = [];

  @arg(number) nrOfCollaboratorsToShowAsImages = 8;

  styles = styles;
  @tracked activeCollaborators;

  get showAsImages() {
    if (this.activeCollaborators.length - this.nrOfCollaboratorsToShowAsImages === 1) {
      return this.nrOfCollaboratorsToShowAsImages + 1;
    }

    return this.nrOfCollaboratorsToShowAsImages;
  }

  get collaboratorsToShowAsImages() {
    return this.activeCollaborators.slice(0, this.showAsImages);
  }

  get collaboratorCount() {
    return this.activeCollaborators.length - this.showAsImages;
  }

  loadData = task(async () => {
    let collaborators = await this.collaborators;
    this.activeCollaborators = collaborators.filter((collaborator) => collaborator.isInviteAccepted);
  });
}
