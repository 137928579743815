import { template as template_60b35a3ba06a45d69292c5de67a751b2 } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
export default template_60b35a3ba06a45d69292c5de67a751b2(`
  <svg
    class="stroke-gray-darkest"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {{mergeClasses}}
    ...attributes
  >
    <path
      d="M1.22913 4.10913L7.99996 7.77079M1.22913 4.10913V11.6925L7.99996 15.3541M1.22913 4.10913L4.61454 2.41913M7.99996 7.77079V15.3541M7.99996 7.77079L11.3854 5.93996M7.99996 15.3541L14.7708 11.6925V4.10913M4.61454 2.41913L7.99996 0.729126L14.7708 4.10913M4.61454 2.41913L11.3854 5.93996M14.7708 4.10913L11.3854 5.93996"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
