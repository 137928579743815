import { Ability } from "ember-can";
import { service } from "@ember/service";

export default class ProjectAbility extends Ability {
  @service session;

  get project() {
    return this.model;
  }

  get canAccess() {
    if (this.session.currentUser?.isSuperUser) {
      return true;
    }

    if (this.project.locked) {
      return false;
    }

    return true;
  }

  get canManage() {
    return this.project.hasWriteAccess;
  }

  get canAccessSettings() {
    return this.project.hasWriteAccess;
  }

  get canDelete() {
    return this.canAccessSettings && this.session.currentUser.isIndividual;
  }

  get canCreateLoadBalancer() {
    return this.project.hasWriteAccess;
  }

  get canCreateFileStorageVolume() {
    return this.project.hasWriteAccess;
  }

  get canCreateAppliances() {
    if (this.session.currentUser?.isSuperUser) {
      return true;
    }

    if (this.project.get("access") === "full") {
      return true;
    }

    return false;
  }

  get canOrderDedicatedServer() {
    return this.session.currentUser.isIndividual && this.project.organization.isVerified;
  }
}
