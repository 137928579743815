/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { Task, task } from "ember-concurrency";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf } from "prop-types";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import ENV from "glesys-controlpanel/config/environment";

@forbidExtraArgs
export default class OrganizationPaymentMethod extends Component {
  @service ajax;
  @service router;
  @service modal;
  @service notification;
  @service intl;

  @arg organization;

  @arg(instanceOf(Task).isRequired) submitTask;

  @tracked paymentCardUrl;

  get cardIsAvailable() {
    return this.organization.availablePaymentMethods.includes("card");
  }

  get invoiceIsAvailable() {
    return this.organization.availablePaymentMethods.includes("invoice");
  }

  @tracked selectedPaymentMethod = this.organization.paymentMethod;
  @tracked selectedName = this.organization.name;
  selectedIdentityNumber = this.organization.identityNumber;
  @tracked selectedAddress = this.organization.address;
  @tracked selectedCity = this.organization.city;
  @tracked selectedZip = this.organization.zip;
  selectedVatNumber = this.organization.vatNumber;
  selectedOrganizationType = this.organization.type;
  selectedOrganizationCountry = this.organization.country;
  organizationHasVatNumber = !!this.organization.vatNumber;

  get hasUnpaidInvoices() {
    return this.organization.hasUnpaidInvoices;
  }

  get selectedPaymentMethodIsCard() {
    return this.selectedPaymentMethod === "card";
  }

  get canContinueOrSave() {
    return !!this.selectedName && !!this.selectedAddress && !!this.selectedCity && !!this.selectedZip;
  }

  constructor() {
    super(...arguments);

    if (this.selectedPaymentMethod === null && this.cardIsAvailable) {
      this.selectedPaymentMethod = "card";
    }

    if (this.selectedPaymentMethodIsCard) {
      this.fetchPaymentCardUrl.perform();
    }
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.fetchPaymentCardUrl.cancelAll();
  }

  fetchPaymentCardUrl = task(async () => {
    let organization = this.organization;
    let {
      paymentcard: { url },
    } = await this.ajax.post("/io.php/paymentcard/add", {
      data: {
        language: "EN",
        customernumber: organization.get("id"),
      },
    });

    this.paymentCardUrl = url;

    if (!this.paymentCardUrl) {
      this.notification.add(
        "error",
        this.intl.t("component.organization-payment-method.error-fetching-payment-card-url.title"),
        this.intl.t("component.organization-payment-method.error-fetching-payment-card-url.description"),
      );
    }
  });

  submitFormTask = task(async () => {
    if (this.selectedPaymentMethod) {
      this.submitTask.perform(this.selectedPaymentMethod, {
        name: this.selectedName,
        identityNumber: this.selectedIdentityNumber,
        address: this.selectedAddress,
        city: this.selectedCity,
        zip: this.selectedZip,
        vatNumber: this.selectedVatNumber,
      });
    }
  });

  @action
  submit(event) {
    // We need to prevent default in tests,
    // otherwise it will try to open the payment card link causing a reload of the tests
    if (ENV.environment === "test") {
      event.preventDefault();
    }

    this.submitFormTask.perform();
  }

  @action
  saveOrganization() {
    this.saveOrganizationTask.perform();
  }

  @action
  cancel() {
    this.router.transitionTo("organization");
  }

  @action
  selectPaymentMethod(paymentMethod) {
    this.selectedPaymentMethod = paymentMethod;

    this.submitFormTask.cancelAll({ resetState: true });

    if (this.selectedPaymentMethodIsCard && !this.paymentCardUrl) {
      this.fetchPaymentCardUrl.perform();
    }
  }

  @action
  onFormChange({ selectedName, selectedAddress, selectedCity, selectedZip, selectedVatNumber }) {
    this.selectedName = selectedName;
    this.selectedAddress = selectedAddress;
    this.selectedCity = selectedCity;
    this.selectedZip = selectedZip;
    this.selectedVatNumber = selectedVatNumber ? selectedVatNumber : null;
  }
}
