import { template as template_f4411be7ca4b4ee0a634d1d72cb91fcb } from "@ember/template-compiler";
import GButton from "./";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsPlus from "../icons/plus";
const isNotCompact = (display)=>display !== "compact";
export default template_f4411be7ca4b4ee0a634d1d72cb91fcb(`
  <GButton
    @type={{@type}}
    @color="outline"
    @disabled={{@disabled}}
    @loading={{@loading}}
    @display={{if @display @display "default"}}
    {{mergeClasses}}
    ...attributes
  >
    <div class="flex items-center">
      <IconsPlus class="w-5 mr-1 {{if (isNotCompact @display) '-ml-1.5'}} -mt-px text-ocean-green" />
      <span>{{yield}}</span>
    </div>
  </GButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
