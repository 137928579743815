import { template as template_f0ac3be2510d4596b0ea213e5633d8bf } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import colors from "glesys-controlpanel/helpers/colors";
import { t } from "ember-intl";
export default template_f0ac3be2510d4596b0ea213e5633d8bf(`
  <span
    class="text-2xs px-1 leading-none py-1 font-bold uppercase tracking-wide rounded {{colors 'pink'}}"
    {{mergeClasses}}
    ...attributes
  >
    {{#if @text}}
      {{@text}}
    {{else}}
      {{t "label.beta"}}
    {{/if}}
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
