/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { func, instanceOf } from "prop-types";
import reservedIp from "glesys-controlpanel/models/reserved-ip";

@forbidExtraArgs
export default class IpItem extends Component {
  @arg(instanceOf(reservedIp).isRequired) ip;

  @arg(func) releaseIP;

  @tracked showMore = false;

  updatePtrTask = task(async (ptr) => {
    let ip = this.ip;
    ip.set("ptr", ptr);

    await ip.save().catch((error) => {
      ip.rollbackAttributes();
      throw error;
    });
  });

  get platform() {
    return (this.ip.platforms || []).join(", ");
  }

  @action
  release() {
    this.releaseIP(this.ip);
  }

  @action
  info() {
    this.showMore = !this.showMore;
  }
}
