import ApplicationSerializer from "./application";

export default class ServerBackupSerializer extends ApplicationSerializer {
  usesProxy = true;

  normalize(modelClass, resourceHash, prop) {
    resourceHash.createdAt = resourceHash.createdat ?? resourceHash.createdAt;
    resourceHash.completedAt = resourceHash.completedat ?? resourceHash.completedAt;
    resourceHash.diskInGiB = resourceHash.diskingib ?? resourceHash.diskInGiB;

    return super.normalize(modelClass, resourceHash, prop);
  }
}
