"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.likelySubtags = void 0;
/* @generated */
// prettier-ignore  
exports.likelySubtags = {
    "aa": "aa-Latn-ET",
    "aaa": "aaa-Latn-NG",
    "aab": "aab-Latn-NG",
    "aac": "aac-Latn-PG",
    "aad": "aad-Latn-PG",
    "aae": "aae-Latn-IT",
    "aaf": "aaf-Mlym-IN",
    "aag": "aag-Latn-PG",
    "aah": "aah-Latn-PG",
    "aai": "aai-Latn-PG",
    "aak": "aak-Latn-PG",
    "aal": "aal-Latn-CM",
    "aan": "aan-Latn-BR",
    "aao": "aao-Arab-DZ",
    "aap": "aap-Latn-BR",
    "aaq": "aaq-Latn-US",
    "aas": "aas-Latn-TZ",
    "aat": "aat-Grek-GR",
    "aau": "aau-Latn-PG",
    "aaw": "aaw-Latn-PG",
    "aax": "aax-Latn-ID",
    "aaz": "aaz-Latn-ID",
    "ab": "ab-Cyrl-GE",
    "aba": "aba-Latn-CI",
    "abb": "abb-Latn-CM",
    "abc": "abc-Latn-PH",
    "abd": "abd-Latn-PH",
    "abe": "abe-Latn-CA",
    "abf": "abf-Latn-MY",
    "abg": "abg-Latn-PG",
    "abh": "abh-Arab-TJ",
    "abi": "abi-Latn-CI",
    "abl": "abl-Rjng-ID",
    "abm": "abm-Latn-NG",
    "abn": "abn-Latn-NG",
    "abo": "abo-Latn-NG",
    "abp": "abp-Latn-PH",
    "abr": "abr-Latn-GH",
    "abs": "abs-Latn-ID",
    "abt": "abt-Latn-PG",
    "abu": "abu-Latn-CI",
    "abv": "abv-Arab-BH",
    "abw": "abw-Latn-PG",
    "abx": "abx-Latn-PH",
    "aby": "aby-Latn-PG",
    "abz": "abz-Latn-ID",
    "aca": "aca-Latn-CO",
    "acb": "acb-Latn-NG",
    "acd": "acd-Latn-GH",
    "ace": "ace-Latn-ID",
    "acf": "acf-Latn-LC",
    "ach": "ach-Latn-UG",
    "acm": "acm-Arab-IQ",
    "acn": "acn-Latn-CN",
    "acp": "acp-Latn-NG",
    "acq": "acq-Arab-YE",
    "acr": "acr-Latn-GT",
    "acs": "acs-Latn-BR",
    "act": "act-Latn-NL",
    "acu": "acu-Latn-EC",
    "acv": "acv-Latn-US",
    "acw": "acw-Arab-SA",
    "acx": "acx-Arab-OM",
    "acy": "acy-Latn-CY",
    "acz": "acz-Latn-SD",
    "ada": "ada-Latn-GH",
    "adb": "adb-Latn-TL",
    "add": "add-Latn-CM",
    "ade": "ade-Latn-TG",
    "adf": "adf-Arab-OM",
    "adg": "adg-Latn-AU",
    "adh": "adh-Latn-UG",
    "adi": "adi-Latn-IN",
    "adj": "adj-Latn-CI",
    "adl": "adl-Latn-IN",
    "adn": "adn-Latn-ID",
    "ado": "ado-Latn-PG",
    "adq": "adq-Latn-GH",
    "adr": "adr-Latn-ID",
    "adt": "adt-Latn-AU",
    "adu": "adu-Latn-NG",
    "adw": "adw-Latn-BR",
    "adx": "adx-Tibt-CN",
    "ady": "ady-Cyrl-RU",
    "adz": "adz-Latn-PG",
    "ae": "ae-Avst-IR",
    "aea": "aea-Latn-AU",
    "aeb": "aeb-Arab-TN",
    "aec": "aec-Arab-EG",
    "aee": "aee-Arab-AF",
    "aek": "aek-Latn-NC",
    "ael": "ael-Latn-CM",
    "aem": "aem-Latn-VN",
    "aeq": "aeq-Arab-PK",
    "aer": "aer-Latn-AU",
    "aeu": "aeu-Latn-CN",
    "aew": "aew-Latn-PG",
    "aey": "aey-Latn-PG",
    "aez": "aez-Latn-PG",
    "af": "af-Latn-ZA",
    "afb": "afb-Arab-KW",
    "afd": "afd-Latn-PG",
    "afe": "afe-Latn-NG",
    "afh": "afh-Latn-GH",
    "afi": "afi-Latn-PG",
    "afk": "afk-Latn-PG",
    "afn": "afn-Latn-NG",
    "afo": "afo-Latn-NG",
    "afp": "afp-Latn-PG",
    "afs": "afs-Latn-MX",
    "afu": "afu-Latn-GH",
    "afz": "afz-Latn-ID",
    "aga": "aga-Latn-PE",
    "agb": "agb-Latn-NG",
    "agc": "agc-Latn-NG",
    "agd": "agd-Latn-PG",
    "age": "age-Latn-PG",
    "agf": "agf-Latn-ID",
    "agg": "agg-Latn-PG",
    "agh": "agh-Latn-CD",
    "agi": "agi-Deva-IN",
    "agj": "agj-Ethi-ET",
    "agk": "agk-Latn-PH",
    "agl": "agl-Latn-PG",
    "agm": "agm-Latn-PG",
    "agn": "agn-Latn-PH",
    "ago": "ago-Latn-PG",
    "agq": "agq-Latn-CM",
    "agr": "agr-Latn-PE",
    "ags": "ags-Latn-CM",
    "agt": "agt-Latn-PH",
    "agu": "agu-Latn-GT",
    "agv": "agv-Latn-PH",
    "agw": "agw-Latn-SB",
    "agx": "agx-Cyrl-RU",
    "agy": "agy-Latn-PH",
    "agz": "agz-Latn-PH",
    "aha": "aha-Latn-GH",
    "ahb": "ahb-Latn-VU",
    "ahg": "ahg-Ethi-ET",
    "ahh": "ahh-Latn-ID",
    "ahi": "ahi-Latn-CI",
    "ahk": "ahk-Latn-MM",
    "ahl": "ahl-Latn-TG",
    "ahm": "ahm-Latn-CI",
    "ahn": "ahn-Latn-NG",
    "aho": "aho-Ahom-IN",
    "ahp": "ahp-Latn-CI",
    "ahr": "ahr-Deva-IN",
    "ahs": "ahs-Latn-NG",
    "aht": "aht-Latn-US",
    "aia": "aia-Latn-SB",
    "aib": "aib-Arab-CN",
    "aic": "aic-Latn-PG",
    "aid": "aid-Latn-AU",
    "aie": "aie-Latn-PG",
    "aif": "aif-Latn-PG",
    "aig": "aig-Latn-AG",
    "aii": "aii-Syrc-IQ",
    "aij": "aij-Hebr-IL",
    "aik": "aik-Latn-NG",
    "ail": "ail-Latn-PG",
    "aim": "aim-Latn-IN",
    "ain": "ain-Kana-JP",
    "aio": "aio-Mymr-IN",
    "aip": "aip-Latn-ID",
    "aiq": "aiq-Arab-AF",
    "air": "air-Latn-ID",
    "ait": "ait-Latn-BR",
    "aiw": "aiw-Latn-ET",
    "aix": "aix-Latn-PG",
    "aiy": "aiy-Latn-CF",
    "aja": "aja-Latn-SS",
    "ajg": "ajg-Latn-BJ",
    "aji": "aji-Latn-NC",
    "ajn": "ajn-Latn-AU",
    "ajw": "ajw-Latn-NG",
    "ajz": "ajz-Latn-IN",
    "ak": "ak-Latn-GH",
    "akb": "akb-Latn-ID",
    "akc": "akc-Latn-ID",
    "akd": "akd-Latn-NG",
    "ake": "ake-Latn-GY",
    "akf": "akf-Latn-NG",
    "akg": "akg-Latn-ID",
    "akh": "akh-Latn-PG",
    "aki": "aki-Latn-PG",
    "akk": "akk-Xsux-IQ",
    "akl": "akl-Latn-PH",
    "ako": "ako-Latn-SR",
    "akp": "akp-Latn-GH",
    "akq": "akq-Latn-PG",
    "akr": "akr-Latn-VU",
    "aks": "aks-Latn-TG",
    "akt": "akt-Latn-PG",
    "aku": "aku-Latn-CM",
    "akv": "akv-Cyrl-RU",
    "akw": "akw-Latn-CG",
    "akz": "akz-Latn-US",
    "ala": "ala-Latn-NG",
    "alc": "alc-Latn-CL",
    "ald": "ald-Latn-CI",
    "ale": "ale-Latn-US",
    "alf": "alf-Latn-NG",
    "alh": "alh-Latn-AU",
    "ali": "ali-Latn-PG",
    "alj": "alj-Latn-PH",
    "alk": "alk-Laoo-LA",
    "all": "all-Mlym-IN",
    "alm": "alm-Latn-VU",
    "aln": "aln-Latn-XK",
    "alo": "alo-Latn-ID",
    "alp": "alp-Latn-ID",
    "alq": "alq-Latn-CA",
    "alr": "alr-Cyrl-RU",
    "alt": "alt-Cyrl-RU",
    "alu": "alu-Latn-SB",
    "alw": "alw-Ethi-ET",
    "alx": "alx-Latn-PG",
    "aly": "aly-Latn-AU",
    "alz": "alz-Latn-CD",
    "am": "am-Ethi-ET",
    "ama": "ama-Latn-BR",
    "amb": "amb-Latn-NG",
    "amc": "amc-Latn-PE",
    "ame": "ame-Latn-PE",
    "amf": "amf-Latn-ET",
    "amg": "amg-Latn-AU",
    "ami": "ami-Latn-TW",
    "amj": "amj-Latn-TD",
    "amk": "amk-Latn-ID",
    "amm": "amm-Latn-PG",
    "amn": "amn-Latn-PG",
    "amo": "amo-Latn-NG",
    "amp": "amp-Latn-PG",
    "amq": "amq-Latn-ID",
    "amr": "amr-Latn-PE",
    "ams": "ams-Jpan-JP",
    "amt": "amt-Latn-PG",
    "amu": "amu-Latn-MX",
    "amv": "amv-Latn-ID",
    "amw": "amw-Syrc-SY",
    "amx": "amx-Latn-AU",
    "amy": "amy-Latn-AU",
    "amz": "amz-Latn-AU",
    "an": "an-Latn-ES",
    "ana": "ana-Latn-CO",
    "anb": "anb-Latn-PE",
    "anc": "anc-Latn-NG",
    "and": "and-Latn-ID",
    "ane": "ane-Latn-NC",
    "anf": "anf-Latn-GH",
    "ang": "ang-Latn-GB",
    "anh": "anh-Latn-PG",
    "ani": "ani-Cyrl-RU",
    "anj": "anj-Latn-PG",
    "ank": "ank-Latn-NG",
    "anl": "anl-Latn-MM",
    "anm": "anm-Latn-IN",
    "ann": "ann-Latn-NG",
    "ano": "ano-Latn-CO",
    "anp": "anp-Deva-IN",
    "anq": "anq-Deva-IN",
    "anr": "anr-Deva-IN",
    "ans": "ans-Latn-CO",
    "ant": "ant-Latn-AU",
    "anu": "anu-Ethi-ET",
    "anv": "anv-Latn-CM",
    "anw": "anw-Latn-NG",
    "anx": "anx-Latn-PG",
    "any": "any-Latn-CI",
    "anz": "anz-Latn-PG",
    "aoa": "aoa-Latn-ST",
    "aob": "aob-Latn-PG",
    "aoc": "aoc-Latn-VE",
    "aod": "aod-Latn-PG",
    "aoe": "aoe-Latn-PG",
    "aof": "aof-Latn-PG",
    "aog": "aog-Latn-PG",
    "aoi": "aoi-Latn-AU",
    "aoj": "aoj-Latn-PG",
    "aok": "aok-Latn-NC",
    "aol": "aol-Latn-ID",
    "aom": "aom-Latn-PG",
    "aon": "aon-Latn-PG",
    "aor": "aor-Latn-VU",
    "aos": "aos-Latn-ID",
    "aot": "aot-Beng-BD",
    "aox": "aox-Latn-GY",
    "aoz": "aoz-Latn-ID",
    "apb": "apb-Latn-SB",
    "apc": "apc-Arab-SY",
    "apd": "apd-Arab-TG",
    "ape": "ape-Latn-PG",
    "apf": "apf-Latn-PH",
    "apg": "apg-Latn-ID",
    "aph": "aph-Deva-NP",
    "api": "api-Latn-BR",
    "apj": "apj-Latn-US",
    "apk": "apk-Latn-US",
    "apl": "apl-Latn-US",
    "apm": "apm-Latn-US",
    "apn": "apn-Latn-BR",
    "apo": "apo-Latn-PG",
    "app": "app-Latn-VU",
    "apr": "apr-Latn-PG",
    "aps": "aps-Latn-PG",
    "apt": "apt-Latn-IN",
    "apu": "apu-Latn-BR",
    "apv": "apv-Latn-BR",
    "apw": "apw-Latn-US",
    "apx": "apx-Latn-ID",
    "apy": "apy-Latn-BR",
    "apz": "apz-Latn-PG",
    "aqc": "aqc-Cyrl-RU",
    "aqd": "aqd-Latn-ML",
    "aqg": "aqg-Latn-NG",
    "aqk": "aqk-Latn-NG",
    "aqm": "aqm-Latn-ID",
    "aqn": "aqn-Latn-PH",
    "aqr": "aqr-Latn-NC",
    "aqt": "aqt-Latn-PY",
    "aqz": "aqz-Latn-BR",
    "ar": "ar-Arab-EG",
    "arc": "arc-Armi-IR",
    "arc-Hatr": "arc-Hatr-IQ",
    "arc-Nbat": "arc-Nbat-JO",
    "arc-Palm": "arc-Palm-SY",
    "ard": "ard-Latn-AU",
    "are": "are-Latn-AU",
    "arh": "arh-Latn-CO",
    "ari": "ari-Latn-US",
    "arj": "arj-Latn-BR",
    "ark": "ark-Latn-BR",
    "arl": "arl-Latn-PE",
    "arn": "arn-Latn-CL",
    "aro": "aro-Latn-BO",
    "arp": "arp-Latn-US",
    "arq": "arq-Arab-DZ",
    "arr": "arr-Latn-BR",
    "ars": "ars-Arab-SA",
    "aru": "aru-Latn-BR",
    "arw": "arw-Latn-SR",
    "arx": "arx-Latn-BR",
    "ary": "ary-Arab-MA",
    "arz": "arz-Arab-EG",
    "as": "as-Beng-IN",
    "asa": "asa-Latn-TZ",
    "asb": "asb-Latn-CA",
    "asc": "asc-Latn-ID",
    "ase": "ase-Sgnw-US",
    "asg": "asg-Latn-NG",
    "ash": "ash-Latn-PE",
    "asi": "asi-Latn-ID",
    "asj": "asj-Latn-CM",
    "ask": "ask-Arab-AF",
    "asl": "asl-Latn-ID",
    "asn": "asn-Latn-BR",
    "aso": "aso-Latn-PG",
    "asr": "asr-Deva-IN",
    "ass": "ass-Latn-CM",
    "ast": "ast-Latn-ES",
    "asu": "asu-Latn-BR",
    "asv": "asv-Latn-CD",
    "asx": "asx-Latn-PG",
    "asy": "asy-Latn-ID",
    "asz": "asz-Latn-ID",
    "ata": "ata-Latn-PG",
    "atb": "atb-Latn-CN",
    "atc": "atc-Latn-PE",
    "atd": "atd-Latn-PH",
    "ate": "ate-Latn-PG",
    "atg": "atg-Latn-NG",
    "ati": "ati-Latn-CI",
    "atj": "atj-Latn-CA",
    "atk": "atk-Latn-PH",
    "atl": "atl-Latn-PH",
    "atm": "atm-Latn-PH",
    "atn": "atn-Arab-IR",
    "ato": "ato-Latn-CM",
    "atp": "atp-Latn-PH",
    "atq": "atq-Latn-ID",
    "atr": "atr-Latn-BR",
    "ats": "ats-Latn-US",
    "att": "att-Latn-PH",
    "atu": "atu-Latn-SS",
    "atv": "atv-Cyrl-RU",
    "atw": "atw-Latn-US",
    "atx": "atx-Latn-BR",
    "aty": "aty-Latn-VU",
    "atz": "atz-Latn-PH",
    "aua": "aua-Latn-SB",
    "auc": "auc-Latn-EC",
    "aud": "aud-Latn-SB",
    "aug": "aug-Latn-BJ",
    "auh": "auh-Latn-ZM",
    "aui": "aui-Latn-PG",
    "auj": "auj-Arab-LY",
    "auk": "auk-Latn-PG",
    "aul": "aul-Latn-VU",
    "aum": "aum-Latn-NG",
    "aun": "aun-Latn-PG",
    "auo": "auo-Latn-NG",
    "aup": "aup-Latn-PG",
    "auq": "auq-Latn-ID",
    "aur": "aur-Latn-PG",
    "aut": "aut-Latn-PF",
    "auu": "auu-Latn-ID",
    "auw": "auw-Latn-ID",
    "auy": "auy-Latn-PG",
    "auz": "auz-Arab-UZ",
    "av": "av-Cyrl-RU",
    "avb": "avb-Latn-PG",
    "avd": "avd-Arab-IR",
    "avi": "avi-Latn-CI",
    "avk": "avk-Latn-001",
    "avl": "avl-Arab-EG",
    "avm": "avm-Latn-AU",
    "avn": "avn-Latn-GH",
    "avo": "avo-Latn-BR",
    "avs": "avs-Latn-PE",
    "avt": "avt-Latn-PG",
    "avu": "avu-Latn-SS",
    "avv": "avv-Latn-BR",
    "awa": "awa-Deva-IN",
    "awb": "awb-Latn-PG",
    "awc": "awc-Latn-NG",
    "awe": "awe-Latn-BR",
    "awg": "awg-Latn-AU",
    "awh": "awh-Latn-ID",
    "awi": "awi-Latn-PG",
    "awk": "awk-Latn-AU",
    "awm": "awm-Latn-PG",
    "awn": "awn-Ethi-ET",
    "awo": "awo-Latn-NG",
    "awr": "awr-Latn-ID",
    "aws": "aws-Latn-ID",
    "awt": "awt-Latn-BR",
    "awu": "awu-Latn-ID",
    "awv": "awv-Latn-ID",
    "aww": "aww-Latn-PG",
    "awx": "awx-Latn-PG",
    "awy": "awy-Latn-ID",
    "axb": "axb-Latn-AR",
    "axe": "axe-Latn-AU",
    "axg": "axg-Latn-BR",
    "axk": "axk-Latn-CF",
    "axl": "axl-Latn-AU",
    "axm": "axm-Armn-AM",
    "axx": "axx-Latn-NC",
    "ay": "ay-Latn-BO",
    "aya": "aya-Latn-PG",
    "ayb": "ayb-Latn-BJ",
    "ayc": "ayc-Latn-PE",
    "ayd": "ayd-Latn-AU",
    "aye": "aye-Latn-NG",
    "ayg": "ayg-Latn-TG",
    "ayh": "ayh-Arab-YE",
    "ayi": "ayi-Latn-NG",
    "ayk": "ayk-Latn-NG",
    "ayl": "ayl-Arab-LY",
    "ayn": "ayn-Arab-YE",
    "ayo": "ayo-Latn-PY",
    "ayp": "ayp-Arab-IQ",
    "ayq": "ayq-Latn-PG",
    "ays": "ays-Latn-PH",
    "ayt": "ayt-Latn-PH",
    "ayu": "ayu-Latn-NG",
    "ayz": "ayz-Latn-ID",
    "az": "az-Latn-AZ",
    "az-Arab": "az-Arab-IR",
    "az-IQ": "az-Arab-IQ",
    "az-IR": "az-Arab-IR",
    "az-RU": "az-Cyrl-RU",
    "azb": "azb-Arab-IR",
    "azd": "azd-Latn-MX",
    "azg": "azg-Latn-MX",
    "azm": "azm-Latn-MX",
    "azn": "azn-Latn-MX",
    "azo": "azo-Latn-CM",
    "azt": "azt-Latn-PH",
    "azz": "azz-Latn-MX",
    "ba": "ba-Cyrl-RU",
    "baa": "baa-Latn-SB",
    "bab": "bab-Latn-GW",
    "bac": "bac-Latn-ID",
    "bae": "bae-Latn-VE",
    "baf": "baf-Latn-CM",
    "bag": "bag-Latn-CM",
    "bah": "bah-Latn-BS",
    "baj": "baj-Latn-ID",
    "bal": "bal-Arab-PK",
    "ban": "ban-Latn-ID",
    "bao": "bao-Latn-CO",
    "bap": "bap-Deva-NP",
    "bap-Krai": "bap-Krai-IN",
    "bar": "bar-Latn-AT",
    "bas": "bas-Latn-CM",
    "bau": "bau-Latn-NG",
    "bav": "bav-Latn-CM",
    "baw": "baw-Latn-CM",
    "bax": "bax-Bamu-CM",
    "bay": "bay-Latn-ID",
    "bba": "bba-Latn-BJ",
    "bbb": "bbb-Latn-PG",
    "bbc": "bbc-Latn-ID",
    "bbd": "bbd-Latn-PG",
    "bbe": "bbe-Latn-CD",
    "bbf": "bbf-Latn-PG",
    "bbg": "bbg-Latn-GA",
    "bbi": "bbi-Latn-CM",
    "bbj": "bbj-Latn-CM",
    "bbk": "bbk-Latn-CM",
    "bbl": "bbl-Geor-GE",
    "bbm": "bbm-Latn-CD",
    "bbn": "bbn-Latn-PG",
    "bbo": "bbo-Latn-BF",
    "bbp": "bbp-Latn-CF",
    "bbq": "bbq-Latn-CM",
    "bbr": "bbr-Latn-PG",
    "bbs": "bbs-Latn-NG",
    "bbt": "bbt-Latn-NG",
    "bbu": "bbu-Latn-NG",
    "bbv": "bbv-Latn-PG",
    "bbw": "bbw-Latn-CM",
    "bbx": "bbx-Latn-CM",
    "bby": "bby-Latn-CM",
    "bca": "bca-Latn-CN",
    "bcb": "bcb-Latn-SN",
    "bcd": "bcd-Latn-ID",
    "bce": "bce-Latn-CM",
    "bcf": "bcf-Latn-PG",
    "bcg": "bcg-Latn-GN",
    "bch": "bch-Latn-PG",
    "bci": "bci-Latn-CI",
    "bcj": "bcj-Latn-AU",
    "bck": "bck-Latn-AU",
    "bcm": "bcm-Latn-PG",
    "bcn": "bcn-Latn-NG",
    "bco": "bco-Latn-PG",
    "bcp": "bcp-Latn-CD",
    "bcq": "bcq-Ethi-ET",
    "bcr": "bcr-Latn-CA",
    "bcs": "bcs-Latn-NG",
    "bct": "bct-Latn-CD",
    "bcu": "bcu-Latn-PG",
    "bcv": "bcv-Latn-NG",
    "bcw": "bcw-Latn-CM",
    "bcy": "bcy-Latn-NG",
    "bcz": "bcz-Latn-SN",
    "bda": "bda-Latn-SN",
    "bdb": "bdb-Latn-ID",
    "bdc": "bdc-Latn-CO",
    "bdd": "bdd-Latn-PG",
    "bde": "bde-Latn-NG",
    "bdf": "bdf-Latn-PG",
    "bdg": "bdg-Latn-MY",
    "bdh": "bdh-Latn-SS",
    "bdi": "bdi-Latn-SD",
    "bdj": "bdj-Latn-SS",
    "bdk": "bdk-Latn-AZ",
    "bdl": "bdl-Latn-ID",
    "bdm": "bdm-Latn-TD",
    "bdn": "bdn-Latn-CM",
    "bdo": "bdo-Latn-TD",
    "bdp": "bdp-Latn-TZ",
    "bdq": "bdq-Latn-VN",
    "bdr": "bdr-Latn-MY",
    "bds": "bds-Latn-TZ",
    "bdt": "bdt-Latn-CF",
    "bdu": "bdu-Latn-CM",
    "bdv": "bdv-Orya-IN",
    "bdw": "bdw-Latn-ID",
    "bdx": "bdx-Latn-ID",
    "bdy": "bdy-Latn-AU",
    "bdz": "bdz-Arab-PK",
    "be": "be-Cyrl-BY",
    "bea": "bea-Latn-CA",
    "beb": "beb-Latn-CM",
    "bec": "bec-Latn-CM",
    "bed": "bed-Latn-ID",
    "bee": "bee-Deva-IN",
    "bef": "bef-Latn-PG",
    "beh": "beh-Latn-BJ",
    "bei": "bei-Latn-ID",
    "bej": "bej-Arab-SD",
    "bek": "bek-Latn-PG",
    "bem": "bem-Latn-ZM",
    "beo": "beo-Latn-PG",
    "bep": "bep-Latn-ID",
    "beq": "beq-Latn-CG",
    "bes": "bes-Latn-TD",
    "bet": "bet-Latn-CI",
    "beu": "beu-Latn-ID",
    "bev": "bev-Latn-CI",
    "bew": "bew-Latn-ID",
    "bex": "bex-Latn-SS",
    "bey": "bey-Latn-PG",
    "bez": "bez-Latn-TZ",
    "bfa": "bfa-Latn-SS",
    "bfb": "bfb-Deva-IN",
    "bfc": "bfc-Latn-CN",
    "bfd": "bfd-Latn-CM",
    "bfe": "bfe-Latn-ID",
    "bff": "bff-Latn-CF",
    "bfg": "bfg-Latn-ID",
    "bfh": "bfh-Latn-PG",
    "bfj": "bfj-Latn-CM",
    "bfl": "bfl-Latn-CF",
    "bfm": "bfm-Latn-CM",
    "bfn": "bfn-Latn-TL",
    "bfo": "bfo-Latn-BF",
    "bfp": "bfp-Latn-CM",
    "bfq": "bfq-Taml-IN",
    "bfs": "bfs-Latn-CN",
    "bft": "bft-Arab-PK",
    "bfu": "bfu-Tibt-IN",
    "bfw": "bfw-Orya-IN",
    "bfx": "bfx-Latn-PH",
    "bfy": "bfy-Deva-IN",
    "bfz": "bfz-Deva-IN",
    "bg": "bg-Cyrl-BG",
    "bga": "bga-Latn-NG",
    "bgb": "bgb-Latn-ID",
    "bgc": "bgc-Deva-IN",
    "bgd": "bgd-Deva-IN",
    "bgf": "bgf-Latn-CM",
    "bgg": "bgg-Latn-IN",
    "bgi": "bgi-Latn-PH",
    "bgj": "bgj-Latn-CM",
    "bgn": "bgn-Arab-PK",
    "bgo": "bgo-Latn-GN",
    "bgp": "bgp-Arab-PK",
    "bgq": "bgq-Deva-IN",
    "bgr": "bgr-Latn-IN",
    "bgs": "bgs-Latn-PH",
    "bgt": "bgt-Latn-SB",
    "bgu": "bgu-Latn-NG",
    "bgv": "bgv-Latn-ID",
    "bgw": "bgw-Deva-IN",
    "bgx": "bgx-Grek-TR",
    "bgy": "bgy-Latn-ID",
    "bgz": "bgz-Latn-ID",
    "bha": "bha-Deva-IN",
    "bhb": "bhb-Deva-IN",
    "bhc": "bhc-Latn-ID",
    "bhd": "bhd-Deva-IN",
    "bhe": "bhe-Arab-PK",
    "bhf": "bhf-Latn-PG",
    "bhg": "bhg-Latn-PG",
    "bhh": "bhh-Cyrl-IL",
    "bhi": "bhi-Deva-IN",
    "bhj": "bhj-Deva-NP",
    "bhl": "bhl-Latn-PG",
    "bhm": "bhm-Arab-OM",
    "bhn": "bhn-Syrc-GE",
    "bho": "bho-Deva-IN",
    "bhp": "bhp-Latn-ID",
    "bhq": "bhq-Latn-ID",
    "bhr": "bhr-Latn-MG",
    "bhs": "bhs-Latn-CM",
    "bht": "bht-Deva-IN",
    "bhu": "bhu-Deva-IN",
    "bhv": "bhv-Latn-ID",
    "bhw": "bhw-Latn-ID",
    "bhy": "bhy-Latn-CD",
    "bhz": "bhz-Latn-ID",
    "bi": "bi-Latn-VU",
    "bia": "bia-Latn-AU",
    "bib": "bib-Latn-BF",
    "bid": "bid-Latn-TD",
    "bie": "bie-Latn-PG",
    "bif": "bif-Latn-GW",
    "big": "big-Latn-PG",
    "bik": "bik-Latn-PH",
    "bil": "bil-Latn-NG",
    "bim": "bim-Latn-GH",
    "bin": "bin-Latn-NG",
    "bio": "bio-Latn-PG",
    "bip": "bip-Latn-CD",
    "biq": "biq-Latn-PG",
    "bir": "bir-Latn-PG",
    "bit": "bit-Latn-PG",
    "biu": "biu-Latn-IN",
    "biv": "biv-Latn-GH",
    "biw": "biw-Latn-CM",
    "biy": "biy-Deva-IN",
    "biz": "biz-Latn-CD",
    "bja": "bja-Latn-CD",
    "bjb": "bjb-Latn-AU",
    "bjc": "bjc-Latn-PG",
    "bjf": "bjf-Syrc-IL",
    "bjg": "bjg-Latn-GW",
    "bjh": "bjh-Latn-PG",
    "bji": "bji-Latn-ET",
    "bjj": "bjj-Deva-IN",
    "bjk": "bjk-Latn-PG",
    "bjl": "bjl-Latn-PG",
    "bjm": "bjm-Arab-IQ",
    "bjn": "bjn-Latn-ID",
    "bjo": "bjo-Latn-CF",
    "bjp": "bjp-Latn-PG",
    "bjr": "bjr-Latn-PG",
    "bjs": "bjs-Latn-BB",
    "bjt": "bjt-Latn-SN",
    "bju": "bju-Latn-CM",
    "bjv": "bjv-Latn-TD",
    "bjw": "bjw-Latn-CI",
    "bjx": "bjx-Latn-PH",
    "bjy": "bjy-Latn-AU",
    "bjz": "bjz-Latn-PG",
    "bka": "bka-Latn-NG",
    "bkc": "bkc-Latn-CM",
    "bkd": "bkd-Latn-PH",
    "bkf": "bkf-Latn-CD",
    "bkg": "bkg-Latn-CF",
    "bkh": "bkh-Latn-CM",
    "bki": "bki-Latn-VU",
    "bkj": "bkj-Latn-CF",
    "bkk": "bkk-Tibt-IN",
    "bkl": "bkl-Latn-ID",
    "bkm": "bkm-Latn-CM",
    "bkn": "bkn-Latn-ID",
    "bko": "bko-Latn-CM",
    "bkp": "bkp-Latn-CD",
    "bkq": "bkq-Latn-BR",
    "bkr": "bkr-Latn-ID",
    "bks": "bks-Latn-PH",
    "bkt": "bkt-Latn-CD",
    "bku": "bku-Latn-PH",
    "bkv": "bkv-Latn-NG",
    "bkw": "bkw-Latn-CG",
    "bkx": "bkx-Latn-TL",
    "bky": "bky-Latn-NG",
    "bkz": "bkz-Latn-ID",
    "bla": "bla-Latn-CA",
    "blb": "blb-Latn-SB",
    "blc": "blc-Latn-CA",
    "bld": "bld-Latn-ID",
    "ble": "ble-Latn-GW",
    "blf": "blf-Latn-ID",
    "blh": "blh-Latn-LR",
    "bli": "bli-Latn-CD",
    "blj": "blj-Latn-ID",
    "blk": "blk-Mymr-MM",
    "blm": "blm-Latn-SS",
    "bln": "bln-Latn-PH",
    "blo": "blo-Latn-BJ",
    "blp": "blp-Latn-SB",
    "blq": "blq-Latn-PG",
    "blr": "blr-Latn-CN",
    "bls": "bls-Latn-ID",
    "blt": "blt-Tavt-VN",
    "blv": "blv-Latn-AO",
    "blw": "blw-Latn-PH",
    "blx": "blx-Latn-PH",
    "bly": "bly-Latn-BJ",
    "blz": "blz-Latn-ID",
    "bm": "bm-Latn-ML",
    "bma": "bma-Latn-NG",
    "bmb": "bmb-Latn-CD",
    "bmc": "bmc-Latn-PG",
    "bmd": "bmd-Latn-GN",
    "bme": "bme-Latn-CF",
    "bmf": "bmf-Latn-SL",
    "bmg": "bmg-Latn-CD",
    "bmh": "bmh-Latn-PG",
    "bmi": "bmi-Latn-TD",
    "bmj": "bmj-Deva-NP",
    "bmk": "bmk-Latn-PG",
    "bml": "bml-Latn-CD",
    "bmm": "bmm-Latn-MG",
    "bmn": "bmn-Latn-PG",
    "bmo": "bmo-Latn-CM",
    "bmp": "bmp-Latn-PG",
    "bmq": "bmq-Latn-ML",
    "bmr": "bmr-Latn-CO",
    "bms": "bms-Latn-NE",
    "bmu": "bmu-Latn-PG",
    "bmv": "bmv-Latn-CM",
    "bmw": "bmw-Latn-CG",
    "bmx": "bmx-Latn-PG",
    "bmz": "bmz-Latn-PG",
    "bn": "bn-Beng-BD",
    "bna": "bna-Latn-ID",
    "bnb": "bnb-Latn-MY",
    "bnc": "bnc-Latn-PH",
    "bnd": "bnd-Latn-ID",
    "bne": "bne-Latn-ID",
    "bnf": "bnf-Latn-ID",
    "bng": "bng-Latn-GQ",
    "bni": "bni-Latn-CD",
    "bnj": "bnj-Latn-PH",
    "bnk": "bnk-Latn-VU",
    "bnm": "bnm-Latn-GQ",
    "bnn": "bnn-Latn-TW",
    "bno": "bno-Latn-PH",
    "bnp": "bnp-Latn-PG",
    "bnq": "bnq-Latn-ID",
    "bnr": "bnr-Latn-VU",
    "bns": "bns-Deva-IN",
    "bnu": "bnu-Latn-ID",
    "bnv": "bnv-Latn-ID",
    "bnw": "bnw-Latn-PG",
    "bnx": "bnx-Latn-CD",
    "bny": "bny-Latn-MY",
    "bnz": "bnz-Latn-CM",
    "bo": "bo-Tibt-CN",
    "boa": "boa-Latn-PE",
    "bob": "bob-Latn-KE",
    "boe": "boe-Latn-CM",
    "bof": "bof-Latn-BF",
    "boh": "boh-Latn-CD",
    "boj": "boj-Latn-PG",
    "bok": "bok-Latn-CG",
    "bol": "bol-Latn-NG",
    "bom": "bom-Latn-NG",
    "bon": "bon-Latn-PG",
    "boo": "boo-Latn-ML",
    "bop": "bop-Latn-PG",
    "boq": "boq-Latn-PG",
    "bor": "bor-Latn-BR",
    "bot": "bot-Latn-SS",
    "bou": "bou-Latn-TZ",
    "bov": "bov-Latn-GH",
    "bow": "bow-Latn-PG",
    "box": "box-Latn-BF",
    "boy": "boy-Latn-CF",
    "boz": "boz-Latn-ML",
    "bpa": "bpa-Latn-VU",
    "bpc": "bpc-Latn-CM",
    "bpd": "bpd-Latn-CF",
    "bpe": "bpe-Latn-PG",
    "bpg": "bpg-Latn-ID",
    "bph": "bph-Cyrl-RU",
    "bpi": "bpi-Latn-PG",
    "bpj": "bpj-Latn-CD",
    "bpk": "bpk-Latn-NC",
    "bpl": "bpl-Latn-AU",
    "bpm": "bpm-Latn-PG",
    "bpo": "bpo-Latn-ID",
    "bpp": "bpp-Latn-ID",
    "bpq": "bpq-Latn-ID",
    "bpr": "bpr-Latn-PH",
    "bps": "bps-Latn-PH",
    "bpt": "bpt-Latn-AU",
    "bpu": "bpu-Latn-PG",
    "bpv": "bpv-Latn-ID",
    "bpw": "bpw-Latn-PG",
    "bpx": "bpx-Deva-IN",
    "bpy": "bpy-Beng-IN",
    "bpz": "bpz-Latn-ID",
    "bqa": "bqa-Latn-BJ",
    "bqb": "bqb-Latn-ID",
    "bqc": "bqc-Latn-BJ",
    "bqd": "bqd-Latn-CM",
    "bqf": "bqf-Latn-GN",
    "bqg": "bqg-Latn-TG",
    "bqi": "bqi-Arab-IR",
    "bqj": "bqj-Latn-SN",
    "bqk": "bqk-Latn-CF",
    "bql": "bql-Latn-PG",
    "bqm": "bqm-Latn-CM",
    "bqo": "bqo-Latn-CM",
    "bqp": "bqp-Latn-NG",
    "bqq": "bqq-Latn-ID",
    "bqr": "bqr-Latn-ID",
    "bqs": "bqs-Latn-PG",
    "bqt": "bqt-Latn-CM",
    "bqu": "bqu-Latn-CD",
    "bqv": "bqv-Latn-CI",
    "bqw": "bqw-Latn-NG",
    "bqx": "bqx-Latn-NG",
    "bqz": "bqz-Latn-CM",
    "br": "br-Latn-FR",
    "bra": "bra-Deva-IN",
    "brb": "brb-Khmr-KH",
    "brc": "brc-Latn-GY",
    "brd": "brd-Deva-NP",
    "brf": "brf-Latn-CD",
    "brg": "brg-Latn-BO",
    "brh": "brh-Arab-PK",
    "bri": "bri-Latn-CM",
    "brj": "brj-Latn-VU",
    "brk": "brk-Arab-SD",
    "brl": "brl-Latn-BW",
    "brm": "brm-Latn-CD",
    "brn": "brn-Latn-CR",
    "bro": "bro-Tibt-BT",
    "brp": "brp-Latn-ID",
    "brq": "brq-Latn-PG",
    "brr": "brr-Latn-SB",
    "brs": "brs-Latn-ID",
    "brt": "brt-Latn-NG",
    "bru": "bru-Latn-VN",
    "brv": "brv-Laoo-LA",
    "brw": "brw-Knda-IN",
    "brx": "brx-Deva-IN",
    "bry": "bry-Latn-PG",
    "brz": "brz-Latn-PG",
    "bs": "bs-Latn-BA",
    "bsa": "bsa-Latn-ID",
    "bsb": "bsb-Latn-BN",
    "bsc": "bsc-Latn-SN",
    "bse": "bse-Latn-CM",
    "bsf": "bsf-Latn-NG",
    "bsh": "bsh-Arab-AF",
    "bsi": "bsi-Latn-CM",
    "bsj": "bsj-Latn-NG",
    "bsk": "bsk-Arab-PK",
    "bsl": "bsl-Latn-NG",
    "bsm": "bsm-Latn-ID",
    "bsn": "bsn-Latn-CO",
    "bso": "bso-Latn-TD",
    "bsp": "bsp-Latn-GN",
    "bsq": "bsq-Bass-LR",
    "bsr": "bsr-Latn-NG",
    "bss": "bss-Latn-CM",
    "bst": "bst-Ethi-ET",
    "bsu": "bsu-Latn-ID",
    "bsv": "bsv-Latn-GN",
    "bsw": "bsw-Latn-ET",
    "bsx": "bsx-Latn-NG",
    "bsy": "bsy-Latn-MY",
    "bta": "bta-Latn-NG",
    "btc": "btc-Latn-CM",
    "btd": "btd-Batk-ID",
    "bte": "bte-Latn-NG",
    "btf": "btf-Latn-TD",
    "btg": "btg-Latn-CI",
    "bth": "bth-Latn-MY",
    "bti": "bti-Latn-ID",
    "btj": "btj-Latn-ID",
    "btm": "btm-Batk-ID",
    "btn": "btn-Latn-PH",
    "bto": "bto-Latn-PH",
    "btp": "btp-Latn-PG",
    "btq": "btq-Latn-MY",
    "btr": "btr-Latn-VU",
    "bts": "bts-Latn-ID",
    "btt": "btt-Latn-NG",
    "btu": "btu-Latn-NG",
    "btv": "btv-Deva-PK",
    "btw": "btw-Latn-PH",
    "btx": "btx-Latn-ID",
    "bty": "bty-Latn-ID",
    "btz": "btz-Latn-ID",
    "bua": "bua-Cyrl-RU",
    "bub": "bub-Latn-TD",
    "buc": "buc-Latn-YT",
    "bud": "bud-Latn-TG",
    "bue": "bue-Latn-CA",
    "buf": "buf-Latn-CD",
    "bug": "bug-Latn-ID",
    "buh": "buh-Latn-CN",
    "bui": "bui-Latn-CG",
    "buj": "buj-Latn-NG",
    "buk": "buk-Latn-PG",
    "bum": "bum-Latn-CM",
    "bun": "bun-Latn-SL",
    "buo": "buo-Latn-PG",
    "bup": "bup-Latn-ID",
    "buq": "buq-Latn-PG",
    "bus": "bus-Latn-NG",
    "but": "but-Latn-PG",
    "buu": "buu-Latn-CD",
    "buv": "buv-Latn-PG",
    "buw": "buw-Latn-GA",
    "bux": "bux-Latn-NG",
    "buy": "buy-Latn-SL",
    "buz": "buz-Latn-NG",
    "bva": "bva-Latn-TD",
    "bvb": "bvb-Latn-GQ",
    "bvc": "bvc-Latn-SB",
    "bvd": "bvd-Latn-SB",
    "bve": "bve-Latn-ID",
    "bvf": "bvf-Latn-TD",
    "bvg": "bvg-Latn-CM",
    "bvh": "bvh-Latn-NG",
    "bvi": "bvi-Latn-SS",
    "bvj": "bvj-Latn-NG",
    "bvk": "bvk-Latn-ID",
    "bvm": "bvm-Latn-CM",
    "bvn": "bvn-Latn-PG",
    "bvo": "bvo-Latn-TD",
    "bvq": "bvq-Latn-CF",
    "bvr": "bvr-Latn-AU",
    "bvt": "bvt-Latn-ID",
    "bvu": "bvu-Latn-ID",
    "bvv": "bvv-Latn-VE",
    "bvw": "bvw-Latn-NG",
    "bvx": "bvx-Latn-CG",
    "bvy": "bvy-Latn-PH",
    "bvz": "bvz-Latn-ID",
    "bwa": "bwa-Latn-NC",
    "bwb": "bwb-Latn-FJ",
    "bwc": "bwc-Latn-ZM",
    "bwd": "bwd-Latn-PG",
    "bwe": "bwe-Mymr-MM",
    "bwf": "bwf-Latn-PG",
    "bwg": "bwg-Latn-MZ",
    "bwh": "bwh-Latn-CM",
    "bwi": "bwi-Latn-VE",
    "bwj": "bwj-Latn-BF",
    "bwk": "bwk-Latn-PG",
    "bwl": "bwl-Latn-CD",
    "bwm": "bwm-Latn-PG",
    "bwo": "bwo-Latn-ET",
    "bwp": "bwp-Latn-ID",
    "bwq": "bwq-Latn-BF",
    "bwr": "bwr-Latn-NG",
    "bws": "bws-Latn-CD",
    "bwt": "bwt-Latn-CM",
    "bwu": "bwu-Latn-GH",
    "bww": "bww-Latn-CD",
    "bwx": "bwx-Latn-CN",
    "bwy": "bwy-Latn-BF",
    "bwz": "bwz-Latn-CG",
    "bxa": "bxa-Latn-SB",
    "bxb": "bxb-Latn-SS",
    "bxc": "bxc-Latn-GQ",
    "bxf": "bxf-Latn-PG",
    "bxg": "bxg-Latn-CD",
    "bxh": "bxh-Latn-PG",
    "bxi": "bxi-Latn-AU",
    "bxj": "bxj-Latn-AU",
    "bxl": "bxl-Latn-BF",
    "bxm": "bxm-Cyrl-MN",
    "bxn": "bxn-Latn-AU",
    "bxo": "bxo-Latn-NG",
    "bxp": "bxp-Latn-CM",
    "bxq": "bxq-Latn-NG",
    "bxs": "bxs-Latn-CM",
    "bxu": "bxu-Mong-CN",
    "bxv": "bxv-Latn-TD",
    "bxw": "bxw-Latn-ML",
    "bxz": "bxz-Latn-PG",
    "bya": "bya-Latn-PH",
    "byb": "byb-Latn-CM",
    "byc": "byc-Latn-NG",
    "byd": "byd-Latn-ID",
    "bye": "bye-Latn-PG",
    "byf": "byf-Latn-NG",
    "byh": "byh-Deva-NP",
    "byi": "byi-Latn-CD",
    "byj": "byj-Latn-NG",
    "byk": "byk-Latn-CN",
    "byl": "byl-Latn-ID",
    "bym": "bym-Latn-AU",
    "byn": "byn-Ethi-ER",
    "byp": "byp-Latn-NG",
    "byr": "byr-Latn-PG",
    "bys": "bys-Latn-NG",
    "byv": "byv-Latn-CM",
    "byw": "byw-Deva-NP",
    "byx": "byx-Latn-PG",
    "byz": "byz-Latn-PG",
    "bza": "bza-Latn-LR",
    "bzb": "bzb-Latn-ID",
    "bzc": "bzc-Latn-MG",
    "bzd": "bzd-Latn-CR",
    "bze": "bze-Latn-ML",
    "bzf": "bzf-Latn-PG",
    "bzh": "bzh-Latn-PG",
    "bzi": "bzi-Thai-TH",
    "bzj": "bzj-Latn-BZ",
    "bzk": "bzk-Latn-NI",
    "bzl": "bzl-Latn-ID",
    "bzm": "bzm-Latn-CD",
    "bzn": "bzn-Latn-ID",
    "bzo": "bzo-Latn-CD",
    "bzp": "bzp-Latn-ID",
    "bzq": "bzq-Latn-ID",
    "bzr": "bzr-Latn-AU",
    "bzt": "bzt-Latn-001",
    "bzu": "bzu-Latn-ID",
    "bzv": "bzv-Latn-CM",
    "bzw": "bzw-Latn-NG",
    "bzx": "bzx-Latn-ML",
    "bzy": "bzy-Latn-NG",
    "bzz": "bzz-Latn-NG",
    "ca": "ca-Latn-ES",
    "caa": "caa-Latn-GT",
    "cab": "cab-Latn-HN",
    "cac": "cac-Latn-GT",
    "cad": "cad-Latn-US",
    "cae": "cae-Latn-SN",
    "caf": "caf-Latn-CA",
    "cag": "cag-Latn-PY",
    "cah": "cah-Latn-PE",
    "caj": "caj-Latn-BO",
    "cak": "cak-Latn-GT",
    "cal": "cal-Latn-MP",
    "cam": "cam-Latn-NC",
    "can": "can-Latn-PG",
    "cao": "cao-Latn-BO",
    "cap": "cap-Latn-BO",
    "caq": "caq-Latn-IN",
    "car": "car-Latn-VE",
    "cas": "cas-Latn-BO",
    "cav": "cav-Latn-BO",
    "caw": "caw-Latn-BO",
    "cax": "cax-Latn-BO",
    "cay": "cay-Latn-CA",
    "caz": "caz-Latn-BO",
    "cbb": "cbb-Latn-CO",
    "cbc": "cbc-Latn-CO",
    "cbd": "cbd-Latn-CO",
    "cbg": "cbg-Latn-CO",
    "cbi": "cbi-Latn-EC",
    "cbj": "cbj-Latn-BJ",
    "cbk": "cbk-Latn-PH",
    "cbl": "cbl-Latn-MM",
    "cbn": "cbn-Thai-TH",
    "cbo": "cbo-Latn-NG",
    "cbq": "cbq-Latn-NG",
    "cbr": "cbr-Latn-PE",
    "cbs": "cbs-Latn-PE",
    "cbt": "cbt-Latn-PE",
    "cbu": "cbu-Latn-PE",
    "cbv": "cbv-Latn-CO",
    "cbw": "cbw-Latn-PH",
    "cby": "cby-Latn-CO",
    "ccc": "ccc-Latn-PE",
    "ccd": "ccd-Latn-BR",
    "cce": "cce-Latn-MZ",
    "ccg": "ccg-Latn-NG",
    "cch": "cch-Latn-NG",
    "ccj": "ccj-Latn-GW",
    "ccl": "ccl-Latn-TZ",
    "ccm": "ccm-Latn-MY",
    "cco": "cco-Latn-MX",
    "ccp": "ccp-Cakm-BD",
    "ccr": "ccr-Latn-SV",
    "cde": "cde-Telu-IN",
    "cdf": "cdf-Latn-IN",
    "cdh": "cdh-Deva-IN",
    "cdi": "cdi-Gujr-IN",
    "cdj": "cdj-Deva-IN",
    "cdm": "cdm-Deva-NP",
    "cdo": "cdo-Hans-CN",
    "cdr": "cdr-Latn-NG",
    "cdz": "cdz-Beng-IN",
    "ce": "ce-Cyrl-RU",
    "cea": "cea-Latn-US",
    "ceb": "ceb-Latn-PH",
    "ceg": "ceg-Latn-PY",
    "cek": "cek-Latn-MM",
    "cen": "cen-Latn-NG",
    "cet": "cet-Latn-NG",
    "cey": "cey-Latn-MM",
    "cfa": "cfa-Latn-NG",
    "cfd": "cfd-Latn-NG",
    "cfg": "cfg-Latn-NG",
    "cfm": "cfm-Latn-MM",
    "cga": "cga-Latn-PG",
    "cgc": "cgc-Latn-PH",
    "cgg": "cgg-Latn-UG",
    "cgk": "cgk-Tibt-BT",
    "ch": "ch-Latn-GU",
    "chb": "chb-Latn-CO",
    "chd": "chd-Latn-MX",
    "chf": "chf-Latn-MX",
    "chg": "chg-Arab-TM",
    "chh": "chh-Latn-US",
    "chj": "chj-Latn-MX",
    "chk": "chk-Latn-FM",
    "chl": "chl-Latn-US",
    "chm": "chm-Cyrl-RU",
    "chn": "chn-Latn-US",
    "cho": "cho-Latn-US",
    "chp": "chp-Latn-CA",
    "chq": "chq-Latn-MX",
    "chr": "chr-Cher-US",
    "cht": "cht-Latn-PE",
    "chw": "chw-Latn-MZ",
    "chx": "chx-Deva-NP",
    "chy": "chy-Latn-US",
    "chz": "chz-Latn-MX",
    "cia": "cia-Latn-ID",
    "cib": "cib-Latn-BJ",
    "cic": "cic-Latn-US",
    "cie": "cie-Latn-NG",
    "cih": "cih-Deva-IN",
    "cim": "cim-Latn-IT",
    "cin": "cin-Latn-BR",
    "cip": "cip-Latn-MX",
    "cir": "cir-Latn-NC",
    "ciw": "ciw-Latn-US",
    "ciy": "ciy-Latn-VE",
    "cja": "cja-Arab-KH",
    "cje": "cje-Latn-VN",
    "cjh": "cjh-Latn-US",
    "cji": "cji-Cyrl-RU",
    "cjk": "cjk-Latn-AO",
    "cjm": "cjm-Cham-VN",
    "cjn": "cjn-Latn-PG",
    "cjo": "cjo-Latn-PE",
    "cjp": "cjp-Latn-CR",
    "cjs": "cjs-Latn-RU",
    "cjv": "cjv-Latn-PG",
    "cjy": "cjy-Hans-CN",
    "ckb": "ckb-Arab-IQ",
    "ckl": "ckl-Latn-NG",
    "ckm": "ckm-Latn-HR",
    "ckn": "ckn-Latn-MM",
    "cko": "cko-Latn-GH",
    "ckq": "ckq-Latn-TD",
    "ckr": "ckr-Latn-PG",
    "cks": "cks-Latn-NC",
    "ckt": "ckt-Cyrl-RU",
    "cku": "cku-Latn-US",
    "ckv": "ckv-Latn-TW",
    "ckx": "ckx-Latn-CM",
    "cky": "cky-Latn-NG",
    "ckz": "ckz-Latn-GT",
    "cla": "cla-Latn-NG",
    "clc": "clc-Latn-CA",
    "cle": "cle-Latn-MX",
    "clh": "clh-Arab-PK",
    "cli": "cli-Latn-GH",
    "clj": "clj-Latn-MM",
    "clk": "clk-Latn-IN",
    "cll": "cll-Latn-GH",
    "clm": "clm-Latn-US",
    "clo": "clo-Latn-MX",
    "clt": "clt-Latn-MM",
    "clu": "clu-Latn-PH",
    "clw": "clw-Cyrl-RU",
    "cly": "cly-Latn-MX",
    "cma": "cma-Latn-VN",
    "cme": "cme-Latn-BF",
    "cmg": "cmg-Soyo-MN",
    "cmi": "cmi-Latn-CO",
    "cml": "cml-Latn-ID",
    "cmo": "cmo-Latn-VN",
    "cmr": "cmr-Latn-MM",
    "cms": "cms-Latn-IT",
    "cmt": "cmt-Latn-ZA",
    "cna": "cna-Tibt-IN",
    "cnb": "cnb-Latn-MM",
    "cnc": "cnc-Latn-VN",
    "cng": "cng-Latn-CN",
    "cnh": "cnh-Latn-MM",
    "cni": "cni-Latn-PE",
    "cnk": "cnk-Latn-MM",
    "cnl": "cnl-Latn-MX",
    "cnp": "cnp-Hans-CN",
    "cnq": "cnq-Latn-CM",
    "cns": "cns-Latn-ID",
    "cnt": "cnt-Latn-MX",
    "cnw": "cnw-Latn-MM",
    "cnx": "cnx-Latn-GB",
    "co": "co-Latn-FR",
    "coa": "coa-Latn-AU",
    "cob": "cob-Latn-MX",
    "coc": "coc-Latn-MX",
    "cod": "cod-Latn-PE",
    "coe": "coe-Latn-CO",
    "cof": "cof-Latn-EC",
    "cog": "cog-Thai-TH",
    "coh": "coh-Latn-KE",
    "coj": "coj-Latn-MX",
    "cok": "cok-Latn-MX",
    "col": "col-Latn-US",
    "com": "com-Latn-US",
    "coo": "coo-Latn-CA",
    "cop": "cop-Copt-EG",
    "coq": "coq-Latn-US",
    "cot": "cot-Latn-PE",
    "cou": "cou-Latn-SN",
    "cox": "cox-Latn-PE",
    "coz": "coz-Latn-MX",
    "cpa": "cpa-Latn-MX",
    "cpb": "cpb-Latn-PE",
    "cpc": "cpc-Latn-PE",
    "cpg": "cpg-Grek-GR",
    "cpi": "cpi-Latn-NR",
    "cpn": "cpn-Latn-GH",
    "cpo": "cpo-Latn-BF",
    "cps": "cps-Latn-PH",
    "cpu": "cpu-Latn-PE",
    "cpx": "cpx-Latn-CN",
    "cpy": "cpy-Latn-PE",
    "cqd": "cqd-Latn-CN",
    "cr": "cr-Cans-CA",
    "cra": "cra-Latn-ET",
    "crb": "crb-Latn-VC",
    "crc": "crc-Latn-VU",
    "crd": "crd-Latn-US",
    "crf": "crf-Latn-CO",
    "crg": "crg-Latn-CA",
    "crh": "crh-Cyrl-UA",
    "cri": "cri-Latn-ST",
    "crj": "crj-Cans-CA",
    "crk": "crk-Cans-CA",
    "crl": "crl-Cans-CA",
    "crm": "crm-Cans-CA",
    "crn": "crn-Latn-MX",
    "cro": "cro-Latn-US",
    "crq": "crq-Latn-AR",
    "crs": "crs-Latn-SC",
    "crt": "crt-Latn-AR",
    "crv": "crv-Latn-IN",
    "crw": "crw-Latn-VN",
    "crx": "crx-Latn-CA",
    "cry": "cry-Latn-NG",
    "crz": "crz-Latn-US",
    "cs": "cs-Latn-CZ",
    "csa": "csa-Latn-MX",
    "csb": "csb-Latn-PL",
    "csh": "csh-Mymr-MM",
    "csj": "csj-Latn-MM",
    "csk": "csk-Latn-SN",
    "csm": "csm-Latn-US",
    "cso": "cso-Latn-MX",
    "csp": "csp-Hans-CN",
    "css": "css-Latn-US",
    "cst": "cst-Latn-US",
    "csv": "csv-Latn-MM",
    "csw": "csw-Cans-CA",
    "csy": "csy-Latn-MM",
    "csz": "csz-Latn-US",
    "cta": "cta-Latn-MX",
    "ctc": "ctc-Latn-US",
    "ctd": "ctd-Pauc-MM",
    "cte": "cte-Latn-MX",
    "ctg": "ctg-Beng-BD",
    "cth": "cth-Latn-MM",
    "ctl": "ctl-Latn-MX",
    "ctm": "ctm-Latn-US",
    "ctn": "ctn-Deva-NP",
    "cto": "cto-Latn-CO",
    "ctp": "ctp-Latn-MX",
    "cts": "cts-Latn-PH",
    "ctt": "ctt-Taml-IN",
    "ctu": "ctu-Latn-MX",
    "cty": "cty-Taml-IN",
    "ctz": "ctz-Latn-MX",
    "cu": "cu-Cyrl-RU",
    "cu-Glag": "cu-Glag-BG",
    "cua": "cua-Latn-VN",
    "cub": "cub-Latn-CO",
    "cuc": "cuc-Latn-MX",
    "cuh": "cuh-Latn-KE",
    "cui": "cui-Latn-CO",
    "cuj": "cuj-Latn-PE",
    "cuk": "cuk-Latn-PA",
    "cul": "cul-Latn-BR",
    "cuo": "cuo-Latn-VE",
    "cup": "cup-Latn-US",
    "cut": "cut-Latn-MX",
    "cuu": "cuu-Lana-CN",
    "cuv": "cuv-Latn-CM",
    "cux": "cux-Latn-MX",
    "cuy": "cuy-Latn-MX",
    "cv": "cv-Cyrl-RU",
    "cvg": "cvg-Latn-IN",
    "cvn": "cvn-Latn-MX",
    "cwa": "cwa-Latn-TZ",
    "cwb": "cwb-Latn-MZ",
    "cwe": "cwe-Latn-TZ",
    "cwg": "cwg-Latn-MY",
    "cwt": "cwt-Latn-SN",
    "cxh": "cxh-Latn-NG",
    "cy": "cy-Latn-GB",
    "cya": "cya-Latn-MX",
    "cyb": "cyb-Latn-BO",
    "cyo": "cyo-Latn-PH",
    "czh": "czh-Hans-CN",
    "czk": "czk-Hebr-CZ",
    "czn": "czn-Latn-MX",
    "czt": "czt-Latn-MM",
    "da": "da-Latn-DK",
    "daa": "daa-Latn-TD",
    "dac": "dac-Latn-PG",
    "dad": "dad-Latn-PG",
    "dae": "dae-Latn-CM",
    "dag": "dag-Latn-GH",
    "dah": "dah-Latn-PG",
    "dai": "dai-Latn-TD",
    "daj": "daj-Latn-SD",
    "dak": "dak-Latn-US",
    "dal": "dal-Latn-KE",
    "dam": "dam-Latn-NG",
    "dao": "dao-Latn-MM",
    "daq": "daq-Deva-IN",
    "dar": "dar-Cyrl-RU",
    "das": "das-Latn-CI",
    "dau": "dau-Latn-TD",
    "dav": "dav-Latn-KE",
    "daw": "daw-Latn-PH",
    "dax": "dax-Latn-AU",
    "daz": "daz-Latn-ID",
    "dba": "dba-Latn-ML",
    "dbb": "dbb-Latn-NG",
    "dbd": "dbd-Latn-NG",
    "dbe": "dbe-Latn-ID",
    "dbf": "dbf-Latn-ID",
    "dbg": "dbg-Latn-ML",
    "dbi": "dbi-Latn-NG",
    "dbj": "dbj-Latn-MY",
    "dbl": "dbl-Latn-AU",
    "dbm": "dbm-Latn-NG",
    "dbn": "dbn-Latn-ID",
    "dbo": "dbo-Latn-NG",
    "dbp": "dbp-Latn-NG",
    "dbq": "dbq-Latn-CM",
    "dbt": "dbt-Latn-ML",
    "dbu": "dbu-Latn-ML",
    "dbv": "dbv-Latn-NG",
    "dbw": "dbw-Latn-ML",
    "dby": "dby-Latn-PG",
    "dcc": "dcc-Arab-IN",
    "dcr": "dcr-Latn-VI",
    "dda": "dda-Latn-AU",
    "ddd": "ddd-Latn-SS",
    "dde": "dde-Latn-CG",
    "ddg": "ddg-Latn-TL",
    "ddi": "ddi-Latn-PG",
    "ddj": "ddj-Latn-AU",
    "ddn": "ddn-Latn-BJ",
    "ddo": "ddo-Cyrl-RU",
    "ddr": "ddr-Latn-AU",
    "dds": "dds-Latn-ML",
    "ddw": "ddw-Latn-ID",
    "de": "de-Latn-DE",
    "dec": "dec-Latn-SD",
    "ded": "ded-Latn-PG",
    "dee": "dee-Latn-LR",
    "def": "def-Arab-IR",
    "deg": "deg-Latn-NG",
    "deh": "deh-Arab-PK",
    "dei": "dei-Latn-ID",
    "dek": "dek-Latn-CM",
    "del": "del-Latn-US",
    "dem": "dem-Latn-ID",
    "den": "den-Latn-CA",
    "deq": "deq-Latn-CF",
    "der": "der-Beng-IN",
    "des": "des-Latn-BR",
    "dev": "dev-Latn-PG",
    "dez": "dez-Latn-CD",
    "dga": "dga-Latn-GH",
    "dgb": "dgb-Latn-ML",
    "dgc": "dgc-Latn-PH",
    "dgd": "dgd-Latn-BF",
    "dge": "dge-Latn-PG",
    "dgg": "dgg-Latn-PG",
    "dgh": "dgh-Latn-NG",
    "dgi": "dgi-Latn-BF",
    "dgk": "dgk-Latn-CF",
    "dgl": "dgl-Arab-SD",
    "dgn": "dgn-Latn-AU",
    "dgr": "dgr-Latn-CA",
    "dgs": "dgs-Latn-BF",
    "dgt": "dgt-Latn-AU",
    "dgw": "dgw-Latn-AU",
    "dgx": "dgx-Latn-PG",
    "dgz": "dgz-Latn-PG",
    "dhg": "dhg-Latn-AU",
    "dhi": "dhi-Deva-NP",
    "dhl": "dhl-Latn-AU",
    "dhm": "dhm-Latn-AO",
    "dhn": "dhn-Gujr-IN",
    "dho": "dho-Deva-IN",
    "dhr": "dhr-Latn-AU",
    "dhs": "dhs-Latn-TZ",
    "dhu": "dhu-Latn-AU",
    "dhv": "dhv-Latn-NC",
    "dhw": "dhw-Deva-NP",
    "dhx": "dhx-Latn-AU",
    "dia": "dia-Latn-PG",
    "dib": "dib-Latn-SS",
    "dic": "dic-Latn-CI",
    "did": "did-Latn-SS",
    "dif": "dif-Latn-AU",
    "dig": "dig-Latn-KE",
    "dih": "dih-Latn-MX",
    "dii": "dii-Latn-CM",
    "dij": "dij-Latn-ID",
    "dil": "dil-Latn-SD",
    "din": "din-Latn-SS",
    "dio": "dio-Latn-NG",
    "dip": "dip-Latn-SS",
    "dir": "dir-Latn-NG",
    "dis": "dis-Latn-IN",
    "diu": "diu-Latn-NA",
    "diw": "diw-Latn-SS",
    "dix": "dix-Latn-VU",
    "diy": "diy-Latn-ID",
    "diz": "diz-Latn-CD",
    "dja": "dja-Latn-AU",
    "djb": "djb-Latn-AU",
    "djc": "djc-Latn-TD",
    "djd": "djd-Latn-AU",
    "dje": "dje-Latn-NE",
    "djf": "djf-Latn-AU",
    "dji": "dji-Latn-AU",
    "djj": "djj-Latn-AU",
    "djk": "djk-Latn-SR",
    "djm": "djm-Latn-ML",
    "djn": "djn-Latn-AU",
    "djo": "djo-Latn-ID",
    "djr": "djr-Latn-AU",
    "dju": "dju-Latn-PG",
    "djw": "djw-Latn-AU",
    "dka": "dka-Tibt-BT",
    "dkg": "dkg-Latn-NG",
    "dkk": "dkk-Latn-ID",
    "dkr": "dkr-Latn-MY",
    "dks": "dks-Latn-SS",
    "dkx": "dkx-Latn-CM",
    "dlg": "dlg-Cyrl-RU",
    "dlm": "dlm-Latn-HR",
    "dln": "dln-Latn-IN",
    "dma": "dma-Latn-GA",
    "dmb": "dmb-Latn-ML",
    "dmc": "dmc-Latn-PG",
    "dmd": "dmd-Latn-AU",
    "dme": "dme-Latn-CM",
    "dmf": "dmf-Medf-NG",
    "dmg": "dmg-Latn-MY",
    "dmk": "dmk-Arab-PK",
    "dml": "dml-Arab-PK",
    "dmm": "dmm-Latn-CM",
    "dmo": "dmo-Latn-CM",
    "dmr": "dmr-Latn-ID",
    "dms": "dms-Latn-ID",
    "dmu": "dmu-Latn-ID",
    "dmv": "dmv-Latn-MY",
    "dmw": "dmw-Latn-AU",
    "dmx": "dmx-Latn-MZ",
    "dmy": "dmy-Latn-ID",
    "dna": "dna-Latn-ID",
    "dnd": "dnd-Latn-PG",
    "dne": "dne-Latn-TZ",
    "dng": "dng-Cyrl-KG",
    "dni": "dni-Latn-ID",
    "dnj": "dnj-Latn-CI",
    "dnk": "dnk-Latn-ID",
    "dnn": "dnn-Latn-BF",
    "dno": "dno-Latn-CD",
    "dnr": "dnr-Latn-PG",
    "dnt": "dnt-Latn-ID",
    "dnu": "dnu-Mymr-MM",
    "dnv": "dnv-Mymr-MM",
    "dnw": "dnw-Latn-ID",
    "dny": "dny-Latn-BR",
    "doa": "doa-Latn-PG",
    "dob": "dob-Latn-PG",
    "doc": "doc-Latn-CN",
    "doe": "doe-Latn-TZ",
    "dof": "dof-Latn-PG",
    "doh": "doh-Latn-NG",
    "doi": "doi-Deva-IN",
    "dok": "dok-Latn-ID",
    "dol": "dol-Latn-PG",
    "don": "don-Latn-PG",
    "doo": "doo-Latn-CD",
    "dop": "dop-Latn-BJ",
    "dor": "dor-Latn-SB",
    "dos": "dos-Latn-BF",
    "dot": "dot-Latn-NG",
    "dov": "dov-Latn-ZW",
    "dow": "dow-Latn-CM",
    "dox": "dox-Ethi-ET",
    "doy": "doy-Latn-GH",
    "dpp": "dpp-Latn-MY",
    "drc": "drc-Latn-PT",
    "dre": "dre-Tibt-NP",
    "drg": "drg-Latn-MY",
    "dri": "dri-Latn-NG",
    "drl": "drl-Latn-AU",
    "drn": "drn-Latn-ID",
    "dro": "dro-Latn-MY",
    "drq": "drq-Deva-NP",
    "drs": "drs-Ethi-ET",
    "drt": "drt-Latn-NL",
    "dru": "dru-Latn-TW",
    "dry": "dry-Deva-NP",
    "dsb": "dsb-Latn-DE",
    "dsh": "dsh-Latn-KE",
    "dsi": "dsi-Latn-TD",
    "dsk": "dsk-Latn-NG",
    "dsn": "dsn-Latn-ID",
    "dso": "dso-Orya-IN",
    "dsq": "dsq-Latn-ML",
    "dta": "dta-Latn-CN",
    "dtb": "dtb-Latn-MY",
    "dtd": "dtd-Latn-CA",
    "dth": "dth-Latn-AU",
    "dti": "dti-Latn-ML",
    "dtk": "dtk-Latn-ML",
    "dtm": "dtm-Latn-ML",
    "dto": "dto-Latn-ML",
    "dtp": "dtp-Latn-MY",
    "dtr": "dtr-Latn-MY",
    "dts": "dts-Latn-ML",
    "dtt": "dtt-Latn-ML",
    "dtu": "dtu-Latn-ML",
    "dty": "dty-Deva-NP",
    "dua": "dua-Latn-CM",
    "dub": "dub-Gujr-IN",
    "duc": "duc-Latn-PG",
    "due": "due-Latn-PH",
    "duf": "duf-Latn-NC",
    "dug": "dug-Latn-KE",
    "duh": "duh-Deva-IN",
    "dui": "dui-Latn-PG",
    "duk": "duk-Latn-PG",
    "dul": "dul-Latn-PH",
    "dum": "dum-Latn-NL",
    "dun": "dun-Latn-ID",
    "duo": "duo-Latn-PH",
    "dup": "dup-Latn-ID",
    "duq": "duq-Latn-ID",
    "dur": "dur-Latn-CM",
    "dus": "dus-Deva-NP",
    "duu": "duu-Latn-CN",
    "duv": "duv-Latn-ID",
    "duw": "duw-Latn-ID",
    "dux": "dux-Latn-ML",
    "duy": "duy-Latn-PH",
    "duz": "duz-Latn-CM",
    "dv": "dv-Thaa-MV",
    "dva": "dva-Latn-PG",
    "dwa": "dwa-Latn-NG",
    "dwk": "dwk-Orya-IN",
    "dwr": "dwr-Latn-ET",
    "dws": "dws-Latn-001",
    "dwu": "dwu-Latn-AU",
    "dww": "dww-Latn-PG",
    "dwy": "dwy-Latn-AU",
    "dwz": "dwz-Deva-NP",
    "dya": "dya-Latn-BF",
    "dyb": "dyb-Latn-AU",
    "dyd": "dyd-Latn-AU",
    "dyg": "dyg-Latn-PH",
    "dyi": "dyi-Latn-CI",
    "dym": "dym-Latn-ML",
    "dyn": "dyn-Latn-AU",
    "dyo": "dyo-Latn-SN",
    "dyr": "dyr-Latn-NG",
    "dyu": "dyu-Latn-BF",
    "dyy": "dyy-Latn-AU",
    "dz": "dz-Tibt-BT",
    "dza": "dza-Latn-NG",
    "dzd": "dzd-Latn-NG",
    "dze": "dze-Latn-AU",
    "dzg": "dzg-Latn-TD",
    "dzl": "dzl-Tibt-BT",
    "dzn": "dzn-Latn-CD",
    "eaa": "eaa-Latn-AU",
    "ebc": "ebc-Latn-ID",
    "ebg": "ebg-Latn-NG",
    "ebk": "ebk-Latn-PH",
    "ebo": "ebo-Latn-CG",
    "ebr": "ebr-Latn-CI",
    "ebu": "ebu-Latn-KE",
    "ecr": "ecr-Grek-GR",
    "ecy": "ecy-Cprt-CY",
    "ee": "ee-Latn-GH",
    "efa": "efa-Latn-NG",
    "efe": "efe-Latn-CD",
    "efi": "efi-Latn-NG",
    "ega": "ega-Latn-CI",
    "egl": "egl-Latn-IT",
    "egm": "egm-Latn-TZ",
    "ego": "ego-Latn-NG",
    "egy": "egy-Egyp-EG",
    "ehu": "ehu-Latn-NG",
    "eip": "eip-Latn-ID",
    "eit": "eit-Latn-PG",
    "eiv": "eiv-Latn-PG",
    "eja": "eja-Latn-GW",
    "eka": "eka-Latn-NG",
    "eke": "eke-Latn-NG",
    "ekg": "ekg-Latn-ID",
    "eki": "eki-Latn-NG",
    "ekl": "ekl-Latn-BD",
    "ekm": "ekm-Latn-CM",
    "eko": "eko-Latn-MZ",
    "ekp": "ekp-Latn-NG",
    "ekr": "ekr-Latn-NG",
    "eky": "eky-Kali-MM",
    "el": "el-Grek-GR",
    "ele": "ele-Latn-PG",
    "elk": "elk-Latn-PG",
    "elm": "elm-Latn-NG",
    "elo": "elo-Latn-KE",
    "elu": "elu-Latn-PG",
    "ema": "ema-Latn-NG",
    "emb": "emb-Latn-ID",
    "eme": "eme-Latn-GF",
    "emg": "emg-Deva-NP",
    "emi": "emi-Latn-PG",
    "emm": "emm-Latn-MX",
    "emn": "emn-Latn-CM",
    "emp": "emp-Latn-PA",
    "ems": "ems-Latn-US",
    "emu": "emu-Deva-IN",
    "emw": "emw-Latn-ID",
    "emx": "emx-Latn-FR",
    "emz": "emz-Latn-CM",
    "en": "en-Latn-US",
    "en-Shaw": "en-Shaw-GB",
    "ena": "ena-Latn-PG",
    "enb": "enb-Latn-KE",
    "enc": "enc-Latn-VN",
    "end": "end-Latn-ID",
    "enf": "enf-Cyrl-RU",
    "enh": "enh-Cyrl-RU",
    "enl": "enl-Latn-PY",
    "enm": "enm-Latn-GB",
    "enn": "enn-Latn-NG",
    "eno": "eno-Latn-ID",
    "enq": "enq-Latn-PG",
    "enr": "enr-Latn-ID",
    "env": "env-Latn-NG",
    "enw": "enw-Latn-NG",
    "enx": "enx-Latn-PY",
    "eo": "eo-Latn-001",
    "eot": "eot-Latn-CI",
    "epi": "epi-Latn-NG",
    "era": "era-Taml-IN",
    "erg": "erg-Latn-VU",
    "erh": "erh-Latn-NG",
    "eri": "eri-Latn-PG",
    "erk": "erk-Latn-VU",
    "err": "err-Latn-AU",
    "ers": "ers-Latn-CN",
    "ert": "ert-Latn-ID",
    "erw": "erw-Latn-ID",
    "es": "es-Latn-ES",
    "ese": "ese-Latn-BO",
    "esg": "esg-Gonm-IN",
    "esh": "esh-Arab-IR",
    "esi": "esi-Latn-US",
    "esm": "esm-Latn-CI",
    "ess": "ess-Latn-US",
    "esu": "esu-Latn-US",
    "esy": "esy-Latn-PH",
    "et": "et-Latn-EE",
    "etb": "etb-Latn-NG",
    "etn": "etn-Latn-VU",
    "eto": "eto-Latn-CM",
    "etr": "etr-Latn-PG",
    "ets": "ets-Latn-NG",
    "ett": "ett-Ital-IT",
    "etu": "etu-Latn-NG",
    "etx": "etx-Latn-NG",
    "etz": "etz-Latn-ID",
    "eu": "eu-Latn-ES",
    "eud": "eud-Latn-MX",
    "eve": "eve-Cyrl-RU",
    "evh": "evh-Latn-NG",
    "evn": "evn-Cyrl-RU",
    "ewo": "ewo-Latn-CM",
    "ext": "ext-Latn-ES",
    "eya": "eya-Latn-US",
    "eyo": "eyo-Latn-KE",
    "eza": "eza-Latn-NG",
    "eze": "eze-Latn-NG",
    "fa": "fa-Arab-IR",
    "faa": "faa-Latn-PG",
    "fab": "fab-Latn-GQ",
    "fad": "fad-Latn-PG",
    "faf": "faf-Latn-SB",
    "fag": "fag-Latn-PG",
    "fah": "fah-Latn-NG",
    "fai": "fai-Latn-PG",
    "faj": "faj-Latn-PG",
    "fak": "fak-Latn-CM",
    "fal": "fal-Latn-CM",
    "fam": "fam-Latn-NG",
    "fan": "fan-Latn-GQ",
    "fap": "fap-Latn-SN",
    "far": "far-Latn-SB",
    "fau": "fau-Latn-ID",
    "fax": "fax-Latn-ES",
    "fay": "fay-Arab-IR",
    "faz": "faz-Arab-IR",
    "fbl": "fbl-Latn-PH",
    "fer": "fer-Latn-SS",
    "ff": "ff-Latn-SN",
    "ff-Adlm": "ff-Adlm-GN",
    "ffi": "ffi-Latn-PG",
    "ffm": "ffm-Latn-ML",
    "fgr": "fgr-Latn-TD",
    "fi": "fi-Latn-FI",
    "fia": "fia-Arab-SD",
    "fie": "fie-Latn-NG",
    "fif": "fif-Latn-SA",
    "fil": "fil-Latn-PH",
    "fip": "fip-Latn-TZ",
    "fir": "fir-Latn-NG",
    "fit": "fit-Latn-SE",
    "fiw": "fiw-Latn-PG",
    "fj": "fj-Latn-FJ",
    "fkk": "fkk-Latn-NG",
    "fkv": "fkv-Latn-NO",
    "fla": "fla-Latn-US",
    "flh": "flh-Latn-ID",
    "fli": "fli-Latn-NG",
    "fll": "fll-Latn-CM",
    "fln": "fln-Latn-AU",
    "flr": "flr-Latn-CD",
    "fly": "fly-Latn-ZA",
    "fmp": "fmp-Latn-CM",
    "fmu": "fmu-Deva-IN",
    "fnb": "fnb-Latn-VU",
    "fng": "fng-Latn-ZA",
    "fni": "fni-Latn-TD",
    "fo": "fo-Latn-FO",
    "fod": "fod-Latn-BJ",
    "foi": "foi-Latn-PG",
    "fom": "fom-Latn-CD",
    "fon": "fon-Latn-BJ",
    "for": "for-Latn-PG",
    "fos": "fos-Latn-TW",
    "fpe": "fpe-Latn-GQ",
    "fqs": "fqs-Latn-PG",
    "fr": "fr-Latn-FR",
    "frc": "frc-Latn-US",
    "frd": "frd-Latn-ID",
    "frk": "frk-Latn-DE",
    "frm": "frm-Latn-FR",
    "fro": "fro-Latn-FR",
    "frp": "frp-Latn-FR",
    "frq": "frq-Latn-PG",
    "frr": "frr-Latn-DE",
    "frs": "frs-Latn-DE",
    "frt": "frt-Latn-VU",
    "fub": "fub-Arab-CM",
    "fud": "fud-Latn-WF",
    "fue": "fue-Latn-BJ",
    "fuf": "fuf-Latn-GN",
    "fuh": "fuh-Latn-NE",
    "fui": "fui-Latn-TD",
    "fum": "fum-Latn-NG",
    "fun": "fun-Latn-BR",
    "fuq": "fuq-Latn-NE",
    "fur": "fur-Latn-IT",
    "fut": "fut-Latn-VU",
    "fuu": "fuu-Latn-CD",
    "fuv": "fuv-Latn-NG",
    "fuy": "fuy-Latn-PG",
    "fvr": "fvr-Latn-SD",
    "fwa": "fwa-Latn-NC",
    "fwe": "fwe-Latn-NA",
    "fy": "fy-Latn-NL",
    "ga": "ga-Latn-IE",
    "gaa": "gaa-Latn-GH",
    "gab": "gab-Latn-TD",
    "gac": "gac-Latn-IN",
    "gad": "gad-Latn-PH",
    "gae": "gae-Latn-VE",
    "gaf": "gaf-Latn-PG",
    "gag": "gag-Latn-MD",
    "gah": "gah-Latn-PG",
    "gai": "gai-Latn-PG",
    "gaj": "gaj-Latn-PG",
    "gak": "gak-Latn-ID",
    "gal": "gal-Latn-TL",
    "gam": "gam-Latn-PG",
    "gan": "gan-Hans-CN",
    "gao": "gao-Latn-PG",
    "gap": "gap-Latn-PG",
    "gaq": "gaq-Orya-IN",
    "gar": "gar-Latn-PG",
    "gas": "gas-Gujr-IN",
    "gat": "gat-Latn-PG",
    "gau": "gau-Telu-IN",
    "gaw": "gaw-Latn-PG",
    "gax": "gax-Latn-ET",
    "gay": "gay-Latn-ID",
    "gba": "gba-Latn-CF",
    "gbb": "gbb-Latn-AU",
    "gbd": "gbd-Latn-AU",
    "gbe": "gbe-Latn-PG",
    "gbf": "gbf-Latn-PG",
    "gbg": "gbg-Latn-CF",
    "gbh": "gbh-Latn-BJ",
    "gbi": "gbi-Latn-ID",
    "gbj": "gbj-Orya-IN",
    "gbk": "gbk-Deva-IN",
    "gbl": "gbl-Gujr-IN",
    "gbm": "gbm-Deva-IN",
    "gbn": "gbn-Latn-SS",
    "gbp": "gbp-Latn-CF",
    "gbq": "gbq-Latn-CF",
    "gbr": "gbr-Latn-NG",
    "gbs": "gbs-Latn-BJ",
    "gbu": "gbu-Latn-AU",
    "gbv": "gbv-Latn-CF",
    "gbw": "gbw-Latn-AU",
    "gbx": "gbx-Latn-BJ",
    "gby": "gby-Latn-NG",
    "gbz": "gbz-Arab-IR",
    "gcc": "gcc-Latn-PG",
    "gcd": "gcd-Latn-AU",
    "gcf": "gcf-Latn-GP",
    "gcl": "gcl-Latn-GD",
    "gcn": "gcn-Latn-PG",
    "gcr": "gcr-Latn-GF",
    "gct": "gct-Latn-VE",
    "gd": "gd-Latn-GB",
    "gdb": "gdb-Orya-IN",
    "gdc": "gdc-Latn-AU",
    "gdd": "gdd-Latn-PG",
    "gde": "gde-Latn-NG",
    "gdf": "gdf-Latn-NG",
    "gdg": "gdg-Latn-PH",
    "gdh": "gdh-Latn-AU",
    "gdi": "gdi-Latn-CF",
    "gdj": "gdj-Latn-AU",
    "gdk": "gdk-Latn-TD",
    "gdl": "gdl-Latn-ET",
    "gdm": "gdm-Latn-TD",
    "gdn": "gdn-Latn-PG",
    "gdo": "gdo-Cyrl-RU",
    "gdq": "gdq-Latn-YE",
    "gdr": "gdr-Latn-PG",
    "gdt": "gdt-Latn-AU",
    "gdu": "gdu-Latn-NG",
    "gdx": "gdx-Deva-IN",
    "gea": "gea-Latn-NG",
    "geb": "geb-Latn-PG",
    "gec": "gec-Latn-LR",
    "ged": "ged-Latn-NG",
    "gef": "gef-Latn-ID",
    "geg": "geg-Latn-NG",
    "geh": "geh-Latn-CA",
    "gei": "gei-Latn-ID",
    "gej": "gej-Latn-TG",
    "gek": "gek-Latn-NG",
    "gel": "gel-Latn-NG",
    "geq": "geq-Latn-CF",
    "ges": "ges-Latn-ID",
    "gev": "gev-Latn-GA",
    "gew": "gew-Latn-NG",
    "gex": "gex-Latn-SO",
    "gey": "gey-Latn-CD",
    "gez": "gez-Ethi-ET",
    "gfk": "gfk-Latn-PG",
    "gga": "gga-Latn-SB",
    "ggb": "ggb-Latn-LR",
    "ggd": "ggd-Latn-AU",
    "gge": "gge-Latn-AU",
    "ggg": "ggg-Arab-PK",
    "ggk": "ggk-Latn-AU",
    "ggl": "ggl-Latn-PG",
    "ggt": "ggt-Latn-PG",
    "ggu": "ggu-Latn-CI",
    "ggw": "ggw-Latn-PG",
    "gha": "gha-Arab-LY",
    "ghc": "ghc-Latn-GB",
    "ghe": "ghe-Deva-NP",
    "ghk": "ghk-Latn-MM",
    "ghn": "ghn-Latn-SB",
    "gho": "gho-Tfng-MA",
    "ghr": "ghr-Arab-PK",
    "ghs": "ghs-Latn-PG",
    "ght": "ght-Tibt-NP",
    "gia": "gia-Latn-AU",
    "gib": "gib-Latn-NG",
    "gic": "gic-Latn-ZA",
    "gid": "gid-Latn-CM",
    "gie": "gie-Latn-CI",
    "gig": "gig-Arab-PK",
    "gih": "gih-Latn-AU",
    "gil": "gil-Latn-KI",
    "gim": "gim-Latn-PG",
    "gin": "gin-Cyrl-RU",
    "gip": "gip-Latn-PG",
    "giq": "giq-Latn-VN",
    "gir": "gir-Latn-VN",
    "gis": "gis-Latn-CM",
    "git": "git-Latn-CA",
    "gix": "gix-Latn-CD",
    "giy": "giy-Latn-AU",
    "giz": "giz-Latn-CM",
    "gjk": "gjk-Arab-PK",
    "gjm": "gjm-Latn-AU",
    "gjn": "gjn-Latn-GH",
    "gjr": "gjr-Latn-AU",
    "gju": "gju-Arab-PK",
    "gka": "gka-Latn-PG",
    "gkd": "gkd-Latn-PG",
    "gke": "gke-Latn-CM",
    "gkn": "gkn-Latn-NG",
    "gko": "gko-Latn-AU",
    "gkp": "gkp-Latn-GN",
    "gku": "gku-Latn-ZA",
    "gl": "gl-Latn-ES",
    "glb": "glb-Latn-NG",
    "glc": "glc-Latn-TD",
    "gld": "gld-Cyrl-RU",
    "glh": "glh-Arab-AF",
    "glj": "glj-Latn-TD",
    "glk": "glk-Arab-IR",
    "gll": "gll-Latn-AU",
    "glo": "glo-Latn-NG",
    "glr": "glr-Latn-LR",
    "glu": "glu-Latn-TD",
    "glw": "glw-Latn-NG",
    "gma": "gma-Latn-AU",
    "gmb": "gmb-Latn-SB",
    "gmd": "gmd-Latn-NG",
    "gmg": "gmg-Latn-PG",
    "gmh": "gmh-Latn-DE",
    "gml": "gml-Latf-DE",
    "gmm": "gmm-Latn-CM",
    "gmn": "gmn-Latn-CM",
    "gmr": "gmr-Latn-AU",
    "gmu": "gmu-Latn-PG",
    "gmv": "gmv-Ethi-ET",
    "gmx": "gmx-Latn-TZ",
    "gmy": "gmy-Linb-GR",
    "gmz": "gmz-Latn-NG",
    "gn": "gn-Latn-PY",
    "gna": "gna-Latn-BF",
    "gnb": "gnb-Latn-IN",
    "gnc": "gnc-Latn-ES",
    "gnd": "gnd-Latn-CM",
    "gne": "gne-Latn-NG",
    "gng": "gng-Latn-TG",
    "gnh": "gnh-Latn-NG",
    "gni": "gni-Latn-AU",
    "gnj": "gnj-Latn-CI",
    "gnk": "gnk-Latn-BW",
    "gnl": "gnl-Latn-AU",
    "gnm": "gnm-Latn-PG",
    "gnn": "gnn-Latn-AU",
    "gnq": "gnq-Latn-MY",
    "gnr": "gnr-Latn-AU",
    "gnt": "gnt-Latn-PG",
    "gnu": "gnu-Latn-PG",
    "gnw": "gnw-Latn-BO",
    "gnz": "gnz-Latn-CF",
    "goa": "goa-Latn-CI",
    "gob": "gob-Latn-CO",
    "goc": "goc-Latn-PG",
    "god": "god-Latn-CI",
    "goe": "goe-Tibt-BT",
    "gof": "gof-Ethi-ET",
    "gog": "gog-Latn-TZ",
    "goh": "goh-Latn-DE",
    "goi": "goi-Latn-PG",
    "goj": "goj-Deva-IN",
    "gok": "gok-Deva-IN",
    "gol": "gol-Latn-LR",
    "gon": "gon-Deva-IN",
    "goo": "goo-Latn-FJ",
    "gop": "gop-Latn-ID",
    "goq": "goq-Latn-ID",
    "gor": "gor-Latn-ID",
    "gos": "gos-Latn-NL",
    "got": "got-Goth-UA",
    "gou": "gou-Latn-CM",
    "gov": "gov-Latn-CI",
    "gow": "gow-Latn-TZ",
    "gox": "gox-Latn-CD",
    "goy": "goy-Latn-TD",
    "gpa": "gpa-Latn-NG",
    "gpe": "gpe-Latn-GH",
    "gpn": "gpn-Latn-PG",
    "gqa": "gqa-Latn-NG",
    "gqn": "gqn-Latn-BR",
    "gqr": "gqr-Latn-TD",
    "gra": "gra-Deva-IN",
    "grb": "grb-Latn-LR",
    "grc": "grc-Cprt-CY",
    "grc-Linb": "grc-Linb-GR",
    "grd": "grd-Latn-NG",
    "grg": "grg-Latn-PG",
    "grh": "grh-Latn-NG",
    "gri": "gri-Latn-SB",
    "grj": "grj-Latn-LR",
    "grm": "grm-Latn-MY",
    "grq": "grq-Latn-PG",
    "grs": "grs-Latn-ID",
    "grt": "grt-Beng-IN",
    "gru": "gru-Ethi-ET",
    "grv": "grv-Latn-LR",
    "grw": "grw-Latn-PG",
    "grx": "grx-Latn-PG",
    "gry": "gry-Latn-LR",
    "grz": "grz-Latn-PG",
    "gsl": "gsl-Latn-SN",
    "gsn": "gsn-Latn-PG",
    "gso": "gso-Latn-CF",
    "gsp": "gsp-Latn-PG",
    "gsw": "gsw-Latn-CH",
    "gta": "gta-Latn-BR",
    "gtu": "gtu-Latn-AU",
    "gu": "gu-Gujr-IN",
    "gua": "gua-Latn-NG",
    "gub": "gub-Latn-BR",
    "guc": "guc-Latn-CO",
    "gud": "gud-Latn-CI",
    "gue": "gue-Latn-AU",
    "guf": "guf-Latn-AU",
    "guh": "guh-Latn-CO",
    "gui": "gui-Latn-BO",
    "guk": "guk-Latn-ET",
    "gul": "gul-Latn-US",
    "gum": "gum-Latn-CO",
    "gun": "gun-Latn-BR",
    "guo": "guo-Latn-CO",
    "gup": "gup-Latn-AU",
    "guq": "guq-Latn-PY",
    "gur": "gur-Latn-GH",
    "gut": "gut-Latn-CR",
    "guu": "guu-Latn-VE",
    "guw": "guw-Latn-BJ",
    "gux": "gux-Latn-BF",
    "guz": "guz-Latn-KE",
    "gv": "gv-Latn-IM",
    "gva": "gva-Latn-PY",
    "gvc": "gvc-Latn-BR",
    "gve": "gve-Latn-PG",
    "gvf": "gvf-Latn-PG",
    "gvj": "gvj-Latn-BR",
    "gvl": "gvl-Latn-TD",
    "gvm": "gvm-Latn-NG",
    "gvn": "gvn-Latn-AU",
    "gvo": "gvo-Latn-BR",
    "gvp": "gvp-Latn-BR",
    "gvr": "gvr-Deva-NP",
    "gvs": "gvs-Latn-PG",
    "gvy": "gvy-Latn-AU",
    "gwa": "gwa-Latn-CI",
    "gwb": "gwb-Latn-NG",
    "gwc": "gwc-Arab-PK",
    "gwd": "gwd-Latn-ET",
    "gwe": "gwe-Latn-TZ",
    "gwf": "gwf-Arab-PK",
    "gwg": "gwg-Latn-NG",
    "gwi": "gwi-Latn-CA",
    "gwj": "gwj-Latn-BW",
    "gwm": "gwm-Latn-AU",
    "gwn": "gwn-Latn-NG",
    "gwr": "gwr-Latn-UG",
    "gwt": "gwt-Arab-AF",
    "gwu": "gwu-Latn-AU",
    "gww": "gww-Latn-AU",
    "gwx": "gwx-Latn-GH",
    "gxx": "gxx-Latn-CI",
    "gyb": "gyb-Latn-PG",
    "gyd": "gyd-Latn-AU",
    "gye": "gye-Latn-NG",
    "gyf": "gyf-Latn-AU",
    "gyg": "gyg-Latn-CF",
    "gyi": "gyi-Latn-CM",
    "gyl": "gyl-Latn-ET",
    "gym": "gym-Latn-PA",
    "gyn": "gyn-Latn-GY",
    "gyo": "gyo-Deva-NP",
    "gyr": "gyr-Latn-BO",
    "gyy": "gyy-Latn-AU",
    "gyz": "gyz-Latn-NG",
    "gza": "gza-Latn-SD",
    "gzi": "gzi-Arab-IR",
    "gzn": "gzn-Latn-ID",
    "ha": "ha-Latn-NG",
    "ha-CM": "ha-Arab-CM",
    "ha-SD": "ha-Arab-SD",
    "haa": "haa-Latn-US",
    "hac": "hac-Arab-IR",
    "had": "had-Latn-ID",
    "hae": "hae-Latn-ET",
    "hag": "hag-Latn-GH",
    "hah": "hah-Latn-PG",
    "hai": "hai-Latn-CA",
    "haj": "haj-Latn-IN",
    "hak": "hak-Hans-CN",
    "hal": "hal-Latn-VN",
    "ham": "ham-Latn-PG",
    "han": "han-Latn-TZ",
    "hao": "hao-Latn-PG",
    "hap": "hap-Latn-ID",
    "haq": "haq-Latn-TZ",
    "har": "har-Ethi-ET",
    "has": "has-Latn-CA",
    "hav": "hav-Latn-CD",
    "haw": "haw-Latn-US",
    "hax": "hax-Latn-CA",
    "hay": "hay-Latn-TZ",
    "haz": "haz-Arab-AF",
    "hba": "hba-Latn-CD",
    "hbb": "hbb-Latn-NG",
    "hbn": "hbn-Latn-SD",
    "hbo": "hbo-Hebr-IL",
    "hbu": "hbu-Latn-TL",
    "hch": "hch-Latn-MX",
    "hdy": "hdy-Ethi-ET",
    "he": "he-Hebr-IL",
    "hed": "hed-Latn-TD",
    "heg": "heg-Latn-ID",
    "heh": "heh-Latn-TZ",
    "hei": "hei-Latn-CA",
    "hem": "hem-Latn-CD",
    "hgm": "hgm-Latn-NA",
    "hgw": "hgw-Latn-PG",
    "hhi": "hhi-Latn-PG",
    "hhr": "hhr-Latn-SN",
    "hhy": "hhy-Latn-PG",
    "hi": "hi-Deva-IN",
    "hia": "hia-Latn-NG",
    "hib": "hib-Latn-PE",
    "hid": "hid-Latn-US",
    "hif": "hif-Deva-FJ",
    "hig": "hig-Latn-NG",
    "hih": "hih-Latn-PG",
    "hii": "hii-Takr-IN",
    "hij": "hij-Latn-CM",
    "hik": "hik-Latn-ID",
    "hil": "hil-Latn-PH",
    "hio": "hio-Latn-BW",
    "hir": "hir-Latn-BR",
    "hit": "hit-Xsux-TR",
    "hiw": "hiw-Latn-VU",
    "hix": "hix-Latn-BR",
    "hji": "hji-Latn-ID",
    "hka": "hka-Latn-TZ",
    "hke": "hke-Latn-CD",
    "hkh": "hkh-Arab-IN",
    "hkk": "hkk-Latn-PG",
    "hla": "hla-Latn-PG",
    "hlb": "hlb-Deva-IN",
    "hld": "hld-Latn-VN",
    "hlt": "hlt-Latn-MM",
    "hlu": "hlu-Hluw-TR",
    "hma": "hma-Latn-CN",
    "hmb": "hmb-Latn-ML",
    "hmd": "hmd-Plrd-CN",
    "hmf": "hmf-Latn-VN",
    "hmj": "hmj-Bopo-CN",
    "hmm": "hmm-Latn-CN",
    "hmn": "hmn-Latn-CN",
    "hmp": "hmp-Latn-CN",
    "hmq": "hmq-Bopo-CN",
    "hmr": "hmr-Latn-IN",
    "hms": "hms-Latn-CN",
    "hmt": "hmt-Latn-PG",
    "hmu": "hmu-Latn-ID",
    "hmv": "hmv-Latn-VN",
    "hmw": "hmw-Latn-CN",
    "hmy": "hmy-Latn-CN",
    "hmz": "hmz-Latn-CN",
    "hna": "hna-Latn-CM",
    "hnd": "hnd-Arab-PK",
    "hne": "hne-Deva-IN",
    "hng": "hng-Latn-AO",
    "hnh": "hnh-Latn-BW",
    "hni": "hni-Latn-CN",
    "hnj": "hnj-Hmnp-US",
    "hnj-Hmng": "hnj-Hmng-LA",
    "hnn": "hnn-Latn-PH",
    "hno": "hno-Arab-PK",
    "hns": "hns-Latn-SR",
    "ho": "ho-Latn-PG",
    "hoa": "hoa-Latn-SB",
    "hob": "hob-Latn-PG",
    "hoc": "hoc-Deva-IN",
    "hod": "hod-Latn-NG",
    "hoe": "hoe-Latn-NG",
    "hoh": "hoh-Arab-OM",
    "hoi": "hoi-Latn-US",
    "hoj": "hoj-Deva-IN",
    "hol": "hol-Latn-AO",
    "hom": "hom-Latn-SS",
    "hoo": "hoo-Latn-CD",
    "hop": "hop-Latn-US",
    "hor": "hor-Latn-TD",
    "hot": "hot-Latn-PG",
    "hov": "hov-Latn-ID",
    "how": "how-Hani-CN",
    "hoy": "hoy-Deva-IN",
    "hpo": "hpo-Mymr-MM",
    "hr": "hr-Latn-HR",
    "hra": "hra-Latn-IN",
    "hrc": "hrc-Latn-PG",
    "hre": "hre-Latn-VN",
    "hrk": "hrk-Latn-ID",
    "hrm": "hrm-Latn-CN",
    "hro": "hro-Latn-VN",
    "hrp": "hrp-Latn-AU",
    "hrt": "hrt-Syrc-TR",
    "hru": "hru-Latn-IN",
    "hrw": "hrw-Latn-PG",
    "hrx": "hrx-Latn-BR",
    "hrz": "hrz-Arab-IR",
    "hsb": "hsb-Latn-DE",
    "hsn": "hsn-Hans-CN",
    "hss": "hss-Arab-OM",
    "ht": "ht-Latn-HT",
    "hti": "hti-Latn-ID",
    "hto": "hto-Latn-CO",
    "hts": "hts-Latn-TZ",
    "htu": "htu-Latn-ID",
    "htx": "htx-Xsux-TR",
    "hu": "hu-Latn-HU",
    "hub": "hub-Latn-PE",
    "huc": "huc-Latn-BW",
    "hud": "hud-Latn-ID",
    "hue": "hue-Latn-MX",
    "huf": "huf-Latn-PG",
    "hug": "hug-Latn-PE",
    "huh": "huh-Latn-CL",
    "hui": "hui-Latn-PG",
    "huk": "huk-Latn-ID",
    "hul": "hul-Latn-PG",
    "hum": "hum-Latn-CD",
    "hup": "hup-Latn-US",
    "hur": "hur-Latn-CA",
    "hus": "hus-Latn-MX",
    "hut": "hut-Deva-NP",
    "huu": "huu-Latn-PE",
    "huv": "huv-Latn-MX",
    "huw": "huw-Latn-ID",
    "hux": "hux-Latn-PE",
    "huy": "huy-Hebr-IL",
    "huz": "huz-Cyrl-RU",
    "hvc": "hvc-Latn-HT",
    "hve": "hve-Latn-MX",
    "hvk": "hvk-Latn-NC",
    "hvn": "hvn-Latn-ID",
    "hvv": "hvv-Latn-MX",
    "hwa": "hwa-Latn-CI",
    "hwc": "hwc-Latn-US",
    "hwo": "hwo-Latn-NG",
    "hy": "hy-Armn-AM",
    "hya": "hya-Latn-CM",
    "hyw": "hyw-Armn-AM",
    "hz": "hz-Latn-NA",
    "ia": "ia-Latn-001",
    "iai": "iai-Latn-NC",
    "ian": "ian-Latn-PG",
    "iar": "iar-Latn-PG",
    "iba": "iba-Latn-MY",
    "ibb": "ibb-Latn-NG",
    "ibd": "ibd-Latn-AU",
    "ibe": "ibe-Latn-NG",
    "ibg": "ibg-Latn-PH",
    "ibh": "ibh-Latn-VN",
    "ibl": "ibl-Latn-PH",
    "ibm": "ibm-Latn-NG",
    "ibn": "ibn-Latn-NG",
    "ibr": "ibr-Latn-NG",
    "ibu": "ibu-Latn-ID",
    "iby": "iby-Latn-NG",
    "ica": "ica-Latn-BJ",
    "ich": "ich-Latn-NG",
    "icr": "icr-Latn-CO",
    "id": "id-Latn-ID",
    "ida": "ida-Latn-KE",
    "idb": "idb-Latn-IN",
    "idc": "idc-Latn-NG",
    "idd": "idd-Latn-BJ",
    "ide": "ide-Latn-NG",
    "idi": "idi-Latn-PG",
    "idr": "idr-Latn-SS",
    "ids": "ids-Latn-NG",
    "idt": "idt-Latn-TL",
    "idu": "idu-Latn-NG",
    "ie": "ie-Latn-EE",
    "ifa": "ifa-Latn-PH",
    "ifb": "ifb-Latn-PH",
    "ife": "ife-Latn-TG",
    "iff": "iff-Latn-VU",
    "ifk": "ifk-Latn-PH",
    "ifm": "ifm-Latn-CG",
    "ifu": "ifu-Latn-PH",
    "ify": "ify-Latn-PH",
    "ig": "ig-Latn-NG",
    "igb": "igb-Latn-NG",
    "ige": "ige-Latn-NG",
    "igg": "igg-Latn-PG",
    "igl": "igl-Latn-NG",
    "igm": "igm-Latn-PG",
    "ign": "ign-Latn-BO",
    "igo": "igo-Latn-PG",
    "igs": "igs-Latn-001",
    "igw": "igw-Latn-NG",
    "ihb": "ihb-Latn-ID",
    "ihi": "ihi-Latn-NG",
    "ihp": "ihp-Latn-ID",
    "ihw": "ihw-Latn-AU",
    "ii": "ii-Yiii-CN",
    "iin": "iin-Latn-AU",
    "ijc": "ijc-Latn-NG",
    "ije": "ije-Latn-NG",
    "ijj": "ijj-Latn-BJ",
    "ijn": "ijn-Latn-NG",
    "ijs": "ijs-Latn-NG",
    "ik": "ik-Latn-US",
    "ikh": "ikh-Latn-NG",
    "iki": "iki-Latn-NG",
    "ikk": "ikk-Latn-NG",
    "ikl": "ikl-Latn-NG",
    "iko": "iko-Latn-NG",
    "ikp": "ikp-Latn-NG",
    "ikr": "ikr-Latn-AU",
    "ikt": "ikt-Latn-CA",
    "ikv": "ikv-Latn-NG",
    "ikw": "ikw-Latn-NG",
    "ikx": "ikx-Latn-UG",
    "ikz": "ikz-Latn-TZ",
    "ila": "ila-Latn-ID",
    "ilb": "ilb-Latn-ZM",
    "ilg": "ilg-Latn-AU",
    "ili": "ili-Latn-CN",
    "ilk": "ilk-Latn-PH",
    "ilm": "ilm-Latn-MY",
    "ilo": "ilo-Latn-PH",
    "ilp": "ilp-Latn-PH",
    "ilu": "ilu-Latn-ID",
    "ilv": "ilv-Latn-NG",
    "imi": "imi-Latn-PG",
    "iml": "iml-Latn-US",
    "imn": "imn-Latn-PG",
    "imo": "imo-Latn-PG",
    "imr": "imr-Latn-ID",
    "ims": "ims-Latn-IT",
    "imt": "imt-Latn-SS",
    "imy": "imy-Lyci-TR",
    "in": "in-Latn-ID",
    "inb": "inb-Latn-CO",
    "ing": "ing-Latn-US",
    "inh": "inh-Cyrl-RU",
    "inj": "inj-Latn-CO",
    "inn": "inn-Latn-PH",
    "ino": "ino-Latn-PG",
    "inp": "inp-Latn-PE",
    "int": "int-Mymr-MM",
    "io": "io-Latn-001",
    "ior": "ior-Ethi-ET",
    "iou": "iou-Latn-PG",
    "iow": "iow-Latn-US",
    "ipi": "ipi-Latn-PG",
    "ipo": "ipo-Latn-PG",
    "iqu": "iqu-Latn-PE",
    "iqw": "iqw-Latn-NG",
    "ire": "ire-Latn-ID",
    "irh": "irh-Latn-ID",
    "iri": "iri-Latn-NG",
    "irk": "irk-Latn-TZ",
    "irn": "irn-Latn-BR",
    "iru": "iru-Taml-IN",
    "irx": "irx-Latn-ID",
    "iry": "iry-Latn-PH",
    "is": "is-Latn-IS",
    "isa": "isa-Latn-PG",
    "isc": "isc-Latn-PE",
    "isd": "isd-Latn-PH",
    "ish": "ish-Latn-NG",
    "isi": "isi-Latn-NG",
    "isk": "isk-Arab-AF",
    "ism": "ism-Latn-ID",
    "isn": "isn-Latn-TZ",
    "iso": "iso-Latn-NG",
    "ist": "ist-Latn-HR",
    "isu": "isu-Latn-CM",
    "it": "it-Latn-IT",
    "itb": "itb-Latn-PH",
    "itd": "itd-Latn-ID",
    "ite": "ite-Latn-BO",
    "iti": "iti-Latn-PH",
    "itk": "itk-Hebr-IT",
    "itl": "itl-Cyrl-RU",
    "itm": "itm-Latn-NG",
    "ito": "ito-Latn-BO",
    "itr": "itr-Latn-PG",
    "its": "its-Latn-NG",
    "itt": "itt-Latn-PH",
    "itv": "itv-Latn-PH",
    "itw": "itw-Latn-NG",
    "itx": "itx-Latn-ID",
    "ity": "ity-Latn-PH",
    "itz": "itz-Latn-GT",
    "iu": "iu-Cans-CA",
    "ium": "ium-Latn-CN",
    "ivb": "ivb-Latn-PH",
    "ivv": "ivv-Latn-PH",
    "iw": "iw-Hebr-IL",
    "iwk": "iwk-Latn-PH",
    "iwm": "iwm-Latn-PG",
    "iwo": "iwo-Latn-ID",
    "iws": "iws-Latn-PG",
    "ixc": "ixc-Latn-MX",
    "ixl": "ixl-Latn-GT",
    "iya": "iya-Latn-NG",
    "iyo": "iyo-Latn-CM",
    "iyx": "iyx-Latn-CG",
    "izh": "izh-Latn-RU",
    "izm": "izm-Latn-NG",
    "izr": "izr-Latn-NG",
    "izz": "izz-Latn-NG",
    "ja": "ja-Jpan-JP",
    "jaa": "jaa-Latn-BR",
    "jab": "jab-Latn-NG",
    "jac": "jac-Latn-GT",
    "jad": "jad-Arab-GN",
    "jae": "jae-Latn-PG",
    "jaf": "jaf-Latn-NG",
    "jah": "jah-Latn-MY",
    "jaj": "jaj-Latn-SB",
    "jak": "jak-Latn-MY",
    "jal": "jal-Latn-ID",
    "jam": "jam-Latn-JM",
    "jan": "jan-Latn-AU",
    "jao": "jao-Latn-AU",
    "jaq": "jaq-Latn-ID",
    "jas": "jas-Latn-NC",
    "jat": "jat-Arab-AF",
    "jau": "jau-Latn-ID",
    "jax": "jax-Latn-ID",
    "jay": "jay-Latn-AU",
    "jaz": "jaz-Latn-NC",
    "jbe": "jbe-Hebr-IL",
    "jbi": "jbi-Latn-AU",
    "jbj": "jbj-Latn-ID",
    "jbk": "jbk-Latn-PG",
    "jbm": "jbm-Latn-NG",
    "jbn": "jbn-Arab-LY",
    "jbo": "jbo-Latn-001",
    "jbr": "jbr-Latn-ID",
    "jbt": "jbt-Latn-BR",
    "jbu": "jbu-Latn-CM",
    "jbw": "jbw-Latn-AU",
    "jct": "jct-Cyrl-UA",
    "jda": "jda-Tibt-IN",
    "jdg": "jdg-Arab-PK",
    "jdt": "jdt-Cyrl-RU",
    "jeb": "jeb-Latn-PE",
    "jee": "jee-Deva-NP",
    "jeh": "jeh-Latn-VN",
    "jei": "jei-Latn-ID",
    "jek": "jek-Latn-CI",
    "jel": "jel-Latn-ID",
    "jen": "jen-Latn-NG",
    "jer": "jer-Latn-NG",
    "jet": "jet-Latn-PG",
    "jeu": "jeu-Latn-TD",
    "jgb": "jgb-Latn-CD",
    "jge": "jge-Geor-GE",
    "jgk": "jgk-Latn-NG",
    "jgo": "jgo-Latn-CM",
    "jhi": "jhi-Latn-MY",
    "ji": "ji-Hebr-UA",
    "jia": "jia-Latn-CM",
    "jib": "jib-Latn-NG",
    "jic": "jic-Latn-HN",
    "jid": "jid-Latn-NG",
    "jie": "jie-Latn-NG",
    "jig": "jig-Latn-AU",
    "jil": "jil-Latn-PG",
    "jim": "jim-Latn-CM",
    "jit": "jit-Latn-TZ",
    "jiu": "jiu-Latn-CN",
    "jiv": "jiv-Latn-EC",
    "jiy": "jiy-Latn-CN",
    "jje": "jje-Hang-KR",
    "jjr": "jjr-Latn-NG",
    "jka": "jka-Latn-ID",
    "jkm": "jkm-Mymr-MM",
    "jko": "jko-Latn-PG",
    "jku": "jku-Latn-NG",
    "jle": "jle-Latn-SD",
    "jma": "jma-Latn-PG",
    "jmb": "jmb-Latn-NG",
    "jmc": "jmc-Latn-TZ",
    "jmd": "jmd-Latn-ID",
    "jmi": "jmi-Latn-NG",
    "jml": "jml-Deva-NP",
    "jmn": "jmn-Latn-MM",
    "jmr": "jmr-Latn-GH",
    "jms": "jms-Latn-NG",
    "jmw": "jmw-Latn-PG",
    "jmx": "jmx-Latn-MX",
    "jna": "jna-Takr-IN",
    "jnd": "jnd-Arab-PK",
    "jng": "jng-Latn-AU",
    "jni": "jni-Latn-NG",
    "jnj": "jnj-Latn-ET",
    "jnl": "jnl-Deva-IN",
    "jns": "jns-Deva-IN",
    "job": "job-Latn-CD",
    "jod": "jod-Latn-CI",
    "jog": "jog-Arab-PK",
    "jor": "jor-Latn-BO",
    "jow": "jow-Latn-ML",
    "jpa": "jpa-Hebr-PS",
    "jpr": "jpr-Hebr-IL",
    "jqr": "jqr-Latn-PE",
    "jra": "jra-Latn-VN",
    "jrb": "jrb-Hebr-IL",
    "jrr": "jrr-Latn-NG",
    "jrt": "jrt-Latn-NG",
    "jru": "jru-Latn-VE",
    "jua": "jua-Latn-BR",
    "jub": "jub-Latn-NG",
    "jud": "jud-Latn-CI",
    "juh": "juh-Latn-NG",
    "jui": "jui-Latn-AU",
    "juk": "juk-Latn-NG",
    "jul": "jul-Deva-NP",
    "jum": "jum-Latn-SD",
    "jun": "jun-Orya-IN",
    "juo": "juo-Latn-NG",
    "jup": "jup-Latn-BR",
    "jur": "jur-Latn-BR",
    "jut": "jut-Latn-DK",
    "juu": "juu-Latn-NG",
    "juw": "juw-Latn-NG",
    "juy": "juy-Orya-IN",
    "jv": "jv-Latn-ID",
    "jvd": "jvd-Latn-ID",
    "jvn": "jvn-Latn-SR",
    "jw": "jw-Latn-ID",
    "jwi": "jwi-Latn-GH",
    "jya": "jya-Tibt-CN",
    "jye": "jye-Hebr-IL",
    "jyy": "jyy-Latn-TD",
    "ka": "ka-Geor-GE",
    "kaa": "kaa-Cyrl-UZ",
    "kab": "kab-Latn-DZ",
    "kac": "kac-Latn-MM",
    "kad": "kad-Latn-NG",
    "kag": "kag-Latn-MY",
    "kah": "kah-Latn-CF",
    "kai": "kai-Latn-NG",
    "kaj": "kaj-Latn-NG",
    "kak": "kak-Latn-PH",
    "kam": "kam-Latn-KE",
    "kao": "kao-Latn-ML",
    "kap": "kap-Cyrl-RU",
    "kaq": "kaq-Latn-PE",
    "kav": "kav-Latn-BR",
    "kaw": "kaw-Kawi-ID",
    "kax": "kax-Latn-ID",
    "kay": "kay-Latn-BR",
    "kba": "kba-Latn-AU",
    "kbb": "kbb-Latn-BR",
    "kbc": "kbc-Latn-BR",
    "kbd": "kbd-Cyrl-RU",
    "kbe": "kbe-Latn-AU",
    "kbg": "kbg-Tibt-IN",
    "kbh": "kbh-Latn-CO",
    "kbi": "kbi-Latn-ID",
    "kbj": "kbj-Latn-CD",
    "kbk": "kbk-Latn-PG",
    "kbl": "kbl-Latn-TD",
    "kbm": "kbm-Latn-PG",
    "kbn": "kbn-Latn-CF",
    "kbo": "kbo-Latn-SS",
    "kbp": "kbp-Latn-TG",
    "kbq": "kbq-Latn-PG",
    "kbr": "kbr-Latn-ET",
    "kbs": "kbs-Latn-GA",
    "kbt": "kbt-Latn-PG",
    "kbu": "kbu-Arab-PK",
    "kbv": "kbv-Latn-ID",
    "kbw": "kbw-Latn-PG",
    "kbx": "kbx-Latn-PG",
    "kby": "kby-Arab-NE",
    "kbz": "kbz-Latn-NG",
    "kca": "kca-Cyrl-RU",
    "kcb": "kcb-Latn-PG",
    "kcc": "kcc-Latn-NG",
    "kcd": "kcd-Latn-ID",
    "kce": "kce-Latn-NG",
    "kcf": "kcf-Latn-NG",
    "kcg": "kcg-Latn-NG",
    "kch": "kch-Latn-NG",
    "kci": "kci-Latn-NG",
    "kcj": "kcj-Latn-GW",
    "kck": "kck-Latn-ZW",
    "kcl": "kcl-Latn-PG",
    "kcm": "kcm-Latn-CF",
    "kcn": "kcn-Latn-UG",
    "kco": "kco-Latn-PG",
    "kcp": "kcp-Latn-SD",
    "kcq": "kcq-Latn-NG",
    "kcs": "kcs-Latn-NG",
    "kct": "kct-Latn-PG",
    "kcu": "kcu-Latn-TZ",
    "kcv": "kcv-Latn-CD",
    "kcw": "kcw-Latn-CD",
    "kcy": "kcy-Arab-DZ",
    "kcz": "kcz-Latn-TZ",
    "kda": "kda-Latn-AU",
    "kdc": "kdc-Latn-TZ",
    "kdd": "kdd-Latn-AU",
    "kde": "kde-Latn-TZ",
    "kdf": "kdf-Latn-PG",
    "kdg": "kdg-Latn-CD",
    "kdh": "kdh-Latn-TG",
    "kdi": "kdi-Latn-UG",
    "kdj": "kdj-Latn-UG",
    "kdk": "kdk-Latn-NC",
    "kdl": "kdl-Latn-NG",
    "kdm": "kdm-Latn-NG",
    "kdn": "kdn-Latn-ZW",
    "kdp": "kdp-Latn-NG",
    "kdq": "kdq-Beng-IN",
    "kdr": "kdr-Latn-LT",
    "kdt": "kdt-Thai-TH",
    "kdw": "kdw-Latn-ID",
    "kdx": "kdx-Latn-NG",
    "kdy": "kdy-Latn-ID",
    "kdz": "kdz-Latn-CM",
    "kea": "kea-Latn-CV",
    "keb": "keb-Latn-GA",
    "kec": "kec-Latn-SD",
    "ked": "ked-Latn-TZ",
    "kee": "kee-Latn-US",
    "kef": "kef-Latn-TG",
    "keg": "keg-Latn-SD",
    "keh": "keh-Latn-PG",
    "kei": "kei-Latn-ID",
    "kek": "kek-Latn-GT",
    "kel": "kel-Latn-CD",
    "kem": "kem-Latn-TL",
    "ken": "ken-Latn-CM",
    "keo": "keo-Latn-UG",
    "ker": "ker-Latn-TD",
    "kes": "kes-Latn-NG",
    "ket": "ket-Cyrl-RU",
    "keu": "keu-Latn-TG",
    "kev": "kev-Mlym-IN",
    "kew": "kew-Latn-PG",
    "kex": "kex-Deva-IN",
    "key": "key-Telu-IN",
    "kez": "kez-Latn-NG",
    "kfa": "kfa-Knda-IN",
    "kfb": "kfb-Deva-IN",
    "kfc": "kfc-Telu-IN",
    "kfd": "kfd-Knda-IN",
    "kfe": "kfe-Taml-IN",
    "kff": "kff-Latn-IN",
    "kfg": "kfg-Knda-IN",
    "kfh": "kfh-Mlym-IN",
    "kfi": "kfi-Taml-IN",
    "kfk": "kfk-Deva-IN",
    "kfl": "kfl-Latn-CM",
    "kfm": "kfm-Arab-IR",
    "kfn": "kfn-Latn-CM",
    "kfo": "kfo-Latn-CI",
    "kfp": "kfp-Deva-IN",
    "kfq": "kfq-Deva-IN",
    "kfr": "kfr-Deva-IN",
    "kfs": "kfs-Deva-IN",
    "kfu": "kfu-Deva-IN",
    "kfv": "kfv-Latn-IN",
    "kfw": "kfw-Latn-IN",
    "kfx": "kfx-Deva-IN",
    "kfy": "kfy-Deva-IN",
    "kfz": "kfz-Latn-BF",
    "kg": "kg-Latn-CD",
    "kga": "kga-Latn-CI",
    "kgb": "kgb-Latn-ID",
    "kge": "kge-Latn-ID",
    "kgf": "kgf-Latn-PG",
    "kgj": "kgj-Deva-NP",
    "kgk": "kgk-Latn-BR",
    "kgl": "kgl-Latn-AU",
    "kgo": "kgo-Latn-SD",
    "kgp": "kgp-Latn-BR",
    "kgq": "kgq-Latn-ID",
    "kgr": "kgr-Latn-ID",
    "kgs": "kgs-Latn-AU",
    "kgt": "kgt-Latn-NG",
    "kgu": "kgu-Latn-PG",
    "kgv": "kgv-Latn-ID",
    "kgw": "kgw-Latn-ID",
    "kgx": "kgx-Latn-ID",
    "kgy": "kgy-Deva-NP",
    "kha": "kha-Latn-IN",
    "khb": "khb-Talu-CN",
    "khc": "khc-Latn-ID",
    "khd": "khd-Latn-ID",
    "khe": "khe-Latn-ID",
    "khf": "khf-Thai-LA",
    "khg": "khg-Tibt-CN",
    "khh": "khh-Latn-ID",
    "khj": "khj-Latn-NG",
    "khl": "khl-Latn-PG",
    "khn": "khn-Deva-IN",
    "kho": "kho-Brah-IR",
    "khp": "khp-Latn-ID",
    "khq": "khq-Latn-ML",
    "khr": "khr-Latn-IN",
    "khs": "khs-Latn-PG",
    "kht": "kht-Mymr-IN",
    "khu": "khu-Latn-AO",
    "khv": "khv-Cyrl-RU",
    "khw": "khw-Arab-PK",
    "khx": "khx-Latn-CD",
    "khy": "khy-Latn-CD",
    "khz": "khz-Latn-PG",
    "ki": "ki-Latn-KE",
    "kia": "kia-Latn-TD",
    "kib": "kib-Latn-SD",
    "kic": "kic-Latn-US",
    "kid": "kid-Latn-CM",
    "kie": "kie-Latn-TD",
    "kif": "kif-Deva-NP",
    "kig": "kig-Latn-ID",
    "kih": "kih-Latn-PG",
    "kij": "kij-Latn-PG",
    "kil": "kil-Latn-NG",
    "kim": "kim-Cyrl-RU",
    "kio": "kio-Latn-US",
    "kip": "kip-Deva-NP",
    "kiq": "kiq-Latn-ID",
    "kis": "kis-Latn-PG",
    "kit": "kit-Latn-PG",
    "kiu": "kiu-Latn-TR",
    "kiv": "kiv-Latn-TZ",
    "kiw": "kiw-Latn-PG",
    "kix": "kix-Latn-IN",
    "kiy": "kiy-Latn-ID",
    "kiz": "kiz-Latn-TZ",
    "kj": "kj-Latn-NA",
    "kja": "kja-Latn-ID",
    "kjb": "kjb-Latn-GT",
    "kjc": "kjc-Latn-ID",
    "kjd": "kjd-Latn-PG",
    "kje": "kje-Latn-ID",
    "kjg": "kjg-Laoo-LA",
    "kjh": "kjh-Cyrl-RU",
    "kji": "kji-Latn-SB",
    "kjj": "kjj-Latn-AZ",
    "kjk": "kjk-Latn-ID",
    "kjl": "kjl-Deva-NP",
    "kjm": "kjm-Latn-VN",
    "kjn": "kjn-Latn-AU",
    "kjo": "kjo-Deva-IN",
    "kjp": "kjp-Mymr-MM",
    "kjq": "kjq-Latn-US",
    "kjr": "kjr-Latn-ID",
    "kjs": "kjs-Latn-PG",
    "kjt": "kjt-Thai-TH",
    "kju": "kju-Latn-US",
    "kjx": "kjx-Latn-PG",
    "kjy": "kjy-Latn-PG",
    "kjz": "kjz-Tibt-BT",
    "kk": "kk-Cyrl-KZ",
    "kk-AF": "kk-Arab-AF",
    "kk-Arab": "kk-Arab-CN",
    "kk-CN": "kk-Arab-CN",
    "kk-IR": "kk-Arab-IR",
    "kk-MN": "kk-Arab-MN",
    "kka": "kka-Latn-NG",
    "kkb": "kkb-Latn-ID",
    "kkc": "kkc-Latn-PG",
    "kkd": "kkd-Latn-NG",
    "kke": "kke-Latn-GN",
    "kkf": "kkf-Tibt-IN",
    "kkg": "kkg-Latn-PH",
    "kkh": "kkh-Lana-MM",
    "kki": "kki-Latn-TZ",
    "kkj": "kkj-Latn-CM",
    "kkk": "kkk-Latn-SB",
    "kkl": "kkl-Latn-ID",
    "kkm": "kkm-Latn-NG",
    "kko": "kko-Latn-SD",
    "kkp": "kkp-Latn-AU",
    "kkq": "kkq-Latn-CD",
    "kkr": "kkr-Latn-NG",
    "kks": "kks-Latn-NG",
    "kkt": "kkt-Deva-NP",
    "kku": "kku-Latn-NG",
    "kkv": "kkv-Latn-ID",
    "kkw": "kkw-Latn-CG",
    "kkx": "kkx-Latn-ID",
    "kky": "kky-Latn-AU",
    "kkz": "kkz-Latn-CA",
    "kl": "kl-Latn-GL",
    "kla": "kla-Latn-US",
    "klb": "klb-Latn-MX",
    "klc": "klc-Latn-CM",
    "kld": "kld-Latn-AU",
    "kle": "kle-Deva-NP",
    "klf": "klf-Latn-TD",
    "klg": "klg-Latn-PH",
    "klh": "klh-Latn-PG",
    "kli": "kli-Latn-ID",
    "klj": "klj-Arab-IR",
    "klk": "klk-Latn-NG",
    "kll": "kll-Latn-PH",
    "klm": "klm-Latn-PG",
    "kln": "kln-Latn-KE",
    "klo": "klo-Latn-NG",
    "klp": "klp-Latn-PG",
    "klq": "klq-Latn-PG",
    "klr": "klr-Deva-NP",
    "kls": "kls-Latn-PK",
    "klt": "klt-Latn-PG",
    "klu": "klu-Latn-LR",
    "klv": "klv-Latn-VU",
    "klw": "klw-Latn-ID",
    "klx": "klx-Latn-PG",
    "kly": "kly-Latn-ID",
    "klz": "klz-Latn-ID",
    "km": "km-Khmr-KH",
    "kma": "kma-Latn-GH",
    "kmb": "kmb-Latn-AO",
    "kmc": "kmc-Latn-CN",
    "kmd": "kmd-Latn-PH",
    "kme": "kme-Latn-CM",
    "kmf": "kmf-Latn-PG",
    "kmg": "kmg-Latn-PG",
    "kmh": "kmh-Latn-PG",
    "kmi": "kmi-Latn-NG",
    "kmj": "kmj-Deva-IN",
    "kmk": "kmk-Latn-PH",
    "kml": "kml-Latn-PH",
    "kmm": "kmm-Latn-IN",
    "kmn": "kmn-Latn-PG",
    "kmo": "kmo-Latn-PG",
    "kmp": "kmp-Latn-CM",
    "kmq": "kmq-Latn-ET",
    "kms": "kms-Latn-PG",
    "kmt": "kmt-Latn-ID",
    "kmu": "kmu-Latn-PG",
    "kmv": "kmv-Latn-BR",
    "kmw": "kmw-Latn-CD",
    "kmx": "kmx-Latn-PG",
    "kmy": "kmy-Latn-NG",
    "kmz": "kmz-Arab-IR",
    "kn": "kn-Knda-IN",
    "kna": "kna-Latn-NG",
    "knb": "knb-Latn-PH",
    "knd": "knd-Latn-ID",
    "kne": "kne-Latn-PH",
    "knf": "knf-Latn-GW",
    "kni": "kni-Latn-NG",
    "knj": "knj-Latn-GT",
    "knk": "knk-Latn-SL",
    "knl": "knl-Latn-ID",
    "knm": "knm-Latn-BR",
    "knn": "knn-Deva-IN",
    "kno": "kno-Latn-SL",
    "knp": "knp-Latn-CM",
    "knq": "knq-Latn-MY",
    "knr": "knr-Latn-PG",
    "kns": "kns-Latn-MY",
    "knt": "knt-Latn-BR",
    "knu": "knu-Latn-GN",
    "knv": "knv-Latn-PG",
    "knw": "knw-Latn-NA",
    "knx": "knx-Latn-ID",
    "kny": "kny-Latn-CD",
    "knz": "knz-Latn-BF",
    "ko": "ko-Kore-KR",
    "koa": "koa-Latn-PG",
    "koc": "koc-Latn-NG",
    "kod": "kod-Latn-ID",
    "koe": "koe-Latn-SS",
    "kof": "kof-Latn-NG",
    "kog": "kog-Latn-CO",
    "koh": "koh-Latn-CG",
    "koi": "koi-Cyrl-RU",
    "kok": "kok-Deva-IN",
    "kol": "kol-Latn-PG",
    "koo": "koo-Latn-UG",
    "kop": "kop-Latn-PG",
    "koq": "koq-Latn-GA",
    "kos": "kos-Latn-FM",
    "kot": "kot-Latn-CM",
    "kou": "kou-Latn-TD",
    "kov": "kov-Latn-NG",
    "kow": "kow-Latn-NG",
    "koy": "koy-Latn-US",
    "koz": "koz-Latn-PG",
    "kpa": "kpa-Latn-NG",
    "kpc": "kpc-Latn-CO",
    "kpd": "kpd-Latn-ID",
    "kpe": "kpe-Latn-LR",
    "kpf": "kpf-Latn-PG",
    "kpg": "kpg-Latn-FM",
    "kph": "kph-Latn-GH",
    "kpi": "kpi-Latn-ID",
    "kpj": "kpj-Latn-BR",
    "kpk": "kpk-Latn-NG",
    "kpl": "kpl-Latn-CD",
    "kpm": "kpm-Latn-VN",
    "kpn": "kpn-Latn-BR",
    "kpo": "kpo-Latn-TG",
    "kpq": "kpq-Latn-ID",
    "kpr": "kpr-Latn-PG",
    "kps": "kps-Latn-ID",
    "kpt": "kpt-Cyrl-RU",
    "kpu": "kpu-Latn-ID",
    "kpw": "kpw-Latn-PG",
    "kpx": "kpx-Latn-PG",
    "kpy": "kpy-Cyrl-RU",
    "kpz": "kpz-Latn-UG",
    "kqa": "kqa-Latn-PG",
    "kqb": "kqb-Latn-PG",
    "kqc": "kqc-Latn-PG",
    "kqd": "kqd-Syrc-IQ",
    "kqe": "kqe-Latn-PH",
    "kqf": "kqf-Latn-PG",
    "kqg": "kqg-Latn-BF",
    "kqh": "kqh-Latn-TZ",
    "kqi": "kqi-Latn-PG",
    "kqj": "kqj-Latn-PG",
    "kqk": "kqk-Latn-BJ",
    "kql": "kql-Latn-PG",
    "kqm": "kqm-Latn-CI",
    "kqn": "kqn-Latn-ZM",
    "kqo": "kqo-Latn-LR",
    "kqp": "kqp-Latn-TD",
    "kqq": "kqq-Latn-BR",
    "kqr": "kqr-Latn-MY",
    "kqs": "kqs-Latn-GN",
    "kqt": "kqt-Latn-MY",
    "kqu": "kqu-Latn-ZA",
    "kqv": "kqv-Latn-ID",
    "kqw": "kqw-Latn-PG",
    "kqx": "kqx-Latn-CM",
    "kqy": "kqy-Ethi-ET",
    "kqz": "kqz-Latn-ZA",
    "kr": "kr-Latn-NG",
    "kra": "kra-Deva-NP",
    "krb": "krb-Latn-US",
    "krc": "krc-Cyrl-RU",
    "krd": "krd-Latn-TL",
    "kre": "kre-Latn-BR",
    "krf": "krf-Latn-VU",
    "krh": "krh-Latn-NG",
    "kri": "kri-Latn-SL",
    "krj": "krj-Latn-PH",
    "krk": "krk-Cyrl-RU",
    "krl": "krl-Latn-RU",
    "krn": "krn-Latn-LR",
    "krp": "krp-Latn-NG",
    "krr": "krr-Khmr-KH",
    "krs": "krs-Latn-SS",
    "krt": "krt-Latn-NE",
    "kru": "kru-Deva-IN",
    "krv": "krv-Khmr-KH",
    "krw": "krw-Latn-LR",
    "krx": "krx-Latn-SN",
    "kry": "kry-Latn-AZ",
    "krz": "krz-Latn-ID",
    "ks": "ks-Arab-IN",
    "ksb": "ksb-Latn-TZ",
    "ksc": "ksc-Latn-PH",
    "ksd": "ksd-Latn-PG",
    "kse": "kse-Latn-PG",
    "ksf": "ksf-Latn-CM",
    "ksg": "ksg-Latn-SB",
    "ksh": "ksh-Latn-DE",
    "ksi": "ksi-Latn-PG",
    "ksj": "ksj-Latn-PG",
    "ksk": "ksk-Latn-US",
    "ksl": "ksl-Latn-PG",
    "ksm": "ksm-Latn-NG",
    "ksn": "ksn-Latn-PH",
    "kso": "kso-Latn-NG",
    "ksp": "ksp-Latn-CF",
    "ksq": "ksq-Latn-NG",
    "ksr": "ksr-Latn-PG",
    "kss": "kss-Latn-LR",
    "kst": "kst-Latn-BF",
    "ksu": "ksu-Mymr-IN",
    "ksv": "ksv-Latn-CD",
    "ksw": "ksw-Mymr-MM",
    "ksx": "ksx-Latn-ID",
    "ksz": "ksz-Deva-IN",
    "kta": "kta-Latn-VN",
    "ktb": "ktb-Ethi-ET",
    "ktc": "ktc-Latn-NG",
    "ktd": "ktd-Latn-AU",
    "kte": "kte-Deva-NP",
    "ktf": "ktf-Latn-CD",
    "ktg": "ktg-Latn-AU",
    "kth": "kth-Latn-TD",
    "kti": "kti-Latn-ID",
    "ktj": "ktj-Latn-CI",
    "ktk": "ktk-Latn-PG",
    "ktl": "ktl-Arab-IR",
    "ktm": "ktm-Latn-PG",
    "ktn": "ktn-Latn-BR",
    "kto": "kto-Latn-PG",
    "ktp": "ktp-Plrd-CN",
    "ktq": "ktq-Latn-PH",
    "kts": "kts-Latn-ID",
    "ktt": "ktt-Latn-ID",
    "ktu": "ktu-Latn-CD",
    "ktv": "ktv-Latn-VN",
    "ktw": "ktw-Latn-US",
    "ktx": "ktx-Latn-BR",
    "kty": "kty-Latn-CD",
    "ktz": "ktz-Latn-NA",
    "ku": "ku-Latn-TR",
    "ku-Arab": "ku-Arab-IQ",
    "ku-LB": "ku-Arab-LB",
    "ku-Yezi": "ku-Yezi-GE",
    "kub": "kub-Latn-NG",
    "kuc": "kuc-Latn-ID",
    "kud": "kud-Latn-PG",
    "kue": "kue-Latn-PG",
    "kuf": "kuf-Laoo-LA",
    "kug": "kug-Latn-NG",
    "kuh": "kuh-Latn-NG",
    "kui": "kui-Latn-BR",
    "kuj": "kuj-Latn-TZ",
    "kuk": "kuk-Latn-ID",
    "kul": "kul-Latn-NG",
    "kum": "kum-Cyrl-RU",
    "kun": "kun-Latn-ER",
    "kuo": "kuo-Latn-PG",
    "kup": "kup-Latn-PG",
    "kuq": "kuq-Latn-BR",
    "kus": "kus-Latn-GH",
    "kut": "kut-Latn-CA",
    "kuu": "kuu-Latn-US",
    "kuv": "kuv-Latn-ID",
    "kuw": "kuw-Latn-CF",
    "kux": "kux-Latn-AU",
    "kuy": "kuy-Latn-AU",
    "kuz": "kuz-Latn-CL",
    "kv": "kv-Cyrl-RU",
    "kva": "kva-Cyrl-RU",
    "kvb": "kvb-Latn-ID",
    "kvc": "kvc-Latn-PG",
    "kvd": "kvd-Latn-ID",
    "kve": "kve-Latn-MY",
    "kvf": "kvf-Latn-TD",
    "kvg": "kvg-Latn-PG",
    "kvh": "kvh-Latn-ID",
    "kvi": "kvi-Latn-TD",
    "kvj": "kvj-Latn-CM",
    "kvl": "kvl-Latn-MM",
    "kvm": "kvm-Latn-CM",
    "kvn": "kvn-Latn-CO",
    "kvo": "kvo-Latn-ID",
    "kvp": "kvp-Latn-ID",
    "kvq": "kvq-Mymr-MM",
    "kvr": "kvr-Latn-ID",
    "kvt": "kvt-Mymr-MM",
    "kvv": "kvv-Latn-ID",
    "kvw": "kvw-Latn-ID",
    "kvx": "kvx-Arab-PK",
    "kvy": "kvy-Kali-MM",
    "kvz": "kvz-Latn-ID",
    "kw": "kw-Latn-GB",
    "kwa": "kwa-Latn-BR",
    "kwb": "kwb-Latn-NG",
    "kwc": "kwc-Latn-CG",
    "kwd": "kwd-Latn-SB",
    "kwe": "kwe-Latn-ID",
    "kwf": "kwf-Latn-SB",
    "kwg": "kwg-Latn-TD",
    "kwh": "kwh-Latn-ID",
    "kwi": "kwi-Latn-CO",
    "kwj": "kwj-Latn-PG",
    "kwk": "kwk-Latn-CA",
    "kwl": "kwl-Latn-NG",
    "kwm": "kwm-Latn-NA",
    "kwn": "kwn-Latn-NA",
    "kwo": "kwo-Latn-PG",
    "kwp": "kwp-Latn-CI",
    "kwr": "kwr-Latn-ID",
    "kws": "kws-Latn-CD",
    "kwt": "kwt-Latn-ID",
    "kwu": "kwu-Latn-CM",
    "kwv": "kwv-Latn-TD",
    "kww": "kww-Latn-SR",
    "kwy": "kwy-Latn-AO",
    "kwz": "kwz-Latn-AO",
    "kxa": "kxa-Latn-PG",
    "kxb": "kxb-Latn-CI",
    "kxc": "kxc-Latn-ET",
    "kxd": "kxd-Latn-BN",
    "kxf": "kxf-Mymr-MM",
    "kxi": "kxi-Latn-MY",
    "kxj": "kxj-Latn-TD",
    "kxk": "kxk-Mymr-MM",
    "kxm": "kxm-Thai-TH",
    "kxn": "kxn-Latn-MY",
    "kxo": "kxo-Latn-BR",
    "kxp": "kxp-Arab-PK",
    "kxq": "kxq-Latn-ID",
    "kxr": "kxr-Latn-PG",
    "kxt": "kxt-Latn-PG",
    "kxv": "kxv-Latn-IN",
    "kxw": "kxw-Latn-PG",
    "kxx": "kxx-Latn-CG",
    "kxy": "kxy-Latn-VN",
    "kxz": "kxz-Latn-PG",
    "ky": "ky-Cyrl-KG",
    "ky-Arab": "ky-Arab-CN",
    "ky-CN": "ky-Arab-CN",
    "ky-Latn": "ky-Latn-TR",
    "ky-TR": "ky-Latn-TR",
    "kya": "kya-Latn-TZ",
    "kyb": "kyb-Latn-PH",
    "kyc": "kyc-Latn-PG",
    "kyd": "kyd-Latn-ID",
    "kye": "kye-Latn-GH",
    "kyf": "kyf-Latn-CI",
    "kyg": "kyg-Latn-PG",
    "kyh": "kyh-Latn-US",
    "kyi": "kyi-Latn-MY",
    "kyj": "kyj-Latn-PH",
    "kyk": "kyk-Latn-PH",
    "kyl": "kyl-Latn-US",
    "kym": "kym-Latn-CF",
    "kyn": "kyn-Latn-PH",
    "kyo": "kyo-Latn-ID",
    "kyq": "kyq-Latn-TD",
    "kyr": "kyr-Latn-BR",
    "kys": "kys-Latn-MY",
    "kyt": "kyt-Latn-ID",
    "kyu": "kyu-Kali-MM",
    "kyv": "kyv-Deva-NP",
    "kyw": "kyw-Deva-IN",
    "kyx": "kyx-Latn-PG",
    "kyy": "kyy-Latn-PG",
    "kyz": "kyz-Latn-BR",
    "kza": "kza-Latn-BF",
    "kzb": "kzb-Latn-ID",
    "kzc": "kzc-Latn-CI",
    "kzd": "kzd-Latn-ID",
    "kze": "kze-Latn-PG",
    "kzf": "kzf-Latn-ID",
    "kzi": "kzi-Latn-MY",
    "kzk": "kzk-Latn-SB",
    "kzl": "kzl-Latn-ID",
    "kzm": "kzm-Latn-ID",
    "kzn": "kzn-Latn-MW",
    "kzo": "kzo-Latn-GA",
    "kzp": "kzp-Latn-ID",
    "kzr": "kzr-Latn-CM",
    "kzs": "kzs-Latn-MY",
    "kzu": "kzu-Latn-ID",
    "kzv": "kzv-Latn-ID",
    "kzw": "kzw-Latn-BR",
    "kzx": "kzx-Latn-ID",
    "kzy": "kzy-Latn-CD",
    "kzz": "kzz-Latn-ID",
    "la": "la-Latn-VA",
    "laa": "laa-Latn-PH",
    "lab": "lab-Lina-GR",
    "lac": "lac-Latn-MX",
    "lad": "lad-Hebr-IL",
    "lae": "lae-Deva-IN",
    "lag": "lag-Latn-TZ",
    "lah": "lah-Arab-PK",
    "lai": "lai-Latn-MW",
    "laj": "laj-Latn-UG",
    "lal": "lal-Latn-CD",
    "lam": "lam-Latn-ZM",
    "lan": "lan-Latn-NG",
    "lap": "lap-Latn-TD",
    "laq": "laq-Latn-VN",
    "lar": "lar-Latn-GH",
    "las": "las-Latn-TG",
    "lau": "lau-Latn-ID",
    "law": "law-Latn-ID",
    "lax": "lax-Latn-IN",
    "laz": "laz-Latn-PG",
    "lb": "lb-Latn-LU",
    "lbb": "lbb-Latn-PG",
    "lbe": "lbe-Cyrl-RU",
    "lbf": "lbf-Deva-IN",
    "lbi": "lbi-Latn-CM",
    "lbj": "lbj-Tibt-IN",
    "lbl": "lbl-Latn-PH",
    "lbm": "lbm-Deva-IN",
    "lbn": "lbn-Latn-LA",
    "lbo": "lbo-Laoo-LA",
    "lbq": "lbq-Latn-PG",
    "lbr": "lbr-Deva-NP",
    "lbt": "lbt-Latn-VN",
    "lbu": "lbu-Latn-PG",
    "lbv": "lbv-Latn-PG",
    "lbw": "lbw-Latn-ID",
    "lbx": "lbx-Latn-ID",
    "lby": "lby-Latn-AU",
    "lbz": "lbz-Latn-AU",
    "lcc": "lcc-Latn-ID",
    "lcd": "lcd-Latn-ID",
    "lce": "lce-Latn-ID",
    "lcf": "lcf-Latn-ID",
    "lch": "lch-Latn-AO",
    "lcl": "lcl-Latn-ID",
    "lcm": "lcm-Latn-PG",
    "lcp": "lcp-Thai-CN",
    "lcq": "lcq-Latn-ID",
    "lcs": "lcs-Latn-ID",
    "lda": "lda-Latn-CI",
    "ldb": "ldb-Latn-NG",
    "ldd": "ldd-Latn-NG",
    "ldg": "ldg-Latn-NG",
    "ldh": "ldh-Latn-NG",
    "ldi": "ldi-Latn-CG",
    "ldj": "ldj-Latn-NG",
    "ldk": "ldk-Latn-NG",
    "ldl": "ldl-Latn-NG",
    "ldm": "ldm-Latn-GN",
    "ldn": "ldn-Latn-001",
    "ldo": "ldo-Latn-NG",
    "ldp": "ldp-Latn-NG",
    "ldq": "ldq-Latn-NG",
    "lea": "lea-Latn-CD",
    "leb": "leb-Latn-ZM",
    "lec": "lec-Latn-BO",
    "led": "led-Latn-CD",
    "lee": "lee-Latn-BF",
    "lef": "lef-Latn-GH",
    "leh": "leh-Latn-ZM",
    "lei": "lei-Latn-PG",
    "lej": "lej-Latn-CD",
    "lek": "lek-Latn-PG",
    "lel": "lel-Latn-CD",
    "lem": "lem-Latn-CM",
    "len": "len-Latn-HN",
    "leo": "leo-Latn-CM",
    "lep": "lep-Lepc-IN",
    "leq": "leq-Latn-PG",
    "ler": "ler-Latn-PG",
    "les": "les-Latn-CD",
    "let": "let-Latn-PG",
    "leu": "leu-Latn-PG",
    "lev": "lev-Latn-ID",
    "lew": "lew-Latn-ID",
    "lex": "lex-Latn-ID",
    "ley": "ley-Latn-ID",
    "lez": "lez-Cyrl-RU",
    "lfa": "lfa-Latn-CM",
    "lfn": "lfn-Latn-001",
    "lg": "lg-Latn-UG",
    "lga": "lga-Latn-SB",
    "lgb": "lgb-Latn-SB",
    "lgg": "lgg-Latn-UG",
    "lgh": "lgh-Latn-VN",
    "lgi": "lgi-Latn-ID",
    "lgk": "lgk-Latn-VU",
    "lgl": "lgl-Latn-SB",
    "lgm": "lgm-Latn-CD",
    "lgn": "lgn-Latn-ET",
    "lgo": "lgo-Latn-SS",
    "lgq": "lgq-Latn-GH",
    "lgr": "lgr-Latn-SB",
    "lgt": "lgt-Latn-PG",
    "lgu": "lgu-Latn-SB",
    "lgz": "lgz-Latn-CD",
    "lha": "lha-Latn-VN",
    "lhh": "lhh-Latn-ID",
    "lhi": "lhi-Latn-CN",
    "lhm": "lhm-Deva-NP",
    "lhn": "lhn-Latn-MY",
    "lhs": "lhs-Syrc-SY",
    "lht": "lht-Latn-VU",
    "lhu": "lhu-Latn-CN",
    "li": "li-Latn-NL",
    "lia": "lia-Latn-SL",
    "lib": "lib-Latn-PG",
    "lic": "lic-Latn-CN",
    "lid": "lid-Latn-PG",
    "lie": "lie-Latn-CD",
    "lif": "lif-Deva-NP",
    "lif-Limb": "lif-Limb-IN",
    "lig": "lig-Latn-GH",
    "lih": "lih-Latn-PG",
    "lij": "lij-Latn-IT",
    "lik": "lik-Latn-CD",
    "lil": "lil-Latn-CA",
    "lio": "lio-Latn-ID",
    "lip": "lip-Latn-GH",
    "liq": "liq-Latn-ET",
    "lir": "lir-Latn-LR",
    "lis": "lis-Lisu-CN",
    "liu": "liu-Latn-SD",
    "liv": "liv-Latn-LV",
    "liw": "liw-Latn-ID",
    "lix": "lix-Latn-ID",
    "liy": "liy-Latn-CF",
    "liz": "liz-Latn-CD",
    "lja": "lja-Latn-AU",
    "lje": "lje-Latn-ID",
    "lji": "lji-Latn-ID",
    "ljl": "ljl-Latn-ID",
    "ljp": "ljp-Latn-ID",
    "ljw": "ljw-Latn-AU",
    "ljx": "ljx-Latn-AU",
    "lka": "lka-Latn-TL",
    "lkb": "lkb-Latn-KE",
    "lkc": "lkc-Latn-VN",
    "lkd": "lkd-Latn-BR",
    "lke": "lke-Latn-UG",
    "lkh": "lkh-Tibt-BT",
    "lki": "lki-Arab-IR",
    "lkj": "lkj-Latn-MY",
    "lkl": "lkl-Latn-PG",
    "lkm": "lkm-Latn-AU",
    "lkn": "lkn-Latn-VU",
    "lko": "lko-Latn-KE",
    "lkr": "lkr-Latn-SS",
    "lks": "lks-Latn-KE",
    "lkt": "lkt-Latn-US",
    "lku": "lku-Latn-AU",
    "lky": "lky-Latn-SS",
    "lla": "lla-Latn-NG",
    "llb": "llb-Latn-MZ",
    "llc": "llc-Latn-GN",
    "lld": "lld-Latn-IT",
    "lle": "lle-Latn-PG",
    "llf": "llf-Latn-PG",
    "llg": "llg-Latn-ID",
    "lli": "lli-Latn-CG",
    "llj": "llj-Latn-AU",
    "llk": "llk-Latn-MY",
    "lll": "lll-Latn-PG",
    "llm": "llm-Latn-ID",
    "lln": "lln-Latn-TD",
    "llp": "llp-Latn-VU",
    "llq": "llq-Latn-ID",
    "llu": "llu-Latn-SB",
    "llx": "llx-Latn-FJ",
    "lma": "lma-Latn-GN",
    "lmb": "lmb-Latn-VU",
    "lmc": "lmc-Latn-AU",
    "lmd": "lmd-Latn-SD",
    "lme": "lme-Latn-TD",
    "lmf": "lmf-Latn-ID",
    "lmg": "lmg-Latn-PG",
    "lmh": "lmh-Deva-NP",
    "lmi": "lmi-Latn-CD",
    "lmj": "lmj-Latn-ID",
    "lmk": "lmk-Latn-IN",
    "lml": "lml-Latn-VU",
    "lmn": "lmn-Telu-IN",
    "lmo": "lmo-Latn-IT",
    "lmp": "lmp-Latn-CM",
    "lmq": "lmq-Latn-ID",
    "lmr": "lmr-Latn-ID",
    "lmu": "lmu-Latn-VU",
    "lmv": "lmv-Latn-FJ",
    "lmw": "lmw-Latn-US",
    "lmx": "lmx-Latn-CM",
    "lmy": "lmy-Latn-ID",
    "ln": "ln-Latn-CD",
    "lna": "lna-Latn-CF",
    "lnb": "lnb-Latn-NA",
    "lnd": "lnd-Latn-ID",
    "lng": "lng-Latn-HU",
    "lnh": "lnh-Latn-MY",
    "lni": "lni-Latn-PG",
    "lnj": "lnj-Latn-AU",
    "lnl": "lnl-Latn-CF",
    "lnm": "lnm-Latn-PG",
    "lnn": "lnn-Latn-VU",
    "lns": "lns-Latn-CM",
    "lnu": "lnu-Latn-NG",
    "lnw": "lnw-Latn-AU",
    "lnz": "lnz-Latn-CD",
    "lo": "lo-Laoo-LA",
    "loa": "loa-Latn-ID",
    "lob": "lob-Latn-BF",
    "loc": "loc-Latn-PH",
    "loe": "loe-Latn-ID",
    "log": "log-Latn-CD",
    "loh": "loh-Latn-SS",
    "loi": "loi-Latn-CI",
    "loj": "loj-Latn-PG",
    "lok": "lok-Latn-SL",
    "lol": "lol-Latn-CD",
    "lom": "lom-Latn-LR",
    "lon": "lon-Latn-MW",
    "loo": "loo-Latn-CD",
    "lop": "lop-Latn-NG",
    "loq": "loq-Latn-CD",
    "lor": "lor-Latn-CI",
    "los": "los-Latn-PG",
    "lot": "lot-Latn-SS",
    "lou": "lou-Latn-US",
    "low": "low-Latn-MY",
    "lox": "lox-Latn-ID",
    "loy": "loy-Deva-NP",
    "loz": "loz-Latn-ZM",
    "lpa": "lpa-Latn-VU",
    "lpe": "lpe-Latn-ID",
    "lpn": "lpn-Latn-MM",
    "lpo": "lpo-Plrd-CN",
    "lpx": "lpx-Latn-SS",
    "lqr": "lqr-Latn-SS",
    "lra": "lra-Latn-MY",
    "lrc": "lrc-Arab-IR",
    "lrg": "lrg-Latn-AU",
    "lri": "lri-Latn-KE",
    "lrk": "lrk-Arab-PK",
    "lrl": "lrl-Arab-IR",
    "lrm": "lrm-Latn-KE",
    "lrn": "lrn-Latn-ID",
    "lro": "lro-Latn-SD",
    "lrt": "lrt-Latn-ID",
    "lrv": "lrv-Latn-VU",
    "lrz": "lrz-Latn-VU",
    "lsa": "lsa-Arab-IR",
    "lsd": "lsd-Hebr-IL",
    "lse": "lse-Latn-CD",
    "lsi": "lsi-Latn-MM",
    "lsm": "lsm-Latn-UG",
    "lsr": "lsr-Latn-PG",
    "lss": "lss-Arab-PK",
    "lt": "lt-Latn-LT",
    "ltc": "ltc-Hant-CN",
    "ltg": "ltg-Latn-LV",
    "lth": "lth-Latn-UG",
    "lti": "lti-Latn-ID",
    "ltn": "ltn-Latn-BR",
    "lto": "lto-Latn-KE",
    "lts": "lts-Latn-KE",
    "ltu": "ltu-Latn-ID",
    "lu": "lu-Latn-CD",
    "lua": "lua-Latn-CD",
    "luc": "luc-Latn-UG",
    "lud": "lud-Latn-RU",
    "lue": "lue-Latn-ZM",
    "luf": "luf-Latn-PG",
    "lui": "lui-Latn-US",
    "luj": "luj-Latn-CD",
    "luk": "luk-Tibt-BT",
    "lul": "lul-Latn-SS",
    "lum": "lum-Latn-AO",
    "lun": "lun-Latn-ZM",
    "luo": "luo-Latn-KE",
    "lup": "lup-Latn-GA",
    "luq": "luq-Latn-CU",
    "lur": "lur-Latn-ID",
    "lus": "lus-Latn-IN",
    "lut": "lut-Latn-US",
    "luu": "luu-Deva-NP",
    "luv": "luv-Arab-OM",
    "luw": "luw-Latn-CM",
    "luy": "luy-Latn-KE",
    "luz": "luz-Arab-IR",
    "lv": "lv-Latn-LV",
    "lva": "lva-Latn-TL",
    "lvi": "lvi-Latn-LA",
    "lvk": "lvk-Latn-SB",
    "lvl": "lvl-Latn-CD",
    "lvu": "lvu-Latn-ID",
    "lwa": "lwa-Latn-CD",
    "lwe": "lwe-Latn-ID",
    "lwg": "lwg-Latn-KE",
    "lwh": "lwh-Latn-VN",
    "lwl": "lwl-Thai-TH",
    "lwm": "lwm-Thai-CN",
    "lwo": "lwo-Latn-SS",
    "lwt": "lwt-Latn-ID",
    "lww": "lww-Latn-VU",
    "lxm": "lxm-Latn-PG",
    "lya": "lya-Tibt-BT",
    "lyn": "lyn-Latn-ZM",
    "lzh": "lzh-Hans-CN",
    "lzl": "lzl-Latn-VU",
    "lzn": "lzn-Latn-MM",
    "lzz": "lzz-Latn-TR",
    "maa": "maa-Latn-MX",
    "mab": "mab-Latn-MX",
    "mad": "mad-Latn-ID",
    "mae": "mae-Latn-NG",
    "maf": "maf-Latn-CM",
    "mag": "mag-Deva-IN",
    "mai": "mai-Deva-IN",
    "maj": "maj-Latn-MX",
    "mak": "mak-Latn-ID",
    "mam": "mam-Latn-GT",
    "man": "man-Latn-GM",
    "man-GN": "man-Nkoo-GN",
    "man-Nkoo": "man-Nkoo-GN",
    "maq": "maq-Latn-MX",
    "mas": "mas-Latn-KE",
    "mat": "mat-Latn-MX",
    "mau": "mau-Latn-MX",
    "mav": "mav-Latn-BR",
    "maw": "maw-Latn-GH",
    "max": "max-Latn-ID",
    "maz": "maz-Latn-MX",
    "mba": "mba-Latn-PH",
    "mbb": "mbb-Latn-PH",
    "mbc": "mbc-Latn-BR",
    "mbd": "mbd-Latn-PH",
    "mbf": "mbf-Latn-SG",
    "mbh": "mbh-Latn-PG",
    "mbi": "mbi-Latn-PH",
    "mbj": "mbj-Latn-BR",
    "mbk": "mbk-Latn-PG",
    "mbl": "mbl-Latn-BR",
    "mbm": "mbm-Latn-CG",
    "mbn": "mbn-Latn-CO",
    "mbo": "mbo-Latn-CM",
    "mbp": "mbp-Latn-CO",
    "mbq": "mbq-Latn-PG",
    "mbr": "mbr-Latn-CO",
    "mbs": "mbs-Latn-PH",
    "mbt": "mbt-Latn-PH",
    "mbu": "mbu-Latn-NG",
    "mbv": "mbv-Latn-GN",
    "mbw": "mbw-Latn-PG",
    "mbx": "mbx-Latn-PG",
    "mby": "mby-Arab-PK",
    "mbz": "mbz-Latn-MX",
    "mca": "mca-Latn-PY",
    "mcb": "mcb-Latn-PE",
    "mcc": "mcc-Latn-PG",
    "mcd": "mcd-Latn-PE",
    "mce": "mce-Latn-MX",
    "mcf": "mcf-Latn-PE",
    "mcg": "mcg-Latn-VE",
    "mch": "mch-Latn-VE",
    "mci": "mci-Latn-PG",
    "mcj": "mcj-Latn-NG",
    "mck": "mck-Latn-AO",
    "mcl": "mcl-Latn-CO",
    "mcm": "mcm-Latn-MY",
    "mcn": "mcn-Latn-TD",
    "mco": "mco-Latn-MX",
    "mcp": "mcp-Latn-CM",
    "mcq": "mcq-Latn-PG",
    "mcr": "mcr-Latn-PG",
    "mcs": "mcs-Latn-CM",
    "mct": "mct-Latn-CM",
    "mcu": "mcu-Latn-CM",
    "mcv": "mcv-Latn-PG",
    "mcw": "mcw-Latn-TD",
    "mcx": "mcx-Latn-CF",
    "mcy": "mcy-Latn-PG",
    "mcz": "mcz-Latn-PG",
    "mda": "mda-Latn-NG",
    "mdb": "mdb-Latn-PG",
    "mdc": "mdc-Latn-PG",
    "mdd": "mdd-Latn-CM",
    "mde": "mde-Arab-TD",
    "mdf": "mdf-Cyrl-RU",
    "mdg": "mdg-Latn-TD",
    "mdh": "mdh-Latn-PH",
    "mdi": "mdi-Latn-CD",
    "mdj": "mdj-Latn-CD",
    "mdk": "mdk-Latn-CD",
    "mdm": "mdm-Latn-CD",
    "mdn": "mdn-Latn-CF",
    "mdp": "mdp-Latn-CD",
    "mdq": "mdq-Latn-CD",
    "mdr": "mdr-Latn-ID",
    "mds": "mds-Latn-PG",
    "mdt": "mdt-Latn-CG",
    "mdu": "mdu-Latn-CG",
    "mdv": "mdv-Latn-MX",
    "mdw": "mdw-Latn-CG",
    "mdx": "mdx-Ethi-ET",
    "mdy": "mdy-Ethi-ET",
    "mdz": "mdz-Latn-BR",
    "mea": "mea-Latn-CM",
    "meb": "meb-Latn-PG",
    "mec": "mec-Latn-AU",
    "med": "med-Latn-PG",
    "mee": "mee-Latn-PG",
    "meh": "meh-Latn-MX",
    "mej": "mej-Latn-ID",
    "mek": "mek-Latn-PG",
    "mel": "mel-Latn-MY",
    "mem": "mem-Latn-AU",
    "men": "men-Latn-SL",
    "meo": "meo-Latn-MY",
    "mep": "mep-Latn-AU",
    "meq": "meq-Latn-CM",
    "mer": "mer-Latn-KE",
    "mes": "mes-Latn-TD",
    "met": "met-Latn-PG",
    "meu": "meu-Latn-PG",
    "mev": "mev-Latn-LR",
    "mew": "mew-Latn-NG",
    "mey": "mey-Latn-SN",
    "mez": "mez-Latn-US",
    "mfa": "mfa-Arab-TH",
    "mfb": "mfb-Latn-ID",
    "mfc": "mfc-Latn-CD",
    "mfd": "mfd-Latn-CM",
    "mfe": "mfe-Latn-MU",
    "mff": "mff-Latn-CM",
    "mfg": "mfg-Latn-GN",
    "mfh": "mfh-Latn-CM",
    "mfi": "mfi-Arab-CM",
    "mfj": "mfj-Latn-CM",
    "mfk": "mfk-Latn-CM",
    "mfl": "mfl-Latn-NG",
    "mfm": "mfm-Latn-NG",
    "mfn": "mfn-Latn-NG",
    "mfo": "mfo-Latn-NG",
    "mfp": "mfp-Latn-ID",
    "mfq": "mfq-Latn-TG",
    "mfr": "mfr-Latn-AU",
    "mft": "mft-Latn-PG",
    "mfu": "mfu-Latn-AO",
    "mfv": "mfv-Latn-SN",
    "mfw": "mfw-Latn-PG",
    "mfx": "mfx-Latn-ET",
    "mfy": "mfy-Latn-MX",
    "mfz": "mfz-Latn-SS",
    "mg": "mg-Latn-MG",
    "mga": "mga-Latg-IE",
    "mgb": "mgb-Latn-TD",
    "mgc": "mgc-Latn-SS",
    "mgd": "mgd-Latn-SS",
    "mge": "mge-Latn-TD",
    "mgf": "mgf-Latn-ID",
    "mgg": "mgg-Latn-CM",
    "mgh": "mgh-Latn-MZ",
    "mgi": "mgi-Latn-NG",
    "mgj": "mgj-Latn-NG",
    "mgk": "mgk-Latn-ID",
    "mgl": "mgl-Latn-PG",
    "mgm": "mgm-Latn-TL",
    "mgn": "mgn-Latn-CF",
    "mgo": "mgo-Latn-CM",
    "mgp": "mgp-Deva-NP",
    "mgq": "mgq-Latn-TZ",
    "mgr": "mgr-Latn-ZM",
    "mgs": "mgs-Latn-TZ",
    "mgt": "mgt-Latn-PG",
    "mgu": "mgu-Latn-PG",
    "mgv": "mgv-Latn-TZ",
    "mgw": "mgw-Latn-TZ",
    "mgy": "mgy-Latn-TZ",
    "mgz": "mgz-Latn-TZ",
    "mh": "mh-Latn-MH",
    "mhb": "mhb-Latn-GA",
    "mhc": "mhc-Latn-MX",
    "mhd": "mhd-Latn-TZ",
    "mhe": "mhe-Latn-MY",
    "mhf": "mhf-Latn-PG",
    "mhg": "mhg-Latn-AU",
    "mhi": "mhi-Latn-UG",
    "mhj": "mhj-Arab-AF",
    "mhk": "mhk-Latn-CM",
    "mhl": "mhl-Latn-PG",
    "mhm": "mhm-Latn-MZ",
    "mhn": "mhn-Latn-IT",
    "mho": "mho-Latn-ZM",
    "mhp": "mhp-Latn-ID",
    "mhq": "mhq-Latn-US",
    "mhs": "mhs-Latn-ID",
    "mht": "mht-Latn-VE",
    "mhu": "mhu-Latn-IN",
    "mhw": "mhw-Latn-BW",
    "mhx": "mhx-Latn-MM",
    "mhy": "mhy-Latn-ID",
    "mhz": "mhz-Latn-ID",
    "mi": "mi-Latn-NZ",
    "mia": "mia-Latn-US",
    "mib": "mib-Latn-MX",
    "mic": "mic-Latn-CA",
    "mid": "mid-Mand-IQ",
    "mie": "mie-Latn-MX",
    "mif": "mif-Latn-CM",
    "mig": "mig-Latn-MX",
    "mih": "mih-Latn-MX",
    "mii": "mii-Latn-MX",
    "mij": "mij-Latn-CM",
    "mik": "mik-Latn-US",
    "mil": "mil-Latn-MX",
    "mim": "mim-Latn-MX",
    "min": "min-Latn-ID",
    "mio": "mio-Latn-MX",
    "mip": "mip-Latn-MX",
    "miq": "miq-Latn-NI",
    "mir": "mir-Latn-MX",
    "mit": "mit-Latn-MX",
    "miu": "miu-Latn-MX",
    "miw": "miw-Latn-PG",
    "mix": "mix-Latn-MX",
    "miy": "miy-Latn-MX",
    "miz": "miz-Latn-MX",
    "mjb": "mjb-Latn-TL",
    "mjc": "mjc-Latn-MX",
    "mjd": "mjd-Latn-US",
    "mje": "mje-Latn-TD",
    "mjg": "mjg-Latn-CN",
    "mjh": "mjh-Latn-TZ",
    "mji": "mji-Latn-CN",
    "mjj": "mjj-Latn-PG",
    "mjk": "mjk-Latn-PG",
    "mjl": "mjl-Deva-IN",
    "mjm": "mjm-Latn-PG",
    "mjn": "mjn-Latn-PG",
    "mjq": "mjq-Mlym-IN",
    "mjr": "mjr-Mlym-IN",
    "mjs": "mjs-Latn-NG",
    "mjt": "mjt-Deva-IN",
    "mju": "mju-Telu-IN",
    "mjv": "mjv-Mlym-IN",
    "mjw": "mjw-Latn-IN",
    "mjx": "mjx-Latn-BD",
    "mjy": "mjy-Latn-US",
    "mjz": "mjz-Deva-NP",
    "mk": "mk-Cyrl-MK",
    "mka": "mka-Latn-CI",
    "mkb": "mkb-Deva-IN",
    "mkc": "mkc-Latn-PG",
    "mke": "mke-Deva-IN",
    "mkf": "mkf-Latn-NG",
    "mki": "mki-Arab-PK",
    "mkj": "mkj-Latn-FM",
    "mkk": "mkk-Latn-CM",
    "mkl": "mkl-Latn-BJ",
    "mkm": "mkm-Thai-TH",
    "mkn": "mkn-Latn-ID",
    "mko": "mko-Latn-NG",
    "mkp": "mkp-Latn-PG",
    "mkr": "mkr-Latn-PG",
    "mks": "mks-Latn-MX",
    "mkt": "mkt-Latn-NC",
    "mku": "mku-Latn-GN",
    "mkv": "mkv-Latn-VU",
    "mkw": "mkw-Latn-CG",
    "mkx": "mkx-Latn-PH",
    "mky": "mky-Latn-ID",
    "mkz": "mkz-Latn-TL",
    "ml": "ml-Mlym-IN",
    "mla": "mla-Latn-VU",
    "mlb": "mlb-Latn-CM",
    "mlc": "mlc-Latn-VN",
    "mle": "mle-Latn-PG",
    "mlf": "mlf-Thai-LA",
    "mlh": "mlh-Latn-PG",
    "mli": "mli-Latn-ID",
    "mlj": "mlj-Latn-TD",
    "mlk": "mlk-Latn-KE",
    "mll": "mll-Latn-VU",
    "mln": "mln-Latn-SB",
    "mlo": "mlo-Latn-SN",
    "mlp": "mlp-Latn-PG",
    "mlq": "mlq-Latn-SN",
    "mlr": "mlr-Latn-CM",
    "mls": "mls-Latn-SD",
    "mlu": "mlu-Latn-SB",
    "mlv": "mlv-Latn-VU",
    "mlw": "mlw-Latn-CM",
    "mlx": "mlx-Latn-VU",
    "mlz": "mlz-Latn-PH",
    "mma": "mma-Latn-NG",
    "mmb": "mmb-Latn-ID",
    "mmc": "mmc-Latn-MX",
    "mmd": "mmd-Latn-CN",
    "mme": "mme-Latn-VU",
    "mmf": "mmf-Latn-NG",
    "mmg": "mmg-Latn-VU",
    "mmh": "mmh-Latn-BR",
    "mmi": "mmi-Latn-PG",
    "mmm": "mmm-Latn-VU",
    "mmn": "mmn-Latn-PH",
    "mmo": "mmo-Latn-PG",
    "mmp": "mmp-Latn-PG",
    "mmq": "mmq-Latn-PG",
    "mmr": "mmr-Latn-CN",
    "mmt": "mmt-Latn-PG",
    "mmu": "mmu-Latn-CM",
    "mmv": "mmv-Latn-BR",
    "mmw": "mmw-Latn-VU",
    "mmx": "mmx-Latn-PG",
    "mmy": "mmy-Latn-TD",
    "mmz": "mmz-Latn-CD",
    "mn": "mn-Cyrl-MN",
    "mn-CN": "mn-Mong-CN",
    "mn-Mong": "mn-Mong-CN",
    "mna": "mna-Latn-PG",
    "mnb": "mnb-Latn-ID",
    "mnc": "mnc-Mong-CN",
    "mnd": "mnd-Latn-BR",
    "mne": "mne-Latn-TD",
    "mnf": "mnf-Latn-CM",
    "mng": "mng-Latn-VN",
    "mnh": "mnh-Latn-CD",
    "mni": "mni-Beng-IN",
    "mnj": "mnj-Arab-AF",
    "mnl": "mnl-Latn-VU",
    "mnm": "mnm-Latn-PG",
    "mnn": "mnn-Latn-VN",
    "mnp": "mnp-Latn-CN",
    "mnq": "mnq-Latn-MY",
    "mnr": "mnr-Latn-US",
    "mns": "mns-Cyrl-RU",
    "mnu": "mnu-Latn-ID",
    "mnv": "mnv-Latn-SB",
    "mnw": "mnw-Mymr-MM",
    "mnx": "mnx-Latn-ID",
    "mny": "mny-Latn-MZ",
    "mnz": "mnz-Latn-ID",
    "mo": "mo-Latn-RO",
    "moa": "moa-Latn-CI",
    "moc": "moc-Latn-AR",
    "mod": "mod-Latn-US",
    "moe": "moe-Latn-CA",
    "mog": "mog-Latn-ID",
    "moh": "moh-Latn-CA",
    "moi": "moi-Latn-NG",
    "moj": "moj-Latn-CG",
    "mok": "mok-Latn-ID",
    "mom": "mom-Latn-NI",
    "moo": "moo-Latn-VN",
    "mop": "mop-Latn-BZ",
    "moq": "moq-Latn-ID",
    "mor": "mor-Latn-SD",
    "mos": "mos-Latn-BF",
    "mot": "mot-Latn-CO",
    "mou": "mou-Latn-TD",
    "mov": "mov-Latn-US",
    "mow": "mow-Latn-CG",
    "mox": "mox-Latn-PG",
    "moy": "moy-Latn-ET",
    "moz": "moz-Latn-TD",
    "mpa": "mpa-Latn-TZ",
    "mpb": "mpb-Latn-AU",
    "mpc": "mpc-Latn-AU",
    "mpd": "mpd-Latn-BR",
    "mpe": "mpe-Latn-ET",
    "mpg": "mpg-Latn-TD",
    "mph": "mph-Latn-AU",
    "mpi": "mpi-Latn-CM",
    "mpj": "mpj-Latn-AU",
    "mpk": "mpk-Latn-TD",
    "mpl": "mpl-Latn-PG",
    "mpm": "mpm-Latn-MX",
    "mpn": "mpn-Latn-PG",
    "mpo": "mpo-Latn-PG",
    "mpp": "mpp-Latn-PG",
    "mpq": "mpq-Latn-BR",
    "mpr": "mpr-Latn-SB",
    "mps": "mps-Latn-PG",
    "mpt": "mpt-Latn-PG",
    "mpu": "mpu-Latn-BR",
    "mpv": "mpv-Latn-PG",
    "mpw": "mpw-Latn-BR",
    "mpx": "mpx-Latn-PG",
    "mpy": "mpy-Latn-ID",
    "mpz": "mpz-Thai-TH",
    "mqa": "mqa-Latn-ID",
    "mqb": "mqb-Latn-CM",
    "mqc": "mqc-Latn-ID",
    "mqe": "mqe-Latn-PG",
    "mqf": "mqf-Latn-ID",
    "mqg": "mqg-Latn-ID",
    "mqh": "mqh-Latn-MX",
    "mqi": "mqi-Latn-ID",
    "mqj": "mqj-Latn-ID",
    "mqk": "mqk-Latn-PH",
    "mql": "mql-Latn-BJ",
    "mqm": "mqm-Latn-PF",
    "mqn": "mqn-Latn-ID",
    "mqo": "mqo-Latn-ID",
    "mqp": "mqp-Latn-ID",
    "mqq": "mqq-Latn-MY",
    "mqr": "mqr-Latn-ID",
    "mqs": "mqs-Latn-ID",
    "mqu": "mqu-Latn-SS",
    "mqv": "mqv-Latn-PG",
    "mqw": "mqw-Latn-PG",
    "mqx": "mqx-Latn-ID",
    "mqy": "mqy-Latn-ID",
    "mqz": "mqz-Latn-PG",
    "mr": "mr-Deva-IN",
    "mra": "mra-Thai-TH",
    "mrb": "mrb-Latn-VU",
    "mrc": "mrc-Latn-US",
    "mrd": "mrd-Deva-NP",
    "mrf": "mrf-Latn-ID",
    "mrg": "mrg-Latn-IN",
    "mrh": "mrh-Latn-IN",
    "mrj": "mrj-Cyrl-RU",
    "mrk": "mrk-Latn-NC",
    "mrl": "mrl-Latn-FM",
    "mrm": "mrm-Latn-VU",
    "mrn": "mrn-Latn-SB",
    "mro": "mro-Mroo-BD",
    "mrp": "mrp-Latn-VU",
    "mrq": "mrq-Latn-PF",
    "mrr": "mrr-Deva-IN",
    "mrs": "mrs-Latn-VU",
    "mrt": "mrt-Latn-NG",
    "mru": "mru-Latn-CM",
    "mrv": "mrv-Latn-PF",
    "mrw": "mrw-Latn-PH",
    "mrx": "mrx-Latn-ID",
    "mry": "mry-Latn-PH",
    "mrz": "mrz-Latn-ID",
    "ms": "ms-Latn-MY",
    "ms-CC": "ms-Arab-CC",
    "msb": "msb-Latn-PH",
    "msc": "msc-Latn-GN",
    "mse": "mse-Latn-TD",
    "msf": "msf-Latn-ID",
    "msg": "msg-Latn-ID",
    "msh": "msh-Latn-MG",
    "msi": "msi-Latn-MY",
    "msj": "msj-Latn-CD",
    "msk": "msk-Latn-PH",
    "msl": "msl-Latn-ID",
    "msm": "msm-Latn-PH",
    "msn": "msn-Latn-VU",
    "mso": "mso-Latn-ID",
    "msp": "msp-Latn-BR",
    "msq": "msq-Latn-NC",
    "mss": "mss-Latn-ID",
    "msu": "msu-Latn-PG",
    "msv": "msv-Latn-CM",
    "msw": "msw-Latn-GW",
    "msx": "msx-Latn-PG",
    "msy": "msy-Latn-PG",
    "msz": "msz-Latn-PG",
    "mt": "mt-Latn-MT",
    "mta": "mta-Latn-PH",
    "mtb": "mtb-Latn-CI",
    "mtc": "mtc-Latn-PG",
    "mtd": "mtd-Latn-ID",
    "mte": "mte-Latn-SB",
    "mtf": "mtf-Latn-PG",
    "mtg": "mtg-Latn-ID",
    "mth": "mth-Latn-ID",
    "mti": "mti-Latn-PG",
    "mtj": "mtj-Latn-ID",
    "mtk": "mtk-Latn-CM",
    "mtl": "mtl-Latn-NG",
    "mtm": "mtm-Cyrl-RU",
    "mtn": "mtn-Latn-NI",
    "mto": "mto-Latn-MX",
    "mtp": "mtp-Latn-BO",
    "mtq": "mtq-Latn-VN",
    "mtr": "mtr-Deva-IN",
    "mts": "mts-Latn-PE",
    "mtt": "mtt-Latn-VU",
    "mtu": "mtu-Latn-MX",
    "mtv": "mtv-Latn-PG",
    "mtw": "mtw-Latn-PH",
    "mtx": "mtx-Latn-MX",
    "mty": "mty-Latn-PG",
    "mua": "mua-Latn-CM",
    "mub": "mub-Latn-TD",
    "muc": "muc-Latn-CM",
    "mud": "mud-Cyrl-RU",
    "mue": "mue-Latn-EC",
    "mug": "mug-Latn-CM",
    "muh": "muh-Latn-SS",
    "mui": "mui-Latn-ID",
    "muj": "muj-Latn-TD",
    "muk": "muk-Tibt-NP",
    "mum": "mum-Latn-PG",
    "muo": "muo-Latn-CM",
    "muq": "muq-Latn-CN",
    "mur": "mur-Latn-SS",
    "mus": "mus-Latn-US",
    "mut": "mut-Deva-IN",
    "muu": "muu-Latn-KE",
    "muv": "muv-Taml-IN",
    "mux": "mux-Latn-PG",
    "muy": "muy-Latn-CM",
    "muz": "muz-Ethi-ET",
    "mva": "mva-Latn-PG",
    "mvd": "mvd-Latn-ID",
    "mve": "mve-Arab-PK",
    "mvf": "mvf-Mong-CN",
    "mvg": "mvg-Latn-MX",
    "mvh": "mvh-Latn-TD",
    "mvk": "mvk-Latn-PG",
    "mvl": "mvl-Latn-AU",
    "mvn": "mvn-Latn-PG",
    "mvo": "mvo-Latn-SB",
    "mvp": "mvp-Latn-ID",
    "mvq": "mvq-Latn-PG",
    "mvr": "mvr-Latn-ID",
    "mvs": "mvs-Latn-ID",
    "mvt": "mvt-Latn-VU",
    "mvu": "mvu-Latn-TD",
    "mvv": "mvv-Latn-MY",
    "mvw": "mvw-Latn-TZ",
    "mvx": "mvx-Latn-ID",
    "mvy": "mvy-Arab-PK",
    "mvz": "mvz-Ethi-ET",
    "mwa": "mwa-Latn-PG",
    "mwb": "mwb-Latn-PG",
    "mwc": "mwc-Latn-PG",
    "mwe": "mwe-Latn-TZ",
    "mwf": "mwf-Latn-AU",
    "mwg": "mwg-Latn-PG",
    "mwh": "mwh-Latn-PG",
    "mwi": "mwi-Latn-VU",
    "mwk": "mwk-Latn-ML",
    "mwl": "mwl-Latn-PT",
    "mwm": "mwm-Latn-TD",
    "mwn": "mwn-Latn-ZM",
    "mwo": "mwo-Latn-VU",
    "mwp": "mwp-Latn-AU",
    "mwq": "mwq-Latn-MM",
    "mwr": "mwr-Deva-IN",
    "mws": "mws-Latn-KE",
    "mwt": "mwt-Mymr-MM",
    "mwu": "mwu-Latn-SS",
    "mwv": "mwv-Latn-ID",
    "mww": "mww-Hmnp-US",
    "mwz": "mwz-Latn-CD",
    "mxa": "mxa-Latn-MX",
    "mxb": "mxb-Latn-MX",
    "mxc": "mxc-Latn-ZW",
    "mxd": "mxd-Latn-ID",
    "mxe": "mxe-Latn-VU",
    "mxf": "mxf-Latn-CM",
    "mxg": "mxg-Latn-AO",
    "mxh": "mxh-Latn-CD",
    "mxi": "mxi-Latn-ES",
    "mxj": "mxj-Latn-IN",
    "mxk": "mxk-Latn-PG",
    "mxl": "mxl-Latn-BJ",
    "mxm": "mxm-Latn-PG",
    "mxn": "mxn-Latn-ID",
    "mxo": "mxo-Latn-ZM",
    "mxp": "mxp-Latn-MX",
    "mxq": "mxq-Latn-MX",
    "mxr": "mxr-Latn-MY",
    "mxs": "mxs-Latn-MX",
    "mxt": "mxt-Latn-MX",
    "mxu": "mxu-Latn-CM",
    "mxv": "mxv-Latn-MX",
    "mxw": "mxw-Latn-PG",
    "mxx": "mxx-Latn-CI",
    "mxy": "mxy-Latn-MX",
    "mxz": "mxz-Latn-ID",
    "my": "my-Mymr-MM",
    "myb": "myb-Latn-TD",
    "myc": "myc-Latn-CD",
    "mye": "mye-Latn-GA",
    "myf": "myf-Latn-ET",
    "myg": "myg-Latn-CM",
    "myh": "myh-Latn-US",
    "myj": "myj-Latn-SS",
    "myk": "myk-Latn-ML",
    "myl": "myl-Latn-ID",
    "mym": "mym-Ethi-ET",
    "myp": "myp-Latn-BR",
    "myr": "myr-Latn-PE",
    "myu": "myu-Latn-BR",
    "myv": "myv-Cyrl-RU",
    "myw": "myw-Latn-PG",
    "myx": "myx-Latn-UG",
    "myy": "myy-Latn-CO",
    "myz": "myz-Mand-IR",
    "mza": "mza-Latn-MX",
    "mzd": "mzd-Latn-CM",
    "mze": "mze-Latn-PG",
    "mzh": "mzh-Latn-AR",
    "mzi": "mzi-Latn-MX",
    "mzj": "mzj-Latn-LR",
    "mzk": "mzk-Latn-NG",
    "mzl": "mzl-Latn-MX",
    "mzm": "mzm-Latn-NG",
    "mzn": "mzn-Arab-IR",
    "mzo": "mzo-Latn-BR",
    "mzp": "mzp-Latn-BO",
    "mzq": "mzq-Latn-ID",
    "mzr": "mzr-Latn-BR",
    "mzt": "mzt-Latn-MY",
    "mzu": "mzu-Latn-PG",
    "mzv": "mzv-Latn-CF",
    "mzw": "mzw-Latn-GH",
    "mzx": "mzx-Latn-GY",
    "mzz": "mzz-Latn-PG",
    "na": "na-Latn-NR",
    "naa": "naa-Latn-ID",
    "nab": "nab-Latn-BR",
    "nac": "nac-Latn-PG",
    "nae": "nae-Latn-ID",
    "naf": "naf-Latn-PG",
    "nag": "nag-Latn-IN",
    "naj": "naj-Latn-GN",
    "nak": "nak-Latn-PG",
    "nal": "nal-Latn-PG",
    "nam": "nam-Latn-AU",
    "nan": "nan-Hans-CN",
    "nao": "nao-Deva-NP",
    "nap": "nap-Latn-IT",
    "naq": "naq-Latn-NA",
    "nar": "nar-Latn-NG",
    "nas": "nas-Latn-PG",
    "nat": "nat-Latn-NG",
    "naw": "naw-Latn-GH",
    "nax": "nax-Latn-PG",
    "nay": "nay-Latn-AU",
    "naz": "naz-Latn-MX",
    "nb": "nb-Latn-NO",
    "nba": "nba-Latn-AO",
    "nbb": "nbb-Latn-NG",
    "nbc": "nbc-Latn-IN",
    "nbd": "nbd-Latn-CD",
    "nbe": "nbe-Latn-IN",
    "nbh": "nbh-Latn-NG",
    "nbi": "nbi-Latn-IN",
    "nbj": "nbj-Latn-AU",
    "nbk": "nbk-Latn-PG",
    "nbm": "nbm-Latn-CF",
    "nbn": "nbn-Latn-ID",
    "nbo": "nbo-Latn-NG",
    "nbp": "nbp-Latn-NG",
    "nbq": "nbq-Latn-ID",
    "nbr": "nbr-Latn-NG",
    "nbt": "nbt-Latn-IN",
    "nbu": "nbu-Latn-IN",
    "nbv": "nbv-Latn-CM",
    "nbw": "nbw-Latn-CD",
    "nby": "nby-Latn-PG",
    "nca": "nca-Latn-PG",
    "ncb": "ncb-Latn-IN",
    "ncc": "ncc-Latn-PG",
    "ncd": "ncd-Deva-NP",
    "nce": "nce-Latn-PG",
    "ncf": "ncf-Latn-PG",
    "ncg": "ncg-Latn-CA",
    "nch": "nch-Latn-MX",
    "nci": "nci-Latn-MX",
    "ncj": "ncj-Latn-MX",
    "nck": "nck-Latn-AU",
    "ncl": "ncl-Latn-MX",
    "ncm": "ncm-Latn-PG",
    "ncn": "ncn-Latn-PG",
    "nco": "nco-Latn-PG",
    "ncq": "ncq-Laoo-LA",
    "ncr": "ncr-Latn-CM",
    "nct": "nct-Latn-IN",
    "ncu": "ncu-Latn-GH",
    "ncx": "ncx-Latn-MX",
    "ncz": "ncz-Latn-US",
    "nd": "nd-Latn-ZW",
    "nda": "nda-Latn-CG",
    "ndb": "ndb-Latn-CM",
    "ndc": "ndc-Latn-MZ",
    "ndd": "ndd-Latn-NG",
    "ndf": "ndf-Cyrl-RU",
    "ndg": "ndg-Latn-TZ",
    "ndh": "ndh-Latn-TZ",
    "ndi": "ndi-Latn-NG",
    "ndj": "ndj-Latn-TZ",
    "ndk": "ndk-Latn-CD",
    "ndl": "ndl-Latn-CD",
    "ndm": "ndm-Latn-TD",
    "ndn": "ndn-Latn-CG",
    "ndp": "ndp-Latn-UG",
    "ndq": "ndq-Latn-AO",
    "ndr": "ndr-Latn-NG",
    "nds": "nds-Latn-DE",
    "ndt": "ndt-Latn-CD",
    "ndu": "ndu-Latn-CM",
    "ndv": "ndv-Latn-SN",
    "ndw": "ndw-Latn-CD",
    "ndx": "ndx-Latn-ID",
    "ndy": "ndy-Latn-CF",
    "ndz": "ndz-Latn-SS",
    "ne": "ne-Deva-NP",
    "nea": "nea-Latn-ID",
    "neb": "neb-Latn-CI",
    "nec": "nec-Latn-ID",
    "ned": "ned-Latn-NG",
    "nee": "nee-Latn-NC",
    "neg": "neg-Cyrl-RU",
    "neh": "neh-Tibt-BT",
    "nei": "nei-Xsux-TR",
    "nej": "nej-Latn-PG",
    "nek": "nek-Latn-NC",
    "nem": "nem-Latn-NC",
    "nen": "nen-Latn-NC",
    "neo": "neo-Latn-VN",
    "neq": "neq-Latn-MX",
    "ner": "ner-Latn-ID",
    "net": "net-Latn-PG",
    "neu": "neu-Latn-001",
    "new": "new-Deva-NP",
    "nex": "nex-Latn-PG",
    "ney": "ney-Latn-CI",
    "nez": "nez-Latn-US",
    "nfa": "nfa-Latn-ID",
    "nfd": "nfd-Latn-NG",
    "nfl": "nfl-Latn-SB",
    "nfr": "nfr-Latn-GH",
    "nfu": "nfu-Latn-CM",
    "ng": "ng-Latn-NA",
    "nga": "nga-Latn-CD",
    "ngb": "ngb-Latn-CD",
    "ngc": "ngc-Latn-CD",
    "ngd": "ngd-Latn-CF",
    "nge": "nge-Latn-CM",
    "ngg": "ngg-Latn-CF",
    "ngh": "ngh-Latn-ZA",
    "ngi": "ngi-Latn-NG",
    "ngj": "ngj-Latn-CM",
    "ngk": "ngk-Latn-AU",
    "ngl": "ngl-Latn-MZ",
    "ngm": "ngm-Latn-FM",
    "ngn": "ngn-Latn-CM",
    "ngp": "ngp-Latn-TZ",
    "ngq": "ngq-Latn-TZ",
    "ngr": "ngr-Latn-SB",
    "ngs": "ngs-Latn-NG",
    "ngt": "ngt-Laoo-LA",
    "ngu": "ngu-Latn-MX",
    "ngv": "ngv-Latn-CM",
    "ngw": "ngw-Latn-NG",
    "ngx": "ngx-Latn-NG",
    "ngy": "ngy-Latn-CM",
    "ngz": "ngz-Latn-CG",
    "nha": "nha-Latn-AU",
    "nhb": "nhb-Latn-CI",
    "nhc": "nhc-Latn-MX",
    "nhd": "nhd-Latn-PY",
    "nhe": "nhe-Latn-MX",
    "nhf": "nhf-Latn-AU",
    "nhg": "nhg-Latn-MX",
    "nhi": "nhi-Latn-MX",
    "nhk": "nhk-Latn-MX",
    "nhm": "nhm-Latn-MX",
    "nhn": "nhn-Latn-MX",
    "nho": "nho-Latn-PG",
    "nhp": "nhp-Latn-MX",
    "nhq": "nhq-Latn-MX",
    "nhr": "nhr-Latn-BW",
    "nht": "nht-Latn-MX",
    "nhu": "nhu-Latn-CM",
    "nhv": "nhv-Latn-MX",
    "nhw": "nhw-Latn-MX",
    "nhx": "nhx-Latn-MX",
    "nhy": "nhy-Latn-MX",
    "nhz": "nhz-Latn-MX",
    "nia": "nia-Latn-ID",
    "nib": "nib-Latn-PG",
    "nid": "nid-Latn-AU",
    "nie": "nie-Latn-TD",
    "nif": "nif-Latn-PG",
    "nig": "nig-Latn-AU",
    "nih": "nih-Latn-TZ",
    "nii": "nii-Latn-PG",
    "nij": "nij-Latn-ID",
    "nil": "nil-Latn-ID",
    "nim": "nim-Latn-TZ",
    "nin": "nin-Latn-NG",
    "nio": "nio-Cyrl-RU",
    "niq": "niq-Latn-KE",
    "nir": "nir-Latn-ID",
    "nis": "nis-Latn-PG",
    "nit": "nit-Telu-IN",
    "niu": "niu-Latn-NU",
    "niv": "niv-Cyrl-RU",
    "niw": "niw-Latn-PG",
    "nix": "nix-Latn-CD",
    "niy": "niy-Latn-CD",
    "niz": "niz-Latn-PG",
    "nja": "nja-Latn-NG",
    "njb": "njb-Latn-IN",
    "njd": "njd-Latn-TZ",
    "njh": "njh-Latn-IN",
    "nji": "nji-Latn-AU",
    "njj": "njj-Latn-CM",
    "njl": "njl-Latn-SS",
    "njm": "njm-Latn-IN",
    "njn": "njn-Latn-IN",
    "njo": "njo-Latn-IN",
    "njr": "njr-Latn-NG",
    "njs": "njs-Latn-ID",
    "njt": "njt-Latn-SR",
    "nju": "nju-Latn-AU",
    "njx": "njx-Latn-CG",
    "njy": "njy-Latn-CM",
    "njz": "njz-Latn-IN",
    "nka": "nka-Latn-ZM",
    "nkb": "nkb-Latn-IN",
    "nkc": "nkc-Latn-CM",
    "nkd": "nkd-Latn-IN",
    "nke": "nke-Latn-SB",
    "nkf": "nkf-Latn-IN",
    "nkg": "nkg-Latn-PG",
    "nkh": "nkh-Latn-IN",
    "nki": "nki-Latn-IN",
    "nkj": "nkj-Latn-ID",
    "nkk": "nkk-Latn-VU",
    "nkm": "nkm-Latn-PG",
    "nkn": "nkn-Latn-AO",
    "nko": "nko-Latn-GH",
    "nkq": "nkq-Latn-GH",
    "nkr": "nkr-Latn-FM",
    "nks": "nks-Latn-ID",
    "nkt": "nkt-Latn-TZ",
    "nku": "nku-Latn-CI",
    "nkv": "nkv-Latn-MW",
    "nkw": "nkw-Latn-CD",
    "nkx": "nkx-Latn-NG",
    "nkz": "nkz-Latn-NG",
    "nl": "nl-Latn-NL",
    "nla": "nla-Latn-CM",
    "nlc": "nlc-Latn-ID",
    "nle": "nle-Latn-KE",
    "nlg": "nlg-Latn-SB",
    "nli": "nli-Arab-AF",
    "nlj": "nlj-Latn-CD",
    "nlk": "nlk-Latn-ID",
    "nlm": "nlm-Arab-PK",
    "nlo": "nlo-Latn-CD",
    "nlq": "nlq-Latn-MM",
    "nlu": "nlu-Latn-GH",
    "nlv": "nlv-Latn-MX",
    "nlw": "nlw-Latn-AU",
    "nlx": "nlx-Deva-IN",
    "nly": "nly-Latn-AU",
    "nlz": "nlz-Latn-SB",
    "nma": "nma-Latn-IN",
    "nmb": "nmb-Latn-VU",
    "nmc": "nmc-Latn-TD",
    "nmd": "nmd-Latn-GA",
    "nme": "nme-Latn-IN",
    "nmf": "nmf-Latn-IN",
    "nmg": "nmg-Latn-CM",
    "nmh": "nmh-Latn-IN",
    "nmi": "nmi-Latn-NG",
    "nmj": "nmj-Latn-CF",
    "nmk": "nmk-Latn-VU",
    "nml": "nml-Latn-CM",
    "nmm": "nmm-Deva-NP",
    "nmn": "nmn-Latn-BW",
    "nmo": "nmo-Latn-IN",
    "nmp": "nmp-Latn-AU",
    "nmq": "nmq-Latn-ZW",
    "nmr": "nmr-Latn-CM",
    "nms": "nms-Latn-VU",
    "nmt": "nmt-Latn-FM",
    "nmu": "nmu-Latn-US",
    "nmv": "nmv-Latn-AU",
    "nmw": "nmw-Latn-PG",
    "nmx": "nmx-Latn-PG",
    "nmz": "nmz-Latn-TG",
    "nn": "nn-Latn-NO",
    "nna": "nna-Latn-AU",
    "nnb": "nnb-Latn-CD",
    "nnc": "nnc-Latn-TD",
    "nnd": "nnd-Latn-VU",
    "nne": "nne-Latn-AO",
    "nnf": "nnf-Latn-PG",
    "nng": "nng-Latn-IN",
    "nnh": "nnh-Latn-CM",
    "nni": "nni-Latn-ID",
    "nnj": "nnj-Latn-ET",
    "nnk": "nnk-Latn-PG",
    "nnl": "nnl-Latn-IN",
    "nnm": "nnm-Latn-PG",
    "nnn": "nnn-Latn-TD",
    "nnp": "nnp-Wcho-IN",
    "nnq": "nnq-Latn-TZ",
    "nnr": "nnr-Latn-AU",
    "nnt": "nnt-Latn-US",
    "nnu": "nnu-Latn-GH",
    "nnv": "nnv-Latn-AU",
    "nnw": "nnw-Latn-BF",
    "nny": "nny-Latn-AU",
    "nnz": "nnz-Latn-CM",
    "no": "no-Latn-NO",
    "noa": "noa-Latn-CO",
    "noc": "noc-Latn-PG",
    "nod": "nod-Lana-TH",
    "noe": "noe-Deva-IN",
    "nof": "nof-Latn-PG",
    "nog": "nog-Cyrl-RU",
    "noh": "noh-Latn-PG",
    "noi": "noi-Deva-IN",
    "noj": "noj-Latn-CO",
    "nok": "nok-Latn-US",
    "non": "non-Runr-SE",
    "nop": "nop-Latn-PG",
    "noq": "noq-Latn-CD",
    "nos": "nos-Yiii-CN",
    "not": "not-Latn-PE",
    "nou": "nou-Latn-PG",
    "nov": "nov-Latn-001",
    "now": "now-Latn-TZ",
    "noy": "noy-Latn-TD",
    "npb": "npb-Tibt-BT",
    "npg": "npg-Latn-MM",
    "nph": "nph-Latn-IN",
    "npl": "npl-Latn-MX",
    "npn": "npn-Latn-PG",
    "npo": "npo-Latn-IN",
    "nps": "nps-Latn-ID",
    "npu": "npu-Latn-IN",
    "npx": "npx-Latn-SB",
    "npy": "npy-Latn-ID",
    "nqg": "nqg-Latn-BJ",
    "nqk": "nqk-Latn-BJ",
    "nql": "nql-Latn-AO",
    "nqm": "nqm-Latn-ID",
    "nqn": "nqn-Latn-PG",
    "nqo": "nqo-Nkoo-GN",
    "nqq": "nqq-Latn-MM",
    "nqt": "nqt-Latn-NG",
    "nqy": "nqy-Latn-MM",
    "nr": "nr-Latn-ZA",
    "nra": "nra-Latn-GA",
    "nrb": "nrb-Latn-ER",
    "nre": "nre-Latn-IN",
    "nrf": "nrf-Latn-JE",
    "nrg": "nrg-Latn-VU",
    "nri": "nri-Latn-IN",
    "nrk": "nrk-Latn-AU",
    "nrl": "nrl-Latn-AU",
    "nrm": "nrm-Latn-MY",
    "nrn": "nrn-Runr-GB",
    "nrp": "nrp-Latn-IT",
    "nru": "nru-Latn-CN",
    "nrx": "nrx-Latn-AU",
    "nrz": "nrz-Latn-PG",
    "nsa": "nsa-Latn-IN",
    "nsb": "nsb-Latn-ZA",
    "nsc": "nsc-Latn-NG",
    "nsd": "nsd-Yiii-CN",
    "nse": "nse-Latn-ZM",
    "nsf": "nsf-Yiii-CN",
    "nsg": "nsg-Latn-TZ",
    "nsh": "nsh-Latn-CM",
    "nsk": "nsk-Cans-CA",
    "nsm": "nsm-Latn-IN",
    "nsn": "nsn-Latn-PG",
    "nso": "nso-Latn-ZA",
    "nsq": "nsq-Latn-US",
    "nss": "nss-Latn-PG",
    "nst": "nst-Tnsa-IN",
    "nsu": "nsu-Latn-MX",
    "nsv": "nsv-Yiii-CN",
    "nsw": "nsw-Latn-VU",
    "nsx": "nsx-Latn-AO",
    "nsy": "nsy-Latn-ID",
    "nsz": "nsz-Latn-US",
    "ntd": "ntd-Latn-MY",
    "nte": "nte-Latn-MZ",
    "ntg": "ntg-Latn-AU",
    "nti": "nti-Latn-BF",
    "ntj": "ntj-Latn-AU",
    "ntk": "ntk-Latn-TZ",
    "ntm": "ntm-Latn-BJ",
    "nto": "nto-Latn-CD",
    "ntp": "ntp-Latn-MX",
    "ntr": "ntr-Latn-GH",
    "ntu": "ntu-Latn-SB",
    "ntx": "ntx-Latn-MM",
    "nty": "nty-Yiii-VN",
    "ntz": "ntz-Arab-IR",
    "nua": "nua-Latn-NC",
    "nuc": "nuc-Latn-BR",
    "nud": "nud-Latn-PG",
    "nue": "nue-Latn-CD",
    "nuf": "nuf-Latn-CN",
    "nug": "nug-Latn-AU",
    "nuh": "nuh-Latn-NG",
    "nui": "nui-Latn-GQ",
    "nuj": "nuj-Latn-UG",
    "nuk": "nuk-Latn-CA",
    "num": "num-Latn-TO",
    "nun": "nun-Latn-MM",
    "nuo": "nuo-Latn-VN",
    "nup": "nup-Latn-NG",
    "nuq": "nuq-Latn-PG",
    "nur": "nur-Latn-PG",
    "nus": "nus-Latn-SS",
    "nut": "nut-Latn-VN",
    "nuu": "nuu-Latn-CD",
    "nuv": "nuv-Latn-BF",
    "nuw": "nuw-Latn-FM",
    "nux": "nux-Latn-PG",
    "nuy": "nuy-Latn-AU",
    "nuz": "nuz-Latn-MX",
    "nv": "nv-Latn-US",
    "nvh": "nvh-Latn-VU",
    "nvm": "nvm-Latn-PG",
    "nvo": "nvo-Latn-CM",
    "nwb": "nwb-Latn-CI",
    "nwc": "nwc-Newa-NP",
    "nwe": "nwe-Latn-CM",
    "nwg": "nwg-Latn-AU",
    "nwi": "nwi-Latn-VU",
    "nwm": "nwm-Latn-SS",
    "nwo": "nwo-Latn-AU",
    "nwr": "nwr-Latn-PG",
    "nww": "nww-Latn-TZ",
    "nwx": "nwx-Deva-NP",
    "nxa": "nxa-Latn-TL",
    "nxd": "nxd-Latn-CD",
    "nxe": "nxe-Latn-ID",
    "nxg": "nxg-Latn-ID",
    "nxi": "nxi-Latn-TZ",
    "nxl": "nxl-Latn-ID",
    "nxn": "nxn-Latn-AU",
    "nxo": "nxo-Latn-GA",
    "nxq": "nxq-Latn-CN",
    "nxr": "nxr-Latn-PG",
    "nxx": "nxx-Latn-ID",
    "ny": "ny-Latn-MW",
    "nyb": "nyb-Latn-GH",
    "nyc": "nyc-Latn-CD",
    "nyd": "nyd-Latn-KE",
    "nye": "nye-Latn-AO",
    "nyf": "nyf-Latn-KE",
    "nyg": "nyg-Latn-CD",
    "nyh": "nyh-Latn-AU",
    "nyi": "nyi-Latn-SD",
    "nyj": "nyj-Latn-CD",
    "nyk": "nyk-Latn-AO",
    "nyl": "nyl-Thai-TH",
    "nym": "nym-Latn-TZ",
    "nyn": "nyn-Latn-UG",
    "nyo": "nyo-Latn-UG",
    "nyp": "nyp-Latn-UG",
    "nyq": "nyq-Arab-IR",
    "nyr": "nyr-Latn-MW",
    "nys": "nys-Latn-AU",
    "nyt": "nyt-Latn-AU",
    "nyu": "nyu-Latn-MZ",
    "nyv": "nyv-Latn-AU",
    "nyw": "nyw-Thai-TH",
    "nyx": "nyx-Latn-AU",
    "nyy": "nyy-Latn-TZ",
    "nza": "nza-Latn-CM",
    "nzb": "nzb-Latn-GA",
    "nzd": "nzd-Latn-CD",
    "nzi": "nzi-Latn-GH",
    "nzk": "nzk-Latn-CF",
    "nzm": "nzm-Latn-IN",
    "nzr": "nzr-Latn-NG",
    "nzu": "nzu-Latn-CG",
    "nzy": "nzy-Latn-TD",
    "nzz": "nzz-Latn-ML",
    "oaa": "oaa-Cyrl-RU",
    "oac": "oac-Cyrl-RU",
    "oar": "oar-Syrc-SY",
    "oav": "oav-Geor-GE",
    "obi": "obi-Latn-US",
    "obk": "obk-Latn-PH",
    "obl": "obl-Latn-CM",
    "obm": "obm-Phnx-JO",
    "obo": "obo-Latn-PH",
    "obr": "obr-Mymr-MM",
    "obt": "obt-Latn-FR",
    "obu": "obu-Latn-NG",
    "oc": "oc-Latn-FR",
    "oca": "oca-Latn-PE",
    "oco": "oco-Latn-GB",
    "ocu": "ocu-Latn-MX",
    "oda": "oda-Latn-NG",
    "odk": "odk-Arab-PK",
    "odt": "odt-Latn-NL",
    "odu": "odu-Latn-NG",
    "ofs": "ofs-Latn-NL",
    "ofu": "ofu-Latn-NG",
    "ogb": "ogb-Latn-NG",
    "ogc": "ogc-Latn-NG",
    "ogg": "ogg-Latn-NG",
    "ogo": "ogo-Latn-NG",
    "ogu": "ogu-Latn-NG",
    "oht": "oht-Xsux-TR",
    "ohu": "ohu-Latn-HU",
    "oia": "oia-Latn-ID",
    "oie": "oie-Latn-SS",
    "oin": "oin-Latn-PG",
    "oj": "oj-Cans-CA",
    "ojb": "ojb-Latn-CA",
    "ojc": "ojc-Latn-CA",
    "ojs": "ojs-Cans-CA",
    "ojv": "ojv-Latn-SB",
    "ojw": "ojw-Latn-CA",
    "oka": "oka-Latn-CA",
    "okb": "okb-Latn-NG",
    "okc": "okc-Latn-CD",
    "okd": "okd-Latn-NG",
    "oke": "oke-Latn-NG",
    "okg": "okg-Latn-AU",
    "oki": "oki-Latn-KE",
    "okk": "okk-Latn-PG",
    "okm": "okm-Hang-KR",
    "oko": "oko-Hani-KR",
    "okr": "okr-Latn-NG",
    "oks": "oks-Latn-NG",
    "oku": "oku-Latn-CM",
    "okv": "okv-Latn-PG",
    "okx": "okx-Latn-NG",
    "okz": "okz-Khmr-KH",
    "ola": "ola-Deva-NP",
    "old": "old-Latn-TZ",
    "ole": "ole-Tibt-BT",
    "olk": "olk-Latn-AU",
    "olm": "olm-Latn-NG",
    "olo": "olo-Latn-RU",
    "olr": "olr-Latn-VU",
    "olt": "olt-Latn-LT",
    "olu": "olu-Latn-AO",
    "om": "om-Latn-ET",
    "oma": "oma-Latn-US",
    "omb": "omb-Latn-VU",
    "omc": "omc-Latn-PE",
    "omg": "omg-Latn-PE",
    "omi": "omi-Latn-CD",
    "omk": "omk-Cyrl-RU",
    "oml": "oml-Latn-CD",
    "omo": "omo-Latn-PG",
    "omp": "omp-Mtei-IN",
    "omr": "omr-Modi-IN",
    "omt": "omt-Latn-KE",
    "omu": "omu-Latn-PE",
    "omw": "omw-Latn-PG",
    "omx": "omx-Mymr-MM",
    "ona": "ona-Latn-AR",
    "one": "one-Latn-CA",
    "ong": "ong-Latn-PG",
    "oni": "oni-Latn-ID",
    "onj": "onj-Latn-PG",
    "onk": "onk-Latn-PG",
    "onn": "onn-Latn-PG",
    "ono": "ono-Latn-CA",
    "onp": "onp-Latn-IN",
    "onr": "onr-Latn-PG",
    "ons": "ons-Latn-PG",
    "ont": "ont-Latn-PG",
    "onu": "onu-Latn-VU",
    "onx": "onx-Latn-ID",
    "ood": "ood-Latn-US",
    "oon": "oon-Deva-IN",
    "oor": "oor-Latn-ZA",
    "opa": "opa-Latn-NG",
    "opk": "opk-Latn-ID",
    "opm": "opm-Latn-PG",
    "opo": "opo-Latn-PG",
    "opt": "opt-Latn-MX",
    "opy": "opy-Latn-BR",
    "or": "or-Orya-IN",
    "ora": "ora-Latn-SB",
    "orc": "orc-Latn-KE",
    "ore": "ore-Latn-PE",
    "org": "org-Latn-NG",
    "orn": "orn-Latn-MY",
    "oro": "oro-Latn-PG",
    "orr": "orr-Latn-NG",
    "ors": "ors-Latn-MY",
    "ort": "ort-Telu-IN",
    "oru": "oru-Arab-PK",
    "orv": "orv-Cyrl-RU",
    "orw": "orw-Latn-BR",
    "orx": "orx-Latn-NG",
    "orz": "orz-Latn-ID",
    "os": "os-Cyrl-GE",
    "osa": "osa-Osge-US",
    "osc": "osc-Ital-IT",
    "osi": "osi-Java-ID",
    "oso": "oso-Latn-NG",
    "osp": "osp-Latn-ES",
    "ost": "ost-Latn-CM",
    "osu": "osu-Latn-PG",
    "osx": "osx-Latn-DE",
    "ota": "ota-Arab-TR",
    "otb": "otb-Tibt-CN",
    "otd": "otd-Latn-ID",
    "ote": "ote-Latn-MX",
    "oti": "oti-Latn-BR",
    "otk": "otk-Orkh-MN",
    "otl": "otl-Latn-MX",
    "otm": "otm-Latn-MX",
    "otn": "otn-Latn-MX",
    "otq": "otq-Latn-MX",
    "otr": "otr-Latn-SD",
    "ots": "ots-Latn-MX",
    "ott": "ott-Latn-MX",
    "otu": "otu-Latn-BR",
    "otw": "otw-Latn-CA",
    "otx": "otx-Latn-MX",
    "oty": "oty-Gran-IN",
    "otz": "otz-Latn-MX",
    "oub": "oub-Latn-LR",
    "oue": "oue-Latn-PG",
    "oui": "oui-Ougr-CN",
    "oum": "oum-Latn-PG",
    "ovd": "ovd-Latn-SE",
    "owi": "owi-Latn-PG",
    "owl": "owl-Latn-GB",
    "oyd": "oyd-Latn-ET",
    "oym": "oym-Latn-BR",
    "oyy": "oyy-Latn-PG",
    "ozm": "ozm-Latn-CM",
    "pa": "pa-Guru-IN",
    "pa-Arab": "pa-Arab-PK",
    "pa-PK": "pa-Arab-PK",
    "pab": "pab-Latn-BR",
    "pac": "pac-Latn-VN",
    "pad": "pad-Latn-BR",
    "pae": "pae-Latn-CD",
    "paf": "paf-Latn-BR",
    "pag": "pag-Latn-PH",
    "pah": "pah-Latn-BR",
    "pai": "pai-Latn-NG",
    "pak": "pak-Latn-BR",
    "pal": "pal-Phli-IR",
    "pal-Phlp": "pal-Phlp-CN",
    "pam": "pam-Latn-PH",
    "pao": "pao-Latn-US",
    "pap": "pap-Latn-CW",
    "paq": "paq-Cyrl-TJ",
    "par": "par-Latn-US",
    "pas": "pas-Latn-ID",
    "pau": "pau-Latn-PW",
    "pav": "pav-Latn-BR",
    "paw": "paw-Latn-US",
    "pax": "pax-Latn-BR",
    "pay": "pay-Latn-HN",
    "paz": "paz-Latn-BR",
    "pbb": "pbb-Latn-CO",
    "pbc": "pbc-Latn-GY",
    "pbe": "pbe-Latn-MX",
    "pbf": "pbf-Latn-MX",
    "pbg": "pbg-Latn-VE",
    "pbh": "pbh-Latn-VE",
    "pbi": "pbi-Latn-CM",
    "pbl": "pbl-Latn-NG",
    "pbm": "pbm-Latn-MX",
    "pbn": "pbn-Latn-NG",
    "pbo": "pbo-Latn-GW",
    "pbp": "pbp-Latn-GN",
    "pbr": "pbr-Latn-TZ",
    "pbs": "pbs-Latn-MX",
    "pbt": "pbt-Arab-AF",
    "pbv": "pbv-Latn-IN",
    "pby": "pby-Latn-PG",
    "pca": "pca-Latn-MX",
    "pcb": "pcb-Khmr-KH",
    "pcc": "pcc-Latn-CN",
    "pcd": "pcd-Latn-FR",
    "pce": "pce-Mymr-MM",
    "pcf": "pcf-Mlym-IN",
    "pcg": "pcg-Mlym-IN",
    "pch": "pch-Deva-IN",
    "pci": "pci-Deva-IN",
    "pcj": "pcj-Telu-IN",
    "pck": "pck-Latn-IN",
    "pcm": "pcm-Latn-NG",
    "pcn": "pcn-Latn-NG",
    "pcp": "pcp-Latn-BO",
    "pcw": "pcw-Latn-NG",
    "pda": "pda-Latn-PG",
    "pdc": "pdc-Latn-US",
    "pdn": "pdn-Latn-ID",
    "pdo": "pdo-Latn-ID",
    "pdt": "pdt-Latn-CA",
    "pdu": "pdu-Latn-MM",
    "pea": "pea-Latn-ID",
    "peb": "peb-Latn-US",
    "ped": "ped-Latn-PG",
    "pee": "pee-Latn-ID",
    "peg": "peg-Orya-IN",
    "pei": "pei-Latn-MX",
    "pek": "pek-Latn-PG",
    "pel": "pel-Latn-ID",
    "pem": "pem-Latn-CD",
    "peo": "peo-Xpeo-IR",
    "pep": "pep-Latn-PG",
    "peq": "peq-Latn-US",
    "pev": "pev-Latn-VE",
    "pex": "pex-Latn-PG",
    "pey": "pey-Latn-ID",
    "pez": "pez-Latn-MY",
    "pfa": "pfa-Latn-FM",
    "pfe": "pfe-Latn-CM",
    "pfl": "pfl-Latn-DE",
    "pga": "pga-Latn-SS",
    "pgd": "pgd-Khar-PK",
    "pgg": "pgg-Deva-IN",
    "pgi": "pgi-Latn-PG",
    "pgk": "pgk-Latn-VU",
    "pgl": "pgl-Ogam-IE",
    "pgn": "pgn-Ital-IT",
    "pgs": "pgs-Latn-NG",
    "pgu": "pgu-Latn-ID",
    "phd": "phd-Deva-IN",
    "phg": "phg-Latn-VN",
    "phh": "phh-Latn-VN",
    "phk": "phk-Mymr-IN",
    "phl": "phl-Arab-PK",
    "phm": "phm-Latn-MZ",
    "phn": "phn-Phnx-LB",
    "pho": "pho-Laoo-LA",
    "phr": "phr-Arab-PK",
    "pht": "pht-Thai-TH",
    "phu": "phu-Thai-TH",
    "phv": "phv-Arab-AF",
    "phw": "phw-Deva-NP",
    "pi": "pi-Sinh-IN",
    "pia": "pia-Latn-MX",
    "pib": "pib-Latn-PE",
    "pic": "pic-Latn-GA",
    "pid": "pid-Latn-VE",
    "pif": "pif-Latn-FM",
    "pig": "pig-Latn-PE",
    "pih": "pih-Latn-NF",
    "pij": "pij-Latn-CO",
    "pil": "pil-Latn-BJ",
    "pim": "pim-Latn-US",
    "pin": "pin-Latn-PG",
    "pio": "pio-Latn-CO",
    "pip": "pip-Latn-NG",
    "pir": "pir-Latn-BR",
    "pis": "pis-Latn-SB",
    "pit": "pit-Latn-AU",
    "piu": "piu-Latn-AU",
    "piv": "piv-Latn-SB",
    "piw": "piw-Latn-TZ",
    "pix": "pix-Latn-PG",
    "piy": "piy-Latn-NG",
    "piz": "piz-Latn-NC",
    "pjt": "pjt-Latn-AU",
    "pka": "pka-Brah-IN",
    "pkb": "pkb-Latn-KE",
    "pkg": "pkg-Latn-PG",
    "pkh": "pkh-Latn-BD",
    "pkn": "pkn-Latn-AU",
    "pko": "pko-Latn-KE",
    "pkp": "pkp-Latn-CK",
    "pkr": "pkr-Mlym-IN",
    "pku": "pku-Latn-ID",
    "pl": "pl-Latn-PL",
    "pla": "pla-Latn-PG",
    "plb": "plb-Latn-VU",
    "plc": "plc-Latn-PH",
    "pld": "pld-Latn-GB",
    "ple": "ple-Latn-ID",
    "plg": "plg-Latn-AR",
    "plh": "plh-Latn-ID",
    "plk": "plk-Arab-PK",
    "pll": "pll-Mymr-MM",
    "pln": "pln-Latn-CO",
    "plo": "plo-Latn-MX",
    "plr": "plr-Latn-CI",
    "pls": "pls-Latn-MX",
    "plu": "plu-Latn-BR",
    "plv": "plv-Latn-PH",
    "plw": "plw-Latn-PH",
    "plz": "plz-Latn-MY",
    "pma": "pma-Latn-VU",
    "pmb": "pmb-Latn-CD",
    "pmd": "pmd-Latn-AU",
    "pme": "pme-Latn-NC",
    "pmf": "pmf-Latn-ID",
    "pmh": "pmh-Brah-IN",
    "pmi": "pmi-Latn-CN",
    "pmj": "pmj-Latn-CN",
    "pml": "pml-Latn-TN",
    "pmm": "pmm-Latn-CM",
    "pmn": "pmn-Latn-CM",
    "pmo": "pmo-Latn-ID",
    "pmq": "pmq-Latn-MX",
    "pmr": "pmr-Latn-PG",
    "pms": "pms-Latn-IT",
    "pmt": "pmt-Latn-PF",
    "pmw": "pmw-Latn-US",
    "pmx": "pmx-Latn-IN",
    "pmy": "pmy-Latn-ID",
    "pmz": "pmz-Latn-MX",
    "pna": "pna-Latn-MY",
    "pnc": "pnc-Latn-ID",
    "pnd": "pnd-Latn-AO",
    "pne": "pne-Latn-MY",
    "png": "png-Latn-NG",
    "pnh": "pnh-Latn-CK",
    "pni": "pni-Latn-ID",
    "pnj": "pnj-Latn-AU",
    "pnk": "pnk-Latn-BO",
    "pnl": "pnl-Latn-BF",
    "pnm": "pnm-Latn-MY",
    "pnn": "pnn-Latn-PG",
    "pno": "pno-Latn-PE",
    "pnp": "pnp-Latn-ID",
    "pnq": "pnq-Latn-BF",
    "pnr": "pnr-Latn-PG",
    "pns": "pns-Latn-ID",
    "pnt": "pnt-Grek-GR",
    "pnv": "pnv-Latn-AU",
    "pnw": "pnw-Latn-AU",
    "pny": "pny-Latn-CM",
    "pnz": "pnz-Latn-CF",
    "poc": "poc-Latn-GT",
    "poe": "poe-Latn-MX",
    "pof": "pof-Latn-CD",
    "pog": "pog-Latn-BR",
    "poh": "poh-Latn-GT",
    "poi": "poi-Latn-MX",
    "pok": "pok-Latn-BR",
    "pom": "pom-Latn-US",
    "pon": "pon-Latn-FM",
    "poo": "poo-Latn-US",
    "pop": "pop-Latn-NC",
    "poq": "poq-Latn-MX",
    "pos": "pos-Latn-MX",
    "pot": "pot-Latn-US",
    "pov": "pov-Latn-GW",
    "pow": "pow-Latn-MX",
    "poy": "poy-Latn-TZ",
    "ppe": "ppe-Latn-PG",
    "ppi": "ppi-Latn-MX",
    "ppk": "ppk-Latn-ID",
    "ppl": "ppl-Latn-SV",
    "ppm": "ppm-Latn-ID",
    "ppn": "ppn-Latn-PG",
    "ppo": "ppo-Latn-PG",
    "ppp": "ppp-Latn-CD",
    "ppq": "ppq-Latn-PG",
    "pps": "pps-Latn-MX",
    "ppt": "ppt-Latn-PG",
    "pqa": "pqa-Latn-NG",
    "pqm": "pqm-Latn-CA",
    "pra": "pra-Khar-PK",
    "prc": "prc-Arab-AF",
    "prd": "prd-Arab-IR",
    "pre": "pre-Latn-ST",
    "prf": "prf-Latn-PH",
    "prg": "prg-Latn-PL",
    "prh": "prh-Latn-PH",
    "pri": "pri-Latn-NC",
    "prk": "prk-Latn-MM",
    "prm": "prm-Latn-PG",
    "pro": "pro-Latn-FR",
    "prq": "prq-Latn-PE",
    "prr": "prr-Latn-BR",
    "prt": "prt-Thai-TH",
    "pru": "pru-Latn-ID",
    "prw": "prw-Latn-PG",
    "prx": "prx-Arab-IN",
    "ps": "ps-Arab-AF",
    "psa": "psa-Latn-ID",
    "pse": "pse-Latn-ID",
    "psh": "psh-Arab-AF",
    "psi": "psi-Arab-AF",
    "psm": "psm-Latn-BO",
    "psn": "psn-Latn-ID",
    "psq": "psq-Latn-PG",
    "pss": "pss-Latn-PG",
    "pst": "pst-Arab-PK",
    "psu": "psu-Brah-IN",
    "psw": "psw-Latn-VU",
    "pt": "pt-Latn-BR",
    "pta": "pta-Latn-PY",
    "pth": "pth-Latn-BR",
    "pti": "pti-Latn-AU",
    "ptn": "ptn-Latn-ID",
    "pto": "pto-Latn-BR",
    "ptp": "ptp-Latn-PG",
    "ptr": "ptr-Latn-VU",
    "ptt": "ptt-Latn-ID",
    "ptu": "ptu-Latn-ID",
    "ptv": "ptv-Latn-VU",
    "pua": "pua-Latn-MX",
    "pub": "pub-Latn-IN",
    "puc": "puc-Latn-ID",
    "pud": "pud-Latn-ID",
    "pue": "pue-Latn-AR",
    "puf": "puf-Latn-ID",
    "pug": "pug-Latn-BF",
    "pui": "pui-Latn-CO",
    "puj": "puj-Latn-ID",
    "pum": "pum-Deva-NP",
    "puo": "puo-Latn-VN",
    "pup": "pup-Latn-PG",
    "puq": "puq-Latn-BO",
    "pur": "pur-Latn-BR",
    "put": "put-Latn-ID",
    "puu": "puu-Latn-GA",
    "puw": "puw-Latn-FM",
    "pux": "pux-Latn-PG",
    "puy": "puy-Latn-US",
    "pwa": "pwa-Latn-PG",
    "pwb": "pwb-Latn-NG",
    "pwg": "pwg-Latn-PG",
    "pwm": "pwm-Latn-PH",
    "pwn": "pwn-Latn-TW",
    "pwo": "pwo-Mymr-MM",
    "pwr": "pwr-Deva-IN",
    "pww": "pww-Thai-TH",
    "pxm": "pxm-Latn-MX",
    "pye": "pye-Latn-CI",
    "pym": "pym-Latn-NG",
    "pyn": "pyn-Latn-BR",
    "pyu": "pyu-Latn-TW",
    "pyx": "pyx-Mymr-MM",
    "pyy": "pyy-Latn-MM",
    "pze": "pze-Latn-NG",
    "pzh": "pzh-Latn-TW",
    "pzn": "pzn-Latn-MM",
    "qu": "qu-Latn-PE",
    "qua": "qua-Latn-US",
    "qub": "qub-Latn-PE",
    "quc": "quc-Latn-GT",
    "qud": "qud-Latn-EC",
    "quf": "quf-Latn-PE",
    "qug": "qug-Latn-EC",
    "qui": "qui-Latn-US",
    "quk": "quk-Latn-PE",
    "qul": "qul-Latn-BO",
    "qum": "qum-Latn-GT",
    "qun": "qun-Latn-US",
    "qup": "qup-Latn-PE",
    "quq": "quq-Latn-ES",
    "qur": "qur-Latn-PE",
    "qus": "qus-Latn-AR",
    "quv": "quv-Latn-GT",
    "quw": "quw-Latn-EC",
    "qux": "qux-Latn-PE",
    "quy": "quy-Latn-PE",
    "qva": "qva-Latn-PE",
    "qvc": "qvc-Latn-PE",
    "qve": "qve-Latn-PE",
    "qvh": "qvh-Latn-PE",
    "qvi": "qvi-Latn-EC",
    "qvj": "qvj-Latn-EC",
    "qvl": "qvl-Latn-PE",
    "qvm": "qvm-Latn-PE",
    "qvn": "qvn-Latn-PE",
    "qvo": "qvo-Latn-PE",
    "qvp": "qvp-Latn-PE",
    "qvs": "qvs-Latn-PE",
    "qvw": "qvw-Latn-PE",
    "qvz": "qvz-Latn-EC",
    "qwa": "qwa-Latn-PE",
    "qwc": "qwc-Latn-PE",
    "qwh": "qwh-Latn-PE",
    "qwm": "qwm-Latn-HU",
    "qws": "qws-Latn-PE",
    "qwt": "qwt-Latn-US",
    "qxa": "qxa-Latn-PE",
    "qxc": "qxc-Latn-PE",
    "qxh": "qxh-Latn-PE",
    "qxl": "qxl-Latn-EC",
    "qxn": "qxn-Latn-PE",
    "qxo": "qxo-Latn-PE",
    "qxp": "qxp-Latn-PE",
    "qxq": "qxq-Arab-IR",
    "qxr": "qxr-Latn-EC",
    "qxt": "qxt-Latn-PE",
    "qxu": "qxu-Latn-PE",
    "qxw": "qxw-Latn-PE",
    "qya": "qya-Latn-001",
    "qyp": "qyp-Latn-US",
    "raa": "raa-Deva-NP",
    "rab": "rab-Deva-NP",
    "rac": "rac-Latn-ID",
    "rad": "rad-Latn-VN",
    "raf": "raf-Deva-NP",
    "rag": "rag-Latn-KE",
    "rah": "rah-Beng-IN",
    "rai": "rai-Latn-PG",
    "raj": "raj-Deva-IN",
    "rak": "rak-Latn-PG",
    "ram": "ram-Latn-BR",
    "ran": "ran-Latn-ID",
    "rao": "rao-Latn-PG",
    "rap": "rap-Latn-CL",
    "rar": "rar-Latn-CK",
    "rav": "rav-Deva-NP",
    "raw": "raw-Latn-MM",
    "rax": "rax-Latn-NG",
    "ray": "ray-Latn-PF",
    "raz": "raz-Latn-ID",
    "rbb": "rbb-Mymr-MM",
    "rbk": "rbk-Latn-PH",
    "rbl": "rbl-Latn-PH",
    "rbp": "rbp-Latn-AU",
    "rcf": "rcf-Latn-RE",
    "rdb": "rdb-Arab-IR",
    "rea": "rea-Latn-PG",
    "reb": "reb-Latn-ID",
    "ree": "ree-Latn-MY",
    "reg": "reg-Latn-TZ",
    "rei": "rei-Orya-IN",
    "rej": "rej-Latn-ID",
    "rel": "rel-Latn-KE",
    "rem": "rem-Latn-PE",
    "ren": "ren-Latn-VN",
    "res": "res-Latn-NG",
    "ret": "ret-Latn-ID",
    "rey": "rey-Latn-BO",
    "rga": "rga-Latn-VU",
    "rgn": "rgn-Latn-IT",
    "rgr": "rgr-Latn-PE",
    "rgs": "rgs-Latn-VN",
    "rgu": "rgu-Latn-ID",
    "rhg": "rhg-Rohg-MM",
    "rhp": "rhp-Latn-PG",
    "ria": "ria-Latn-IN",
    "rif": "rif-Latn-MA",
    "ril": "ril-Latn-MM",
    "rim": "rim-Latn-TZ",
    "rin": "rin-Latn-NG",
    "rir": "rir-Latn-ID",
    "rit": "rit-Latn-AU",
    "riu": "riu-Latn-ID",
    "rjg": "rjg-Latn-ID",
    "rji": "rji-Deva-NP",
    "rjs": "rjs-Deva-NP",
    "rka": "rka-Khmr-KH",
    "rkb": "rkb-Latn-BR",
    "rkh": "rkh-Latn-CK",
    "rki": "rki-Mymr-MM",
    "rkm": "rkm-Latn-BF",
    "rkt": "rkt-Beng-BD",
    "rkw": "rkw-Latn-AU",
    "rm": "rm-Latn-CH",
    "rma": "rma-Latn-NI",
    "rmb": "rmb-Latn-AU",
    "rmc": "rmc-Latn-SK",
    "rmd": "rmd-Latn-DK",
    "rme": "rme-Latn-GB",
    "rmf": "rmf-Latn-FI",
    "rmg": "rmg-Latn-NO",
    "rmh": "rmh-Latn-ID",
    "rmi": "rmi-Armn-AM",
    "rmk": "rmk-Latn-PG",
    "rml": "rml-Latn-PL",
    "rmm": "rmm-Latn-ID",
    "rmn": "rmn-Latn-RS",
    "rmo": "rmo-Latn-CH",
    "rmp": "rmp-Latn-PG",
    "rmq": "rmq-Latn-ES",
    "rmt": "rmt-Arab-IR",
    "rmu": "rmu-Latn-SE",
    "rmw": "rmw-Latn-GB",
    "rmx": "rmx-Latn-VN",
    "rmz": "rmz-Mymr-IN",
    "rn": "rn-Latn-BI",
    "rnd": "rnd-Latn-CD",
    "rng": "rng-Latn-MZ",
    "rnl": "rnl-Latn-IN",
    "rnn": "rnn-Latn-ID",
    "rnr": "rnr-Latn-AU",
    "rnw": "rnw-Latn-TZ",
    "ro": "ro-Latn-RO",
    "rob": "rob-Latn-ID",
    "roc": "roc-Latn-VN",
    "rod": "rod-Latn-NG",
    "roe": "roe-Latn-PG",
    "rof": "rof-Latn-TZ",
    "rog": "rog-Latn-VN",
    "rol": "rol-Latn-PH",
    "rom": "rom-Latn-RO",
    "roo": "roo-Latn-PG",
    "rop": "rop-Latn-AU",
    "ror": "ror-Latn-ID",
    "rou": "rou-Latn-TD",
    "row": "row-Latn-ID",
    "rpn": "rpn-Latn-VU",
    "rpt": "rpt-Latn-PG",
    "rri": "rri-Latn-SB",
    "rrm": "rrm-Latn-NZ",
    "rro": "rro-Latn-PG",
    "rrt": "rrt-Latn-AU",
    "rsk": "rsk-Cyrl-RS",
    "rsw": "rsw-Latn-NG",
    "rtc": "rtc-Latn-MM",
    "rth": "rth-Latn-ID",
    "rtm": "rtm-Latn-FJ",
    "rtw": "rtw-Deva-IN",
    "ru": "ru-Cyrl-RU",
    "rub": "rub-Latn-UG",
    "ruc": "ruc-Latn-UG",
    "rue": "rue-Cyrl-UA",
    "ruf": "ruf-Latn-TZ",
    "rug": "rug-Latn-SB",
    "rui": "rui-Latn-TZ",
    "ruk": "ruk-Latn-NG",
    "ruo": "ruo-Latn-HR",
    "rup": "rup-Latn-RO",
    "ruq": "ruq-Latn-GR",
    "rut": "rut-Cyrl-RU",
    "ruu": "ruu-Latn-MY",
    "ruy": "ruy-Latn-NG",
    "ruz": "ruz-Latn-NG",
    "rw": "rw-Latn-RW",
    "rwa": "rwa-Latn-PG",
    "rwk": "rwk-Latn-TZ",
    "rwl": "rwl-Latn-TZ",
    "rwm": "rwm-Latn-UG",
    "rwo": "rwo-Latn-PG",
    "rwr": "rwr-Deva-IN",
    "rxd": "rxd-Latn-AU",
    "rxw": "rxw-Latn-AU",
    "ryu": "ryu-Kana-JP",
    "sa": "sa-Deva-IN",
    "saa": "saa-Latn-TD",
    "sab": "sab-Latn-PA",
    "sac": "sac-Latn-US",
    "sad": "sad-Latn-TZ",
    "sae": "sae-Latn-BR",
    "saf": "saf-Latn-GH",
    "sah": "sah-Cyrl-RU",
    "saj": "saj-Latn-ID",
    "sak": "sak-Latn-GA",
    "sam": "sam-Samr-PS",
    "sao": "sao-Latn-ID",
    "saq": "saq-Latn-KE",
    "sar": "sar-Latn-BO",
    "sas": "sas-Latn-ID",
    "sat": "sat-Olck-IN",
    "sau": "sau-Latn-ID",
    "sav": "sav-Latn-SN",
    "saw": "saw-Latn-ID",
    "sax": "sax-Latn-VU",
    "say": "say-Latn-NG",
    "saz": "saz-Saur-IN",
    "sba": "sba-Latn-TD",
    "sbb": "sbb-Latn-SB",
    "sbc": "sbc-Latn-PG",
    "sbd": "sbd-Latn-BF",
    "sbe": "sbe-Latn-PG",
    "sbg": "sbg-Latn-ID",
    "sbh": "sbh-Latn-PG",
    "sbi": "sbi-Latn-PG",
    "sbj": "sbj-Latn-TD",
    "sbk": "sbk-Latn-TZ",
    "sbl": "sbl-Latn-PH",
    "sbm": "sbm-Latn-TZ",
    "sbn": "sbn-Arab-PK",
    "sbo": "sbo-Latn-MY",
    "sbp": "sbp-Latn-TZ",
    "sbq": "sbq-Latn-PG",
    "sbr": "sbr-Latn-ID",
    "sbs": "sbs-Latn-NA",
    "sbt": "sbt-Latn-ID",
    "sbu": "sbu-Tibt-IN",
    "sbv": "sbv-Latn-IT",
    "sbw": "sbw-Latn-GA",
    "sbx": "sbx-Latn-ID",
    "sby": "sby-Latn-ZM",
    "sbz": "sbz-Latn-CF",
    "sc": "sc-Latn-IT",
    "scb": "scb-Latn-VN",
    "sce": "sce-Latn-CN",
    "scf": "scf-Latn-PA",
    "scg": "scg-Latn-ID",
    "sch": "sch-Latn-IN",
    "sci": "sci-Latn-LK",
    "sck": "sck-Deva-IN",
    "scl": "scl-Arab-PK",
    "scn": "scn-Latn-IT",
    "sco": "sco-Latn-GB",
    "scp": "scp-Deva-NP",
    "scs": "scs-Latn-CA",
    "sct": "sct-Laoo-LA",
    "scu": "scu-Takr-IN",
    "scv": "scv-Latn-NG",
    "scw": "scw-Latn-NG",
    "scx": "scx-Grek-IT",
    "sd": "sd-Arab-PK",
    "sd-Deva": "sd-Deva-IN",
    "sd-IN": "sd-Deva-IN",
    "sd-Khoj": "sd-Khoj-IN",
    "sd-Sind": "sd-Sind-IN",
    "sda": "sda-Latn-ID",
    "sdb": "sdb-Arab-IQ",
    "sdc": "sdc-Latn-IT",
    "sde": "sde-Latn-NG",
    "sdf": "sdf-Arab-IQ",
    "sdg": "sdg-Arab-AF",
    "sdh": "sdh-Arab-IR",
    "sdj": "sdj-Latn-CG",
    "sdk": "sdk-Latn-PG",
    "sdn": "sdn-Latn-IT",
    "sdo": "sdo-Latn-MY",
    "sdq": "sdq-Latn-ID",
    "sdr": "sdr-Beng-BD",
    "sds": "sds-Arab-TN",
    "sdu": "sdu-Latn-ID",
    "sdx": "sdx-Latn-MY",
    "se": "se-Latn-NO",
    "sea": "sea-Latn-MY",
    "seb": "seb-Latn-CI",
    "sec": "sec-Latn-CA",
    "sed": "sed-Latn-VN",
    "see": "see-Latn-US",
    "sef": "sef-Latn-CI",
    "seg": "seg-Latn-TZ",
    "seh": "seh-Latn-MZ",
    "sei": "sei-Latn-MX",
    "sej": "sej-Latn-PG",
    "sek": "sek-Latn-CA",
    "sel": "sel-Cyrl-RU",
    "sen": "sen-Latn-BF",
    "seo": "seo-Latn-PG",
    "sep": "sep-Latn-BF",
    "seq": "seq-Latn-BF",
    "ser": "ser-Latn-US",
    "ses": "ses-Latn-ML",
    "set": "set-Latn-ID",
    "seu": "seu-Latn-ID",
    "sev": "sev-Latn-CI",
    "sew": "sew-Latn-PG",
    "sey": "sey-Latn-EC",
    "sez": "sez-Latn-MM",
    "sfe": "sfe-Latn-PH",
    "sfm": "sfm-Plrd-CN",
    "sfw": "sfw-Latn-GH",
    "sg": "sg-Latn-CF",
    "sga": "sga-Ogam-IE",
    "sgb": "sgb-Latn-PH",
    "sgc": "sgc-Latn-KE",
    "sgd": "sgd-Latn-PH",
    "sge": "sge-Latn-ID",
    "sgh": "sgh-Cyrl-TJ",
    "sgi": "sgi-Latn-CM",
    "sgj": "sgj-Deva-IN",
    "sgm": "sgm-Latn-KE",
    "sgp": "sgp-Latn-IN",
    "sgr": "sgr-Arab-IR",
    "sgs": "sgs-Latn-LT",
    "sgt": "sgt-Tibt-BT",
    "sgu": "sgu-Latn-ID",
    "sgw": "sgw-Ethi-ET",
    "sgy": "sgy-Arab-AF",
    "sgz": "sgz-Latn-PG",
    "sha": "sha-Latn-NG",
    "shb": "shb-Latn-BR",
    "shc": "shc-Latn-CD",
    "shd": "shd-Arab-PK",
    "she": "she-Latn-ET",
    "shg": "shg-Latn-BW",
    "shh": "shh-Latn-US",
    "shi": "shi-Tfng-MA",
    "shj": "shj-Latn-SD",
    "shk": "shk-Latn-SS",
    "shm": "shm-Arab-IR",
    "shn": "shn-Mymr-MM",
    "sho": "sho-Latn-NG",
    "shp": "shp-Latn-PE",
    "shq": "shq-Latn-ZM",
    "shr": "shr-Latn-CD",
    "shs": "shs-Latn-CA",
    "sht": "sht-Latn-US",
    "shu": "shu-Arab-TD",
    "shv": "shv-Arab-OM",
    "shw": "shw-Latn-SD",
    "shy": "shy-Latn-DZ",
    "shz": "shz-Latn-ML",
    "si": "si-Sinh-LK",
    "sia": "sia-Cyrl-RU",
    "sib": "sib-Latn-MY",
    "sid": "sid-Latn-ET",
    "sie": "sie-Latn-ZM",
    "sif": "sif-Latn-BF",
    "sig": "sig-Latn-GH",
    "sih": "sih-Latn-NC",
    "sii": "sii-Latn-IN",
    "sij": "sij-Latn-PG",
    "sik": "sik-Latn-BR",
    "sil": "sil-Latn-GH",
    "sim": "sim-Latn-PG",
    "sip": "sip-Tibt-IN",
    "siq": "siq-Latn-PG",
    "sir": "sir-Latn-NG",
    "sis": "sis-Latn-US",
    "siu": "siu-Latn-PG",
    "siv": "siv-Latn-PG",
    "siw": "siw-Latn-PG",
    "six": "six-Latn-PG",
    "siy": "siy-Arab-IR",
    "siz": "siz-Arab-EG",
    "sja": "sja-Latn-CO",
    "sjb": "sjb-Latn-ID",
    "sjd": "sjd-Cyrl-RU",
    "sje": "sje-Latn-SE",
    "sjg": "sjg-Latn-TD",
    "sjl": "sjl-Latn-IN",
    "sjm": "sjm-Latn-PH",
    "sjp": "sjp-Deva-IN",
    "sjr": "sjr-Latn-PG",
    "sjt": "sjt-Cyrl-RU",
    "sju": "sju-Latn-SE",
    "sjw": "sjw-Latn-US",
    "sk": "sk-Latn-SK",
    "ska": "ska-Latn-US",
    "skb": "skb-Thai-TH",
    "skc": "skc-Latn-PG",
    "skd": "skd-Latn-US",
    "ske": "ske-Latn-VU",
    "skf": "skf-Latn-BR",
    "skg": "skg-Latn-MG",
    "skh": "skh-Latn-ID",
    "ski": "ski-Latn-ID",
    "skj": "skj-Deva-NP",
    "skm": "skm-Latn-PG",
    "skn": "skn-Latn-PH",
    "sko": "sko-Latn-ID",
    "skp": "skp-Latn-MY",
    "skq": "skq-Latn-BF",
    "skr": "skr-Arab-PK",
    "sks": "sks-Latn-PG",
    "skt": "skt-Latn-CD",
    "sku": "sku-Latn-VU",
    "skv": "skv-Latn-ID",
    "skw": "skw-Latn-GY",
    "skx": "skx-Latn-ID",
    "sky": "sky-Latn-SB",
    "skz": "skz-Latn-ID",
    "sl": "sl-Latn-SI",
    "slc": "slc-Latn-CO",
    "sld": "sld-Latn-BF",
    "slg": "slg-Latn-ID",
    "slh": "slh-Latn-US",
    "sli": "sli-Latn-PL",
    "slj": "slj-Latn-BR",
    "sll": "sll-Latn-PG",
    "slm": "slm-Latn-PH",
    "sln": "sln-Latn-US",
    "slp": "slp-Latn-ID",
    "slr": "slr-Latn-CN",
    "slu": "slu-Latn-ID",
    "slw": "slw-Latn-PG",
    "slx": "slx-Latn-CD",
    "sly": "sly-Latn-ID",
    "slz": "slz-Latn-ID",
    "sm": "sm-Latn-WS",
    "sma": "sma-Latn-SE",
    "smb": "smb-Latn-PG",
    "smc": "smc-Latn-PG",
    "smf": "smf-Latn-PG",
    "smg": "smg-Latn-PG",
    "smh": "smh-Yiii-CN",
    "smj": "smj-Latn-SE",
    "smk": "smk-Latn-PH",
    "sml": "sml-Latn-PH",
    "smn": "smn-Latn-FI",
    "smp": "smp-Samr-IL",
    "smq": "smq-Latn-PG",
    "smr": "smr-Latn-ID",
    "sms": "sms-Latn-FI",
    "smt": "smt-Latn-IN",
    "smu": "smu-Khmr-KH",
    "smw": "smw-Latn-ID",
    "smx": "smx-Latn-CD",
    "smy": "smy-Arab-IR",
    "smz": "smz-Latn-PG",
    "sn": "sn-Latn-ZW",
    "snc": "snc-Latn-PG",
    "sne": "sne-Latn-MY",
    "snf": "snf-Latn-SN",
    "sng": "sng-Latn-CD",
    "sni": "sni-Latn-PE",
    "snj": "snj-Latn-CF",
    "snk": "snk-Latn-ML",
    "snl": "snl-Latn-PH",
    "snm": "snm-Latn-UG",
    "snn": "snn-Latn-CO",
    "sno": "sno-Latn-US",
    "snp": "snp-Latn-PG",
    "snq": "snq-Latn-GA",
    "snr": "snr-Latn-PG",
    "sns": "sns-Latn-VU",
    "snu": "snu-Latn-ID",
    "snv": "snv-Latn-MY",
    "snw": "snw-Latn-GH",
    "snx": "snx-Latn-PG",
    "sny": "sny-Latn-PG",
    "snz": "snz-Latn-PG",
    "so": "so-Latn-SO",
    "soa": "soa-Tavt-TH",
    "sob": "sob-Latn-ID",
    "soc": "soc-Latn-CD",
    "sod": "sod-Latn-CD",
    "soe": "soe-Latn-CD",
    "sog": "sog-Sogd-UZ",
    "soi": "soi-Deva-NP",
    "sok": "sok-Latn-TD",
    "sol": "sol-Latn-PG",
    "soo": "soo-Latn-CD",
    "sop": "sop-Latn-CD",
    "soq": "soq-Latn-PG",
    "sor": "sor-Latn-TD",
    "sos": "sos-Latn-BF",
    "sou": "sou-Thai-TH",
    "sov": "sov-Latn-PW",
    "sow": "sow-Latn-PG",
    "sox": "sox-Latn-CM",
    "soy": "soy-Latn-BJ",
    "soz": "soz-Latn-TZ",
    "spb": "spb-Latn-ID",
    "spc": "spc-Latn-VE",
    "spd": "spd-Latn-PG",
    "spe": "spe-Latn-PG",
    "spg": "spg-Latn-MY",
    "spi": "spi-Latn-ID",
    "spk": "spk-Latn-PG",
    "spl": "spl-Latn-PG",
    "spm": "spm-Latn-PG",
    "spn": "spn-Latn-PY",
    "spo": "spo-Latn-US",
    "spp": "spp-Latn-ML",
    "spq": "spq-Latn-PE",
    "spr": "spr-Latn-ID",
    "sps": "sps-Latn-PG",
    "spt": "spt-Tibt-IN",
    "spv": "spv-Orya-IN",
    "sq": "sq-Latn-AL",
    "sqa": "sqa-Latn-NG",
    "sqh": "sqh-Latn-NG",
    "sqm": "sqm-Latn-CF",
    "sqo": "sqo-Arab-IR",
    "sqq": "sqq-Laoo-LA",
    "sqt": "sqt-Arab-YE",
    "squ": "squ-Latn-CA",
    "sr": "sr-Cyrl-RS",
    "sr-ME": "sr-Latn-ME",
    "sr-RO": "sr-Latn-RO",
    "sr-RU": "sr-Latn-RU",
    "sr-TR": "sr-Latn-TR",
    "sra": "sra-Latn-PG",
    "srb": "srb-Sora-IN",
    "sre": "sre-Latn-ID",
    "srf": "srf-Latn-PG",
    "srg": "srg-Latn-PH",
    "srh": "srh-Arab-CN",
    "sri": "sri-Latn-CO",
    "srk": "srk-Latn-MY",
    "srl": "srl-Latn-ID",
    "srm": "srm-Latn-SR",
    "srn": "srn-Latn-SR",
    "sro": "sro-Latn-IT",
    "srq": "srq-Latn-BO",
    "srr": "srr-Latn-SN",
    "srs": "srs-Latn-CA",
    "srt": "srt-Latn-ID",
    "sru": "sru-Latn-BR",
    "srv": "srv-Latn-PH",
    "srw": "srw-Latn-ID",
    "srx": "srx-Deva-IN",
    "sry": "sry-Latn-PG",
    "srz": "srz-Arab-IR",
    "ss": "ss-Latn-ZA",
    "ssb": "ssb-Latn-PH",
    "ssc": "ssc-Latn-TZ",
    "ssd": "ssd-Latn-PG",
    "sse": "sse-Latn-PH",
    "ssf": "ssf-Latn-TW",
    "ssg": "ssg-Latn-PG",
    "ssh": "ssh-Arab-AE",
    "ssj": "ssj-Latn-PG",
    "ssl": "ssl-Latn-GH",
    "ssm": "ssm-Latn-MY",
    "ssn": "ssn-Latn-KE",
    "sso": "sso-Latn-PG",
    "ssq": "ssq-Latn-ID",
    "sss": "sss-Laoo-LA",
    "sst": "sst-Latn-PG",
    "ssu": "ssu-Latn-PG",
    "ssv": "ssv-Latn-VU",
    "ssx": "ssx-Latn-PG",
    "ssy": "ssy-Latn-ER",
    "ssz": "ssz-Latn-PG",
    "st": "st-Latn-ZA",
    "sta": "sta-Latn-ZM",
    "stb": "stb-Latn-PH",
    "ste": "ste-Latn-ID",
    "stf": "stf-Latn-PG",
    "stg": "stg-Latn-VN",
    "sth": "sth-Latn-IE",
    "sti": "sti-Latn-VN",
    "stj": "stj-Latn-BF",
    "stk": "stk-Latn-PG",
    "stl": "stl-Latn-NL",
    "stm": "stm-Latn-PG",
    "stn": "stn-Latn-SB",
    "sto": "sto-Latn-CA",
    "stp": "stp-Latn-MX",
    "stq": "stq-Latn-DE",
    "str": "str-Latn-CA",
    "sts": "sts-Arab-AF",
    "stt": "stt-Latn-VN",
    "stv": "stv-Ethi-ET",
    "stw": "stw-Latn-FM",
    "sty": "sty-Cyrl-RU",
    "su": "su-Latn-ID",
    "sua": "sua-Latn-PG",
    "sub": "sub-Latn-CD",
    "suc": "suc-Latn-PH",
    "sue": "sue-Latn-PG",
    "sug": "sug-Latn-PG",
    "sui": "sui-Latn-PG",
    "suj": "suj-Latn-TZ",
    "suk": "suk-Latn-TZ",
    "suo": "suo-Latn-PG",
    "suq": "suq-Latn-ET",
    "sur": "sur-Latn-NG",
    "sus": "sus-Latn-GN",
    "sut": "sut-Latn-NI",
    "suv": "suv-Latn-IN",
    "suw": "suw-Latn-TZ",
    "suy": "suy-Latn-BR",
    "suz": "suz-Sunu-NP",
    "sv": "sv-Latn-SE",
    "sva": "sva-Geor-GE",
    "svb": "svb-Latn-PG",
    "svc": "svc-Latn-VC",
    "sve": "sve-Latn-ID",
    "svm": "svm-Latn-IT",
    "svs": "svs-Latn-SB",
    "sw": "sw-Latn-TZ",
    "swb": "swb-Arab-YT",
    "swf": "swf-Latn-CD",
    "swg": "swg-Latn-DE",
    "swi": "swi-Hani-CN",
    "swj": "swj-Latn-GA",
    "swk": "swk-Latn-MW",
    "swm": "swm-Latn-PG",
    "swo": "swo-Latn-BR",
    "swp": "swp-Latn-PG",
    "swq": "swq-Latn-CM",
    "swr": "swr-Latn-ID",
    "sws": "sws-Latn-ID",
    "swt": "swt-Latn-ID",
    "swu": "swu-Latn-ID",
    "swv": "swv-Deva-IN",
    "sww": "sww-Latn-VU",
    "swx": "swx-Latn-BR",
    "swy": "swy-Latn-TD",
    "sxb": "sxb-Latn-KE",
    "sxe": "sxe-Latn-GA",
    "sxn": "sxn-Latn-ID",
    "sxr": "sxr-Latn-TW",
    "sxs": "sxs-Latn-NG",
    "sxu": "sxu-Runr-DE",
    "sxw": "sxw-Latn-BJ",
    "sya": "sya-Latn-ID",
    "syb": "syb-Latn-PH",
    "syc": "syc-Syrc-TR",
    "syi": "syi-Latn-GA",
    "syk": "syk-Latn-NG",
    "syl": "syl-Beng-BD",
    "sym": "sym-Latn-BF",
    "syn": "syn-Syrc-IR",
    "syo": "syo-Latn-KH",
    "syr": "syr-Syrc-IQ",
    "sys": "sys-Latn-TD",
    "syw": "syw-Deva-NP",
    "syx": "syx-Latn-GA",
    "sza": "sza-Latn-MY",
    "szb": "szb-Latn-ID",
    "szc": "szc-Latn-MY",
    "szg": "szg-Latn-CD",
    "szl": "szl-Latn-PL",
    "szn": "szn-Latn-ID",
    "szp": "szp-Latn-ID",
    "szv": "szv-Latn-CM",
    "szw": "szw-Latn-ID",
    "szy": "szy-Latn-TW",
    "ta": "ta-Taml-IN",
    "taa": "taa-Latn-US",
    "tab": "tab-Cyrl-RU",
    "tac": "tac-Latn-MX",
    "tad": "tad-Latn-ID",
    "tae": "tae-Latn-BR",
    "taf": "taf-Latn-BR",
    "tag": "tag-Latn-SD",
    "taj": "taj-Deva-NP",
    "tak": "tak-Latn-NG",
    "tal": "tal-Latn-NG",
    "tan": "tan-Latn-NG",
    "tao": "tao-Latn-TW",
    "tap": "tap-Latn-CD",
    "taq": "taq-Latn-ML",
    "tar": "tar-Latn-MX",
    "tas": "tas-Latn-VN",
    "tau": "tau-Latn-US",
    "tav": "tav-Latn-CO",
    "taw": "taw-Latn-PG",
    "tax": "tax-Latn-TD",
    "tay": "tay-Latn-TW",
    "taz": "taz-Latn-SD",
    "tba": "tba-Latn-BR",
    "tbc": "tbc-Latn-PG",
    "tbd": "tbd-Latn-PG",
    "tbe": "tbe-Latn-SB",
    "tbf": "tbf-Latn-PG",
    "tbg": "tbg-Latn-PG",
    "tbh": "tbh-Latn-AU",
    "tbi": "tbi-Latn-SD",
    "tbj": "tbj-Latn-PG",
    "tbk": "tbk-Tagb-PH",
    "tbl": "tbl-Latn-PH",
    "tbm": "tbm-Latn-CD",
    "tbn": "tbn-Latn-CO",
    "tbo": "tbo-Latn-PG",
    "tbp": "tbp-Latn-ID",
    "tbs": "tbs-Latn-PG",
    "tbt": "tbt-Latn-CD",
    "tbu": "tbu-Latn-MX",
    "tbv": "tbv-Latn-PG",
    "tbw": "tbw-Latn-PH",
    "tbx": "tbx-Latn-PG",
    "tby": "tby-Latn-ID",
    "tbz": "tbz-Latn-BJ",
    "tca": "tca-Latn-BR",
    "tcb": "tcb-Latn-US",
    "tcc": "tcc-Latn-TZ",
    "tcd": "tcd-Latn-GH",
    "tce": "tce-Latn-CA",
    "tcf": "tcf-Latn-MX",
    "tcg": "tcg-Latn-ID",
    "tch": "tch-Latn-TC",
    "tci": "tci-Latn-PG",
    "tck": "tck-Latn-GA",
    "tcm": "tcm-Latn-ID",
    "tcn": "tcn-Tibt-NP",
    "tco": "tco-Mymr-MM",
    "tcp": "tcp-Latn-MM",
    "tcq": "tcq-Latn-ID",
    "tcs": "tcs-Latn-AU",
    "tcu": "tcu-Latn-MX",
    "tcw": "tcw-Latn-MX",
    "tcx": "tcx-Taml-IN",
    "tcy": "tcy-Knda-IN",
    "tcz": "tcz-Latn-IN",
    "tda": "tda-Tfng-NE",
    "tdb": "tdb-Deva-IN",
    "tdc": "tdc-Latn-CO",
    "tdd": "tdd-Tale-CN",
    "tde": "tde-Latn-ML",
    "tdg": "tdg-Deva-NP",
    "tdh": "tdh-Deva-NP",
    "tdi": "tdi-Latn-ID",
    "tdj": "tdj-Latn-ID",
    "tdk": "tdk-Latn-NG",
    "tdl": "tdl-Latn-NG",
    "tdm": "tdm-Latn-GY",
    "tdn": "tdn-Latn-ID",
    "tdo": "tdo-Latn-NG",
    "tdq": "tdq-Latn-NG",
    "tdr": "tdr-Latn-VN",
    "tds": "tds-Latn-ID",
    "tdt": "tdt-Latn-TL",
    "tdv": "tdv-Latn-NG",
    "tdx": "tdx-Latn-MG",
    "tdy": "tdy-Latn-PH",
    "te": "te-Telu-IN",
    "tea": "tea-Latn-MY",
    "teb": "teb-Latn-EC",
    "tec": "tec-Latn-KE",
    "ted": "ted-Latn-CI",
    "tee": "tee-Latn-MX",
    "teg": "teg-Latn-GA",
    "teh": "teh-Latn-AR",
    "tei": "tei-Latn-PG",
    "tek": "tek-Latn-CD",
    "tem": "tem-Latn-SL",
    "ten": "ten-Latn-CO",
    "teo": "teo-Latn-UG",
    "tep": "tep-Latn-MX",
    "teq": "teq-Latn-SD",
    "ter": "ter-Latn-BR",
    "tes": "tes-Java-ID",
    "tet": "tet-Latn-TL",
    "teu": "teu-Latn-UG",
    "tev": "tev-Latn-ID",
    "tew": "tew-Latn-US",
    "tex": "tex-Latn-SS",
    "tey": "tey-Latn-SD",
    "tez": "tez-Latn-NE",
    "tfi": "tfi-Latn-BJ",
    "tfn": "tfn-Latn-US",
    "tfo": "tfo-Latn-ID",
    "tfr": "tfr-Latn-PA",
    "tft": "tft-Latn-ID",
    "tg": "tg-Cyrl-TJ",
    "tg-Arab": "tg-Arab-PK",
    "tg-PK": "tg-Arab-PK",
    "tga": "tga-Latn-KE",
    "tgb": "tgb-Latn-MY",
    "tgc": "tgc-Latn-PG",
    "tgd": "tgd-Latn-NG",
    "tge": "tge-Deva-NP",
    "tgf": "tgf-Tibt-BT",
    "tgh": "tgh-Latn-TT",
    "tgi": "tgi-Latn-PG",
    "tgj": "tgj-Latn-IN",
    "tgn": "tgn-Latn-PH",
    "tgo": "tgo-Latn-PG",
    "tgp": "tgp-Latn-VU",
    "tgq": "tgq-Latn-MY",
    "tgs": "tgs-Latn-VU",
    "tgt": "tgt-Latn-PH",
    "tgu": "tgu-Latn-PG",
    "tgv": "tgv-Latn-BR",
    "tgw": "tgw-Latn-CI",
    "tgx": "tgx-Latn-CA",
    "tgy": "tgy-Latn-SS",
    "tgz": "tgz-Latn-AU",
    "th": "th-Thai-TH",
    "thd": "thd-Latn-AU",
    "the": "the-Deva-NP",
    "thf": "thf-Deva-NP",
    "thh": "thh-Latn-MX",
    "thi": "thi-Tale-LA",
    "thk": "thk-Latn-KE",
    "thl": "thl-Deva-NP",
    "thm": "thm-Thai-TH",
    "thp": "thp-Latn-CA",
    "thq": "thq-Deva-NP",
    "thr": "thr-Deva-NP",
    "ths": "ths-Deva-NP",
    "tht": "tht-Latn-CA",
    "thu": "thu-Latn-SS",
    "thv": "thv-Latn-DZ",
    "thy": "thy-Latn-NG",
    "thz": "thz-Latn-NE",
    "ti": "ti-Ethi-ET",
    "tic": "tic-Latn-SD",
    "tif": "tif-Latn-PG",
    "tig": "tig-Ethi-ER",
    "tih": "tih-Latn-MY",
    "tii": "tii-Latn-CD",
    "tij": "tij-Deva-NP",
    "tik": "tik-Latn-CM",
    "til": "til-Latn-US",
    "tim": "tim-Latn-PG",
    "tin": "tin-Cyrl-RU",
    "tio": "tio-Latn-PG",
    "tip": "tip-Latn-ID",
    "tiq": "tiq-Latn-BF",
    "tis": "tis-Latn-PH",
    "tit": "tit-Latn-CO",
    "tiu": "tiu-Latn-PH",
    "tiv": "tiv-Latn-NG",
    "tiw": "tiw-Latn-AU",
    "tix": "tix-Latn-US",
    "tiy": "tiy-Latn-PH",
    "tja": "tja-Latn-LR",
    "tjg": "tjg-Latn-ID",
    "tji": "tji-Latn-CN",
    "tjj": "tjj-Latn-AU",
    "tjl": "tjl-Mymr-MM",
    "tjn": "tjn-Latn-CI",
    "tjo": "tjo-Arab-DZ",
    "tjp": "tjp-Latn-AU",
    "tjs": "tjs-Latn-CN",
    "tju": "tju-Latn-AU",
    "tjw": "tjw-Latn-AU",
    "tk": "tk-Latn-TM",
    "tka": "tka-Latn-BR",
    "tkb": "tkb-Deva-IN",
    "tkd": "tkd-Latn-TL",
    "tke": "tke-Latn-MZ",
    "tkf": "tkf-Latn-BR",
    "tkg": "tkg-Latn-MG",
    "tkl": "tkl-Latn-TK",
    "tkp": "tkp-Latn-SB",
    "tkq": "tkq-Latn-NG",
    "tkr": "tkr-Latn-AZ",
    "tks": "tks-Arab-IR",
    "tkt": "tkt-Deva-NP",
    "tku": "tku-Latn-MX",
    "tkv": "tkv-Latn-PG",
    "tkw": "tkw-Latn-SB",
    "tkx": "tkx-Latn-ID",
    "tkz": "tkz-Latn-VN",
    "tl": "tl-Latn-PH",
    "tla": "tla-Latn-MX",
    "tlb": "tlb-Latn-ID",
    "tlc": "tlc-Latn-MX",
    "tld": "tld-Latn-ID",
    "tlf": "tlf-Latn-PG",
    "tlg": "tlg-Latn-ID",
    "tli": "tli-Latn-US",
    "tlj": "tlj-Latn-UG",
    "tlk": "tlk-Latn-ID",
    "tll": "tll-Latn-CD",
    "tlm": "tlm-Latn-VU",
    "tln": "tln-Latn-ID",
    "tlp": "tlp-Latn-MX",
    "tlq": "tlq-Latn-MM",
    "tlr": "tlr-Latn-SB",
    "tls": "tls-Latn-VU",
    "tlt": "tlt-Latn-ID",
    "tlu": "tlu-Latn-ID",
    "tlv": "tlv-Latn-ID",
    "tlx": "tlx-Latn-PG",
    "tly": "tly-Latn-AZ",
    "tma": "tma-Latn-TD",
    "tmb": "tmb-Latn-VU",
    "tmc": "tmc-Latn-TD",
    "tmd": "tmd-Latn-PG",
    "tme": "tme-Latn-BR",
    "tmf": "tmf-Latn-PY",
    "tmg": "tmg-Latn-ID",
    "tmh": "tmh-Latn-NE",
    "tmi": "tmi-Latn-VU",
    "tmj": "tmj-Latn-ID",
    "tml": "tml-Latn-ID",
    "tmm": "tmm-Latn-VN",
    "tmn": "tmn-Latn-ID",
    "tmo": "tmo-Latn-MY",
    "tmq": "tmq-Latn-PG",
    "tmr": "tmr-Syrc-IL",
    "tmt": "tmt-Latn-VU",
    "tmu": "tmu-Latn-ID",
    "tmv": "tmv-Latn-CD",
    "tmw": "tmw-Latn-MY",
    "tmy": "tmy-Latn-PG",
    "tmz": "tmz-Latn-VE",
    "tn": "tn-Latn-ZA",
    "tna": "tna-Latn-BO",
    "tnb": "tnb-Latn-CO",
    "tnc": "tnc-Latn-CO",
    "tnd": "tnd-Latn-CO",
    "tng": "tng-Latn-TD",
    "tnh": "tnh-Latn-PG",
    "tni": "tni-Latn-ID",
    "tnk": "tnk-Latn-VU",
    "tnl": "tnl-Latn-VU",
    "tnm": "tnm-Latn-ID",
    "tnn": "tnn-Latn-VU",
    "tno": "tno-Latn-BO",
    "tnp": "tnp-Latn-VU",
    "tnq": "tnq-Latn-PR",
    "tnr": "tnr-Latn-SN",
    "tns": "tns-Latn-PG",
    "tnt": "tnt-Latn-ID",
    "tnv": "tnv-Cakm-BD",
    "tnw": "tnw-Latn-ID",
    "tnx": "tnx-Latn-SB",
    "tny": "tny-Latn-TZ",
    "to": "to-Latn-TO",
    "tob": "tob-Latn-AR",
    "toc": "toc-Latn-MX",
    "tod": "tod-Latn-GN",
    "tof": "tof-Latn-PG",
    "tog": "tog-Latn-MW",
    "toh": "toh-Latn-MZ",
    "toi": "toi-Latn-ZM",
    "toj": "toj-Latn-MX",
    "tok": "tok-Latn-001",
    "tol": "tol-Latn-US",
    "tom": "tom-Latn-ID",
    "too": "too-Latn-MX",
    "top": "top-Latn-MX",
    "toq": "toq-Latn-SS",
    "tor": "tor-Latn-CD",
    "tos": "tos-Latn-MX",
    "tou": "tou-Latn-VN",
    "tov": "tov-Arab-IR",
    "tow": "tow-Latn-US",
    "tox": "tox-Latn-PW",
    "toy": "toy-Latn-ID",
    "toz": "toz-Latn-CM",
    "tpa": "tpa-Latn-PG",
    "tpc": "tpc-Latn-MX",
    "tpe": "tpe-Latn-BD",
    "tpf": "tpf-Latn-ID",
    "tpg": "tpg-Latn-ID",
    "tpi": "tpi-Latn-PG",
    "tpj": "tpj-Latn-PY",
    "tpk": "tpk-Latn-BR",
    "tpl": "tpl-Latn-MX",
    "tpm": "tpm-Latn-GH",
    "tpn": "tpn-Latn-BR",
    "tpp": "tpp-Latn-MX",
    "tpr": "tpr-Latn-BR",
    "tpt": "tpt-Latn-MX",
    "tpu": "tpu-Khmr-KH",
    "tpv": "tpv-Latn-MP",
    "tpx": "tpx-Latn-MX",
    "tpy": "tpy-Latn-BR",
    "tpz": "tpz-Latn-PG",
    "tqb": "tqb-Latn-BR",
    "tql": "tql-Latn-VU",
    "tqm": "tqm-Latn-PG",
    "tqn": "tqn-Latn-US",
    "tqo": "tqo-Latn-PG",
    "tqp": "tqp-Latn-PG",
    "tqt": "tqt-Latn-MX",
    "tqu": "tqu-Latn-SB",
    "tqw": "tqw-Latn-US",
    "tr": "tr-Latn-TR",
    "tra": "tra-Arab-AF",
    "trb": "trb-Latn-PG",
    "trc": "trc-Latn-MX",
    "tre": "tre-Latn-ID",
    "trf": "trf-Latn-TT",
    "trg": "trg-Hebr-IL",
    "trh": "trh-Latn-PG",
    "tri": "tri-Latn-SR",
    "trj": "trj-Latn-TD",
    "trl": "trl-Latn-GB",
    "trm": "trm-Arab-AF",
    "trn": "trn-Latn-BO",
    "tro": "tro-Latn-IN",
    "trp": "trp-Latn-IN",
    "trq": "trq-Latn-MX",
    "trr": "trr-Latn-PE",
    "trs": "trs-Latn-MX",
    "trt": "trt-Latn-ID",
    "tru": "tru-Latn-TR",
    "trv": "trv-Latn-TW",
    "trw": "trw-Arab-PK",
    "trx": "trx-Latn-MY",
    "try": "try-Latn-IN",
    "trz": "trz-Latn-BR",
    "ts": "ts-Latn-ZA",
    "tsa": "tsa-Latn-CG",
    "tsb": "tsb-Latn-ET",
    "tsc": "tsc-Latn-MZ",
    "tsd": "tsd-Grek-GR",
    "tsg": "tsg-Latn-PH",
    "tsh": "tsh-Latn-CM",
    "tsi": "tsi-Latn-CA",
    "tsj": "tsj-Tibt-BT",
    "tsl": "tsl-Latn-VN",
    "tsp": "tsp-Latn-BF",
    "tsr": "tsr-Latn-VU",
    "tst": "tst-Latn-ML",
    "tsu": "tsu-Latn-TW",
    "tsv": "tsv-Latn-GA",
    "tsw": "tsw-Latn-NG",
    "tsx": "tsx-Latn-PG",
    "tsz": "tsz-Latn-MX",
    "tt": "tt-Cyrl-RU",
    "ttb": "ttb-Latn-NG",
    "ttc": "ttc-Latn-GT",
    "ttd": "ttd-Latn-PG",
    "tte": "tte-Latn-PG",
    "ttf": "ttf-Latn-CM",
    "tth": "tth-Laoo-LA",
    "tti": "tti-Latn-ID",
    "ttj": "ttj-Latn-UG",
    "ttk": "ttk-Latn-CO",
    "ttl": "ttl-Latn-ZM",
    "ttm": "ttm-Latn-CA",
    "ttn": "ttn-Latn-ID",
    "tto": "tto-Laoo-LA",
    "ttp": "ttp-Latn-ID",
    "ttr": "ttr-Latn-NG",
    "tts": "tts-Thai-TH",
    "ttt": "ttt-Latn-AZ",
    "ttu": "ttu-Latn-PG",
    "ttv": "ttv-Latn-PG",
    "ttw": "ttw-Latn-MY",
    "tty": "tty-Latn-ID",
    "ttz": "ttz-Deva-NP",
    "tua": "tua-Latn-PG",
    "tub": "tub-Latn-US",
    "tuc": "tuc-Latn-PG",
    "tud": "tud-Latn-BR",
    "tue": "tue-Latn-CO",
    "tuf": "tuf-Latn-CO",
    "tug": "tug-Latn-TD",
    "tuh": "tuh-Latn-PG",
    "tui": "tui-Latn-CM",
    "tuj": "tuj-Latn-ID",
    "tul": "tul-Latn-NG",
    "tum": "tum-Latn-MW",
    "tun": "tun-Latn-US",
    "tuo": "tuo-Latn-BR",
    "tuq": "tuq-Latn-TD",
    "tus": "tus-Latn-CA",
    "tuu": "tuu-Latn-US",
    "tuv": "tuv-Latn-KE",
    "tux": "tux-Latn-BR",
    "tuy": "tuy-Latn-KE",
    "tuz": "tuz-Latn-BF",
    "tva": "tva-Latn-SB",
    "tvd": "tvd-Latn-NG",
    "tve": "tve-Latn-ID",
    "tvi": "tvi-Latn-NG",
    "tvk": "tvk-Latn-VU",
    "tvl": "tvl-Latn-TV",
    "tvm": "tvm-Latn-ID",
    "tvn": "tvn-Mymr-MM",
    "tvo": "tvo-Latn-ID",
    "tvs": "tvs-Latn-KE",
    "tvt": "tvt-Latn-IN",
    "tvu": "tvu-Latn-CM",
    "tvw": "tvw-Latn-ID",
    "tvx": "tvx-Latn-TW",
    "twa": "twa-Latn-US",
    "twb": "twb-Latn-PH",
    "twd": "twd-Latn-NL",
    "twe": "twe-Latn-ID",
    "twf": "twf-Latn-US",
    "twg": "twg-Latn-ID",
    "twh": "twh-Latn-VN",
    "twl": "twl-Latn-MZ",
    "twm": "twm-Deva-IN",
    "twn": "twn-Latn-CM",
    "two": "two-Latn-BW",
    "twp": "twp-Latn-PG",
    "twq": "twq-Latn-NE",
    "twr": "twr-Latn-MX",
    "twt": "twt-Latn-BR",
    "twu": "twu-Latn-ID",
    "tww": "tww-Latn-PG",
    "twx": "twx-Latn-MZ",
    "twy": "twy-Latn-ID",
    "txa": "txa-Latn-MY",
    "txe": "txe-Latn-ID",
    "txg": "txg-Tang-CN",
    "txi": "txi-Latn-BR",
    "txj": "txj-Latn-NG",
    "txm": "txm-Latn-ID",
    "txn": "txn-Latn-ID",
    "txo": "txo-Toto-IN",
    "txq": "txq-Latn-ID",
    "txs": "txs-Latn-ID",
    "txt": "txt-Latn-ID",
    "txu": "txu-Latn-BR",
    "txx": "txx-Latn-MY",
    "txy": "txy-Latn-MG",
    "ty": "ty-Latn-PF",
    "tya": "tya-Latn-PG",
    "tye": "tye-Latn-NG",
    "tyh": "tyh-Latn-VN",
    "tyi": "tyi-Latn-CG",
    "tyj": "tyj-Latn-VN",
    "tyl": "tyl-Latn-VN",
    "tyn": "tyn-Latn-ID",
    "typ": "typ-Latn-AU",
    "tyr": "tyr-Tavt-VN",
    "tys": "tys-Latn-VN",
    "tyt": "tyt-Latn-VN",
    "tyu": "tyu-Latn-BW",
    "tyv": "tyv-Cyrl-RU",
    "tyx": "tyx-Latn-CG",
    "tyy": "tyy-Latn-NG",
    "tyz": "tyz-Latn-VN",
    "tzh": "tzh-Latn-MX",
    "tzj": "tzj-Latn-GT",
    "tzl": "tzl-Latn-001",
    "tzm": "tzm-Latn-MA",
    "tzn": "tzn-Latn-ID",
    "tzo": "tzo-Latn-MX",
    "tzx": "tzx-Latn-PG",
    "uam": "uam-Latn-BR",
    "uar": "uar-Latn-PG",
    "uba": "uba-Latn-NG",
    "ubi": "ubi-Latn-TD",
    "ubl": "ubl-Latn-PH",
    "ubr": "ubr-Latn-PG",
    "ubu": "ubu-Latn-PG",
    "uby": "uby-Latn-TR",
    "uda": "uda-Latn-NG",
    "ude": "ude-Cyrl-RU",
    "udg": "udg-Mlym-IN",
    "udi": "udi-Cyrl-RU",
    "udj": "udj-Latn-ID",
    "udl": "udl-Latn-CM",
    "udm": "udm-Cyrl-RU",
    "udu": "udu-Latn-SD",
    "ues": "ues-Latn-ID",
    "ufi": "ufi-Latn-PG",
    "ug": "ug-Arab-CN",
    "ug-Cyrl": "ug-Cyrl-KZ",
    "ug-KZ": "ug-Cyrl-KZ",
    "ug-MN": "ug-Cyrl-MN",
    "uga": "uga-Ugar-SY",
    "ugb": "ugb-Latn-AU",
    "uge": "uge-Latn-SB",
    "ugh": "ugh-Cyrl-RU",
    "ugo": "ugo-Thai-TH",
    "uha": "uha-Latn-NG",
    "uhn": "uhn-Latn-ID",
    "uis": "uis-Latn-PG",
    "uiv": "uiv-Latn-CM",
    "uji": "uji-Latn-NG",
    "uk": "uk-Cyrl-UA",
    "uka": "uka-Latn-ID",
    "ukg": "ukg-Latn-PG",
    "ukh": "ukh-Latn-CF",
    "uki": "uki-Orya-IN",
    "ukk": "ukk-Latn-MM",
    "ukp": "ukp-Latn-NG",
    "ukq": "ukq-Latn-NG",
    "uku": "uku-Latn-NG",
    "ukv": "ukv-Latn-SS",
    "ukw": "ukw-Latn-NG",
    "uky": "uky-Latn-AU",
    "ula": "ula-Latn-NG",
    "ulb": "ulb-Latn-NG",
    "ulc": "ulc-Cyrl-RU",
    "ule": "ule-Latn-AR",
    "ulf": "ulf-Latn-ID",
    "uli": "uli-Latn-FM",
    "ulk": "ulk-Latn-AU",
    "ulm": "ulm-Latn-ID",
    "uln": "uln-Latn-PG",
    "ulu": "ulu-Latn-ID",
    "ulw": "ulw-Latn-NI",
    "uly": "uly-Latn-NG",
    "uma": "uma-Latn-US",
    "umb": "umb-Latn-AO",
    "umd": "umd-Latn-AU",
    "umg": "umg-Latn-AU",
    "umi": "umi-Latn-MY",
    "umm": "umm-Latn-NG",
    "umn": "umn-Latn-MM",
    "umo": "umo-Latn-BR",
    "ump": "ump-Latn-AU",
    "umr": "umr-Latn-AU",
    "ums": "ums-Latn-ID",
    "una": "una-Latn-PG",
    "und": "en-Latn-US",
    "und-419": "es-Latn-419",
    "und-AD": "ca-Latn-AD",
    "und-AE": "ar-Arab-AE",
    "und-AF": "fa-Arab-AF",
    "und-AL": "sq-Latn-AL",
    "und-AM": "hy-Armn-AM",
    "und-AO": "pt-Latn-AO",
    "und-AR": "es-Latn-AR",
    "und-AS": "sm-Latn-AS",
    "und-AT": "de-Latn-AT",
    "und-AW": "nl-Latn-AW",
    "und-AX": "sv-Latn-AX",
    "und-AZ": "az-Latn-AZ",
    "und-Adlm": "ff-Adlm-GN",
    "und-Aghb": "xag-Aghb-AZ",
    "und-Ahom": "aho-Ahom-IN",
    "und-Arab": "ar-Arab-EG",
    "und-Arab-AF": "fa-Arab-AF",
    "und-Arab-BN": "ms-Arab-BN",
    "und-Arab-CC": "ms-Arab-CC",
    "und-Arab-CN": "ug-Arab-CN",
    "und-Arab-GB": "ur-Arab-GB",
    "und-Arab-ID": "ms-Arab-ID",
    "und-Arab-IN": "ur-Arab-IN",
    "und-Arab-IR": "fa-Arab-IR",
    "und-Arab-KH": "cja-Arab-KH",
    "und-Arab-MM": "rhg-Arab-MM",
    "und-Arab-MN": "kk-Arab-MN",
    "und-Arab-MU": "ur-Arab-MU",
    "und-Arab-NG": "ha-Arab-NG",
    "und-Arab-PK": "ur-Arab-PK",
    "und-Arab-TG": "apd-Arab-TG",
    "und-Arab-TH": "mfa-Arab-TH",
    "und-Arab-TJ": "fa-Arab-TJ",
    "und-Arab-TR": "apc-Arab-TR",
    "und-Arab-YT": "swb-Arab-YT",
    "und-Armi": "arc-Armi-IR",
    "und-Armn": "hy-Armn-AM",
    "und-Avst": "ae-Avst-IR",
    "und-BA": "bs-Latn-BA",
    "und-BD": "bn-Beng-BD",
    "und-BE": "nl-Latn-BE",
    "und-BF": "fr-Latn-BF",
    "und-BG": "bg-Cyrl-BG",
    "und-BH": "ar-Arab-BH",
    "und-BI": "rn-Latn-BI",
    "und-BJ": "fr-Latn-BJ",
    "und-BL": "fr-Latn-BL",
    "und-BN": "ms-Latn-BN",
    "und-BO": "es-Latn-BO",
    "und-BQ": "pap-Latn-BQ",
    "und-BR": "pt-Latn-BR",
    "und-BT": "dz-Tibt-BT",
    "und-BY": "be-Cyrl-BY",
    "und-Bali": "ban-Bali-ID",
    "und-Bamu": "bax-Bamu-CM",
    "und-Bass": "bsq-Bass-LR",
    "und-Batk": "bbc-Batk-ID",
    "und-Beng": "bn-Beng-BD",
    "und-Bhks": "sa-Bhks-IN",
    "und-Bopo": "zh-Bopo-TW",
    "und-Brah": "pka-Brah-IN",
    "und-Brai": "fr-Brai-FR",
    "und-Bugi": "bug-Bugi-ID",
    "und-Buhd": "bku-Buhd-PH",
    "und-CC": "ms-Arab-CC",
    "und-CD": "sw-Latn-CD",
    "und-CF": "fr-Latn-CF",
    "und-CG": "fr-Latn-CG",
    "und-CH": "de-Latn-CH",
    "und-CI": "fr-Latn-CI",
    "und-CL": "es-Latn-CL",
    "und-CM": "fr-Latn-CM",
    "und-CN": "zh-Hans-CN",
    "und-CO": "es-Latn-CO",
    "und-CR": "es-Latn-CR",
    "und-CU": "es-Latn-CU",
    "und-CV": "pt-Latn-CV",
    "und-CW": "pap-Latn-CW",
    "und-CY": "el-Grek-CY",
    "und-CZ": "cs-Latn-CZ",
    "und-Cakm": "ccp-Cakm-BD",
    "und-Cans": "iu-Cans-CA",
    "und-Cari": "xcr-Cari-TR",
    "und-Cham": "cjm-Cham-VN",
    "und-Cher": "chr-Cher-US",
    "und-Chrs": "xco-Chrs-UZ",
    "und-Copt": "cop-Copt-EG",
    "und-Cpmn": "und-Cpmn-CY",
    "und-Cprt": "grc-Cprt-CY",
    "und-Cyrl": "ru-Cyrl-RU",
    "und-Cyrl-AF": "kaa-Cyrl-AF",
    "und-Cyrl-AL": "mk-Cyrl-AL",
    "und-Cyrl-AZ": "az-Cyrl-AZ",
    "und-Cyrl-BA": "sr-Cyrl-BA",
    "und-Cyrl-BG": "bg-Cyrl-BG",
    "und-Cyrl-BY": "be-Cyrl-BY",
    "und-Cyrl-GE": "ab-Cyrl-GE",
    "und-Cyrl-GR": "mk-Cyrl-GR",
    "und-Cyrl-IR": "kaa-Cyrl-IR",
    "und-Cyrl-KG": "ky-Cyrl-KG",
    "und-Cyrl-MD": "uk-Cyrl-MD",
    "und-Cyrl-ME": "sr-Cyrl-ME",
    "und-Cyrl-MK": "mk-Cyrl-MK",
    "und-Cyrl-MN": "mn-Cyrl-MN",
    "und-Cyrl-RO": "bg-Cyrl-RO",
    "und-Cyrl-RS": "sr-Cyrl-RS",
    "und-Cyrl-SK": "uk-Cyrl-SK",
    "und-Cyrl-TJ": "tg-Cyrl-TJ",
    "und-Cyrl-TR": "kbd-Cyrl-TR",
    "und-Cyrl-UA": "uk-Cyrl-UA",
    "und-Cyrl-UZ": "uz-Cyrl-UZ",
    "und-Cyrl-XK": "sr-Cyrl-XK",
    "und-DE": "de-Latn-DE",
    "und-DJ": "aa-Latn-DJ",
    "und-DK": "da-Latn-DK",
    "und-DO": "es-Latn-DO",
    "und-DZ": "ar-Arab-DZ",
    "und-Deva": "hi-Deva-IN",
    "und-Deva-BT": "ne-Deva-BT",
    "und-Deva-FJ": "hif-Deva-FJ",
    "und-Deva-MU": "bho-Deva-MU",
    "und-Deva-NP": "ne-Deva-NP",
    "und-Deva-PK": "btv-Deva-PK",
    "und-Diak": "dv-Diak-MV",
    "und-Dogr": "doi-Dogr-IN",
    "und-Dupl": "fr-Dupl-FR",
    "und-EA": "es-Latn-EA",
    "und-EC": "es-Latn-EC",
    "und-EE": "et-Latn-EE",
    "und-EG": "ar-Arab-EG",
    "und-EH": "ar-Arab-EH",
    "und-ER": "ti-Ethi-ER",
    "und-ES": "es-Latn-ES",
    "und-ET": "am-Ethi-ET",
    "und-Egyp": "egy-Egyp-EG",
    "und-Elba": "sq-Elba-AL",
    "und-Elym": "arc-Elym-IR",
    "und-Ethi": "am-Ethi-ET",
    "und-Ethi-ER": "ti-Ethi-ER",
    "und-FI": "fi-Latn-FI",
    "und-FO": "fo-Latn-FO",
    "und-FR": "fr-Latn-FR",
    "und-GA": "fr-Latn-GA",
    "und-GE": "ka-Geor-GE",
    "und-GF": "fr-Latn-GF",
    "und-GH": "ak-Latn-GH",
    "und-GL": "kl-Latn-GL",
    "und-GN": "fr-Latn-GN",
    "und-GP": "fr-Latn-GP",
    "und-GQ": "es-Latn-GQ",
    "und-GR": "el-Grek-GR",
    "und-GT": "es-Latn-GT",
    "und-GW": "pt-Latn-GW",
    "und-Gara": "wo-Gara-SN",
    "und-Geor": "ka-Geor-GE",
    "und-Glag": "cu-Glag-BG",
    "und-Gong": "wsg-Gong-IN",
    "und-Gonm": "esg-Gonm-IN",
    "und-Goth": "got-Goth-UA",
    "und-Gran": "sa-Gran-IN",
    "und-Grek": "el-Grek-GR",
    "und-Grek-TR": "bgx-Grek-TR",
    "und-Gujr": "gu-Gujr-IN",
    "und-Gukh": "gvr-Gukh-NP",
    "und-Guru": "pa-Guru-IN",
    "und-HK": "zh-Hant-HK",
    "und-HN": "es-Latn-HN",
    "und-HR": "hr-Latn-HR",
    "und-HT": "ht-Latn-HT",
    "und-HU": "hu-Latn-HU",
    "und-Hanb": "zh-Hanb-TW",
    "und-Hang": "ko-Hang-KR",
    "und-Hani": "zh-Hani-CN",
    "und-Hano": "hnn-Hano-PH",
    "und-Hans": "zh-Hans-CN",
    "und-Hant": "zh-Hant-TW",
    "und-Hant-CA": "yue-Hant-CA",
    "und-Hant-CN": "yue-Hant-CN",
    "und-Hatr": "arc-Hatr-IQ",
    "und-Hebr": "he-Hebr-IL",
    "und-Hebr-SE": "yi-Hebr-SE",
    "und-Hebr-UA": "yi-Hebr-UA",
    "und-Hebr-US": "yi-Hebr-US",
    "und-Hira": "ja-Hira-JP",
    "und-Hluw": "hlu-Hluw-TR",
    "und-Hmng": "hnj-Hmng-LA",
    "und-Hmnp": "hnj-Hmnp-US",
    "und-Hung": "hu-Hung-HU",
    "und-IC": "es-Latn-IC",
    "und-ID": "id-Latn-ID",
    "und-IL": "he-Hebr-IL",
    "und-IN": "hi-Deva-IN",
    "und-IQ": "ar-Arab-IQ",
    "und-IR": "fa-Arab-IR",
    "und-IS": "is-Latn-IS",
    "und-IT": "it-Latn-IT",
    "und-Ital": "ett-Ital-IT",
    "und-JO": "ar-Arab-JO",
    "und-JP": "ja-Jpan-JP",
    "und-Jamo": "ko-Jamo-KR",
    "und-Java": "jv-Java-ID",
    "und-Jpan": "ja-Jpan-JP",
    "und-KE": "sw-Latn-KE",
    "und-KG": "ky-Cyrl-KG",
    "und-KH": "km-Khmr-KH",
    "und-KM": "ar-Arab-KM",
    "und-KP": "ko-Kore-KP",
    "und-KR": "ko-Kore-KR",
    "und-KW": "ar-Arab-KW",
    "und-KZ": "ru-Cyrl-KZ",
    "und-Kali": "eky-Kali-MM",
    "und-Kana": "ja-Kana-JP",
    "und-Kawi": "kaw-Kawi-ID",
    "und-Khar": "pra-Khar-PK",
    "und-Khmr": "km-Khmr-KH",
    "und-Khoj": "sd-Khoj-IN",
    "und-Kits": "zkt-Kits-CN",
    "und-Knda": "kn-Knda-IN",
    "und-Kore": "ko-Kore-KR",
    "und-Krai": "bap-Krai-IN",
    "und-Kthi": "bho-Kthi-IN",
    "und-LA": "lo-Laoo-LA",
    "und-LB": "ar-Arab-LB",
    "und-LI": "de-Latn-LI",
    "und-LK": "si-Sinh-LK",
    "und-LS": "st-Latn-LS",
    "und-LT": "lt-Latn-LT",
    "und-LU": "fr-Latn-LU",
    "und-LV": "lv-Latn-LV",
    "und-LY": "ar-Arab-LY",
    "und-Lana": "nod-Lana-TH",
    "und-Laoo": "lo-Laoo-LA",
    "und-Latn-AE": "en-Latn-AE",
    "und-Latn-AF": "tk-Latn-AF",
    "und-Latn-AM": "ku-Latn-AM",
    "und-Latn-BD": "en-Latn-BD",
    "und-Latn-BG": "en-Latn-BG",
    "und-Latn-BT": "en-Latn-BT",
    "und-Latn-CC": "en-Latn-CC",
    "und-Latn-CN": "za-Latn-CN",
    "und-Latn-CY": "tr-Latn-CY",
    "und-Latn-DZ": "fr-Latn-DZ",
    "und-Latn-EG": "en-Latn-EG",
    "und-Latn-ER": "en-Latn-ER",
    "und-Latn-ET": "en-Latn-ET",
    "und-Latn-GE": "ku-Latn-GE",
    "und-Latn-GR": "en-Latn-GR",
    "und-Latn-HK": "en-Latn-HK",
    "und-Latn-IL": "en-Latn-IL",
    "und-Latn-IN": "en-Latn-IN",
    "und-Latn-IQ": "en-Latn-IQ",
    "und-Latn-IR": "tk-Latn-IR",
    "und-Latn-JO": "en-Latn-JO",
    "und-Latn-KM": "fr-Latn-KM",
    "und-Latn-KZ": "en-Latn-KZ",
    "und-Latn-LB": "en-Latn-LB",
    "und-Latn-LK": "en-Latn-LK",
    "und-Latn-MA": "fr-Latn-MA",
    "und-Latn-MK": "sq-Latn-MK",
    "und-Latn-MM": "kac-Latn-MM",
    "und-Latn-MO": "pt-Latn-MO",
    "und-Latn-MR": "fr-Latn-MR",
    "und-Latn-MV": "en-Latn-MV",
    "und-Latn-NP": "en-Latn-NP",
    "und-Latn-PK": "en-Latn-PK",
    "und-Latn-RU": "krl-Latn-RU",
    "und-Latn-SD": "en-Latn-SD",
    "und-Latn-SS": "en-Latn-SS",
    "und-Latn-SY": "fr-Latn-SY",
    "und-Latn-TH": "en-Latn-TH",
    "und-Latn-TN": "fr-Latn-TN",
    "und-Latn-TW": "trv-Latn-TW",
    "und-Latn-UA": "pl-Latn-UA",
    "und-Latn-YE": "en-Latn-YE",
    "und-Lepc": "lep-Lepc-IN",
    "und-Limb": "lif-Limb-IN",
    "und-Lina": "lab-Lina-GR",
    "und-Linb": "grc-Linb-GR",
    "und-Lisu": "lis-Lisu-CN",
    "und-Lyci": "xlc-Lyci-TR",
    "und-Lydi": "xld-Lydi-TR",
    "und-MA": "ar-Arab-MA",
    "und-MC": "fr-Latn-MC",
    "und-MD": "ro-Latn-MD",
    "und-ME": "sr-Latn-ME",
    "und-MF": "fr-Latn-MF",
    "und-MG": "mg-Latn-MG",
    "und-MK": "mk-Cyrl-MK",
    "und-ML": "bm-Latn-ML",
    "und-MM": "my-Mymr-MM",
    "und-MN": "mn-Cyrl-MN",
    "und-MO": "zh-Hant-MO",
    "und-MQ": "fr-Latn-MQ",
    "und-MR": "ar-Arab-MR",
    "und-MT": "mt-Latn-MT",
    "und-MU": "mfe-Latn-MU",
    "und-MV": "dv-Thaa-MV",
    "und-MX": "es-Latn-MX",
    "und-MY": "ms-Latn-MY",
    "und-MZ": "pt-Latn-MZ",
    "und-Mahj": "hi-Mahj-IN",
    "und-Maka": "mak-Maka-ID",
    "und-Mand": "myz-Mand-IR",
    "und-Mani": "xmn-Mani-CN",
    "und-Marc": "bo-Marc-CN",
    "und-Medf": "dmf-Medf-NG",
    "und-Mend": "men-Mend-SL",
    "und-Merc": "xmr-Merc-SD",
    "und-Mero": "xmr-Mero-SD",
    "und-Mlym": "ml-Mlym-IN",
    "und-Modi": "mr-Modi-IN",
    "und-Mong": "mn-Mong-CN",
    "und-Mroo": "mro-Mroo-BD",
    "und-Mtei": "mni-Mtei-IN",
    "und-Mult": "skr-Mult-PK",
    "und-Mymr": "my-Mymr-MM",
    "und-Mymr-IN": "kht-Mymr-IN",
    "und-Mymr-TH": "mnw-Mymr-TH",
    "und-NA": "af-Latn-NA",
    "und-NC": "fr-Latn-NC",
    "und-NE": "ha-Latn-NE",
    "und-NI": "es-Latn-NI",
    "und-NL": "nl-Latn-NL",
    "und-NO": "nb-Latn-NO",
    "und-NP": "ne-Deva-NP",
    "und-Nagm": "unr-Nagm-IN",
    "und-Nand": "sa-Nand-IN",
    "und-Narb": "xna-Narb-SA",
    "und-Nbat": "arc-Nbat-JO",
    "und-Newa": "new-Newa-NP",
    "und-Nkoo": "man-Nkoo-GN",
    "und-Nkoo-ML": "bm-Nkoo-ML",
    "und-Nshu": "zhx-Nshu-CN",
    "und-OM": "ar-Arab-OM",
    "und-Ogam": "sga-Ogam-IE",
    "und-Olck": "sat-Olck-IN",
    "und-Onao": "unr-Onao-IN",
    "und-Orkh": "otk-Orkh-MN",
    "und-Orya": "or-Orya-IN",
    "und-Osge": "osa-Osge-US",
    "und-Osma": "so-Osma-SO",
    "und-Ougr": "oui-Ougr-CN",
    "und-PA": "es-Latn-PA",
    "und-PE": "es-Latn-PE",
    "und-PF": "fr-Latn-PF",
    "und-PG": "tpi-Latn-PG",
    "und-PH": "fil-Latn-PH",
    "und-PK": "ur-Arab-PK",
    "und-PL": "pl-Latn-PL",
    "und-PM": "fr-Latn-PM",
    "und-PR": "es-Latn-PR",
    "und-PS": "ar-Arab-PS",
    "und-PT": "pt-Latn-PT",
    "und-PW": "pau-Latn-PW",
    "und-PY": "gn-Latn-PY",
    "und-Palm": "arc-Palm-SY",
    "und-Pauc": "ctd-Pauc-MM",
    "und-Perm": "kv-Perm-RU",
    "und-Phag": "lzh-Phag-CN",
    "und-Phli": "pal-Phli-IR",
    "und-Phlp": "pal-Phlp-CN",
    "und-Phnx": "phn-Phnx-LB",
    "und-Plrd": "hmd-Plrd-CN",
    "und-Prti": "xpr-Prti-IR",
    "und-QA": "ar-Arab-QA",
    "und-RE": "fr-Latn-RE",
    "und-RO": "ro-Latn-RO",
    "und-RS": "sr-Cyrl-RS",
    "und-RU": "ru-Cyrl-RU",
    "und-RW": "rw-Latn-RW",
    "und-Rjng": "rej-Rjng-ID",
    "und-Rohg": "rhg-Rohg-MM",
    "und-Runr": "non-Runr-SE",
    "und-SA": "ar-Arab-SA",
    "und-SC": "fr-Latn-SC",
    "und-SD": "ar-Arab-SD",
    "und-SE": "sv-Latn-SE",
    "und-SI": "sl-Latn-SI",
    "und-SJ": "nb-Latn-SJ",
    "und-SK": "sk-Latn-SK",
    "und-SL": "kri-Latn-SL",
    "und-SM": "it-Latn-SM",
    "und-SN": "fr-Latn-SN",
    "und-SO": "so-Latn-SO",
    "und-SR": "nl-Latn-SR",
    "und-SS": "ar-Arab-SS",
    "und-ST": "pt-Latn-ST",
    "und-SV": "es-Latn-SV",
    "und-SY": "ar-Arab-SY",
    "und-Samr": "smp-Samr-IL",
    "und-Sarb": "xsa-Sarb-YE",
    "und-Saur": "saz-Saur-IN",
    "und-Sgnw": "ase-Sgnw-US",
    "und-Shaw": "en-Shaw-GB",
    "und-Shrd": "sa-Shrd-IN",
    "und-Sidd": "sa-Sidd-IN",
    "und-Sind": "sd-Sind-IN",
    "und-Sinh": "si-Sinh-LK",
    "und-Sogd": "sog-Sogd-UZ",
    "und-Sogo": "sog-Sogo-UZ",
    "und-Sora": "srb-Sora-IN",
    "und-Soyo": "cmg-Soyo-MN",
    "und-Sund": "su-Sund-ID",
    "und-Sunu": "suz-Sunu-NP",
    "und-Sylo": "syl-Sylo-BD",
    "und-Syrc": "syr-Syrc-IQ",
    "und-TD": "fr-Latn-TD",
    "und-TF": "fr-Latn-TF",
    "und-TG": "fr-Latn-TG",
    "und-TH": "th-Thai-TH",
    "und-TJ": "tg-Cyrl-TJ",
    "und-TK": "tkl-Latn-TK",
    "und-TL": "pt-Latn-TL",
    "und-TM": "tk-Latn-TM",
    "und-TN": "ar-Arab-TN",
    "und-TO": "to-Latn-TO",
    "und-TR": "tr-Latn-TR",
    "und-TV": "tvl-Latn-TV",
    "und-TW": "zh-Hant-TW",
    "und-TZ": "sw-Latn-TZ",
    "und-Tagb": "tbw-Tagb-PH",
    "und-Takr": "doi-Takr-IN",
    "und-Tale": "tdd-Tale-CN",
    "und-Talu": "khb-Talu-CN",
    "und-Taml": "ta-Taml-IN",
    "und-Tang": "txg-Tang-CN",
    "und-Tavt": "blt-Tavt-VN",
    "und-Telu": "te-Telu-IN",
    "und-Tfng": "zgh-Tfng-MA",
    "und-Tglg": "fil-Tglg-PH",
    "und-Thaa": "dv-Thaa-MV",
    "und-Thai": "th-Thai-TH",
    "und-Thai-CN": "lcp-Thai-CN",
    "und-Thai-KH": "kdt-Thai-KH",
    "und-Thai-LA": "kdt-Thai-LA",
    "und-Tibt": "bo-Tibt-CN",
    "und-Tibt-BT": "dz-Tibt-BT",
    "und-Tirh": "mai-Tirh-IN",
    "und-Tnsa": "nst-Tnsa-IN",
    "und-Todr": "sq-Todr-AL",
    "und-Toto": "txo-Toto-IN",
    "und-Tutg": "sa-Tutg-IN",
    "und-UA": "uk-Cyrl-UA",
    "und-UG": "sw-Latn-UG",
    "und-UY": "es-Latn-UY",
    "und-UZ": "uz-Latn-UZ",
    "und-Ugar": "uga-Ugar-SY",
    "und-VA": "it-Latn-VA",
    "und-VE": "es-Latn-VE",
    "und-VN": "vi-Latn-VN",
    "und-VU": "bi-Latn-VU",
    "und-Vaii": "vai-Vaii-LR",
    "und-Vith": "sq-Vith-AL",
    "und-WF": "fr-Latn-WF",
    "und-WS": "sm-Latn-WS",
    "und-Wara": "hoc-Wara-IN",
    "und-Wcho": "nnp-Wcho-IN",
    "und-XK": "sq-Latn-XK",
    "und-Xpeo": "peo-Xpeo-IR",
    "und-Xsux": "akk-Xsux-IQ",
    "und-YE": "ar-Arab-YE",
    "und-YT": "fr-Latn-YT",
    "und-Yezi": "ku-Yezi-GE",
    "und-Yiii": "ii-Yiii-CN",
    "und-ZM": "bem-Latn-ZM",
    "und-ZW": "sn-Latn-ZW",
    "und-Zanb": "cmg-Zanb-MN",
    "une": "une-Latn-NG",
    "ung": "ung-Latn-AU",
    "uni": "uni-Latn-PG",
    "unk": "unk-Latn-BR",
    "unm": "unm-Latn-US",
    "unn": "unn-Latn-AU",
    "unr": "unr-Beng-IN",
    "unr-Deva": "unr-Deva-NP",
    "unr-NP": "unr-Deva-NP",
    "unu": "unu-Latn-PG",
    "unx": "unx-Beng-IN",
    "unz": "unz-Latn-ID",
    "uon": "uon-Latn-TW",
    "upi": "upi-Latn-PG",
    "upv": "upv-Latn-VU",
    "ur": "ur-Arab-PK",
    "ura": "ura-Latn-PE",
    "urb": "urb-Latn-BR",
    "urc": "urc-Latn-AU",
    "ure": "ure-Latn-BO",
    "urf": "urf-Latn-AU",
    "urg": "urg-Latn-PG",
    "urh": "urh-Latn-NG",
    "uri": "uri-Latn-PG",
    "urk": "urk-Thai-TH",
    "urm": "urm-Latn-PG",
    "urn": "urn-Latn-ID",
    "uro": "uro-Latn-PG",
    "urp": "urp-Latn-BR",
    "urr": "urr-Latn-VU",
    "urt": "urt-Latn-PG",
    "uru": "uru-Latn-BR",
    "urv": "urv-Latn-PG",
    "urw": "urw-Latn-PG",
    "urx": "urx-Latn-PG",
    "ury": "ury-Latn-ID",
    "urz": "urz-Latn-BR",
    "usa": "usa-Latn-PG",
    "ush": "ush-Arab-PK",
    "usi": "usi-Latn-BD",
    "usk": "usk-Latn-CM",
    "usp": "usp-Latn-GT",
    "uss": "uss-Latn-NG",
    "usu": "usu-Latn-PG",
    "uta": "uta-Latn-NG",
    "ute": "ute-Latn-US",
    "uth": "uth-Latn-NG",
    "utp": "utp-Latn-SB",
    "utr": "utr-Latn-NG",
    "utu": "utu-Latn-PG",
    "uum": "uum-Grek-GE",
    "uur": "uur-Latn-VU",
    "uve": "uve-Latn-NC",
    "uvh": "uvh-Latn-PG",
    "uvl": "uvl-Latn-PG",
    "uwa": "uwa-Latn-AU",
    "uya": "uya-Latn-NG",
    "uz": "uz-Latn-UZ",
    "uz-AF": "uz-Arab-AF",
    "uz-Arab": "uz-Arab-AF",
    "uz-CN": "uz-Cyrl-CN",
    "uzs": "uzs-Arab-AF",
    "vaa": "vaa-Taml-IN",
    "vae": "vae-Latn-CF",
    "vaf": "vaf-Arab-IR",
    "vag": "vag-Latn-GH",
    "vah": "vah-Deva-IN",
    "vai": "vai-Vaii-LR",
    "vaj": "vaj-Latn-NA",
    "val": "val-Latn-PG",
    "vam": "vam-Latn-PG",
    "van": "van-Latn-PG",
    "vao": "vao-Latn-VU",
    "vap": "vap-Latn-IN",
    "var": "var-Latn-MX",
    "vas": "vas-Deva-IN",
    "vau": "vau-Latn-CD",
    "vav": "vav-Deva-IN",
    "vay": "vay-Deva-NP",
    "vbb": "vbb-Latn-ID",
    "vbk": "vbk-Latn-PH",
    "ve": "ve-Latn-ZA",
    "vec": "vec-Latn-IT",
    "vem": "vem-Latn-NG",
    "veo": "veo-Latn-US",
    "vep": "vep-Latn-RU",
    "ver": "ver-Latn-NG",
    "vgr": "vgr-Arab-PK",
    "vi": "vi-Latn-VN",
    "vic": "vic-Latn-SX",
    "vid": "vid-Latn-TZ",
    "vif": "vif-Latn-CG",
    "vig": "vig-Latn-BF",
    "vil": "vil-Latn-AR",
    "vin": "vin-Latn-TZ",
    "vit": "vit-Latn-NG",
    "viv": "viv-Latn-PG",
    "vjk": "vjk-Deva-IN",
    "vka": "vka-Latn-AU",
    "vkj": "vkj-Latn-TD",
    "vkk": "vkk-Latn-ID",
    "vkl": "vkl-Latn-ID",
    "vkm": "vkm-Latn-BR",
    "vkn": "vkn-Latn-NG",
    "vko": "vko-Latn-ID",
    "vkp": "vkp-Latn-IN",
    "vkt": "vkt-Latn-ID",
    "vku": "vku-Latn-AU",
    "vkz": "vkz-Latn-NG",
    "vlp": "vlp-Latn-VU",
    "vls": "vls-Latn-BE",
    "vma": "vma-Latn-AU",
    "vmb": "vmb-Latn-AU",
    "vmc": "vmc-Latn-MX",
    "vmd": "vmd-Knda-IN",
    "vme": "vme-Latn-ID",
    "vmf": "vmf-Latn-DE",
    "vmg": "vmg-Latn-PG",
    "vmh": "vmh-Arab-IR",
    "vmi": "vmi-Latn-AU",
    "vmj": "vmj-Latn-MX",
    "vmk": "vmk-Latn-MZ",
    "vml": "vml-Latn-AU",
    "vmm": "vmm-Latn-MX",
    "vmp": "vmp-Latn-MX",
    "vmq": "vmq-Latn-MX",
    "vmr": "vmr-Latn-MZ",
    "vms": "vms-Latn-ID",
    "vmu": "vmu-Latn-AU",
    "vmw": "vmw-Latn-MZ",
    "vmx": "vmx-Latn-MX",
    "vmy": "vmy-Latn-MX",
    "vmz": "vmz-Latn-MX",
    "vnk": "vnk-Latn-SB",
    "vnm": "vnm-Latn-VU",
    "vnp": "vnp-Latn-VU",
    "vo": "vo-Latn-001",
    "vor": "vor-Latn-NG",
    "vot": "vot-Latn-RU",
    "vra": "vra-Latn-VU",
    "vro": "vro-Latn-EE",
    "vrs": "vrs-Latn-SB",
    "vrt": "vrt-Latn-VU",
    "vto": "vto-Latn-ID",
    "vum": "vum-Latn-GA",
    "vun": "vun-Latn-TZ",
    "vut": "vut-Latn-CM",
    "vwa": "vwa-Latn-CN",
    "wa": "wa-Latn-BE",
    "waa": "waa-Latn-US",
    "wab": "wab-Latn-PG",
    "wac": "wac-Latn-US",
    "wad": "wad-Latn-ID",
    "wae": "wae-Latn-CH",
    "waf": "waf-Latn-BR",
    "wag": "wag-Latn-PG",
    "wah": "wah-Latn-ID",
    "wai": "wai-Latn-ID",
    "waj": "waj-Latn-PG",
    "wal": "wal-Ethi-ET",
    "wam": "wam-Latn-US",
    "wan": "wan-Latn-CI",
    "wap": "wap-Latn-GY",
    "waq": "waq-Latn-AU",
    "war": "war-Latn-PH",
    "was": "was-Latn-US",
    "wat": "wat-Latn-PG",
    "wau": "wau-Latn-BR",
    "wav": "wav-Latn-NG",
    "waw": "waw-Latn-BR",
    "wax": "wax-Latn-PG",
    "way": "way-Latn-SR",
    "waz": "waz-Latn-PG",
    "wba": "wba-Latn-VE",
    "wbb": "wbb-Latn-ID",
    "wbe": "wbe-Latn-ID",
    "wbf": "wbf-Latn-BF",
    "wbh": "wbh-Latn-TZ",
    "wbi": "wbi-Latn-TZ",
    "wbj": "wbj-Latn-TZ",
    "wbk": "wbk-Arab-AF",
    "wbl": "wbl-Latn-PK",
    "wbm": "wbm-Latn-CN",
    "wbp": "wbp-Latn-AU",
    "wbq": "wbq-Telu-IN",
    "wbr": "wbr-Deva-IN",
    "wbt": "wbt-Latn-AU",
    "wbv": "wbv-Latn-AU",
    "wbw": "wbw-Latn-ID",
    "wca": "wca-Latn-BR",
    "wci": "wci-Latn-TG",
    "wdd": "wdd-Latn-GA",
    "wdg": "wdg-Latn-PG",
    "wdj": "wdj-Latn-AU",
    "wdk": "wdk-Latn-AU",
    "wdt": "wdt-Latn-CA",
    "wdu": "wdu-Latn-AU",
    "wdy": "wdy-Latn-AU",
    "wec": "wec-Latn-CI",
    "wed": "wed-Latn-PG",
    "weg": "weg-Latn-AU",
    "weh": "weh-Latn-CM",
    "wei": "wei-Latn-PG",
    "wem": "wem-Latn-BJ",
    "weo": "weo-Latn-ID",
    "wep": "wep-Latn-DE",
    "wer": "wer-Latn-PG",
    "wes": "wes-Latn-CM",
    "wet": "wet-Latn-ID",
    "weu": "weu-Latn-MM",
    "wew": "wew-Latn-ID",
    "wfg": "wfg-Latn-ID",
    "wga": "wga-Latn-AU",
    "wgb": "wgb-Latn-PG",
    "wgg": "wgg-Latn-AU",
    "wgi": "wgi-Latn-PG",
    "wgo": "wgo-Latn-ID",
    "wgu": "wgu-Latn-AU",
    "wgy": "wgy-Latn-AU",
    "wha": "wha-Latn-ID",
    "whg": "whg-Latn-PG",
    "whk": "whk-Latn-ID",
    "whu": "whu-Latn-ID",
    "wib": "wib-Latn-BF",
    "wic": "wic-Latn-US",
    "wie": "wie-Latn-AU",
    "wif": "wif-Latn-AU",
    "wig": "wig-Latn-AU",
    "wih": "wih-Latn-AU",
    "wii": "wii-Latn-PG",
    "wij": "wij-Latn-AU",
    "wik": "wik-Latn-AU",
    "wil": "wil-Latn-AU",
    "wim": "wim-Latn-AU",
    "win": "win-Latn-US",
    "wir": "wir-Latn-BR",
    "wiu": "wiu-Latn-PG",
    "wiv": "wiv-Latn-PG",
    "wiy": "wiy-Latn-US",
    "wja": "wja-Latn-NG",
    "wji": "wji-Latn-NG",
    "wka": "wka-Latn-TZ",
    "wkd": "wkd-Latn-ID",
    "wkr": "wkr-Latn-AU",
    "wkw": "wkw-Latn-AU",
    "wky": "wky-Latn-AU",
    "wla": "wla-Latn-PG",
    "wle": "wle-Ethi-ET",
    "wlg": "wlg-Latn-AU",
    "wlh": "wlh-Latn-TL",
    "wli": "wli-Latn-ID",
    "wlm": "wlm-Latn-GB",
    "wlo": "wlo-Arab-ID",
    "wlr": "wlr-Latn-VU",
    "wls": "wls-Latn-WF",
    "wlu": "wlu-Latn-AU",
    "wlv": "wlv-Latn-AR",
    "wlw": "wlw-Latn-ID",
    "wlx": "wlx-Latn-GH",
    "wma": "wma-Latn-NG",
    "wmb": "wmb-Latn-AU",
    "wmc": "wmc-Latn-PG",
    "wmd": "wmd-Latn-BR",
    "wme": "wme-Deva-NP",
    "wmh": "wmh-Latn-TL",
    "wmi": "wmi-Latn-AU",
    "wmm": "wmm-Latn-ID",
    "wmn": "wmn-Latn-NC",
    "wmo": "wmo-Latn-PG",
    "wms": "wms-Latn-ID",
    "wmt": "wmt-Latn-AU",
    "wmw": "wmw-Latn-MZ",
    "wmx": "wmx-Latn-PG",
    "wnb": "wnb-Latn-PG",
    "wnc": "wnc-Latn-PG",
    "wnd": "wnd-Latn-AU",
    "wne": "wne-Arab-PK",
    "wng": "wng-Latn-ID",
    "wni": "wni-Arab-KM",
    "wnk": "wnk-Latn-ID",
    "wnm": "wnm-Latn-AU",
    "wnn": "wnn-Latn-AU",
    "wno": "wno-Latn-ID",
    "wnp": "wnp-Latn-PG",
    "wnu": "wnu-Latn-PG",
    "wnw": "wnw-Latn-US",
    "wny": "wny-Latn-AU",
    "wo": "wo-Latn-SN",
    "woa": "woa-Latn-AU",
    "wob": "wob-Latn-CI",
    "woc": "woc-Latn-PG",
    "wod": "wod-Latn-ID",
    "woe": "woe-Latn-FM",
    "wof": "wof-Latn-GM",
    "wog": "wog-Latn-PG",
    "woi": "woi-Latn-ID",
    "wok": "wok-Latn-CM",
    "wom": "wom-Latn-NG",
    "won": "won-Latn-CD",
    "woo": "woo-Latn-ID",
    "wor": "wor-Latn-ID",
    "wos": "wos-Latn-PG",
    "wow": "wow-Latn-ID",
    "wpc": "wpc-Latn-VE",
    "wrb": "wrb-Latn-AU",
    "wrg": "wrg-Latn-AU",
    "wrh": "wrh-Latn-AU",
    "wri": "wri-Latn-AU",
    "wrk": "wrk-Latn-AU",
    "wrl": "wrl-Latn-AU",
    "wrm": "wrm-Latn-AU",
    "wro": "wro-Latn-AU",
    "wrp": "wrp-Latn-ID",
    "wrr": "wrr-Latn-AU",
    "wrs": "wrs-Latn-PG",
    "wru": "wru-Latn-ID",
    "wrv": "wrv-Latn-PG",
    "wrw": "wrw-Latn-AU",
    "wrx": "wrx-Latn-ID",
    "wrz": "wrz-Latn-AU",
    "wsa": "wsa-Latn-ID",
    "wsg": "wsg-Gong-IN",
    "wsi": "wsi-Latn-VU",
    "wsk": "wsk-Latn-PG",
    "wsr": "wsr-Latn-PG",
    "wss": "wss-Latn-GH",
    "wsu": "wsu-Latn-BR",
    "wsv": "wsv-Arab-AF",
    "wtb": "wtb-Latn-TZ",
    "wtf": "wtf-Latn-PG",
    "wth": "wth-Latn-AU",
    "wti": "wti-Latn-ET",
    "wtk": "wtk-Latn-PG",
    "wtm": "wtm-Deva-IN",
    "wtw": "wtw-Latn-ID",
    "wua": "wua-Latn-AU",
    "wub": "wub-Latn-AU",
    "wud": "wud-Latn-TG",
    "wul": "wul-Latn-ID",
    "wum": "wum-Latn-GA",
    "wun": "wun-Latn-TZ",
    "wur": "wur-Latn-AU",
    "wut": "wut-Latn-PG",
    "wuu": "wuu-Hans-CN",
    "wuv": "wuv-Latn-PG",
    "wux": "wux-Latn-AU",
    "wuy": "wuy-Latn-ID",
    "wwa": "wwa-Latn-BJ",
    "wwb": "wwb-Latn-AU",
    "wwo": "wwo-Latn-VU",
    "wwr": "wwr-Latn-AU",
    "www": "www-Latn-CM",
    "wxw": "wxw-Latn-AU",
    "wyb": "wyb-Latn-AU",
    "wyi": "wyi-Latn-AU",
    "wym": "wym-Latn-PL",
    "wyn": "wyn-Latn-US",
    "wyr": "wyr-Latn-BR",
    "wyy": "wyy-Latn-FJ",
    "xaa": "xaa-Latn-ES",
    "xab": "xab-Latn-NG",
    "xag": "xag-Aghb-AZ",
    "xai": "xai-Latn-BR",
    "xaj": "xaj-Latn-BR",
    "xak": "xak-Latn-VE",
    "xal": "xal-Cyrl-RU",
    "xam": "xam-Latn-ZA",
    "xan": "xan-Ethi-ET",
    "xao": "xao-Latn-VN",
    "xar": "xar-Latn-PG",
    "xas": "xas-Cyrl-RU",
    "xat": "xat-Latn-BR",
    "xau": "xau-Latn-ID",
    "xav": "xav-Latn-BR",
    "xaw": "xaw-Latn-US",
    "xay": "xay-Latn-ID",
    "xbb": "xbb-Latn-AU",
    "xbd": "xbd-Latn-AU",
    "xbe": "xbe-Latn-AU",
    "xbg": "xbg-Latn-AU",
    "xbi": "xbi-Latn-PG",
    "xbj": "xbj-Latn-AU",
    "xbm": "xbm-Latn-FR",
    "xbn": "xbn-Latn-MY",
    "xbp": "xbp-Latn-AU",
    "xbr": "xbr-Latn-ID",
    "xbw": "xbw-Latn-BR",
    "xby": "xby-Latn-AU",
    "xch": "xch-Latn-US",
    "xco": "xco-Chrs-UZ",
    "xcr": "xcr-Cari-TR",
    "xda": "xda-Latn-AU",
    "xdk": "xdk-Latn-AU",
    "xdo": "xdo-Latn-AO",
    "xdq": "xdq-Cyrl-RU",
    "xdy": "xdy-Latn-ID",
    "xed": "xed-Latn-CM",
    "xeg": "xeg-Latn-ZA",
    "xem": "xem-Latn-ID",
    "xer": "xer-Latn-BR",
    "xes": "xes-Latn-PG",
    "xet": "xet-Latn-BR",
    "xeu": "xeu-Latn-PG",
    "xgb": "xgb-Latn-CI",
    "xgd": "xgd-Latn-AU",
    "xgg": "xgg-Latn-AU",
    "xgi": "xgi-Latn-AU",
    "xgm": "xgm-Latn-AU",
    "xgu": "xgu-Latn-AU",
    "xgw": "xgw-Latn-AU",
    "xh": "xh-Latn-ZA",
    "xhe": "xhe-Arab-PK",
    "xhm": "xhm-Khmr-KH",
    "xhv": "xhv-Latn-VN",
    "xii": "xii-Latn-ZA",
    "xin": "xin-Latn-GT",
    "xir": "xir-Latn-BR",
    "xis": "xis-Orya-IN",
    "xiy": "xiy-Latn-BR",
    "xjb": "xjb-Latn-AU",
    "xjt": "xjt-Latn-AU",
    "xka": "xka-Arab-PK",
    "xkb": "xkb-Latn-BJ",
    "xkc": "xkc-Arab-IR",
    "xkd": "xkd-Latn-ID",
    "xke": "xke-Latn-ID",
    "xkf": "xkf-Tibt-BT",
    "xkg": "xkg-Latn-ML",
    "xkj": "xkj-Arab-IR",
    "xkl": "xkl-Latn-ID",
    "xkn": "xkn-Latn-ID",
    "xkp": "xkp-Arab-IR",
    "xkq": "xkq-Latn-ID",
    "xkr": "xkr-Latn-BR",
    "xks": "xks-Latn-ID",
    "xkt": "xkt-Latn-GH",
    "xku": "xku-Latn-CG",
    "xkv": "xkv-Latn-BW",
    "xkw": "xkw-Latn-ID",
    "xkx": "xkx-Latn-PG",
    "xky": "xky-Latn-MY",
    "xkz": "xkz-Latn-BT",
    "xla": "xla-Latn-PG",
    "xlc": "xlc-Lyci-TR",
    "xld": "xld-Lydi-TR",
    "xly": "xly-Elym-IR",
    "xma": "xma-Latn-SO",
    "xmb": "xmb-Latn-CM",
    "xmc": "xmc-Latn-MZ",
    "xmd": "xmd-Latn-CM",
    "xmf": "xmf-Geor-GE",
    "xmg": "xmg-Latn-CM",
    "xmh": "xmh-Latn-AU",
    "xmj": "xmj-Latn-CM",
    "xmm": "xmm-Latn-ID",
    "xmn": "xmn-Mani-CN",
    "xmo": "xmo-Latn-BR",
    "xmp": "xmp-Latn-AU",
    "xmq": "xmq-Latn-AU",
    "xmr": "xmr-Merc-SD",
    "xmt": "xmt-Latn-ID",
    "xmu": "xmu-Latn-AU",
    "xmv": "xmv-Latn-MG",
    "xmw": "xmw-Latn-MG",
    "xmx": "xmx-Latn-ID",
    "xmy": "xmy-Latn-AU",
    "xmz": "xmz-Latn-ID",
    "xna": "xna-Narb-SA",
    "xnb": "xnb-Latn-TW",
    "xni": "xni-Latn-AU",
    "xnj": "xnj-Latn-TZ",
    "xnk": "xnk-Latn-AU",
    "xnm": "xnm-Latn-AU",
    "xnn": "xnn-Latn-PH",
    "xnq": "xnq-Latn-MZ",
    "xnr": "xnr-Deva-IN",
    "xnt": "xnt-Latn-US",
    "xnu": "xnu-Latn-AU",
    "xny": "xny-Latn-AU",
    "xnz": "xnz-Latn-EG",
    "xoc": "xoc-Latn-NG",
    "xod": "xod-Latn-ID",
    "xog": "xog-Latn-UG",
    "xoi": "xoi-Latn-PG",
    "xok": "xok-Latn-BR",
    "xom": "xom-Latn-SD",
    "xon": "xon-Latn-GH",
    "xoo": "xoo-Latn-BR",
    "xop": "xop-Latn-PG",
    "xor": "xor-Latn-BR",
    "xow": "xow-Latn-PG",
    "xpa": "xpa-Latn-AU",
    "xpb": "xpb-Latn-AU",
    "xpd": "xpd-Latn-AU",
    "xpf": "xpf-Latn-AU",
    "xpg": "xpg-Grek-TR",
    "xph": "xph-Latn-AU",
    "xpi": "xpi-Ogam-GB",
    "xpj": "xpj-Latn-AU",
    "xpk": "xpk-Latn-BR",
    "xpl": "xpl-Latn-AU",
    "xpm": "xpm-Cyrl-RU",
    "xpn": "xpn-Latn-BR",
    "xpo": "xpo-Latn-MX",
    "xpq": "xpq-Latn-US",
    "xpr": "xpr-Prti-IR",
    "xpt": "xpt-Latn-AU",
    "xpv": "xpv-Latn-AU",
    "xpw": "xpw-Latn-AU",
    "xpx": "xpx-Latn-AU",
    "xpz": "xpz-Latn-AU",
    "xra": "xra-Latn-BR",
    "xrb": "xrb-Latn-BF",
    "xrd": "xrd-Latn-AU",
    "xre": "xre-Latn-BR",
    "xrg": "xrg-Latn-AU",
    "xri": "xri-Latn-BR",
    "xrm": "xrm-Cyrl-RU",
    "xrn": "xrn-Cyrl-RU",
    "xrr": "xrr-Latn-IT",
    "xru": "xru-Latn-AU",
    "xrw": "xrw-Latn-PG",
    "xsa": "xsa-Sarb-YE",
    "xsb": "xsb-Latn-PH",
    "xse": "xse-Latn-ID",
    "xsh": "xsh-Latn-NG",
    "xsi": "xsi-Latn-PG",
    "xsm": "xsm-Latn-GH",
    "xsn": "xsn-Latn-NG",
    "xsp": "xsp-Latn-PG",
    "xsq": "xsq-Latn-MZ",
    "xsr": "xsr-Deva-NP",
    "xsu": "xsu-Latn-VE",
    "xsy": "xsy-Latn-TW",
    "xta": "xta-Latn-MX",
    "xtb": "xtb-Latn-MX",
    "xtc": "xtc-Latn-SD",
    "xtd": "xtd-Latn-MX",
    "xte": "xte-Latn-ID",
    "xth": "xth-Latn-AU",
    "xti": "xti-Latn-MX",
    "xtj": "xtj-Latn-MX",
    "xtl": "xtl-Latn-MX",
    "xtm": "xtm-Latn-MX",
    "xtn": "xtn-Latn-MX",
    "xtp": "xtp-Latn-MX",
    "xtq": "xtq-Brah-IR",
    "xts": "xts-Latn-MX",
    "xtt": "xtt-Latn-MX",
    "xtu": "xtu-Latn-MX",
    "xtv": "xtv-Latn-AU",
    "xtw": "xtw-Latn-BR",
    "xty": "xty-Latn-MX",
    "xub": "xub-Taml-IN",
    "xud": "xud-Latn-AU",
    "xuj": "xuj-Taml-IN",
    "xul": "xul-Latn-AU",
    "xum": "xum-Latn-IT",
    "xun": "xun-Latn-AU",
    "xuo": "xuo-Latn-TD",
    "xut": "xut-Latn-AU",
    "xuu": "xuu-Latn-NA",
    "xve": "xve-Ital-IT",
    "xvi": "xvi-Arab-AF",
    "xvn": "xvn-Latn-ES",
    "xvo": "xvo-Latn-IT",
    "xvs": "xvs-Latn-IT",
    "xwa": "xwa-Latn-BR",
    "xwd": "xwd-Latn-AU",
    "xwe": "xwe-Latn-BJ",
    "xwj": "xwj-Latn-AU",
    "xwk": "xwk-Latn-AU",
    "xwl": "xwl-Latn-BJ",
    "xwo": "xwo-Cyrl-RU",
    "xwr": "xwr-Latn-ID",
    "xwt": "xwt-Latn-AU",
    "xww": "xww-Latn-AU",
    "xxb": "xxb-Latn-GH",
    "xxk": "xxk-Latn-ID",
    "xxm": "xxm-Latn-AU",
    "xxr": "xxr-Latn-BR",
    "xxt": "xxt-Latn-ID",
    "xya": "xya-Latn-AU",
    "xyb": "xyb-Latn-AU",
    "xyj": "xyj-Latn-AU",
    "xyk": "xyk-Latn-AU",
    "xyl": "xyl-Latn-BR",
    "xyt": "xyt-Latn-AU",
    "xyy": "xyy-Latn-AU",
    "xzh": "xzh-Marc-CN",
    "xzp": "xzp-Latn-MX",
    "yaa": "yaa-Latn-PE",
    "yab": "yab-Latn-BR",
    "yac": "yac-Latn-ID",
    "yad": "yad-Latn-PE",
    "yae": "yae-Latn-VE",
    "yaf": "yaf-Latn-CD",
    "yag": "yag-Latn-CL",
    "yah": "yah-Latn-TJ",
    "yai": "yai-Cyrl-TJ",
    "yaj": "yaj-Latn-CF",
    "yak": "yak-Latn-US",
    "yal": "yal-Latn-GN",
    "yam": "yam-Latn-CM",
    "yan": "yan-Latn-NI",
    "yao": "yao-Latn-MZ",
    "yap": "yap-Latn-FM",
    "yaq": "yaq-Latn-MX",
    "yar": "yar-Latn-VE",
    "yas": "yas-Latn-CM",
    "yat": "yat-Latn-CM",
    "yau": "yau-Latn-VE",
    "yav": "yav-Latn-CM",
    "yaw": "yaw-Latn-BR",
    "yax": "yax-Latn-AO",
    "yay": "yay-Latn-NG",
    "yaz": "yaz-Latn-NG",
    "yba": "yba-Latn-NG",
    "ybb": "ybb-Latn-CM",
    "ybe": "ybe-Latn-CN",
    "ybh": "ybh-Deva-NP",
    "ybi": "ybi-Deva-NP",
    "ybj": "ybj-Latn-NG",
    "ybl": "ybl-Latn-NG",
    "ybm": "ybm-Latn-PG",
    "ybn": "ybn-Latn-BR",
    "ybo": "ybo-Latn-PG",
    "ybx": "ybx-Latn-PG",
    "yby": "yby-Latn-PG",
    "ycl": "ycl-Latn-CN",
    "ycn": "ycn-Latn-CO",
    "ycr": "ycr-Latn-TW",
    "yda": "yda-Latn-AU",
    "yde": "yde-Latn-PG",
    "ydg": "ydg-Arab-PK",
    "ydk": "ydk-Latn-PG",
    "yea": "yea-Mlym-IN",
    "yec": "yec-Latn-DE",
    "yee": "yee-Latn-PG",
    "yei": "yei-Latn-CM",
    "yej": "yej-Grek-GR",
    "yel": "yel-Latn-CD",
    "yer": "yer-Latn-NG",
    "yes": "yes-Latn-NG",
    "yet": "yet-Latn-ID",
    "yeu": "yeu-Telu-IN",
    "yev": "yev-Latn-PG",
    "yey": "yey-Latn-BW",
    "yga": "yga-Latn-AU",
    "ygi": "ygi-Latn-AU",
    "ygl": "ygl-Latn-PG",
    "ygm": "ygm-Latn-PG",
    "ygp": "ygp-Plrd-CN",
    "ygr": "ygr-Latn-PG",
    "ygu": "ygu-Latn-AU",
    "ygw": "ygw-Latn-PG",
    "yhd": "yhd-Hebr-IL",
    "yi": "yi-Hebr-UA",
    "yia": "yia-Latn-AU",
    "yig": "yig-Yiii-CN",
    "yih": "yih-Hebr-DE",
    "yii": "yii-Latn-AU",
    "yij": "yij-Latn-AU",
    "yil": "yil-Latn-AU",
    "yim": "yim-Latn-IN",
    "yir": "yir-Latn-ID",
    "yis": "yis-Latn-PG",
    "yiv": "yiv-Yiii-CN",
    "yka": "yka-Latn-PH",
    "ykg": "ykg-Cyrl-RU",
    "ykh": "ykh-Cyrl-MN",
    "yki": "yki-Latn-ID",
    "ykk": "ykk-Latn-PG",
    "ykm": "ykm-Latn-PG",
    "yko": "yko-Latn-CM",
    "ykr": "ykr-Latn-PG",
    "yky": "yky-Latn-CF",
    "yla": "yla-Latn-PG",
    "ylb": "ylb-Latn-PG",
    "yle": "yle-Latn-PG",
    "ylg": "ylg-Latn-PG",
    "yli": "yli-Latn-ID",
    "yll": "yll-Latn-PG",
    "ylr": "ylr-Latn-AU",
    "ylu": "ylu-Latn-PG",
    "yly": "yly-Latn-NC",
    "ymb": "ymb-Latn-PG",
    "yme": "yme-Latn-PE",
    "ymg": "ymg-Latn-CD",
    "ymk": "ymk-Latn-MZ",
    "yml": "yml-Latn-PG",
    "ymm": "ymm-Latn-SO",
    "ymn": "ymn-Latn-ID",
    "ymo": "ymo-Latn-PG",
    "ymp": "ymp-Latn-PG",
    "yna": "yna-Plrd-CN",
    "ynd": "ynd-Latn-AU",
    "yng": "yng-Latn-CD",
    "ynk": "ynk-Cyrl-RU",
    "ynl": "ynl-Latn-PG",
    "ynq": "ynq-Latn-NG",
    "yns": "yns-Latn-CD",
    "ynu": "ynu-Latn-CO",
    "yo": "yo-Latn-NG",
    "yob": "yob-Latn-PG",
    "yog": "yog-Latn-PH",
    "yoi": "yoi-Jpan-JP",
    "yok": "yok-Latn-US",
    "yol": "yol-Latn-IE",
    "yom": "yom-Latn-CD",
    "yon": "yon-Latn-PG",
    "yot": "yot-Latn-NG",
    "yoy": "yoy-Thai-TH",
    "yra": "yra-Latn-PG",
    "yrb": "yrb-Latn-PG",
    "yre": "yre-Latn-CI",
    "yrk": "yrk-Cyrl-RU",
    "yrl": "yrl-Latn-BR",
    "yrm": "yrm-Latn-AU",
    "yro": "yro-Latn-BR",
    "yrs": "yrs-Latn-ID",
    "yrw": "yrw-Latn-PG",
    "yry": "yry-Latn-AU",
    "ysd": "ysd-Yiii-CN",
    "ysn": "ysn-Yiii-CN",
    "ysp": "ysp-Yiii-CN",
    "ysr": "ysr-Cyrl-RU",
    "yss": "yss-Latn-PG",
    "ysy": "ysy-Plrd-CN",
    "ytw": "ytw-Latn-PG",
    "yty": "yty-Latn-AU",
    "yua": "yua-Latn-MX",
    "yub": "yub-Latn-AU",
    "yuc": "yuc-Latn-US",
    "yud": "yud-Hebr-IL",
    "yue": "yue-Hant-HK",
    "yue-CN": "yue-Hans-CN",
    "yue-Hans": "yue-Hans-CN",
    "yuf": "yuf-Latn-US",
    "yug": "yug-Cyrl-RU",
    "yui": "yui-Latn-CO",
    "yuj": "yuj-Latn-PG",
    "yul": "yul-Latn-CF",
    "yum": "yum-Latn-US",
    "yun": "yun-Latn-NG",
    "yup": "yup-Latn-CO",
    "yuq": "yuq-Latn-BO",
    "yur": "yur-Latn-US",
    "yut": "yut-Latn-PG",
    "yuw": "yuw-Latn-PG",
    "yux": "yux-Cyrl-RU",
    "yuz": "yuz-Latn-BO",
    "yva": "yva-Latn-ID",
    "yvt": "yvt-Latn-VE",
    "ywa": "ywa-Latn-PG",
    "ywg": "ywg-Latn-AU",
    "ywn": "ywn-Latn-BR",
    "ywq": "ywq-Plrd-CN",
    "ywr": "ywr-Latn-AU",
    "ywu": "ywu-Plrd-CN",
    "yww": "yww-Latn-AU",
    "yxa": "yxa-Latn-AU",
    "yxg": "yxg-Latn-AU",
    "yxl": "yxl-Latn-AU",
    "yxm": "yxm-Latn-AU",
    "yxu": "yxu-Latn-AU",
    "yxy": "yxy-Latn-AU",
    "yyr": "yyr-Latn-AU",
    "yyu": "yyu-Latn-PG",
    "za": "za-Latn-CN",
    "zaa": "zaa-Latn-MX",
    "zab": "zab-Latn-MX",
    "zac": "zac-Latn-MX",
    "zad": "zad-Latn-MX",
    "zae": "zae-Latn-MX",
    "zaf": "zaf-Latn-MX",
    "zag": "zag-Latn-SD",
    "zah": "zah-Latn-NG",
    "zaj": "zaj-Latn-TZ",
    "zak": "zak-Latn-TZ",
    "zam": "zam-Latn-MX",
    "zao": "zao-Latn-MX",
    "zap": "zap-Latn-MX",
    "zaq": "zaq-Latn-MX",
    "zar": "zar-Latn-MX",
    "zas": "zas-Latn-MX",
    "zat": "zat-Latn-MX",
    "zau": "zau-Tibt-IN",
    "zav": "zav-Latn-MX",
    "zaw": "zaw-Latn-MX",
    "zax": "zax-Latn-MX",
    "zay": "zay-Latn-ET",
    "zaz": "zaz-Latn-NG",
    "zba": "zba-Arab-001",
    "zbc": "zbc-Latn-MY",
    "zbe": "zbe-Latn-MY",
    "zbt": "zbt-Latn-ID",
    "zbu": "zbu-Latn-NG",
    "zbw": "zbw-Latn-MY",
    "zca": "zca-Latn-MX",
    "zch": "zch-Hani-CN",
    "zdj": "zdj-Arab-KM",
    "zea": "zea-Latn-NL",
    "zeg": "zeg-Latn-PG",
    "zeh": "zeh-Hani-CN",
    "zem": "zem-Latn-NG",
    "zen": "zen-Tfng-MR",
    "zga": "zga-Latn-TZ",
    "zgb": "zgb-Hani-CN",
    "zgh": "zgh-Tfng-MA",
    "zgm": "zgm-Hani-CN",
    "zgn": "zgn-Hani-CN",
    "zgr": "zgr-Latn-PG",
    "zh": "zh-Hans-CN",
    "zh-AU": "zh-Hant-AU",
    "zh-BN": "zh-Hant-BN",
    "zh-Bopo": "zh-Bopo-TW",
    "zh-GB": "zh-Hant-GB",
    "zh-GF": "zh-Hant-GF",
    "zh-HK": "zh-Hant-HK",
    "zh-Hanb": "zh-Hanb-TW",
    "zh-Hant": "zh-Hant-TW",
    "zh-ID": "zh-Hant-ID",
    "zh-MO": "zh-Hant-MO",
    "zh-PA": "zh-Hant-PA",
    "zh-PF": "zh-Hant-PF",
    "zh-PH": "zh-Hant-PH",
    "zh-SR": "zh-Hant-SR",
    "zh-TH": "zh-Hant-TH",
    "zh-TW": "zh-Hant-TW",
    "zh-US": "zh-Hant-US",
    "zh-VN": "zh-Hant-VN",
    "zhd": "zhd-Hani-CN",
    "zhi": "zhi-Latn-NG",
    "zhn": "zhn-Latn-CN",
    "zhw": "zhw-Latn-CM",
    "zhx": "zhx-Nshu-CN",
    "zia": "zia-Latn-PG",
    "zik": "zik-Latn-PG",
    "zil": "zil-Latn-GN",
    "zim": "zim-Latn-TD",
    "zin": "zin-Latn-TZ",
    "ziw": "ziw-Latn-TZ",
    "ziz": "ziz-Latn-NG",
    "zka": "zka-Latn-ID",
    "zkd": "zkd-Latn-MM",
    "zko": "zko-Cyrl-RU",
    "zkp": "zkp-Latn-BR",
    "zkt": "zkt-Kits-CN",
    "zku": "zku-Latn-AU",
    "zkz": "zkz-Cyrl-RU",
    "zla": "zla-Latn-CD",
    "zlj": "zlj-Hani-CN",
    "zlm": "zlm-Latn-TG",
    "zln": "zln-Hani-CN",
    "zlq": "zlq-Hani-CN",
    "zlu": "zlu-Latn-NG",
    "zma": "zma-Latn-AU",
    "zmb": "zmb-Latn-CD",
    "zmc": "zmc-Latn-AU",
    "zmd": "zmd-Latn-AU",
    "zme": "zme-Latn-AU",
    "zmf": "zmf-Latn-CD",
    "zmg": "zmg-Latn-AU",
    "zmh": "zmh-Latn-PG",
    "zmi": "zmi-Latn-MY",
    "zmj": "zmj-Latn-AU",
    "zmk": "zmk-Latn-AU",
    "zml": "zml-Latn-AU",
    "zmm": "zmm-Latn-AU",
    "zmn": "zmn-Latn-GA",
    "zmo": "zmo-Latn-SD",
    "zmp": "zmp-Latn-CD",
    "zmq": "zmq-Latn-CD",
    "zmr": "zmr-Latn-AU",
    "zms": "zms-Latn-CD",
    "zmt": "zmt-Latn-AU",
    "zmu": "zmu-Latn-AU",
    "zmv": "zmv-Latn-AU",
    "zmw": "zmw-Latn-CD",
    "zmx": "zmx-Latn-CG",
    "zmy": "zmy-Latn-AU",
    "zmz": "zmz-Latn-CD",
    "zna": "zna-Latn-TD",
    "zne": "zne-Latn-CD",
    "zng": "zng-Latn-VN",
    "znk": "znk-Latn-AU",
    "zns": "zns-Latn-NG",
    "zoc": "zoc-Latn-MX",
    "zoh": "zoh-Latn-MX",
    "zom": "zom-Latn-IN",
    "zoo": "zoo-Latn-MX",
    "zoq": "zoq-Latn-MX",
    "zor": "zor-Latn-MX",
    "zos": "zos-Latn-MX",
    "zpa": "zpa-Latn-MX",
    "zpb": "zpb-Latn-MX",
    "zpc": "zpc-Latn-MX",
    "zpd": "zpd-Latn-MX",
    "zpe": "zpe-Latn-MX",
    "zpf": "zpf-Latn-MX",
    "zpg": "zpg-Latn-MX",
    "zph": "zph-Latn-MX",
    "zpi": "zpi-Latn-MX",
    "zpj": "zpj-Latn-MX",
    "zpk": "zpk-Latn-MX",
    "zpl": "zpl-Latn-MX",
    "zpm": "zpm-Latn-MX",
    "zpn": "zpn-Latn-MX",
    "zpo": "zpo-Latn-MX",
    "zpp": "zpp-Latn-MX",
    "zpq": "zpq-Latn-MX",
    "zpr": "zpr-Latn-MX",
    "zps": "zps-Latn-MX",
    "zpt": "zpt-Latn-MX",
    "zpu": "zpu-Latn-MX",
    "zpv": "zpv-Latn-MX",
    "zpw": "zpw-Latn-MX",
    "zpx": "zpx-Latn-MX",
    "zpy": "zpy-Latn-MX",
    "zpz": "zpz-Latn-MX",
    "zqe": "zqe-Hani-CN",
    "zrg": "zrg-Orya-IN",
    "zrn": "zrn-Latn-TD",
    "zro": "zro-Latn-EC",
    "zrp": "zrp-Hebr-FR",
    "zrs": "zrs-Latn-ID",
    "zsa": "zsa-Latn-PG",
    "zsr": "zsr-Latn-MX",
    "zsu": "zsu-Latn-PG",
    "zte": "zte-Latn-MX",
    "ztg": "ztg-Latn-MX",
    "ztl": "ztl-Latn-MX",
    "ztm": "ztm-Latn-MX",
    "ztn": "ztn-Latn-MX",
    "ztp": "ztp-Latn-MX",
    "ztq": "ztq-Latn-MX",
    "zts": "zts-Latn-MX",
    "ztt": "ztt-Latn-MX",
    "ztu": "ztu-Latn-MX",
    "ztx": "ztx-Latn-MX",
    "zty": "zty-Latn-MX",
    "zu": "zu-Latn-ZA",
    "zuh": "zuh-Latn-PG",
    "zum": "zum-Arab-OM",
    "zun": "zun-Latn-US",
    "zuy": "zuy-Latn-CM",
    "zwa": "zwa-Ethi-ET",
    "zyg": "zyg-Hani-CN",
    "zyj": "zyj-Latn-CN",
    "zyn": "zyn-Hani-CN",
    "zyp": "zyp-Latn-MM",
    "zza": "zza-Latn-TR",
    "zzj": "zzj-Hani-CN"
};
