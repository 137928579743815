import { template as template_bc29fcdb41f84bc38e55360ea70b1b2b } from "@ember/template-compiler";
import Pill from "glesys-controlpanel/components/pill";
import PillWarning from "glesys-controlpanel/components/pill/warning";
import DnsDomainStateLabel from "glesys-controlpanel/components/dns-domain-state-label";
import GButtonLinkTo from "glesys-controlpanel/components/g-button/link-to";
import { t } from "ember-intl";
import TableTd from "glesys-controlpanel/components/table/td";
export default template_bc29fcdb41f84bc38e55360ea70b1b2b(`
  <TableTd data-test-dns-domain-row={{@domain.id}}>
    {{@domain.displayname}}
  </TableTd>
  <TableTd>
    {{#if @domain.usingglesysnameserver}}
      {{! template-lint-disable no-bare-strings }}
      <Pill @text="GleSYS" class="my-2" />
    {{else}}
      <PillWarning @text={{t "label.other-provider"}} class="my-2" />
    {{/if}}
  </TableTd>
  <TableTd>
    {{#if @domain.isManagedByGlesys}}
      <DnsDomainStateLabel @domain={{@domain}} class="my-2" />
    {{else}}
      <PillWarning @text={{t "label.other-provider"}} class="my-2" />
    {{/if}}
  </TableTd>
  <TableTd>
    {{#if @domain.isManagedByGlesys}}
      <div class="size-3 rounded-full mx-8 {{if @domain.isAutoRenewalActive 'bg-ocean-green' 'bg-gray-light'}}"></div>
    {{/if}}
  </TableTd>
  <TableTd class="flex items-center justify-end pt-3 pb-3">
    {{#if @domain.id}}
      <GButtonLinkTo @color="gray" @route="dns.domain" @model={{@domain.id}}>
        {{t "button.manage"}}
      </GButtonLinkTo>
    {{/if}}
  </TableTd>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
