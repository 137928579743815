import Route from "@ember/routing/route";
import { task } from "ember-concurrency";

export default class RemoteHandsOverviewRoute extends Route {
  model() {
    return {
      loadRemoteHandsRequests: this.loadRemoteHandsRequests,
    };
  }

  loadRemoteHandsRequests = task(async () => {
    let { project } = this.modelFor("organization");
    return await project.get("remoteHandsRequest");
  });
}
