import { uncountable } from "@ember-data/request-utils/string";

export function initialize(/* container, application */) {
  uncountable("email-quota");
}

export default {
  name: "main",
  initialize,
};
