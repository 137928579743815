import ApplicationSerializer from "./application";

export default class LoadBalancerCertificateSerializer extends ApplicationSerializer {
  usesProxy = true;

  attrs = {
    loadBalancer: "loadbalancerid",
    name: "certificatename",
  };

  normalize(modelClass, resourceHash, prop) {
    let normalizedResouceHash = {
      id: resourceHash,
      name: resourceHash,
    };

    return super.normalize(modelClass, normalizedResouceHash, prop);
  }

  serialize() {
    let json = super.serialize(...arguments);

    json.certificate = btoa(json.certificate);
    delete json.id;

    return json;
  }
}
