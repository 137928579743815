/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { arg } from "ember-arg-types";
import { instanceOf } from "prop-types";
import NetworkCircuitModel from "glesys-controlpanel/models/network-circuit";
import { service } from "@ember/service";
import { constantToSentenceCase } from "glesys-controlpanel/helpers/constant-to-sentence-case";

export default class EntityNetworkCircuit extends Component {
  @service intl;

  @arg(instanceOf(NetworkCircuitModel)) entity;

  get networkCircuit() {
    return this.entity;
  }

  get tableData() {
    return [
      { header: this.intl.t("label.type"), value: constantToSentenceCase([this.networkCircuit.type]) },
      {
        header: this.intl.t("label.cost"),
        billing: this.networkCircuit.billing,
      },
    ];
  }
}
