import { template as template_7b47408eade543d6bdde4b9c9e1edff2 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf } from "prop-types";
import NotFound from "./not-found";
import Default from "./default";
import HttpError from "./http-error";
import { isHttpError, isNotFoundError } from "glesys-controlpanel/utils/errors";
export default @forbidExtraArgs
class RouteError extends Component {
    @arg(instanceOf(Error).isRequired)
    error;
    get component() {
        switch(true){
            case isNotFoundError(this.error):
                return NotFound;
            case isHttpError(this.error):
                return HttpError;
            default:
                return Default;
        }
    }
    static{
        template_7b47408eade543d6bdde4b9c9e1edff2(`
    <div data-test-route-error ...attributes>
      {{component this.component error=this.error}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
