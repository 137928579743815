import Ember from "ember";
import Bugsnag from "@bugsnag/js";
import ENV from "glesys-controlpanel/config/environment";

export function initialize(appInstance) {
  let session = appInstance.lookup("service:session");

  Ember.onerror = (error) => {
    let user = session.currentUser;
    if (ENV.environment === "production") {
      Bugsnag.setUser(user?.id, user?.username, user?.name);

      Bugsnag.notify(error);
    }
  };
}

export default {
  name: "bugsnag",
  initialize,
};
