import ApplicationSerializer from "./application";

export default class NetworkAdapterSerializer extends ApplicationSerializer {
  usesProxy = true;

  primaryKey = "networkadapterid";

  attrs = {
    adapterType: "adaptertype",
    server: "serverid",
  };

  serialize() {
    let json = super.serialize(...arguments);

    json.networkid = json.network ?? json.segment;

    return json;
  }

  normalize(modelClass, resourceHash, prop) {
    resourceHash.network = resourceHash.networkid;
    resourceHash.segment = resourceHash.networkid;

    return super.normalize(modelClass, resourceHash, prop);
  }
}
