import { template as template_82b6199bab0948069bd4ff846d90b14a } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { arg } from "ember-arg-types";
import { array, bool, func, object, oneOfType } from "prop-types";
import { Task, restartableTask } from "ember-concurrency";
import { modifier } from "ember-modifier";
import Skeleton from "glesys-controlpanel/components/skeleton";
export default class DataLoader extends Component {
    @arg(oneOfType([
        func,
        array,
        object
    ]).isRequired)
    load;
    @arg(bool)
    useLoadingSkeleton = true;
    get isLoading() {
        return this.task.isRunning || (this.load instanceof Task && this.load.isRunning);
    }
    loadData = modifier(async ()=>{
        this.task.perform(this.load);
        return ()=>this.task.cancelAll();
    });
    task = restartableTask(async (promise)=>{
        switch(true){
            case promise instanceof Task:
                return await promise.linked().perform();
            case typeof promise === "function":
                return await promise();
            case Array.isArray(promise):
                return await Promise.all(promise);
            default:
                return await promise;
        }
    });
    static{
        template_82b6199bab0948069bd4ff846d90b14a(`
    <div {{this.loadData}} ...attributes>
      {{#if (has-block)}}
        {{#if this.isLoading}}
          {{#if (has-block "loading")}}
            {{yield to="loading"}}
          {{else if this.useLoadingSkeleton}}
            <Skeleton data-test-data-loader-skeleton />
          {{else}}
            {{yield}}
          {{/if}}
        {{else if this.task.last.isError}}
          {{yield this.task.last.error to="error"}}
        {{else if this.task.last.isSuccessful}}
          {{yield this.task.last.value}}
        {{/if}}
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
