import { template as template_66f75ffe002c46bf94731fd00029adc5 } from "@ember/template-compiler";
import type { TOC } from "@ember/component/template-only";
interface OSEndpointSignature {
    Element: SVGElement;
}
const OsEndpoint: TOC<OSEndpointSignature> = template_66f75ffe002c46bf94731fd00029adc5(`
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" ...attributes>
    <path
      d="M4.06 13C4.24338 14.4558 4.82361 15.8331 5.73721 16.9813C6.65082 18.1295 7.8626 19.0043 9.24 19.51C8.44503 17.4311 8.03174 15.2257 8.02 13H4.06ZM4.06 11H8.02C8.03174 8.77428 8.44503 6.56894 9.24 4.49C7.8626 4.99572 6.65082 5.87052 5.73721 7.0187C4.82361 8.16687 4.24338 9.5442 4.06 11ZM19.94 11C19.7566 9.5442 19.1764 8.16687 18.2628 7.0187C17.3492 5.87052 16.1374 4.99572 14.76 4.49C15.555 6.56894 15.9683 8.77428 15.98 11H19.94ZM19.94 13H15.98C15.9683 15.2257 15.555 17.4311 14.76 19.51C16.1374 19.0043 17.3492 18.1295 18.2628 16.9813C19.1764 15.8331 19.7566 14.4558 19.94 13ZM10.02 13C10.18 16.95 11.25 20 12 20C12.75 20 13.82 16.95 13.98 13H10.02ZM10.02 11H13.98C13.82 7.05 12.75 4 12 4C11.25 4 10.18 7.05 10.02 11ZM12 22C9.34784 22 6.8043 20.9464 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12C2 9.34784 3.05357 6.8043 4.92893 4.92893C6.8043 3.05357 9.34784 2 12 2C14.6522 2 17.1957 3.05357 19.0711 4.92893C20.9464 6.8043 22 9.34784 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22Z"
    ></path>
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OsEndpoint;
declare module "@glint/environment-ember-loose/registry" {
    export default interface Registry {
        OsEndpoint: typeof OsEndpoint;
    }
}
