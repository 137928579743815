import { template as template_48ed036373df47e6a9d540ae4b7f7e00 } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import colors from "glesys-controlpanel/helpers/colors";
export default template_48ed036373df47e6a9d540ae4b7f7e00(`
  <span
    class="text-2xs px-1 leading-none py-0 font-bold uppercase tracking-wide rounded {{colors 'gray'}}"
    {{mergeClasses}}
    ...attributes
  >
    {{@text}}
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
