import ApplicationSerializer from "./application";
import DasherizedMixin from "./_dasherized-mixin";
import { service } from "@ember/service";

export default class ProjectSerializer extends ApplicationSerializer.extend(DasherizedMixin) {
  @service abilities;

  usesProxy = true;

  primaryKey = "accountname";

  attrs = {
    lockedReason: "lockedreason",
  };

  normalize(modelClass, resourceHash, prop) {
    resourceHash.links = {
      "api-keys": `/io.php/apikey/list?context=${resourceHash.accountname}`,
      archives: `/io.php/archive/list?context=${resourceHash.accountname}`,
      "archive-arguments": `/io.php/archive/allowedarguments?context=${resourceHash.accountname}`,
      "colocation-racks": `/io.php/colocationrack/list?context=${resourceHash.accountname}`,
      "dns-domains": `/io.php/domain/list?context=${resourceHash.accountname}`,
      "email-domains": `/io.php/email/overview?context=${resourceHash.accountname}`,
      "load-balancers": "loadbalancers",
      networks: `/io.php/network/list?context=${resourceHash.accountname}`,
      servers: "vps/servers",
      "file-storage-volumes": `/io.php/filestorage/listvolumes?context=${resourceHash.accountname}`,
      "private-networks": `/io.php/privatenetwork/list?context=${resourceHash.accountname}`,
      "network-circuits": `/io.php/networkcircuit/list?context=${resourceHash.accountname}`,
      "platform-arguments": "vps/arguments",
      "reserved-ips": "ips",
      "vpn-users": `/io.php/vpn/listusers?context=${resourceHash.accountname}`,
      "ssh-keys": `/io.php/sshkey/list?context=${resourceHash.accountname}`,
      shipments: `/io.php/shipment/list?context=${resourceHash.accountname}`,
      "remote-hands-request": `/io.php/remotehands/list?context=${resourceHash.accountname}`,
    };

    return super.normalize(modelClass, resourceHash, prop);
  }

  serialize() {
    let json = super.serialize(...arguments);

    json.organizationnumber = json.organization;
    json.projectkey = json.accountname;

    return json;
  }
}
