import ApplicationAdapter from "./application";

export default class DnsRecordAdapter extends ApplicationAdapter {
  urlForCreateRecord(_, snapshot) {
    return `${this.baseUri()}/domain/addrecord?domainname=${snapshot.belongsTo("domain").id}`;
  }

  urlForUpdateRecord(id) {
    return `${this.baseUri()}/domain/updaterecord?recordid=${id}`;
  }

  urlForDeleteRecord(id) {
    return `${this.baseUri()}/domain/deleterecord?recordid=${id}`;
  }
}
