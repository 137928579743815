import Model, { attr, belongsTo, hasMany } from "@ember-data/model";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class ServerModel extends Model {
  @service intl;
  @service metrics;
  @service router;

  @attr hostname;
  @attr datacenter;
  @attr platform;
  @attr description;

  @attr campaignCode;
  @attr cpucores;
  @attr memorysize;
  @attr disksize;
  @attr transfer;
  @attr bandwidth;

  @attr templatename;
  @attr rootpassword;
  @attr isofile;

  @attr cost;
  @attr billing;
  @attr backup;

  @attr supportedfeatures;

  @attr("yesno") hasManagedHosting;

  // Only used for creation
  @attr ipv4;
  @attr ipv6;
  @attr users;

  @attr cloudconfig;
  @attr cloudconfigtemplate;
  @attr cloudconfigkey;
  @attr cloudconfigparams;

  @attr additionaldisks;

  @belongsTo("project", { async: false, inverse: "servers" }) project;
  @belongsTo("server-cost", { async: true, inverse: "server" }) costs;
  @hasMany("reserved-ip", { async: false, inverse: "server" }) ips;
  @hasMany("network-adapter", { async: true, inverse: "server" }) networkAdapters;
  @belongsTo("platform-argument", { async: true, inverse: "server" }) platformArguments;
  @belongsTo("server-status", { async: true, inverse: "server" }) status;
  @hasMany("server-backup", { async: true, inverse: "server" }) backups;

  get truncatedHostname() {
    if (this.hostname.length > 60) {
      return this.hostname.slice(0, 60) + "...";
    }
    return this.hostname;
  }

  get statusString() {
    switch (this.status.get("status")) {
      case "creating":
        return this.intl.t("server.status.creating");
      case "updating":
        return this.intl.t("server.status.updating");
      case "restoring":
        return this.intl.t("server.status.restoring");
      case "on":
      case "on-bios":
        return this.intl.t("server.status.on");
      case "off":
      case "off-forced":
        return this.intl.t("server.status.off");
      case "reboot":
        return this.intl.t("server.status.reboot");
      case "reset":
        return this.intl.t("server.status.reset");
      default:
        return "";
    }
  }

  get canChangeNetwork() {
    return this.platform === "VMware";
  }

  get isKVM() {
    return this.platform === "KVM";
  }

  get isOpenVz() {
    return this.platform === "OpenVZ";
  }

  get isVMware() {
    return this.platform === "VMware";
  }

  get hasBackupActivated() {
    return !!(this.backup?.schedules?.length || this.backup?.enabled);
  }

  get isWindows() {
    let template = this.templatename;
    return `${template}`.toLowerCase().indexOf("windows") > -1;
  }

  get isPhysical() {
    return this.platform === "Physical Server";
  }

  get isVirtual() {
    return !this.isPhysical;
  }

  get isDedicated() {
    return this.isPhysical && this.id.match(/ds\d+/);
  }

  get isColocation() {
    return this.isPhysical && this.id.match(/cs\d+/);
  }

  get ipsVersion4() {
    return this.hasMany("ips")
      .value()
      ?.filter((ip) => ip.version === 4)
      .filter((ip) => !ip.isDeleted);
  }

  get ipsVersion6() {
    return this.hasMany("ips")
      .value()
      ?.filter((ip) => ip.version === 6)
      .filter((ip) => !ip.isDeleted);
  }

  can(feature) {
    return this.supportedfeatures?.[feature] === "yes";
  }

  get currency() {
    return this.cost?.currency || "";
  }

  get costPerHour() {
    return (this.cost?.amount || 0) / 720;
  }

  get costPerMonth() {
    return this.cost?.amount || 0;
  }

  get isRunning() {
    return this.status.get("isRunning");
  }

  get isSuspended() {
    return this.status.get("isSuspended");
  }

  get isLocked() {
    return this.status.get("isLocked");
  }

  get supportsEditCpuCores() {
    return this.can("editcpucores");
  }

  get supportsEditMemorySize() {
    return this.can("editmemorysize");
  }

  get supportsEditDiskSize() {
    return this.can("editdisksize");
  }

  get supportsDowngradingDiskSize() {
    return this.supportsEditDiskSize && !(this.isVMware || this.isKVM);
  }

  get supportsEditIp() {
    return this.can("editip");
  }

  get supportsPowerOn() {
    return this.can("start") && this.status.get("isStopped");
  }

  get supportsPowerOnBIOS() {
    return this.platform.toLowerCase() === "vmware";
  }

  get supportsReboot() {
    return this.can("reboot");
  }

  get supportsReset() {
    return this.can("reset");
  }

  get supportsPowerOff() {
    return this.can("stopsoft");
  }

  get supportsForcedPowerOff() {
    return this.can("stophard");
  }

  get supportsCloning() {
    return this.can("clone");
  }

  get supportsHtmlConsole() {
    return this.can("consolehtml");
  }

  get supportsDeletion() {
    return this.isVirtual && !this.status.get("isLocked");
  }

  get hasAdditionalDisks() {
    return this.additionaldisks?.length > 0;
  }

  //
  // reboot | off | off-forced | on | on-bios
  //
  power(type) {
    let { store } = this;
    let adapter = store.adapterFor(this.constructor.modelName);

    return adapter.power(this, type);
  }

  changeRootPassword(password) {
    let adapter = this.store.adapterFor(this.constructor.modelName);
    return adapter.changeRootPassword(this, password);
  }

  clone(data) {
    let adapter = this.store.adapterFor(this.constructor.modelName);
    return adapter.clone(this, data);
  }

  unlock() {
    let status = this.belongsTo("status");

    status?.value().set("isLocked", false);
    status?.value().set("status", "ready");

    let data = status?.value().serialize({ includeId: true });
    status.push(this.store.normalize("server-status", data));
  }

  lock(serverStatus = "ready") {
    let status = this.belongsTo("status");

    status?.value().set("isLocked", true);
    status?.value().set("status", serverStatus);

    let data = status?.value().serialize({ includeId: true });
    status.push(this.store.normalize("server-status", data));
  }

  matchesFilter(filter) {
    let { id, description, hostname } = this;
    return (
      (hostname && hostname.indexOf(filter) >= 0) ||
      (description && description.indexOf(filter) >= 0) ||
      (id && id.indexOf(filter) >= 0) ||
      this.hasMany("ips")
        .value()
        .slice()
        .some((ip) => ip.get("address").indexOf(filter) >= 0)
    );
  }

  editServerTask = task(async (properties) => {
    for (let [key, value] of Object.entries(properties)) {
      this[key] = value;
    }

    this.lock("updating");
    await this.save();
  });

  deleteServerTask = task(async (keepReservedIps) => {
    let server = this;

    try {
      server.deleteRecord();

      await server.save({ adapterOptions: { keepReservedIps } });

      this.metrics.trackEvent("Intercom", { event: "deleted-server" });

      if (this.router.currentRoute?.name !== "servers.overview") {
        this.router.transitionTo("servers.overview");
      }
    } catch (error) {
      server.rollbackAttributes();
      throw error;
    }
  });

  createNetworkAdapterTask = task(async (properties) => {
    let server = this;

    Object.assign(properties, { server });

    let networkAdapter = this.store.createRecord("network-adapter", properties);

    await networkAdapter.save().catch((error) => {
      networkAdapter.unloadRecord();
      throw error;
    });
  });
}
