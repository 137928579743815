/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";

export default class ModalsHotkeys extends Component {
  @service modal;
  @service intl;

  listeners = [
    {
      name: this.intl.t("hotkeys.section.basics"),
      hotkeys: [
        { keys: ["/"], description: this.intl.t("hotkeys.keys./") },
        { keys: ["?"], description: this.intl.t("hotkeys.keys.?") },
        { keys: [["p"], ["⌘", "k"]], description: this.intl.t("hotkeys.keys.p") },
        { keys: ["ctrl", "h"], description: this.intl.t("hotkeys.keys.ctrl+h") },
        { keys: ["ctrl"], text: this.intl.t("hotkeys.keys.org-text"), description: this.intl.t("hotkeys.keys.org") },
      ],
    },
  ];
}
