import { template as template_34b85e01297d41398be5950c75958d8f } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import CostCompact from "glesys-controlpanel/components/cost/compact";
export default template_34b85e01297d41398be5950c75958d8f(`
  <div
    class="flex items-center pt-3 border-gray-lighter border-t -mb-2 mt-12 justify-end"
    {{mergeClasses}}
    ...attributes
  >
    <CostCompact @currency={{@currency}} @price={{@cost}} @discountedPrice={{@discountedPrice}} />
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
