import ApplicationAdapter from "./application";

export default class NetworkAdapterAdapter extends ApplicationAdapter {
  urlForFindRecord(id) {
    return `${this.baseUri()}/networkadapter/details?networkadapterid=${id}`;
  }
  urlForCreateRecord() {
    return `${this.baseUri()}/networkadapter/create`;
  }

  urlForUpdateRecord(id) {
    return `${this.baseUri()}/networkadapter/edit?networkadapterid=${id}`;
  }

  urlForDeleteRecord(id) {
    return `${this.baseUri()}/networkadapter/delete?networkadapterid=${id}`;
  }
}
