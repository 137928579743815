import { template as template_fad8ae94cbb5471bbfcf26afd665deae } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf } from "prop-types";
import Organization from "../../models/organization";
import { task } from "ember-concurrency";
import { action } from "@ember/object";
import { modifier } from "ember-modifier";
import { tracked } from "@glimmer/tracking";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import Box from "glesys-controlpanel/components/box";
import IconsAnimatedWindmill from "glesys-controlpanel/components/icons/animated-windmill";
import GButtonDownload from "glesys-controlpanel/components/g-button/download";
import GButton from "glesys-controlpanel/components/g-button";
import { t } from "ember-intl";
import { on } from "@ember/modifier";
export default @forbidExtraArgs
class extends Component {
    @service
    ajax;
    @service
    metrics;
    @arg(instanceOf(Organization))
    organization;
    @tracked
    _organization;
    get certificateUrl() {
        return this.getGreenHostingCertificate.lastSuccessful.value;
    }
    resetState = modifier((_, [organization])=>{
        this._organization = organization;
        this.getGreenHostingCertificate.cancelAll({
            resetState: true
        });
    });
    @action
    handleGenerate() {
        this.getGreenHostingCertificate.perform();
    }
    getGreenHostingCertificate = task(async ()=>{
        await this.metrics.trackEvent("Intercom", {
            event: "generated-green-hosting-certificate"
        });
        let { url } = await this.ajax.post(`/io.php/certificate/generate`, {
            data: {
                organizationId: this.organization.id
            }
        });
        return url;
    });
    static{
        template_fad8ae94cbb5471bbfcf26afd665deae(`
    <Box class="mb-6" data-test-certificate {{this.resetState @organization}} {{mergeClasses}} ...attributes>
      <div class="flex flex-col md:flex-row items-center justify-between">
        <div class="flex flex-col sm:flex-row items-center">
          <div class="sm:-mt-3">
            <IconsAnimatedWindmill class="w-10 mb-2 sm:-mb-1" />
          </div>
          <div class="ml-0 sm:ml-6 text-center sm:text-left">
            <h2 class="text-black text-lg mb-1">{{t "component.green-hosting-certificate.title"}}</h2>
            <p class="text-gray-dark text-base">{{t "component.green-hosting-certificate.text"}}</p>
          </div>
        </div>
        {{#if this.getGreenHostingCertificate.last.isSuccessful}}
          <GButtonDownload @href={{this.certificateUrl}} class="mr-6 mt-6 sm:mt-0">
            {{t "component.green-hosting-certificate.button.download"}}
          </GButtonDownload>
        {{else}}
          <GButton
            @loading={{this.getGreenHostingCertificate.isRunning}}
            @color="blue"
            class="mt-6 md:mt-0 md:ml-3 lg:mr-6"
            {{on "click" this.handleGenerate}}
          >{{t "component.green-hosting-certificate.button.generate"}}</GButton>
        {{/if}}
      </div>
    </Box>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
