import ApplicationSerializer from "./application";

export default class SshKeySerializer extends ApplicationSerializer {
  usesProxy = true;

  attrs = {
    project: "account",
  };

  serialize() {
    let json = super.serialize(...arguments);

    json.projectkey = json.account;
    json.sshkey = json.data;

    return json;
  }
}
