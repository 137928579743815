import { hash } from "rsvp";
import Route from "@ember/routing/route";
import { service } from "@ember/service";

export default class ObjectStorageOverviewRoute extends Route {
  @service store;

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { project } = this.modelFor("organization");
    return hash({
      project,
      dataCenters: this.store.findAll("data-center"),
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    controller.setupController();
  }

  resetController(controller) {
    super.resetController(...arguments);
    controller.resetController();
  }
}
