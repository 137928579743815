import ApplicationAdapter from "./application";

export default class UserAdapter extends ApplicationAdapter {
  urlForFindRecord(id) {
    return `${this.baseUri()}/customer/settings?organizationnumber=${id}`;
  }

  urlForCreateRecord() {
    return `${this.baseUri()}/user/createorganization`;
  }

  urlForUpdateRecord(id) {
    return `${this.baseUri()}/customer/settings?organizationnumber=${id}`;
  }
}
