/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { tracked } from "@glimmer/tracking";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import Component from "@glimmer/component";

export default class ModalsServerOpenConsole extends Component {
  @service ajax;
  @service store;

  @tracked console;

  get server() {
    return this.args.params.server;
  }

  get consoleUrl() {
    return this.console.url;
  }

  fetchConsoleData = task(async () => {
    let { server, store } = this;
    let serverAdapter = store.adapterFor(server.constructor.modelName);

    let data = await serverAdapter.console(server);
    this.console = data.console;

    window.open(
      data.console.url,
      "_blank",
      "toolbar=no, scrollbars=yes, resizable=yes, top=50, left=50, width=1096, height=900",
    );
  });
}
