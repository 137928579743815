import { service } from "@ember/service";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import RSVP from "rsvp";
import { task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";

export default class ServerDetailsController extends Controller {
  @service ajax;
  @service metrics;
  @service modal;
  @service router;
  @service store;
  @service cost;

  get project() {
    return this.model.project;
  }

  get networks() {
    return this.model.networks;
  }

  get server() {
    return this.model.server;
  }

  get canEditBackup() {
    return this.server.isVMware;
  }

  @tracked editableDisk;
  @tracked editableMemory;
  @tracked editableCpu;

  get supportsEditCpuCores() {
    return this.server.supportsEditCpuCores;
  }

  get supportsEditMemorySize() {
    return this.server.supportsEditMemorySize;
  }

  get supportsEditDiskSize() {
    return this.server.supportsEditDiskSize;
  }

  get supportsEditIp() {
    return this.server.supportsEditIp;
  }

  get platform() {
    return this.server.platform;
  }

  get args() {
    return this.server.platformArguments;
  }

  get diskValues() {
    return this.args.get("diskSizes").map((diskSize) => diskSize.value);
  }

  get memoryValues() {
    return this.args.get("memorySizes").map((memorySize) => memorySize.value);
  }

  get cpuValues() {
    return this.args.get("cpuCores").map((cpuCore) => cpuCore.value);
  }

  // Sort by version:asc address:desc
  get sortedIps() {
    return this.server
      .hasMany("ips")
      .value()
      ?.slice()
      .sort((a, b) => {
        return a.version - b.version || a.address.replaceAll(/[.:]/g, "") - b.address.replaceAll(/[.:]/g, "");
      });
  }

  get serverResourcesIsDirty() {
    let { cpucores, memorysize, disksize } = this.server;
    return cpucores !== this.editableCpu || memorysize !== this.editableMemory || disksize !== this.editableDisk;
  }

  getServerBackupCost = task(async () => {
    let data = {
      context: this.project.id,
      serverid: this.server.id,
      enabled: true,
    };

    let result = await this.ajax.post("/io.php/server/estimatedbackupcost", { data }).catch(() => {});

    return result.billing.estimated.total;
  });

  removeIpTask = task(async (ip, options) => {
    if (options["keep-ip"]) {
      ip.set("server", null);
      await ip.save();
    } else {
      await ip.destroyRecord();
    }
  });

  assignIpsTask = task(async (ips) => {
    let store = this.store;
    let project = this.project;
    let server = this.server;

    await RSVP.all(
      ips.map((ip) => {
        let reservedIp;

        if (ip.get("isReserved")) {
          reservedIp = ip;
        } else {
          reservedIp = store.createRecord("reserved-ip", {
            project,
            address: ip.get("address"),
          });
        }
        reservedIp.set("server", server);

        return reservedIp.save().catch((error) => {
          reservedIp.rollbackAttributes();
          throw error;
        });
      }),
    );
  });

  saveServerConfigurationTask = task(async () => {
    let server = this.server;
    let config = {
      disksize: this.editableDisk,
      memorysize: this.editableMemory,
      cpucores: this.editableCpu,
    };
    server.setProperties(config);
    server.lock("updating");
    await server.save();
  });

  updateBackupConfigurationTask = task(async () => {
    let backupEnabled = !this.server.backup.enabled;
    let { project, server } = this;
    let url = `/io.php/projects/${project.id}/vps/servers/${server.id}/backup`;

    await this.ajax
      .post(url, {
        data: {
          enabled: backupEnabled,
        },
      })
      .then((response) => this.store.pushPayload("server", { server: response }));
  });

  @action
  openServerAddIpsModal(version) {
    let project = this.project;
    let server = this.server;
    let task = this.assignIpsTask;
    this.modal.open("modals/server-add-ips", {
      project,
      server,
      task,
      version,
    });
  }
}
