/* eslint-disable ember/no-new-mixins */
import { service } from "@ember/service";
import Mixin from "@ember/object/mixin";
import RESTAdapter from "@ember-data/adapter/rest";
import config from "../config/environment";
import { isIoUrl, ioApiBaseUri } from "../services/http";

export const projectChildMixin = Mixin.create({
  uriSuffix: null,

  buildURL(type, id, snapshot) {
    let baseUri = this.baseUriProject(snapshot);
    let uriSuffix = this.uriSuffix;
    let url = `${baseUri}/${uriSuffix}`;

    if (id) {
      url += `/${id}`;
    }

    return url;
  },
});

export const childMixin = Mixin.create({
  parentAdapter: null,
  parentRelationship: null,
  parentSuffix: null,

  buildURL(type, id, snapshot) {
    let parentAdapter = this.store.adapterFor(this.parentAdapter);
    let parentSnapshot = snapshot.belongsTo(this.parentRelationship);

    let baseUri = parentAdapter.buildURL(type, parentSnapshot.id, parentSnapshot);
    let url = `${baseUri}/${this.parentSuffix}`;

    if (id) {
      url += `/${id}`;
    }

    return url;
  },
});

export const adapterConfig = Mixin.create({
  session: service(),

  namespace: "io.php",

  get headers() {
    let headers = {
      "Content-Type": "application/json",
    };
    if (this.session.basicToken) {
      headers.Authorization = `Basic ${this.session.basicToken}`;
    }
    return headers;
  },

  get host() {
    if (config.ioApiBaseUri !== null) {
      return config.ioApiBaseUri;
    }

    return "";
  },
});

export default class ApplicationAdapter extends RESTAdapter.extend(adapterConfig) {
  coalesceFindRequests = true;

  ajaxOptions() {
    let options = super.ajaxOptions(...arguments);
    options.ifModified = true;
    return options;
  }

  get apiBaseUri() {
    return config.apiBaseUri;
  }

  baseUri() {
    let uri = [];

    uri.push(this.host);

    let namespace = this.namespace;
    uri.push(namespace);

    return uri.join("/");
  }

  _baseUri(model) {
    let snapshot = model._createSnapshot();
    return this.buildURL(snapshot.modelName, snapshot.id, snapshot);
  }

  baseUriProject(snapshot) {
    if (snapshot) {
      let baseUri = this.baseUri();
      let project;

      project = snapshot.belongsTo("project");

      if (typeof project !== "undefined") {
        return `${baseUri}/projects/${project.id}`;
      }

      return baseUri;
    }
  }

  findHasMany(store, snapshot, url /* , relationship */) {
    url = this.urlPrefix(url, this.buildURL(snapshot.modelName, snapshot.id, snapshot, "findHasMany"));
    return this.ajax(url, "GET");
  }

  normalizeErrorResponse(status, headers, payload) {
    return super.normalizeErrorResponse(status, headers, payload?.error?.message ?? payload);
  }

  buildURL(type, id, snapshot) {
    let uri = super.buildURL(...arguments);
    let apiBaseUri = isIoUrl(uri) ? ioApiBaseUri : config.apiBaseUri;
    let url = new URL(uri, apiBaseUri);

    let context = snapshot?.adapterOptions?.context;
    if (context) {
      url.searchParams.set("context", context);
    }
    return url.toString();
  }
}
