import { isPresent } from "@ember/utils";
import Controller from "@ember/controller";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";

export default class ServersOverviewController extends Controller {
  @service intl;

  queryParams = ["filter"];
  @tracked filter;

  showPricesPerHour = false;
  @tracked serverFilter = "";

  get isDedicatedServers() {
    return this.filter === "dedicated";
  }

  get title() {
    switch (this.filter) {
      case "dedicated":
        return this.intl.t("servers.dedicated.page-title");
      case "colocation":
        return this.intl.t("servers.colocation.page-title");
      case "virtual":
      default:
        return this.intl.t("servers.virtual.page-title");
    }
  }

  get description() {
    switch (this.filter) {
      case "dedicated":
        return this.intl.t("servers.dedicated.page-description");
      case "colocation":
        return this.intl.t("servers.colocation.page-description");
      case "virtual":
      default:
        return this.intl.t("servers.virtual.page-description");
    }
  }

  get readMoreLink() {
    switch (this.filter) {
      case "dedicated":
        return "https://glesys.com/dedicated";
      case "colocation":
        return "https://glesys.com/colocation";
      default:
        return "";
    }
  }

  get emptyStateText() {
    switch (this.filter) {
      case "dedicated":
        return this.intl.t("servers.dedicated.empty-state");
      case "colocation":
        return this.intl.t("servers.colocation.empty-state");
      case "virtual":
      default:
        return this.intl.t("servers.create.intro");
    }
  }

  get project() {
    return this.model.project;
  }

  get servers() {
    let servers = this.model.servers.filter((server) => !server.isDeleted);

    switch (this.filter) {
      case "dedicated":
        return servers.filter((server) => server.isDedicated);
      case "colocation":
        return servers.filter((server) => server.isColocation);
      case "virtual":
        return servers.filter((server) => server.isVirtual);
      default:
        return servers;
    }
  }

  get sortedServers() {
    return this.servers.slice().sort((a, b) => a.hostname.localeCompare(b.hostname));
  }

  get filteredServers() {
    let filter = this.serverFilter;
    let servers = this.sortedServers;

    if (isPresent(filter)) {
      return servers.filter((server) => {
        return server.matchesFilter(filter);
      });
    } else {
      return servers;
    }
  }

  onFilterChange = (event) => (this.serverFilter = event.target.value);
}
