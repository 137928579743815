import { service } from "@ember/service";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { task, timeout } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import ENV from "glesys-controlpanel/config/environment";

export default class OrganizationInvoicesOverviewController extends Controller {
  @service ajax;

  get invoices() {
    return this.model.invoices;
  }

  get paypalURL() {
    return this.model.paypalURL;
  }

  get organization() {
    return this.model.organization;
  }

  get unsettledInvoices() {
    return this.invoices.filter((invoice) => !invoice.isSettled);
  }

  get hasPayableInvoices() {
    return this.unsettledInvoices.filter((invoice) => !invoice.isCredit).length > 0;
  }

  get canSelectInvoices() {
    return (
      this.organization.canPayInvoicesManuallyUsingPaypal &&
      this.organization.paymentMethod !== "card" &&
      this.hasPayableInvoices
    );
  }

  // NOTE: We should be able to get the currency from the Organization
  get currency() {
    return this.invoices.at(0).currency;
  }

  @tracked selectedInvoices = null;

  get selectedInvoicesRemain() {
    return this.selectedInvoices.map((invoice) => invoice.remain);
  }

  get selectedInvoicesRemainSum() {
    return this.selectedInvoicesRemain.reduce((acc, curr) => acc + curr, 0);
  }

  get showPaymentOptions() {
    return this.selectedInvoicesRemainSum > 0;
  }

  saveInvoiceOptionsTask = task(async (properties) => {
    let organization = this.organization;
    organization.setProperties(properties);

    await organization
      .save()
      .then(() => {})
      .catch((error) => {
        organization.rollbackAttributes();
        throw error;
      });
  });

  payInvoices = task(async () => {
    let { selectedInvoices } = this;
    let invoiceids = selectedInvoices.map((invoice) => invoice.id).join("+");

    let paypalData = await this.ajax.post(`/io.php/invoice/paybypaypal`, {
      data: {
        invoiceids,
      },
    });

    let paypalUrl = paypalData.paypal.url;

    let win = window.open(paypalUrl, "paypalwindow");
    while (true) {
      if (ENV.environment !== "test") {
        await timeout(1000);
      }
      if (win == null || win.closed) {
        break;
      }
    }

    this.invoices.reload();
    this.invoices.forEach((invoice) => (invoice.selected = false));
  });

  @action
  selectInvoice(invoice) {
    if (!this.selectedInvoices.includes(invoice)) {
      this.selectedInvoices = [...this.selectedInvoices, invoice];
    }
  }

  @action
  deselectInvoice(invoice) {
    let selectedInvoices = this.selectedInvoices.filter((i) => i !== invoice);
    this.selectedInvoices = selectedInvoices;
  }
}
