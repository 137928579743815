import { template as template_95776cc09cc644909502aeb86b4acbcf } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { Task, task } from "ember-concurrency";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { shape, instanceOf } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { tracked } from "@glimmer/tracking";
import GModalHeader from "glesys-controlpanel/components/g-modal/header";
import GModalBody from "glesys-controlpanel/components/g-modal/body";
import GModalFooter from "glesys-controlpanel/components/g-modal/footer";
import TaskForm from "glesys-controlpanel/components/task-form";
import Label from "glesys-controlpanel/components/label";
import Select from "glesys-controlpanel/components/select";
import CostCompact from "glesys-controlpanel/components/cost/compact";
import GButton from "glesys-controlpanel/components/g-button";
import InfoPopup from "glesys-controlpanel/components/info-popup";
import AlertMessage from "glesys-controlpanel/components/alert-message";
import modalClose from "glesys-controlpanel/helpers/modal-close";
import { on } from "@ember/modifier";
import { t } from "ember-intl";
export default @forbidExtraArgs
class ModalsDedicatedServerOrderForm extends Component {
    @service
    modal;
    @arg(shape({
        package: shape({
            name: stringable.isRequired
        }).isRequired,
        task: instanceOf(Task).isRequired
    }).isRequired)
    params;
    selectedDataCenter;
    @tracked
    selectedOperatingSystem;
    @tracked
    selectedStorage;
    get package() {
        return this.params.package;
    }
    get task() {
        return this.params.task;
    }
    get cost() {
        return this.package.mrc + this.selectedStorage?.cost;
    }
    get availableDataCenters() {
        return this.package.locations.filter((location)=>location.in_stock).map((location)=>({
                key: location.key,
                value: `${location.value} (${location.key})`
            }));
    }
    get availableOperatingSystem() {
        return [
            {
                value: "Install manually over IPMI"
            },
            {
                value: "Debian 12 (Bookworm)"
            },
            {
                value: "Debian 11 (Bullseye)"
            },
            {
                value: "Ubuntu 20.04 (Focal Fossa)"
            },
            {
                value: "Ubuntu 22.04 (Jammy Jellyfish)"
            },
            {
                value: "Ubuntu 24.04 (Noble Numbat)"
            },
            {
                value: "Windows Server Core 2022 Standard"
            },
            {
                value: "Windows Server 2022 Standard"
            },
            {
                value: "Windows Server Core 2025 Standard"
            },
            {
                value: "Windows Server 2025 Standard"
            }
        ];
    }
    get availableStorage() {
        return [
            {
                key: 480,
                value: "480 GiB",
                cost: 0
            },
            {
                key: 960,
                value: "960 GiB (+200 SEK)",
                cost: 200
            },
            {
                key: 1920,
                value: "1,920 GiB (+600 SEK)",
                cost: 600
            }
        ];
    }
    get isWindows() {
        return this.selectedOperatingSystem?.value.toLowerCase().startsWith("windows");
    }
    onSelectDataCenter = (dataCenter)=>(this.selectedDataCenter = dataCenter);
    onSelectOperatingSystem = (operatingSystem)=>(this.selectedOperatingSystem = operatingSystem);
    onSelectStorage = (storage)=>(this.selectedStorage = storage);
    submitTask = task(async ()=>{
        if (await this.task.perform(this.package, this.selectedDataCenter.key, this.selectedOperatingSystem.value, this.selectedStorage.key, this.cost)) {
            this.modal.close();
        }
    });
    static{
        template_95776cc09cc644909502aeb86b4acbcf(`
    <GModalHeader data-test-dedicated-server-order-form-header>
      {{t "component.modal.dedicated-server-order-form.header" name=this.package.name}}
    </GModalHeader>
    <GModalBody data-test-dedicated-server-order-form-body>
      <TaskForm @task={{this.submitTask}} id="dedicated-server-order-form">
        <div class="grid gap-6 text-gray-darker mb-6" data-test-dedicated-server-order-intro>
          <p>{{t "component.modal.dedicated-server-order-form.info.delivery"}}</p>
          <p>{{t "component.modal.dedicated-server-order-form.info.email-confirmation"}}</p>
        </div>

        <div class="rounded border border-gray-light bg-gray-lightest p-4" data-test-dedicated-server-order-summary>
          <p class="mb-4" data-test-dedicated-server-order-summary-name>
            {{t "component.modal.dedicated-server-order-form.package" name=this.package.name}}
          </p>
          <div class="grid text-sm">
            <div
              class="flex items-center justify-between border-b border-gray-light py-2"
              data-test-dedicated-server-order-summary-cpu
            >
              <Label class="w-auto mb-0">{{t "label.cpu"}}</Label>
              <div>
                {{this.package.cpu.value}}
                <span class="text-gray-darker">{{this.package.cpu.subValue}}</span>
              </div>
            </div>
            <div
              class="flex items-center justify-between border-b border-gray-light py-2"
              data-test-dedicated-server-order-summary-memory
            >
              <Label class="w-auto mb-0">{{t "label.memory"}}</Label>
              <div>{{t "servers.create.dedicated.memory" memory=this.package.memory}}</div>
            </div>
            <div
              class="flex items-center justify-between border-b border-gray-light py-2"
              data-test-dedicated-server-order-summary-storage
            >
              <Label class="w-auto mb-0">{{t "label.usable-storage"}}</Label>
              <div>{{t "servers.create.dedicated.storage" storage=this.package.storage}}</div>
            </div>
            <div
              class="flex items-center justify-between border-b border-gray-light py-2"
              data-test-dedicated-server-order-summary-connection
            >
              <Label class="w-auto mb-0">{{t "label.connection"}}</Label>
              <div>{{t "servers.create.dedicated.connection" connection=this.package.connection}}</div>
            </div>
            <div class="flex items-center justify-between py-2" data-test-dedicated-server-order-summary-ipv4>
              <Label class="w-auto mb-0">{{t "label.ipv4"}}</Label>
              <div>{{! template-lint-disable no-bare-strings }}1 pcs</div>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-4 mt-6">
          <p data-test-dedicated-server-order-configuration>
            {{t "component.modal.dedicated-server-order-form.configuration"}}
          </p>
          <div>
            <Label>{{t "label.data-center"}}</Label>
            <Select
              @items={{this.availableDataCenters}}
              @selected={{this.selectedDataCenter}}
              @onChange={{this.onSelectDataCenter}}
              data-test-dedicated-server-order-configuration-data-center
            />
          </div>
          <div>
            <Label class="flex items-center gap-2">{{t "label.operating-system"}}</Label>
            <Select
              @items={{this.availableOperatingSystem}}
              @selected={{this.selectedOperatingSystem}}
              @onChange={{this.onSelectOperatingSystem}}
              data-test-dedicated-server-order-configuration-operating-system
            />
          </div>
          <div>
            <Label class="flex items-center gap-2">
              {{t "label.usable-storage"}}
              <InfoPopup>{{t "component.modal.dedicated-server-order-form.storage-popup"}}</InfoPopup>
            </Label>
            <Select
              @items={{this.availableStorage}}
              @selected={{this.selectedStorage}}
              @onChange={{this.onSelectStorage}}
              data-test-dedicated-server-order-configuration-storage
            />
          </div>
        </div>
        <div
          class="grid gap-2 items-center justify-end mt-8 pt-4 border-t border-gray-light"
          data-test-dedicated-server-order-cost
        >
          <div class="flex gap-12 items-center justify-end" data-test-dedicated-server-order-cost-setup-fee>
            <Label class="mb-0">{{t "component.modal.dedicated-server-order-form.setup-fee"}}</Label>
            <CostCompact @price="0" @currency="SEK" @oneTimeCost={{true}} />
          </div>
          <div class="flex gap-12 items-center justify-end" data-test-dedicated-server-order-cost-mrc>
            <div class="flex gap-2 items-center">
              <Label class="mb-0">{{t "label.mrc"}}</Label>
              <InfoPopup>{{t "servers.create.dedicated.mrc-popup"}}</InfoPopup>
            </div>
            <CostCompact @price={{this.cost}} @currency="SEK" />
          </div>
        </div>
        {{#if this.isWindows}}
          <div class="flex justify-end pt-2">
            <AlertMessage>{{t "component.modal.dedicated-server-order-form.window-license-information"}}</AlertMessage>
          </div>
        {{/if}}
      </TaskForm>
    </GModalBody>
    <GModalFooter data-test-dedicated-server-order-form-footer>
      <GButton @color="gray" class="mr-2" data-test-dedicated-server-order-cancel {{on "click" (modalClose)}}>
        {{t "button.cancel"}}
      </GButton>

      <GButton @type="submit" @color="green" form="dedicated-server-order-form" data-test-dedicated-server-order-submit>
        {{t "button.place-order"}}
      </GButton>
    </GModalFooter>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
