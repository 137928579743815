/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";
import { capitalize } from "@ember/string";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(localeData);

export default class extends Component {
  //eslint-disable-next-line ember/no-tracked-properties-from-args
  @tracked value = this.args.value;

  @service intl;

  get formattedDate() {
    let dayIndex = dayjs.weekdays().findIndex((day) => day.toLowerCase() === this.value.weekDay.toLowerCase());

    let date = dayjs.utc(this.value.startTime, "HH:mm:ss").locale(this.intl.primaryLocale).day(dayIndex).local();

    return date.isBefore(dayjs.utc()) ? date.add(1, "week") : date;
  }

  get endTime() {
    return this.formattedDate.add(this.value.durationInMinutes, "minute");
  }

  get formattedWeekDay() {
    return capitalize(this.intl.formatDate(this.formattedDate, { weekday: "long" }));
  }

  get formattedStartTime() {
    return this.formattedDate.format("HH:mm");
  }

  get formattedTimezone() {
    return `UTC ${this.formattedDate.format("Z")}`;
  }

  get formattedEndTime() {
    return this.endTime.format("HH:mm");
  }
}
