import { template as template_67c3f68d19894167a855013c40d210f5 } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import gButtonColor from "glesys-controlpanel/helpers/g-button-color";
import IconsDownload from "../icons/download";
const isCompact = (display)=>display === "compact";
const isNotCompact = (display)=>!isCompact(display);
export default template_67c3f68d19894167a855013c40d210f5(`
  <div
    class="inline-flex items-center relative rounded text-sm transition-all duration-200 cursor-pointer
      {{if (isCompact @display) 'px-2 py-1' 'px-4 py-2'}}
      {{gButtonColor 'outline'}}
      transition-all duration-200"
    {{mergeClasses}}
    ...attributes
  >
    <a href={{@href}} class="flex items-center text-gray-darker" download>
      <IconsDownload class="w-4 mr-2.5 {{if (isNotCompact @display) '-ml-0.5'}} -mt-px text-glesys-blue-lighter" />
      <span>
        {{yield}}
      </span>
    </a>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
