import { template as template_fe7ce431273d400ab2d724689b3672fe } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf } from "prop-types";
import { Task, task } from "ember-concurrency";
import user from "glesys-controlpanel/models/user";
import Box from "glesys-controlpanel/components/box";
import IconsPassword from "glesys-controlpanel/components/icons/password";
import IconsChevronLeft from "glesys-controlpanel/components/icons/chevron-left";
import Icons2fa from "glesys-controlpanel/components/icons/2fa";
import GButton from "glesys-controlpanel/components/g-button";
import TaskForm from "glesys-controlpanel/components/task-form";
import Label from "glesys-controlpanel/components/label";
import GInput from "glesys-controlpanel/components/g-input";
import { t } from "ember-intl";
import { eq } from "ember-truth-helpers";
import { on } from "@ember/modifier";
import { fn } from "@ember/helper";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
const BackButton = template_fe7ce431273d400ab2d724689b3672fe(`
  <button
    class="mb-4 -mt-1 flex items-center hover:text-gray-dark hover:underline text-gray"
    type="submit"
    {{mergeClasses}}
    ...attributes
  >
    <IconsChevronLeft class="w-6 -ml-2" />
    <span>
      {{t "recover.confirm.back"}}
    </span>
  </button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default @forbidExtraArgs
class RecoverConfirm2faUser extends Component {
    @arg(instanceOf(Task).isRequired)
    task;
    @arg(instanceOf(user).isRequired)
    user;
    @tracked
    currentStep = "select-action";
    otp = null;
    password = null;
    @action
    setStep(step) {
        this.currentStep = step;
    }
    onOtpChange = ({ otp })=>(this.otp = otp);
    onPasswordChange = ({ password })=>(this.password = password);
    recoverTask = task(async ()=>{
        let { otp, password } = this;
        await this.task.perform({
            action: this.currentStep,
            otp,
            password
        });
    });
    static{
        template_fe7ce431273d400ab2d724689b3672fe(`
    <div ...attributes>
      {{#if (eq this.currentStep "select-action")}}
        <Box class="w-128">
          <:header>{{t "recover.title"}}</:header>
          <:default>
            {{yield}}
            <div class="flex flex-col">
              <p class="text-base text-gray-dark pb-6">
                {{t "recover.confirm.2fa.is-external-enabled"}}
                <br />
                {{t "recover.confirm.2fa.reset-or-disable"}}
              </p>
              <div class="w-full flex flex-wrap pb-4">
                <Box
                  data-test-2fa-reset-password
                  class="text-base w-full mb-4 text-gray-darker hover:border-gray hover:bg-gray-lightest"
                  role="button"
                  {{on "click" (fn this.setStep "2fa-reset-password")}}
                >
                  <div class="flex items-center">
                    <IconsPassword role="presentation" class="w-6 mr-3 -ml-1 text-gray-dark" />
                    <span>{{t "recover.confirm.2fa.reset-password"}}</span>
                  </div>
                </Box>
                <Box
                  data-test-revert-to-email-based-2fa
                  class="text-base w-full text-gray-darker hover:border-gray hover:bg-gray-lightest"
                  role="button"
                  {{on "click" (fn this.setStep "revert-to-email-based")}}
                >
                  <div class="flex items-center">
                    <Icons2fa role="presentation" class="w-6 mr-3 -ml-1 text-gray-dark" />
                    <span>{{t "recover.confirm.2fa.revert-to-email-based-2fa"}}</span>
                  </div>
                </Box>
              </div>
            </div>
          </:default>
        </Box>
      {{else}}
        {{#if (eq this.currentStep "2fa-reset-password")}}
          <Box class="w-128">
            <:header>{{t "recover.title"}}</:header>
            <:default>
              <BackButton {{on "click" (fn this.setStep "select-action")}} />
              <TaskForm
                @autocomplete="on"
                @method="post"
                @onChange={{this.onOtpChange}}
                @task={{this.recoverTask}}
                data-test-recover-password-2fa
                id="recover"
                class="w-full -mb-6"
              >
                <span class="text-base">{{t "recover.confirm.2fa.required"}}</span>
                <div class="my-4 text-gray-light">
                  <Label for="otp">
                    {{t "recover.confirm.2fa.label"}}
                  </Label>
                  <GInput
                    value={{this.otp}}
                    data-test-recover-one-time-password
                    id="otp"
                    name="otp"
                    required={{true}}
                  />
                </div>
              </TaskForm>
            </:default>
            <:footer>
              <GButton
                @type="submit"
                @color="green"
                @loading={{this.recoverTask.isRunning}}
                form="recover"
                data-test-recover-button
              >
                {{t "recover.confirm.create-new-password"}}
              </GButton>
            </:footer>
          </Box>
        {{else}}
          <Box class="w-128">
            <:header>{{t "recover.title"}}</:header>
            <:default>
              <BackButton {{on "click" (fn this.setStep "select-action")}} />
              <TaskForm
                @autocomplete="on"
                @method="post"
                @onChange={{this.onPasswordChange}}
                @task={{this.recoverTask}}
                data-test-recover-2fa
                id="recover"
                class="w-full -mb-6"
              >
                <span class="text-base">{{t "recover.confirm.2fa.lost"}}</span>
                <div class="my-4 text-gray-light">
                  <Label for="password">
                    {{t "recover.confirm.2fa.enter-password"}}
                  </Label>
                  <GInput
                    type="password"
                    value={{this.password}}
                    data-test-recover-2fa-password
                    id="password"
                    name="password"
                    required={{true}}
                  />
                </div>
              </TaskForm>
            </:default>
            <:footer>
              <GButton
                @type="submit"
                @color="green"
                @loading={{this.recoverTask.isRunning}}
                form="recover"
                data-test-recover-button
              >
                {{t "recover.confirm.2fa.revert-to-email-based-2fa"}}
              </GButton>
            </:footer>
          </Box>
        {{/if}}
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
