import { template as template_d34817deedcb41d3947485663ea85058 } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsCheck from "glesys-controlpanel/components/icons/check";
export default template_d34817deedcb41d3947485663ea85058(`
  <label
    class="grid grid-cols-[min-content,auto] gap-2 items-center has-[:enabled]:cursor-pointer has-[:disabled]:cursor-not-allowed"
  >
    <div class="grid [grid-template-areas:'checkbox'] [&>*]:[grid-area:checkbox]">
      <input
        type="checkbox"
        class="opacity-0 peer enabled:cursor-pointer disabled:cursor-not-allowed"
        data-test-checkbox
        {{mergeClasses}}
        ...attributes
      />
      <div class="bg-white border border-gray-light size-4 inline-flex items-center justify-center" />
      <IconsCheck
        class="stroke-gray-dark size-4 transition-transform origin-bottom-left scale-0 peer-checked:scale-100"
      />
    </div>
    {{#if (has-block)}}
      <span class="text-gray-darker text-sm leading-tight">
        {{yield}}
      </span>
    {{/if}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
