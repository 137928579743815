import { template as template_30490fb6d4f84ad6a5a6a457cdb17b4a } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { Task, restartableTask, timeout } from "ember-concurrency";
import ENV from "glesys-controlpanel/config/environment";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { func, instanceOf } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import TaskForm from "glesys-controlpanel/components/task-form";
import GInput from "glesys-controlpanel/components/g-input";
import GButton from "glesys-controlpanel/components/g-button";
import { t } from "ember-intl";
import { eq, or } from "ember-truth-helpers";
import { on } from "@ember/modifier";
export default @forbidExtraArgs
class extends Component {
    @arg(instanceOf(Task))
    searchTask;
    @arg(stringable)
    searchQuery = "";
    @arg(func.isRequired)
    onChange;
    @tracked
    error = false;
    get searchQueryIsTooShort() {
        return this.searchQuery.length > 0 && this.searchQuery.length < 3;
    }
    @action
    onClick(event) {
        this.error = false;
        if (this.searchQuery.length < 3) {
            this.error = true;
            event.preventDefault();
        }
    }
    @action
    onInputChange(event) {
        this.onChange(event.target.value);
        this.debounce.perform();
    }
    debounce = restartableTask(async ()=>{
        if (this.searchQueryIsTooShort) {
            if (ENV.environment !== "test") {
                await timeout(1000);
            }
            this.error = true;
        } else {
            this.error = false;
        }
    });
    static{
        template_30490fb6d4f84ad6a5a6a457cdb17b4a(`
    <TaskForm @task={{this.searchTask}} ...attributes>
      <div class="w-full flex mb-3">
        <div class="grow mr-4">
          <GInput
            value={{this.searchQuery}}
            class="mb-0"
            data-test-search-field
            disabled={{this.searchTask.isRunning}}
            placeholder={{t "dns.register.search-hint"}}
            {{on "input" this.onInputChange}}
          />
        </div>
        <GButton
          @type="submit"
          @color="gray"
          @loading={{this.searchTask.isRunning}}
          @disabled={{or this.searchQueryIsTooShort (eq this.searchQuery "")}}
          data-test-check-availability
          {{on "click" this.onClick}}
        >
          {{t "dns.search.button"}}
        </GButton>
      </div>
      {{#if this.error}}
        <span class="block text-xs text-left -mt-2 text-red" data-test-message>
          {{t "dns.register.search-warning"}}
        </span>
      {{/if}}
    </TaskForm>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
