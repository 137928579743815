import ApplicationSerializer from "./application";

export default class CollaboratorSerializer extends ApplicationSerializer {
  usesProxy = true;

  attrs = {
    isOwner: "isowner",
    hasTwoFactorEnabled: "hastwofactorenabled",
    projectAccess: "projectaccess",
  };

  normalize(modelClass, resourceHash, prop) {
    resourceHash.username = resourceHash.username || resourceHash.recipient;
    resourceHash.inviteStatus = resourceHash.invitestatus || resourceHash.status;

    return super.normalize(modelClass, resourceHash, prop);
  }

  serialize(snapshot) {
    let json = super.serialize(...arguments);

    // API expects "isowner" to be a boolean, not "yes" or "no"...
    json.isowner = json.isowner === "yes";
    json.email = json.username;
    json.customernumber = json.organization;
    json.collaboratorid = snapshot.id;

    if (json.projectaccess) {
      json.projectaccess = Object.keys(json.projectaccess)
        .map((key) => `${key}:${json.projectaccess[key]}`)
        .join(",");
    }

    return json;
  }
}
