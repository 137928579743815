import Model, { attr, belongsTo, hasMany } from "@ember-data/model";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class PrivateNetworkModel extends Model {
  @service router;

  @attr name;

  @belongsTo("project", { async: false, inverse: "privateNetworks" }) project;
  @hasMany("private-network-segment", { async: true, inverse: "privateNetwork" }) segments;

  deletePrivateNetworkTask = task(async () => {
    let privateNetwork = this;

    try {
      privateNetwork.deleteRecord();
      await privateNetwork.save();
    } catch (error) {
      privateNetwork.rollbackAttributes();
      throw error;
    }

    this.router.transitionTo("private-networks.overview");
  });

  updateTask = task(async (name) => {
    this.name = name;
    this.save();
  });
}
