import hbs from "htmlbars-inline-precompile";

export default {
  title: "QrCode",
  component: "QrCode",
};

export const Default = (args) => ({
  template: hbs`
    <QrCode @content={{this.content}} />
  `,
  context: args,
});

Default.args = {
  content: "Hello world",
};
