import ApplicationSerializer from "./application";
import DasherizedMixin from "./_dasherized-mixin";
import { EmbeddedRecordsMixin } from "@ember-data/serializer/rest";

export default class ServerSerializer extends ApplicationSerializer.extend(DasherizedMixin, EmbeddedRecordsMixin) {
  primaryKey = "serverid";

  attrs = {
    hasManagedHosting: "managedhosting",
    ips: { embedded: "always", key: "iplist" },
    project: "projectkey",
  };

  normalize(modelClass, resourceHash, prop) {
    resourceHash.iplist = Object.values(resourceHash.iplist).map((ip) => ({
      ...ip,
      project: resourceHash.projectkey,
      server: resourceHash.serverid,
    }));

    if (resourceHash.backup) {
      resourceHash.backup.enabled = resourceHash.backup.enabled === "yes";
    }

    resourceHash.links = {
      "network-adapters": `/io.php/server/networkadapters?serverid=${resourceHash.serverid}`,
      backups: `/io.php/server/listbackups?serverid=${resourceHash.serverid}`,
      costs: `/io.php/server/costs?serverid=${resourceHash.serverid}`,
      status: `/io.php/server/status?serverid=${resourceHash.serverid}`,
      "platform-arguments": `/io.php/projects/${resourceHash.projectkey}/vps/servers/${resourceHash.serverid}/arguments`,
    };

    return super.normalize(modelClass, resourceHash, prop);
  }
}
