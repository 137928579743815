/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import server from "glesys-controlpanel/models/server";
import { instanceOf } from "prop-types";

@forbidExtraArgs
export default class ServerConfigurationKvm extends Component {
  @service abilities;

  @arg(instanceOf(server)) server;

  get isEditable() {
    return this.abilities.can("reconfigure for server", this.server);
  }

  updateServerDetails = task(async (type, oldValue, newValue) => {
    let config = {},
      server = this.server;
    config[type] = newValue;
    for (let [key, value] of Object.entries(config)) {
      server.set(key, value);
    }
    await server.save();
  });
}
