import { template as template_383a880dffed4ba2bd87b0c59daf5f21 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { func } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import DnsGroup from "glesys-controlpanel/components/dns-group";
import DnsRecord from "glesys-controlpanel/components/dns-record";
import ButtonGroup from "glesys-controlpanel/components/button-group";
import GButton from "glesys-controlpanel/components/g-button";
import IconsChevronLeft from "glesys-controlpanel/components/icons/chevron-left";
import { on } from "@ember/modifier";
import { fn } from "@ember/helper";
export default @forbidExtraArgs
class DnsGroupPaginated extends Component {
    @arg(stringable.isRequired)
    type;
    @arg(func.isRequired)
    onTemplate;
    @arg
    records;
    @arg
    servers;
    pageSize = 30;
    @tracked
    page = 1;
    get pages() {
        let len = this.records.length;
        let pageSize = this.pageSize;
        return Math.ceil(len / pageSize);
    }
    get paginatedRecords() {
        let { records, page, pageSize } = this;
        let index = (page - 1) * pageSize;
        if (records.length > 0) {
            return records.slice(index, index + pageSize);
        }
        return [];
    }
    @action
    changePage(diff) {
        let { page, pages } = this;
        let np = parseInt(page, 10) + parseInt(diff, 10);
        if (np > 0 && np <= pages) {
            this.page = np;
        }
    }
    static{
        template_383a880dffed4ba2bd87b0c59daf5f21(`
    <DnsGroup @type={{this.type}} @onTemplate={{this.onTemplate}} ...attributes>
      {{#each this.paginatedRecords as |paginatedRecord|}}
        <DnsRecord @record={{paginatedRecord}} @servers={{this.servers}} />
      {{/each}}
      <tr>
        <td></td>
        <td colspan="4">
          <ButtonGroup class="flex justify-end">
            <GButton @color="outline" {{on "click" (fn this.changePage -1)}}>
              <IconsChevronLeft class="size-5" />
            </GButton>
            <GButton @color="outline">
              {{this.page}}/{{this.pages}}
            </GButton>
            <GButton @color="outline" {{on "click" (fn this.changePage 1)}}>
              <IconsChevronLeft class="size-5 rotate-180" />
            </GButton>
          </ButtonGroup>
        </td>
      </tr>
    </DnsGroup>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
