import { service } from "@ember/service";
import Route from "@ember/routing/route";
import RSVP from "rsvp";
import { task, enqueueTask } from "ember-concurrency";
import { NotFoundError } from "glesys-controlpanel/utils/errors";

export default class EmailDomainRoute extends Route {
  @service ajax;
  @service store;

  async model(params) {
    let { project } = this.modelFor("organization");

    let domains = await this.store.query("email-domain", {
      filter: params.email_domain_id,
      project: project.get("id"),
    });

    let domain = domains.find((domain) => domain.domainname === params.email_domain_id);

    if (!domain) {
      throw new NotFoundError();
    }

    return RSVP.hash({
      project,
      domain,
    });
  }

  afterModel(model) {
    let { domain } = model;

    let fetchDomainMailServerTask = this.fetchDomainMailServerTask;
    fetchDomainMailServerTask.perform(domain);

    let fetchQuotaTask = this.fetchQuotaTask;
    domain.get("accounts").then((emailAccounts) => {
      emailAccounts.forEach((emailAccount) => {
        fetchQuotaTask.perform(emailAccount);
      });
    });
  }

  fetchDomainMailServerTask = enqueueTask({ maxConcurrency: 3 }, async (domain) => {
    await domain.fetchMailServer();
  });

  fetchQuotaTask = task(async (emailAccount) => {
    let url = `/io.php/email/quota?emailaccount=${emailAccount.id}`;

    await this.ajax.request(url).then((response) => {
      let usedQuota = Math.round((response.quota.usedquotainmib / 1024) * 100) / 100;

      return this.store.pushPayload("email-account", {
        "email-account": {
          emailaccount: emailAccount.id,
          usedQuota,
        },
      });
    });
  });
}
