/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { action } from "@ember/object";
import { modifier } from "ember-modifier";
import { tracked } from "@glimmer/tracking";

export default class extends Component {
  @service session;
  @service intl;
  @service router;
  @service modal;
  @service keyboard;
  @service storage;

  get organization() {
    return this.args.organization;
  }

  get user() {
    return this.args.user;
  }

  get locale() {
    return this.intl.primaryLocale;
  }

  @tracked shouldHaveBorder = false;

  @action
  transitionTo(route) {
    this.router.transitionTo(route);
  }

  @action
  switchLanguage(locale) {
    this.intl.setLocaleIfValid(locale);
    this.storage.set("glesys:locale", locale);
  }

  @action
  signOut(status) {
    this.session.close.perform(status);
  }

  registerListeners = modifier(() => {
    this.keyboard.listenFor({ key: "?" }, () => this.modal.open("modals/hotkeys", {}));

    const handleScroll = () => (this.shouldHaveBorder = window.scrollY >= 80);

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      this.keyboard.stopListenFor({ key: "?" });
      window.removeEventListener("scroll", handleScroll);
    };
  });
}
