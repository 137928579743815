/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool, instanceOf } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { tracked } from "@glimmer/tracking";
import { Task, task } from "ember-concurrency";

@forbidExtraArgs
export default class EditableField extends Component {
  @arg(instanceOf(Task).isRequired) task;

  @arg(stringable) placeholder;

  @arg(stringable) value;

  @arg(bool) canEdit = true;

  @arg(stringable) contentsWrapperStyle;

  @tracked isEditing = false;
  @tracked currentValue = this.value;

  closeForm() {
    this.isEditing = false;
    this.currentValue = this.value;
  }

  submitTask = task(async () => {
    await this.task?.perform(this.currentValue);
    this.closeForm();
  });

  onChange = ({ currentValue }) => (this.currentValue = currentValue);

  @action
  close() {
    this.closeForm();
  }

  @action
  open() {
    this.isEditing = true;
  }
}
