/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { modifier } from "ember-modifier";

export default class extends Component {
  @service http;

  instanceId = this.args.instanceId;
  project = this.args.project;

  get cost() {
    return this.fetchEstimatedCostForInstance?.lastSuccessful?.value.billing;
  }

  fetchCostForInstance = modifier(() => {
    this.fetchEstimatedCostForInstance.perform();

    return () => this.fetchEstimatedCostForInstance.cancelAll();
  });

  fetchEstimatedCostForInstance = task(async () => {
    let { content } = await this.http.request({
      url: `/objectstorage/estimatedcost?instanceid=${this.instanceId}&projectkey=${this.project.id}`,
      method: "POST",
    });

    return content;
  });
}
