import { service } from "@ember/service";
import Route from "@ember/routing/route";
import RSVP from "rsvp";

export default class ServerIsoRoute extends Route {
  @service ajax;

  model() {
    let { project, server } = this.modelFor("server");

    return RSVP.hash({
      project,
      server,
      iso: this.ajax.request(`/io.php/server/listiso?serverid=${server.id}&projectkey=${project.id}`),
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.resetProperties();
  }
}
