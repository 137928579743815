/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf } from "prop-types";
import { Task, task } from "ember-concurrency";

@forbidExtraArgs
export default class OrganizationInvoiceOptions extends Component {
  @arg organization;

  @arg(instanceOf(Task).isRequired) task;

  organizationPaymentTermsNetDays = this.organization.paymentTermsNetDays;
  organizationInvoiceDeliveryMethod = this.organization.invoiceDeliveryMethod;
  organizationCurrentBillingEmails = this.organization.currentBillingEmails;
  organizationBillingEmailOverrides = this.organization.billingEmailOverrides;
  organizationVatNumber = this.organization.vatNumber;

  @arg paymentTerms;

  get invoiceAddressLines() {
    if (this.organization.separateInvoiceAddress) {
      return []
        .concat([this.organization.name], this.organization.invoiceAddress.split("\n"), [
          `${this.organization.invoiceZip} ${this.organization.invoiceCity}`,
          this.organization.invoiceCountry,
        ])
        .filter(Boolean);
    } else {
      return []
        .concat([this.organization.name], this.organization.address.split("\n"), [
          `${this.organization.zip} ${this.organization.city}`,
          this.organization.country,
        ])
        .filter(Boolean);
    }
  }

  onChange = ({ organizationBillingEmailOverrides, organizationVatNumber }) => {
    this.organizationBillingEmailOverrides = organizationBillingEmailOverrides;
    this.organizationVatNumber = organizationVatNumber;
  };

  submitTask = task(async () => {
    await this.task.perform({
      paymentTermsNetDays: this.organizationPaymentTermsNetDays,
      invoiceDeliveryMethod: this.organizationInvoiceDeliveryMethod,
      currentBillingEmails: this.organizationCurrentBillingEmails,
      billingEmailOverrides: this.organizationBillingEmailOverrides,
      vatNumber: this.organizationVatNumber,
    });
  });

  @action
  organizationInvoiceDeliveryMethodChange(method) {
    this.organizationInvoiceDeliveryMethod = method;
  }
}
