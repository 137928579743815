/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import styles from "./styles.css";

const WILDCARD = "0.0.0.0/0";

function difference(setA, setB) {
  let _difference = new Set(setA);
  for (let elem of setB) {
    _difference.delete(elem);
  }
  return _difference;
}

export default class ModalsApiKeyAccess extends Component {
  @service modal;
  @service notification;
  @service intl;

  styles = styles;

  get apiKey() {
    return this.args.params.apiKey;
  }

  get task() {
    return this.args.params.task;
  }

  @tracked host = null;
  @tracked hosts = this.apiKey.allowedhosts;

  submitTask = task(async (hosts) => {
    try {
      this.host = null;

      await this.task.perform(hosts);

      if (this.apiKey.allowedhosts.includes(WILDCARD)) {
        this.notification.add(
          "success",
          this.intl.t("component.modal.api-key-access.notifications.title"),
          this.intl.t("component.modal.api-key-access.notifications.allow-all-text"),
        );
        return;
      }

      this.notification.add(
        "success",
        this.intl.t("component.modal.api-key-access.notifications.title"),
        this.intl.t("component.modal.api-key-access.notifications.success-text", {
          hosts: this.apiKey.allowedhosts.length,
        }),
      );
    } catch {
      let delta = difference(new Set(this.hosts), this.apiKey.allowedhosts);
      if (delta.size) {
        this.notification.add(
          "error",
          this.intl.t("component.modal.api-key-access.notifications.title"),
          this.intl.t("component.modal.api-key-access.notifications.error-text", { hosts: delta.size }),
        );
      }
    }
  });

  addHostToAllowedHosts = task(async () => {
    let input = new Set(
      this.hosts.concat(
        this.host
          .split(",")
          .map((s) => s.trim())
          .filter((e) => e),
      ),
    );
    this.hosts = [...input.values()];
    await this.submitTask.perform(this.hosts);
  });

  onFormChange = ({ host }) => (this.host = host);

  @action
  removeHostFromAllowedHosts(host) {
    this.hosts = this.hosts.filter((h) => h !== host);
    this.submitTask.perform(this.hosts);
  }

  @action
  allowAllHosts() {
    this.hosts = [WILDCARD];
    this.submitTask.perform(this.hosts);
  }
}
