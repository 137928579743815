import Model, { attr, belongsTo, hasMany } from "@ember-data/model";

export default class RemoteHandsRequestModel extends Model {
  @attr key;
  @attr datacenter;
  @attr status;
  @attr executionoption;
  @attr instructions;
  @attr servicekeys;
  @attr createdat;
  @attr trackingids;

  @hasMany("shipment", { async: false, inverse: "remoteHandsRequest" }) shipments;
  @belongsTo("project", { async: false, inverse: "remoteHandsRequests" }) project;
}
