import { service } from "@ember/service";
import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class ServersCloneController extends Controller {
  @service ajax;
  @service router;

  get server() {
    return this.model.server;
  }

  hostname = null;
  description = null;

  @tracked dataCenterSelection = this.server.datacenter;

  get dataCenterOptions() {
    return this.server.platformArguments.get("dataCenters");
  }

  @tracked sizeSelection = this.server.disksize;

  get sizeOptions() {
    return this.server.platformArguments.get("diskSizes")?.map((size) => size.value);
  }

  @tracked memorySelection = this.server.memorysize;

  get memoryOptions() {
    return this.server.platformArguments.get("memorySizes")?.map((memory) => memory.value);
  }

  @tracked cpuSelection = this.server.cpucores;

  get cpuOptions() {
    return this.server.platformArguments.get("cpuCores")?.map((cpu) => cpu.value);
  }

  @tracked bandwidthSelection = this.server.bandwidth;

  get bandwidthOptions() {
    return this.server.platformArguments.get("externalBandwidthOptions")?.map((bandwith) => bandwith.value);
  }

  onChange = ({ hostname, description }) => {
    this.hostname = hostname;
    this.description = description;
  };

  cloneServerTask = task(async () => {
    let server = this.server;
    let data = {
      hostname: this.hostname,
      description: this.description,
      datacenter: this.dataCenterSelection,
      disksize: this.sizeSelection,
      memorysize: this.memorySelection,
      cpucores: this.cpuSelection,
      bandwidth: this.bandwidthSelection,
    };

    await server.clone(data);
    this.router.transitionTo("servers");
  });

  resetProperties() {
    this.hostname = `${this.server.hostname}-clone`;
    this.description = this.server.description || "";
    this.dataCenterSelection = this.server.datacenter;
    this.sizeSelection = this.server.disksize;
    this.memorySelection = this.server.memorysize;
    this.cpuSelection = this.server.cpucores;
    this.bandwidthSelection = this.server.bandwidth;
  }

  @action
  dataCenterChanged(dataCenter) {
    this.dataCenterSelection = dataCenter.value;
  }

  @action
  onCpuChange(value) {
    this.cpuSelection = value;
  }

  @action
  onMemoryChange(value) {
    this.memorySelection = value;
  }

  @action
  onSizeChange(value) {
    this.sizeSelection = value;
  }

  @action
  onBandwidthChange(value) {
    this.bandwidthSelection = value;
  }
}
