import Route from "@ember/routing/route";
import ENV from "glesys-controlpanel/config/environment";
import { task, enqueueTask, all, timeout } from "ember-concurrency";
import { service } from "@ember/service";

export default class ServersOverviewRoute extends Route {
  @service store;

  queryParams = {
    filter: {
      refreshModel: true,
    },
  };

  setupController() {
    super.setupController(...arguments);
    this.serverStatusLoopTask.perform();
  }

  resetController() {
    super.resetController(...arguments);
    this.serverStatusLoopTask.cancelAll();
  }

  serverStatusLoopTask = task(async () => {
    while (true) {
      let { servers } = this.modelFor("servers");
      await all(
        servers.filter((server) => !server.isDeleted).map((server) => this.fetchServerStatusTask.perform(server)),
      );

      if (ENV.environment === "test") {
        return;
      }

      await timeout(5 * 1000);
    }
  });

  fetchServerStatusTask = enqueueTask({ maxConcurrency: 2 }, async (server) => {
    let serverAdapter = this.store.adapterFor("server");

    try {
      await serverAdapter.get("fetchStatusTask").perform(server);
    } catch (e) {
      // The server has been removed in another window or by another user.
      if (e?.errors?.at(0)?.status == 404) {
        this.store.unloadRecord(server);
      } else if (e?.errors?.at(0)?.status == 400) {
        // Do nothing
      } else if (server.get("isDeleted")) {
        // Swallow the error if the server was just deleted.
      } else {
        throw e;
      }
    }
  });
}
