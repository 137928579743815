/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { arg } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { bool } from "prop-types";

export default class KvmGenerateUser extends Component {
  @service current;

  @arg(stringable) username;
  @arg(stringable) password;
  @arg(stringable) sshKey;

  @arg(bool) supportsSshKeys;

  get passwordIsValid() {
    return !this.password || this.password.length === 0 || this.password.length >= 8;
  }

  get sshKeys() {
    return this.project.sshKeys;
  }

  get project() {
    return this.current.project;
  }

  @action
  onSshKeySave(key) {
    this.args.onChange(this.username, this.password, key);
  }

  @action
  applySshKey(key) {
    this.args.onChange(this.username, this.password, key.data);
  }

  @action
  onUsernameChange(event) {
    this.args.onChange(event.target.value, this.password, this.sshKey);
  }

  @action
  onPasswordChange(event) {
    this.args.onChange(this.username, event.target.value, this.sshKey);
  }

  @action
  onSshKeyChange(event) {
    this.args.onChange(this.username, this.password, event.target.value);
  }
}
