const TEMPLATES = {
  CNAME: [
    {
      label: "GleSYS DKIM",
      records: [
        { host: "glesys-smtp._domainkey", ttl: 3600, rdata: "key.438b0bc3e63b.dkim.mail.glesys.com.", type: "CNAME" },
      ],
    },
  ],
  MX: [
    {
      label: "GleSYS",
      icon: "ico-glesys.png",
      records: [
        { host: "@", ttl: 3600, rdata: "10 mx01.glesys.se.", type: "MX" },
        { host: "@", ttl: 3600, rdata: "20 mx02.glesys.se.", type: "MX" },
      ],
    },
    {
      label: "Gmail/Google Apps",
      icon: "ico-google.png",
      records: [
        {
          host: "@",
          ttl: 3600,
          rdata: "1 aspmx.l.google.com.",
          type: "MX",
        },
        {
          host: "@",
          ttl: 3600,
          rdata: "5 alt1.aspmx.l.google.com.",
          type: "MX",
        },
        {
          host: "@",
          ttl: 3600,
          rdata: "5 alt2.aspmx.l.google.com.",
          type: "MX",
        },
        {
          host: "@",
          ttl: 3600,
          rdata: "10 alt3.aspmx.l.google.com.",
          type: "MX",
        },
        {
          host: "@",
          ttl: 3600,
          rdata: "10 alt4.aspmx.l.google.com.",
          type: "MX",
        },
      ],
    },
  ],

  NS: [
    {
      label: "GleSYS",
      icon: "ico-glesys.png",
      records: [
        { host: "@", ttl: 3600, rdata: "ns1.namesystem.se.", type: "NS" },
        { host: "@", ttl: 3600, rdata: "ns2.namesystem.se.", type: "NS" },
        { host: "@", ttl: 3600, rdata: "ns3.namesystem.se.", type: "NS" },
      ],
    },
  ],

  SRV: [
    {
      label: "Google Talk",
      icon: "ico-google.png",
      records: [
        {
          host: "_xmpp-server._tcp",
          ttl: 3600,
          rdata: "5 0 5269 xmpp-server.l.google.com.",
          type: "SRV",
        },
        {
          host: "_xmpp-server._tcp",
          ttl: 3600,
          rdata: "20 0 5269 alt1.xmpp-server.l.google.com.",
          type: "SRV",
        },
        {
          host: "_xmpp-server._tcp",
          ttl: 3600,
          rdata: "20 0 5269 alt2.xmpp-server.l.google.com.",
          type: "SRV",
        },
        {
          host: "_xmpp-server._tcp",
          ttl: 3600,
          rdata: "20 0 5269 alt3.xmpp-server.l.google.com.",
          type: "SRV",
        },
        {
          host: "_xmpp-server._tcp",
          ttl: 3600,
          rdata: "20 0 5269 alt4.xmpp-server.l.google.com.",
          type: "SRV",
        },
      ],
    },
    {
      label: "Microsoft Lync Online",
      icon: "ico-lynconline.png",
      records: [
        {
          host: "_sip._tls",
          ttl: 3600,
          rdata: "100 1 443 sipdir.online.lync.com.",
          type: "SRV",
        },
        {
          host: "_sipfederationtls._tcp",
          ttl: 3600,
          rdata: "100 1 5061 sipfed.online.lync.com.",
          type: "SRV",
        },
      ],
    },
  ],

  TXT: [
    {
      label: "GleSYS (SPF)",
      icon: "ico-glesys.png",
      records: [
        {
          host: "@",
          ttl: 3600,
          rdata: '"v=spf1 include:_mail.glesys.com -all"',
          type: "TXT",
        },
      ],
    },
    {
      label: "Gmail/Google Apps",
      icon: "ico-google.png",
      records: [
        {
          host: "@",
          ttl: 3600,
          rdata: '"v=spf1 include:_spf.google.com ~all"',
          type: "TXT",
        },
      ],
    },
  ],
};

export default TEMPLATES;
