import { template as template_5bba9d1f4bf24c8aa48cca1d43211c64 } from "@ember/template-compiler";
import { service } from "@ember/service";
import { action } from "@ember/object";
import Component from "@glimmer/component";
import SelectMenu from "glesys-controlpanel/components/select/menu";
import SelectOption from "glesys-controlpanel/components/select/option";
import IconsFaq from "glesys-controlpanel/components/icons/faq";
import { t } from "ember-intl";
export default class HelpDropdown extends Component {
    @service
    help;
    @service
    modal;
    @service
    metrics;
    @service
    intl;
    get topics() {
        let topics = this.help.topicsForCurrentRoute;
        return topics.length === 0 ? [] : [
            {
                groupName: this.intl.t("component.help-dropdown.header"),
                items: topics
            }
        ];
    }
    get disabled() {
        return this.topics.length === 0;
    }
    @action
    showTopic(topic) {
        if (!topic) {
            return;
        }
        this.metrics.trackEvent("GoogleAnalytics", {
            category: "open-help",
            action: topic.key,
            label: topic.title
        });
        this.modal.open("modals/help-topic", {
            topic
        });
    }
    static{
        template_5bba9d1f4bf24c8aa48cca1d43211c64(`
    <SelectMenu
      @chevron={{false}}
      data-test-help-dropdown
      data-test-help-dropdown-disabled="{{this.disabled}}"
      ...attributes
      as |Menu|
    >
      <Menu.Header>
        <IconsFaq class="w-6 {{if this.disabled 'opacity-75' ''}}" />
      </Menu.Header>
      <Menu.Dropdown @prefersPosition="right" @items={{this.topics}} @key="title" @onSelect={{this.showTopic}}>
        <SelectOption class="text-sm text-gray-darker hover:text-black border-t border-gray-lighter">
          <div class="leading-normal">
            <a target="_blank" rel="noopener noreferrer" href="https://glesys.se/support" class="text-gray-dark mb-1">
              {{t "component.help-dropdown.not-available"}}
            </a>
          </div>
        </SelectOption>
      </Menu.Dropdown>
    </SelectMenu>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
