import ApplicationSerializer from "./application";

export default class EmailDomainSerializer extends ApplicationSerializer {
  usesProxy = true;

  primaryKey = "domainname";

  normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
    let normalizedPayload = {
      domains: payload.overview.domains.map((domain) => {
        domain.project = payload.debug.input.project;
        return domain;
      }),
      meta: payload.overview.meta,
    };

    return super.normalizeArrayResponse(store, primaryModelClass, normalizedPayload, id, requestType);
  }

  normalize(modelClass, resourceHash, prop) {
    // The names clashes with the relationships
    resourceHash.accountsCount = resourceHash.accounts;
    resourceHash.aliasesCount = resourceHash.aliases;
    delete resourceHash.accounts;
    delete resourceHash.aliases;

    resourceHash.links = {
      accounts: `/io.php/email/list?domainname=${resourceHash.domainname}`,
      aliases: `/io.php/email/list?domainname=${resourceHash.domainname}`,
    };

    return super.normalize(modelClass, resourceHash, prop);
  }
}
