import { template as template_5cfce028a0664df7831744dc8c486c4c } from "@ember/template-compiler";
import GButton from "./";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsPen from "../icons/pen";
const isNotCompact = (display)=>display !== "compact";
export default template_5cfce028a0664df7831744dc8c486c4c(`
  <GButton
    @type={{@type}}
    @color="outline"
    @disabled={{@disabled}}
    @loading={{@loading}}
    @display={{if @display @display "default"}}
    {{mergeClasses}}
    ...attributes
  >
    <div class="flex items-center">
      <IconsPen class="w-3 mr-2 {{if (isNotCompact @display) '-ml-1'}} -mt-px text-powder-blue" />
      <span>{{yield}}</span>
    </div>
  </GButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
