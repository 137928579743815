/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { Task, task } from "ember-concurrency";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf, shape } from "prop-types";
import ServerModel from "glesys-controlpanel/models/server";
import { stringable } from "glesys-controlpanel/utils/prop-types";

@forbidExtraArgs
export default class ModalsKvmNetworkAdapterCreate extends Component {
  @service modal;

  @arg(
    shape({
      name: stringable,
      server: instanceOf(ServerModel),
      task: instanceOf(Task),
    }).isRequired,
  )
  params;

  @tracked name = this.params.name;
  @tracked bandwidth = 1;
  @tracked segment;

  get sizes() {
    return [1];
  }

  onFormChange = ({ name }) => (this.name = name);

  @action
  onBandwidthChange(value) {
    this.bandwidth = value;
  }

  @action
  onSegmentChange(segment) {
    this.segment = segment;
  }

  loadSegments = task(async () => {
    let privateNetworks = await this.params.server.project.privateNetworks;

    let segmentsGroupedByPrivateNetworks = await Promise.all(
      privateNetworks.map(async (privateNetwork) => ({
        groupName: `${privateNetwork.name} (${privateNetwork.id})`,
        items: (await privateNetwork.segments).filter(
          (segment) => segment.platform === "kvm" && this.params.server.datacenter === segment.dataCenter.name,
        ),
      })),
    );

    return segmentsGroupedByPrivateNetworks.filter((privateNetwork) => privateNetwork.items.length > 0);
  });

  submitTask = task(async () => {
    let name = this.name;
    let bandwidth = this.bandwidth * 1000;
    let segment = this.segment;

    await this.params.task.perform({ bandwidth, name, segment });

    this.modal.close();
  });
}
