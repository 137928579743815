import { template as template_2e8a61fba02346e5bfb22a4df7ae3f98 } from "@ember/template-compiler";
import Pill from "glesys-controlpanel/components/pill";
import PillError from "glesys-controlpanel/components/pill/error";
import PillInfo from "glesys-controlpanel/components/pill/info";
import PillWarning from "glesys-controlpanel/components/pill/warning";
import { and, eq, not } from "ember-truth-helpers";
import { t } from "ember-intl";
export default template_2e8a61fba02346e5bfb22a4df7ae3f98(`
  <div ...attributes>
    {{! template-lint-disable no-bare-strings }}
    {{#if (eq @domain.state "glesys-ok")}}
      <Pill @text="GleSYS" data-test-dns-domain-pill-ok />
    {{/if}}
    {{#if (eq @domain.state "glesys-expired")}}
      <PillError @text="GleSYS - {{t 'dns.domain.state.expired'}}" data-test-dns-domain-pill-expired />
    {{/if}}
    {{#if (and (not @domain.isAutoRenewalActive) (eq @domain.state "glesys-expiring"))}}
      <PillWarning
        @text="GleSYS - {{t 'dns.domain.state.expiring' date=@domain.registrarinfo.expire}}"
        data-test-dns-domain-pill-expiring
      />
    {{/if}}
    {{#if (and @domain.isAutoRenewalActive (eq @domain.state "glesys-expiring"))}}
      <PillInfo
        @text="GleSYS - {{t 'dns.domain.state.renews' date=@domain.renewalDate}}"
        data-test-dns-domain-pill-renewing
      />
    {{/if}}
    {{#if (eq @domain.state "glesys-waiting")}}
      <Pill
        @text="GleSYS - {{t 'dns.domain.state.waiting'}}"
        data-test-dns-domain-pill-waiting
        data-test-state-waiting
      />
    {{/if}}
    {{#if (eq @domain.state "glesys-failed")}}
      <PillError @text="GleSYS - {{t 'dns.domain.state.failed'}}" data-test-dns-domain-pill-failed />
    {{/if}}
    {{#if (eq @domain.state "glesys-unknown")}}
      <Pill @text="GleSYS" data-test-dns-domain-pill-unknown />
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
