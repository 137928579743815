import { template as template_1a62b21afb2a4fb684a8f283b89df994 } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
export default template_1a62b21afb2a4fb684a8f283b89df994(`
  <div
    class="flex-col flex items-center justify-center text-center h-36 border border-dashed rounded border-gray-light"
    data-test-box-empty-state
    {{mergeClasses}}
    ...attributes
  >
    <h3 class="text-base text-black mb-2">
      {{@title}}
    </h3>
    <p class="text-sm text-gray-dark leading-normal mb-2">
      {{@description}}
    </p>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
