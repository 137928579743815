import { template as template_76d1697f20f04e6d951044a5f7903af0 } from "@ember/template-compiler";
import GButton from "./";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsUpload from "../icons/upload";
const isNotCompact = (display)=>display !== "compact";
export default template_76d1697f20f04e6d951044a5f7903af0(`
  <GButton
    @type={{@type}}
    @color="outline"
    @disabled={{@disabled}}
    @loading={{@loading}}
    @display={{if @display @display "default"}}
    {{mergeClasses}}
    ...attributes
  >
    <div class="flex items-center">
      <IconsUpload class="w-4 mr-2.5 {{if (isNotCompact @display) '-ml-0.5'}} -mt-px text-powder-blue" />
      <span>
        {{yield}}
      </span>
    </div>
  </GButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
