import { template as template_a1a3fcd4e3574154bb9bb0d1c358974d } from "@ember/template-compiler";
import { LinkTo } from "@ember/routing";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
export default template_a1a3fcd4e3574154bb9bb0d1c358974d(`
  <div class="border-b border-gray-light flex" {{mergeClasses}} ...attributes>
    {{#each @tabs as |tab|}}
      <LinkTo
        @activeClass="!text-black border-b border-black"
        @route={{tab.route}}
        class="p-3 text-base text-gray hover:text-gray-dark mb-[-1px]"
        data-test-tab="{{tab.route}}"
      >
        {{tab.name}}
      </LinkTo>
    {{/each}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
