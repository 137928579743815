import { template as template_261ca1c6b0da4b43af65ecae4ad029e9 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import { on } from "@ember/modifier";
export default class extends Component {
    get type() {
        return this.args.type || "button";
    }
    get disabled() {
        return this.args.disabled || false;
    }
    @action
    onClick() {
        this.args.onClick?.();
    }
    static{
        template_261ca1c6b0da4b43af65ecae4ad029e9(`
    <button
      class="appearance-none inline-flex items-center justify-center leading-none"
      disabled={{this.disabled}}
      type={{this.type}}
      {{on "click" this.onClick}}
      {{mergeClasses}}
      ...attributes
    >
      {{yield}}
    </button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
