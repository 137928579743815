import { getOrSetUniversal } from "./-private.js";
const RuntimeConfig = getOrSetUniversal('WarpDriveRuntimeConfig', {
  debug: {}
});
const settings = globalThis.sessionStorage?.getItem('WarpDriveRuntimeConfig');
if (settings) {
  Object.assign(RuntimeConfig, JSON.parse(settings));
}
function getRuntimeConfig() {
  return RuntimeConfig;
}

/**
 * Upserts the specified logging configuration into the runtime
 * config.
 *
 * globalThis.setWarpDriveLogging({ LOG_PAYLOADS: true } });
 *
 * @typedoc
 */
function setLogging(config) {
  Object.assign(RuntimeConfig.debug, config);
  globalThis.sessionStorage?.setItem('WarpDriveRuntimeConfig', JSON.stringify(RuntimeConfig));
}
export { getRuntimeConfig, setLogging };