import Route from "@ember/routing/route";
import { restartableTask, enqueueTask } from "ember-concurrency";
import { service } from "@ember/service";
import RSVP from "rsvp";

export default class EmailOverviewRoute extends Route {
  @service ajax;
  @service store;

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  queryParams = {
    page: {
      refreshModel: true,
    },
    filter: {
      refreshModel: true,
    },
  };

  model({ page, filter }) {
    let { project } = this.modelFor("organization");

    return RSVP.hash({
      project,
      dnsDomains: project.get("dnsDomains"),
      pricelist: this.ajax.request(`/io.php/domain/pricelist?context=${project.id}`),
      domainsTaskResult: this.fetchDomainsTask.perform(project, page, filter),
      quotaTaskResult: this.fetchQuotaTask.perform(project),
    });
  }

  resetController(controller) {
    controller.setProperties({
      page: 1,
      filter: "",
    });
  }

  fetchDomainsTask = restartableTask(async (project, page, filter) => {
    return this.store.query("email-domain", { project: project.get("id"), page, filter }).then((domains) => {
      domains.forEach((domain) => this.fetchDomainMailServerTask.perform(domain));
      return domains;
    });
  });

  fetchQuotaTask = restartableTask(async (project) => {
    return project.get("emailQuota");
  });

  fetchDomainMailServerTask = enqueueTask({ maxConcurrency: 3 }, async (domain) => {
    await domain.fetchMailServer();
  });
}
