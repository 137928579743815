/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf, arrayOf } from "prop-types";
import server from "glesys-controlpanel/models/server";
import privateNetwork from "glesys-controlpanel/models/private-network";
import incrementName from "glesys-controlpanel/helpers/increment-name";
import { tracked } from "@glimmer/tracking";
import { task } from "ember-concurrency";

@forbidExtraArgs
export default class NetworkAdapters extends Component {
  @arg(instanceOf(server)) server;
  @arg(arrayOf(instanceOf(privateNetwork))) privateNetworks;
  @tracked sortedNetworkAdapters;

  loadData = task(async () => {
    let networkAdapters = await this.server.networkAdapters;
    let ips = await this.server.ips;

    let sortedIps = ips
      ?.slice()
      .sort((a, b) => a.version - b.version || a.address.replaceAll(/[.:]/g, "") - b.address.replaceAll(/[.:]/g, ""));

    this.sortedNetworkAdapters = networkAdapters
      ?.map((adapter) => {
        adapter.bandwidthInGbps = adapter.bandwidth / 1000;
        return adapter;
      })
      .slice()
      .sort((a, b) => a.name?.localeCompare(b.name));

    await Promise.all(this.privateNetworks.map((privateNetwork) => privateNetwork.segments));

    return { sortedNetworkAdapters: this.sortedNetworkAdapters, sortedIps };
  });

  get defaultName() {
    return incrementName(
      this.sortedNetworkAdapters.map((item) => item.name),
      "Network adapter",
    );
  }
}
