import { template as template_6d1584651ccc4614be6393e841c5060c } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { array, bool, number, object, oneOf, oneOfType, string } from "prop-types";
import { modifier } from "ember-modifier";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import { and } from "ember-truth-helpers";
import zoomPlugin from "chartjs-plugin-zoom";
import annotationPlugin from "chartjs-plugin-annotation";
import { Chart as ChartJS, _adapters, registerables } from "chart.js";
import dajysAdapter from "./chartjs-adapter-dayjs-3";
ChartJS.register(...registerables);
ChartJS.register(zoomPlugin);
ChartJS.register(annotationPlugin);
_adapters._date.override(dajysAdapter);
export default @forbidExtraArgs
class Chart extends Component {
    @arg(oneOf[("line", "bar", "radar", "polarArea", "pie", "doughnut")])
    type = "line";
    @arg(oneOfType([
        array,
        object
    ]).isRequired)
    data = [];
    @arg(object)
    options;
    @arg(string)
    unit = "";
    @arg(number)
    max;
    @arg(bool)
    zoomable = false;
    @arg(bool)
    panable = false;
    chart;
    get chartPlugins() {
        let zoom = {
            pan: {
                enabled: this.panable
            },
            zoom: {
                wheel: {
                    enabled: this.zoomable
                },
                pinch: {
                    enabled: this.zoomable
                },
                drag: {
                    enabled: false
                }
            }
        };
        return {
            zoom,
            legend: {
                display: false
            },
            tooltip: {
                mode: "nearest",
                axis: "x",
                intersect: false,
                bodyFont: {
                    family: "Averta"
                },
                displayColors: this.data?.datasets?.length > 1,
                callbacks: {
                    title: (context)=>context[0]?.label,
                    label: (context)=>`${context.dataset.label || ""} ${context.formattedValue} ${this.unit}`.trim()
                }
            },
            ...this.options?.plugins
        };
    }
    get chartOptions() {
        return {
            plugins: this.chartPlugins,
            responsive: true,
            maintainAspectRatio: true,
            elements: {
                point: {
                    radius: 0
                }
            },
            datasets: {
                line: {
                    borderColor: "#6EB3F0",
                    backgroundColor: "#6EB3F0",
                    fill: false,
                    borderWidth: 2
                }
            },
            scales: {
                y: {
                    grid: {
                        ...this.options?.scales?.y?.grid
                    },
                    suggestedMax: this.max,
                    suggestedMin: 0,
                    ticks: {
                        maxTicksLimit: 10,
                        stepSize: 0.01,
                        font: {
                            family: "Averta"
                        },
                        padding: 20
                    },
                    bounds: "data"
                },
                x: {
                    grid: {
                        display: false,
                        ...this.options?.scales?.x?.grid
                    },
                    stacked: this.options?.scales?.x?.stacked || false,
                    type: this.options?.scales?.x?.type || "time",
                    time: {
                        unit: "hour",
                        displayFormats: {
                            hour: "HH:mm",
                            day: "YYYY-MM-DD"
                        },
                        tooltipFormat: "YYYY-MM-DD HH:mm",
                        ...this.options?.scales?.x?.time
                    }
                }
            }
        };
    }
    willDestroy() {
        super.willDestroy(...arguments);
        this.chart?.destroy();
    }
    setupChart = modifier((element)=>{
        if (!this.chart) {
            this.chart = new ChartJS(element, {
                type: this.type
            });
        }
        this.chart.data = this.data;
        this.chart.options = this.chartOptions;
        this.chart.plugins = this.chartPlugins;
        this.chart.update();
    });
    static{
        template_6d1584651ccc4614be6393e841c5060c(`
    <div class="relative" {{mergeClasses}} ...attributes>
      <canvas
        class="{{if (and this.zoomable this.panable) 'cursor-move'}} -mt-5 -mx-4"
        data-test-chart
        {{this.setupChart}}
      >
      </canvas>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
