import { template as template_d4de8d1fffc04e69b09365f89888588f } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import { LinkTo } from "@ember/routing";
import IconsChevronLeft from "glesys-controlpanel/components/icons/chevron-left";
export default template_d4de8d1fffc04e69b09365f89888588f(`
  <LinkTo
    @route={{@route}}
    class="flex items-center leading-none text-gray hover:text-gray-darker stroke-gray hover:stroke-gray-darker"
    data-test-back-to
    {{mergeClasses}}
    ...attributes
  >
    <IconsChevronLeft class="size-5 stroke-current" data-test-back-to-chevron />
    {{yield}}
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
