import { template as template_d56d5c8313364ca2b0e07680f835ce38 } from "@ember/template-compiler";
import gButtonColor from "glesys-controlpanel/helpers/g-button-color";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import Content from "./content";
import IconsExternalLink from "../icons/external-link";
const isCompact = (display)=>display === "compact";
const color = (color)=>color || "outline";
export default template_d56d5c8313364ca2b0e07680f835ce38(`
  <a
    href="#"
    class="flex items-center relative rounded text-sm
      {{if (isCompact @display) 'px-2 py-1' 'px-4 py-2'}}
      {{if @disabled 'cursor-not-allowed pointer-events-none opacity-60' 'cursor-pointer'}}
      {{gButtonColor (color @color)}}
      transition-all duration-200"
    target="{{if @external '_blank'}}"
    rel="{{if @external 'noopener noreferrer'}}"
    {{mergeClasses}}
    ...attributes
  >
    <Content @loading={{@loading}} @color={{color @color}}>
      <div class="flex items-center">
        {{yield}}
        {{#if @external}}
          <IconsExternalLink class="w-4 ml-2" />
        {{/if}}
      </div>
    </Content>
  </a>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
