import { service } from "@ember/service";
import { isEmpty } from "@ember/utils";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { dropTask, task, timeout } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import ENV from "glesys-controlpanel/config/environment";
import dayjs from "dayjs";
import { allRouteParams } from "glesys-controlpanel/utils/url";

export default class LoginController extends Controller {
  queryParams = ["status"];

  @service intl;
  @service session;
  @service router;
  @service ajax;

  username = null;
  password = null;
  otp = null;
  verificationCode = null;

  @tracked error = null;
  @tracked status = null;
  @tracked serviceInfo = [];
  @tracked displayOneTimePassword = false;
  @tracked displayVerificationCode = false;

  get statusMessage() {
    if (this.displayVerificationCode) {
      return this.intl.t("login.status.verification-code");
    }

    switch (this.status) {
      case "logout":
        return this.intl.t("login.status.logged-out");
      case "logout.timeout":
        return this.intl.t("login.status.logged-out-time-out");
      case "timeout":
        return this.intl.t("login.status.timed-out");
      case "reset":
        return this.intl.t("login.status.reset");
      case "updated-to-external-2fa":
        return this.intl.t("login.status.2fa-updated");
      case "revert-to-email-based":
        return this.intl.t("login.status.revert-to-email-based-2fa");
      case "inactive":
        return this.intl.t("login.status.logged-out-inactive");
      default:
        return "";
    }
  }

  get successMessage() {
    switch (this.status) {
      case "confirmed":
        return this.intl.t("login.status.email-confirmed");
      default:
        return "";
    }
  }

  get errorMessage() {
    switch (this.status) {
      case "confirmation-failed":
        return this.intl.t("login.status.confirmation-failed");
      default:
        return this.error;
    }
  }

  get hasActiveMaintenance() {
    return this.serviceInfo["scheduled_maintenances"]?.some((maintenance) =>
      dayjs().isAfter(dayjs(maintenance.scheduled_for).subtract(1, "hour")),
    );
  }

  get hasActiveIncident() {
    return this.serviceInfo["incidents"]?.length > 0;
  }

  @action
  onFormChange({ username, password, otp, verificationCode }) {
    this.username = username ?? this.username;
    this.password = password ?? this.password;
    this.otp = otp ?? this.otp;
    this.verificationCode = verificationCode ?? this.verificationCode;
  }

  @action
  resetLogin() {
    this.resetForm();
  }

  setupController() {
    this.fetchStatusPage.perform();
  }

  resetController() {
    this.fetchStatusPage.cancelAll();
    this.scheduleStatusPageFetch.cancelAll();
    this.resetForm();
  }

  resetForm() {
    this.status = null;
    this.error = null;
    this.displayOneTimePassword = false;
    this.displayVerificationCode = false;
    this.username = null;
    this.password = null;
    this.otp = null;
    this.verificationCode = null;
  }

  loginTask = dropTask(async () => {
    if (isEmpty(this.username) || isEmpty(this.password)) {
      return;
    }

    this.status = null;
    this.error = null;

    try {
      await this.session.open.perform(this.username, this.password, this.otp, this.verificationCode);
      let transition = this.session.attemptedTransition;

      if (!transition) {
        return await this.router.transitionTo("dashboard").followRedirects();
      }

      let routeParams = allRouteParams(transition.to);

      await this.router
        .transitionTo(transition.to.name, ...routeParams, { queryParams: transition.to.queryParams })
        .followRedirects();
    } catch (error) {
      if (error.response?.status !== 401) {
        throw error;
      }

      let payload = error?.content;
      if (payload.otp_required === true) {
        this.displayOneTimePassword = true;
      } else if (payload.verification_code_required === true) {
        this.displayVerificationCode = true;
      } else {
        this.error = payload.error?.message ?? payload.error;
      }
    }
  });

  fetchStatusPage = task(async () => {
    this.serviceInfo = await this.ajax.request(`https://wf8tcthkr1hd.statuspage.io/api/v2/summary.json`);

    if (ENV.environment !== "test") {
      this.scheduleStatusPageFetch.perform();
    }
  });

  scheduleStatusPageFetch = task(async () => {
    await timeout(1000 * 60);
    this.fetchStatusPage.perform();
  });
}
