import { template as template_229d137103574dd7b0e4b416f4964cad } from "@ember/template-compiler";
import styles from "./styles.css";
export default template_229d137103574dd7b0e4b416f4964cad(`
  <div data-test-button-group ...attributes>
    <div class="{{styles.root}} inline-flex items-stretch rounded border border-gray-light divide-x divide-gray-light">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
