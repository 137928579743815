import Controller from "@ember/controller";
import { task, timeout, restartableTask } from "ember-concurrency";
import { service } from "@ember/service";
import ENV from "glesys-controlpanel/config/environment";

export default class extends Controller {
  @service store;
  @service ajax;

  get server() {
    return this.model.server;
  }

  get type() {
    return this.model.type;
  }

  get title() {
    switch (this.type) {
      case "gitlab":
        return "gitlab.creating.title";
      case "jitsi":
        return "jitsi.creating.title";
      case "plesk":
        return "plesk.creating.title";
      case "wordpress":
        return "wordpress.creating.title";
      default:
        return "appliance.creating.title";
    }
  }

  get url() {
    switch (this.type) {
      case "wordpress":
        return this.server.hostname.replace(/^www\./, "");
      default:
        return this.server.hostname;
    }
  }

  get docs() {
    switch (this.type) {
      case "gitlab":
        return "gitlab.creating.docs";
      case "jitsi":
        return "jitsi.creating.docs";
      case "plesk":
        return "plesk.creating.docs";
      case "wordpress":
        return "wordpress.creating.docs";
      default:
        return "appliance.creating.docs";
    }
  }

  pollStatus = restartableTask(async () => {
    await this.pollServerCreatingStatus.perform();
    await this.pollProvisioningStatus.perform();
  });

  pollServerCreatingStatus = task(async () => {
    let serverAdapter = this.store.adapterFor("server");

    while (true) {
      await serverAdapter.get("fetchStatusTask").perform(this.server);
      let { isRunning, isLocked } = this.server.belongsTo("status").value();
      if (isRunning && !isLocked) {
        return;
      }
      await timeout(5 * 1000);
    }
  });

  pollProvisioningStatus = task(async () => {
    // istanbul ignore if
    if (ENV.environment !== "test") {
      await timeout(45 * 1000);
    }

    let { hostname } = this.server;

    while (true) {
      try {
        // Hacky or not, here I come:
        // We can't ping an URL due to CORS,
        // but { mode: "no-cors" } will let us get an opaque response back if the URL is reachable
        // and fail when the URL is unreachable.
        let response = await fetch(`https://${hostname}`, { mode: "no-cors" });

        // istanbul ignore else
        if (response.ok || response.type === "opaque") {
          return;
        }
      } catch (error) {
        if (error.message !== "Failed to fetch") {
          throw error;
        }
      }

      // istanbul ignore if
      if (ENV.environment !== "test") {
        await timeout(5 * 1000);
      }
    }
  });
}
