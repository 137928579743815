import Service, { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
export default class CurrentService extends Service {
  @service store;
  @service session;
  @service storage;

  @tracked _organization;
  @tracked _project;
  @tracked utm;

  get latestOrganizationFromLocalStorage() {
    return this.storage.get("glesys:latest-organization") || null;
  }

  get latestProjectFromLocalStorage() {
    return this.storage.get("glesys:latest-project") || null;
  }

  get organization() {
    return this._organization;
  }

  set organization(organization) {
    this._organization = organization;
    if (this.project?.organization !== this.organization) {
      this.project = null;
    }
    try {
      if (this.organization?.id) {
        this.storage.set("glesys:latest-organization", this.organization?.id);
      }
    } catch {
      // Fail silently
    }
  }

  get project() {
    return this._project;
  }

  set project(project) {
    this._project = project;
    try {
      if (this.project?.id) {
        this.organization = this.project.organization;
        this.storage.set("glesys:latest-project", this.project?.id);
      }
    } catch {
      // Fail silently
    }
  }
}
