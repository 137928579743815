/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";

import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { object } from "prop-types";

import { service } from "@ember/service";

@forbidExtraArgs
export default class Toggle extends Component {
  @service intl;

  @arg(object) leftItem = {
    label: {
      active: this.intl.t("component.toggle.default.left.active"),
      passive: this.intl.t("component.toggle.default.left.passive"),
    },
    value: "off",
    action: () => {},
  };

  @arg(object) rightItem = {
    label: {
      active: this.intl.t("component.toggle.default.right.active"),
      passive: this.intl.t("component.toggle.default.right.passive"),
    },
    value: "on",
    action: () => {},
  };

  @arg selectedItem;

  @tracked currentItem = this.selectedItem;

  @action
  setSelected(item) {
    this.currentItem = item;
  }

  @action
  setValue() {
    if (this.currentItem.value == this.leftItem.value) {
      this.currentItem = this.rightItem;
    } else {
      this.currentItem = this.leftItem;
    }
    this.currentItem.action();
  }
}
