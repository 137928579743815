import ApplicationAdapter from "./application";

export default class SshKeyAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return `${this.baseUri()}/sshkey/add`;
  }

  urlForDeleteRecord(id) {
    return `${this.baseUri()}/sshkey/remove?sshkeyids=${id}`;
  }
}
