import { NEGATIVE_ZERO, ZERO } from '../constants';
import { invariant, repeat } from '../utils';
import { GetUnsignedRoundingMode } from './GetUnsignedRoundingMode';
import { ToRawFixed } from './ToRawFixed';
import { ToRawPrecision } from './ToRawPrecision';
/**
 * https://tc39.es/ecma402/#sec-formatnumberstring
 */
export function FormatNumericToString(intlObject, _x) {
    var x = _x;
    var sign;
    // -0
    if (x.isZero() && x.isNegative()) {
        sign = 'negative';
        x = ZERO;
    }
    else {
        invariant(x.isFinite(), 'NumberFormatDigitInternalSlots value is not finite');
        if (x.lessThan(0)) {
            sign = 'negative';
        }
        else {
            sign = 'positive';
        }
        if (sign === 'negative') {
            x = x.negated();
        }
    }
    var result;
    var roundingType = intlObject.roundingType;
    var unsignedRoundingMode = GetUnsignedRoundingMode(intlObject.roundingMode, sign === 'negative');
    switch (roundingType) {
        case 'significantDigits':
            result = ToRawPrecision(x, intlObject.minimumSignificantDigits, intlObject.maximumSignificantDigits, unsignedRoundingMode);
            break;
        case 'fractionDigits':
            result = ToRawFixed(x, intlObject.minimumFractionDigits, intlObject.maximumFractionDigits, intlObject.roundingIncrement, unsignedRoundingMode);
            break;
        default:
            var sResult = ToRawPrecision(x, intlObject.minimumSignificantDigits, intlObject.maximumSignificantDigits, unsignedRoundingMode);
            var fResult = ToRawFixed(x, intlObject.minimumFractionDigits, intlObject.maximumFractionDigits, intlObject.roundingIncrement, unsignedRoundingMode);
            if (intlObject.roundingType === 'morePrecision') {
                if (sResult.roundingMagnitude <= fResult.roundingMagnitude) {
                    result = sResult;
                }
                else {
                    result = fResult;
                }
            }
            else {
                invariant(intlObject.roundingType === 'lessPrecision', 'Invalid roundingType');
                if (sResult.roundingMagnitude <= fResult.roundingMagnitude) {
                    result = fResult;
                }
                else {
                    result = sResult;
                }
            }
            break;
    }
    x = result.roundedNumber;
    var string = result.formattedString;
    if (intlObject.trailingZeroDisplay === 'stripIfInteger' && x.isInteger()) {
        var i = string.indexOf('.');
        if (i > -1) {
            string = string.slice(0, i);
        }
    }
    var int = result.integerDigitsCount;
    var minInteger = intlObject.minimumIntegerDigits;
    if (int < minInteger) {
        var forwardZeros = repeat('0', minInteger - int);
        string = forwardZeros + string;
    }
    if (sign === 'negative') {
        if (x.isZero()) {
            x = NEGATIVE_ZERO;
        }
        else {
            x = x.negated();
        }
    }
    return { roundedNumber: x, formattedString: string };
}
