import Route from "@ember/routing/route";

export default class SupportChallengeRoute extends Route {
  setupController(controller, model) {
    super.setupController(controller, model);
    controller.resetProperties();
  }

  resetController(controller) {
    super.resetController(...arguments);
    controller.fetchSupportChallengeStatus.cancelAll();
  }
}
