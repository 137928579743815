/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import { guidFor } from "@ember/object/internals";
import styles from "./styles.css";

export default class Slider extends Component {
  get id() {
    return guidFor(this);
  }

  styles = styles;

  get value() {
    return this.args.value || 0;
  }

  get disabled() {
    return this.args.disabled || false;
  }

  get min() {
    return this.args.min || 0;
  }

  get max() {
    return this.args.max || 100;
  }

  get step() {
    return this.args.step || 1;
  }

  get showValue() {
    return this.args.showValue || false;
  }

  get onChange() {
    return this.args.onChange;
  }

  get style() {
    let total = this.max - this.min;
    let value = Math.ceil(((this.value - this.min) / total) * 100);
    return htmlSafe(`background-size: ${value}% 100%`);
  }

  @action
  handleChange(event) {
    event.target?.value && this.onChange?.(event.target.value);
  }
}
