import { template as template_d057a201f3db4fd595737f068da270e0 } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
export default template_d057a201f3db4fd595737f068da270e0(`
  <svg class="stroke-gray-darker" viewBox="0 0 17 16" fill="none" {{mergeClasses}} ...attributes>
    <path
      d="M3 4.75C3 3.64543 3.89543 2.75 5 2.75H11.5C12.6046 2.75 13.5 3.64543 13.5 4.75V11.25C13.5 12.3546 12.6046 13.25 11.5 13.25H5C3.89543 13.25 3 12.3546 3 11.25V4.75Z"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M6 0.75V2.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.5 5.75H14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M6 13.75V15.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2.5 5.75H1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.5 0.75V2.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M15.5 10.25H14" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M10.5 13.75V15.25" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M2.5 10.25H1" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
