/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { func, instanceOf, oneOfType } from "prop-types";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import AvailableIpModel from "glesys-controlpanel/models/available-ip";
import ReservedIpModel from "glesys-controlpanel/models/reserved-ip";

@forbidExtraArgs
export default class SelectableIpItem extends Component {
  @arg(oneOfType([instanceOf(AvailableIpModel), instanceOf(ReservedIpModel)])) ip;
  @arg(func.isRequired) onSelect;
  @arg(func.isRequired) onDeselect;

  @tracked selected = false;

  @action
  onClick() {
    this.selected = !this.selected;
    if (this.selected) {
      this.onSelect();
    } else {
      this.onDeselect();
    }
  }
}
