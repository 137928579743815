import { getOwner } from '@ember/application';
import { deprecate } from '@ember/debug';
import { M as Model, n as normalizeModelName } from "./model-6Exz3e1N.js";
import { macroCondition, getGlobalConfig } from '@embroider/macros';

/*
    In case someone defined a relationship to a mixin, for example:
    ```ts
      class CommentModel extends Model {
        @belongsTo('commentable', { polymorphic: true }) owner;
      }

      let Commentable = Mixin.create({
        @hasMany('comment') comments;
      });
    ```
    we want to look up a Commentable class which has all the necessary
    relationship meta data. Thus, we look up the mixin and create a mock
    Model, so we can access the relationship CPs of the mixin (`comments`)
    in this case
  */
function modelForMixin(store, normalizedModelName) {
  const owner = getOwner(store);
  const MaybeMixin = owner.factoryFor(`mixin:${normalizedModelName}`);
  const mixin = MaybeMixin && MaybeMixin.class;
  if (mixin) {
    const ModelForMixin = Model.extend(mixin);
    ModelForMixin.__isMixin = true;
    ModelForMixin.__mixin = mixin;
    //Cache the class as a model
    owner.register(`model:${normalizedModelName}`, ModelForMixin);
  }
  return owner.factoryFor(`model:${normalizedModelName}`);
}
class ModelSchemaProvider {
  constructor(store) {
    this.store = store;
    this._schemas = new Map();
    this._typeMisses = new Set();
  }
  hasTrait(type) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`hasTrait is not available with @ember-data/model's SchemaService`);
      }
    })() : {};
    return false;
  }
  resourceHasTrait(resource, trait) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`resourceHasTrait is not available with @ember-data/model's SchemaService`);
      }
    })() : {};
    return false;
  }
  transformation(field) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`transformation is not available with @ember-data/model's SchemaService`);
      }
    })() : {};
  }
  derivation(field) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`derivation is not available with @ember-data/model's SchemaService`);
      }
    })() : {};
  }
  hashFn(field) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`hashFn is not available with @ember-data/model's SchemaService`);
      }
    })() : {};
  }
  resource(resource) {
    const type = normalizeModelName(resource.type);
    if (!this._schemas.has(type)) {
      this._loadModelSchema(type);
    }
    return this._schemas.get(type).schema;
  }
  registerResources(schemas) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`registerResources is not available with @ember-data/model's SchemaService`);
      }
    })() : {};
  }
  registerResource(schema) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`registerResource is not available with @ember-data/model's SchemaService`);
      }
    })() : {};
  }
  registerTransformation(transform) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`registerTransformation is not available with @ember-data/model's SchemaService`);
      }
    })() : {};
  }
  registerDerivation(derivation) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`registerDerivation is not available with @ember-data/model's SchemaService`);
      }
    })() : {};
  }
  registerHashFn(hashFn) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`registerHashFn is not available with @ember-data/model's SchemaService`);
      }
    })() : {};
  }
  _loadModelSchema(type) {
    const modelClass = this.store.modelFor(type);
    const attributeMap = modelClass.attributes;
    const attributes = Object.create(null);
    attributeMap.forEach((meta, name) => attributes[name] = meta);
    const relationships = modelClass.relationshipsObject || null;
    const fields = new Map();
    for (const attr of Object.values(attributes)) {
      fields.set(attr.name, attr);
    }
    for (const rel of Object.values(relationships)) {
      fields.set(rel.name, rel);
    }
    const schema = {
      legacy: true,
      identity: {
        name: 'id',
        kind: '@id'
      },
      type,
      fields: Array.from(fields.values())
    };
    const internalSchema = {
      schema,
      attributes,
      relationships,
      fields
    };
    this._schemas.set(type, internalSchema);
    return internalSchema;
  }
  fields(resource) {
    const type = normalizeModelName(resource.type);
    if (!this._schemas.has(type)) {
      this._loadModelSchema(type);
    }
    return this._schemas.get(type).fields;
  }
  hasResource(resource) {
    const type = normalizeModelName(resource.type);
    if (this._schemas.has(type)) {
      return true;
    }
    if (this._typeMisses.has(type)) {
      return false;
    }
    const factory = getModelFactory(this.store, type);
    const exists = factory !== null;
    if (!exists) {
      this._typeMisses.add(type);
      return false;
    }
    return true;
  }
}
if (macroCondition(getGlobalConfig().WarpDrive.deprecations.ENABLE_LEGACY_SCHEMA_SERVICE)) {
  ModelSchemaProvider.prototype.doesTypeExist = function (type) {
    deprecate(`Use \`schema.hasResource({ type })\` instead of \`schema.doesTypeExist(type)\``, false, {
      id: 'ember-data:schema-service-updates',
      until: '6.0',
      for: 'ember-data',
      since: {
        available: '4.13',
        enabled: '5.4'
      }
    });
    return this.hasResource({
      type
    });
  };
  ModelSchemaProvider.prototype.attributesDefinitionFor = function (resource) {
    deprecate(`Use \`schema.fields({ type })\` instead of \`schema.attributesDefinitionFor({ type })\``, false, {
      id: 'ember-data:schema-service-updates',
      until: '6.0',
      for: 'ember-data',
      since: {
        available: '4.13',
        enabled: '5.4'
      }
    });
    const type = normalizeModelName(resource.type);
    if (!this._schemas.has(type)) {
      this._loadModelSchema(type);
    }
    return this._schemas.get(type).attributes;
  };
  ModelSchemaProvider.prototype.relationshipsDefinitionFor = function (resource) {
    deprecate(`Use \`schema.fields({ type })\` instead of \`schema.relationshipsDefinitionFor({ type })\``, false, {
      id: 'ember-data:schema-service-updates',
      until: '6.0',
      for: 'ember-data',
      since: {
        available: '4.13',
        enabled: '5.4'
      }
    });
    const type = normalizeModelName(resource.type);
    if (!this._schemas.has(type)) {
      this._loadModelSchema(type);
    }
    return this._schemas.get(type).relationships;
  };
}
function buildSchema(store) {
  return new ModelSchemaProvider(store);
}
function getModelFactory(store, type) {
  if (!store._modelFactoryCache) {
    store._modelFactoryCache = Object.create(null);
  }
  const cache = store._modelFactoryCache;
  let factory = cache[type];
  if (!factory) {
    const owner = getOwner(store);
    factory = owner.factoryFor(`model:${type}`);
    if (!factory) {
      //Support looking up mixins as base types for polymorphic relationships
      factory = modelForMixin(store, type);
    }
    if (!factory) {
      // we don't cache misses in case someone wants to register a missing model
      return null;
    }
    const klass = factory.class;
    if (klass.isModel) {
      const hasOwnModelNameSet = klass.modelName && Object.prototype.hasOwnProperty.call(klass, 'modelName');
      if (!hasOwnModelNameSet) {
        Object.defineProperty(klass, 'modelName', {
          value: type
        });
      }
    }
    cache[type] = factory;
  }
  return factory;
}
export { ModelSchemaProvider as M, buildSchema as b, getModelFactory as g };