import Route from "@ember/routing/route";
import { task } from "ember-concurrency";

export default class ColocationRacksRoute extends Route {
  model() {
    let { project } = this.modelFor("organization");

    return { project, loadData: this.loadData };
  }

  loadData = task(async () => {
    return this.context.project.colocationRacks;
  });
}
