import { template as template_e7d253ada9a647b2b973f17e8a28c12c } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
export default template_e7d253ada9a647b2b973f17e8a28c12c(`
  <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {{mergeClasses}} ...attributes>
    <path
      d="M7.25 4.5V7.75M7.25 4.5V2.5C7.25 2.16848 7.38169 1.85054 7.61612 1.61612C7.85054 1.3817 8.16848 1.25 8.5 1.25C8.83152 1.25 9.14946 1.3817 9.38388 1.61612C9.6183 1.85054 9.75 2.16848 9.75 2.5V7.75M7.25 4.5C7.25 4.16848 7.1183 3.85054 6.88388 3.61612C6.64946 3.3817 6.33152 3.25 6 3.25C5.66848 3.25 5.35054 3.3817 5.11612 3.61612C4.88169 3.85054 4.75 4.16848 4.75 4.5V9.75L3.146 7.827C2.535 7.094 1.418 7.073 0.754999 7.761M0.754999 7.761L2.733 12.631C3.10729 13.5528 3.74809 14.3421 4.57342 14.8977C5.39875 15.4533 6.37107 15.7501 7.366 15.75H11.25C13.46 15.75 14.75 13.96 14.75 11.75V5.5C14.75 5.16848 14.6183 4.85054 14.3839 4.61612C14.1495 4.3817 13.8315 4.25 13.5 4.25C13.1685 4.25 12.8505 4.3817 12.6161 4.61612C12.3817 4.85054 12.25 5.16848 12.25 5.5M0.754999 7.761C0.749999 7.767 0.751999 7.755 0.754999 7.761ZM9.75 5.5V3.5C9.75 3.16848 9.88169 2.85054 10.1161 2.61612C10.3505 2.3817 10.6685 2.25 11 2.25C11.3315 2.25 11.6495 2.3817 11.8839 2.61612C12.1183 2.85054 12.25 3.16848 12.25 3.5V7.75"
      stroke="#364349"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
