import { service } from "@ember/service";
import Controller from "@ember/controller";
/* eslint-disable ember/no-computed-properties-in-native-classes */
import { action, computed } from "@ember/object";
import { dropTask, task } from "ember-concurrency";
import groupBy from "glesys-controlpanel/utils/group-by";
import { guidFor } from "@ember/object/internals";
import { next } from "@ember/runloop";
import { tracked } from "@glimmer/tracking";
import { modifier } from "ember-modifier";

export default class DnsDomainController extends Controller {
  @service ajax;
  @service modal;
  @service notification;
  @service intl;
  @service keyboard;
  @service router;
  @service abilities;
  @service store;

  @tracked records = [];

  get project() {
    return this.model.project;
  }

  get organization() {
    return this.model.organization;
  }

  get projectId() {
    return this.project.id;
  }

  get domain() {
    return this.model.domain;
  }

  get servers() {
    return this.model.servers;
  }

  get prices() {
    return this.model.prices.pricelist;
  }

  get expireDate() {
    return this.domain.registrarinfo.expire;
  }

  get state() {
    return this.domain.state;
  }

  get stateColor() {
    let state = this.state;
    if (state === "glesys-ok") {
      return "green";
    }
    if (state === "glesys-failed") {
      return "red";
    }
    return "grey";
  }

  get menuItems() {
    const generateAuthCode = this.domain.isManagedByGlesys
      ? {
          id: "generate.auth-code",
          disabled: !this.organization.isOwner,
          value: this.intl.t("dns.domain.select-menu.generate-auth-code"),
        }
      : null;

    let item = [
      {
        groupName: "",
        items: [
          {
            id: "configure.email",
            value: this.intl.t("button.email"),
          },
          generateAuthCode,
        ].filter((item) => item !== null),
      },
      {
        groupName: this.intl.t("dns.domain.select-menu.title"),
        items: [
          {
            id: "file.export",
            value: this.intl.t("dns.domain.select-menu.export"),
          },
        ],
      },
    ];

    return item;
  }

  // This needs to be computed,
  // otherwise we get weird rerenders where the input loses focus and it scrolls down to the bottom
  // Is there another way to fix it?
  @computed("intl.primaryLocale", "records.[]")
  get sortedRecords() {
    let byType = (a, b) => a.type.localeCompare(b.type);
    let newFirst = (a, b) => b.isNew - a.isNew;
    let byHostname = (a, b) =>
      a.isNotNew && b.isNotNew ? a.host?.localeCompare(b.host, this.intl.primaryLocale, { numeric: true }) : 0;

    return this.records.slice().sort((a, b) => byType(a, b) || byHostname(a, b) || newFirst(a, b));
  }

  get groupedRecords() {
    return groupBy(this.sortedRecords, "type");
  }

  get dirtyRecords() {
    return this.records.filter((record) => record.hasDirtyAttributes || record.isDeleted);
  }

  get hasDirtyRecords() {
    return this.dirtyRecords.length > 0;
  }

  get isDirty() {
    return this.hasDirtyRecords || this.domain.hasDirtyAttributes;
  }

  get canRenew() {
    let { state } = this;
    if (state === "glesys-ok" || state === "glesys-expiring" || this.domain.isRenewFail) {
      return true;
    }
    return false;
  }

  get domainPrice() {
    let prices = this.prices;
    let domainName = this.domain.domainname;
    let tld = domainName.split(".").pop(-1);
    let filteredPrices = prices[tld];
    return filteredPrices;
  }

  get serverArray() {
    let servers = this.servers;
    let array = servers.map((server) => {
      return {
        hostname: server.hostname,
        ipsVersion4: server.ipsVersion4.map((ip) => ip.address),
        ipsVersion6: server.ipsVersion6.map((ip) => ip.address),
      };
    });
    return array;
  }

  registerListeners = modifier(() => {
    this.keyboard.listenFor(
      { ctrlKey: true, code: "KeyS", preventDefault: true, ignoreFormFieldFocus: true },
      this.saveChanges,
    );
    this.keyboard.listenFor(
      { metaKey: true, code: "KeyS", preventDefault: true, ignoreFormFieldFocus: true },
      this.saveChanges,
    );

    return () => {
      this.keyboard.stopListenFor({ ctrlKey: true, code: "KeyS" });
      this.keyboard.stopListenFor({ metaKey: true, code: "KeyS" });
    };
  });

  @action
  onMenuItemSelect(item) {
    switch (item.id) {
      case "configure.email":
        return this.router.transitionTo("email.domain", this.domain.id);
      case "file.export":
        return this.modal.open("modals/export-zone-file", { domain: this.domain });
      case "generate.auth-code":
        return this.modal.open("modals/generate-auth-code", { domain: this.domain });
    }
  }

  onSoaChange = (event) => {
    let { name, value, type } = event.target;

    this.domain[name] = type === "number" ? parseInt(value) : value;
  };

  renewDomain = task(async (numyears) => {
    let url = `/io.php/domain/renew`;
    await this.ajax.post(url, {
      data: {
        domainname: this.domain.id,
        numyears,
      },
    });

    this.send("reloadDetails");
  });

  deleteDomainTask = task(async () => {
    this.domain.deleteRecord();
    await this.domain.save().catch((error) => {
      this.router.transitionTo("dns.domain", this.domain.id);
      throw error;
    });
    await this.router.transitionTo("dns");
  });

  changeNameServers = task(async (nameservers) => {
    let url = `/io.php/domain/changenameservers`;

    let filteredNameServers = Object.fromEntries(Object.entries(nameservers).filter(([, value]) => value));

    await this.ajax.post(url, {
      data: {
        domainname: this.domain.id,
        ...filteredNameServers,
      },
    });

    this.send("reloadDetails");
  });

  toggleAutorenew = dropTask(async () => {
    this.domain.set("registrarinfo.autorenew", this.domain.registrarinfo.autorenew === "yes" ? "no" : "yes");
    await this.ajax.post("/io.php/domain/setautorenew", {
      data: {
        domainname: this.domain.id,
        autorenew: this.domain.registrarinfo.autorenew,
      },
    });
  });

  @action
  addRecord(type) {
    let domain = this.domain;
    let record = this.store.createRecord("dns-record", { type, domain, rdata: type === "MX" ? "10" : "" });
    let id = guidFor(record);
    next(() => {
      document.querySelector(`#dns-record-${id} ._host-input`)?.focus();
    });
  }

  @action
  saveChanges() {
    if (!this.isDirty) {
      return;
    }

    let domain = this.domain;
    let records = this.dirtyRecords;

    if (domain.hasDirtyAttributes) {
      domain.save();
    }
    records.forEach((record) => record.save());
    this.notification.add("success", this.intl.t("dns.updated.title"), this.intl.t("dns.updated.content"));
  }

  @action
  discardChanges() {
    let domain = this.domain;
    let records = this.dirtyRecords;

    domain.rollbackAttributes();
    records.forEach((record) => record.rollbackAttributes());
  }

  @action
  template(template) {
    let domain = this.domain;
    template.records.forEach((record) => {
      this.store.createRecord("dns-record", Object.assign(record, { domain }));
    });
  }

  loadRecords = task(async () => {
    this.records = await this.domain.records;
  });
}
