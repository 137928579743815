import Service, { service } from "@ember/service";

export default class CostService extends Service {
  @service current;

  get currency() {
    return this.current.project.currency;
  }

  get prices() {
    return {
      loadBalancer: { SEK: 243, EUR: 24.28 },
      vpn: { SEK: 60.69, EUR: 6.07 },
      archive: { SEK: 1.21, EUR: 0.11 },
      remoteHands: {
        officeHours: { SEK: 1087, EUR: 108.68 },
        nonOfficeHours: { SEK: 1931, EUR: 193.08 },
      },
      network: { SEK: 304, EUR: 30.34 },
      email: {
        account: { SEK: 11.03, EUR: 1.1 },
        storage: { SEK: 3.31, EUR: 0.33 },
      },
      backup: {
        storage: { SEK: 0.36, EUR: 0.03 },
      },
      ipv4: { SEK: 27.58, EUR: 2.76 },
      ipv6: { SEK: 1.1, EUR: 0.1 },
    };
  }

  forManualBackup(size) {
    return this.formatCost(size * this.prices.backup.storage[this.currency]);
  }

  forNetworkAdapter(bandwidth, server, network) {
    let platformArguments = server?.get("platformArguments");

    let bandwidthOptions = platformArguments?.get("externalBandwidthOptions");

    if (network && !network.get("isPublic")) {
      bandwidthOptions = platformArguments?.get("internalBandwidthOptions");
    }

    let bandwidthOption = bandwidthOptions?.find((option) => option.value === bandwidth);

    return bandwidthOption ? bandwidthOption.basecost + bandwidthOption.licensecost : 0;
  }

  forEmailAccount(quota = 1) {
    return this.formatCost(
      (Math.ceil(quota) - 1) * this.prices.email.storage[this.currency] + this.prices.email.account[this.currency],
    );
  }

  forNetwork() {
    return this.formatCost(this.prices.network[this.currency]);
  }

  forRemoteHands() {
    return {
      officeHours: this.formatCost(this.prices.remoteHands.officeHours[this.currency]),
      nonOfficeHours: this.formatCost(this.prices.remoteHands.nonOfficeHours[this.currency]),
    };
  }

  forArchive(size) {
    return this.formatCost(size * this.prices.archive[this.currency]);
  }

  forVpnUser() {
    return this.formatCost(this.prices.vpn[this.currency]);
  }

  forLoadBalancer() {
    return this.formatCost(this.prices.loadBalancer[this.currency]);
  }

  forIpVersion(version) {
    return this.formatCost(this.prices[`ipv${version}`][this.currency]);
  }

  formatCost(cost) {
    return {
      cost: cost ? Number(cost.toFixed(2)) : "N/A",
      currency: this.currency ?? "",
    };
  }
}
