import { getOrSetUniversal, getOrSetGlobal } from '@warp-drive/core-types/-private';
import { STRUCTURED, IS_FUTURE, SkipCache } from '@warp-drive/core-types/request';
import { macroCondition, getGlobalConfig } from '@embroider/macros';
const PromiseCache = getOrSetUniversal('PromiseCache', new WeakMap());
const RequestMap = getOrSetUniversal('RequestMap', new Map());
function setRequestResult(requestId, result) {
  RequestMap.set(requestId, result);
}
function clearRequestResult(requestId) {
  RequestMap.delete(requestId);
}
function getRequestResult(requestId) {
  return RequestMap.get(requestId);
}
function setPromiseResult(promise, result) {
  PromiseCache.set(promise, result);
}
function getPromiseResult(promise) {
  return PromiseCache.get(promise);
}
const IS_CACHE_HANDLER = getOrSetGlobal('IS_CACHE_HANDLER', Symbol('IS_CACHE_HANDLER'));
function curryFuture(owner, inbound, outbound) {
  owner.setStream(inbound.getStream());
  inbound.then(doc => {
    const document = {
      [STRUCTURED]: true,
      request: owner.request,
      response: doc.response,
      content: doc.content
    };
    outbound.resolve(document);
  }, error => {
    if (isDoc(error)) {
      owner.setStream(owner.god.stream);
    }
    if (!error || !(error instanceof Error)) {
      try {
        throw new Error(error ? error : `Request Rejected with an Unknown Error`);
      } catch (e) {
        if (error && typeof error === 'object') {
          Object.assign(e, error);
          e.message = error.message || `Request Rejected with an Unknown Error`;
        }
        error = e;
      }
    }
    error[STRUCTURED] = true;
    error.request = owner.request;
    error.response = owner.getResponse();
    error.error = error.error || error.message;
    outbound.reject(error);
  });
  return outbound.promise;
}
function isDoc(doc) {
  return doc && doc[STRUCTURED] === true;
}
function ensureDoc(owner, content, isError) {
  if (isDoc(content)) {
    return content;
  }
  if (isError) {
    return {
      [STRUCTURED]: true,
      request: owner.request,
      response: owner.getResponse(),
      error: content
    };
  }
  return {
    [STRUCTURED]: true,
    request: owner.request,
    response: owner.getResponse(),
    content: content
  };
}
function enhanceReason(reason) {
  return new DOMException(reason || 'The user aborted a request.', 'AbortError');
}
function handleOutcome(owner, inbound, outbound) {
  inbound.then(content => {
    if (owner.controller.signal.aborted) {
      // the next function did not respect the signal, we handle it here
      outbound.reject(enhanceReason(owner.controller.signal.reason));
      return;
    }
    if (isDoc(content)) {
      owner.setStream(owner.god.stream);
      content = content.content;
    }
    const document = {
      [STRUCTURED]: true,
      request: owner.request,
      response: owner.getResponse(),
      content
    };
    outbound.resolve(document);
  }, error => {
    if (isDoc(error)) {
      owner.setStream(owner.god.stream);
    }
    if (!error || !(error instanceof Error)) {
      try {
        throw new Error(error ? error : `Request Rejected with an Unknown Error`);
      } catch (e) {
        if (error && typeof error === 'object') {
          Object.assign(e, error);
          e.message = error.message || `Request Rejected with an Unknown Error`;
        }
        error = e;
      }
    }
    error[STRUCTURED] = true;
    error.request = owner.request;
    error.response = owner.getResponse();
    error.error = error.error || error.message;
    outbound.reject(error);
  });
  return outbound.promise;
}
function isCacheHandler(handler, index) {
  return index === 0 && Boolean(handler[IS_CACHE_HANDLER]);
}
function executeNextHandler(wares, request, i, god) {
  if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
    if (i === wares.length) {
      throw new Error(`No handler was able to handle this request.`);
    }
    assertValidRequest(request, false);
  }
  const owner = new ContextOwner(request, god, i === 0);
  function next(r) {
    owner.nextCalled++;
    return executeNextHandler(wares, r, i + 1, god);
  }
  const _isCacheHandler = isCacheHandler(wares[i], i);
  const context = new Context(owner, _isCacheHandler);
  let outcome;
  try {
    outcome = wares[i].request(context, next);
    if (_isCacheHandler) {
      context._finalize();
    }
    if (!!outcome && _isCacheHandler) {
      if (!(outcome instanceof Promise)) {
        setRequestResult(owner.requestId, {
          isError: false,
          result: ensureDoc(owner, outcome, false)
        });
        outcome = Promise.resolve(outcome);
      }
    } else if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      if (!outcome || !(outcome instanceof Promise) && !(typeof outcome === 'object' && 'then' in outcome)) {
        // eslint-disable-next-line no-console
        console.log({
          request,
          handler: wares[i],
          outcome
        });
        if (outcome === undefined) {
          throw new Error(`Expected handler.request to return a promise, instead received undefined.`);
        }
        throw new Error(`Expected handler.request to return a promise, instead received a synchronous value.`);
      }
    }
  } catch (e) {
    if (_isCacheHandler) {
      setRequestResult(owner.requestId, {
        isError: true,
        result: ensureDoc(owner, e, true)
      });
    }
    // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
    outcome = Promise.reject(e);
  }
  const future = createFuture(owner);
  if (isFuture(outcome)) {
    return curryFuture(owner, outcome, future);
  }
  return handleOutcome(owner, outcome, future);
}
function isFuture(maybe) {
  return Boolean(maybe && maybe instanceof Promise && maybe[IS_FUTURE] === true);
}
function createDeferred() {
  let resolve;
  let reject;
  const promise = new Promise((res, rej) => {
    resolve = res;
    reject = rej;
  });
  return {
    resolve,
    reject,
    promise
  };
}
function upgradePromise(promise, future) {
  promise[IS_FUTURE] = true;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  promise.getStream = future.getStream;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  promise.abort = future.abort;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  promise.onFinalize = future.onFinalize;
  promise.id = future.id;
  promise.lid = future.lid;
  return promise;
}
function createFuture(owner) {
  const deferred = createDeferred();
  let {
    promise
  } = deferred;
  let cbs;
  promise = promise.finally(() => {
    owner.resolveStream();
    if (cbs) {
      cbs.forEach(cb => cb());
    }
  });
  promise.onFinalize = fn => {
    cbs = cbs || [];
    cbs.push(fn);
  };
  promise[IS_FUTURE] = true;
  promise.getStream = () => {
    return owner.getStream();
  };
  promise.abort = reason => {
    owner.abort(enhanceReason(reason));
  };
  promise.id = owner.requestId;
  promise.lid = owner.god.identifier;
  deferred.promise = promise;
  return deferred;
}
function upgradeHeaders(headers) {
  headers.clone = () => {
    return new Headers(headers);
  };
  headers.toJSON = () => {
    return Array.from(headers);
  };
  return headers;
}
function cloneResponseProperties(response) {
  const {
    headers,
    ok,
    redirected,
    status,
    statusText,
    type,
    url
  } = response;
  upgradeHeaders(headers);
  return {
    headers: headers,
    ok,
    redirected,
    status,
    statusText,
    type,
    url
  };
}
class ContextOwner {
  hasSetStream = false;
  hasSetResponse = false;
  hasSubscribers = false;
  stream = createDeferred();
  response = null;
  nextCalled = 0;
  constructor(request, god, isRoot = false) {
    this.isRoot = isRoot;
    this.requestId = god.id;
    this.controller = request.controller || god.controller;
    this.stream.promise.sizeHint = 0;
    if (request.controller) {
      if (request.controller !== god.controller) {
        god.controller.signal.addEventListener('abort', () => {
          this.controller.abort(god.controller.signal.reason);
        });
      }
      delete request.controller;
    }
    let enhancedRequest = Object.assign({
      signal: this.controller.signal
    }, request);
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      if (!request?.cacheOptions?.[SkipCache]) {
        request = deepFreeze(request);
        enhancedRequest = deepFreeze(enhancedRequest);
      }
    } else {
      if (request.headers) {
        upgradeHeaders(request.headers);
      }
    }
    this.enhancedRequest = enhancedRequest;
    this.request = request;
    this.god = god;
    this.stream.promise = this.stream.promise.then(stream => {
      if (this.god.stream === stream && this.hasSubscribers) {
        this.god.stream = null;
      }
      return stream;
    });
  }
  get hasRequestedStream() {
    return this.god.hasRequestedStream;
  }
  getResponse() {
    if (this.hasSetResponse) {
      return this.response;
    }
    if (this.nextCalled === 1) {
      return this.god.response;
    }
    return null;
  }
  getStream() {
    if (this.isRoot) {
      this.god.hasRequestedStream = true;
    }
    if (!this.hasSetResponse) {
      const hint = this.god.response?.headers?.get('content-length');
      this.stream.promise.sizeHint = hint ? parseInt(hint, 10) : 0;
    }
    this.hasSubscribers = true;
    return this.stream.promise;
  }
  abort(reason) {
    this.controller.abort(reason);
  }
  setStream(stream) {
    if (!this.hasSetStream) {
      this.hasSetStream = true;
      if (!(stream instanceof Promise)) {
        this.god.stream = stream;
      }
      // @ts-expect-error
      this.stream.resolve(stream);
    }
  }
  resolveStream() {
    this.setStream(this.nextCalled === 1 ? this.god.stream : null);
  }
  setResponse(response) {
    if (this.hasSetResponse) {
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        throw new Error(`Cannot setResponse when a response has already been set`);
      }
      return;
    }
    this.hasSetResponse = true;
    if (response instanceof Response) {
      // TODO potentially avoid cloning in prod
      let responseData = cloneResponseProperties(response);
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        responseData = deepFreeze(responseData);
      }
      this.response = responseData;
      this.god.response = responseData;
      const sizeHint = response.headers?.get('content-length');
      this.stream.promise.sizeHint = sizeHint ? parseInt(sizeHint, 10) : 0;
    } else {
      this.response = response;
      this.god.response = response;
    }
  }
}
class Context {
  #owner;
  constructor(owner, isCacheHandler) {
    this.id = owner.requestId;
    this.#owner = owner;
    this.request = owner.enhancedRequest;
    this._isCacheHandler = isCacheHandler;
    this._finalized = false;
  }
  setStream(stream) {
    this.#owner.setStream(stream);
  }
  setResponse(response) {
    this.#owner.setResponse(response);
  }
  setIdentifier(identifier) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`setIdentifier may only be used synchronously from a CacheHandler`);
      }
    })(identifier && this._isCacheHandler && !this._finalized) : {};
    this.#owner.god.identifier = identifier;
  }
  get hasRequestedStream() {
    return this.#owner.hasRequestedStream;
  }
  _finalize() {
    this._finalized = true;
  }
}
const BODY_TYPES = {
  type: 'string',
  klass: ['Blob', 'ArrayBuffer', 'TypedArray', 'DataView', 'FormData', 'URLSearchParams', 'ReadableStream']
};
const ValidKeys = new Map([['records', 'array'], ['data', 'json'], ['body', BODY_TYPES], ['disableTestWaiter', 'boolean'], ['options', 'object'], ['cacheOptions', 'object'], ['op', 'string'], ['store', 'object'], ['url', 'string'], ['cache', ['default', 'force-cache', 'no-cache', 'no-store', 'only-if-cached', 'reload']], ['credentials', ['include', 'omit', 'same-origin']], ['destination', ['', 'object', 'audio', 'audioworklet', 'document', 'embed', 'font', 'frame', 'iframe', 'image', 'manifest', 'paintworklet', 'report', 'script', 'sharedworker', 'style', 'track', 'video', 'worker', 'xslt']], ['headers', 'headers'], ['integrity', 'string'], ['keepalive', 'boolean'], ['method', ['QUERY', 'GET', 'PUT', 'PATCH', 'DELETE', 'POST', 'OPTIONS', 'HEAD', 'CONNECT', 'TRACE']], ['mode', ['same-origin', 'cors', 'navigate', 'no-cors']], ['redirect', ['error', 'follow', 'manual']], ['referrer', 'string'], ['signal', 'AbortSignal'], ['controller', 'AbortController'], ['referrerPolicy', ['', 'same-origin', 'no-referrer', 'no-referrer-when-downgrade', 'origin', 'origin-when-cross-origin', 'strict-origin', 'strict-origin-when-cross-origin', 'unsafe-url']]]);
const IS_FROZEN = getOrSetGlobal('IS_FROZEN', Symbol('FROZEN'));
const IS_COLLECTION = getOrSetGlobal('IS_COLLECTION', Symbol.for('Collection'));
function freezeHeaders(headers) {
  headers.delete = headers.set = headers.append = () => {
    throw new Error(`Cannot Mutate Immutatable Headers, use headers.clone to get a copy`);
  };
  upgradeHeaders(headers);
  return headers;
}
function deepFreeze(value) {
  if (value && value[IS_FROZEN]) {
    return value;
  }
  const _type = typeof value;
  switch (_type) {
    case 'boolean':
    case 'string':
    case 'number':
    case 'symbol':
    case 'undefined':
    case 'bigint':
      return value;
    case 'function':
      throw new Error(`Cannot deep-freeze a function`);
    case 'object':
      {
        const _niceType = niceTypeOf(value);
        switch (_niceType) {
          case 'array':
            {
              if (value[IS_COLLECTION]) {
                return value;
              }
              const arr = value.map(deepFreeze);
              arr[IS_FROZEN] = true;
              return Object.freeze(arr);
            }
          case 'null':
            return value;
          case 'object':
            Object.keys(value).forEach(key => {
              try {
                value[key] = deepFreeze(value[key]);
              } catch {
                // continue
              }
            });
            value[IS_FROZEN] = true;
            return Object.freeze(value);
          case 'headers':
            return freezeHeaders(value);
          case 'Collection':
          case 'Store':
          case 'AbortSignal':
            return value;
          case 'date':
          case 'map':
          case 'set':
          case 'error':
          case 'stream':
          default:
            // console.log(`Cannot deep-freeze ${_niceType}`);
            return value;
        }
      }
  }
}
function isMaybeContext(request) {
  if (request && typeof request === 'object') {
    const keys = Object.keys(request);
    if (keys.length === 1 && keys[0] === 'request') {
      return true;
    }
  }
  return false;
}
function niceTypeOf(v) {
  if (v === null) {
    return 'null';
  }
  if (typeof v === 'string') {
    return v ? 'non-empty-string' : 'empty-string';
  }
  if (!v) {
    return typeof v;
  }
  if (Array.isArray(v)) {
    return 'array';
  }
  if (v instanceof Date) {
    return 'date';
  }
  if (v instanceof Map) {
    return 'map';
  }
  if (v instanceof Set) {
    return 'set';
  }
  if (v instanceof Error) {
    return 'error';
  }
  if (v instanceof ReadableStream || v instanceof WritableStream || v instanceof TransformStream) {
    return 'stream';
  }
  if (v instanceof Headers) {
    return 'headers';
  }
  if (typeof v === 'object' && v.constructor && v.constructor.name !== 'Object') {
    return v.constructor.name;
  }
  return typeof v;
}
function validateKey(key, value, errors) {
  const schema = ValidKeys.get(key);
  if (!schema && !IgnoredKeys.has(key)) {
    errors.push(`InvalidKey: '${key}'`);
    return;
  }
  if (schema) {
    if (schema === BODY_TYPES) {
      if (typeof value === 'string' || value instanceof ReadableStream) {
        return;
      }
      const type = niceTypeOf(value);
      if (schema.klass.includes(type)) {
        return;
      }
      errors.push(`InvalidValue: key 'body' should be a string or one of '${schema.klass.join("', '")}', received ${'<a value of type ' + niceTypeOf(value) + '>'}`);
      return;
    }
    if (Array.isArray(schema)) {
      if (!schema.includes(value)) {
        errors.push(`InvalidValue: key ${key} should be one of '${schema.join("', '")}', received ${typeof value === 'string' ? value : '<a value of type ' + niceTypeOf(value) + '>'}`);
      }
      return;
    } else if (schema === 'json') {
      try {
        JSON.stringify(value);
      } catch (e) {
        errors.push(`InvalidValue: key ${key} should be a JSON serializable value, but failed to serialize with Error - ${e.message}`);
      }
      return;
    } else if (schema === 'headers') {
      if (!(value instanceof Headers)) {
        errors.push(`InvalidValue: key ${key} should be an instance of Headers, received ${niceTypeOf(value)}`);
      }
      return;
    } else if (schema === 'record') {
      const _type = typeof value;
      // record must extend plain object or Object.create(null)
      if (!value || _type !== 'object' || value.constructor && value.constructor !== Object) {
        errors.push(`InvalidValue: key ${key} should be a dictionary of string keys to string values, received ${niceTypeOf(value)}`);
        return;
      }
      const keys = Object.keys(value);
      keys.forEach(k => {
        const v = value[k];
        if (typeof k !== 'string') {
          errors.push(`\tThe key ${String(k)} on ${key} should be a string key`);
        } else if (typeof v !== 'string') {
          errors.push(`\tThe value of ${key}.${k} should be a string not ${niceTypeOf(v)}`);
        }
      });
      return;
    } else if (schema === 'string') {
      if (typeof value !== 'string' || value.length === 0) {
        errors.push(`InvalidValue: key ${key} should be a non-empty string, received ${typeof value === 'string' ? "''" : typeof value}`);
      }
      return;
    } else if (schema === 'object') {
      if (!value || Array.isArray(value) || typeof value !== 'object') {
        errors.push(`InvalidValue: key ${key} should be an object`);
      }
      return;
    } else if (schema === 'boolean') {
      if (typeof value !== 'boolean') {
        errors.push(`InvalidValue: key ${key} should be a boolean, received ${typeof value}`);
      }
      return;
    } else if (schema === 'array') {
      if (!Array.isArray(value)) {
        errors.push(`InvalidValue: key ${key} should be an array, received ${typeof value}`);
      }
      return;
    }
  }
}
const IgnoredKeys = new Set([]);
function assertValidRequest(request, isTopLevel) {
  if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
    // handle basic shape
    if (!request) {
      throw new Error(`Expected ${isTopLevel ? 'RequestManager.request' : 'next'}(<request>) to be called with a request, but none was provided.`);
    }
    if (Array.isArray(request) || typeof request !== 'object') {
      throw new Error(`The \`request\` passed to \`${isTopLevel ? 'RequestManager.request' : 'next'}(<request>)\` should be an object, received \`${niceTypeOf(request)}\``);
    }
    if (Object.keys(request).length === 0) {
      throw new Error(`The \`request\` passed to \`${isTopLevel ? 'RequestManager.request' : 'next'}(<request>)\` was empty (\`{}\`). Requests need at least one valid key.`);
    }

    // handle accidentally passing context entirely
    if (request instanceof Context) {
      throw new Error(`Expected a request passed to \`${isTopLevel ? 'RequestManager.request' : 'next'}(<request>)\` but received the previous handler's context instead`);
    }
    // handle Object.assign({}, context);
    if (isMaybeContext(request)) {
      throw new Error(`Expected a request passed to \`${isTopLevel ? 'RequestManager.request' : 'next'}(<request>)\` but received an object with a request key instead.`);
    }

    // handle schema
    const keys = Object.keys(request);
    const validationErrors = [];
    const isLegacyRequest = Boolean('op' in request && !request.url);
    keys.forEach(key => {
      if (isLegacyRequest && key === 'data') {
        return;
      }
      validateKey(key, request[key], validationErrors);
    });
    if (validationErrors.length) {
      const error = new Error(`Invalid Request passed to \`${isTopLevel ? 'RequestManager.request' : 'next'}(<request>)\`.\n\nThe following issues were found:\n\n\t${validationErrors.join('\n\t')}`);
      error.errors = validationErrors;
      throw error;
    }
  }
}
export { IS_CACHE_HANDLER as I, assertValidRequest as a, createDeferred as b, clearRequestResult as c, getPromiseResult as d, executeNextHandler as e, cloneResponseProperties as f, getRequestResult as g, setPromiseResult as s, upgradePromise as u };