import { Ability } from "ember-can";
import { service } from "@ember/service";

export default class ApiKeyAbility extends Ability {
  @service session;

  get organizations() {
    return this.model;
  }

  get canCreate() {
    let ownedOrganizationsWithProjects = this.organizations.filter(
      (organization) =>
        (this.session.currentUser?.isSuperUser || organization.get("isOwner")) && organization.projects.length > 0,
    );

    return ownedOrganizationsWithProjects.length > 0;
  }
}
