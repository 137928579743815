import { template as template_9408e0c2b01a44e69d7890ad6bd4ac08 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { Task, task } from "ember-concurrency";
import { service } from "@ember/service";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf, shape } from "prop-types";
import { tracked } from "@glimmer/tracking";
import Server from "glesys-controlpanel/models/server";
import GModalHeader from "glesys-controlpanel/components/g-modal/header";
import GModalBody from "glesys-controlpanel/components/g-modal/body";
import GModalFooter from "glesys-controlpanel/components/g-modal/footer";
import TaskForm from "glesys-controlpanel/components/task-form";
import Label from "glesys-controlpanel/components/label";
import GInput from "glesys-controlpanel/components/g-input";
import GButton from "glesys-controlpanel/components/g-button";
import modalClose from "glesys-controlpanel/helpers/modal-close";
import { t } from "ember-intl";
import { on } from "@ember/modifier";
export default @forbidExtraArgs
class ModalsServerEditDescription extends Component {
    @service
    modal;
    @arg(shape({
        server: instanceOf(Server).isRequired,
        task: instanceOf(Task).isRequired
    }).isRequired)
    params;
    get server() {
        return this.args.params.server;
    }
    get task() {
        return this.args.params.task;
    }
    @tracked
    description = this.server.description;
    submitTask = task(async ()=>{
        await this.task.perform({
            description: this.description
        });
        this.modal.close();
    });
    onChange = ({ description })=>(this.description = description);
    get isDisabled() {
        return !this.description || this.task.isRunning;
    }
    static{
        template_9408e0c2b01a44e69d7890ad6bd4ac08(`
    <GModalHeader>
      {{t "label.edit-name-for" name=this.server.id}}
    </GModalHeader>
    <GModalBody>
      <TaskForm @onChange={{this.onChange}} @task={{this.submitTask}} id="server-edit-description">
        <Label>
          {{t "label.name"}}
        </Label>
        <GInput
          value={{this.description}}
          name="description"
          required={{true}}
          minlength="2"
          {{! template-lint-disable no-autofocus-attribute }}
          autofocus
          data-test-server-description
        />
      </TaskForm>
    </GModalBody>
    <GModalFooter>
      <GButton @color="gray" class="mr-2" {{on "click" (modalClose)}}>
        {{t "button.cancel"}}
      </GButton>
      <GButton
        @type="submit"
        @color="blue"
        @disabled={{this.isDisabled}}
        form="server-edit-description"
        data-test-server-edit-description-submit
      >
        {{t "button.update"}}
      </GButton>
    </GModalFooter>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
