import { tracked } from "@glimmer/tracking";

export default class Nameserver {
  id;
  domainName;

  @tracked _nameserver;
  @tracked _glueRecord;

  constructor(id, domainName, nameserver, glueRecord) {
    this.id = id;
    this.domainName = domainName;
    this.nameserver = nameserver;
    this.glueRecord = glueRecord;
  }

  get nameserver() {
    return this._nameserver;
  }

  set nameserver(nameserver) {
    this._nameserver = nameserver;
    if (!this.needsGlueRecord) {
      this.glueRecord = "";
    }
  }

  get glueRecord() {
    return this._glueRecord;
  }

  set glueRecord(glueRecord) {
    this._glueRecord = this.needsGlueRecord ? glueRecord : "";
  }

  get supportsGlueRecord() {
    let supportedTlds = ["se", "nu"];
    return new RegExp(`\\.(${supportedTlds.join("|")})$`).test(this.domainName);
  }

  get needsGlueRecord() {
    return this.nameserver.endsWith(`.${this.domainName}`);
  }
}
