import { template as template_142ccbaffed643a6be25ab4b3b1ed306 } from "@ember/template-compiler";
import Box from "glesys-controlpanel/components/box";
import Cost from "glesys-controlpanel/components/cost";
import IconsEye from "glesys-controlpanel/components/icons/eye";
import GButtonLink from "glesys-controlpanel/components/g-button/link";
import { t } from "ember-intl";
export default template_142ccbaffed643a6be25ab4b3b1ed306(`
  <Box data-test-server-total-cost>
    <:default>
      <p class="text-gray-dark text-sm">{{t "title.total-cost"}}</p>
      <Cost
        @currency={{@server.billing.currency}}
        @price={{@server.billing.price}}
        @discountedPrice={{@server.billing.total}}
        class="mt-1"
      />
    </:default>
    <:footer>
      <GButtonLink @display="compact" href="#server-cost-summary">
        <div class="flex items-center">
          <IconsEye class="w-5 mr-1 -mt-px text-rebeccapurple" />
          {{t "server.details.view-breakdown"}}
        </div>
      </GButtonLink>
    </:footer>
  </Box>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
