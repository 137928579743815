import { template as template_429551abdfab48639e3d859e6d90dbb1 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { Task, task } from "ember-concurrency";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { shape, instanceOf } from "prop-types";
import { on } from "@ember/modifier";
import { fn } from "@ember/helper";
import { t } from "ember-intl";
import { tracked } from "@glimmer/tracking";
import Header from "glesys-controlpanel/components/g-modal/header";
import Body from "glesys-controlpanel/components/g-modal/body";
import Footer from "glesys-controlpanel/components/g-modal/footer";
import Select from "glesys-controlpanel/components/select";
import modalClose from "glesys-controlpanel/helpers/modal-close";
import GButton from "glesys-controlpanel/components/g-button";
import TaskForm from "glesys-controlpanel/components/task-form";
import Label from "glesys-controlpanel/components/label";
import GInput from "glesys-controlpanel/components/g-input";
import RadioButton from "glesys-controlpanel/components/radio-button";
import InfoPopup from "glesys-controlpanel/components/info-popup";
import DataLoader from "glesys-controlpanel/components/data-loader";
export default @forbidExtraArgs
class ModalsCreateRemoteHandsRequest extends Component {
    @service
    modal;
    @service
    ajax;
    @arg(shape({
        submitTask: instanceOf(Task),
        loadServicesTask: instanceOf(Task)
    }).isRequired)
    params;
    @tracked
    selectedServices = [];
    @tracked
    selectedExecutionOption;
    instructions;
    shipments;
    selectServices = (service)=>{
        if (this.selectedServices.includes(service)) {
            this.selectedServices = this.selectedServices.filter((s)=>s !== service);
        } else {
            this.selectedServices = [
                ...this.selectedServices,
                service
            ];
        }
    };
    selectExecutionOption = (option)=>{
        this.selectedExecutionOption = option;
    };
    onFormChange = ({ instructions, shipments })=>{
        this.instructions = instructions;
        this.shipments = shipments;
    };
    loadServicesTask = task(async ()=>{
        let services = await this.params.loadServicesTask.perform();
        return services.map((service)=>({
                id: service.id,
                value: service.id
            }));
    });
    onSubmitTask = task(async ()=>{
        let ok = await this.params.submitTask.perform(this.selectedServices, this.selectedExecutionOption, this.instructions, this.shipments);
        if (ok) {
            this.modal.close();
        }
    });
    static{
        template_429551abdfab48639e3d859e6d90dbb1(`
    <Header>{{t "component.modal.create-remote-hands-request.title"}}</Header>
    <Body data-test-create-remote-hands-request-modal>
      <p class="text-gray-darker pb-6">{{t "component.modal.create-remote-hands-request.description"}}</p>
      <TaskForm @onChange={{this.onFormChange}} @task={{this.onSubmitTask}} id="create-remote-hands-request-form">
        <div class="grid gap-6">
          <div>
            <Label>{{t "label.services"}}</Label>
            <DataLoader @load={{this.loadServicesTask}} as |services|>
              <Select
                @items={{services}}
                @selected={{this.selectedServices}}
                @onChange={{this.selectServices}}
                @multiple={{true}}
                data-test-create-remote-hands-request-services
              />
            </DataLoader>
          </div>

          <div>
            <Label>{{t "label.execution-option"}}</Label>
            <div class="flex flex-col gap-4">
              <div
                role="button"
                class="border border-gray-light p-4 flex gap-2 rounded"
                {{on "click" (fn this.selectExecutionOption "asap")}}
                {{! template-lint-disable require-presentational-children }}
              >
                <RadioButton
                  @name="remote-hands-request-execution-option"
                  @groupValue={{this.selectedExecutionOption}}
                  @value="asap"
                  @onChange={{fn this.selectExecutionOption "asap"}}
                  class="w-full"
                  required
                  data-test-create-remote-hands-request-execution-option="asap"
                >
                  <div class="flex flex-col gap-1.5 ml-0.5 mb-0.5">
                    <div class="flex gap-2 items-center text-black">
                      {{t "component.modal.create-remote-hands-request.execution-option.asap.text"}}
                      <InfoPopup>
                        {{t "component.modal.create-remote-hands-request.execution-option.asap.info-popup"}}
                      </InfoPopup>
                    </div>
                    <div class="text-gray-dark">
                      {{t "component.modal.create-remote-hands-request.execution-option.asap.sub-text"}}
                    </div>
                  </div>
                </RadioButton>
              </div>
              <div
                role="button"
                class="border border-gray-light p-4 flex gap-2 rounded"
                {{on "click" (fn this.selectExecutionOption "scheduled")}}
                {{! template-lint-disable require-presentational-children }}
              >
                <RadioButton
                  @name="remote-hands-request-execution-option"
                  @groupValue={{this.selectedExecutionOption}}
                  @value="scheduled"
                  @onChange={{fn this.selectExecutionOption "scheduled"}}
                  class="w-full"
                  required
                  data-test-create-remote-hands-request-execution-option="scheduled"
                >
                  <div class="flex flex-col gap-1.5 ml-0.5 mb-0.5">
                    <div class="text-black">
                      {{t "component.modal.create-remote-hands-request.execution-option.scheduled.text"}}
                    </div>
                    <div class="text-gray-dark">
                      {{t "component.modal.create-remote-hands-request.execution-option.scheduled.sub-text"}}
                    </div>
                  </div>
                </RadioButton>
              </div>
            </div>
          </div>
          <div>
            <Label for="instructions">{{t "label.instructions"}}</Label>
            <textarea
              id="instructions"
              rows="6"
              class="w-full border text-sm border-gray-light focus:border-powder-blue rounded p-3"
              value={{this.instructions}}
              name="instructions"
              placeholder={{t "component.modal.create-remote-hands-request.instructions-placeholder"}}
              required
              data-test-create-remote-hands-request-modal-instructions
            />
          </div>

          <div>
            <Label for="remote-hands-request-shipments">{{t "label.shipments-optional"}}</Label>
            <GInput
              value={{this.shipments}}
              name="shipments"
              placeholder={{t "component.modal.create-remote-hands-request.shipments-placeholder"}}
              data-test-create-remote-hands-request-modal-shipments
            />
          </div>
        </div>
      </TaskForm>
    </Body>
    <Footer>
      <GButton @color="gray" class="mr-2" data-test-cancel-button {{on "click" (modalClose)}}>
        {{t "button.close"}}
      </GButton>
      <GButton
        @type="submit"
        @color="blue"
        form="create-remote-hands-request-form"
        data-test-create-remote-hands-request-submit
      >
        {{t "button.create"}}
      </GButton>
    </Footer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
