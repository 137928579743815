/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class ModalsFileStorageVolumesUpdateDetails extends Component {
  @service modal;

  get task() {
    return this.args.params.updateDetailsTask;
  }

  get volumeName() {
    return this.args.params.currentName;
  }

  newName = "";

  onFormChange = ({ name }) => (this.newName = name);

  submitTask = task(async () => {
    let { task, newName } = this;

    await task.perform(newName);
    this.modal.close();
  });
}
