/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { bool, func } from "prop-types";
import { modifier } from "ember-modifier";

@forbidExtraArgs
export default class AtomDeleteOption extends Component {
  @arg(stringable.isRequired) key;
  @arg(stringable) label;
  @arg(bool) selected = false;
  @arg(func.isRequired) onChange;

  handleDefault = modifier((element) => element.dispatchEvent(new Event("change", { bubbles: true })));
}
