import { template as template_6e4307a8eace4f908f66790ad4d2a883 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { arg } from "ember-arg-types";
import { shape, instanceOf } from "prop-types";
import { Task } from "ember-concurrency";
import TaskForm from "glesys-controlpanel/components/task-form";
import Label from "glesys-controlpanel/components/label";
import PasswordInput from "glesys-controlpanel/components/password-input";
import GModalHeader from "glesys-controlpanel/components/g-modal/header";
import GModalBody from "glesys-controlpanel/components/g-modal/body";
import GModalFooter from "glesys-controlpanel/components/g-modal/footer";
import GButton from "glesys-controlpanel/components/g-button";
import modalClose from "glesys-controlpanel/helpers/modal-close";
import { t } from "ember-intl";
import { on } from "@ember/modifier";
export default class ModalsArchiveChangePassword extends Component {
    @service
    modal;
    @arg(shape({
        task: instanceOf(Task).isRequired
    }).isRequired)
    params;
    get task() {
        return this.params.task;
    }
    @tracked
    password = "";
    @tracked
    passwordConfirm = "";
    @action
    onFormChange({ password, passwordConfirm }) {
        this.password = password;
        this.passwordConfirm = passwordConfirm;
    }
    get isPasswordTooShort() {
        return this.password.length > 0 && this.password.length < 8;
    }
    get isPasswordMismatch() {
        return this.password.length > 0 && this.password !== this.passwordConfirm;
    }
    get hasErrors() {
        return this.isPasswordMismatch || this.isPasswordTooShort;
    }
    submitTask = task(async ()=>{
        await this.task.perform(this.password);
        this.modal.close();
    });
    static{
        template_6e4307a8eace4f908f66790ad4d2a883(`
    <GModalHeader>
      {{t "title.change-password"}}
    </GModalHeader>
    <GModalBody>
      <TaskForm @onChange={{this.onFormChange}} @task={{this.submitTask}} id="archive-change-password">
        <div>
          <Label for="archive-new-password">
            {{t "label.new-password"}}
          </Label>
          <PasswordInput
            value={{this.password}}
            name="password"
            id="archive-new-password"
            autocomplete="new-password"
            data-test-archive-change-password-new-password
          />
        </div>
        <div class="pt-6">
          <Label for="archive-confirm-password">
            {{t "label.confirm-password"}}
          </Label>
          <PasswordInput
            value={{this.passwordConfirm}}
            name="passwordConfirm"
            id="archive-confirm-password"
            autocomplete="new-password"
            data-test-archive-change-password-confirm-new-password
          />
        </div>

        {{#if this.hasErrors}}
          <div class="mt-4 text-red border border-red-lighter rounded bg-red-lightest" data-test-password-errors>
            <ul role="list" class="p-3 list-disc list-inside text-sm">
              {{#if this.isPasswordMismatch}}<li>{{t "message.error.password-match"}}</li>{{/if}}
              {{#if this.isPasswordTooShort}}<li>{{t "message.error.password-length" length=8}}</li>{{/if}}
            </ul>
          </div>
        {{/if}}
      </TaskForm>
    </GModalBody>
    <GModalFooter>
      <GButton @color="gray" class="mr-2" {{on "click" (modalClose)}}>
        {{t "button.cancel"}}
      </GButton>

      <GButton
        @type="submit"
        @color="blue"
        @disabled={{this.hasErrors}}
        form="archive-change-password"
        data-test-archive-change-password-save-button
      >
        {{t "button.change-password"}}
      </GButton>
    </GModalFooter>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
