import Route from "@ember/routing/route";
import { service } from "@ember/service";
import { task, all } from "ember-concurrency";
import { NotFoundError } from "glesys-controlpanel/utils/errors";

export default class RemoteHandsRequestRoute extends Route {
  @service store;
  @service abilities;

  async model() {
    return {
      createRemoteHandsRequestTask: this.createRemoteHandsRequestTask,
      loadServicesTask: this.loadServicesTask,
    };
  }

  beforeModel() {
    let { organization } = this.modelFor("organization");

    if (this.abilities.cannot("access remote hands request for organization", organization)) {
      throw new NotFoundError();
    }
  }

  loadServicesTask = task(async () => {
    let { project } = this.modelFor("organization");

    let serversPromise = project.get("servers");
    let racksPromise = project.get("colocationRacks");

    let [servers, racks] = await all([serversPromise, racksPromise]);

    return servers.filter((server) => server.isColocation).concat(racks);
  });

  createRemoteHandsRequestTask = task(async (services, executionOption, instructions, shipments) => {
    let { project } = this.modelFor("organization");

    let request = this.store.createRecord("remote-hands-request", {
      project,
      servicekeys: services.map((service) => service.id),
      executionoption: executionOption,
      instructions,
      trackingids: shipments ? shipments.split(/, ?/).filter(Boolean) : null,
    });
    await request.save();
    return true;
  });
}
