import { template as template_d4a948cd07b24589a10a8fa595b1e0ff } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { task } from "ember-concurrency";
import QrCreator from "qr-creator";
import DataLoader from "glesys-controlpanel/components/data-loader";
export default @forbidExtraArgs
class QrCode extends Component {
    @arg(stringable.isRequired)
    content;
    createQRCode = task(async ()=>{
        let container = document.createElement("div");
        QrCreator.render({
            text: this.content,
            radius: 0.5,
            ecLevel: "L",
            size: 300,
            background: "#fff"
        }, container);
        return container.firstChild.toDataURL();
    });
    static{
        template_d4a948cd07b24589a10a8fa595b1e0ff(`
    <DataLoader @load={{this.createQRCode}} data-test-qr-code ...attributes as |qrCode|>
      <img aria-hidden="true" src={{qrCode}} />
    </DataLoader>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
