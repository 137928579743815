import { template as template_3d2a66d25cc747f591aebcb60ed204d4 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { arg } from "ember-arg-types";
import { bool } from "prop-types";
import { service } from "@ember/service";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsGenericServer from "glesys-controlpanel/components/icons/generic-server";
export default class GServerStatusIconComponent extends Component {
    @service
    intl;
    @arg(bool)
    showStatus = false;
    get platform() {
        return this.args.platform?.toLowerCase();
    }
    get isRunning() {
        return this.args.isRunning || false;
    }
    get state() {
        if (this.args.isLocked) return "locked";
        if (this.args.isRunning !== undefined) {
            return this.args.isRunning ? "running" : "stopped";
        }
        return "unknown";
    }
    get statusString() {
        switch(this.state){
            case "locked":
                return this.intl.t("component.g-server-status.locked");
            case "running":
                return this.intl.t("component.g-server-status.running");
            case "stopped":
                return this.intl.t("component.g-server-status.stopped");
            case "unknown":
            default:
                return this.intl.t("component.g-server-status.loading");
        }
    }
    get textColorClasses() {
        switch(this.state){
            case "locked":
                return "text-yellow";
            case "running":
                return "text-ocean-green";
            case "stopped":
                return "text-red";
        }
        return "text-gray-light";
    }
    get colorClasses() {
        switch(this.state){
            case "locked":
                return "border-yellow bg-yellow-lightest";
            case "running":
                return "border-ocean-green bg-ocean-green-lightest";
            case "stopped":
                return "border-red bg-red-lightest";
        }
        return "border-gray-light bg-gray-lightest";
    }
    static{
        template_3d2a66d25cc747f591aebcb60ed204d4(`
    <div class="flex items-center gap-1.5 h-5" data-test-g-server-status-icon {{mergeClasses}} ...attributes>
      {{#if this.platform}}
        <div
          data-test-status-icon
          data-test-status={{this.state}}
          title={{this.state}}
          class="border-[1.5px] h-full aspect-square rounded-full {{this.colorClasses}}"
        ></div>
        {{#if this.showStatus}}
          <span class="{{this.textColorClasses}}" data-test-g-server-status-icon-status>{{this.statusString}}</span>
        {{/if}}
      {{else}}
        <IconsGenericServer class="size-5" />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
