import { template as template_859c7528411145c5a3cd9c618c5af8e7 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import md5 from "md5";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { number, string, bool } from "prop-types";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
export default @forbidExtraArgs
class GravatarImage extends Component {
    @arg(string)
    email;
    @arg(bool)
    dark;
    @arg(string)
    fallback = "blank";
    @arg(number)
    size = 32;
    get src() {
        return `//www.gravatar.com/avatar/${md5(this.email || "")}?d=${encodeURIComponent(this.fallback)}&s=${this.size}`;
    }
    get fallbackStyle() {
        if (this.fallback !== "blank") {
            return "";
        }
        if (this.dark) {
            return `bg-[url('/assets/images/user-dark.svg')]`;
        }
        return `bg-[url('/assets/images/user.svg')]`;
    }
    static{
        template_859c7528411145c5a3cd9c618c5af8e7(`
    <img
      class="{{this.fallbackStyle}} bg-contain bg-no-repeat"
      src="{{this.src}}"
      height={{this.size}}
      width={{this.size}}
      data-test-gravatar-image={{this.email}}
      {{mergeClasses}}
      ...attributes
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
