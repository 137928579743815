import { template as template_faa7cd4c89df41ea9918acde736209d8 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool, oneOf } from "prop-types";
import gButtonColors, { colors } from "glesys-controlpanel/helpers/g-button-color";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import Content from "./content";
export default @forbidExtraArgs
class extends Component {
    @arg(oneOf([
        "button",
        "submit"
    ]))
    type = "button";
    @arg(bool)
    disabled = false;
    @arg(bool)
    loading = false;
    @arg(oneOf(colors))
    color;
    @arg(oneOf([
        null,
        "default",
        "compact"
    ]))
    display;
    get stateClasses() {
        return this.disabled ? "cursor-not-allowed pointer-events-none" : "cursor-pointer";
    }
    get isDisabled() {
        return this.disabled || this.loading;
    }
    get isCompact() {
        return this.display === "compact";
    }
    static{
        template_faa7cd4c89df41ea9918acde736209d8(`
    <button
      type={{this.type}}
      disabled={{this.isDisabled}}
      class="flex items-center relative rounded text-sm
        {{if this.isCompact 'px-2 py-1' 'px-4 py-2'}}
        {{this.stateClasses}}
        {{gButtonColors this.color}}
        disabled:opacity-60 transition-all duration-200"
      {{mergeClasses}}
      ...attributes
    >
      <Content @loading={{this.loading}} @color={{this.color}}>
        {{yield}}
      </Content>
    </button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
