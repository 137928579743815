import Controller from "@ember/controller";
import { all, dropTask, task } from "ember-concurrency";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";

export default class ArchiveController extends Controller {
  @service router;
  @service http;
  @service store;

  get archive() {
    return this.model.archive;
  }

  get archiveArguments() {
    return this.model.archiveArguments;
  }

  @tracked description;
  @tracked sizeSelection;

  get hasChangedConfiguration() {
    return this.description !== this.archive.description || this.sizeSelection !== this.archive.sizetotal;
  }

  saveConfigurationTask = dropTask(async () => {
    let archive = this.archive;

    let requests = [];

    if (archive.description != this.description) {
      requests.push(
        this.http.request({
          url: "/io.php/archive/changedescription",
          method: "POST",
          body: {
            username: archive.id,
            description: this.description,
          },
        }),
      );
    }

    if (archive.size != this.sizeSelection) {
      requests.push(
        this.http.request({
          url: "/io.php/archive/resize",
          method: "POST",
          body: {
            username: archive.id,
            size: this.sizeSelection,
            projectkey: this.archive.project.id,
          },
        }),
      );
    }

    let responses = await all(requests);

    this.store.pushPayload("archive", { archive: responses.at(-1).content.details });
  });

  changePasswordTask = dropTask(async (password) => {
    await this.http.request({
      url: "/io.php/archive/changepassword",
      method: "POST",
      body: {
        username: this.archive.id,
        password,
      },
    });
  });

  deleteArchive = task(async () => {
    await this.archive.destroyRecord();
    this.router.transitionTo("archives");
  });

  @action
  onFormChange({ description }) {
    this.description = description;
  }

  @action
  selectSize(size) {
    this.sizeSelection = size.value;
  }

  get sizeOptions() {
    return this.archiveArguments.size
      .filter((size) => size >= this.archive.sizetotal)
      .map((size) => ({ label: `${size} GB`, value: size }));
  }

  resetProperties() {
    let archive = this.archive;
    this.description = archive.description;
    this.sizeSelection = archive.sizetotal;
  }
}
