import { template as template_d165401000aa46a3955ff197194b0efc } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsLoadingSpinner from "../icons/loading-spinner";
export default template_d165401000aa46a3955ff197194b0efc(`
  <span class={{if @loading "opacity-0"}} {{mergeClasses}} ...attributes>{{yield}}</span>
  {{#if @loading}}
    <div
      class="absolute bottom-0 flex items-center justify-center left-0 right-0 top-0"
      data-test-g-button-loading-spinner
    >
      <IconsLoadingSpinner @color={{@color}} class="w-6 -ml-1 -mt-0.5" />
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
