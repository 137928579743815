/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";

export default class ModalsEmailAliasEdit extends Component {
  @service modal;

  get alias() {
    return this.args.params.alias;
  }

  get task() {
    return this.args.params.task;
  }

  get addButtonIsDisabled() {
    return this.newRecipient.length === 0;
  }

  @tracked recipients = [];
  @tracked newRecipient = "";

  constructor() {
    super(...arguments);

    this.recipients = this.alias.recipients.map((recipient) => ({
      email: recipient,
      willBeRemoved: false,
    }));
  }

  addRecipient() {
    if (!this.recipients.find((recipient) => recipient.email === this.newRecipient)) {
      this.recipients = [
        ...this.recipients,
        {
          email: this.newRecipient,
          willBeRemoved: false,
        },
      ];
    }

    this.newRecipient = "";
  }

  addRecipientTask = task(async () => {
    this.addRecipient();
  });

  submitTask = task(async () => {
    let { alias, task } = this;
    let goto = this.recipients
      .filter((recipient) => !recipient.willBeRemoved)
      .map((recipient) => recipient.email)
      .join(",");

    alias.set("goto", goto);
    await task.perform(alias);

    this.modal.close();
  });

  onFormChange = ({ newRecipient }) => (this.newRecipient = newRecipient);

  @action
  removeRecipient(recipient) {
    this.recipients = this.recipients.map((item) => {
      if (item.email === recipient.email) {
        return {
          ...item,
          willBeRemoved: true,
        };
      }
      return item;
    });
  }

  @action
  undoRemoveRecipient(recipient) {
    this.recipients = this.recipients.map((item) => {
      if (item.email === recipient.email) {
        return {
          ...item,
          willBeRemoved: false,
        };
      }
      return item;
    });
  }
}
