import { template as template_74cfe783ad934a7eaf4296927c254176 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool, instanceOf } from "prop-types";
import { service } from "@ember/service";
import ShipmentModel from "glesys-controlpanel/models/shipment";
import dayjs from "dayjs";
import Box from "glesys-controlpanel/components/box";
import EntityTable from "glesys-controlpanel/components/entity/table";
import Pill from "glesys-controlpanel/components/pill";
import PillInfo from "glesys-controlpanel/components/pill/info";
import PillSuccess from "glesys-controlpanel/components/pill/success";
import PillError from "glesys-controlpanel/components/pill/error";
import IconsPackage from "glesys-controlpanel/components/icons/package";
import EntityRemoteHandsRequest from "glesys-controlpanel/components/entity/remote-hands-request";
import Label from "glesys-controlpanel/components/label";
import { t } from "ember-intl";
import { and } from "ember-truth-helpers";
export default @forbidExtraArgs
class EntityShipment extends Component {
    @service
    intl;
    @arg(instanceOf(ShipmentModel))
    entity;
    @arg(bool)
    includeRemoteHandsRequest = true;
    get shipment() {
        return this.entity;
    }
    get tableData() {
        return [
            {
                header: this.intl.t("label.tracking-id"),
                value: this.shipment.trackingid
            },
            {
                header: this.intl.t("label.data-center"),
                value: this.shipment.datacenter
            },
            {
                header: this.intl.t("label.created"),
                value: dayjs(this.shipment.createdat).format("YYYY-MM-DD HH:mm")
            }
        ];
    }
    get isPending() {
        return this.shipment.status?.toLowerCase() === "pending";
    }
    get isReceived() {
        return this.shipment.status?.toLowerCase() === "received";
    }
    get isDelivered() {
        return this.shipment.status?.toLowerCase() === "delivered";
    }
    get isCanceled() {
        return this.shipment.status?.toLowerCase() === "canceled";
    }
    static{
        template_74cfe783ad934a7eaf4296927c254176(`
    <Box @collapsable={{true}} data-test-shipment={{this.shipment.id}} ...attributes>
      <:header>
        <div class="flex items-center gap-4">
          <div class="p-2 border rounded border-gray-light">
            <IconsPackage class="size-4" />
          </div>
          {{this.shipment.id}}
          {{#if this.isPending}}
            <Pill @text={{this.shipment.status}} data-test-shipment-status-pending />
          {{/if}}

          {{#if this.isReceived}}
            <PillInfo @text={{this.shipment.status}} data-test-shipment-status-received />
          {{/if}}

          {{#if this.isDelivered}}
            <PillSuccess @text={{this.shipment.status}} data-test-shipment-status-delivered />
          {{/if}}

          {{#if this.isCanceled}}
            <PillError @text={{this.shipment.status}} data-test-shipment-status-canceled />
          {{/if}}
        </div>
      </:header>
      <:default>
        <EntityTable @entity={{this.tableData}} />

        {{#if (and this.includeRemoteHandsRequest this.shipment.remoteHandsRequest)}}
          <Label class="pt-6">{{t "label.remote-hands-request"}}</Label>
          <EntityRemoteHandsRequest @entity={{this.shipment.remoteHandsRequest}} @includeShipments={{false}} />
        {{/if}}
      </:default>
    </Box>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
