import Controller from "@ember/controller";
import { task, all } from "ember-concurrency";
import { service } from "@ember/service";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { waitForPromise } from "@ember/test-waiters";
import { SilentError } from "glesys-controlpanel/services/error-handler";

export default class extends Controller {
  @service store;
  @service ajax;
  @service session;
  @service notification;
  @service metrics;
  @service router;

  @tracked dataCenter = "Falkenberg";
  @tracked host;
  @tracked domain;
  @tracked email = this.session.get("currentUser").id;
  @tracked username = this.session.get("currentUser").defaultServerUsername;
  @tracked password;
  @tracked sshKey;
  @tracked apiKey;

  get project() {
    return this.model.project;
  }

  get server() {
    return this.submit.last.value;
  }

  dataCenters = [{ value: "Falkenberg" }, { value: "Stockholm" }];

  get canSubmit() {
    return this.host && this.domain && this.email && this.username && (this.sshKey || this.password);
  }

  get applianceConfig() {
    return {
      cpucores: 4,
      disksize: 20,
      memorysize: 8192,
      bandwidth: 100,
    };
  }

  @action
  onUserChange(username, password, sshkey) {
    this.username = username;
    this.password = password;
    this.sshKey = sshkey;
  }

  @action
  selectDataCenter(dataCenter) {
    this.dataCenter = dataCenter;
  }

  @action
  hostnameUpdated(host, domain) {
    this.host = host;
    this.domain = domain;
  }

  resetProperties() {
    this.password = "";
    this.sshKey = "";
    this.email = this.session.get("currentUser").id;
  }

  getAvailableIps() {
    return all(
      [4, 6].map((ipversion) => {
        return this.store.query("available-ip", {
          projectkey: this.project.id,
          platform: "KVM",
          datacenter: this.dataCenter,
          ipversion,
        });
      }),
    );
  }

  getRandomIp(availableIps, type) {
    let ips = type == "ipv4" ? availableIps[0] : availableIps[1];
    let random = Math.floor(Math.random() * ips.length);

    return ips.at(random);
  }

  createDnsRecord(ipv4, host) {
    return this.store.createRecord("dns-record", {
      type: "A",
      domain: this.domain,
      host: host,
      rdata: ipv4,
      domainname: this.domain.id,
    });
  }

  async fetchCloudConfig() {
    let response = await waitForPromise(
      fetch("https://raw.githubusercontent.com/glesys/one-click-installers/master/gitlab/cloud-config.yaml"),
    );

    if (!response.ok) {
      throw new SilentError("Could not fetch cloudconfig");
    }

    return response.text();
  }

  submit = task(async () => {
    let user = { username: this.username };
    this.sshKey && (user.sshKeys = [this.sshKey]);
    this.password && (user.password = this.password);

    let hostname = [this.host, this.domain.id].filter((x) => x).join(".");

    let cloudconfig = await this.fetchCloudConfig();

    let data = {
      project: this.project,
      datacenter: this.dataCenter,
      platform: "KVM",
      hostname: hostname,
      templatename: "ubuntu-20-04",
      backup: {
        schedules: [],
      },
      cloudconfig,
      cloudconfigparams: {
        hostname: hostname,
      },
      users: [user],
      ...this.applianceConfig,
    };

    // get available IPs
    let availableIps = await this.getAvailableIps();

    // get random IPs from available
    let ipv4 = await this.getRandomIp(availableIps, "ipv4");
    data.ipv4 = ipv4.address;

    let ipv6 = await this.getRandomIp(availableIps, "ipv6");
    data.ipv6 = ipv6.address;

    let host = this.host;

    let records = [await this.createDnsRecord(data.ipv4, host)];

    let server = this.store.createRecord("server", data);

    try {
      for (const record of records) {
        await record.save();
      }
      await server.save();

      // set PTR on ip addresses
      let reservedIps = this.project.get("reservedIps").reload();
      reservedIps.forEach((ip) => {
        if (ip.address == data.ipv4 || ip.address == data.ipv6) {
          ip.set("ptr", this.host);
          ip.save().catch(() => ip.rollbackAttributes());
        }
      });

      this.metrics.trackEvent("Intercom", { event: "created-gitlab-server" });
      this.router.replaceWith("servers.appliance.creating", server.id, "gitlab");
    } catch (error) {
      for (const record of records) {
        await record.destroyRecord();
      }
      await server.deleteRecord();
      this.notification.add("error", error.errors[0].title, error.errors[0].detail);
    }
  });
}
