import { template as template_8eee667abec846e58d061bf9c2ebd190 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool, instanceOf } from "prop-types";
import RemoteHandsModel from "glesys-controlpanel/models/remote-hands-request";
import dayjs from "dayjs";
import Box from "glesys-controlpanel/components/box";
import EntityTable from "glesys-controlpanel/components/entity/table";
import Pill from "glesys-controlpanel/components/pill";
import PillInfo from "glesys-controlpanel/components/pill/info";
import PillSuccess from "glesys-controlpanel/components/pill/success";
import PillError from "glesys-controlpanel/components/pill/error";
import IconsRemoteHands from "glesys-controlpanel/components/icons/remote-hands";
import { service } from "@ember/service";
import EntityShipment from "glesys-controlpanel/components/entity/shipment";
import Label from "glesys-controlpanel/components/label";
import { t } from "ember-intl";
import { and } from "ember-truth-helpers";
export default @forbidExtraArgs
class EntityRemoteHandsRequest extends Component {
    @service
    intl;
    @arg(instanceOf(RemoteHandsModel))
    entity;
    @arg(bool)
    includeShipments = true;
    get remoteHands() {
        return this.entity;
    }
    get tableData() {
        return [
            {
                header: this.intl.t("label.instructions"),
                value: this.remoteHands.instructions
            },
            {
                header: this.intl.t("label.services"),
                value: this.remoteHands.servicekeys.join(", ")
            },
            {
                header: this.intl.t("label.execution-option"),
                value: this.remoteHands.executionoption
            },
            {
                header: this.intl.t("label.created"),
                value: dayjs(this.remoteHands.createdat).format("YYYY-MM-DD HH:mm")
            }
        ];
    }
    get readableStatus() {
        return this.remoteHands.status.replace(/_/g, " ");
    }
    get isPending() {
        return this.remoteHands.status?.toLowerCase() === "pending";
    }
    get isInProgress() {
        return this.remoteHands.status?.toLowerCase() === "in_progress";
    }
    get isCompleted() {
        return this.remoteHands.status?.toLowerCase() === "completed";
    }
    get isCanceled() {
        return this.remoteHands.status?.toLowerCase() === "canceled";
    }
    static{
        template_8eee667abec846e58d061bf9c2ebd190(`
    <Box @collapsable={{true}} data-test-remote-hands-request={{this.remoteHands.id}} ...attributes>
      <:header>
        <div class="flex items-center gap-4">
          <div class="p-2 border rounded border-gray-light">
            <IconsRemoteHands class="size-4" />
          </div>
          {{this.remoteHands.id}}
          <div class="mb-1">
            {{#if this.isPending}}
              <Pill @text={{this.readableStatus}} data-test-remote-hands-request-status-pending />
            {{/if}}

            {{#if this.isInProgress}}
              <PillInfo @text={{this.readableStatus}} data-test-remote-hands-request-status-in-progress />
            {{/if}}

            {{#if this.isCompleted}}
              <PillSuccess @text={{this.readableStatus}} data-test-remote-hands-request-status-completed />
            {{/if}}

            {{#if this.isCanceled}}
              <PillError @text={{this.readableStatus}} data-test-remote-hands-request-status-canceled />
            {{/if}}
          </div>
        </div>
      </:header>
      <:default>
        <EntityTable @entity={{this.tableData}} />

        {{#if (and this.includeShipments this.remoteHands.shipments)}}
          <Label class="pt-6">{{t "label.shipments"}}</Label>
          <div class="flex flex-col gap-4">
            {{#each this.remoteHands.shipments as |shipment|}}
              <EntityShipment @entity={{shipment}} @includeRemoteHandsRequest={{false}} />
            {{/each}}
          </div>
        {{/if}}
      </:default>
    </Box>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
