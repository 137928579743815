import { service } from "@ember/service";
import Controller from "@ember/controller";
import { task, timeout } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import ENV from "glesys-controlpanel/config/environment";

export default class SupportChallengeController extends Controller {
  @service ajax;
  @service session;

  @tracked code = null;
  @tracked success = false;

  get currentUserEmail() {
    return this.session.currentUser.id;
  }

  get canInitiateSupportChallenge() {
    return !this.code && !this.success;
  }

  initateSupportChallenge = task(async () => {
    let result = await this.ajax.post("/io.php/user/initiatesupportchallenge");

    if (result.supportchallenge.code) {
      this.code = result.supportchallenge.code;
      this.fetchSupportChallengeStatus.perform();
    }
  });

  fetchSupportChallengeStatus = task(async () => {
    let challenge = await this.ajax.request("/io.php/user/supportchallenge");

    if (!challenge.supportchallenge) {
      this.code = null;
      return;
    }

    if (challenge.supportchallenge?.status == "answered") {
      this.success = true;
      return;
    }

    if (ENV.environment !== "test") {
      await timeout(5000);
      this.fetchSupportChallengeStatus.perform();
    }
  });

  resetProperties() {
    this.code = null;
    this.success = false;
  }
}
