/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { array, arrayOf, bool, func, instanceOf, number, object, oneOfType, shape } from "prop-types";
import { action } from "@ember/object";
import { Task, task } from "ember-concurrency";
import { modifier } from "ember-modifier";
import Pagination from "./pagination-dto";

@forbidExtraArgs
export default class Table extends Component {
  @arg(
    arrayOf(
      shape({
        title: stringable.isRequired,
        key: stringable,
      }),
    ),
  )
  headers;

  @arg(oneOfType([array, instanceOf(Task), object])) items;

  @arg(shape({ page: number, total: number, perPage: number.isRequired })) pagination;

  @arg(func) onPageChange;

  @arg(bool) fixed = false;

  @arg(bool) footer = true;

  @arg(bool) outsideBorders = true;

  paginationDto = new Pagination(
    this.pagination?.page || 1,
    this.pagination?.total || this.task?.last?.value?.length,
    this.pagination?.perPage || 10,
  );

  get shouldShowFooter() {
    return this.footer || this.paginationDto.isPaginated;
  }

  get currentItems() {
    return this.paginationDto?.itemsForPage(this.task?.last.value);
  }

  get task() {
    return this.items instanceof Task ? this.items : this.convertToTask;
  }

  convertToTask = task(async () => {
    return this.items;
  });

  updateItems = task(async (task, pagination) => {
    await task.perform();
    this.paginationDto.update(
      pagination?.page || 1,
      pagination?.total || task?.last.value?.length,
      pagination?.perPage,
    );
  });

  handleUpdateOfItems = modifier(() => this.updateItems.perform(this.task, this.pagination));

  @action
  pageChanged(page) {
    if (this.onPageChange) {
      return this.onPageChange(page);
    }

    this.paginationDto.page = page;
  }
}
