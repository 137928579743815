/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { instanceOf } from "prop-types";
import { Task, task } from "ember-concurrency";
import { action } from "@ember/object";

@forbidExtraArgs
export default class OrganizationDetails extends Component {
  @arg organization;

  @arg(instanceOf(Task).isRequired) task;

  organizationNumber = this.organization.id;
  organizationName = this.organization.name;
  organizationCountry = this.organization.country;
  organizationIdentityNumber = this.organization.identityNumber;
  organizationInvoiceReference = this.organization.invoiceReference;
  organizationEmail = this.organization.email;
  organizationPhone = this.organization.phone;
  organizationAddress = this.organization.address;
  organizationCity = this.organization.city;
  organizationZip = this.organization.zip;

  get organizationNamePlaceholder() {
    return `Organization ${this.organizationNumber}`;
  }

  @action
  onFormChange({
    organizationName,
    organizationIdentityNumber,
    organizationInvoiceReference,
    organizationEmail,
    organizationPhone,
    organizationAddress,
    organizationCity,
    organizationZip,
    separateInvoiceAddress,
    invoiceAddress,
    invoiceCity,
    invoiceZip,
  }) {
    this.organizationName = organizationName;
    this.organizationIdentityNumber = organizationIdentityNumber;
    this.organizationInvoiceReference = organizationInvoiceReference;
    this.organizationEmail = organizationEmail;
    this.organizationPhone = organizationPhone;

    this.organizationAddress = organizationAddress;
    this.organizationCity = organizationCity;
    this.organizationZip = organizationZip;

    this.organization.separateInvoiceAddress = Boolean(separateInvoiceAddress);
    this.organization.invoiceAddress = invoiceAddress;
    this.organization.invoiceCity = invoiceCity;
    this.organization.invoiceZip = invoiceZip;
  }

  submitTask = task(async () => {
    await this.task.perform({
      name: this.organizationName,
      identityNumber: this.organizationIdentityNumber,
      invoiceReference: this.organizationInvoiceReference,
      email: this.organizationEmail,
      phone: this.organizationPhone,
      address: this.organizationAddress,
      city: this.organizationCity,
      zip: this.organizationZip,
    });
  });
}
