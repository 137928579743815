/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";

export default class SidebarOrganizationProjectSwitcher extends Component {
  @service router;
  @service intl;
  @service abilities;

  @tracked isOpen = false;

  get organization() {
    return this.args.organization;
  }

  get projects() {
    let projectList = [
      {
        groupName: "",
        items: this.organization
          .hasMany("projects")
          .value()
          .slice()
          .sort((a, b) => a.locked - b.locked || a.displayName.localeCompare(b.displayName))
          .map((project) => {
            project.disabled = this.abilities.cannot("access project", project);
            return project;
          }),
      },
    ];

    if (this.abilities.can("create project for organization", this.organization)) {
      projectList.push({
        groupName: "",
        items: [
          {
            id: "create-new-project",
            displayName: this.intl.t("button.create-project"),
          },
        ],
      });
    }

    return projectList;
  }

  get currentProject() {
    return this.args.currentProject;
  }

  @action
  toggle() {
    this.isOpen = !this.isOpen;
  }

  @action
  close() {
    this.isOpen = false;
  }

  @action
  projectChanged(item) {
    item.id === "create-new-project" ? this.router.transitionTo("projects.create") : this.args.projectChanged(item);

    this.close();
  }
}
