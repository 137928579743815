import ApplicationAdapter from "./application";

export default class DnsDomainAdapter extends ApplicationAdapter {
  urlForFindRecord(id) {
    return `${this.baseUri()}/domain/details?domainname=${id}`;
  }

  urlForCreateRecord(_, snapshot) {
    return `${this.baseUri()}/domain/add?context=${snapshot.belongsTo("project").id}`;
  }

  urlForUpdateRecord(id) {
    return `${this.baseUri()}/domain/edit?domainname=${id}`;
  }

  urlForDeleteRecord(id) {
    return `${this.baseUri()}/domain/delete?domainname=${id}`;
  }
}
