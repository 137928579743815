import { hash } from "rsvp";
import { service } from "@ember/service";
import Route from "@ember/routing/route";

export default class ServerDetailsRoute extends Route {
  @service ajax;

  model() {
    let { project, server } = this.modelFor("server");

    return hash({
      project,
      costs: server.isKVM ? null : server.get("costs"),
      networks: project.get("networks"),
      server,
    });
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    let { server } = model;

    let editableDisk = server.get("disksize");
    let editableMemory = server.get("memorysize");
    let editableCpu = server.get("cpucores");

    controller.setProperties({
      editableDisk,
      editableMemory,
      editableCpu,
      keepReservedIps: true,
    });
  }
}
