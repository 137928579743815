import { template as template_e6492a837ef64984bbaec8bc44dfab79 } from "@ember/template-compiler";
export default template_e6492a837ef64984bbaec8bc44dfab79(`
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" ...attributes>
    <path
      d="M4.08621 4.01293H14L12.5886 10.0113C12.4053 10.7901 11.7104 11.3405 10.9103 11.3405H7.35302C6.57069 11.3405 5.88644 10.8138 5.68626 10.0576L4.08621 4.01293ZM4.08621 4.01293L3.43966 1.42673H1.5"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.02613 14.5733C6.50224 14.5733 6.8882 14.1873 6.8882 13.7112C6.8882 13.2351 6.50224 12.8491 6.02613 12.8491C5.55002 12.8491 5.16406 13.2351 5.16406 13.7112C5.16406 14.1873 5.55002 14.5733 6.02613 14.5733Z"
      fill="currentColor"
    />
    <path
      d="M12.0603 14.5733C12.5364 14.5733 12.9224 14.1873 12.9224 13.7112C12.9224 13.2351 12.5364 12.8491 12.0603 12.8491C11.5842 12.8491 11.1982 13.2351 11.1982 13.7112C11.1982 14.1873 11.5842 14.5733 12.0603 14.5733Z"
      fill="currentColor"
    />
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
