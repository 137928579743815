import Model, { attr, belongsTo } from "@ember-data/model";

export default class ShipmentModel extends Model {
  @attr trackingid;
  @attr datacenter;
  @attr status;
  @attr createdat;

  @belongsTo("remote-hands-request", { async: false, inverse: "shipments" }) remoteHandsRequest;
  @belongsTo("project", { async: false, inverse: "shipments" }) project;
}
