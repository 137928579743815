/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { dropTask } from "ember-concurrency";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class ModalsIpChangePtr extends Component {
  @service modal;

  get task() {
    return this.args.params.task;
  }

  get currentPtr() {
    return this.args.params.currentPtr;
  }

  @tracked ptr = this.currentPtr;

  get isValidPtr() {
    return (`${this.ptr}`.endsWith(".") && `${this.ptr}`.length > 1) || `${this.ptr}`.length == 0;
  }

  get isDisabled() {
    return !this.isValidPtr;
  }

  onFormChange = ({ ptr }) => (this.ptr = ptr);

  submitTask = dropTask(async () => {
    await this.task.perform(this.ptr);
    this.modal.close();
  });
}
