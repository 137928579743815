import { getOwner } from '@ember/application';
import { recordIdentifierFor } from '@ember-data/store';
import { _deprecatingNormalize } from '@ember-data/store/-private';
import { p as payloadIsNotBlank, n as normalizeResponseHelper, i as iterateData, F as FetchManager, S as SaveOp, a as assertIdentifierHasId, b as SnapshotRecordArray } from "./-private-Dlia0pw1.js";
import { macroCondition, getGlobalConfig, importSync } from '@embroider/macros';
function _findHasMany(adapter, store, identifier, link, relationship, options) {
  const promise = Promise.resolve().then(() => {
    const snapshot = store._fetchManager.createSnapshot(identifier, options);
    const useLink = !link || typeof link === 'string';
    const relatedLink = useLink ? link : link.href;
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Attempted to load a hasMany relationship from a specified 'link' in the original payload, but the specified link is empty. You must provide a valid 'link' in the original payload to use 'findHasMany'`);
      }
    })(relatedLink) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected the adapter to implement 'findHasMany' but it does not`);
      }
    })(typeof adapter.findHasMany === 'function') : {};
    return adapter.findHasMany(store, snapshot, relatedLink, relationship);
  });
  return promise.then(adapterPayload => {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You made a 'findHasMany' request for a ${identifier.type}'s '${relationship.name}' relationship, using link '${JSON.stringify(link)}' , but the adapter's response did not have any data`);
      }
    })(payloadIsNotBlank(adapterPayload)) : {};
    const modelClass = store.modelFor(relationship.type);
    const serializer = store.serializerFor(relationship.type);
    let payload = normalizeResponseHelper(serializer, store, modelClass, adapterPayload, null, 'findHasMany');
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`fetched the hasMany relationship '${relationship.name}' for ${identifier.type}:${identifier.id} with link '${JSON.stringify(link)}', but no data member is present in the response. If no data exists, the response should set { data: [] }`);
      }
    })('data' in payload && Array.isArray(payload.data)) : {};
    payload = syncRelationshipDataFromLink(store, payload, identifier, relationship);
    return store._push(payload, true);
  }, null);
}
function _findBelongsTo(store, identifier, link, relationship, options) {
  const promise = Promise.resolve().then(() => {
    const adapter = store.adapterFor(identifier.type);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You tried to load a belongsTo relationship but you have no adapter (for ${identifier.type})`);
      }
    })(adapter) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You tried to load a belongsTo relationship from a specified 'link' in the original payload but your adapter does not implement 'findBelongsTo'`);
      }
    })(typeof adapter.findBelongsTo === 'function') : {};
    const snapshot = store._fetchManager.createSnapshot(identifier, options);
    const useLink = !link || typeof link === 'string';
    const relatedLink = useLink ? link : link.href;
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Attempted to load a belongsTo relationship from a specified 'link' in the original payload, but the specified link is empty. You must provide a valid 'link' in the original payload to use 'findBelongsTo'`);
      }
    })(relatedLink) : {};
    return adapter.findBelongsTo(store, snapshot, relatedLink, relationship);
  });
  return promise.then(adapterPayload => {
    const modelClass = store.modelFor(relationship.type);
    const serializer = store.serializerFor(relationship.type);
    let payload = normalizeResponseHelper(serializer, store, modelClass, adapterPayload, null, 'findBelongsTo');
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`fetched the belongsTo relationship '${relationship.name}' for ${identifier.type}:${identifier.id} with link '${JSON.stringify(link)}', but no data member is present in the response. If no data exists, the response should set { data: null }`);
      }
    })('data' in payload && (payload.data === null || typeof payload.data === 'object' && !Array.isArray(payload.data))) : {};
    if (!payload.data && !payload.links && !payload.meta) {
      return null;
    }
    payload = syncRelationshipDataFromLink(store, payload, identifier, relationship);
    return store._push(payload, true);
  }, null);
}

// sync
// iterate over records in payload.data
// for each record
//   assert that record.relationships[inverse] is either undefined (so we can fix it)
//     or provide a data: {id, type} that matches the record that requested it
//   return the relationship data for the parent
function syncRelationshipDataFromLink(store, payload, parentIdentifier, relationship) {
  // ensure the right hand side (incoming payload) points to the parent record that
  // requested this relationship
  const relationshipData = payload.data ? iterateData(payload.data, (data, index) => {
    const {
      id,
      type
    } = data;
    ensureRelationshipIsSetToParent(data, parentIdentifier, store, relationship, index);
    return {
      id,
      type
    };
  }) : null;
  const relatedDataHash = {};
  if ('meta' in payload) {
    relatedDataHash.meta = payload.meta;
  }
  if ('links' in payload) {
    relatedDataHash.links = payload.links;
  }
  if ('data' in payload) {
    relatedDataHash.data = relationshipData;
  }

  // now, push the left hand side (the parent record) to ensure things are in sync, since
  // the payload will be pushed with store._push
  const parentPayload = {
    id: parentIdentifier.id,
    type: parentIdentifier.type,
    relationships: {
      [relationship.name]: relatedDataHash
    }
  };
  if (!Array.isArray(payload.included)) {
    payload.included = [];
  }
  payload.included.push(parentPayload);
  return payload;
}
function ensureRelationshipIsSetToParent(payload, parentIdentifier, store, parentRelationship, index) {
  const {
    id,
    type
  } = payload;
  if (!payload.relationships) {
    payload.relationships = {};
  }
  const {
    relationships
  } = payload;
  const inverse = getInverse(store, parentIdentifier, parentRelationship, type);
  if (inverse) {
    const {
      inverseKey,
      kind
    } = inverse;
    const relationshipData = relationships[inverseKey]?.data;
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      if (typeof relationshipData !== 'undefined' && !relationshipDataPointsToParent(relationshipData, parentIdentifier)) {
        const inspect = function inspect(thing) {
          return `'${JSON.stringify(thing)}'`;
        };
        const quotedType = inspect(type);
        const quotedInverse = inspect(inverseKey);
        const expected = inspect({
          id: parentIdentifier.id,
          type: parentIdentifier.type
        });
        const expectedModel = `${parentIdentifier.type}:${parentIdentifier.id}`;
        const got = inspect(relationshipData);
        const prefix = typeof index === 'number' ? `data[${index}]` : `data`;
        const path = `${prefix}.relationships.${inverseKey}.data`;
        const data = Array.isArray(relationshipData) ? relationshipData[0] : relationshipData;
        const other = data ? `<${data.type}:${data.id}>` : null;
        const relationshipFetched = `${expectedModel}.${parentRelationship.kind}("${parentRelationship.name}")`;
        const includedRecord = `<${type}:${id}>`;
        const message = [`Encountered mismatched relationship: Ember Data expected ${path} in the payload from ${relationshipFetched} to include ${expected} but got ${got} instead.\n`, `The ${includedRecord} record loaded at ${prefix} in the payload specified ${other} as its ${quotedInverse}, but should have specified ${expectedModel} (the record the relationship is being loaded from) as its ${quotedInverse} instead.`, `This could mean that the response for ${relationshipFetched} may have accidentally returned ${quotedType} records that aren't related to ${expectedModel} and could be related to a different ${parentIdentifier.type} record instead.`, `Ember Data has corrected the ${includedRecord} record's ${quotedInverse} relationship to ${expectedModel} so that ${relationshipFetched} will include ${includedRecord}.`, `Please update the response from the server or change your serializer to either ensure that the response for only includes ${quotedType} records that specify ${expectedModel} as their ${quotedInverse}, or omit the ${quotedInverse} relationship from the response.`].join('\n');
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          {
            throw new Error(message);
          }
        })() : {};
      }
    }
    if (kind !== 'hasMany' || typeof relationshipData !== 'undefined') {
      relationships[inverseKey] = relationships[inverseKey] || {};
      relationships[inverseKey].data = fixRelationshipData(relationshipData ?? null, kind, parentIdentifier);
    }
  }
}
function inverseForRelationship(store, identifier, key) {
  const definition = store.schema.fields(identifier).get(key);
  if (!definition) {
    return null;
  }
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected the field definition to be a relationship`);
    }
  })(definition.kind === 'hasMany' || definition.kind === 'belongsTo') : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected the relationship defintion to specify the inverse type or null.`);
    }
  })(definition.options?.inverse === null || typeof definition.options?.inverse === 'string' && definition.options.inverse.length > 0) : {};
  return definition.options.inverse;
}
function getInverse(store, parentIdentifier, parentRelationship, type) {
  const {
    name: lhs_relationshipName
  } = parentRelationship;
  const {
    type: parentType
  } = parentIdentifier;
  const inverseKey = inverseForRelationship(store, {
    type: parentType
  }, lhs_relationshipName);
  if (inverseKey) {
    const definition = store.schema.fields({
      type
    }).get(inverseKey);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected the field definition to be a relationship`);
      }
    })(definition && (definition.kind === 'hasMany' || definition.kind === 'belongsTo')) : {};
    return {
      inverseKey,
      kind: definition.kind
    };
  }
}
function relationshipDataPointsToParent(relationshipData, identifier) {
  if (relationshipData === null) {
    return false;
  }
  if (Array.isArray(relationshipData)) {
    if (relationshipData.length === 0) {
      return false;
    }
    for (let i = 0; i < relationshipData.length; i++) {
      const entry = relationshipData[i];
      if (validateRelationshipEntry(entry, identifier)) {
        return true;
      }
    }
  } else {
    return validateRelationshipEntry(relationshipData, identifier);
  }
  return false;
}
function fixRelationshipData(relationshipData, relationshipKind, {
  id,
  type
}) {
  const parentRelationshipData = {
    id,
    type
  };
  let payload = null;
  if (relationshipKind === 'hasMany') {
    const relData = relationshipData || [];
    if (relationshipData) {
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error('expected the relationship data to be an array');
        }
      })(Array.isArray(relationshipData)) : {};
      // these arrays could be massive so this is better than filter
      // Note: this is potentially problematic if type/id are not in the
      // same state of normalization.
      const found = relationshipData.find(v => {
        return v.type === parentRelationshipData.type && v.id === parentRelationshipData.id;
      });
      if (!found) {
        relData.push(parentRelationshipData);
      }
    } else {
      relData.push(parentRelationshipData);
    }
    payload = relData;
  } else {
    const relData = relationshipData || {};
    Object.assign(relData, parentRelationshipData);
    payload = relData;
  }
  return payload;
}
function validateRelationshipEntry({
  id
}, {
  id: parentModelID
}) {
  return !!id && id.toString() === parentModelID;
}
const PotentialLegacyOperations = new Set(['findRecord', 'findAll', 'query', 'queryRecord', 'findBelongsTo', 'findHasMany', 'updateRecord', 'createRecord', 'deleteRecord']);
const LegacyNetworkHandler = {
  request(context, next) {
    // if we are not a legacy request, move on
    if (context.request.url || !context.request.op || !PotentialLegacyOperations.has(context.request.op)) {
      return next(context.request);
    }
    const {
      store
    } = context.request;
    if (!store._fetchManager) {
      store._fetchManager = new FetchManager(store);
    }
    switch (context.request.op) {
      case 'findRecord':
        return findRecord(context);
      case 'findAll':
        return findAll(context);
      case 'query':
        return query(context);
      case 'queryRecord':
        return queryRecord(context);
      case 'findBelongsTo':
        return findBelongsTo(context);
      case 'findHasMany':
        return findHasMany(context);
      case 'updateRecord':
        return saveRecord(context);
      case 'createRecord':
        return saveRecord(context);
      case 'deleteRecord':
        return saveRecord(context);
      default:
        return next(context.request);
    }
  }
};
function findBelongsTo(context) {
  const {
    store,
    data,
    records: identifiers
  } = context.request;
  const {
    options,
    record,
    links,
    useLink,
    field
  } = data;
  const identifier = identifiers?.[0];

  // short circuit if we are already loading
  const pendingRequest = identifier && store._fetchManager.getPendingFetch(identifier, options);
  if (pendingRequest) {
    return pendingRequest;
  }
  if (useLink) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected a related link when calling store.findBelongsTo, found ${String(links)}`);
      }
    })(links && links.related) : {};
    return _findBelongsTo(store, record, links.related, field, options);
  }
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected an identifier`);
    }
  })(Array.isArray(identifiers) && identifiers.length === 1) : {};
  const manager = store._fetchManager;
  assertIdentifierHasId(identifier);
  return options.reload ? manager.scheduleFetch(identifier, options, context.request) : manager.fetchDataIfNeededForIdentifier(identifier, options, context.request);
}
function findHasMany(context) {
  const {
    store,
    data,
    records: identifiers
  } = context.request;
  const {
    options,
    record,
    links,
    useLink,
    field
  } = data;

  // link case
  if (useLink) {
    const adapter = store.adapterFor(record.type);
    /*
    If a relationship was originally populated by the adapter as a link
    (as opposed to a list of IDs), this method is called when the
    relationship is fetched.
     The link (which is usually a URL) is passed through unchanged, so the
    adapter can make whatever request it wants.
     The usual use-case is for the server to register a URL as a link, and
    then use that URL in the future to make a request for the relationship.
    */
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You tried to load a hasMany relationship but you have no adapter (for ${record.type})`);
      }
    })(adapter) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You tried to load a hasMany relationship from a specified 'link' in the original payload but your adapter does not implement 'findHasMany'`);
      }
    })(typeof adapter.findHasMany === 'function') : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected a related link when calling store.findHasMany, found ${String(links)}`);
      }
    })(links && links.related) : {};
    return _findHasMany(adapter, store, record, links.related, field, options);
  }

  // identifiers case
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected an array of identifiers to fetch`);
    }
  })(Array.isArray(identifiers)) : {};
  const fetches = new Array(identifiers.length);
  const manager = store._fetchManager;
  for (let i = 0; i < identifiers.length; i++) {
    const identifier = identifiers[i];
    // TODO we probably can be lenient here and return from cache for the isNew case
    assertIdentifierHasId(identifier);
    fetches[i] = options.reload ? manager.scheduleFetch(identifier, options, context.request) : manager.fetchDataIfNeededForIdentifier(identifier, options, context.request);
  }
  return Promise.all(fetches);
}
function saveRecord(context) {
  const {
    store,
    data,
    op: operation
  } = context.request;
  const {
    options,
    record: identifier
  } = data;
  store.cache.willCommit(identifier, context);
  const saveOptions = Object.assign({
    [SaveOp]: operation
  }, options);
  const fetchManagerPromise = store._fetchManager.scheduleSave(identifier, saveOptions);
  return fetchManagerPromise.then(payload => {
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_PAYLOADS)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_PAYLOADS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_PAYLOADS) {
        try {
          const payloadCopy = payload ? JSON.parse(JSON.stringify(payload)) : payload;
          // eslint-disable-next-line no-console
          console.log(`EmberData | Payload - ${operation}`, payloadCopy);
        } catch {
          // eslint-disable-next-line no-console
          console.log(`EmberData | Payload - ${operation}`, payload);
        }
      }
    }
    let result;
    store._join(() => {
      // @ts-expect-error we don't have access to a response in legacy
      result = store.cache.didCommit(identifier, {
        request: context.request,
        content: payload
      });
    });

    // blatantly lie if we were a createRecord request
    // to give some semblance of cache-control to the
    // CachePolicy while legacy is still around
    if (store.lifetimes?.didRequest && operation === 'createRecord') {
      store.lifetimes.didRequest(context.request, {
        status: 201
      }, null, store);
    }
    return store.peekRecord(result.data);
  }).catch(e => {
    let err = e;
    if (!e) {
      err = new Error(`Unknown Error Occurred During Request`);
    } else if (typeof e === 'string') {
      err = new Error(e);
    }
    adapterDidInvalidate(store, identifier, err);
    throw err;
  });
}
function adapterDidInvalidate(store, identifier, error) {
  if (error && error.isAdapterError === true && error.code === 'InvalidError') {
    const serializer = store.serializerFor(identifier.type);

    // TODO @deprecate extractErrors being called
    // TODO remove extractErrors from the default serializers.
    if (serializer && typeof serializer.extractErrors === 'function') {
      const errorsHash = serializer.extractErrors(store, store.modelFor(identifier.type), error, identifier.id);
      error.errors = errorsHashToArray(errorsHash);
    }
  }
  const cache = store.cache;
  if (error.errors) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected the cache in use by resource ${String(identifier)} to have a getErrors(identifier) method for retrieving errors.`);
      }
    })(typeof cache.getErrors === 'function') : {};
    let jsonApiErrors = error.errors;
    if (jsonApiErrors.length === 0) {
      jsonApiErrors = [{
        title: 'Invalid Error',
        detail: '',
        source: {
          pointer: '/data'
        }
      }];
    }
    cache.commitWasRejected(identifier, jsonApiErrors);
  } else {
    cache.commitWasRejected(identifier);
  }
}
function makeArray(value) {
  return Array.isArray(value) ? value : [value];
}
const PRIMARY_ATTRIBUTE_KEY = 'base';
function errorsHashToArray(errors) {
  const out = [];
  if (errors) {
    Object.keys(errors).forEach(key => {
      const messages = makeArray(errors[key]);
      for (let i = 0; i < messages.length; i++) {
        let title = 'Invalid Attribute';
        let pointer = `/data/attributes/${key}`;
        if (key === PRIMARY_ATTRIBUTE_KEY) {
          title = 'Invalid Document';
          pointer = `/data`;
        }
        out.push({
          title: title,
          detail: messages[i],
          source: {
            pointer: pointer
          }
        });
      }
    });
  }
  return out;
}
function findRecord(context) {
  const {
    store,
    data
  } = context.request;
  const {
    record: identifier,
    options
  } = data;
  let promise;

  // if not loaded start loading
  if (!store._instanceCache.recordIsLoaded(identifier)) {
    promise = store._fetchManager.fetchDataIfNeededForIdentifier(identifier, options, context.request);

    // Refetch if the reload option is passed
  } else if (options.reload) {
    assertIdentifierHasId(identifier);
    promise = store._fetchManager.scheduleFetch(identifier, options, context.request);
  } else {
    let snapshot = null;
    const adapter = store.adapterFor(identifier.type);

    // Refetch the record if the adapter thinks the record is stale
    if (typeof options.reload === 'undefined' && adapter.shouldReloadRecord && adapter.shouldReloadRecord(store, snapshot = store._fetchManager.createSnapshot(identifier, options))) {
      assertIdentifierHasId(identifier);
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        promise = store._fetchManager.scheduleFetch(identifier, Object.assign({}, options, {
          reload: true
        }), context.request);
      } else {
        options.reload = true;
        promise = store._fetchManager.scheduleFetch(identifier, options, context.request);
      }
    } else {
      // Trigger the background refetch if backgroundReload option is passed
      if (options.backgroundReload !== false && (options.backgroundReload || !adapter.shouldBackgroundReloadRecord || adapter.shouldBackgroundReloadRecord(store, snapshot = snapshot || store._fetchManager.createSnapshot(identifier, options)))) {
        assertIdentifierHasId(identifier);
        if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
          void store._fetchManager.scheduleFetch(identifier, Object.assign({}, options, {
            backgroundReload: true
          }), context.request);
        } else {
          options.backgroundReload = true;
          void store._fetchManager.scheduleFetch(identifier, options, context.request);
        }
      }

      // Return the cached record
      promise = Promise.resolve(identifier);
    }
  }
  return promise.then(i => store.peekRecord(i));
}
function findAll(context) {
  const {
    store,
    data
  } = context.request;
  const {
    type,
    options
  } = data;
  const adapter = store.adapterFor(type);
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You tried to load all records but you have no adapter (for ${type})`);
    }
  })(adapter) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You tried to load all records but your adapter does not implement 'findAll'`);
    }
  })(typeof adapter.findAll === 'function') : {};

  // avoid initializing the liveArray just to set `isUpdating`
  const maybeRecordArray = store.recordArrayManager._live.get(type);
  const snapshotArray = new SnapshotRecordArray(store, type, options);
  const shouldReload = options.reload || options.reload !== false && (adapter.shouldReloadAll && adapter.shouldReloadAll(store, snapshotArray) || !adapter.shouldReloadAll && snapshotArray.length === 0);
  let fetch;
  if (shouldReload) {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    maybeRecordArray && (maybeRecordArray.isUpdating = true);
    fetch = _findAll(adapter, store, type, snapshotArray, context.request, true);
  } else {
    fetch = Promise.resolve(store.peekAll(type));
    if (options.backgroundReload || options.backgroundReload !== false && (!adapter.shouldBackgroundReloadAll || adapter.shouldBackgroundReloadAll(store, snapshotArray))) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      maybeRecordArray && (maybeRecordArray.isUpdating = true);
      void _findAll(adapter, store, type, snapshotArray, context.request, false);
    }
  }
  return fetch;
}
function _findAll(adapter, store, type, snapshotArray, request, isAsyncFlush) {
  const schema = store.modelFor(type);
  let promise = Promise.resolve().then(() => adapter.findAll(store, schema, null, snapshotArray));
  promise = promise.then(adapterPayload => {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You made a 'findAll' request for '${type}' records, but the adapter's response did not have any data`);
      }
    })(payloadIsNotBlank(adapterPayload)) : {};
    const serializer = store.serializerFor(type);
    const payload = normalizeResponseHelper(serializer, store, schema, adapterPayload, null, 'findAll');
    store._push(payload, isAsyncFlush);
    snapshotArray._recordArray.isUpdating = false;
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_PAYLOADS)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_PAYLOADS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_PAYLOADS) {
        // eslint-disable-next-line no-console
        console.log(`request: findAll<${type}> background reload complete`);
      }
    }
    return snapshotArray._recordArray;
  });
  if (macroCondition(getGlobalConfig().WarpDrive.env.TESTING)) {
    if (!request.disableTestWaiter) {
      const {
        waitForPromise
      } = importSync('@ember/test-waiters');
      promise = waitForPromise(promise);
    }
  }
  return promise;
}
function query(context) {
  const {
    store,
    data
  } = context.request;
  let {
    options
  } = data;
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const {
    type,
    query
  } = data;
  const adapter = store.adapterFor(type);
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You tried to make a query but you have no adapter (for ${type})`);
    }
  })(adapter) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You tried to make a query but your adapter does not implement 'query'`);
    }
  })(typeof adapter.query === 'function') : {};
  const recordArray = options._recordArray || store.recordArrayManager.createArray({
    type,
    query
  });
  if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
    options = Object.assign({}, options);
    delete options._recordArray;
  } else {
    delete options._recordArray;
  }
  const schema = store.modelFor(type);
  const promise = Promise.resolve().then(() => adapter.query(store, schema, query, recordArray, options));
  return promise.then(adapterPayload => {
    const serializer = store.serializerFor(type);
    const payload = normalizeResponseHelper(serializer, store, schema, adapterPayload, null, 'query');
    const identifiers = store._push(payload, true);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error('The response to store.query is expected to be an array but it was a single record. Please wrap your response in an array or use `store.queryRecord` to query for a single record.');
      }
    })(Array.isArray(identifiers)) : {};
    store.recordArrayManager.populateManagedArray(recordArray, identifiers, payload);
    return recordArray;
  });
}
function assertSingleResourceDocument(payload) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected the primary data returned by the serializer for a 'queryRecord' response to be a single object or null but instead it was an array.`);
    }
  })(!Array.isArray(payload.data)) : {};
}
function queryRecord(context) {
  const {
    store,
    data
  } = context.request;
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const {
    type,
    query,
    options
  } = data;
  const adapter = store.adapterFor(type);
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You tried to make a query but you have no adapter (for ${type})`);
    }
  })(adapter) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You tried to make a query but your adapter does not implement 'queryRecord'`);
    }
  })(typeof adapter.queryRecord === 'function') : {};
  const schema = store.modelFor(type);
  const promise = Promise.resolve().then(() => adapter.queryRecord(store, schema, query, options));
  return promise.then(adapterPayload => {
    const serializer = store.serializerFor(type);
    const payload = normalizeResponseHelper(serializer, store, schema, adapterPayload, null, 'queryRecord');
    assertSingleResourceDocument(payload);
    const identifier = store._push(payload, true);
    return identifier ? store.peekRecord(identifier) : null;
  });
}

/**
 * @module @ember-data/store
 * @class Store
 */

/**
    Returns an instance of the adapter for a given type. For
    example, `adapterFor('person')` will return an instance of
    the adapter located at `app/adapters/person.js`

    If no `person` adapter is found, this method will look
    for an `application` adapter (the default adapter for
    your entire application).

    @method adapterFor
    @public
    @param {String} modelName
    @return Adapter
  */

function adapterFor(modelName, _allowMissing) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Attempted to call store.adapterFor(), but the store instance has already been destroyed.`);
    }
  })(!(this.isDestroying || this.isDestroyed)) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You need to pass a model name to the store's adapterFor method`);
    }
  })(modelName) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Passing classes to store.adapterFor has been removed. Please pass a dasherized string instead of ${modelName}`);
    }
  })(typeof modelName === 'string') : {};
  this._adapterCache = this._adapterCache || Object.create(null);
  const normalizedModelName = _deprecatingNormalize(modelName);
  const {
    _adapterCache
  } = this;
  let adapter = _adapterCache[normalizedModelName];
  if (adapter) {
    return adapter;
  }
  const owner = getOwner(this);

  // name specific adapter
  adapter = owner.lookup(`adapter:${normalizedModelName}`);
  if (adapter !== undefined) {
    _adapterCache[normalizedModelName] = adapter;
    return adapter;
  }

  // no adapter found for the specific name, fallback and check for application adapter
  adapter = _adapterCache.application || owner.lookup('adapter:application');
  if (adapter !== undefined) {
    _adapterCache[normalizedModelName] = adapter;
    _adapterCache.application = adapter;
    return adapter;
  }
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`No adapter was found for '${modelName}' and no 'application' adapter was found as a fallback.`);
    }
  })(_allowMissing) : {};
}

/**
    Returns an instance of the serializer for a given type. For
    example, `serializerFor('person')` will return an instance of
    `App.PersonSerializer`.

    If no `App.PersonSerializer` is found, this method will look
    for an `App.ApplicationSerializer` (the default serializer for
    your entire application).

    If a serializer cannot be found on the adapter, it will fall back
    to an instance of `JSONSerializer`.

    @method serializerFor
    @public
    @param {String} modelName the record to serialize
    @return {Serializer}
  */
function serializerFor(modelName) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Attempted to call store.serializerFor(), but the store instance has already been destroyed.`);
    }
  })(!(this.isDestroying || this.isDestroyed)) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You need to pass a model name to the store's serializerFor method`);
    }
  })(modelName) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Passing classes to store.serializerFor has been removed. Please pass a dasherized string instead of ${modelName}`);
    }
  })(typeof modelName === 'string') : {};
  this._serializerCache = this._serializerCache || Object.create(null);
  const normalizedModelName = _deprecatingNormalize(modelName);
  const {
    _serializerCache
  } = this;
  let serializer = _serializerCache[normalizedModelName];
  if (serializer) {
    return serializer;
  }

  // by name
  const owner = getOwner(this);
  serializer = owner.lookup(`serializer:${normalizedModelName}`);
  if (serializer !== undefined) {
    _serializerCache[normalizedModelName] = serializer;
    return serializer;
  }

  // no serializer found for the specific model, fallback and check for application serializer
  serializer = _serializerCache.application || owner.lookup('serializer:application');
  if (serializer !== undefined) {
    _serializerCache[normalizedModelName] = serializer;
    _serializerCache.application = serializer;
    return serializer;
  }
  return null;
}

/**
    `normalize` converts a json payload into the normalized form that
    [push](../methods/push?anchor=push) expects.

    Example

    ```js
    socket.on('message', function(message) {
      let modelName = message.model;
      let data = message.data;
      store.push(store.normalize(modelName, data));
    });
    ```

    @method normalize
    @public
    @param {String} modelName The name of the model type for this payload
    @param {Object} payload
    @return {Object} The normalized payload
  */
// TODO @runspired @deprecate users should call normalize on the associated serializer directly
function normalize(modelName, payload) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Attempted to call store.normalize(), but the store instance has already been destroyed.`);
    }
  })(!(this.isDestroying || this.isDestroyed)) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You need to pass a model name to the store's normalize method`);
    }
  })(modelName) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${typeof modelName}`);
    }
  })(typeof modelName === 'string') : {};
  const normalizedModelName = _deprecatingNormalize(modelName);
  const serializer = this.serializerFor(normalizedModelName);
  const schema = this.modelFor(normalizedModelName);
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You must define a normalize method in your serializer in order to call store.normalize`);
    }
  })(typeof serializer?.normalize === 'function') : {};
  return serializer.normalize(schema, payload);
}

/**
    Push some raw data into the store.

    This method can be used both to push in brand new
    records, as well as to update existing records. You
    can push in more than one type of object at once.
    All objects should be in the format expected by the
    serializer.

    ```app/serializers/application.js
    import RESTSerializer from '@ember-data/serializer/rest';

    export default class ApplicationSerializer extends RESTSerializer;
    ```

    ```js
    let pushData = {
      posts: [
        { id: 1, postTitle: "Great post", commentIds: [2] }
      ],
      comments: [
        { id: 2, commentBody: "Insightful comment" }
      ]
    }

    store.pushPayload(pushData);
    ```

    By default, the data will be deserialized using a default
    serializer (the application serializer if it exists).

    Alternatively, `pushPayload` will accept a model type which
    will determine which serializer will process the payload.

    ```app/serializers/application.js
    import RESTSerializer from '@ember-data/serializer/rest';

     export default class ApplicationSerializer extends RESTSerializer;
    ```

    ```app/serializers/post.js
    import JSONSerializer from '@ember-data/serializer/json';

    export default JSONSerializer;
    ```

    ```js
    store.pushPayload(pushData); // Will use the application serializer
    store.pushPayload('post', pushData); // Will use the post serializer
    ```

    @method pushPayload
    @public
    @param {String} modelName Optionally, a model type used to determine which serializer will be used
    @param {Object} inputPayload
  */
// TODO @runspired @deprecate pushPayload in favor of looking up the serializer
function pushPayload(modelName, inputPayload) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Attempted to call store.pushPayload(), but the store instance has already been destroyed.`);
    }
  })(!(this.isDestroying || this.isDestroyed)) : {};
  const payload = inputPayload || modelName;
  const normalizedModelName = inputPayload ? _deprecatingNormalize(modelName) : 'application';
  const serializer = this.serializerFor(normalizedModelName);
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You cannot use 'store.pushPayload(<type>, <payload>)' unless the serializer for '${normalizedModelName}' defines 'pushPayload'`);
    }
  })(serializer && typeof serializer.pushPayload === 'function') : {};
  serializer.pushPayload(this, payload);
}

// TODO @runspired @deprecate records should implement their own serialization if desired
function serializeRecord(record, options) {
  // TODO we used to check if the record was destroyed here
  if (!this._fetchManager) {
    this._fetchManager = new FetchManager(this);
  }
  return this._fetchManager.createSnapshot(recordIdentifierFor(record)).serialize(options);
}
function cleanup() {
  // enqueue destruction of any adapters/serializers we have created
  for (const adapterName in this._adapterCache) {
    const adapter = this._adapterCache[adapterName];
    if (typeof adapter.destroy === 'function') {
      adapter.destroy();
    }
  }
  for (const serializerName in this._serializerCache) {
    const serializer = this._serializerCache[serializerName];
    if (typeof serializer.destroy === 'function') {
      serializer.destroy();
    }
  }
}
export { LegacyNetworkHandler, adapterFor, cleanup, normalize, pushPayload, serializeRecord, serializerFor };