/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { action } from "@ember/object";

export default class Notification extends Component {
  @service notification;

  get topRightNotifications() {
    return this.notification.notifications.filter((notification) => {
      return notification.options.position === "top-right";
    });
  }

  get bottomLeftNotifications() {
    return this.notification.notifications.filter((notification) => {
      return notification.options.position === "bottom-left";
    });
  }

  @action
  removeNotification(content) {
    this.notification.remove(content);
  }
}
