import Controller from "@ember/controller";
import { service } from "@ember/service";
import { task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";

export default class extends Controller {
  @service ajax;
  @service session;

  @tracked email = null;

  onChange = ({ email }) => (this.email = email);

  requestToConvertToEmailUser = task(async () => {
    await this.ajax.post("/io.php/legacyuser/convert", {
      data: {
        username: this.session.currentUser.username,
        email: this.email,
      },
    });

    this.email = null;
  });
}
