import { service } from "@ember/service";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import DomainItem from "./domain-item";
import { task, enqueueTask, all } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";

export default class DnsRegisterController extends Controller {
  queryParams = ["query"];

  @service ajax;
  @service modal;
  @service metrics;
  @service router;
  @service store;

  get countries() {
    return this.model.countries.countries;
  }

  get pricelist() {
    return this.model.pricelist.pricelist;
  }

  get organization() {
    return this.model.organization;
  }

  get project() {
    return this.model.project;
  }

  @tracked query = "";
  searchedQuery = "";

  get isQueryEmpty() {
    return !this.query.length;
  }

  @tracked hasSearched = false;
  @tracked availableDomains = null;
  @tracked selectedDomains = null;
  @tracked shouldShowErrors = false;

  get domainsAvailableForRegistration() {
    return this.availableDomains.filter((domain) => domain.status === "registrable");
  }

  get domainsAvailableForTransfer() {
    return this.availableDomains.filter((domain) => domain.status === "transferable");
  }

  get domainsWithErrors() {
    let domains = this.selectedDomains;
    return domains.filter((domain) => {
      return domain.error != null;
    });
  }

  get tldsAvailableForRegistration() {
    let pricelist = this.pricelist;

    return Object.keys(pricelist).filter((tld) => {
      return pricelist[tld].registration.length;
    });
  }

  constructor() {
    super(...arguments);

    this.availableDomains = [];
    this.selectedDomains = [];
  }

  getSearchedTlds(query) {
    let searchedTlds = [];

    let hasTld = query.includes(".");
    if (hasTld) {
      let hasManyTlds = query.match(/\.\{(?<tlds>(?=.*[|,/]?).+)\}/);
      if (hasManyTlds?.groups) {
        searchedTlds = hasManyTlds.groups.tlds.replace(/ /g, "").split(",");
      } else {
        searchedTlds = [query.split(".").pop()].filter((x) => x);
      }
    }
    return searchedTlds;
  }

  getUnsupportedTlds(query) {
    return this.getSearchedTlds(query).filter((tld) => !this.tldsAvailableForRegistration.includes(tld));
  }

  searchTask = task(async () => {
    let search = this.query.replace(/\s/g, "");
    let TLDs = this.getSearchedTlds(search);

    if (TLDs.length > 1) {
      this.metrics.trackEvent("Intercom", {
        event: "multi-TLD-search",
      });
    }

    this.shouldShowErrors = false;
    this.searchedQuery = search;
    this.unsupportedTlds = this.getUnsupportedTlds(search);

    await this.ajax
      .post(`/io.php/projects/${this.project.id}/domain/available`, {
        data: {
          search,
        },
      })
      .then((data) => {
        let pricelist = this.pricelist;
        let selectedDomains = this.selectedDomains;

        this.availableDomains = data.map((item) => {
          let tldPrice = pricelist[item.tld];
          let domain = selectedDomains.find((domain) => domain.domainName === item.domainname);

          if (!domain) {
            domain = new DomainItem(item.domainname, item.tld, item.available === "yes", item.prices, tldPrice);
          }

          return domain;
        });
      })
      .catch((error) => {
        let message = error?.errors?.at(0)?.detail?.error?.message;
        if (message) {
          alert(message);
        }
      });

    this.hasSearched = true;
    this.shouldShowErrors = this.unsupportedTlds.length != 0;
  });

  registerDomainTask = enqueueTask({ maxConcurrency: 3 }, async (domain, data) => {
    let isNew = false;
    let domainRecord = this.project
      .hasMany("dnsDomains")
      .value()
      .find((d) => {
        return d.id === domain.domainName || d.domainname === domain.domainName || d.displayname === domain.domainName;
      });

    if (!domainRecord) {
      isNew = true;
      domainRecord = this.store.createRecord("dns-domain", {
        project: this.project,
        domainname: domain.domainName,
      });

      try {
        await domainRecord.save();
      } catch (error) {
        domainRecord.unloadRecord();
        domain.error = error?.errors?.at(0)?.detail;
        return;
      }
    }

    data = {
      ...data,
      domainname: domainRecord.id,
      numyears: domain.period ? parseInt(domain.period, 10) : null,
      context: this.project.id,
    };

    try {
      await this.ajax.post(`/io.php/domain/register`, {
        data,
      });
      domain.isDone = true;
    } catch (error) {
      domain.error = error?.content?.error?.message;
      if (isNew) {
        domainRecord.destroyRecord();
      }
    }
  });

  submitTask = task(async (data) => {
    let selectedDomains = this.selectedDomains.filter((domain) => !domain.isDone);
    let tasks = [];

    selectedDomains.map((domain) => {
      tasks.push(this.registerDomainTask.perform(domain, data));
    });

    await all(tasks);

    let hasErrors = !!this.selectedDomains.filter((domain) => !domain.isDone).length;

    if (!hasErrors) {
      this.router.transitionTo("dns");
    }
  });

  @action
  toggleSelected(domain) {
    domain.isSelected = !domain.isSelected;

    if (domain.isSelected) {
      this.selectedDomains = [...this.selectedDomains, domain];
    } else {
      this.selectedDomains = this.selectedDomains.filter((d) => d !== domain);
    }
  }

  @action
  onQueryChange(query) {
    this.query = query;
  }

  @action
  changePeriod(domain, event) {
    domain.period = event.years;
  }
}
