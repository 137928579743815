import { service } from "@ember/service";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { task } from "ember-concurrency";

export default class DashboardInviteController extends Controller {
  @service ajax;
  @service session;
  @service router;

  get invite() {
    return this.model.invite;
  }

  get user() {
    return this.session.currentUser;
  }

  get displayName() {
    let user = this.user;
    let { name, username } = user;
    return name ? `${name} (${username})` : username;
  }

  acceptTask = task(async () => {
    let user = this.session.currentUser;

    await this.ajax.post(`/io.php/invite/accept`, { data: { invitecode: this.invite.id } });

    await user.reload().then(() => {
      this.router.transitionTo("dashboard");
    });
  });

  @action
  switchUser() {
    let id = this.invite.id;
    this.send("logout", "logout", "dashboard.invite", id);
  }
}
