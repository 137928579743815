/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { htmlSafe } from "@ember/template";
import { createPopper } from "@popperjs/core";
import { next } from "@ember/runloop";
import { modifier } from "ember-modifier";
import styles from "./styles.css";

export default class Tour extends Component {
  @service tour;
  @service keyboard;

  styles = styles;

  @tracked isOpen = false;
  @tracked attachToElement;
  @tracked element;
  @tracked window = window;

  get current() {
    return this.tour.current;
  }

  get animationClasses() {
    return this.isOpen ? "opacity-100" : "opacity-0";
  }

  get clipPath() {
    const { innerWidth, innerHeight } = this.window;
    const { width = 0, height = 0, left = 0, top = 0 } = this.attachToElement?.getBoundingClientRect() || {};

    let startPointX = ((top / innerHeight) * 100).toFixed(2);
    let endPointX = (((top + height) / innerHeight) * 100).toFixed(2);

    let startPointY = ((left / innerWidth) * 100).toFixed(2);
    let endPointY = (((left + width) / innerWidth) * 100).toFixed(2);

    return htmlSafe(`clip-path: polygon(
      0% 0%,
      0% 100%,
      ${startPointY}% 100%,
      ${startPointY}% ${startPointX}%,
      ${endPointY}% ${startPointX}%,
      ${endPointY}% ${endPointX}%,
      ${startPointY}% ${endPointX}%,
      ${startPointY}% 100%,
      100% 100%,
      100% 0%
    )`);
  }

  animateIn = modifier(() => next(() => (this.isOpen = true)));

  registerListeners = modifier(() => {
    this.keyboard.listenFor({ key: "ArrowRight", ignoreFormFieldFocus: true }, this.tour.next);
    this.keyboard.listenFor({ key: "ArrowLeft", ignoreFormFieldFocus: true }, this.tour.back);
    this.keyboard.listenFor({ key: "Escape", ignoreFormFieldFocus: true }, this.tour.exit);

    window.addEventListener("resize", this.handleResize);

    return () => {
      this.keyboard.stopListenFor({ key: "ArrowRight" });
      this.keyboard.stopListenFor({ key: "ArrowLeft" });
      this.keyboard.stopListenFor({ key: "Escape" });

      window.removeEventListener("resize", this.handleResize);
    };
  });

  updateCurrent = modifier((element) => {
    this.element = element;

    let attachToElement;

    if (this.current.element) {
      attachToElement = document.querySelector(this.current.element);
      this.attachToElement = attachToElement;
    } else {
      attachToElement = {
        getBoundingClientRect: () => {
          return {
            width: window.innerWidth,
            height: window.innerHeight,
            top: window.innerHeight / 2,
            right: 0,
            bottom: 0,
            left: 0,
          };
        },
      };
      this.attachToElement = null;
    }

    createPopper(attachToElement, this.element, {
      placement: this.current.placement || "auto",
      modifiers: [
        {
          name: "computeStyles",
          options: {
            gpuAcceleration: true,
            adaptive: false,
          },
        },
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
        {
          name: "preventOverflow",
          options: {
            padding: 20,
          },
        },
      ],
    }).update();

    element.querySelector("#glesys-tour-next-button").focus();
  });

  @action
  handleResize() {
    this.window = window;
  }
}
