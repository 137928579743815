/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { cached, tracked } from "@glimmer/tracking";
import { service } from "@ember/service";
import { action } from "@ember/object";
import ENV from "glesys-controlpanel/config/environment";

export default class ModalsUserExternalTwofactorActivate extends Component {
  @service modal;
  @service session;

  get google() {
    return this.args.params.google;
  }

  get task() {
    return this.args.params.task;
  }

  get method() {
    return this.args.params.method;
  }

  get isYubiKey() {
    return this.method === "yubikey";
  }

  @cached
  get secret() {
    return ENV.totpSecret
      .split("")
      .toSorted(() => Math.random() - 0.5)
      .slice(0, 16)
      .join("");
  }

  get content() {
    return `otpauth://totp/${encodeURIComponent(this.session.currentUser.username)}?secret=${encodeURIComponent(this.secret)}&issuer=GleSYS+Cloud`;
  }

  @tracked otp = "";

  @action
  onFormChange({ otp }) {
    this.otp = otp;
  }

  submitTask = task(async () => {
    let { task, method, otp } = this;
    let secret = this.isYubiKey ? otp.substring(0, 12) : this.secret;
    await task.perform(method, secret, otp);
    this.otp = "";
    this.modal.close();
  });
}
