/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { task } from "ember-concurrency";
import { string, func, object, shape } from "prop-types";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { action } from "@ember/object";

@forbidExtraArgs
export default class ModalsSshKeyAdd extends Component {
  @service modal;
  @service store;

  @arg(shape({ sshKey: string, name: string, onSave: func.isRequired, project: object })) params;

  sshKey = this.params.sshKey;
  name = this.params.name;
  onSave = this.params.onSave;

  get project() {
    return this.params.project;
  }

  @action
  onFormChange({ name, sshKey }) {
    this.name = name;
    this.sshKey = sshKey;
  }

  addSshKey = task(async () => {
    let sshKey = this.store.createRecord("ssh-key", {
      project: this.project,
      description: this.name,
      data: this.sshKey,
    });

    try {
      await sshKey.save();
    } catch (error) {
      sshKey.unloadRecord();
      throw error;
    }

    this.onSave(this.sshKey);
    this.modal.close();
  });
}
