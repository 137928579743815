import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";
import { action } from "@ember/object";

export default class extends Controller {
  @service ajax;
  @service intl;
  @service notification;

  @tracked action;
  @tracked showSuccess = false;
  @tracked showError = false;
  @tracked currentUser = null;

  get newPassword() {
    return this.sendNewPasswordRequest.lastSuccessful?.value?.password;
  }

  setupController(model) {
    if (model.users.length === 1) {
      this.currentUser = model.users[0];
    }
  }

  @action
  selectUser(user) {
    this.currentUser = user;
    this.showSuccess = false;
    this.showError = false;
  }

  sendNewPasswordRequest = task(async (data) => {
    this.showError = false;
    this.action = data?.action || "sendpasswordrequest";
    let response = null;

    try {
      switch (this.action) {
        case "sendpasswordrequest":
        case "2fa-reset-password":
          response = await this.ajax.post("/io.php/recovery/resetpassword", {
            data: {
              username: this.currentUser.username,
              token: this.model.token,
              ...data,
            },
          });
          break;
        case "revert-to-email-based":
          response = await this.ajax.post("/io.php/recovery/disabletwofactor", {
            data: {
              username: this.currentUser.username,
              token: this.model.token,
              ...data,
            },
          });
          break;
      }

      this.showSuccess = true;

      return response;
    } catch {
      this.showError = true;
    }
  });
}
