import { service } from "@ember/service";
import Route from "@ember/routing/route";
import RSVP from "rsvp";

export default class FileStorageVolumesCreateRoute extends Route {
  @service ajax;
  @service abilities;
  @service metrics;
  @service router;
  @service store;

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  activate() {
    this.metrics.trackEvent("Intercom", {
      event: "visited-create-file-storage-volume",
    });
  }

  model() {
    let { project } = this.modelFor("organization");
    let plans = this.ajax.request(`/io.php/filestorage/listplans?projectkey=${project.id}`);

    return RSVP.hash({
      dataCenters: this.store.findAll("data-center"),
      plans,
      project,
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setProperties({
      hasAutoPromotion: true,
      name: "",
      selectedDataCenter: "Stockholm",
      selectedPlan: model.plans.plans.at(0),
    });
  }
}
