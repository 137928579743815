import Route from "@ember/routing/route";
import { service } from "@ember/service";

export default class extends Route {
  @service ajax;

  model({ token }) {
    return this.ajax
      .post(`/io.php/recovery/details`, { data: { token } })
      .then(({ users }) => {
        return { users, token };
      })
      .catch(() => {
        return { users: [] };
      });
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setupController(model);
  }
}
