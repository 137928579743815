import Route from "@ember/routing/route";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class ConfirmRoute extends Route {
  @service ajax;
  @service router;

  model(params) {
    return {
      task: this.confirmTask.perform(params.token),
    };
  }

  confirmTask = task(async (token) => {
    try {
      await this.ajax.post("/io.php/user/confirm", { data: { token } });
      this.router.transitionTo("login", { queryParams: { status: "confirmed" } });
    } catch {
      this.router.transitionTo("login", {
        queryParams: { status: "confirmation-failed" },
      });
    }
  });
}
