import ApplicationAdapter from "./application";

export default class ApiKeyAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return `${this.baseUri()}/apikey/create`;
  }

  urlForUpdateRecord() {
    return `${this.baseUri()}/apikey/edit`;
  }

  urlForDeleteRecord(id) {
    return `${this.baseUri()}/apikey/delete?apikey=${id}`;
  }
}
