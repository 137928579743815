export default {
  "organization.collaborators.details": [
    {
      title: {
        en: "About permissions, organizations and projects",
        sv: "Om behörigheter, organisationer och projekt",
      },
      body: {
        en: "Read our help article about [users, organizations and projects](https://glesys.se/kb/artikel/anvandare-organisationer-och-projekt) (in Swedish)",
        sv: "Läs vår hjälpartikel om [användare, organisationer och projekt](https://glesys.se/kb/artikel/anvandare-organisationer-och-projekt)",
      },
    },
  ],
  "organization.collaborators.overview": [
    {
      title: {
        en: "About permissions, organizations and projects",
        sv: "Om behörigheter, organisationer och projekt",
      },
      body: {
        en: "Read our help article about [users, organizations and projects](https://glesys.se/kb/artikel/anvandare-organisationer-och-projekt) (in Swedish)",
        sv: "Läs vår hjälpartikel om [användare, organisationer och projekt](https://glesys.se/kb/artikel/anvandare-organisationer-och-projekt)",
      },
    },
  ],
  "archives.overview": [
    {
      title: {
        en: "What is GleSYS Archive Service?",
        sv: "Vad är GleSYS Arkiv?",
      },
      body: {
        en: 'The GleSYS Archive Service is intended to be a cost-efficient and easy way to store files and access them through the network. All data is easily accessed on every platform supporting FTP or CIFS. \n\nThe Archive Service is perfect if you want to store files for backup purposes. We do not keep backups of the data on the archive servers. It is however not intended to be a high performance file system to store, for example database files.\n\n## Pricing\n€0.1 per GB Excl. VAT\nNo starting fee or contract period.\n\n[Learn more about GleSYS Archive Service](https://glesys.com/services/archives "GleSYS Archive Service")',
        sv: 'Vår fillagringstjänst är ett kostnadseffektivt och enkelt sätt att lagra filer i våra datacenter. Du har full kontroll på vilket land datan befinner sig i och betalar bara för det du använder. Du kan komma åt lagringsytan på alla plattformar som stödjer FTP eller CIFS.\n\nAnvänd tjänsten för att skicka filer till kunder, spara backuper eller som temporär lagring vid en flytt. Du bestämmer själv.\n\n## Kostnad\n1 kr per GB, exkl. moms\n\nIngen startkostnad eller bindningstid.\n\n[Läs mer om GleSYS arkivlagring](https://glesys.se/tjanster/arkivlagring "GleSYS arkivlagring")',
      },
    },
    {
      title: {
        en: "Maintenance window for Archives",
        sv: "Servicefönster för arkivlagring",
      },
      body: {
        en: "Archives has a weekly recurring maintenance window. The timeframe for this is every Wednesday between 1PM-3PM CET/CEST.\n\nDuring this timeframe the service may be temporarily unavailable for a short period of time.",
        sv: "Arkivtjänsten har ett veckovis återkommande servicefönster. Tidsramen för servicefönstret är varje onsdag mellan 13.00-15.00 CET/CEST.\n\nUnder servicefönstret kan tjänsten vara temporärt otillgänglig.",
      },
    },
  ],
  "servers.create": [
    {
      title: {
        en: "What is KVM?",
        sv: "Vad är KVM?",
      },
      body: {
        en: "KVM is our latest VPS platform, starting at only €6/month, with support for the latest Linux-based Operating Systems. It provides extreme flexibility – go from 1 to 32 GB of memory without the need of reboot.\n\n[Learn more about our KVM platform](https://glesys.com/vps/platforms/kvm)",
        sv: "KVM är vår nya kostnadseffektiva VPS-plattform med ett startpris från 60 kr/mån och stöd för de senaste Linux-operativsystemen. Extrem flexibilitet med möjlighet att gå från 1 till 32 GB minne utan omstart.\n\n[Läs mer om KVM](https://glesys.se/vps/plattformar/kvm)",
      },
    },
    {
      title: {
        en: "What is VMware?",
        sv: "Vad är VMware?",
      },
      body: {
        en: 'VMware is our premium virtual server platform. With a VMware server you get the most dedicated server experience from a virtual server. You can choose between Linux or Windows operating systems.\n\n[Learn more about our platforms](https://glesys.com/vps/platforms "Learn more about GleSYS\'s platforms")',
        sv: 'VMware är vår premium-plattform för virtualisering. Med VMware får du känslan av att ha en dedikerad server. Välj mellan Linux eller Windows som operativsystem.\n\n[Läs mer om våra plattformar](https://glesys.se/vps/plattformar "Läs mer om GleSYS plattformar")',
      },
    },
    {
      title: {
        en: "How do I generate SSH keys for a KVM server?",
        sv: "Hur skapar jag en publik SSH-nyckel för en KVM-server?",
      },
      body: {
        en: "When you create a server on our KVM platform you can add your own public SSH key.\n\n[Learn how to generate SSH keys](https://glesys.se/kb/artikel/skapa-publika-ssh-nycklar) (in Swedish)",
        sv: "När du skapar en server på vår KVM-plattform kan du samtidigt lägga till din egen publika SSH-nyckel för ökad säkerhet.\n\n[Läs vår guide för hur du skapar SSH-nycklar](https://glesys.se/kb/artikel/skapa-publika-ssh-nycklar)",
      },
    },
  ],
  "load-balancer.details": [
    {
      title: {
        en: "How do I create my first load balancer?",
        sv: "Hur skapar jag min första lastbalanserare?",
      },
      body: {
        en: "With GleSYS Load Balancer you are able to easily distribute traffic between several servers. [Learn how to setup a load balancer in GleSYS Cloud](https://glesys.se/kb/lastbalanserare/sa-kommer-du-igang-med-en-lastbalanserare-i-glesys-cloud) (in Swedish).\n\nOn our website you can find out more about features and pricing for our flexible load balancing service. [Learn more &rsaquo;](https://glesys.com/services/loadbalancer)",
        sv: "Med GleSYS Lastbalanserare kan du på ett enkelt och smidigt sätt fördela olika sorters trafik mellan flera olika servrar. [Lär dig hur du kommer igång i vår guide](https://glesys.se/kb/lastbalanserare/sa-kommer-du-igang-med-en-lastbalanserare-i-glesys-cloud).\n\nPå vår webbplats kan du läsa mer om funktioner och prissättning för vår lastbalanseringstjänst. [Läs mer &rsaquo;](https://glesys.se/tjanster/lastbalanserare)",
      },
    },
    {
      title: {
        en: "What is a listener?",
        sv: "Vad är en lyssnare?",
      },
      body: {
        en: "A __listener__ is a process that checks for connection requests. It's configured with a protocol and a port for front-end (client to load balancer) connections.\n\nFor each listener you'll also set up __targets__ with a protocol and a port for back-end (load balancer to back-end instance) connections. \n\n[Learn more how to configure listeners](https://glesys.se/kb/lastbalanserare/sa-kommer-du-igang-med-en-lastbalanserare-i-glesys-cloud) (In Swedish)",
        sv: "En __lyssnare__ hanterar förfrågningar om anslutningar. Den konfigureras med en port och ett protokoll för front-end och dess förfrågningar (klient till lastbalanserare).\n\nFör varje lyssnare ställer du också in __mål__ med protokoll och port för back-end (lastbalanserare till back-end). \n\n[Läs mer om hur du konfigurerar lyssnare &rsaquo;\n](https://glesys.se/kb/lastbalanserare/sa-kommer-du-igang-med-en-lastbalanserare-i-glesys-cloud)",
      },
    },
    {
      title: {
        en: "Using HTTP-mode causes the target to enter DOWN state.",
        sv: "I HTTP-läge går målet in i DOWN läge.",
      },
      body: {
        en: "The loadbalancer performs a health check of the target in the background. \n\nYou can test the health yourself using this command:\n    \n    curl -X OPTIONS http://TARGET-IP/\n\nThis should return HTTP status 20X or 30X, otherwise the target will enter DOWN state.\n\nPlease contact support@glesys.com to disable health checks. \n\n### Running nginx? \n\nThe load balancer is using HTTP OPTIONS requests to determine availability of the target. If your target(s) doesn't reach UP state, please verify that your nginx configuration has a match for the following:\n    \n    server { location = / { if ($request_method = OPTIONS) { return 200; } } }",
        sv: "Lastbalanseraren gör hälsokontroller i bakgrunden på varje mål. Du kan själv testa genom att köra kommandot nedan.\n    \n    curl -X OPTIONS http://TARGET-IP/\n    \nTestet ska svara med HTTP-status 20X eller 30X, om testet svarar något annat så kommer målet att gå in i DOWN-läge.\n\nVänligen maila support@glesys.se om du vill deaktivera hälsokontroller. \n\n### Kör du nginx? \n\nLastbalanseraren använder HTTP OPTIONS-förfrågningar för att fastställa om målen är tillgängliga. Om dina mål inte blir gröna, kontrollera att din nginx-konfiguration matchar följande:\n    \n    server { location = / { if ($request_method = OPTIONS) { return 200; } } }",
      },
    },
  ],
  "servers.overview": [
    {
      title: {
        en: "How are servers billed?",
        sv: "Hur faktureras servrar?",
      },
      body: {
        en: "Please note, you will be charged for all your servers, even if they are stopped, since resources are reserved. You are charged by the hour for all servers.\nAll prices are excluding VAT.",
        sv: "Var uppmärksam på att vi tar betalt för alla servrar. Även om de är avstängda eftersom resurser reserveras. Du betalar per timma för alla servrar.\nAlla priser är exklusive moms.",
      },
    },
    {
      title: {
        en: "Where do I find PTR and Gateway/Subnet details for an IP?",
        sv: "Var hittar jag PTR och gateway/subnäts information för ett IP?",
      },
      body: {
        en: 'Head back to the services menu and click "IP-addresses". \n\n### To change the PTR:\nClick the pen symbol to the right of the IP.\n\n### To find the gateway/subnet details:\nClick the "i" symbol to the right of the IP.\n\n### To release the IP:\nClick the "x" symbol to the right of the IP.\n\n',
        sv: 'Gå tillbaka till tjänstemenyn och klicka på "IP-addresser". \n\n### För att ändra PTR\nKlicka på pennan till höger om IP-adressen.\n\n### För att hitta gateway/subnätsinformationen:\nKlicka på "i" symbolen till höger om IP-adressen.\n\n### För att släppa IP-adressen:\nKlicka på "x"-symbolen till höger om IP-adressen.',
      },
    },
  ],
  "dashboard.api-access": [
    {
      title: {
        en: "Where can I read the API documentation?",
        sv: "Var kan jag läsa API-dokumentationen?",
      },
      body: {
        en: "The public API have a complete documentation endpoint for endpoint at the [GleSYS GitHub space](https://github.com/glesys/API/wiki).\n\nDiscover everything from what you can do to what flags are optional and what are required.",
        sv: "GleSYS publika API har en komplett dokumentation som kan läsas via [GleSYS GitHub-sidor](https://github.com/glesys/API/wiki).\n\nI dokumentationen kan du upptäcka allt från vilka funktioner som är tillgängliga till vilka flaggor som är valbara liksom vilka som är obligatoriska.",
      },
    },
  ],
  "server.details": [
    {
      title: {
        en: "Where do I find PTR and Gateway/Subnet details for an IP?",
        sv: "Var hittar jag PTR och gateway/subnäts information för ett IP?",
      },
      body: {
        en: 'Head back to the services menu and click "IP-addresses". \n\n### To change the PTR:\nClick the pen symbol to the right of the IP.\n\n### To find the gateway/subnet details:\nClick the "i" symbol to the right of the IP.\n\n### To release the IP:\nClick the "x" symbol to the right of the IP.\n\n',
        sv: 'Gå tillbaka till tjänstemenyn och klicka på "IP-addresser". \n\n### För att ändra PTR\nKlicka på pennan till höger om IP-adressen.\n\n### För att hitta gateway/subnätsinformationen:\nKlicka på "i" symbolen till höger om IP-adressen.\n\n### För att släppa IP-adressen:\nKlicka på "x"-symbolen till höger om IP-adressen.',
      },
    },
  ],
  "file-storage-volumes.create": [
    {
      title: {
        en: "How do I setup my file storage volume?",
        sv: "Hur konfigurerar jag in min fillagringsvolym?",
      },
      body: {
        en: "In our guide you can learn how to create, manage access and mount your volume in your operating system. \n\n[Learn how to setup file storage](https://glesys.se/kb/kom-igang/kom-igang-med-glesys-fillagring) (in Swedish)",
        sv: "I vår hjälpartikel kan du lära dig hur du skapar, hanterar åtkomst och monterar din volym i ditt operativsystem.\n\n[Kom igång med fillagring](https://glesys.se/kb/kom-igang/kom-igang-med-glesys-fillagring)",
      },
    },
  ],
  "file-storage-volumes.overview": [
    {
      title: {
        en: "How do I setup my file storage volume?",
        sv: "Hur konfigurerar jag in min fillagringsvolym?",
      },
      body: {
        en: "In our guide you can learn how to create, manage access and mount your volume in your operating system. \n\n[Learn how to setup file storage](https://glesys.se/kb/kom-igang/kom-igang-med-glesys-fillagring) (in Swedish)",
        sv: "I vår hjälpartikel kan du lära dig hur du skapar, hanterar åtkomst och monterar din volym i ditt operativsystem.\n\n[Kom igång med fillagring](https://glesys.se/kb/kom-igang/kom-igang-med-glesys-fillagring)",
      },
    },
  ],
  "file-storage-volumes.details": [
    {
      title: {
        en: "How do I setup my file storage volume?",
        sv: "Hur konfigurerar jag in min fillagringsvolym?",
      },
      body: {
        en: "In our guide you can learn how to create, manage access and mount your volume in your operating system. \n\n[Learn how to setup file storage](https://glesys.se/kb/kom-igang/kom-igang-med-glesys-fillagring) (in Swedish)",
        sv: "I vår hjälpartikel kan du lära dig hur du skapar, hanterar åtkomst och monterar din volym i ditt operativsystem.\n\n[Kom igång med fillagring](https://glesys.se/kb/kom-igang/kom-igang-med-glesys-fillagring)",
      },
    },
    {
      title: {
        en: "Can I provide access for custom IP addresses?",
        sv: "Kan jag ge egna IP-adresser åtkomst till volymen?",
      },
      body: {
        en: "Yes, you can! Contact our support at [support@glesys.com](mailto:support@glesys.com?subject=I%20would%20like%20to%20provide%20access%20for%20custom%20IPs%20to%20my%20volume), with the IP addresses you would want to add and we'll help you out.",
        sv: "Ja, det kan du! Kontakta vår support på [support@glesys.se](mailto:support@glesys.se?subject=Jag%20vill%20ge%20egna%20IP-adresser%20åtkomst%20till%20min%20volym) med vilka IP-adresser du vill ge åtkomst till och vi hjälper dig.",
      },
    },
  ],
  "dns.domain": [
    {
      title: {
        en: "What are the different record types?",
        sv: "Vad innebär de olika pekartyperna?",
      },
      body: {
        en: "### A Records\nUse to point your hostname and subdomains to your servers using an IPv4 address.\n\n### AAAA Records\nUse to point your hostname and subdomains to your servers using an IPv6 address.\n\n### ALIAS Records\nAn ALIAS record can provide a CNAME-like behaviour for apex domains but will only return A and/or AAAA records. Locally defined A and/or AAAA records will take precedence.\n\n### CAA Records\nA CAA record is used to specify which certificate authorities are allowed to issue certificates for a domain.\n\n### MX Records\nUse to point to your mailsever. Always use a priority number, ex. `10 mail.yourserver.com`.\n\n### TXT Records\nIs plain text encapsulated by quotation marks, used for different purposes, ex. SPF records\n\n### CNAME Records\nUse to point your hostname and subdomains to your servers using another hostname. Don't forget to end the hostname with a dot (`.`) or the alias will be appended to the current domain.\n\n__Example__\n\nIf you want to point the alias `test.my-domain.com` to `another-domain.com`:\n\n`test CNAME another-domain.com` will point to `another-domain.com.current-domain.com`\n\n`test CNAME another-domain.com.` will point to `another-domain.com`\n\n### NS Records\nNS Records are nameserver records. You can add additional NS records to a domain if you plan on using additional name servers that do not belong to GleSYS.\n\n### URL Records (custom)\nUsed to point your hostname directly to an URL. (This is a custom feature provided by GleSYS and not part of the DNS standard)\n\n[Learn more about how to add and configure DNS record types](https://glesys.se/kb/domaner/lagg-till-och-andra-dns-pekare-for-din-doman) (in Swedish)",
        sv: "### A\nEn A-pekare används för att koppla ihop en domän med en IPv4-adress.\n\n### AAAA\nFungerar på samma sätt som en A-pekare, men för IPv6-adresser.\n\n### ALIAS\nEn ALIAS-pekare ger ett CNAME-liknande beteende för apex-domäner men returnerar bara A- och/eller AAAA. Lokalt definierade A och/eller AAAA kommer att ha företräde.\n\n### CAA\nEn CAA-pekare används för att ange vilka certifikatmyndigheter som får utfärda certifikat för en domän.\n\n### MX\nEn MX-pekare används för att koppla en domän till en mailserver.\n\n### TXT\nEn TXT-pekare får endast innehålla godtycklig text inkapslat mellan citattecken och kan kan bland annat användas för att verifiera att du är en ägare av en domän eller att lägga till SPF-pekare.\n\n### CNAME\nCNAME används för att koppla ett aliasnamn till en domän. CNAME får inte innehålla IP-adresser.\n\n### NS\nNS-pekare är pekare till namnservrar. Du kan lägga till ytterligare namnservrar till en domän om du tänker använda namnservrar som inte tillhör GleSYS.\n\n[Lär dig mer om hur du lägger du till och ändrar DNS-pekare för din domän](https://glesys.se/kb/domaner/lagg-till-och-andra-dns-pekare-for-din-doman)",
      },
    },
    {
      title: {
        en: "What are GleSYS's name servers?",
        sv: "Vad har GleSYS för namnservrar?",
      },
      body: {
        en: "Our nameservers are:\n\n    ns1.namesystem.se\n    ns2.namesystem.se\n    ns3.namesystem.se\n\n[Learn how to change name servers in GleSYS Cloud (in Swedish)](https://glesys.se/kb/domaner/byta-namnservrar)",
        sv: "Våra namnservrar är:\n\n    ns1.namesystem.se\n    ns2.namesystem.se\n    ns3.namesystem.se\n\n[Så här byter du namnservrar i GleSYS Cloud](https://glesys.se/kb/domaner/byta-namnservrar)",
      },
    },
    {
      title: {
        en: "Can I use glue records for name servers?",
        sv: "Är det möjligt att använda glue records för namnservrar?",
      },
      body: {
        en: "In domains in the top level domains .NU and .SE it is possible to add glue records for name servers. When using a name server in the same domain as it is supposed to serve there need to be one or more IP addresses stored together with the name of the server. In GleSYS Cloud there will show a separate text field next to the ordinary input field when a name inside the current domain is identified. Add IP addresses separated by space.",
        sv: "För domäner på toppdomänerna .NU och .SE kan så kallade glue records användas för namnservrar. När en namnserver adresseras inom samma domän som den sköter om så ska en eller flera IP-adresser anges tillsammans med namnet på servern. När ett namn inom domänen anges som namnserver i GleSYS Coud så visas ett extra fält där IP-adresser ska anges. Separera IP-adresser med mellanslag.",
      },
    },
    {
      title: {
        en: "Why is generating auth-code not enabled?",
        sv: "Varför är inte generering av autentiseringskod aktiverat?",
      },
      body: {
        en: "Only organization owners are able to generate auth-code for a domain.",
        sv: "Endast organisationsägare kan generera autentiseringskod för en domän.",
      },
    },
  ],
  "load-balancers.overview": [
    {
      title: {
        en: "How do I create my first load balancer?",
        sv: "Hur skapar jag min första lastbalanserare?",
      },
      body: {
        en: "With GleSYS Load Balancer you are able to easily distribute traffic between several servers. [Learn how to setup a load balancer in GleSYS Cloud](https://glesys.se/kb/lastbalanserare/sa-kommer-du-igang-med-en-lastbalanserare-i-glesys-cloud) (in Swedish).\n\nOn our website you can find out more about features and pricing for our flexible load balancing service. [Learn more &rsaquo;](https://glesys.com/services/loadbalancer)",
        sv: "Med GleSYS Lastbalanserare kan du på ett enkelt och smidigt sätt fördela olika sorters trafik mellan flera olika servrar. [Lär dig hur du kommer igång i vår guide](https://glesys.se/kb/lastbalanserare/sa-kommer-du-igang-med-en-lastbalanserare-i-glesys-cloud).\n\nPå vår webbplats kan du läsa mer om funktioner och prissättning för vår lastbalanseringstjänst. [Läs mer &rsaquo;](https://glesys.se/tjanster/lastbalanserare)",
      },
    },
  ],
  "email.domain.details": [
    {
      title: {
        en: "What is an email account?",
        sv: "Vad är ett e-postkonto?",
      },
      body: {
        en: 'An email account is an email address with storage. All accounts can be accessed with POP3, IMAP or through [our webmail client](https://mail.glesys.se).\n\n[Learn more about our Email Service](https://glesys.com/services/email "GleSYS Email Service")',
        sv: 'Ett e-postkonto är en e-postadress med lagring. Alla e-postkonton kan anslutas till med POP3, IMAP eller via [vår webbmail](https://mail.glesys.se).\n\n[Läs mer om vår e-posttjänst](https://glesys.se/tjanster/e-post "Läs mer om GleSYS e-posttjänst")',
      },
    },
    {
      title: {
        en: "What is an email alias?",
        sv: "Vad är ett e-postalias?",
      },
      body: {
        en: "An email alias is an email address without any storage. Instead, they point to another email address where all emails will be forwarded. An email alias can also have multiple recipients.",
        sv: "Ett e-postalias är en e-postadress utan lagringsutrymme. Istället skickas breven vidare till en annan e-postadress du har valt. Ett e-postalias kan också ha flera mottagare.",
      },
    },
    {
      title: {
        en: "How do I access my email account?",
        sv: "Hur ansluter jag till mitt e-postkonto?",
      },
      body: {
        en: 'To access your email account you can use POP3, IMAP or [our webmail](https://mail.glesys.se/ "GleSYS webmail"). \n\n## Incoming email configuration:\n\nHostname: `mail.glesys.se`\n\nIMAP Ports:\nWith SSL: `993`\nNon-SSL: `143`\n\nPOP3 Ports:\nWith SSL: `995`\nNon-SSL: `110`\n\n## Outgoing email configuration (SMTP):\n\nServer address: `mail.glesys.se`\n\nPorts for SMTP:\nSSL: `587` or `2525`\nNon-SSL: `25`\n\nPlease check the documentation of your email client for eventual configuration. The email service is restricted to a maximum of 200 emails / day.\n\n[Learn more about configuring your email account](https://glesys.se/kb/e-post/konfigurera-glesys-e-post "How to configure your email account") (in Swedish)',
        sv: 'Du kan ansluta till ditt e-postkonto med POP3, IMAP eller [via vår webbmail](https://mail.glesys.se/ "GleSYS webmail").\n\n## Inställningar för inkommande e-post:\n\nVärdnamn: `mail.glesys.se`\n\n**Portar för IMAP:**\nMed SSL: `993`\nUtan SSL: `143`\n\n**Portar för POP3:**\nMed SSL: `995`\nUtan SSL: `110`\n\n## Inställningar för utgående e-post (SMTP):\n\nMed SSL: `587` eller `2525`\nUtan SSL: `25`\n\n[Läs mer om hur du konfigurerar ditt e-postkonto](https://glesys.se/kb/e-post/konfigurera-glesys-e-post "Konfigurera ditt e-postkonto")',
      },
    },
    {
      title: {
        en: "Can I enable DKIM for my email account?",
        sv: "Kan jag aktivera DKIM för mitt e-postkonto?",
      },
      body: {
        en: "If you're using our email service DKIM is already enabled for your account with a shared key.\n\n If you want the source to be your own domain you need to add a CNAME record to your domain. You can either use the GleSYS DKIM template or manually add a record:\n\n `host: glesys-smtp._domainkey`\n\n`data: key.438b0bc3e63b.dkim.mail.glesys.com.`",
        sv: "Om du använder vår emailtjänst så är DKIM redan aktiverat för ditt konto med en delad nyckel.\n\n Om du vill att källan ska vara din egen domän behöver du lägga till en CNAME-pekare till din domän. Du kan antingen använda GleSYS DKIM-mallen eller manuellt lägga till en pekare:\n\n `host: glesys-smtp._domainkey`\n\n`data: key.438b0bc3e63b.dkim.mail.glesys.com.`",
      },
    },
  ],
  "email.overview": [
    {
      title: {
        en: "What is an email account?",
        sv: "Vad är ett e-postkonto?",
      },
      body: {
        en: 'An email account is an email address with storage. All accounts can be accessed with POP3, IMAP or through [our webmail client](https://mail.glesys.se).\n\n[Learn more about our Email Service](https://glesys.com/services/email "GleSYS Email Service")',
        sv: 'Ett e-postkonto är en e-postadress med lagring. Alla e-postkonton kan anslutas till med POP3, IMAP eller via [vår webbmail](https://mail.glesys.se).\n\n[Läs mer om vår e-posttjänst](https://glesys.se/tjanster/e-post "Läs mer om GleSYS e-posttjänst")',
      },
    },
    {
      title: {
        en: "What is an email alias?",
        sv: "Vad är ett e-postalias?",
      },
      body: {
        en: "An email alias is an email address without any storage. Instead, they point to another email address where all emails will be forwarded. An email alias can also have multiple recipients.",
        sv: "Ett e-postalias är en e-postadress utan lagringsutrymme. Istället skickas breven vidare till en annan e-postadress du har valt. Ett e-postalias kan också ha flera mottagare.",
      },
    },
    {
      title: {
        en: "How do I access my email account?",
        sv: "Hur ansluter jag till mitt e-postkonto?",
      },
      body: {
        en: 'To access your email account you can use POP3, IMAP or [our webmail](https://mail.glesys.se/ "GleSYS webmail"). \n\n## Incoming email configuration:\n\nHostname: `mail.glesys.se`\n\nIMAP Ports:\nWith SSL: `993`\nNon-SSL: `143`\n\nPOP3 Ports:\nWith SSL: `995`\nNon-SSL: `110`\n\n## Outgoing email configuration (SMTP):\n\nServer address: `mail.glesys.se`\n\nPorts for SMTP:\nSSL: `587` or `2525`\nNon-SSL: `25`\n\nPlease check the documentation of your email client for eventual configuration. The email service is restricted to a maximum of 200 emails / day.\n\n[Learn more about configuring your email account](https://glesys.se/kb/e-post/konfigurera-glesys-e-post "How to configure your email account") (in Swedish)',
        sv: 'Du kan ansluta till ditt e-postkonto med POP3, IMAP eller [via vår webbmail](https://mail.glesys.se/ "GleSYS webmail").\n\n## Inställningar för inkommande e-post:\n\nVärdnamn: `mail.glesys.se`\n\n**Portar för IMAP:**\nMed SSL: `993`\nUtan SSL: `143`\n\n**Portar för POP3:**\nMed SSL: `995`\nUtan SSL: `110`\n\n## Inställningar för utgående e-post (SMTP):\n\nMed SSL: `587` eller `2525`\nUtan SSL: `25`\n\n[Läs mer om hur du konfigurerar ditt e-postkonto](https://glesys.se/kb/e-post/konfigurera-glesys-e-post "Konfigurera ditt e-postkonto")',
      },
    },
  ],
  "email.domain.account": [
    {
      title: {
        en: "How do I access my email account?",
        sv: "Hur ansluter jag till mitt e-postkonto?",
      },
      body: {
        en: 'To access your email account you can use POP3, IMAP or [our webmail](https://mail.glesys.se/ "GleSYS webmail"). \n\n## Incoming email configuration:\n\nHostname: `mail.glesys.se`\n\nIMAP Ports:\nWith SSL: `993`\nNon-SSL: `143`\n\nPOP3 Ports:\nWith SSL: `995`\nNon-SSL: `110`\n\n## Outgoing email configuration (SMTP):\n\nServer address: `mail.glesys.se`\n\nPorts for SMTP:\nSSL: `587` or `2525`\nNon-SSL: `25`\n\nPlease check the documentation of your email client for eventual configuration. The email service is restricted to a maximum of 200 emails / day.\n\n[Learn more about configuring your email account](https://glesys.se/kb/e-post/konfigurera-glesys-e-post "How to configure your email account") (in Swedish)',
        sv: 'Du kan ansluta till ditt e-postkonto med POP3, IMAP eller [via vår webbmail](https://mail.glesys.se/ "GleSYS webmail").\n\n## Inställningar för inkommande e-post:\n\nVärdnamn: `mail.glesys.se`\n\n**Portar för IMAP:**\nMed SSL: `993`\nUtan SSL: `143`\n\n**Portar för POP3:**\nMed SSL: `995`\nUtan SSL: `110`\n\n## Inställningar för utgående e-post (SMTP):\n\nMed SSL: `587` eller `2525`\nUtan SSL: `25`\n\n[Läs mer om hur du konfigurerar ditt e-postkonto](https://glesys.se/kb/e-post/konfigurera-glesys-e-post "Konfigurera ditt e-postkonto")',
      },
    },
  ],
  vpn: [
    {
      title: {
        en: "What is GleSYS VPN?",
        sv: "Vad är GleSYS VPN?",
      },
      body: {
        en: "Our VPN service is used to create a tunnel from your remote location to GleSYS network (last hops will be untunneled within GleSYS backbone). \n\nIt’s perfect for managing your servers when you are out of office without having to open firewall ports SSH or RDP for the whole internet address scope.\n\n## Pricing\n€5 /mo. and VPN account.",
        sv: "Vår VPN-tjänst används för att skapa en tunnel från din plats till GleSYS nätverk. Det passar perfekt för att hantera dina servrar när du är utanför ditt kontor, utan att behöva öppna några portar i brandväggen.\n\n## Kostnad\n50 kr/månad och VPN-konto.",
      },
    },
    {
      title: {
        en: "How do I connect via VPN?",
        sv: "Hur ansluter jag via VPN?",
      },
      body: {
        en: "The VPN tunnel is PPTP/L2TP based.\n\nUse `vpn.glesys.com` as host together with your username and password (both are case-sensitive) in your preferred PPTP/L2TP client.\n\nThe IP range we use for the VPN service is `37.152.60.64/26`.\n\nL2TP over IPsec requires a Shared Secret. Our Shared Secret is `GleSYS`.",
        sv: "VPN-tunneln är PPTP/L2TP-baserad. Använd `vpn.glesys.com` som värdnamn tillsammans med ditt användarnamn och lösenord (båda skiftlägeskänsliga).\n\nIP-adresserna vi använder för VPN-tjänsten är `37.152.60.64/26`.\n\nObservera att L2TP över IPsec kräver en delad hemlighet (Shared Secret). Vår delade hemlighet är `GleSYS`.",
      },
    },
  ],
  "dashboard.home": [],
  "servers.clone": [
    {
      title: {
        en: "How does cloning work?",
        sv: "Hur fungerar kloning?",
      },
      body: {
        en: "This function will create an exact copy of your server. Your parent server will stay online during the clone process.\n\nThe clone will be placed on another node in the Cloud cluster. How long a clone takes depends on the size of your server. Usually 5 GB of cloned data takes around 10 minutes.\n\nCloning a server will cause it to restart.",
        sv: "Denna funktion skapar en exakt kopia av din server. Din nuvarande server kommer vara online under hela kloningsprocessen.\n\nKlonen kommer placeras på en annan nod i Cloud-klustret. Hur lång tid en klon tar beror på storleken ev din server. 5 GB klonad data tar vanligtvis 10 minuter.\n\nKloning kommer orska omstart av servern.",
      },
    },
  ],
};
