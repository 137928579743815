import Helper from "@ember/component/helper";
import { service } from "@ember/service";

export default class CanManage extends Helper {
  @service current;
  @service abilities;

  compute([project]) {
    return this.abilities.can("manage for project", project || this.current.project);
  }
}
