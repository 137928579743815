/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { tracked } from "@glimmer/tracking";
import { task } from "ember-concurrency";
import Component from "@glimmer/component";
import { service } from "@ember/service";

export default class ModalsPrivateNetworkCreate extends Component {
  @service modal;
  @service http;
  @service current;

  @tracked name;
  @tracked billing;

  get task() {
    return this.args.params.task;
  }

  onChange = ({ name }) => (this.name = name);

  loadCostTask = task(async () => {
    this.billing = await this.http.request({
      url: `/io.php/privatenetwork/estimatedcost`,
      method: "POST",
      body: {
        projectkey: this.current.project.id,
      },
    });
  });

  submitTask = task(async () => {
    let task = this.task;

    await task.perform(this.name);

    this.modal.close();
  });
}
