import { template as template_85bfece24c514b3497191221556edbe0 } from "@ember/template-compiler";
import { LinkTo } from "@ember/routing";
import CostCompact from "glesys-controlpanel/components/cost/compact";
import { t } from "ember-intl";
import IconsChevronLeft from "glesys-controlpanel/components/icons/chevron-left";
export default template_85bfece24c514b3497191221556edbe0(`
  <LinkTo
    @route="archive"
    @model={{@archive.id}}
    class="w-full mb-4 p-5 px-6 border flex items-center justify-between border-gray-light bg-white rounded group hover:bg-gray-lightest hover:border-gray"
    data-test-archive-row="{{@archive.id}}"
  >
    <div class="flex flex-1 items-center mb-1">
      <div class="pr-4 md:pr-16 w-3/12">
        <span title={{@archive.username}} class="block truncate text-base text-black" data-test-archive-username>
          {{@archive.username}}
        </span>
        <span class="text-sm text-gray-dark group-hover:text-gray-darker mt-1 block">
          <span class="truncate block">
            {{#if @archive.description}}
              {{@archive.description}}
            {{else}}
              {{@archive.node.hostname}}
            {{/if}}
          </span>
        </span>
      </div>

      <div class="pr-4 md:pr-16 w-40">
        <span class="text-sm text-gray-dark flex items-center">
          <span>{{t "label.usage"}}</span>
        </span>
        <span class="block text-base text-black mt-1">
          {{@archive.usage.usage}}/{{@archive.usage.max}}
          {{@archive.usage.unit}}
        </span>
      </div>

      <div class="pr-4 md:pr-16">
        <span class="text-sm text-gray-dark flex items-center">
          <span>{{t "label.cost"}}</span>
        </span>
        <span class="block text-base text-black mt-1">
          <CostCompact @currency={{@archive.cost.currency}} @price={{@archive.cost.amount}} />
        </span>
      </div>

      <div class="flex flex-1 justify-end">
        <IconsChevronLeft class="stroke-gray group-hover:stroke-gray-darker -rotate-180" />
      </div>
    </div>
  </LinkTo>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
