/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { tracked } from "@glimmer/tracking";

export default class ModalsExportZoneFile extends Component {
  @service modal;
  @service ajax;
  @service notification;
  @service intl;

  get domain() {
    return this.args.params.domain;
  }

  get fileObjectUrl() {
    let data = new Blob([this.file], { type: "text/txt" });
    let fileObjectUrl = URL.createObjectURL(data);

    return fileObjectUrl;
  }

  @tracked file = "";

  exportFile = task(async () => {
    try {
      let response = await this.ajax.post(`/io.php/domain/export`, {
        data: {
          domainname: this.domain.id,
        },
      });
      this.file = response.zonefile;
    } catch {
      this.notification.add(
        "error",
        this.intl.t("dns.domain.files.notifications.title"),
        this.intl.t("dns.domain.files.notifications.error-text"),
      );
      this.modal.close();
    }
  });
}
