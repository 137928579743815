/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { arg } from "ember-arg-types";
import { instanceOf } from "prop-types";
import PrivateNetworkSegment from "glesys-controlpanel/models/private-network-segment";
import { service } from "@ember/service";
import { task } from "ember-concurrency";

export default class EntityPrivateNetworkSegment extends Component {
  @service intl;

  @arg(instanceOf(PrivateNetworkSegment)) entity;

  get segment() {
    return this.entity;
  }

  get tableData() {
    return [
      { header: this.intl.t("label.ipv4"), value: this.segment.ipv4subnet },
      { header: this.intl.t("label.ipv6"), value: this.segment.ipv6subnet },
      { header: this.intl.t("label.type"), value: this.segment.type },
      { header: this.intl.t("label.platform"), value: this.segment.platform.toUpperCase() },
      {
        header: this.intl.t("label.location"),
        value: this.segment.dataCenter.name,
        subValue: this.segment.dataCenter.id,
      },
    ];
  }

  loadServers = task(async () => {
    let servers = await this.segment.privateNetwork.project.get("servers");
    return servers;
  });
}
