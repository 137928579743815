/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool, func } from "prop-types";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

@forbidExtraArgs
export default class InvoiceRow extends Component {
  @arg invoice;

  @arg(bool) isSelectable = true;

  @arg organization;

  @arg(func) onSelect;

  @arg(func) onDeselect;

  @tracked selected = false;

  @action
  toggle() {
    this.selected = !this.selected;
    this.selected ? this.onSelect(this.invoice) : this.onDeselect(this.invoice);
  }
}
