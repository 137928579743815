import ApplicationAdapter from "./application";

export default class ServerBackupAdapter extends ApplicationAdapter {
  urlForCreateRecord(_, snapshot) {
    return `${this.baseUri()}/server/createmanualbackup?serverid=${snapshot.belongsTo("server").id}`;
  }

  urlForDeleteRecord(id) {
    return `${this.baseUri()}/server/deletemanualbackup?backupid=${id}`;
  }
}
