import { modifier } from "ember-modifier";

const autofocusable = modifier((element: HTMLInputElement) => {
  if (element.hasAttribute("autofocus")) {
    element.focus();
  }
});

export default autofocusable;

declare module "@glint/environment-ember-loose/registry" {
  export default interface Registry {
    autofocusable: typeof autofocusable;
  }
}
