/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class SidebarOrganizationProjectSwitcherSettings extends Component {
  @service router;
  @service intl;
  @service notification;

  get currentProject() {
    return this.args.currentProject;
  }

  renameTask = task(async (name) => {
    let project = this.currentProject;
    project.name = name;

    await project
      .save()
      .then(() => {})
      .catch((error) => {
        project.rollbackAttributes();
        throw error;
      });
  });

  setColorTask = task(async (color) => {
    let project = this.currentProject;
    project.color = color;

    await project
      .save()
      .then(() => {})
      .catch((error) => {
        project.rollbackAttributes();
        throw error;
      });
  });

  deleteTask = task(async () => {
    let project = this.args.currentProject;

    project.destroyRecord();

    this.notification.add(
      "success",
      this.intl.t("project.settings.delete-notification.title"),
      this.intl.t("project.settings.delete-notification.text"),
    );

    this.router.transitionTo("organization", { queryParams: { project: null } });
  });
}
