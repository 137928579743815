import { service } from "@ember/service";
import Controller from "@ember/controller";
import { action } from "@ember/object";
import { task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";

export default class DashboardSettingsController extends Controller {
  @service ajax;
  @service intl;
  @service session;
  @service notification;

  get user() {
    return this.session.currentUser;
  }

  get isYubiKey() {
    return this.user.twoFactorMethod === "yubikey";
  }

  @tracked passwordCurrent = null;
  @tracked passwordNew = null;
  @tracked passwordOneTimePassword = null;

  @action
  onUserDetailsFormChange({ name }) {
    this.user.name = name;
  }

  @action
  onPasswordFormChange({ passwordCurrent, passwordNew, passwordOneTimePassword }) {
    this.passwordCurrent = passwordCurrent;
    this.passwordNew = passwordNew;
    this.passwordOneTimePassword = passwordOneTimePassword;
  }

  updateToExternalTwoFactorTask = task(async (method, secret, verification) => {
    await this.ajax
      .post("/io.php/user/enabletwofactor", {
        data: {
          method,
          secret,
          verification,
        },
      })
      .then(() => {
        this.send("logout", "updated-to-external-2fa");
      });
  });

  revertToEmailBasedTwoFactorTask = task(async (otp, password) => {
    await this.ajax
      .post("/io.php/user/disabletwofactor", {
        data: {
          password,
          otp,
        },
      })
      .then(() => {
        this.send("logout", "revert-to-email-based");
      });
  });

  saveDetailsTask = task(async () => {
    let user = this.user;
    await user.save();
  });

  changePasswordTask = task(async () => {
    let currentpassword = this.passwordCurrent;
    let newpassword = this.passwordNew;
    let otp = this.passwordOneTimePassword;

    await this.ajax
      .post("/io.php/user/changepassword", {
        data: {
          currentpassword,
          newpassword,
          otp,
        },
      })
      .then(() => {
        this.passwordCurrent = null;
        this.passwordNew = null;
        this.passwordOneTimePassword = null;
        this.send("logout", "reset");
      })
      .catch((error) => {
        switch (error.content.error.internal_code) {
          case 1601:
            this.notification.add(
              "error",
              this.intl.t("dashboard.settings.change-password.title"),
              this.intl.t("dashboard.settings.change-password.invalid"),
            );
            return;

          case 1603:
          case 1604:
            this.notification.add(
              "error",
              this.intl.t("dashboard.settings.change-password.title"),
              this.intl.t("dashboard.settings.change-password.otp-required"),
            );
            return;
        }
        throw error;
      });
  });
}
