import { template as template_f3a39af594624027902f15626294da40 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { oneOf } from "prop-types";
import { modifier } from "ember-modifier";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsInfo from "glesys-controlpanel/components/icons/info";
export default @forbidExtraArgs
class InfoPopup extends Component {
    @arg(oneOf([
        null,
        "orange",
        "red"
    ]))
    iconColor;
    @tracked
    showToolTip = false;
    get iconColorClass() {
        switch(this.iconColor){
            case "orange":
                return this.showToolTip ? "stroke-orange-dark" : "stroke-orange-light";
            case "red":
                return "stroke-red-dark";
            default:
                return this.showToolTip ? "stroke-gray-darker" : "stroke-gray";
        }
    }
    get iconStyle() {
        return `info w-4 inline-flex self-center relative z-8 cursor-pointer ${this.iconColorClass}`;
    }
    @action
    handleMouseEnter() {
        this.showToolTip = true;
    }
    @action
    handleMouseLeave() {
        this.showToolTip = false;
    }
    registerListeners = modifier((element)=>{
        element.addEventListener("mouseenter", this.handleMouseEnter);
        element.addEventListener("mouseleave", this.handleMouseLeave);
        return ()=>{
            element.removeEventListener("mouseenter", this.handleMouseEnter);
            element.removeEventListener("mouseleave", this.handleMouseLeave);
        };
    });
    static{
        template_f3a39af594624027902f15626294da40(`
    <span
      class="inline-flex h-4 text-left text-gray-dark font-normal text-xs whitespace-normal"
      data-test-info-popup
      {{this.registerListeners}}
      {{mergeClasses}}
      ...attributes
    >
      <IconsInfo class={{this.iconStyle}} />
      {{#if this.showToolTip}}
        <div class="mt-4 absolute z-9 max-w-xs bg-white border rounded p-4 py-2 shadow">
          {{yield}}
        </div>
      {{/if}}
    </span>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
