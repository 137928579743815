/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { restartableTask, timeout } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { bool, func, oneOf } from "prop-types";
import { action } from "@ember/object";
import ENV from "glesys-controlpanel/config/environment";
import { isPresent } from "@ember/utils";

@forbidExtraArgs
export default class Code extends Component {
  @tracked state = null;

  @arg(stringable) code = "";

  @arg(bool) editable;

  @arg(func) onChange;

  @arg(oneOf(["tabs", "spaces"])) indentation = "spaces";

  get lineNumbers() {
    return this.code.split(/\r?\n/).map((_, index) => index + 1);
  }

  get supportsCopyingToClipboard() {
    return isPresent(navigator.clipboard);
  }

  copyCodeToClipboard = restartableTask(async () => {
    try {
      await navigator.clipboard.writeText(this.code);
      this.state = "success";
    } catch (e) {
      this.state = "failure";
      if (e.name !== "NotAllowedError") {
        throw e;
      }
    }

    // istanbul ignore next
    await timeout(ENV.environment === "test" ? 0 : 4 * 1000);
  });

  @action
  onInput(event) {
    this.debounceChangeTask.perform(event.target.value);
  }

  @action
  onKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      let indentation = this.indentation === "tabs" ? "\t" : "  ";
      event.target.setRangeText(indentation, event.target.selectionStart, event.target.selectionStart, "end");
    }
  }

  debounceChangeTask = restartableTask(async (value) => {
    // istanbul ignore next
    await timeout(ENV.environment === "test" ? 0 : 500);
    this.onChange?.(value);
  });
}
