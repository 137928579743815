import ApplicationSerializer from "./application";

export default class ApiKeySerializer extends ApplicationSerializer {
  usesProxy = true;

  primaryKey = "apikey";

  normalize(modelClass, resourceHash, prop) {
    resourceHash.allowedhosts = resourceHash.allowedhosts ?? [];

    return super.normalize(modelClass, resourceHash, prop);
  }

  serialize() {
    let json = super.serialize(...arguments);

    json.projectkey = json.project;
    json.allowedhosts = JSON.stringify(json.allowedhosts);
    json.permissions = JSON.stringify(json.permissions);

    return json;
  }
}
