/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { isPresent } from "@ember/utils";
import Component from "@glimmer/component";
import { htmlSafe } from "@ember/template";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool, number, oneOf } from "prop-types";

@forbidExtraArgs
export default class ProgressBar extends Component {
  @arg text;

  @arg(number) max;

  @arg(number) value;

  @arg(bool) showPercentText = false;

  @arg(oneOf(["small", "large"])) size = "small";

  @arg(bool) colors = true;

  get percent() {
    let { max, value } = this;
    if (isPresent(max) && isPresent(value)) {
      let percent = Math.round((parseFloat(value) / parseFloat(max)) * 100);
      if (Number.isInteger(percent)) {
        return percent;
      }
    }
    return 0;
  }

  get style() {
    let style = "bg-gray-lighter";
    switch (this.size) {
      case "large":
        return `${style} h-6`;
      case "small":
      default:
        return `${style} h-2`;
    }
  }

  get color() {
    if (!this.colors) {
      return "bg-gray-light";
    }

    switch (true) {
      case this.percent > 90:
        return "bg-red-dark";
      case this.percent > 75:
        return "bg-orange-dark";
      case this.percent > 50:
        return "bg-yellow-dark";
      default:
        return "bg-ocean-green";
    }
  }

  get progressBarStyle() {
    let percent = this.percent > 100 ? 100 : this.percent;
    return htmlSafe(`width: ${percent}%;`);
  }
}
