import { template as template_d9ab7eb0cc024f73999313e1721bd63f } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
export default template_d9ab7eb0cc024f73999313e1721bd63f(`
  <label class="w-full mb-2 block text-sm font-normal text-gray-dark" {{mergeClasses}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
