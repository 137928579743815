import ApplicationSerializer from "./application";
import { EmbeddedRecordsMixin } from "@ember-data/serializer/rest";

export default class RemoteHandsRequestSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  usesProxy = true;

  primaryKey = "key";

  attrs = {
    project: "projectkey",
    shipments: { serialize: false, deserialize: "records" },
  };

  normalize(modelClass, resourceHash, prop) {
    // Embedded relationships needs to have a id property,
    // even if their own serializer specifies another primaryKey.
    resourceHash.shipments = resourceHash.shipments?.map((shipment) => {
      shipment.id = shipment.key;
      delete shipment.remotehandsrequest;
      return shipment;
    });

    return super.normalize(modelClass, resourceHash, prop);
  }
}
