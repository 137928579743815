import ApplicationSerializer from "./application";
import { EmbeddedRecordsMixin } from "@ember-data/serializer/rest";

export default class LoadBalancerSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  attrs = {
    listeners: {
      key: "ports",
      embedded: "always",
    },
  };

  normalize(modelClass, resourceHash, prop) {
    resourceHash.links = {
      ...resourceHash.links,
      certificates: `/io.php/loadbalancer/listcertificate?loadbalancerid=${resourceHash.id}`,
    };

    return super.normalize(modelClass, resourceHash, prop);
  }
}
