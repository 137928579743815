/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class ModalsVpnUserChangePassword extends Component {
  @service modal;

  get task() {
    return this.args.params.task;
  }

  get vpnUser() {
    return this.args.params.vpnUser;
  }

  @tracked password;
  @tracked passwordConfirm;

  @action
  onFormChange({ password, passwordConfirm }) {
    this.password = password;
    this.passwordConfirm = passwordConfirm;
  }

  get canSubmit() {
    return Boolean(this.password) && this.password === this.passwordConfirm;
  }

  submitTask = task(async () => {
    await this.task.perform(this.vpnUser, this.password);

    this.modal.close();
  });
}
