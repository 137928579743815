export const updateUrlFor = (transition, router) => {
  // eslint-disable-next-line ember/no-private-routing-service
  return router._router._routerMicrolib.updateURL(urlFor(transition, router));
};

export const urlFor = (transition, router) => {
  const routeParams = allRouteParams(transition.to);

  return router.urlFor(transition.to.name, ...routeParams, { queryParams: transition.to.queryParams });
};

export const allRouteParams = (route) => {
  const allParams = (route) => {
    let params = Object.values(route.params);
    if (route.parent) {
      params = params.concat(allParams(route.parent));
    }

    return params;
  };

  return allParams(route).reverse();
};
