import { template as template_08be3ef26b0b4856b76cf9c104ff1931 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { arrayOf, number, shape } from "prop-types";
import Chart from "glesys-controlpanel/components/chart";
export default @forbidExtraArgs
class FileStorageVolumeLoadChart extends Component {
    @arg(shape({
        average: arrayOf(shape({
            timestamp: stringable,
            value: number
        })),
        max: arrayOf(shape({
            timestamp: stringable,
            value: number
        }))
    }))
    data = [];
    @arg(stringable)
    unit;
    @arg(number)
    max;
    get formattedData() {
        let data = this.data;
        return {
            datasets: [
                {
                    backgroundColor: "#7DB9D8",
                    data: data.average.map((item)=>item.value),
                    label: "Average"
                },
                {
                    data: data.max.map((item)=>item.value),
                    label: "Max during day"
                }
            ],
            labels: data.average.map((item)=>item.timestamp)
        };
    }
    get options() {
        return {
            scales: {
                x: {
                    stacked: true,
                    time: {
                        unit: "day",
                        tooltipFormat: "YYYY-MM-DD"
                    }
                },
                y: {
                    grid: {
                        display: false,
                        drawBorder: false
                    }
                }
            }
        };
    }
    static{
        template_08be3ef26b0b4856b76cf9c104ff1931(`
    <Chart
      @type="bar"
      @data={{this.formattedData}}
      @options={{this.options}}
      @max={{100}}
      @unit={{this.unit}}
      ...attributes
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
