/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { task } from "ember-concurrency";
import { action } from "@ember/object";

export default class ModalsLoadBalancersCreate extends Component {
  @service intl;
  @service current;
  @service modal;
  @service cost;

  get task() {
    return this.args.params.task;
  }

  get loadBalancerCost() {
    return this.cost.forLoadBalancer();
  }

  dataCenters = Object.freeze([{ value: "Falkenberg" }, { value: "Stockholm" }]);

  name;
  selectedDataCenter = "Falkenberg";

  onFormChange = ({ name }) => (this.name = name);

  submitTask = task(async () => {
    let task = this.task;
    let name = this.name;
    let selectedDataCenter = this.selectedDataCenter;

    await task.perform(name, selectedDataCenter);
    this.modal.close();
  });

  @action
  selectDataCenter(dataCenter) {
    this.selectedDataCenter = dataCenter.value;
  }
}
