import setupDeprecationWorkflow from "ember-cli-deprecation-workflow";

/**
 * Docs: https://github.com/ember-cli/ember-cli-deprecation-workflow
 */
setupDeprecationWorkflow({
  /**
    false by default, but if a developer / team wants to be more aggressive about being proactive with
    handling their deprecations, this should be set to "true"
  */
  throwOnUnhandled: false,
  workflow: [
    /* ... handlers ... */
    /* to generate this list, run your app for a while (or run the test suite),
     * and then run in the browser console:
     *
     *    deprecationWorkflow.flushDeprecations()
     *
     * And copy the handlers here
     */
    /* example: */
    /* { handler: 'silence', matchId: 'template-action' }, */

    { handler: "throw", matchId: "ember-data:deprecate-legacy-imports" },
    { handler: "throw", matchId: "ember-data:deprecate-non-strict-id" },
    { handler: "silence", matchId: "ember-test-waiters-legacy-module-name" },
    { handler: "throw", matchId: "warp-drive.ember-inflector" },
    { handler: "throw", matchId: "ember-data:deprecate-non-strict-types" },
    { handler: "throw", matchId: "component-template-resolving" },
    { handler: "silence", matchId: "importing-inject-from-ember-service" },
  ],
});
