import ApplicationAdapter from "./application";

export default class PrivateNetworkAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return `${this.baseUri()}/privatenetwork/create`;
  }

  urlForUpdateRecord(id) {
    return `${this.baseUri()}/privatenetwork/edit?privatenetworkid=${id}`;
  }

  urlForDeleteRecord(id) {
    return `${this.baseUri()}/privatenetwork/delete?privatenetworkid=${id}`;
  }
}
