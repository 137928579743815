import Route from "@ember/routing/route";
import { service } from "@ember/service";

export default class OrganizationsCreateRoute extends Route {
  @service ajax;

  model() {
    return this.ajax.request("/io.php/country/list");
  }
}
