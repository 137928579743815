import { template as template_4276374921b84a099d87c5ccd167d2f1 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { Task, task } from "ember-concurrency";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { shape, instanceOf } from "prop-types";
import { on } from "@ember/modifier";
import { t } from "ember-intl";
import Header from "glesys-controlpanel/components/g-modal/header";
import Body from "glesys-controlpanel/components/g-modal/body";
import Footer from "glesys-controlpanel/components/g-modal/footer";
import Select from "glesys-controlpanel/components/select";
import modalClose from "glesys-controlpanel/helpers/modal-close";
import GButton from "glesys-controlpanel/components/g-button";
import TaskForm from "glesys-controlpanel/components/task-form";
import Label from "glesys-controlpanel/components/label";
import GInput from "glesys-controlpanel/components/g-input";
export default @forbidExtraArgs
class ModalsCreateShipment extends Component {
    @service
    modal;
    @service
    ajax;
    @arg(shape({
        submitTask: instanceOf(Task)
    }).isRequired)
    params;
    selectedDataCenter;
    trackingId;
    get dataCenters() {
        return [
            {
                id: "dc-fbg1",
                value: "Falkenberg"
            },
            {
                id: "dc-sto1",
                value: "Stockholm"
            }
        ];
    }
    selectDataCenter = (dataCenter)=>{
        this.selectedDataCenter = dataCenter;
    };
    onFormChange = ({ trackingId })=>(this.trackingId = trackingId);
    onSubmitTask = task(async ()=>{
        let ok = await this.params.submitTask.perform(this.selectedDataCenter, this.trackingId);
        if (ok) {
            this.modal.close();
        }
    });
    static{
        template_4276374921b84a099d87c5ccd167d2f1(`
    <Header>{{t "component.modal.create-shipment.title"}}</Header>
    <Body data-test-create-shipment-modal>
      <p class="text-gray-darker pb-6">
        {{t "component.modal.create-shipment.description"}}
      </p>
      <TaskForm @onChange={{this.onFormChange}} @task={{this.onSubmitTask}} id="create-shipment-form">
        <div class="grid gap-2">
          <div>
            <Label>{{t "label.data-center"}}</Label>
            <Select
              @items={{this.dataCenters}}
              @onChange={{this.selectDataCenter}}
              @placeholder={{t "component.modal.create-shipment.data-center-placeholder"}}
              data-test-create-shipment-modal-datacenter
            />
          </div>

          <div>
            <Label for="trackingId">{{t "label.tracking-id"}}</Label>
            <GInput
              value={{this.trackingId}}
              name="trackingId"
              placeholder={{t "component.modal.create-shipment.tracking-id-placeholder"}}
              data-test-create-shipment-modal-tracking-id
            />
          </div>
        </div>
      </TaskForm>
    </Body>
    <Footer>
      <GButton @color="gray" class="mr-2" data-test-cancel-button {{on "click" (modalClose)}}>
        {{t "button.close"}}
      </GButton>
      <GButton @type="submit" @color="blue" form="create-shipment-form" data-test-create-shipment-submit>
        {{t "button.create"}}
      </GButton>
    </Footer>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
