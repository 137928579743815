export default {
  all: () => [
    {
      id: "X10-1",
      name: "X10-1",
      cpu: {
        value: "Intel® Xeon® E5-2620 v4",
        subValue: "8 cores @ 2.10 GHz",
      },
      memory: 32,
      storage: 480,
      connection: 1,
      locations: [{ key: "dc-fbg1", value: "Falkenberg", in_stock: false }],
      mrc: 1690,
    },
    {
      id: "X10-2",
      name: "X10-2",
      cpu: {
        value: "Intel® Xeon® E5-2690 v4",
        subValue: "14 cores @ 2.60 GHz",
      },
      memory: 64,
      storage: 480,
      connection: 1,
      locations: [
        { key: "dc-fbg1", value: "Falkenberg", in_stock: true },
        { key: "dc-sto1", value: "Stockholm", in_stock: true },
        { key: "dc-oul1", value: "Oulu", in_stock: true },
      ],
      mrc: 1690,
    },
    {
      id: "X10-3",
      name: "X10-3",
      cpu: {
        value: "Intel® Xeon® E5-2690 v4",
        subValue: "14 cores @ 2.60 GHz",
      },
      memory: 128,
      storage: 480,
      connection: 1,
      locations: [{ key: "dc-sto1", value: "Stockholm", in_stock: true }],
      mrc: 2590,
    },
    {
      id: "X10-4",
      name: "X10-4",
      cpu: {
        value: "2 x Intel® Xeon® E5-2690 v4",
        subValue: "28 cores @ 2.60 GHz",
      },
      memory: 128,
      storage: 480,
      connection: 1,
      locations: [
        { key: "dc-fbg1", value: "Falkenberg", in_stock: true },
        { key: "dc-sto1", value: "Stockholm", in_stock: true },
      ],
      mrc: 2890,
    },
    {
      id: "X11-1",
      name: "X11-1",
      cpu: {
        value: "Intel® Xeon® Gold 6132",
        subValue: "14 cores @ 2.60 GHz",
      },
      memory: 64,
      storage: 480,
      connection: 1,
      locations: [
        { key: "dc-fbg1", value: "Falkenberg", in_stock: true },
        { key: "dc-sto1", value: "Stockholm", in_stock: true },
      ],
      mrc: 2090,
    },
    {
      id: "X11-2",
      name: "X11-2",
      cpu: {
        value: "Intel® Xeon® Gold 6132",
        subValue: "14 cores @ 2.60 GHz",
      },
      memory: 96,
      storage: 480,
      connection: 1,
      locations: [
        { key: "dc-fbg1", value: "Falkenberg", in_stock: true },
        { key: "dc-sto1", value: "Stockholm", in_stock: true },
      ],
      mrc: 2290,
    },
    {
      id: "X11-3",
      name: "X11-3",
      cpu: {
        value: "2 x Intel® Xeon® Gold 6132",
        subValue: "28 cores @ 2.60 GHz",
      },
      memory: 96,
      storage: 480,
      connection: 1,
      locations: [
        { key: "dc-fbg1", value: "Falkenberg", in_stock: false },
        { key: "dc-sto1", value: "Stockholm", in_stock: true },
        { key: "dc-oul1", value: "Oulu", in_stock: true },
      ],
      mrc: 2890,
    },
    {
      id: "X11-4",
      name: "X11-4",
      cpu: {
        value: "2 x Intel® Xeon® Gold 6132",
        subValue: "28 cores @ 2.60 GHz",
      },
      memory: 256,
      storage: 480,
      connection: 1,
      locations: [{ key: "dc-sto1", value: "Stockholm", in_stock: true }],
      mrc: 3090,
    },
  ],
};
