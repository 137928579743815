import ApplicationSerializer from "./application";

export default class ServerStatusSerializer extends ApplicationSerializer {
  usesProxy = true;

  attrs = {
    isLocked: "islocked",
    isRunning: "isrunning",
    isSuspended: "issuspended",
    cpuusage: "cpu",
    memoryusage: "memory",
    diskusage: "disk",
  };
}
