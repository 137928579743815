import { template as template_b28cbfdbaa53423a9df55957ae9d3bf3 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { arrayOf, func, number, shape } from "prop-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import SelectablePlanItem from "glesys-controlpanel/components/selectable-plan-item";
import { t } from "ember-intl";
let planPropType = shape({
    id: stringable,
    cost: shape({
        currency: stringable,
        price: number,
        total: number
    }),
    quota: number
});
export default @forbidExtraArgs
class FileStorageVolumePlanSelect extends Component {
    @arg(arrayOf(planPropType))
    plans;
    @arg(planPropType)
    selectedPlan;
    @arg(func.isRequired)
    onSelectedPlan;
    get plansWithCost() {
        return this.plans.filter((plan)=>plan.cost !== null);
    }
    @action
    togglePlan(plan) {
        this.onSelectedPlan(plan);
    }
    static{
        template_b28cbfdbaa53423a9df55957ae9d3bf3(`
    <div class="text-gray-darkest" {{mergeClasses}} ...attributes>
      {{#if this.plansWithCost}}
        <div class="flex justify-between text-center text-lg py-4 border-b border-gray-light">
          <div class="flex-1 uppercase text-xs tracking-wide text-gray-darker">
            {{t "label.file-storage-plans-quota"}}
          </div>
          <div class="flex-1 uppercase text-xs tracking-wide text-gray-darker">
            {{t "label.file-storage-plans-price"}}
          </div>
          <div class="flex-1 uppercase text-xs tracking-wide text-gray-darker">
            {{t "label.file-storage-plans-unit-price"}}
          </div>
        </div>
        {{#each this.plansWithCost as |plan index|}}
          <SelectablePlanItem
            @index={{index}}
            @plan={{plan}}
            @selectedPlan={{this.selectedPlan}}
            @onToggle={{this.togglePlan}}
            data-test-selectable-plan-item={{plan.planid}}
          />
        {{/each}}
      {{else}}
        <div data-test-file-storage-volumes-update-settings-modal-has-no-plans>
          {{t "file-storage-volume.plans-modal.no-plans"}}
        </div>
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
