import ApplicationAdapter from "./application";

export default class CollaboratorAdapter extends ApplicationAdapter {
  urlForCreateRecord() {
    return `${this.baseUri()}/invite/create`;
  }

  urlForUpdateRecord() {
    return `${this.baseUri()}/customer/editcollaborator`;
  }

  urlForDeleteRecord(id) {
    return `${this.baseUri()}/customer/removecollaborator?collaboratorid=${id}`;
  }
}
