/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { arrayOf, func, instanceOf } from "prop-types";
import server from "glesys-controlpanel/models/server";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { action } from "@ember/object";

@forbidExtraArgs
export default class SelectableServerItem extends Component {
  @arg(instanceOf(server)) server;
  @arg(arrayOf(stringable)) accessList = [];
  @arg(func.isRequired) onToggle;

  get selected() {
    let { accessList, server } = this;
    return accessList.includes(server.get("id"));
  }

  @action
  onClick() {
    this.onToggle(this.server);
  }
}
