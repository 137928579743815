import ApplicationSerializer from "./application";
import { EmbeddedRecordsMixin } from "@ember-data/serializer/rest";

export default class PrivateNetworkSegmentSerializer extends ApplicationSerializer.extend(EmbeddedRecordsMixin) {
  usesProxy = true;

  attrs = {
    dataCenter: "datacenter",
    privateNetwork: "privatenetworkid",
    networkAdapters: {
      key: "networkadapters",
      embedded: "always",
    },
  };
}
