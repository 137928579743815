import Service from "@ember/service";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { task } from "ember-concurrency";
import { importSync } from "@embroider/macros";
import { ensureSafeComponent } from "@embroider/util";

export default class ModalService extends Service {
  @tracked name = null;
  @tracked params = null;
  @tracked isOpen = null;

  setOpen(name, params = null) {
    let { isOpen = null } = params;

    this.name = name;
    this.params = params;
    this.isOpen = isOpen;
  }

  setClose() {
    if (!(this.isDestroying && this.isDestroyed)) {
      this.isOpen = false;
    }
  }

  setDelete() {
    if (!(this.isDestroying && this.isDestroyed)) {
      this.name = null;
      this.isOpen = false;
    }
  }

  @action
  open() {
    this.setOpen(...arguments);
  }

  @action
  close() {
    this.setClose(...arguments);
  }

  @action
  delete() {
    this.setDelete(...arguments);
  }

  loadComponent = task(async () => {
    let module = importSync(`../components/${this.name}`);

    return ensureSafeComponent(module.default, this);
  });
}
