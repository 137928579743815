/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { restartableTask, timeout } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";

import { arg, forbidExtraArgs } from "ember-arg-types";
import { stringable } from "glesys-controlpanel/utils/prop-types";
import { bool } from "prop-types";
import ENV from "glesys-controlpanel/config/environment";
import { isPresent } from "@ember/utils";
import { modifier } from "ember-modifier";

@forbidExtraArgs
export default class Copyable extends Component {
  states = { success: "success", failure: "failure", init: "null" };
  @tracked state = this.states.init;

  @arg(stringable) contentsWrapperStyle;
  @arg(bool) alwaysShowCopyIcon;

  element;

  storeElementToCopyFrom = modifier((element) => {
    this.element = element;
  });

  get supportsClipboard() {
    return isPresent(navigator.clipboard);
  }

  copyTextToClipboard = restartableTask(async () => {
    try {
      const textToBeCopied = this.element.textContent.trim();
      await navigator.clipboard.writeText(textToBeCopied);
      this.state = this.states.success;
    } catch (e) {
      this.state = this.states.failure;
      if (e.name !== "NotAllowedError") {
        throw e;
      }
    }

    await timeout(ENV.environment === "test" ? 0 : 2 * 1000);
  });
}
