/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { task } from "ember-concurrency";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class ModalsDnsRegisterDomainDetails extends Component {
  @service modal;

  get task() {
    return this.args.params.task;
  }

  get organization() {
    return this.args.params.organization;
  }

  get countries() {
    return this.args.params.countries;
  }

  organizationIdentityNumber = this.organization.identityNumber;
  organizationName = this.organization.name;
  organizationFirstname;
  organizationLastname;
  organizationAddress = this.organization.address;
  organizationZip = this.organization.zip;
  organizationCity = this.organization.city;
  organizationEmail = this.organization.email;
  organizationPhone = this.organization.phone;

  @tracked organizationCountry = this.organization.country;

  get countryName() {
    return this.countries.find((country) => {
      return country.name.toLowerCase() == this.organizationCountry.toLowerCase();
    })?.name;
  }

  submitTask = task(async () => {
    let task = this.task;
    let data = {
      organization: this.organizationName,
      organizationnumber: this.organizationIdentityNumber,
      firstname: this.organizationFirstname,
      lastname: this.organizationLastname,
      address: this.organizationAddress,
      zipcode: this.organizationZip,
      city: this.organizationCity,
      country: this.organizationCountry,
      email: this.organizationEmail,
      phonenumber: this.organizationPhone,
    };

    await task.perform(data);
    this.modal.close();
  });

  @action
  onFormChange({
    organizationName,
    organizationIdentityNumber,
    organizationFirstname,
    organizationLastname,
    organizationAddress,
    organizationCity,
    organizationZip,
    organizationEmail,
    organizationPhone,
  }) {
    this.organizationName = organizationName;
    this.organizationIdentityNumber = organizationIdentityNumber;
    this.organizationFirstname = organizationFirstname;
    this.organizationLastname = organizationLastname;
    this.organizationAddress = organizationAddress;
    this.organizationCity = organizationCity;
    this.organizationZip = organizationZip;
    this.organizationEmail = organizationEmail;
    this.organizationPhone = organizationPhone;
  }

  @action
  selectedCountry(value) {
    this.organizationCountry = value.code;
  }
}
