/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";
import { task, restartableTask, timeout } from "ember-concurrency";
import Component from "@glimmer/component";

export default class extends Component {
  @service notification;
  @service http;
  @service intl;

  @tracked dataCenter = "dc-fbg1";
  @tracked description = null;
  @tracked addDefaultBucket = true;
  @tracked isFetchingInitialBucket = true;

  get project() {
    return this.args.params.project;
  }

  get availableDataCenters() {
    return this.args.params.availableDataCenters;
  }

  get isCreating() {
    return !this.createInstance.lastSuccessful;
  }

  get createdInstance() {
    return this.createInstance.lastSuccessful.value;
  }

  get closeModalText() {
    return this.isCreating ? this.intl.t("button.cancel") : this.intl.t("button.close");
  }

  get endpoint() {
    return `objects.${this.dataCenter}.glesys.net`;
  }

  @action
  onFormChange({ description, addDefaultBucket }) {
    this.description = description;
    this.addDefaultBucket = Boolean(addDefaultBucket);
  }

  @action
  changeDataCenter(dataCenter) {
    this.dataCenter = dataCenter;
  }

  refetchInstance = restartableTask(async () => {
    if (this.isCreating) {
      await timeout(1000);
      this.refetchInstance.perform();
    }

    let {
      content: { instance },
    } = await this.http.request({
      url: `/objectstorage/instancedetails?instanceid=${this.createdInstance.id}`,
    });

    if (instance.initialbucket == null) {
      await timeout(1000);
      this.refetchInstance.perform();
    } else {
      this.isFetchingInitialBucket = false;
      await this.notification.add(
        "success",
        this.intl.t("object-storage.notifications.bucket-created-title"),
        this.intl.t("object-storage.notifications.bucket-created-text"),
      );
    }
    return instance;
  });

  createInstance = task(async () => {
    let {
      content: { instance },
    } = await this.http.request({
      url: `/objectstorage/createinstance`,
      method: "POST",
      body: {
        projectkey: this.project.id,
        datacenter: this.dataCenter,
        description: this.description,
        createinitialbucket: this.addDefaultBucket,
      },
    });

    if (typeof instance !== "undefined") {
      await this.args.params.refreshTask.perform();
      await this.notification.add(
        "success",
        this.intl.t("object-storage.notifications.instance-created.title"),
        this.intl.t("object-storage.notifications.instance-created.text"),
      );
      if (this.addDefaultBucket) {
        this.refetchInstance.perform();
      }
      return instance;
    }
    return null;
  });
}
