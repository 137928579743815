import Controller from "@ember/controller";

export default class OrganizationNextInvoiceController extends Controller {
  get accounts() {
    return this.model.next.accounts;
  }

  get changes() {
    return this.model.next.changes;
  }

  get summary() {
    return this.model.next.summary;
  }

  get formatedChanges() {
    let changes = this.changes ?? {};
    return Object.keys(changes).map((name) => ({
      name,
      rows: changes[name].map((row) => {
        row.isArticle = row.articlenumber === "000";
        row.total = parseInt(row.price, 10) * parseInt(row.quantity, 10);
        return row;
      }),
    }));
  }
}
