/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";

@forbidExtraArgs
export default class LoadBalancerListener extends Component {
  @arg listener;
  @arg certificates;

  get certificateItems() {
    return [{ name: "-", id: "none" }].concat(this.certificates?.slice() ?? []);
  }

  get selectedCertificate() {
    if (this.listener.certificate === "none") {
      return "-";
    }
    return this.listener.certificate || "-";
  }

  modeSelections = Object.freeze([{ value: "http" }, { value: "tcp" }]);

  get loadBalancer() {
    return this.listener.loadBalancer;
  }

  sessionOptions = Object.freeze([
    { value: true, label: "yes" },
    { value: false, label: "no" },
  ]);

  get sessionSelection() {
    let value = this.listener.stickysessions;
    return { value, label: value ? "yes" : "no" };
  }

  portSelected = (event) => (this.listener.port = parseInt(event.target.value));

  modeSelected = (mode) => (this.listener.mode = mode.value);

  @action
  certificateSelected(certificate) {
    this.listener.certificate = certificate.id;
  }

  @action
  stickySessionChange(option) {
    this.listener.stickysessions = option.value;
  }

  @action
  deletePort() {
    let listener = this.listener;

    if (listener.get("isDeleted")) {
      listener.rollbackAttributes();
    } else {
      listener.deleteRecord();
    }
  }
}
