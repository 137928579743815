import { template as template_7667240e105d4aedb7a18a6283745486 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { bool } from "prop-types";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import { on } from "@ember/modifier";
import IconsChevronDown from "glesys-controlpanel/components/icons/chevron-down";
import { and } from "ember-truth-helpers";
export default @forbidExtraArgs
class Box extends Component {
    @arg(bool)
    collapsable = false;
    @arg(bool)
    showFooter = true;
    @tracked
    open = false;
    get isOpen() {
        return !this.collapsable || this.open;
    }
    @action
    toggle() {
        this.open = !this.open;
    }
    static{
        template_7667240e105d4aedb7a18a6283745486(`
    <div
      class="relative flex flex-col bg-white rounded outline outline-1 outline-gray-light"
      {{mergeClasses}}
      ...attributes
    >
      {{#if (has-block "header")}}
        <div
          role="button"
          class="cursor-pointer flex items-center rounded p-4 outline-gray-light
            {{if this.isOpen 'outline outline-1 rounded-b-none'}}
            {{if
              this.collapsable
              'hover:relative hover:outline hover:outline-1 hover:outline-gray hover:bg-gray-lightest'
            }}
            {{unless this.collapsable 'pointer-events-none outline outline-1 rounded-b-none'}}"
          data-test-box-toggle-content
          {{on "click" this.toggle}}
        >
          <div class="text-lg text-black mt-0 w-full">
            {{yield to="header"}}
          </div>
          {{#if (has-block "headerActions")}}
            {{yield to="headerActions"}}
            {{#if this.collapsable}}
              <div
                class="w-9 h-8 mr-2 cursor-pointer flex items-center justify-center rounded border border-gray-light text-gray hover:border-gray/60 hover:bg-gray-lightest hover:text-gray-dark"
              >
                <IconsChevronDown
                  role="presentation"
                  class="text-gray-dark transition duration-300 {{if this.isOpen 'rotate-180'}}"
                />
              </div>
            {{/if}}
          {{else if this.collapsable}}
            <IconsChevronDown
              role="presentation"
              class="text-gray-dark transition duration-300 {{if this.isOpen 'rotate-180'}}"
            />
          {{/if}}
        </div>
      {{/if}}
      {{#if this.isOpen}}
        <div
          class="p-6 flex-1
            {{if (and (has-block 'footer') this.showFooter) 'pb-10'}}
            {{if (has-block 'header') 'flex flex-col'}}"
        >
          {{yield}}
        </div>
        {{#if (and (has-block "footer") this.showFooter)}}
          <div
            class="bg-gray-lightest justify-end flex items-center rounded-b px-4 py-3 outline outline-1 outline-gray-light"
          >
            {{yield to="footer"}}
          </div>
        {{/if}}
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
