/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { task } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";

export default class ModalsNetworksCreate extends Component {
  @service store;
  @service current;
  @service modal;
  @service cost;

  get task() {
    return this.args.params.task;
  }

  get networkCost() {
    return this.cost.forNetwork();
  }

  @tracked description;

  selectedDataCenter = "Falkenberg";

  get disabled() {
    return this.current.organization.isRestricted || !this.description;
  }

  get dataCenters() {
    return this.store.peekAll("data-center").filter((dataCenter) => dataCenter.hasNetworkSupport);
  }

  onFormChange = ({ description }) => (this.description = description);

  submitTask = task(async () => {
    let task = this.task;
    let description = this.description;
    let selectedDataCenter = this.selectedDataCenter;

    await task.perform(description, selectedDataCenter);

    this.modal.close();
  });

  @action
  selectDataCenter(dataCenter) {
    this.selectedDataCenter = dataCenter.name;
  }
}
