/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { tracked } from "@glimmer/tracking";
import { Task, task } from "ember-concurrency";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { arg } from "ember-arg-types";
import { arrayOf, shape, instanceOf } from "prop-types";
import DataCenter from "glesys-controlpanel/models/data-center";
import { action } from "@ember/object";
import PrivateNetworkModel from "glesys-controlpanel/models/private-network";

export default class ModalsPrivateNetworkCreateSegment extends Component {
  @service modal;

  @tracked name;
  platform = "KVM";
  dataCenter;

  ipv4subnet = "192.168.0.0/24";

  @arg(
    shape({
      privateNetwork: instanceOf(PrivateNetworkModel).isRequired,
      task: instanceOf(Task).isRequired,
      dataCenters: arrayOf(instanceOf(DataCenter)).isRequired,
    }).isRequired,
  )
  params;

  get task() {
    return this.params.task;
  }

  get platforms() {
    return [{ value: "KVM" }];
  }

  get dataCenters() {
    return this.params.dataCenters;
  }

  get validationErrors() {
    return !this.name || !this.platform || !this.dataCenter || !this.ipv4subnet;
  }

  onChange = ({ name, ipv4subnet }) => {
    this.name = name;
    this.ipv4subnet = ipv4subnet;
  };

  @action
  selectDataCenter(dataCenter) {
    this.dataCenter = dataCenter;
  }

  submitTask = task(async () => {
    let { name, platform, dataCenter, ipv4subnet } = this;
    await this.task.perform({ name, platform, dataCenter, ipv4subnet });

    this.modal.close();
  });
}
