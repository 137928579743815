/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { service } from "@ember/service";
import { task } from "ember-concurrency";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class ModalsServerAddIps extends Component {
  @service store;
  @service modal;

  get project() {
    return this.args.params.project;
  }

  get server() {
    return this.args.params.server;
  }

  get task() {
    return this.args.params.task;
  }

  get version() {
    return this.args.params.version;
  }

  get ipCost() {
    return this.selectedIps.reduce((acc, curr) => acc + curr.cost.amount, 0);
  }

  @tracked availableIps = null;
  @tracked reservedIps = null;
  @tracked selectedIps = null;

  fetchAvailableIpsTask = task(async () => {
    let store = this.store;

    let project = this.project;
    let server = this.server;

    let datacenter = server.get("datacenter");
    let platform = server.get("platform");
    let ipversion = this.version;

    let availableIps = await store.query("available-ip", {
      projectkey: project.get("id"),
      datacenter,
      platform,
      ipversion,
    });
    let reservedIps = await project.get("reservedIps").then((reservedIps) => {
      return reservedIps.filter((reservedIp) => {
        return (
          reservedIp.get("serverId") === null &&
          reservedIp.get("dataCenter") === datacenter &&
          reservedIp.get("platforms").includes(platform) &&
          reservedIp.get("version") == ipversion
        );
      });
    });
    let selectedIps = [];

    this.availableIps = availableIps;
    this.reservedIps = reservedIps;
    this.selectedIps = selectedIps;
  });

  submitTask = task(async () => {
    let task = this.task;
    let selectedIps = this.selectedIps;

    await task.perform(selectedIps);

    this.modal.close();
  });

  @action
  selectIp(ip) {
    this.selectedIps = [...this.selectedIps, ip];
  }

  @action
  deselectIp(ip) {
    this.selectedIps = this.selectedIps.filter((x) => x !== ip);
  }
}
