/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import EmberObject, { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import { arg, forbidExtraArgs } from "ember-arg-types";
import { array, func, instanceOf, oneOf, oneOfType, string, bool } from "prop-types";
import { isEqual } from "@ember/utils";
import { modifier } from "ember-modifier";

@forbidExtraArgs
export default class extends Component {
  @tracked isOpen = false;

  @arg(bool) showAllItems;

  @arg(func) onChange;

  @arg(func) onClose;

  @arg(oneOf(["click", "hover", "none"])) event = "click";

  get eventTrigger() {
    return this.event;
  }

  @arg(bool) chevron = true;

  @arg(string) key = "value";

  get valueKey() {
    return this.key;
  }

  @arg selected;

  @arg(oneOfType([array, instanceOf(EmberObject)]).isRequired) items = [];

  get allItemsFlattened() {
    return this.items?.flatMap((item) => {
      return item.items ? item.items.map((i) => i) : item;
    });
  }

  @tracked selectedItem = null;

  @arg(bool) disabled = false;

  registerListeners = modifier((element) => {
    document.addEventListener("keydown", this.keyDown);

    switch (this.eventTrigger) {
      case "none":
        break;
      case "hover":
        element.addEventListener("mouseenter", this.open);
        element.addEventListener("mouseleave", this.close);
        break;
      case "click":
      default:
        element.addEventListener("click", this.toggle);
    }

    return () => {
      document.removeEventListener("keydown", this.keyDown);

      switch (this.eventTrigger) {
        case "none":
          break;
        case "hover":
          element.removeEventListener("mouseenter", this.open);
          element.removeEventListener("mouseleave", this.close);
          break;
        case "click":
        default:
          element.removeEventListener("click", this.toggle);
      }
    };
  });

  updateSelected = modifier(() => {
    if (this.selected) {
      this.selectedItem = this.allItemsFlattened.find((item) =>
        isEqual(item[this.valueKey], this.selected[this.valueKey] || this.selected),
      );
    }
  });

  @action
  keyDown(event) {
    if (event.key === "Escape") {
      this.close();
    }
  }

  @action
  open() {
    this.isOpen = true;
  }

  @action
  close() {
    this.isOpen = false;
    this.onClose?.();
  }

  @action
  toggle(event) {
    event.stopPropagation();
    this.isOpen = !this.isOpen;
  }

  @action
  selectItem(item) {
    this.onChange?.(item);
    this.close();
  }
}
