/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { service } from "@ember/service";
import { task, timeout } from "ember-concurrency";
import ENV from "glesys-controlpanel/config/environment";

export default class extends Component {
  @service ajax;

  @tracked serviceInfo = [];

  constructor() {
    super(...arguments);
    this.fetchServiceInfo.perform();
  }

  willDestroy() {
    super.willDestroy(...arguments);
    this.fetchServiceInfo.cancelAll();
  }

  fetchServiceInfo = task(async () => {
    let result = await this.ajax.request(`https://wf8tcthkr1hd.statuspage.io/api/v2/summary.json`);
    this.serviceInfo = result;
    if (ENV.environment !== "test") {
      await timeout(1000 * 60);
      this.fetchServiceInfo.perform();
    }
  });

  get maintenances() {
    return this.serviceInfo["scheduled_maintenances"]?.map((maintenance) => {
      return {
        name: maintenance.name,
        text: maintenance.incident_updates[0].body,
        date: maintenance.scheduled_for,
        link: maintenance.shortlink,
      };
    });
  }

  get incidents() {
    return this.serviceInfo["incidents"]?.map((incident) => {
      return {
        name: incident.name,
        text: incident.incident_updates[incident.incident_updates.length - 1].body,
        date: incident.updated_at,
        link: incident.shortlink,
      };
    });
  }

  get hasActiveMaintenance() {
    return !!this.maintenances?.length;
  }

  get hasActiveIncidents() {
    return !!this.incidents?.length;
  }
}
