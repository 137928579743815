import Application from "@ember/application";
import Resolver from "ember-resolver";
import loadInitializers from "ember-load-initializers";
import config from "glesys-controlpanel/config/environment";
import { importSync, isDevelopingApp, macroCondition } from "@embroider/macros";
import { extendResolver } from "ember-can";
import "./polyfill";
import "./assets/app.css";

/* istanbul ignore else */
if (macroCondition(isDevelopingApp())) {
  importSync("./deprecation-workflow");
}

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = extendResolver(Resolver);
}

loadInitializers(App, config.modulePrefix);
