import { template as template_ae2c5b850d124f2f8efd05881bb01e63 } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import colors from "glesys-controlpanel/helpers/colors";
export default template_ae2c5b850d124f2f8efd05881bb01e63(`
  <span
    class="text-2xs px-1 leading-none py-1 font-bold uppercase tracking-wide rounded {{colors 'orange'}}"
    {{mergeClasses}}
    ...attributes
  >
    {{@text}}
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
