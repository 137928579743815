import Route from "@ember/routing/route";
import { service } from "@ember/service";
import { action } from "@ember/object";
import { NotFoundError } from "glesys-controlpanel/utils/errors";
import { allRouteParams, updateUrlFor } from "glesys-controlpanel/utils/url";

export default class OrganizationRoute extends Route {
  @service current;
  @service abilities;
  @service session;
  @service router;
  @service store;
  @service errorHandler;

  queryParams = {
    project: {
      refreshModel: true,
    },
  };

  async model(params, transition) {
    let organization = this.store.peekRecord("organization", params.organization_id);
    let project;

    if (!organization) {
      return { organization: null, project: null };
    }

    let projects = await organization.get("projects");
    project = projects.find((project) => project.id === params.project);

    if (params.project && (!project || this.abilities.cannot("access project", project))) {
      return { organization, project: null };
    }

    project =
      project ||
      projects.find(
        (project) => project.id === this.current.project?.id || this.current.latestProjectFromLocalStorage,
      ) ||
      projects.find((proj) => this.abilities.can("access project", proj));

    if (params.project !== (project?.id || null)) {
      let params = allRouteParams(transition.to);
      await this.router.replaceWith(transition.to.name, ...params, {
        queryParams: { ...transition.to.queryParams, project: project?.id || null },
      });
    }

    return { organization, project };
  }

  afterModel({ organization, project }, transition) {
    transition.finally(() => {
      this.current.set("organization", organization);
      this.current.set("project", project);
    });

    if (!organization) {
      throw new NotFoundError();
    }
  }

  deactivate() {
    this.current.set("organization", null);
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.setupController(model);
  }

  @action
  error(error, transition) {
    this.errorHandler.handleError(error);
    updateUrlFor(transition, this.router);
    this.intermediateTransitionTo("dashboard.error", error);
    return false;
  }
}
