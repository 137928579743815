import { service } from "@ember/service";
import Route from "@ember/routing/route";
import RSVP from "rsvp";

export default class FileStorageVolumesDetailsRoute extends Route {
  @service ajax;
  @service store;

  model(params) {
    let volumeId = params.file_storage_volume_id;
    let { project } = this.modelFor("organization");
    let projectId = project.get("id");
    let ajax = this.ajax;

    let fileStorageVolume = this.store.queryRecord("file-storage-volume", {
      volumeid: volumeId,
      project: projectId,
    });

    let plans = ajax.request(`/io.php/filestorage/listplans?projectkey=${project.id}&volumeid=${volumeId}`);

    return RSVP.hash({
      project,
      fileStorageVolume,
      plans,
      servers: project.get("servers"),
    });
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.setupController();
  }

  resetController(controller, model) {
    super.resetController(controller, model);

    controller.resetController();
  }
}
