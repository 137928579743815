import { hash } from "rsvp";
import Route from "@ember/routing/route";
import { task } from "ember-concurrency";
import { service } from "@ember/service";

export default class ArchivesRoute extends Route {
  @service ajax;
  @service store;

  buildRouteInfoMetadata() {
    return { jumpable: true };
  }

  model() {
    let { project } = this.modelFor("organization");
    return hash({
      project,
      archives: project.get("archives"),
      archiveArguments: project.get("archiveArguments"), // TODO: Load this when needed (in modal)
    });
  }

  afterModel({ project, archives }) {
    archives.forEach((archive) => {
      this.fetchDetailsTask.perform(project, archive);
    });
  }

  fetchDetailsTask = task(async (project, archive) => {
    await archive.reload();
  });
}
