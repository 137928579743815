import { service } from "@ember/service";
import Route from "@ember/routing/route";
import RSVP from "rsvp";
import { action } from "@ember/object";
import { restartableTask, timeout } from "ember-concurrency";
import ENV from "glesys-controlpanel/config/environment";
import { NotFoundError } from "glesys-controlpanel/utils/errors";

export default class DnsDomainRoute extends Route {
  @service ajax;
  @service router;
  @service store;
  @service intl;

  model(params) {
    let { project, domains, pricelist } = this.modelFor("dns");
    let domainId = params.dns_domain_id;
    let domain = domains.find((domain) => domain.id === domainId);

    if (!domain) {
      throw new NotFoundError(this.intl.t("dns.domain.not-found", { domain: domainId }));
    }

    return RSVP.hash({
      project,
      domain,
      organization: project.get("organization"),
      prices: pricelist,
      servers: project.get("servers"),
    });
  }

  setupController() {
    super.setupController(...arguments);
    this.fetchDetailsLoopTask.perform();
  }

  resetController() {
    super.resetController(...arguments);
    this.fetchDetailsLoopTask.cancelAll();
  }

  async fetchDetails({ domain }) {
    try {
      await domain.reload();
    } catch (error) {
      if (error?.errors?.at(0)?.status == 404 || domain.get("isDeleted")) {
        this.store.unloadRecord(domain);
        this.router.transitionTo("dns.overview");
      } else {
        throw error;
      }
    }
  }

  @action
  reloadDetails() {
    let model = this.modelFor(this.routeName);
    this.fetchDetails(model);
  }

  fetchDetailsLoopTask = restartableTask(async () => {
    while (true) {
      this.reloadDetails();

      if (ENV.environment === "test") {
        return;
      }

      await timeout(5 * 60 * 1000);
    }
  });
}
