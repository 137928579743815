import { service } from "@ember/service";
import Controller from "@ember/controller";
import { task } from "ember-concurrency";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";

export default class FileStorageVolumesCreateController extends Controller {
  @service metrics;
  @service router;
  @service store;

  get project() {
    return this.model.project;
  }

  get dataCenters() {
    return this.model.dataCenters;
  }

  get plans() {
    return this.model.plans.plans;
  }

  get supportedDataCenters() {
    return this.dataCenters.filter((dataCenter) => dataCenter.hasFileStorageVolumeSupport);
  }

  @tracked hasAutoPromotion = true;
  @tracked name = "";
  @tracked selectedDataCenter = "";
  @tracked selectedPlan = null;

  get canNotSubmit() {
    return (
      this.project.hasReadAccess ||
      this.project.organization.isRestricted ||
      !this.name ||
      !this.selectedDataCenter ||
      !this.selectedPlan
    );
  }

  createFileStorageVolumeTask = task(async () => {
    let { hasAutoPromotion, project, name, selectedDataCenter, selectedPlan } = this;

    let volume = this.store.createRecord("file-storage-volume", {
      project,
      dataCenter: selectedDataCenter,
      hasAutoPromotion,
      name,
      plan: selectedPlan,
    });

    await volume.save().catch((error) => {
      volume.unloadRecord();
      throw error;
    });

    this.metrics.trackEvent("Intercom", {
      event: "created-file-storage-volume",
    });

    this.router.transitionTo("file-storage-volumes.details", volume.id);
  });

  @action
  onFormChange({ name, hasAutoPromotion }) {
    this.name = name;
    this.hasAutoPromotion = Boolean(hasAutoPromotion);
  }

  @action
  dataCenterChanged(dataCenter) {
    this.selectedDataCenter = dataCenter.name;
  }

  @action
  selectPlan(plan) {
    this.selectedPlan = plan;
  }
}
