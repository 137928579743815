import { template as template_52c469caddf84e15bc738863a466f545 } from "@ember/template-compiler";
import PillError from "glesys-controlpanel/components/pill/error";
import PillWarning from "glesys-controlpanel/components/pill/warning";
import PillInfo from "glesys-controlpanel/components/pill/info";
import Pill from "glesys-controlpanel/components/pill";
import InfoPopup from "glesys-controlpanel/components/info-popup";
import { t } from "ember-intl";
import { and, eq, not } from "ember-truth-helpers";
export default template_52c469caddf84e15bc738863a466f545(`
  <div ...attributes>
    {{#if (eq @domain.state "glesys-expired")}}
      <PillError @text="{{t 'dns.domain.state.expired'}}" />
    {{/if}}
    {{#if (and (not @domain.isAutoRenewalActive) (eq @domain.state "glesys-expiring"))}}
      <PillWarning @text="{{t 'dns.domain.state.expiring' date=@domain.registrarinfo.expire}}" />
    {{/if}}
    {{#if (and @domain.isAutoRenewalActive (eq @domain.state "glesys-expiring"))}}
      <PillInfo @text="{{t 'dns.domain.state.renews' date=@domain.renewalDate}}" />
    {{/if}}
    {{#if (eq @domain.state "glesys-waiting")}}
      <Pill @text="{{t 'dns.domain.state.waiting'}}" data-test-state-waiting />
    {{/if}}
    {{#if (eq @domain.state "glesys-failed")}}
      <PillError @text="{{t 'dns.domain.state.failed'}}" />
      {{#if @domain.failedDescription}}
        <InfoPopup class="ml-2 mt-1">
          <p>{{@domain.failedDescription}}</p>
          <p>{{t "label.for-more-info"}}</p>
        </InfoPopup>
      {{/if}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
