import { template as template_18fba61961f746a8bbf6c7f2ee30c452 } from "@ember/template-compiler";
import GButton from "./";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsEye from "../icons/eye";
const isNotCompact = (display)=>display !== "compact";
export default template_18fba61961f746a8bbf6c7f2ee30c452(`
  <GButton
    @type={{@type}}
    @color="outline"
    @disabled={{@disabled}}
    @loading={{@loading}}
    @display={{if @display @display "default"}}
    {{mergeClasses}}
    ...attributes
  >
    <div class="flex items-center">
      <IconsEye class="w-5 mr-1 {{if (isNotCompact @display) '-ml-1.5'}} -mt-px text-rebeccapurple" />
      <span>{{yield}}</span>
    </div>
  </GButton>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
