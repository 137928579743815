import Route from "@ember/routing/route";
import { service } from "@ember/service";
import { action } from "@ember/object";
import { allRouteParams, updateUrlFor } from "glesys-controlpanel/utils/url";

export default class ApplicationRoute extends Route {
  title = "GleSYS Control Panel";

  queryParams = {
    locale: {
      replace: true,
    },
    utm_source: {
      replace: true,
    },
    utm_medium: {
      replace: true,
    },
    utm_content: {
      replace: true,
    },
    utm_campaign: {
      replace: true,
    },
  };

  @service metrics;
  @service router;
  @service session;
  @service errorHandler;

  constructor() {
    super(...arguments);

    this.router.on("routeWillChange", ({ from, to }) => {
      if (!this.session.sidebarOpen) {
        return;
      }

      let fromOrganization = allRouteParams(from).at(0);
      let toOrganization = allRouteParams(to).at(0);

      // If we are switching organization we want the sidebar to be kept open
      // since you probably want to keep selecting a service in the sidebar.
      if (toOrganization && toOrganization !== fromOrganization) {
        return;
      }

      this.session.closeSidebar();
    });

    this.router.on("routeDidChange", () => {
      window.scrollTo(0, 0);
      this.metrics.trackPage("GoogleAnalytics", {
        page: this.router.currentRouteName,
      });

      /* istanbul ignore if */
      if (window.Intercom) {
        window.Intercom("update");
      }
    });
  }

  activate() {
    let spinner = document.querySelector("#initial-spinner");
    if (spinner) {
      document.body.removeChild(spinner);
    }
  }

  beforeModel() {
    return this.session.fetch.perform();
  }

  @action
  error(error, transition) {
    this.errorHandler.handleError(error);
    updateUrlFor(transition, this.router);
    this.intermediateTransitionTo("error", error);
    return false;
  }

  @action
  logout(status = "logout") {
    this.session.close.perform(status);
  }
}
