import { deprecate, warn } from '@ember/debug';
import { macroCondition, getGlobalConfig, dependencySatisfies, importSync } from '@embroider/macros';
import { EnableHydration, SkipCache } from '@warp-drive/core-types/request';
import { setLogging, getRuntimeConfig } from '@warp-drive/core-types/runtime';
import { getOrSetGlobal, peekTransient, setTransient } from '@warp-drive/core-types/-private';
import { _backburner } from '@ember/runloop';
import { defineSignal, createSignal, subscribe, createArrayTags, addToTransaction, addTransactionCB } from '@ember-data/tracking/-private';
import { CACHE_OWNER, DEBUG_STALE_CACHE_OWNER, DEBUG_IDENTIFIER_BUCKET, DEBUG_CLIENT_ORIGINATED } from '@warp-drive/core-types/identifier';
import { dasherize } from '@ember-data/request-utils/string';
import { compat } from '@ember-data/tracking';

/**
  @module @ember-data/store
*/

function coerceId(id) {
  if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_NON_STRICT_ID)) {
    let normalized;
    if (id === null || id === undefined || id === '') {
      normalized = null;
    } else {
      normalized = String(id);
    }
    deprecate(`The resource id '<${typeof id}> ${String(id)} ' is not normalized. Update your application code to use '${JSON.stringify(normalized)}' instead.`, normalized === id, {
      id: 'ember-data:deprecate-non-strict-id',
      until: '6.0',
      for: 'ember-data',
      since: {
        available: '4.13',
        enabled: '5.3'
      }
    });
    return normalized;
  }
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Resource IDs must be a non-empty string or null. Received '${String(id)}'.`);
    }
  })(id === null || typeof id === 'string' && id.length > 0) : {};
  return id;
}
function ensureStringId(id) {
  let normalized = null;
  if (typeof id === 'string') {
    normalized = id.length > 0 ? id : null;
  } else if (typeof id === 'number' && !isNaN(id)) {
    normalized = String(id);
  }
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected id to be a string or number, received ${String(id)}`);
    }
  })(normalized !== null) : {};
  return normalized;
}
function normalizeModelName(type) {
  if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_NON_STRICT_TYPES)) {
    const result = dasherize(type);
    deprecate(`The resource type '${type}' is not normalized. Update your application code to use '${result}' instead of '${type}'.`, result === type, {
      id: 'ember-data:deprecate-non-strict-types',
      until: '6.0',
      for: 'ember-data',
      since: {
        available: '4.13',
        enabled: '5.3'
      }
    });
    return result;
  }
  return type;
}

/**
  @module @ember-data/store
*/

function installPolyfill() {
  const isFastBoot = typeof FastBoot !== 'undefined';
  const CRYPTO = isFastBoot ? FastBoot.require('crypto') : globalThis.crypto;
  if (!CRYPTO.randomUUID) {
    // we might be able to optimize this by requesting more bytes than we need at a time
    const rng = function () {
      // WHATWG crypto RNG - http://wiki.whatwg.org/wiki/Crypto
      const rnds8 = new Uint8Array(16);
      if (!CRYPTO.getRandomValues && !isFastBoot) {
        throw new Error(`Unable to generate bytes for UUID`);
      }
      return CRYPTO.getRandomValues ? CRYPTO.getRandomValues(rnds8) : CRYPTO.randomFillSync(rnds8);
    };

    /*
     * Convert array of 16 byte values to UUID string format of the form:
     * XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX
     */
    const byteToHex = [];
    for (let i = 0; i < 256; ++i) {
      byteToHex[i] = (i + 0x100).toString(16).substr(1);
    }
    const bytesToUuid = function (buf) {
      const bth = byteToHex;
      // join used to fix memory issue caused by concatenation: https://bugs.chromium.org/p/v8/issues/detail?id=3175#c4
      return [bth[buf[0]], bth[buf[1]], bth[buf[2]], bth[buf[3]], '-', bth[buf[4]], bth[buf[5]], '-', bth[buf[6]], bth[buf[7]], '-', bth[buf[8]], bth[buf[9]], '-', bth[buf[10]], bth[buf[11]], bth[buf[12]], bth[buf[13]], bth[buf[14]], bth[buf[15]]].join('');
    };
    CRYPTO.randomUUID = function uuidv4() {
      const rnds = rng();

      // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`
      rnds[6] = rnds[6] & 0x0f | 0x40;
      rnds[8] = rnds[8] & 0x3f | 0x80;
      return bytesToUuid(rnds);
    };
  }
}
function isResource(resource) {
  return Boolean(resource && typeof resource === 'object');
}
function hasProp(resource, prop) {
  return Boolean(isResource(resource) && prop in resource && typeof resource[prop] === 'string' && resource[prop].length);
}
function hasLid(resource) {
  return hasProp(resource, 'lid');
}
function hasId(resource) {
  return hasProp(resource, 'id') || Boolean(isResource(resource) && 'id' in resource && typeof resource.id === 'number');
}
function hasType(resource) {
  return hasProp(resource, 'type');
}

/**
  @module @ember-data/store
*/
const DOCUMENTS = getOrSetGlobal('DOCUMENTS', new Set());
function isStableIdentifier(identifier) {
  return identifier[CACHE_OWNER] !== undefined;
}
function isDocumentIdentifier(identifier) {
  return DOCUMENTS.has(identifier);
}
const isFastBoot = typeof FastBoot !== 'undefined';
const _crypto = isFastBoot ? FastBoot.require('crypto') : globalThis.crypto;
if (macroCondition(getGlobalConfig().WarpDrive.polyfillUUID)) {
  installPolyfill();
}
function uuidv4() {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error('crypto.randomUUID needs to be avaliable. Some browsers incorrectly disallow it in insecure contexts. You maybe want to enable the polyfill: https://github.com/emberjs/data#randomuuid-polyfill');
    }
  })(typeof _crypto.randomUUID === 'function') : {};
  return _crypto.randomUUID();
}
function freeze(obj) {
  if (typeof Object.freeze === 'function') {
    return Object.freeze(obj);
  }
  return obj;
}

// type IdentifierTypeLookup = { all: Set<StableRecordIdentifier>; id: Map<string, StableRecordIdentifier> };
// type IdentifiersByType = Map<string, IdentifierTypeLookup>;

function setIdentifierGenerationMethod(method) {
  setTransient('configuredGenerationMethod', method);
}
function setIdentifierUpdateMethod(method) {
  setTransient('configuredUpdateMethod', method);
}
function setIdentifierForgetMethod(method) {
  setTransient('configuredForgetMethod', method);
}
function setIdentifierResetMethod(method) {
  setTransient('configuredResetMethod', method);
}
function setKeyInfoForResource(method) {
  setTransient('configuredKeyInfoMethod', method);
}

// Map<type, Map<id, lid>>

// TODO can we just delete this?
const NEW_IDENTIFIERS = new Map();
// TODO @runspired maybe needs peekTransient ?
let IDENTIFIER_CACHE_ID = 0;
function updateTypeIdMapping(typeMap, identifier, id) {
  let idMap = typeMap.get(identifier.type);
  if (!idMap) {
    idMap = new Map();
    typeMap.set(identifier.type, idMap);
  }
  idMap.set(id, identifier.lid);
}
function defaultUpdateMethod(identifier, data, bucket) {
  if (bucket === 'record') {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected identifier to be a StableRecordIdentifier`);
      }
    })(isStableIdentifier(identifier)) : {};
    if (!identifier.id && hasId(data)) {
      updateTypeIdMapping(NEW_IDENTIFIERS, identifier, data.id);
    }
  }
}
function defaultKeyInfoMethod(resource, known) {
  // TODO RFC something to make this configurable
  const id = hasId(resource) ? coerceId(resource.id) : null;
  const type = hasType(resource) ? normalizeModelName(resource.type) : known ? known.type : null;
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected keyInfoForResource to provide a type for the resource`);
    }
  })(type) : {};
  return {
    type,
    id
  };
}
function defaultGenerationMethod(data, bucket) {
  if (bucket === 'record') {
    if (hasLid(data)) {
      return data.lid;
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Cannot generate an identifier for a resource without a type`);
      }
    })(hasType(data)) : {};
    if (hasId(data)) {
      const type = normalizeModelName(data.type);
      const lid = NEW_IDENTIFIERS.get(type)?.get(data.id);
      return lid || `@lid:${type}-${data.id}`;
    }
    return uuidv4();
  } else if (bucket === 'document') {
    if (!data.url) {
      return null;
    }
    if (!data.method || data.method.toUpperCase() === 'GET') {
      return data.url;
    }
    return null;
  }
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    {
      throw new Error(`Unknown bucket ${bucket}`);
    }
  })() : {};
}
function defaultEmptyCallback(...args) {}
function defaultMergeMethod(a, _b, _c) {
  return a;
}
let DEBUG_MAP;
if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
  DEBUG_MAP = getOrSetGlobal('DEBUG_MAP', new WeakMap());
}

/**
 * Each instance of {Store} receives a unique instance of a IdentifierCache.
 *
 * This cache is responsible for assigning or retrieving the unique identify
 * for arbitrary resource data encountered by the store. Data representing
 * a unique resource or record should always be represented by the same
 * identifier.
 *
 * It can be configured by consuming applications.
 *
 * @class IdentifierCache
   @public
 */
class IdentifierCache {
  constructor() {
    // we cache the user configuredGenerationMethod at init because it must
    // be configured prior and is not allowed to be changed
    this._generate = peekTransient('configuredGenerationMethod') || defaultGenerationMethod;
    this._update = peekTransient('configuredUpdateMethod') || defaultUpdateMethod;
    this._forget = peekTransient('configuredForgetMethod') || defaultEmptyCallback;
    this._reset = peekTransient('configuredResetMethod') || defaultEmptyCallback;
    this._merge = defaultMergeMethod;
    this._keyInfoForResource = peekTransient('configuredKeyInfoMethod') || defaultKeyInfoMethod;
    this._id = IDENTIFIER_CACHE_ID++;
    this._cache = {
      resources: new Map(),
      resourcesByType: Object.create(null),
      documents: new Map(),
      polymorphicLidBackMap: new Map()
    };
  }

  /**
   * Internal hook to allow management of merge conflicts with identifiers.
   *
   * we allow late binding of this private internal merge so that
   * the cache can insert itself here to handle elimination of duplicates
   *
   * @method __configureMerge
   * @private
   */
  __configureMerge(method) {
    this._merge = method || defaultMergeMethod;
  }
  upgradeIdentifier(resource) {
    return this._getRecordIdentifier(resource, 2);
  }

  /**
   * @method _getRecordIdentifier
   * @private
   */

  _getRecordIdentifier(resource, shouldGenerate) {
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_IDENTIFIERS)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_IDENTIFIERS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_IDENTIFIERS) {
        // eslint-disable-next-line no-console
        console.groupCollapsed(`Identifiers: ${shouldGenerate ? 'Generating' : 'Peeking'} Identifier`, resource);
      }
    }
    // short circuit if we're already the stable version
    if (isStableIdentifier(resource)) {
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        // TODO should we instead just treat this case as a new generation skipping the short circuit?
        if (!this._cache.resources.has(resource.lid) || this._cache.resources.get(resource.lid) !== resource) {
          throw new Error(`The supplied identifier ${JSON.stringify(resource)} does not belong to this store instance`);
        }
      }
      if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_IDENTIFIERS)) {
        if (getGlobalConfig().WarpDrive.debug.LOG_IDENTIFIERS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_IDENTIFIERS) {
          // eslint-disable-next-line no-console
          console.log(`Identifiers: cache HIT - Stable ${resource.lid}`);
          // eslint-disable-next-line no-console
          console.groupEnd();
        }
      }
      return resource;
    }

    // the resource is unknown, ask the application to identify this data for us
    const lid = this._generate(resource, 'record');
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_IDENTIFIERS)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_IDENTIFIERS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_IDENTIFIERS) {
        // eslint-disable-next-line no-console
        console.log(`Identifiers: ${lid ? 'no ' : ''}lid ${lid ? lid + ' ' : ''}determined for resource`, resource);
      }
    }
    let identifier = /*#__NOINLINE__*/getIdentifierFromLid(this._cache, lid, resource);
    if (identifier !== null) {
      if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_IDENTIFIERS)) {
        if (getGlobalConfig().WarpDrive.debug.LOG_IDENTIFIERS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_IDENTIFIERS) {
          // eslint-disable-next-line no-console
          console.groupEnd();
        }
      }
      return identifier;
    }
    if (shouldGenerate === 0) {
      if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_IDENTIFIERS)) {
        if (getGlobalConfig().WarpDrive.debug.LOG_IDENTIFIERS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_IDENTIFIERS) {
          // eslint-disable-next-line no-console
          console.groupEnd();
        }
      }
      return;
    }

    // if we still don't have an identifier, time to generate one
    if (shouldGenerate === 2) {
      resource.lid = lid;
      resource[CACHE_OWNER] = this._id;
      identifier = /*#__NOINLINE__*/makeStableRecordIdentifier(resource, 'record', false);
    } else {
      // we lie a bit here as a memory optimization
      const keyInfo = this._keyInfoForResource(resource, null);
      keyInfo.lid = lid;
      keyInfo[CACHE_OWNER] = this._id;
      identifier = /*#__NOINLINE__*/makeStableRecordIdentifier(keyInfo, 'record', false);
    }
    addResourceToCache(this._cache, identifier);
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_IDENTIFIERS)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_IDENTIFIERS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_IDENTIFIERS) {
        // eslint-disable-next-line no-console
        console.groupEnd();
      }
    }
    return identifier;
  }

  /**
   * allows us to peek without generating when needed
   * useful for the "create" case when we need to see if
   * we are accidentally overwritting something
   *
   * @method peekRecordIdentifier
   * @param resource
   * @return {StableRecordIdentifier | undefined}
   * @private
   */
  peekRecordIdentifier(resource) {
    return this._getRecordIdentifier(resource, 0);
  }

  /**
    Returns the DocumentIdentifier for the given Request, creates one if it does not yet exist.
    Returns `null` if the request does not have a `cacheKey` or `url`.
     @method getOrCreateDocumentIdentifier
    @param request
    @return {StableDocumentIdentifier | null}
    @public
  */
  getOrCreateDocumentIdentifier(request) {
    let cacheKey = request.cacheOptions?.key;
    if (!cacheKey) {
      cacheKey = this._generate(request, 'document');
    }
    if (!cacheKey) {
      return null;
    }
    let identifier = this._cache.documents.get(cacheKey);
    if (identifier === undefined) {
      identifier = {
        lid: cacheKey
      };
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        Object.freeze(identifier);
      }
      DOCUMENTS.add(identifier);
      this._cache.documents.set(cacheKey, identifier);
    }
    return identifier;
  }

  /**
    Returns the Identifier for the given Resource, creates one if it does not yet exist.
     Specifically this means that we:
     - validate the `id` `type` and `lid` combo against known identifiers
    - return an object with an `lid` that is stable (repeated calls with the same
      `id` + `type` or `lid` will return the same `lid` value)
    - this referential stability of the object itself is guaranteed
     @method getOrCreateRecordIdentifier
    @param resource
    @return {StableRecordIdentifier}
    @public
  */
  getOrCreateRecordIdentifier(resource) {
    return this._getRecordIdentifier(resource, 1);
  }

  /**
   Returns a new Identifier for the supplied data. Call this method to generate
   an identifier when a new resource is being created local to the client and
   potentially does not have an `id`.
    Delegates generation to the user supplied `GenerateMethod` if one has been provided
   with the signature `generateMethod({ type }, 'record')`.
    @method createIdentifierForNewRecord
   @param data
   @return {StableRecordIdentifier}
   @public
  */
  createIdentifierForNewRecord(data) {
    const newLid = this._generate(data, 'record');
    const identifier = /*#__NOINLINE__*/makeStableRecordIdentifier({
      id: data.id || null,
      type: data.type,
      lid: newLid,
      [CACHE_OWNER]: this._id
    }, 'record', true);

    // populate our unique table
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      if (this._cache.resources.has(identifier.lid)) {
        throw new Error(`The lid generated for the new record is not unique as it matches an existing identifier`);
      }
    }

    /*#__NOINLINE__*/
    addResourceToCache(this._cache, identifier);
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_IDENTIFIERS)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_IDENTIFIERS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_IDENTIFIERS) {
        // eslint-disable-next-line no-console
        console.log(`Identifiers: created identifier ${String(identifier)} for newly generated resource`, data);
      }
    }
    return identifier;
  }

  /**
   Provides the opportunity to update secondary lookup tables for existing identifiers
   Called after an identifier created with `createIdentifierForNewRecord` has been
   committed.
    Assigned `id` to an `Identifier` if `id` has not previously existed; however,
   attempting to change the `id` or calling update without providing an `id` when
   one is missing will throw an error.
     - sets `id` (if `id` was previously `null`)
    - `lid` and `type` MUST NOT be altered post creation
     If a merge occurs, it is possible the returned identifier does not match the originally
    provided identifier. In this case the abandoned identifier will go through the usual
    `forgetRecordIdentifier` codepaths.
     @method updateRecordIdentifier
    @param identifierObject
    @param data
    @return {StableRecordIdentifier}
    @public
  */
  updateRecordIdentifier(identifierObject, data) {
    let identifier = this.getOrCreateRecordIdentifier(identifierObject);
    const keyInfo = this._keyInfoForResource(data, identifier);
    let existingIdentifier = /*#__NOINLINE__*/detectMerge(this._cache, keyInfo, identifier, data);
    const hadLid = hasLid(data);
    if (!existingIdentifier) {
      // If the incoming type does not match the identifier type, we need to create an identifier for the incoming
      // data so we can merge the incoming data with the existing identifier, see #7325 and #7363
      if (identifier.type !== keyInfo.type) {
        if (hadLid) {
          // Strip the lid to ensure we force a new identifier creation
          delete data.lid;
        }
        existingIdentifier = this.getOrCreateRecordIdentifier(data);
      }
    }
    if (existingIdentifier) {
      const generatedIdentifier = identifier;
      identifier = this._mergeRecordIdentifiers(keyInfo, generatedIdentifier, existingIdentifier, data);

      // make sure that the `lid` on the data we are processing matches the lid we kept
      if (hadLid) {
        data.lid = identifier.lid;
      }
      if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_IDENTIFIERS)) {
        if (getGlobalConfig().WarpDrive.debug.LOG_IDENTIFIERS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_IDENTIFIERS) {
          // eslint-disable-next-line no-console
          console.log(`Identifiers: merged identifiers ${generatedIdentifier.lid} and ${existingIdentifier.lid} for resource into ${identifier.lid}`, data);
        }
      }
    }
    const id = identifier.id;
    /*#__NOINLINE__*/
    performRecordIdentifierUpdate(identifier, keyInfo, data, this._update);
    const newId = identifier.id;

    // add to our own secondary lookup table
    if (id !== newId && newId !== null) {
      if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_IDENTIFIERS)) {
        if (getGlobalConfig().WarpDrive.debug.LOG_IDENTIFIERS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_IDENTIFIERS) {
          // eslint-disable-next-line no-console
          console.log(`Identifiers: updated id for identifier ${identifier.lid} from '${String(id)}' to '${String(newId)}' for resource`, data);
        }
      }
      const typeSet = this._cache.resourcesByType[identifier.type];
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Expected to find a typeSet for ${identifier.type}`);
        }
      })(typeSet) : {};
      typeSet.id.set(newId, identifier);
      if (id !== null) {
        typeSet.id.delete(id);
      }
    } else if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_IDENTIFIERS)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_IDENTIFIERS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_IDENTIFIERS) {
        // eslint-disable-next-line no-console
        console.log(`Identifiers: updated identifier ${identifier.lid} resource`, data);
      }
    }
    return identifier;
  }

  /**
   * @method _mergeRecordIdentifiers
   * @private
   */
  _mergeRecordIdentifiers(keyInfo, identifier, existingIdentifier, data) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected keyInfo to contain an id`);
      }
    })(hasId(keyInfo)) : {};
    // delegate determining which identifier to keep to the configured MergeMethod
    const kept = this._merge(identifier, existingIdentifier, data);
    const abandoned = kept === identifier ? existingIdentifier : identifier;

    // get any backreferences before forgetting this identifier, as it will be removed from the cache
    // and we will no longer be able to find them
    const abandonedBackReferences = this._cache.polymorphicLidBackMap.get(abandoned.lid);
    // delete the backreferences for the abandoned identifier so that forgetRecordIdentifier
    // does not try to remove them.
    if (abandonedBackReferences) this._cache.polymorphicLidBackMap.delete(abandoned.lid);

    // cleanup the identifier we no longer need
    this.forgetRecordIdentifier(abandoned);

    // ensure a secondary cache entry for the original lid for the abandoned identifier
    this._cache.resources.set(abandoned.lid, kept);

    // backReferences let us know which other identifiers are pointing at this identifier
    // so we can delete them later if we forget this identifier
    const keptBackReferences = this._cache.polymorphicLidBackMap.get(kept.lid) ?? [];
    keptBackReferences.push(abandoned.lid);

    // update the backreferences from the abandoned identifier to be for the kept identifier
    if (abandonedBackReferences) {
      abandonedBackReferences.forEach(lid => {
        keptBackReferences.push(lid);
        this._cache.resources.set(lid, kept);
      });
    }
    this._cache.polymorphicLidBackMap.set(kept.lid, keptBackReferences);
    return kept;
  }

  /**
   Provides the opportunity to eliminate an identifier from secondary lookup tables
   as well as eliminates it from ember-data's own lookup tables and book keeping.
    Useful when a record has been deleted and the deletion has been persisted and
   we do not care about the record anymore. Especially useful when an `id` of a
   deleted record might be reused later for a new record.
    @method forgetRecordIdentifier
   @param identifierObject
   @public
  */
  forgetRecordIdentifier(identifierObject) {
    const identifier = this.getOrCreateRecordIdentifier(identifierObject);
    const typeSet = this._cache.resourcesByType[identifier.type];
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected to find a typeSet for ${identifier.type}`);
      }
    })(typeSet) : {};
    if (identifier.id !== null) {
      typeSet.id.delete(identifier.id);
    }
    this._cache.resources.delete(identifier.lid);
    typeSet.lid.delete(identifier.lid);
    const backReferences = this._cache.polymorphicLidBackMap.get(identifier.lid);
    if (backReferences) {
      backReferences.forEach(lid => {
        this._cache.resources.delete(lid);
      });
      this._cache.polymorphicLidBackMap.delete(identifier.lid);
    }
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      identifier[DEBUG_STALE_CACHE_OWNER] = identifier[CACHE_OWNER];
    }
    identifier[CACHE_OWNER] = undefined;
    this._forget(identifier, 'record');
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_IDENTIFIERS)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_IDENTIFIERS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_IDENTIFIERS) {
        // eslint-disable-next-line no-console
        console.log(`Identifiers: released identifier ${identifierObject.lid}`);
      }
    }
  }
  destroy() {
    NEW_IDENTIFIERS.clear();
    this._cache.documents.forEach(identifier => {
      DOCUMENTS.delete(identifier);
    });
    this._reset();
  }
}
function makeStableRecordIdentifier(recordIdentifier, bucket, clientOriginated) {
  if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
    // we enforce immutability in dev
    //  but preserve our ability to do controlled updates to the reference
    let wrapper = {
      type: recordIdentifier.type,
      lid: recordIdentifier.lid,
      get id() {
        return recordIdentifier.id;
      }
    };
    const proto = {
      get [CACHE_OWNER]() {
        return recordIdentifier[CACHE_OWNER];
      },
      set [CACHE_OWNER](value) {
        recordIdentifier[CACHE_OWNER] = value;
      },
      get [DEBUG_STALE_CACHE_OWNER]() {
        return recordIdentifier[DEBUG_STALE_CACHE_OWNER];
      },
      set [DEBUG_STALE_CACHE_OWNER](value) {
        recordIdentifier[DEBUG_STALE_CACHE_OWNER] = value;
      },
      get [DEBUG_CLIENT_ORIGINATED]() {
        return clientOriginated;
      },
      get [DEBUG_IDENTIFIER_BUCKET]() {
        return bucket;
      }
    };
    Object.defineProperty(proto, 'toString', {
      enumerable: false,
      value: () => {
        const {
          type,
          id,
          lid
        } = recordIdentifier;
        return `${clientOriginated ? '[CLIENT_ORIGINATED] ' : ''}${String(type)}:${String(id)} (${lid})`;
      }
    });
    Object.defineProperty(proto, 'toJSON', {
      enumerable: false,
      value: () => {
        const {
          type,
          id,
          lid
        } = recordIdentifier;
        return {
          type,
          id,
          lid
        };
      }
    });
    Object.setPrototypeOf(wrapper, proto);
    DEBUG_MAP.set(wrapper, recordIdentifier);
    wrapper = freeze(wrapper);
    return wrapper;
  }
  return recordIdentifier;
}
function performRecordIdentifierUpdate(identifier, keyInfo, data, updateFn) {
  if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
    const {
      id,
      type
    } = keyInfo;

    // get the mutable instance behind our proxy wrapper
    const wrapper = identifier;
    identifier = DEBUG_MAP.get(wrapper);
    if (hasLid(data)) {
      const lid = data.lid;
      if (lid !== identifier.lid) {
        throw new Error(`The 'lid' for a RecordIdentifier cannot be updated once it has been created. Attempted to set lid for '${wrapper.lid}' to '${lid}'.`);
      }
    }
    if (id && identifier.id !== null && identifier.id !== id) {
      // here we warn and ignore, as this may be a mistake, but we allow the user
      // to have multiple cache-keys pointing at a single lid so we cannot error
      warn(`The 'id' for a RecordIdentifier should not be updated once it has been set. Attempted to set id for '${wrapper.lid}' to '${id}'.`, false, {
        id: 'ember-data:multiple-ids-for-identifier'
      });
    }

    // TODO consider just ignoring here to allow flexible polymorphic support
    if (type && type !== identifier.type) {
      throw new Error(`The 'type' for a RecordIdentifier cannot be updated once it has been set. Attempted to set type for '${wrapper.lid}' to '${type}'.`);
    }
    updateFn(wrapper, data, 'record');
  } else {
    updateFn(identifier, data, 'record');
  }

  // upgrade the ID, this is a "one time only" ability
  // for the multiple-cache-key scenario we "could"
  // use a heuristic to guess the best id for display
  // (usually when `data.id` is available and `data.attributes` is not)
  if (data.id !== undefined) {
    identifier.id = coerceId(data.id);
  }
}
function detectMerge(cache, keyInfo, identifier, data) {
  const newId = keyInfo.id;
  const {
    id,
    type,
    lid
  } = identifier;
  const typeSet = cache.resourcesByType[identifier.type];

  // if the IDs are present but do not match
  // then check if we have an existing identifier
  // for the newer ID.
  if (id !== null && id !== newId && newId !== null) {
    const existingIdentifier = typeSet && typeSet.id.get(newId);
    return existingIdentifier !== undefined ? existingIdentifier : false;
  } else {
    const newType = keyInfo.type;

    // If the ids and type are the same but lid is not the same, we should trigger a merge of the identifiers
    // we trigger a merge of the identifiers
    // though probably we should just throw an error here
    if (id !== null && id === newId && newType === type && hasLid(data) && data.lid !== lid) {
      return getIdentifierFromLid(cache, data.lid, data) || false;

      // If the lids are the same, and ids are the same, but types are different we should trigger a merge of the identifiers
    } else if (id !== null && id === newId && newType && newType !== type && hasLid(data) && data.lid === lid) {
      const newTypeSet = cache.resourcesByType[newType];
      const existingIdentifier = newTypeSet && newTypeSet.id.get(newId);
      return existingIdentifier !== undefined ? existingIdentifier : false;
    }
  }
  return false;
}
function getIdentifierFromLid(cache, lid, resource) {
  const identifier = cache.resources.get(lid);
  if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_IDENTIFIERS)) {
    if (getGlobalConfig().WarpDrive.debug.LOG_IDENTIFIERS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_IDENTIFIERS) {
      // eslint-disable-next-line no-console
      console.log(`Identifiers: cache ${identifier ? 'HIT' : 'MISS'} - Non-Stable ${lid}`, resource);
    }
  }
  return identifier || null;
}
function addResourceToCache(cache, identifier) {
  cache.resources.set(identifier.lid, identifier);
  let typeSet = cache.resourcesByType[identifier.type];
  if (!typeSet) {
    typeSet = {
      lid: new Map(),
      id: new Map()
    };
    cache.resourcesByType[identifier.type] = typeSet;
  }
  typeSet.lid.set(identifier.lid, identifier);
  if (identifier.id) {
    typeSet.id.set(identifier.id, identifier);
  }
}
const TEXT_COLORS = {
  TEXT: 'inherit',
  notify: ['white', 'white', 'inherit', 'magenta', 'inherit'],
  'reactive-ui': ['white', 'white', 'inherit', 'magenta', 'inherit'],
  graph: ['white', 'white', 'inherit', 'magenta', 'inherit'],
  request: ['white', 'white', 'inherit', 'magenta', 'inherit'],
  cache: ['white', 'white', 'inherit', 'magenta', 'inherit']
};
const BG_COLORS = {
  TEXT: 'transparent',
  notify: ['dimgray', 'cadetblue', 'transparent', 'transparent', 'transparent'],
  'reactive-ui': ['dimgray', 'cadetblue', 'transparent', 'transparent', 'transparent'],
  graph: ['dimgray', 'cadetblue', 'transparent', 'transparent', 'transparent'],
  request: ['dimgray', 'cadetblue', 'transparent', 'transparent', 'transparent'],
  cache: ['dimgray', 'cadetblue', 'transparent', 'transparent', 'transparent']
};
const NOTIFY_BORDER = {
  TEXT: 0,
  notify: [3, 2, 0, 0, 0],
  'reactive-ui': [3, 2, 0, 0, 0],
  graph: [3, 2, 0, 0, 0],
  request: [3, 2, 0, 0, 0],
  cache: [3, 2, 0, 0, 0]
};
const LIGHT_DARK_ALT = {
  lightgreen: 'green',
  green: 'lightgreen'
};
function badge(isLight, color, bgColor, border) {
  return [`color: ${correctColor(isLight, color)}; background-color: ${correctColor(isLight, bgColor)}; padding: ${border}px ${2 * border}px; border-radius: ${border}px;`, `color: ${TEXT_COLORS.TEXT}; background-color: ${BG_COLORS.TEXT};`];
}
function colorForBucket(isLight, scope, bucket) {
  if (scope === 'notify') {
    return bucket === 'added' ? badge(isLight, 'lightgreen', 'transparent', 0) : bucket === 'removed' ? badge(isLight, 'red', 'transparent', 0) : badge(isLight, TEXT_COLORS[scope][2], BG_COLORS[scope][2], NOTIFY_BORDER[scope][2]);
  }
  if (scope === 'reactive-ui') {
    return bucket === 'created' ? badge(isLight, 'lightgreen', 'transparent', 0) : bucket === 'disconnected' ? badge(isLight, 'red', 'transparent', 0) : badge(isLight, TEXT_COLORS[scope][2], BG_COLORS[scope][2], NOTIFY_BORDER[scope][2]);
  }
  if (scope === 'cache') {
    return bucket === 'inserted' ? badge(isLight, 'lightgreen', 'transparent', 0) : bucket === 'removed' ? badge(isLight, 'red', 'transparent', 0) : badge(isLight, TEXT_COLORS[scope][2], BG_COLORS[scope][2], NOTIFY_BORDER[scope][2]);
  }
  return badge(isLight, TEXT_COLORS[scope][3], BG_COLORS[scope][3], NOTIFY_BORDER[scope][3]);
}
function logGroup(scope, prefix, subScop1, subScop2, subScop3, subScop4) {
  // eslint-disable-next-line no-console
  console.groupCollapsed(..._log(scope, prefix, subScop1, subScop2, subScop3, subScop4));
}
function log(scope, prefix, subScop1, subScop2, subScop3, subScop4) {
  // eslint-disable-next-line no-console
  console.log(..._log(scope, prefix, subScop1, subScop2, subScop3, subScop4));
}
function correctColor(isLight, color) {
  if (!isLight) {
    return color;
  }
  return color in LIGHT_DARK_ALT ? LIGHT_DARK_ALT[color] : color;
}
function isLightMode() {
  if (window?.matchMedia?.('(prefers-color-scheme: light)').matches) {
    return true;
  }
  return false;
}
function _log(scope, prefix, subScop1, subScop2, subScop3, subScop4) {
  const isLight = isLightMode();
  switch (scope) {
    case 'reactive-ui':
    case 'notify':
      {
        const scopePath = prefix ? `[${prefix}] ${scope}` : scope;
        const path = subScop4 ? `${subScop3}.${subScop4}` : subScop3;
        return [`%c@warp%c-%cdrive%c %c${scopePath}%c %c${subScop1}%c %c${subScop2}%c %c${path}%c`, ...badge(isLight, 'lightgreen', 'transparent', 0), ...badge(isLight, 'magenta', 'transparent', 0), ...badge(isLight, TEXT_COLORS[scope][0], BG_COLORS[scope][0], NOTIFY_BORDER[scope][0]), ...badge(isLight, TEXT_COLORS[scope][1], BG_COLORS[scope][1], NOTIFY_BORDER[scope][1]), ...badge(isLight, TEXT_COLORS[scope][2], BG_COLORS[scope][2], NOTIFY_BORDER[scope][2]), ...colorForBucket(isLight, scope, path)];
      }
    case 'cache':
      {
        const scopePath = prefix ? `${scope} (${prefix})` : scope;
        return [`%c@warp%c-%cdrive%c %c${scopePath}%c %c${subScop1}%c %c${subScop2}%c %c${subScop3}%c %c${subScop4}%c`, ...badge(isLight, 'lightgreen', 'transparent', 0), ...badge(isLight, 'magenta', 'transparent', 0), ...badge(isLight, TEXT_COLORS[scope][0], BG_COLORS[scope][0], NOTIFY_BORDER[scope][0]), ...badge(isLight, TEXT_COLORS[scope][1], BG_COLORS[scope][1], NOTIFY_BORDER[scope][1]), ...badge(isLight, TEXT_COLORS[scope][2], BG_COLORS[scope][2], NOTIFY_BORDER[scope][2]), ...colorForBucket(isLight, scope, subScop3), ...badge(isLight, TEXT_COLORS[scope][4], BG_COLORS[scope][4], NOTIFY_BORDER[scope][4])];
      }
  }
  return [];
}

/**
  @module @ember-data/store
*/

/**
   A `RecordReference` is a low-level API that allows users and
   addon authors to perform meta-operations on a record.

   @class RecordReference
   @public
*/
class RecordReference {
  // unsubscribe token given to us by the notification manager
  ___token;
  ___identifier;
  constructor(store, identifier) {
    this.store = store;
    this.___identifier = identifier;
    this.___token = store.notifications.subscribe(identifier, (_, bucket, notifiedKey) => {
      if (bucket === 'identity' || bucket === 'attributes' && notifiedKey === 'id') {
        this._ref++;
      }
    });
  }
  destroy() {
    this.store.notifications.unsubscribe(this.___token);
  }
  get type() {
    return this.identifier().type;
  }

  /**
     The `id` of the record that this reference refers to.
      Together, the `type` and `id` properties form a composite key for
     the identity map.
      Example
      ```javascript
     let userRef = store.getReference('user', 1);
      userRef.id(); // '1'
     ```
      @method id
    @public
     @return {String} The id of the record.
  */
  id() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this._ref; // consume the tracked prop
    return this.___identifier.id;
  }

  /**
     The `identifier` of the record that this reference refers to.
      Together, the `type` and `id` properties form a composite key for
     the identity map.
      Example
      ```javascript
     let userRef = store.getReference('user', 1);
      userRef.identifier(); // '1'
     ```
      @method identifier
    @public
     @return {String} The identifier of the record.
  */
  identifier() {
    return this.___identifier;
  }

  /**
     How the reference will be looked up when it is loaded. Currently
     this always returns `identity` to signify that a record will be
     loaded by its `type` and `id`.
      Example
      ```javascript
     const userRef = store.getReference('user', 1);
      userRef.remoteType(); // 'identity'
     ```
      @method remoteType
     @public
     @return {String} 'identity'
  */
  remoteType() {
    return 'identity';
  }

  /**
    This API allows you to provide a reference with new data. The
    simplest usage of this API is similar to `store.push`: you provide a
    normalized hash of data and the object represented by the reference
    will update.
     If you pass a promise to `push`, Ember Data will not ask the adapter
    for the data if another attempt to fetch it is made in the
    interim. When the promise resolves, the underlying object is updated
    with the new data, and the promise returned by *this function* is resolved
    with that object.
     For example, `recordReference.push(promise)` will be resolved with a
    record.
      Example
      ```javascript
     let userRef = store.getReference('user', 1);
      // provide data for reference
     userRef.push({
       data: {
         id: "1",
         type: "user",
         attributes: {
           username: "@user"
         }
       }
     }).then(function(user) {
       userRef.value() === user;
     });
     ```
     @method push
    @public
    @param objectOrPromise a JSON:API ResourceDocument or a promise resolving to one
    @return a promise for the value (record or relationship)
  */
  push(objectOrPromise) {
    // TODO @deprecate pushing unresolved payloads
    return Promise.resolve(objectOrPromise).then(data => {
      return this.store.push(data);
    });
  }

  /**
    If the entity referred to by the reference is already loaded, it is
    present as `reference.value`. Otherwise the value returned by this function
    is `null`.
      Example
      ```javascript
     let userRef = store.getReference('user', 1);
      userRef.value(); // user
     ```
      @method value
    @public
     @return {Model} the record for this RecordReference
  */
  value() {
    return this.store.peekRecord(this.___identifier);
  }

  /**
     Triggers a fetch for the backing entity based on its `remoteType`
     (see `remoteType` definitions per reference type).
      Example
      ```javascript
     let userRef = store.getReference('user', 1);
      // load user (via store.find)
     userRef.load().then(...)
     ```
      @method load
    @public
     @return {Promise<record>} the record for this RecordReference
  */
  load() {
    const id = this.id();
    if (id !== null) {
      return this.store.findRecord(this.type, id);
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`Unable to fetch record of type ${this.type} without an id`);
      }
    })() : {};
  }

  /**
     Reloads the record if it is already loaded. If the record is not
     loaded it will load the record via `store.findRecord`
      Example
      ```javascript
     let userRef = store.getReference('user', 1);
      // or trigger a reload
     userRef.reload().then(...)
     ```
      @method reload
    @public
     @return {Promise<record>} the record for this RecordReference
  */
  reload() {
    const id = this.id();
    if (id !== null) {
      return this.store.findRecord(this.type, id, {
        reload: true
      });
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`Unable to fetch record of type ${this.type} without an id`);
      }
    })() : {};
  }
}
defineSignal(RecordReference.prototype, '_ref');

/**
  @module @ember-data/store
*/
class CacheCapabilitiesManager {
  constructor(_store) {
    this._store = _store;
    this._willNotify = false;
    this._pendingNotifies = new Map();
  }
  get identifierCache() {
    return this._store.identifierCache;
  }
  _scheduleNotification(identifier, key) {
    let pending = this._pendingNotifies.get(identifier);
    if (!pending) {
      pending = new Set();
      this._pendingNotifies.set(identifier, pending);
    }
    pending.add(key);
    if (this._willNotify === true) {
      return;
    }
    this._willNotify = true;
    // it's possible a cache adhoc notifies us,
    // in which case we sync flush
    if (this._store._cbs) {
      this._store._schedule('notify', () => this._flushNotifications());
    } else {
      // TODO @runspired determine if relationship mutations should schedule
      // into join/run vs immediate flush
      this._flushNotifications();
    }
  }
  _flushNotifications() {
    if (this._willNotify === false) {
      return;
    }
    const pending = this._pendingNotifies;
    this._pendingNotifies = new Map();
    this._willNotify = false;
    pending.forEach((set, identifier) => {
      set.forEach(key => {
        this._store.notifications.notify(identifier, 'relationships', key);
      });
    });
  }
  notifyChange(identifier, namespace, key) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected a stable identifier`);
      }
    })(isStableIdentifier(identifier) || isDocumentIdentifier(identifier)) : {};

    // TODO do we still get value from this?
    if (namespace === 'relationships' && key) {
      this._scheduleNotification(identifier, key);
      return;
    }

    // @ts-expect-error
    this._store.notifications.notify(identifier, namespace, key);
  }
  get schema() {
    return this._store.schema;
  }
  setRecordId(identifier, id) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected a stable identifier`);
      }
    })(isStableIdentifier(identifier)) : {};
    this._store._instanceCache.setRecordId(identifier, id);
  }
  hasRecord(identifier) {
    return Boolean(this._store._instanceCache.peek(identifier));
  }
  disconnectRecord(identifier) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected a stable identifier`);
      }
    })(isStableIdentifier(identifier)) : {};
    this._store._instanceCache.disconnect(identifier);
    this._pendingNotifies.delete(identifier);
  }
}
if (macroCondition(getGlobalConfig().WarpDrive.deprecations.ENABLE_LEGACY_SCHEMA_SERVICE)) {
  CacheCapabilitiesManager.prototype.getSchemaDefinitionService = function () {
    // FIXME add deprecation for this
    return this._store.schema;
  };
}

/*
 * Returns the Cache instance associated with a given
 * Model or Identifier
 */

const CacheForIdentifierCache = getOrSetGlobal('CacheForIdentifierCache', new Map());
function setCacheFor(identifier, cache) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Illegal set of identifier`);
    }
  })(!CacheForIdentifierCache.has(identifier) || CacheForIdentifierCache.get(identifier) === cache) : {};
  CacheForIdentifierCache.set(identifier, cache);
}
function removeRecordDataFor(identifier) {
  CacheForIdentifierCache.delete(identifier);
}
function peekCache(instance) {
  if (CacheForIdentifierCache.has(instance)) {
    return CacheForIdentifierCache.get(instance);
  }
  return null;
}
function isDestroyable(record) {
  return Boolean(record && typeof record === 'object' && typeof record.destroy === 'function');
}

/**
  @module @ember-data/store
*/

const RecordCache = getOrSetGlobal('RecordCache', new Map());
function peekRecordIdentifier(record) {
  return RecordCache.get(record);
}

/**
  Retrieves the unique referentially-stable [RecordIdentifier](/ember-data/release/classes/StableRecordIdentifier)
  assigned to the given record instance.
  ```js
  import { recordIdentifierFor } from "@ember-data/store";
  // ... gain access to a record, for instance with peekRecord or findRecord
  const record = store.peekRecord("user", "1");
  // get the identifier for the record (see docs for StableRecordIdentifier)
  const identifier = recordIdentifierFor(record);
  // access the identifier's properties.
  const { id, type, lid } = identifier;
  ```
  @method recordIdentifierFor
  @public
  @static
  @for @ember-data/store
  @param {Object} record a record instance previously obstained from the store.
  @return {StableRecordIdentifier}
 */

function recordIdentifierFor(record) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`${String(record)} is not a record instantiated by @ember-data/store`);
    }
  })(RecordCache.has(record)) : {};
  return RecordCache.get(record);
}
function setRecordIdentifier(record, identifier) {
  if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
    if (RecordCache.has(record) && RecordCache.get(record) !== identifier) {
      throw new Error(`${String(record)} was already assigned an identifier`);
    }
  }

  /*
  It would be nice to do a reverse check here that an identifier has not
  previously been assigned a record; however, unload + rematerialization
  prevents us from having a great way of doing so when CustomRecordClasses
  don't necessarily give us access to a `isDestroyed` for dematerialized
  instance.
  */

  RecordCache.set(record, identifier);
}
const StoreMap = getOrSetGlobal('StoreMap', new Map());
function storeFor(record) {
  const store = StoreMap.get(record);
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`A record in a disconnected state cannot utilize the store. This typically means the record has been destroyed, most commonly by unloading it.`);
    }
  })(store) : {};
  return store;
}
class InstanceCache {
  __instances = {
    record: new Map(),
    reference: new WeakMap()
  };
  constructor(store) {
    this.store = store;
    this._storeWrapper = new CacheCapabilitiesManager(this.store);
    store.identifierCache.__configureMerge((identifier, matchedIdentifier, resourceData) => {
      let keptIdentifier = identifier;
      if (identifier.id !== matchedIdentifier.id) {
        // @ts-expect-error TODO this needs to be fixed
        keptIdentifier = 'id' in resourceData && identifier.id === resourceData.id ? identifier : matchedIdentifier;
      } else if (identifier.type !== matchedIdentifier.type) {
        keptIdentifier =
        // @ts-expect-error TODO this needs to be fixed
        'type' in resourceData && identifier.type === resourceData.type ? identifier : matchedIdentifier;
      }
      const staleIdentifier = identifier === keptIdentifier ? matchedIdentifier : identifier;

      // check for duplicate entities
      const keptHasRecord = this.__instances.record.has(keptIdentifier);
      const staleHasRecord = this.__instances.record.has(staleIdentifier);

      // we cannot merge entities when both have records
      // (this may not be strictly true, we could probably swap the cache data the record points at)
      if (keptHasRecord && staleHasRecord) {
        // TODO we probably don't need to throw these errors anymore
        // we can probably just "swap" what data source the abandoned
        // record points at so long as
        // it itself is not retained by the store in any way.
        // @ts-expect-error TODO this needs to be fixed
        if ('id' in resourceData) {
          throw new Error(`Failed to update the 'id' for the RecordIdentifier '${identifier.type}:${String(identifier.id)} (${identifier.lid})' to '${String(resourceData.id)}', because that id is already in use by '${matchedIdentifier.type}:${String(matchedIdentifier.id)} (${matchedIdentifier.lid})'`);
        }
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          {
            throw new Error(`Failed to update the RecordIdentifier '${identifier.type}:${String(identifier.id)} (${identifier.lid})' to merge with the detected duplicate identifier '${matchedIdentifier.type}:${String(matchedIdentifier.id)} (${String(matchedIdentifier.lid)})'`);
          }
        })() : {};
      }
      this.store.cache.patch({
        op: 'mergeIdentifiers',
        record: staleIdentifier,
        value: keptIdentifier
      });

      /*
      TODO @runspired consider adding this to make polymorphism even nicer
      if (identifier.type !== matchedIdentifier.type) {
      this.store._graph?.registerPolymorphicType(identifier.type, matchedIdentifier.type);
      }
      */

      this.unloadRecord(staleIdentifier);
      return keptIdentifier;
    });
  }
  peek(identifier) {
    return this.__instances.record.get(identifier);
  }
  getRecord(identifier, properties) {
    let record = this.__instances.record.get(identifier);
    if (!record) {
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Cannot create a new record instance while the store is being destroyed`);
        }
      })(!this.store.isDestroying && !this.store.isDestroyed) : {};
      const cache = this.store.cache;
      setCacheFor(identifier, cache);
      record = this.store.instantiateRecord(identifier, properties || {});
      setRecordIdentifier(record, identifier);
      setCacheFor(record, cache);
      StoreMap.set(record, this.store);
      this.__instances.record.set(identifier, record);
      if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_INSTANCE_CACHE)) {
        if (getGlobalConfig().WarpDrive.debug.LOG_INSTANCE_CACHE || globalThis.getWarpDriveRuntimeConfig().debug.LOG_INSTANCE_CACHE) {
          logGroup('reactive-ui', '', identifier.type, identifier.lid, 'created', '');
          // eslint-disable-next-line no-console
          console.log({
            properties
          });
          // eslint-disable-next-line no-console
          console.groupEnd();
        }
      }
    }
    return record;
  }
  getReference(identifier) {
    const cache = this.__instances.reference;
    let reference = cache.get(identifier);
    if (!reference) {
      reference = new RecordReference(this.store, identifier);
      cache.set(identifier, reference);
    }
    return reference;
  }
  recordIsLoaded(identifier, filterDeleted = false) {
    const cache = this.cache;
    if (!cache) {
      return false;
    }
    const isNew = cache.isNew(identifier);
    const isEmpty = cache.isEmpty(identifier);

    // if we are new we must consider ourselves loaded
    if (isNew) {
      return !cache.isDeleted(identifier);
    }
    // even if we have a past request, if we are now empty we are not loaded
    // typically this is true after an unloadRecord call

    // if we are not empty, not new && we have a fulfilled request then we are loaded
    // we should consider allowing for something to be loaded that is simply "not empty".
    // which is how RecordState currently handles this case; however, RecordState is buggy
    // in that it does not account for unloading.
    return filterDeleted && cache.isDeletionCommitted(identifier) ? false : !isEmpty;
  }
  disconnect(identifier) {
    const record = this.__instances.record.get(identifier);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error('Cannot destroy record while it is still materialized');
      }
    })(!isDestroyable(record) || record.isDestroyed || record.isDestroying) : {};
    this.store._graph?.remove(identifier);
    this.store.identifierCache.forgetRecordIdentifier(identifier);
    removeRecordDataFor(identifier);
    this.store._requestCache._clearEntries(identifier);
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_INSTANCE_CACHE)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_INSTANCE_CACHE || globalThis.getWarpDriveRuntimeConfig().debug.LOG_INSTANCE_CACHE) {
        log('reactive-ui', '', identifier.type, identifier.lid, 'disconnected', '');
      }
    }
  }
  unloadRecord(identifier) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      const requests = this.store.getRequestStateService().getPendingRequestsForRecord(identifier);
      if (requests.some(req => {
        return req.type === 'mutation';
      })) {
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          {
            throw new Error(`You can only unload a record which is not inFlight. '${String(identifier)}'`);
          }
        })() : {};
      }
    }
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_INSTANCE_CACHE)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_INSTANCE_CACHE || globalThis.getWarpDriveRuntimeConfig().debug.LOG_INSTANCE_CACHE) {
        // eslint-disable-next-line no-console
        console.groupCollapsed(`InstanceCache: unloading record for ${String(identifier)}`);
      }
    }

    // TODO is this join still necessary?
    this.store._join(() => {
      const record = this.__instances.record.get(identifier);
      const cache = this.cache;
      if (record) {
        this.store.teardownRecord(record);
        this.__instances.record.delete(identifier);
        StoreMap.delete(record);
        RecordCache.delete(record);
        removeRecordDataFor(record);
        if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_INSTANCE_CACHE)) {
          if (getGlobalConfig().WarpDrive.debug.LOG_INSTANCE_CACHE || globalThis.getWarpDriveRuntimeConfig().debug.LOG_INSTANCE_CACHE) {
            // eslint-disable-next-line no-console
            console.log(`InstanceCache: destroyed record for ${String(identifier)}`);
          }
        }
      }
      if (cache) {
        cache.unloadRecord(identifier);
        removeRecordDataFor(identifier);
        if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_INSTANCE_CACHE)) {
          if (getGlobalConfig().WarpDrive.debug.LOG_INSTANCE_CACHE || globalThis.getWarpDriveRuntimeConfig().debug.LOG_INSTANCE_CACHE) {
            // eslint-disable-next-line no-console
            console.log(`InstanceCache: destroyed cache for ${String(identifier)}`);
          }
        }
      } else {
        this.disconnect(identifier);
      }
      this.store._requestCache._clearEntries(identifier);
      if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_INSTANCE_CACHE)) {
        if (getGlobalConfig().WarpDrive.debug.LOG_INSTANCE_CACHE || globalThis.getWarpDriveRuntimeConfig().debug.LOG_INSTANCE_CACHE) {
          // eslint-disable-next-line no-console
          console.log(`InstanceCache: unloaded RecordData for ${String(identifier)}`);
          // eslint-disable-next-line no-console
          console.groupEnd();
        }
      }
    });
  }
  clear(type) {
    const cache = this.store.identifierCache._cache;
    if (type === undefined) {
      // it would be cool if we could just de-ref cache here
      // but probably would require WeakRef models to do so.
      cache.resources.forEach(identifier => {
        this.unloadRecord(identifier);
      });
    } else {
      const typeCache = cache.resourcesByType;
      const identifiers = typeCache[type]?.lid;
      if (identifiers) {
        identifiers.forEach(identifier => {
          // if (rds.has(identifier)) {
          this.unloadRecord(identifier);
          // }
          // TODO we don't remove the identifier, should we?
        });
      }
    }
  }

  // TODO this should move into something coordinating operations
  setRecordId(identifier, id) {
    const {
      type,
      lid
    } = identifier;
    const oldId = identifier.id;

    // ID absolutely can't be missing if the oldID is empty (missing Id in response for a new record)
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`'${type}' was saved to the server, but the response does not have an id and your record does not either.`);
      }
    })(!(id === null && oldId === null)) : {};

    // ID absolutely can't be different than oldID if oldID is not null
    // TODO this assertion and restriction may not strictly be needed in the identifiers world
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Cannot update the id for '${type}:${lid}' from '${String(oldId)}' to '${id}'.`);
      }
    })(!(oldId !== null && id !== oldId)) : {};

    // ID can be null if oldID is not null (altered ID in response for a record)
    // however, this is more than likely a developer error.
    if (oldId !== null && id === null) {
      warn(`Your ${type} record was saved to the server, but the response does not have an id.`, !(oldId !== null && id === null));
      return;
    }
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_INSTANCE_CACHE)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_INSTANCE_CACHE || globalThis.getWarpDriveRuntimeConfig().debug.LOG_INSTANCE_CACHE) {
        // eslint-disable-next-line no-console
        console.log(`InstanceCache: updating id to '${id}' for record ${String(identifier)}`);
      }
    }
    const existingIdentifier = this.store.identifierCache.peekRecordIdentifier({
      type,
      id
    });
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`'${type}' was saved to the server, but the response returned the new id '${id}', which has already been used with another record.'`);
      }
    })(!existingIdentifier || existingIdentifier === identifier) : {};
    if (identifier.id === null) {
      // TODO potentially this needs to handle merged result
      this.store.identifierCache.updateRecordIdentifier(identifier, {
        type,
        id
      });
    }

    // TODO update resource cache if needed ?
    // TODO handle consequences of identifier merge for notifications
    this.store.notifications.notify(identifier, 'identity');
  }
}
function _resourceIsFullDeleted(identifier, cache) {
  return cache.isDeletionCommitted(identifier) || cache.isNew(identifier) && cache.isDeleted(identifier);
}
function resourceIsFullyDeleted(instanceCache, identifier) {
  const cache = instanceCache.cache;
  return !cache || _resourceIsFullDeleted(identifier, cache);
}

/*
    When a find request is triggered on the store, the user can optionally pass in
    attributes and relationships to be preloaded. These are meant to behave as if they
    came back from the server, except the user obtained them out of band and is informing
    the store of their existence. The most common use case is for supporting client side
    nested URLs, such as `/posts/1/comments/2` so the user can do
    `store.findRecord('comment', 2, { preload: { post: 1 } })` without having to fetch the post.

    Preloaded data can be attributes and relationships passed in either as IDs or as actual
    models.
  */

function preloadData(store, identifier, preload) {
  const jsonPayload = {};
  //TODO(Igor) consider the polymorphic case
  const schemas = store.schema;
  const fields = schemas.fields(identifier);
  Object.keys(preload).forEach(key => {
    const preloadValue = preload[key];
    const field = fields.get(key);
    if (field && (field.kind === 'hasMany' || field.kind === 'belongsTo')) {
      if (!jsonPayload.relationships) {
        jsonPayload.relationships = {};
      }
      jsonPayload.relationships[key] = preloadRelationship(field, preloadValue);
    } else {
      if (!jsonPayload.attributes) {
        jsonPayload.attributes = {};
      }
      jsonPayload.attributes[key] = preloadValue;
    }
  });
  const cache = store.cache;
  const hasRecord = Boolean(store._instanceCache.peek(identifier));
  cache.upsert(identifier, jsonPayload, hasRecord);
}
function preloadRelationship(schema, preloadValue) {
  const relatedType = schema.type;
  if (schema.kind === 'hasMany') {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error('You need to pass in an array to set a hasMany property on a record');
      }
    })(Array.isArray(preloadValue)) : {};
    return {
      data: preloadValue.map(value => _convertPreloadRelationshipToJSON(value, relatedType))
    };
  }
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error('You should not pass in an array to set a belongsTo property on a record');
    }
  })(!Array.isArray(preloadValue)) : {};
  return {
    data: preloadValue ? _convertPreloadRelationshipToJSON(preloadValue, relatedType) : null
  };
}

/*
  findRecord('user', '1', { preload: { friends: ['1'] }});
  findRecord('user', '1', { preload: { friends: [record] }});
*/
function _convertPreloadRelationshipToJSON(value, type) {
  if (typeof value === 'string' || typeof value === 'number') {
    return {
      type,
      id: ensureStringId(value)
    };
  }
  // TODO if not a record instance assert it's an identifier
  // and allow identifiers to be used
  return recordIdentifierFor(value);
}
function _clearCaches() {
  RecordCache.clear();
  StoreMap.clear();
  CacheForIdentifierCache.clear();
}

// if modelFor turns out to be a bottleneck we should replace with a Map
// and clear it during store teardown.
const AvailableShims = getOrSetGlobal('AvailableShims', new WeakMap());
function getShimClass(store, modelName) {
  let shims = AvailableShims.get(store);
  if (!shims) {
    shims = Object.create(null);
    AvailableShims.set(store, shims);
  }
  let shim = shims[modelName];
  if (shim === undefined) {
    shim = shims[modelName] = new ShimModelClass(store, modelName);
  }
  return shim;
}

// Mimics the static apis of @ember-data/model
class ShimModelClass {
  constructor(store, modelName) {
    this.__store = store;
    this.modelName = modelName;
  }
  get fields() {
    const fields = new Map();
    const fieldSchemas = this.__store.schema.fields({
      type: this.modelName
    });
    fieldSchemas.forEach((schema, key) => {
      if (schema.kind === 'attribute' || schema.kind === 'belongsTo' || schema.kind === 'hasMany') {
        fields.set(key, schema.kind);
      }
    });
    return fields;
  }
  get attributes() {
    const attrs = new Map();
    const fields = this.__store.schema.fields({
      type: this.modelName
    });
    fields.forEach((schema, key) => {
      if (schema.kind === 'attribute') {
        attrs.set(key, schema);
      }
    });
    return attrs;
  }
  get relationshipsByName() {
    const rels = new Map();
    const fields = this.__store.schema.fields({
      type: this.modelName
    });
    fields.forEach((schema, key) => {
      if (schema.kind === 'belongsTo' || schema.kind === 'hasMany') {
        rels.set(key, schema);
      }
    });
    return rels;
  }
  eachAttribute(callback, binding) {
    this.__store.schema.fields({
      type: this.modelName
    }).forEach((schema, key) => {
      if (schema.kind === 'attribute') {
        callback.call(binding, key, schema);
      }
    });
  }
  eachRelationship(callback, binding) {
    this.__store.schema.fields({
      type: this.modelName
    }).forEach((schema, key) => {
      if (schema.kind === 'belongsTo' || schema.kind === 'hasMany') {
        callback.call(binding, key, schema);
      }
    });
  }
  eachTransformedAttribute(callback, binding) {
    this.__store.schema.fields({
      type: this.modelName
    }).forEach((schema, key) => {
      if (schema.kind === 'attribute') {
        const type = schema.type;
        if (type) callback.call(binding, key, type);
      }
    });
  }
}

/**
 * The CacheManager wraps a Cache enforcing that only
 * the public API surface area is exposed.
 *
 * Hence, it is the value of `Store.cache`, wrapping
 * the cache instance returned by `Store.createCache`.
 *
 * It handles translating between cache versions when
 * necessary, for instance when a Store is configured
 * to use both a v1 and a v2 cache depending on some
 * heuristic.
 *
 * Starting with the v2 spec, the cache is designed such
 * that it must be implemented as a singleton.
 *
 * @class CacheManager
 * @public
 */
class CacheManager {
  version = '2';
  #cache;
  constructor(cache) {
    this.#cache = cache;
  }

  // Cache Management
  // ================

  /**
   * Cache the response to a request
   *
   * Unlike `store.push` which has UPSERT
   * semantics, `put` has `replace` semantics similar to
   * the `http` method `PUT`
   *
   * the individually cacheable
   * e resource data it may contain
   * should upsert, but the document data surrounding it should
   * fully replace any existing information
   *
   * Note that in order to support inserting arbitrary data
   * to the cache that did not originate from a request `put`
   * should expect to sometimes encounter a document with only
   * a `content` member and therefor must not assume the existence
   * of `request` and `response` on the document.
   *
   * @method put
   * @param {StructuredDocument} doc
   * @return {ResourceDocument}
   * @public
   */
  put(doc) {
    return this.#cache.put(doc);
  }

  /**
   * Perform an operation on the cache to update the remote state.
   *
   * Note: currently the only valid operation is a MergeOperation
   * which occurs when a collision of identifiers is detected.
   *
   * @method patch
   * @public
   * @param op the operation to perform
   * @return {void}
   */
  patch(op) {
    this.#cache.patch(op);
  }

  /**
   * Update resource data with a local mutation. Currently supports operations
   * on relationships only.
   *
   * @method mutate
   * @public
   * @param mutation
   */
  mutate(mutation) {
    this.#cache.mutate(mutation);
  }

  /**
   * Peek resource data from the Cache.
   *
   * In development, if the return value
   * is JSON the return value
   * will be deep-cloned and deep-frozen
   * to prevent mutation thereby enforcing cache
   * Immutability.
   *
   * This form of peek is useful for implementations
   * that want to feed raw-data from cache to the UI
   * or which want to interact with a blob of data
   * directly from the presentation cache.
   *
   * An implementation might want to do this because
   * de-referencing records which read from their own
   * blob is generally safer because the record does
   * not require retaining connections to the Store
   * and Cache to present data on a per-field basis.
   *
   * This generally takes the place of `getAttr` as
   * an API and may even take the place of `getRelationship`
   * depending on implementation specifics, though this
   * latter usage is less recommended due to the advantages
   * of the Graph handling necessary entanglements and
   * notifications for relational data.
   *
   * @method peek
   * @public
   * @param {StableRecordIdentifier | StableDocumentIdentifier} identifier
   * @return {ResourceDocument | ResourceBlob | null} the known resource data
   */

  peek(identifier) {
    return this.#cache.peek(identifier);
  }
  peekRemoteState(identifier) {
    return this.#cache.peekRemoteState(identifier);
  }
  /**
   * Peek the Cache for the existing request data associated with
   * a cacheable request
   *
   * @method peekRequest
   * @param {StableDocumentIdentifier}
   * @return {StableDocumentIdentifier | null}
   * @public
   */
  peekRequest(identifier) {
    return this.#cache.peekRequest(identifier);
  }

  /**
   * Push resource data from a remote source into the cache for this identifier
   *
   * @method upsert
   * @public
   * @param identifier
   * @param data
   * @param hasRecord
   * @return {void | string[]} if `hasRecord` is true then calculated key changes should be returned
   */
  upsert(identifier, data, hasRecord) {
    return this.#cache.upsert(identifier, data, hasRecord);
  }

  // Cache Forking Support
  // =====================

  /**
   * Create a fork of the cache from the current state.
   *
   * Applications should typically not call this method themselves,
   * preferring instead to fork at the Store level, which will
   * utilize this method to fork the cache.
   *
   * @method fork
   * @public
   * @return Promise<Cache>
   */
  fork() {
    return this.#cache.fork();
  }

  /**
   * Merge a fork back into a parent Cache.
   *
   * Applications should typically not call this method themselves,
   * preferring instead to merge at the Store level, which will
   * utilize this method to merge the caches.
   *
   * @method merge
   * @param {Cache} cache
   * @public
   * @return Promise<void>
   */
  merge(cache) {
    return this.#cache.merge(cache);
  }

  /**
   * Generate the list of changes applied to all
   * record in the store.
   *
   * Each individual resource or document that has
   * been mutated should be described as an individual
   * `Change` entry in the returned array.
   *
   * A `Change` is described by an object containing up to
   * three properties: (1) the `identifier` of the entity that
   * changed; (2) the `op` code of that change being one of
   * `upsert` or `remove`, and if the op is `upsert` a `patch`
   * containing the data to merge into the cache for the given
   * entity.
   *
   * This `patch` is opaque to the Store but should be understood
   * by the Cache and may expect to be utilized by an Adapter
   * when generating data during a `save` operation.
   *
   * It is generally recommended that the `patch` contain only
   * the updated state, ignoring fields that are unchanged
   *
   * ```ts
   * interface Change {
   *  identifier: StableRecordIdentifier | StableDocumentIdentifier;
   *  op: 'upsert' | 'remove';
   *  patch?: unknown;
   * }
   * ```
   *
   * @method diff
   * @public
   */
  diff() {
    return this.#cache.diff();
  }

  // SSR Support
  // ===========

  /**
   * Serialize the entire contents of the Cache into a Stream
   * which may be fed back into a new instance of the same Cache
   * via `cache.hydrate`.
   *
   * @method dump
   * @return {Promise<ReadableStream>}
   * @public
   */
  dump() {
    return this.#cache.dump();
  }

  /**
   * hydrate a Cache from a Stream with content previously serialized
   * from another instance of the same Cache, resolving when hydration
   * is complete.
   *
   * This method should expect to be called both in the context of restoring
   * the Cache during application rehydration after SSR **AND** at unknown
   * times during the lifetime of an already booted application when it is
   * desired to bulk-load additional information into the cache. This latter
   * behavior supports optimizing pre/fetching of data for route transitions
   * via data-only SSR modes.
   *
   * @method hydrate
   * @param {ReadableStream} stream
   * @return {Promise<void>}
   * @public
   */
  hydrate(stream) {
    return this.#cache.hydrate(stream);
  }

  // Cache
  // =====

  // Resource Support
  // ================

  /**
   * [LIFECYCLE] Signal to the cache that a new record has been instantiated on the client
   *
   * It returns properties from options that should be set on the record during the create
   * process. This return value behavior is deprecated.
   *
   * @method clientDidCreate
   * @public
   * @param identifier
   * @param options
   */
  clientDidCreate(identifier, options) {
    return this.#cache.clientDidCreate(identifier, options);
  }

  /**
   * [LIFECYCLE] Signals to the cache that a resource
   * will be part of a save transaction.
   *
   * @method willCommit
   * @public
   * @param identifier
   */
  willCommit(identifier, context) {
    this.#cache.willCommit(identifier, context);
  }

  /**
   * [LIFECYCLE] Signals to the cache that a resource
   * was successfully updated as part of a save transaction.
   *
   * @method didCommit
   * @public
   * @param identifier
   * @param data
   */
  didCommit(identifier, result) {
    return this.#cache.didCommit(identifier, result);
  }

  /**
   * [LIFECYCLE] Signals to the cache that a resource
   * was update via a save transaction failed.
   *
   * @method commitWasRejected
   * @public
   * @param identifier
   * @param errors
   */
  commitWasRejected(identifier, errors) {
    this.#cache.commitWasRejected(identifier, errors);
  }

  /**
   * [LIFECYCLE] Signals to the cache that all data for a resource
   * should be cleared.
   *
   * @method unloadRecord
   * @public
   * @param identifier
   */
  unloadRecord(identifier) {
    this.#cache.unloadRecord(identifier);
  }

  // Granular Resource Data APIs
  // ===========================

  /**
   * Retrieve the data for an attribute from the cache
   *
   * @method getAttr
   * @public
   * @param identifier
   * @param propertyName
   * @return {unknown}
   */
  getAttr(identifier, propertyName) {
    return this.#cache.getAttr(identifier, propertyName);
  }

  /**
   * Retrieve the remote state for an attribute from the cache
   *
   * @method getRemoteAttr
   * @public
   * @param identifier
   * @param propertyName
   * @return {unknown}
   */
  getRemoteAttr(identifier, propertyName) {
    return this.#cache.getRemoteAttr(identifier, propertyName);
  }

  /**
   * Mutate the data for an attribute in the cache
   *
   * @method setAttr
   * @public
   * @param identifier
   * @param propertyName
   * @param value
   */
  setAttr(identifier, propertyName, value) {
    this.#cache.setAttr(identifier, propertyName, value);
  }

  /**
   * Query the cache for the changed attributes of a resource.
   *
   * @method changedAttrs
   * @public
   * @param identifier
   * @return
   */
  changedAttrs(identifier) {
    return this.#cache.changedAttrs(identifier);
  }

  /**
   * Query the cache for whether any mutated attributes exist
   *
   * @method hasChangedAttrs
   * @public
   * @param identifier
   * @return {boolean}
   */
  hasChangedAttrs(identifier) {
    return this.#cache.hasChangedAttrs(identifier);
  }

  /**
   * Tell the cache to discard any uncommitted mutations to attributes
   *
   * @method rollbackAttrs
   * @public
   * @param identifier
   * @return the names of attributes that were restored
   */
  rollbackAttrs(identifier) {
    return this.#cache.rollbackAttrs(identifier);
  }

  // Relationships
  // =============

  /**
   * Query the cache for the changes to relationships of a resource.
   *
   * Returns a map of relationship names to RelationshipDiff objects.
   *
   * ```ts
   * type RelationshipDiff =
  | {
      kind: 'collection';
      remoteState: StableRecordIdentifier[];
      additions: Set<StableRecordIdentifier>;
      removals: Set<StableRecordIdentifier>;
      localState: StableRecordIdentifier[];
      reordered: boolean;
    }
  | {
      kind: 'resource';
      remoteState: StableRecordIdentifier | null;
      localState: StableRecordIdentifier | null;
    };
    ```
   *
   * @method changedRelationships
   * @public
   * @param {StableRecordIdentifier} identifier
   * @return {Map<string, RelationshipDiff>}
   */
  changedRelationships(identifier) {
    return this.#cache.changedRelationships(identifier);
  }

  /**
   * Query the cache for whether any mutated attributes exist
   *
   * @method hasChangedRelationships
   * @public
   * @param {StableRecordIdentifier} identifier
   * @return {boolean}
   */
  hasChangedRelationships(identifier) {
    return this.#cache.hasChangedRelationships(identifier);
  }

  /**
   * Tell the cache to discard any uncommitted mutations to relationships.
   *
   * This will also discard the change on any appropriate inverses.
   *
   * This method is a candidate to become a mutation
   *
   * @method rollbackRelationships
   * @public
   * @param {StableRecordIdentifier} identifier
   * @return {string[]} the names of relationships that were restored
   */
  rollbackRelationships(identifier) {
    return this.#cache.rollbackRelationships(identifier);
  }

  /**
   * Query the cache for the current state of a relationship property
   *
   * @method getRelationship
   * @public
   * @param identifier
   * @param propertyName
   * @return resource relationship object
   */
  getRelationship(identifier, propertyName) {
    return this.#cache.getRelationship(identifier, propertyName);
  }

  /**
   * Query the cache for the remote state of a relationship property
   *
   * @method getRelationship
   * @public
   * @param identifier
   * @param propertyName
   * @return resource relationship object
   */
  getRemoteRelationship(identifier, propertyName) {
    return this.#cache.getRemoteRelationship(identifier, propertyName);
  }

  // Resource State
  // ===============

  /**
   * Update the cache state for the given resource to be marked as locally deleted,
   * or remove such a mark.
   *
   * @method setIsDeleted
   * @public
   * @param identifier
   * @param isDeleted
   */
  setIsDeleted(identifier, isDeleted) {
    this.#cache.setIsDeleted(identifier, isDeleted);
  }

  /**
   * Query the cache for any validation errors applicable to the given resource.
   *
   * @method getErrors
   * @public
   * @param identifier
   * @return
   */
  getErrors(identifier) {
    return this.#cache.getErrors(identifier);
  }

  /**
   * Query the cache for whether a given resource has any available data
   *
   * @method isEmpty
   * @public
   * @param identifier
   * @return {boolean}
   */
  isEmpty(identifier) {
    return this.#cache.isEmpty(identifier);
  }

  /**
   * Query the cache for whether a given resource was created locally and not
   * yet persisted.
   *
   * @method isNew
   * @public
   * @param identifier
   * @return {boolean}
   */
  isNew(identifier) {
    return this.#cache.isNew(identifier);
  }

  /**
   * Query the cache for whether a given resource is marked as deleted (but not
   * necessarily persisted yet).
   *
   * @method isDeleted
   * @public
   * @param identifier
   * @return {boolean}
   */
  isDeleted(identifier) {
    return this.#cache.isDeleted(identifier);
  }

  /**
   * Query the cache for whether a given resource has been deleted and that deletion
   * has also been persisted.
   *
   * @method isDeletionCommitted
   * @public
   * @param identifier
   * @return {boolean}
   */
  isDeletionCommitted(identifier) {
    return this.#cache.isDeletionCommitted(identifier);
  }
}

/**
 * @module @ember-data/store
 */

function isCacheOperationValue(value) {
  return value === 'added' || value === 'state' || value === 'updated' || value === 'removed' || value === 'invalidated';
}
function runLoopIsFlushing() {
  //@ts-expect-error
  return !!_backburner.currentInstance && _backburner._autorun !== true;
}
function count(label) {
  // @ts-expect-error
  // eslint-disable-next-line
  globalThis.__WarpDriveMetricCountData[label] = (globalThis.__WarpDriveMetricCountData[label] || 0) + 1;
}
function asInternalToken(token) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected a token with a 'for' property`);
    }
  })(token && typeof token === 'function' && 'for' in token) : {};
}
function _unsubscribe(token, cache) {
  asInternalToken(token);
  const identifier = token.for;
  if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_NOTIFICATIONS)) {
    if (getGlobalConfig().WarpDrive.debug.LOG_NOTIFICATIONS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_NOTIFICATIONS) {
      if (!identifier) {
        // eslint-disable-next-line no-console
        console.log('Passed unknown unsubscribe token to unsubscribe', identifier);
      }
    }
  }
  if (identifier) {
    const callbacks = cache.get(identifier);
    if (!callbacks) {
      return;
    }
    const index = callbacks.indexOf(token);
    if (index === -1) {
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Cannot unsubscribe a token that is not subscribed`);
        }
      })(index !== -1) : {};
      return;
    }
    callbacks.splice(index, 1);
  }
}

/**
 * The NotificationManager provides the ability to subscribe to
 * changes to Cache state.
 *
 * This Feature is what allows EmberData to create subscriptions that
 * work with any framework or change-notification system.
 *
 * @class NotificationManager
 * @public
 */
class NotificationManager {
  constructor(store) {
    this.store = store;
    this.isDestroyed = false;
    this._buffered = new Map();
    this._hasFlush = false;
    this._cache = new Map();
  }

  /**
   * Subscribe to changes for a given resource identifier, resource addition/removal, or document addition/removal.
   *
   * ```ts
   * export type CacheOperation = 'added' | 'removed' | 'updated' | 'state';
   *
   * export interface NotificationCallback {
   *   (identifier: StableRecordIdentifier, notificationType: 'attributes' | 'relationships', key?: string): void;
   *   (identifier: StableRecordIdentifier, notificationType: 'errors' | 'meta' | 'identity' | 'state'): void;
   *   (identifier: StableRecordIdentifier, notificationType: NotificationType, key?: string): void;
   * }
   * export interface ResourceOperationCallback {
   *   // resource updates
   *   (identifier: StableRecordIdentifier, notificationType: CacheOperation): void;
   * }
   * export interface DocumentOperationCallback {
   *   // document updates
   *   (identifier: StableDocumentIdentifier, notificationType: CacheOperation): void;
   * }
   * ```
   *
   * @method subscribe
   * @public
   * @param {StableDocumentIdentifier | StableRecordIdentifier | 'resource' | 'document'} identifier
   * @param {NotificationCallback | ResourceOperationCallback | DocumentOperationCallback} callback
   * @return {UnsubscribeToken} an opaque token to be used with unsubscribe
   */

  subscribe(identifier, callback) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected to receive a stable Identifier to subscribe to`);
      }
    })(identifier === 'resource' || identifier === 'document' || isStableIdentifier(identifier) || isDocumentIdentifier(identifier)) : {};
    let callbacks = this._cache.get(identifier);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`expected to receive a valid callback`);
      }
    })(typeof callback === 'function') : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`cannot subscribe with the same callback twice`);
      }
    })(!callbacks || !callbacks.includes(callback)) : {};
    // we use the callback as the cancellation token
    //@ts-expect-error
    callback.for = identifier;
    if (!callbacks) {
      callbacks = [];
      this._cache.set(identifier, callbacks);
    }
    callbacks.push(callback);
    return callback;
  }

  /**
   * remove a previous subscription
   *
   * @method unsubscribe
   * @public
   * @param {UnsubscribeToken} token
   */
  unsubscribe(token) {
    if (!this.isDestroyed) {
      _unsubscribe(token, this._cache);
    }
  }

  /**
   * Custom Caches and Application Code should not call this method directly.
   *
   * @method notify
   * @param identifier
   * @param value
   * @param key
   * @return {Boolean} whether a notification was delivered to any subscribers
   * @private
   */

  notify(identifier, value, key) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Notify does not accept a key argument for the namespace '${value}'. Received key '${key || ''}'.`);
      }
    })(!key || value === 'attributes' || value === 'relationships') : {};
    if (!isStableIdentifier(identifier) && !isDocumentIdentifier(identifier)) {
      if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_NOTIFICATIONS)) {
        if (getGlobalConfig().WarpDrive.debug.LOG_NOTIFICATIONS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_NOTIFICATIONS) {
          // eslint-disable-next-line no-console
          console.log(`Notifying: Expected to receive a stable Identifier to notify '${value}' '${key || ''}' with, but ${String(identifier)} is not in the cache`, identifier);
        }
      }
      return false;
    }
    const hasSubscribers = Boolean(this._cache.get(identifier)?.length);
    if (isCacheOperationValue(value) || hasSubscribers) {
      let buffer = this._buffered.get(identifier);
      if (!buffer) {
        buffer = [];
        this._buffered.set(identifier, buffer);
      }
      buffer.push([value, key]);
      if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_METRIC_COUNTS)) {
        if (getGlobalConfig().WarpDrive.debug.LOG_METRIC_COUNTS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_METRIC_COUNTS) {
          count(`notify ${'type' in identifier ? identifier.type : '<document>'} ${value} ${key}`);
        }
      }
      if (!this._scheduleNotify()) {
        if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_NOTIFICATIONS)) {
          if (getGlobalConfig().WarpDrive.debug.LOG_NOTIFICATIONS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_NOTIFICATIONS) {
            log('notify', 'buffered', `${'type' in identifier ? identifier.type : 'document'}`, identifier.lid, `${value}`, key || '');
          }
        }
      }
    } else {
      if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_NOTIFICATIONS)) {
        if (getGlobalConfig().WarpDrive.debug.LOG_NOTIFICATIONS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_NOTIFICATIONS) {
          log('notify', 'discarded', `${'type' in identifier ? identifier.type : 'document'}`, identifier.lid, `${value}`, key || '');
        }
      }
      if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_METRIC_COUNTS)) {
        if (getGlobalConfig().WarpDrive.debug.LOG_METRIC_COUNTS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_METRIC_COUNTS) {
          count(`DISCARDED notify ${'type' in identifier ? identifier.type : '<document>'} ${value} ${key}`);
        }
      }
    }
    return hasSubscribers;
  }
  _onNextFlush(cb) {
    this._onFlushCB = cb;
  }
  _scheduleNotify() {
    const asyncFlush = this.store._enableAsyncFlush;
    if (this._hasFlush) {
      if (asyncFlush !== false && !runLoopIsFlushing()) {
        return false;
      }
    }
    if (asyncFlush && !runLoopIsFlushing()) {
      this._hasFlush = true;
      return false;
    }
    this._flush();
    return true;
  }
  _flush() {
    const buffered = this._buffered;
    if (buffered.size) {
      this._buffered = new Map();
      buffered.forEach((states, identifier) => {
        states.forEach(args => {
          // @ts-expect-error
          this._flushNotification(identifier, args[0], args[1]);
        });
      });
    }
    this._hasFlush = false;
    this._onFlushCB?.();
    this._onFlushCB = undefined;
  }
  _flushNotification(identifier, value, key) {
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_NOTIFICATIONS)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_NOTIFICATIONS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_NOTIFICATIONS) {
        log('notify', '', `${'type' in identifier ? identifier.type : 'document'}`, identifier.lid, `${value}`, key || '');
      }
    }

    // TODO for documents this will need to switch based on Identifier kind
    if (isCacheOperationValue(value)) {
      const callbackMap = this._cache.get(isDocumentIdentifier(identifier) ? 'document' : 'resource');
      if (callbackMap) {
        callbackMap.forEach(cb => {
          cb(identifier, value);
        });
      }
    }
    const callbacks = this._cache.get(identifier);
    if (!callbacks || !callbacks.length) {
      return false;
    }
    callbacks.forEach(cb => {
      // @ts-expect-error overload doesn't narrow within body
      cb(identifier, value, key);
    });
    return true;
  }
  destroy() {
    this.isDestroyed = true;
    this._cache.clear();
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
/*
  We redefine Proxy because the native Proxy type treats the `target` and
  `receiver` as the same type incorrectly.

  We ported this from Typescript's own Proxy types on 3/10/2024.
*/

const NativeProxy = Proxy;
function decorateMethodV2(prototype, prop, decorators) {
  const origDesc = Object.getOwnPropertyDescriptor(prototype, prop);
  let desc = {
    ...origDesc
  };
  for (let decorator of decorators) {
    desc = decorator(prototype, prop, desc) || desc;
  }
  if (desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(prototype) : void 0;
    desc.initializer = void 0;
  }
  Object.defineProperty(prototype, prop, desc);
}

/**
  @module @ember-data/store
*/
const ARRAY_GETTER_METHODS = new Set([Symbol.iterator, 'concat', 'entries', 'every', 'fill', 'filter', 'find', 'findIndex', 'flat', 'flatMap', 'forEach', 'includes', 'indexOf', 'join', 'keys', 'lastIndexOf', 'map', 'reduce', 'reduceRight', 'slice', 'some', 'values']);
const ARRAY_SETTER_METHODS = new Set(['push', 'pop', 'unshift', 'shift', 'splice', 'sort']);
const SYNC_PROPS = new Set(['[]', 'length', 'links', 'meta']);
function isArrayGetter(prop) {
  return ARRAY_GETTER_METHODS.has(prop);
}
function isArraySetter(prop) {
  return ARRAY_SETTER_METHODS.has(prop);
}
function isSelfProp(self, prop) {
  return prop in self;
}
const ARRAY_SIGNAL = getOrSetGlobal('#signal', Symbol('#signal'));
const SOURCE = getOrSetGlobal('#source', Symbol('#source'));
const MUTATE = getOrSetGlobal('#update', Symbol('#update'));
const NOTIFY = getOrSetGlobal('#notify', Symbol('#notify'));
const IS_COLLECTION = getOrSetGlobal('IS_COLLECTION', Symbol.for('Collection'));
function notifyArray(arr) {
  addToTransaction(arr[ARRAY_SIGNAL]);
}
function convertToInt(prop) {
  if (typeof prop === 'symbol') return null;
  const num = Number(prop);
  if (isNaN(num)) return null;
  return num % 1 === 0 ? num : null;
}
function safeForEach(instance, arr, store, callback, target) {
  if (target === undefined) {
    target = null;
  }
  // clone to prevent mutation
  arr = arr.slice();
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error('`forEach` expects a function as first argument.');
    }
  })(typeof callback === 'function') : {};

  // because we retrieveLatest above we need not worry if array is mutated during iteration
  // by unloadRecord/rollbackAttributes
  // push/add/removeObject may still be problematic
  // but this is a more traditionally expected forEach bug.
  const length = arr.length; // we need to access length to ensure we are consumed

  for (let index = 0; index < length; index++) {
    callback.call(target, store._instanceCache.getRecord(arr[index]), index, instance);
  }
  return instance;
}

/**
  A record array is an array that contains records of a certain type (or modelName).
  The record array materializes records as needed when they are retrieved for the first
  time. You should not create record arrays yourself. Instead, an instance of
  `RecordArray` or its subclasses will be returned by your application's store
  in response to queries.

  This class should not be imported and instantiated by consuming applications.

  @class RecordArray
  @public
*/

class IdentifierArray {
  /**
    The flag to signal a `RecordArray` is currently loading data.
    Example
    ```javascript
    let people = store.peekAll('person');
    people.isUpdating; // false
    people.update();
    people.isUpdating; // true
    ```
    @property isUpdating
    @public
    @type Boolean
  */

  isLoaded = true;
  isDestroying = false;
  isDestroyed = false;
  _updatingPromise = null;
  [IS_COLLECTION] = true;
  [SOURCE];
  [NOTIFY]() {
    notifyArray(this);
  }

  /**
    The store that created this record array.
     @property store
    @private
    @type Store
    */

  destroy(clear) {
    this.isDestroying = !clear;
    // changing the reference breaks the Proxy
    // this[SOURCE] = [];
    this[SOURCE].length = 0;
    this[NOTIFY]();
    this.isDestroyed = !clear;
  }

  // length must be on self for proxied methods to work properly
  get length() {
    return this[SOURCE].length;
  }
  static {
    decorateMethodV2(this.prototype, "length", [compat]);
  }
  set length(value) {
    this[SOURCE].length = value;
  }
  constructor(options) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    this.modelName = options.type;
    this.store = options.store;
    this._manager = options.manager;
    this[SOURCE] = options.identifiers;
    this[ARRAY_SIGNAL] = createSignal(this, 'length');
    const store = options.store;
    const boundFns = new Map();
    const _SIGNAL = this[ARRAY_SIGNAL];
    const PrivateState = {
      links: options.links || null,
      meta: options.meta || null
    };
    let transaction = false;

    // when a mutation occurs
    // we track all mutations within the call
    // and forward them as one

    const proxy = new NativeProxy(this[SOURCE], {
      get(target, prop, receiver) {
        const index = convertToInt(prop);
        if (_SIGNAL.shouldReset && (index !== null || SYNC_PROPS.has(prop) || isArrayGetter(prop))) {
          options.manager._syncArray(receiver);
          _SIGNAL.t = false;
          _SIGNAL.shouldReset = false;
        }
        if (index !== null) {
          const identifier = target[index];
          if (!transaction) {
            subscribe(_SIGNAL);
          }
          return identifier && store._instanceCache.getRecord(identifier);
        }
        if (prop === 'meta') return subscribe(_SIGNAL), PrivateState.meta;
        if (prop === 'links') return subscribe(_SIGNAL), PrivateState.links;
        if (prop === '[]') return subscribe(_SIGNAL), receiver;
        if (isArrayGetter(prop)) {
          let fn = boundFns.get(prop);
          if (fn === undefined) {
            if (prop === 'forEach') {
              fn = function () {
                subscribe(_SIGNAL);
                transaction = true;
                const result = safeForEach(receiver, target, store, arguments[0], arguments[1]);
                transaction = false;
                return result;
              };
            } else {
              fn = function () {
                subscribe(_SIGNAL);
                // array functions must run through Reflect to work properly
                // binding via other means will not work.
                transaction = true;
                const result = Reflect.apply(target[prop], receiver, arguments);
                transaction = false;
                return result;
              };
            }
            boundFns.set(prop, fn);
          }
          return fn;
        }
        if (isArraySetter(prop)) {
          let fn = boundFns.get(prop);
          if (fn === undefined) {
            fn = function () {
              // array functions must run through Reflect to work properly
              // binding via other means will not work.
              if (!options.allowMutation) {
                macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
                  if (!test) {
                    throw new Error(`Mutating this array of records via ${String(prop)} is not allowed.`);
                  }
                })(options.allowMutation) : {};
                return;
              }
              const args = Array.prototype.slice.call(arguments);
              macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
                if (!test) {
                  throw new Error(`Cannot start a new array transaction while a previous transaction is underway`);
                }
              })(!transaction) : {};
              transaction = true;
              const result = self[MUTATE](target, receiver, prop, args, _SIGNAL);
              transaction = false;
              return result;
            };
            boundFns.set(prop, fn);
          }
          return fn;
        }
        if (isSelfProp(self, prop)) {
          if (prop === NOTIFY || prop === ARRAY_SIGNAL || prop === SOURCE) {
            return self[prop];
          }
          let fn = boundFns.get(prop);
          if (fn) return fn;
          const outcome = self[prop];
          if (typeof outcome === 'function') {
            fn = function () {
              subscribe(_SIGNAL);
              // array functions must run through Reflect to work properly
              // binding via other means will not work.
              return Reflect.apply(outcome, receiver, arguments);
            };
            boundFns.set(prop, fn);
            return fn;
          }
          return subscribe(_SIGNAL), outcome;
        }
        return target[prop];
      },
      // FIXME: Should this get a generic like get above?
      set(target, prop, value, receiver) {
        if (prop === 'length') {
          if (!transaction && value === 0) {
            transaction = true;
            self[MUTATE](target, receiver, 'length 0', [], _SIGNAL);
            transaction = false;
            return true;
          } else if (transaction) {
            return Reflect.set(target, prop, value);
          } else {
            macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
              {
                throw new Error(`unexpected length set`);
              }
            })() : {};
          }
        }
        if (prop === 'links') {
          PrivateState.links = value || null;
          return true;
        }
        if (prop === 'meta') {
          PrivateState.meta = value || null;
          return true;
        }
        const index = convertToInt(prop);

        // we do not allow "holey" arrays and so if the index is
        // greater than length then we will disallow setting it.
        // however, there is a special case for "unshift" with more than
        // one item being inserted since current items will be moved to the
        // new indices first.
        // we "loosely" detect this by just checking whether we are in
        // a transaction.
        if (index === null || index > target.length) {
          if (index !== null && transaction) {
            const identifier = recordIdentifierFor(value);
            macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
              if (!test) {
                throw new Error(`Cannot set index ${index} past the end of the array.`);
              }
            })(isStableIdentifier(identifier)) : {};
            target[index] = identifier;
            return true;
          } else if (isSelfProp(self, prop)) {
            // @ts-expect-error not all properties are indeces and we can't safely cast
            self[prop] = value;
            return true;
          }
          return false;
        }
        if (!options.allowMutation) {
          macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
            if (!test) {
              throw new Error(`Mutating ${String(prop)} on this Array is not allowed.`);
            }
          })(options.allowMutation) : {};
          return false;
        }
        const original = target[index];
        const newIdentifier = extractIdentifierFromRecord$2(value);
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          if (!test) {
            throw new Error(`Expected a record`);
          }
        })(isStableIdentifier(newIdentifier)) : {};
        // We generate "transactions" whenever a setter method on the array
        // is called and might bulk update multiple array cells. Fundamentally,
        // all array operations decompose into individual cell replacements.
        // e.g. a push is really a "replace cell at next index with new value"
        // or a splice is "shift all values left/right by X and set out of new
        // bounds cells to undefined"
        //
        // so, if we are in a transaction, then this is not a user generated change
        // but one generated by a setter method. In this case we want to only apply
        // the change to the target array and not call the MUTATE method.
        // If there is no transaction though, then this means the user themselves has
        // directly changed the value of a specific index and we need to thus generate
        // a mutation for that change.
        // e.g. "arr.push(newVal)" is handled by a "addToRelatedRecords" mutation within
        // a transaction.
        // while "arr[arr.length] = newVal;" is handled by this replace cell code path.
        if (!transaction) {
          self[MUTATE](target, receiver, 'replace cell', [index, original, newIdentifier], _SIGNAL);
        } else {
          target[index] = newIdentifier;
        }
        return true;
      },
      deleteProperty(target, prop) {
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          if (!test) {
            throw new Error(`Deleting keys on managed arrays is disallowed`);
          }
        })(transaction) : {};
        if (!transaction) {
          return false;
        }
        return Reflect.deleteProperty(target, prop);
      },
      getPrototypeOf() {
        return Array.prototype;
      }
    });
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      Object.defineProperty(this, '__SHOW_ME_THE_DATA_(debug mode only)__', {
        enumerable: false,
        configurable: true,
        get() {
          return proxy.slice();
        }
      });
    }
    createArrayTags(proxy, _SIGNAL);
    this[NOTIFY] = this[NOTIFY].bind(proxy);
    return proxy;
  }

  /**
    Used to get the latest version of all of the records in this array
    from the adapter.
     Example
     ```javascript
    let people = store.peekAll('person');
    people.isUpdating; // false
     people.update().then(function() {
      people.isUpdating; // false
    });
     people.isUpdating; // true
    ```
     @method update
    @public
  */
  update() {
    if (this.isUpdating) {
      return this._updatingPromise;
    }
    this.isUpdating = true;
    const updatingPromise = this._update();
    void updatingPromise.finally(() => {
      this._updatingPromise = null;
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      this.isUpdating = false;
    });
    this._updatingPromise = updatingPromise;
    return updatingPromise;
  }

  /*
    Update this Array and return a promise which resolves once the update
    is finished.
   */
  _update() {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`_update cannot be used with this array`);
      }
    })(this.modelName) : {};
    // @ts-expect-error typescript is unable to handle the complexity of
    //   T = unknown, modelName = string
    //   T extends TypedRecordInstance, modelName = TypeFromInstance<T>
    // both being valid options to pass through here.
    return this.store.findAll(this.modelName, {
      reload: true
    });
  }

  // TODO deprecate
  /**
    Saves all of the records in the `RecordArray`.
     Example
     ```javascript
    let messages = store.peekAll('message');
    messages.forEach(function(message) {
      message.hasBeenSeen = true;
    });
    messages.save();
    ```
     @method save
    @public
    @return {Promise<IdentifierArray>} promise
  */
  save() {
    const promise = Promise.all(this.map(record => this.store.saveRecord(record))).then(() => this);
    return promise;
  }
}

// this will error if someone tries to call
// A(identifierArray) since it is not configurable
// which is preferable to the `meta` override we used
// before which required importing all of Ember
const desc = {
  enumerable: true,
  configurable: false,
  get: function () {
    // here to support computed chains
    // and {{#each}}
    if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_COMPUTED_CHAINS)) {
      return this;
    }
  }
};
compat(desc);
Object.defineProperty(IdentifierArray.prototype, '[]', desc);
defineSignal(IdentifierArray.prototype, 'isUpdating', false);
class Collection extends IdentifierArray {
  query = null;
  constructor(options) {
    super(options);
    this.query = options.query || null;
    this.isLoaded = options.isLoaded || false;
  }
  _update() {
    const {
      store,
      query
    } = this;

    // TODO save options from initial request?
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`update cannot be used with this array`);
      }
    })(this.modelName) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`update cannot be used with no query`);
      }
    })(query) : {};
    // @ts-expect-error typescript is unable to handle the complexity of
    //   T = unknown, modelName = string
    //   T extends TypedRecordInstance, modelName = TypeFromInstance<T>
    // both being valid options to pass through here.
    const promise = store.query(this.modelName, query, {
      _recordArray: this
    });
    return promise;
  }
  destroy(clear) {
    super.destroy(clear);
    this._manager._managed.delete(this);
    this._manager._pending.delete(this);
  }
}
// trick the proxy "in" check
Collection.prototype.query = null;

// Ensure instanceof works correctly
// Object.setPrototypeOf(IdentifierArray.prototype, Array.prototype);

function assertRecordPassedToHasMany$1(record) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`All elements of a hasMany relationship must be instances of Model, you passed $${typeof record}`);
    }
  })(function () {
    try {
      recordIdentifierFor(record);
      return true;
    } catch {
      return false;
    }
  }()) : {};
}
function extractIdentifierFromRecord$2(record) {
  if (!record) {
    return null;
  }
  assertRecordPassedToHasMany$1(record);
  return recordIdentifierFor(record);
}

/**
  @module @ember-data/store
*/
const FAKE_ARR = getOrSetGlobal('FAKE_ARR', {});
const SLICE_BATCH_SIZE = 1200;
/**
 * This is a clever optimization.
 *
 * clever optimizations rarely stand the test of time, so if you're
 * ever curious or think something better is possible please benchmark
 * and discuss. The benchmark for this at the time of writing is in
 * `scripts/benchmark-push.js`
 *
 * This approach turns out to be 150x faster in Chrome and node than
 * simply using push or concat. It's highly susceptible to the specifics
 * of the batch size, and may require tuning.
 *
 * Clever optimizations should always come with a `why`. This optimization
 * exists for two reasons.
 *
 * 1) array.push(...objects) and Array.prototype.push.apply(arr, objects)
 *   are susceptible to stack overflows. The size of objects at which this
 *   occurs varies by environment, browser, and current stack depth and memory
 *   pressure; however, it occurs in all browsers in fairly pristine conditions
 *   somewhere around 125k to 200k elements. Since EmberData regularly encounters
 *   arrays larger than this in size, we cannot use push.
 *
 * 2) `array.concat` or simply setting the array to a new reference is often an
 *   easier approach; however, native Proxy to an array cannot swap it's target array
 *   and attempts at juggling multiple array sources have proven to be victim to a number
 *   of browser implementation bugs. Should these bugs be addressed then we could
 *   simplify to using `concat`, however, do note this is currently 150x faster
 *   than concat, and due to the overloaded signature of concat will likely always
 *   be faster.
 *
 * Sincerely,
 *   - runspired (Chris Thoburn) 08/21/2022
 *
 * @function fastPush
 * @internal
 * @param target the array to push into
 * @param source the items to push into target
 */
function fastPush(target, source) {
  let startLength = 0;
  const newLength = source.length;
  while (newLength - startLength > SLICE_BATCH_SIZE) {
    // eslint-disable-next-line prefer-spread
    target.push.apply(target, source.slice(startLength, startLength + SLICE_BATCH_SIZE));
    startLength += SLICE_BATCH_SIZE;
  }
  // eslint-disable-next-line prefer-spread
  target.push.apply(target, source.slice(startLength));
}
/**
  @class RecordArrayManager
  @internal
*/
class RecordArrayManager {
  constructor(options) {
    this.store = options.store;
    this.isDestroying = false;
    this.isDestroyed = false;
    this._live = new Map();
    this._managed = new Set();
    this._pending = new Map();
    this._staged = new Map();
    this._keyedArrays = new Map();
    this._identifiers = new Map();
    this._set = new Map();
    this._visibilitySet = new Map();
    this._subscription = this.store.notifications.subscribe('resource', (identifier, type) => {
      if (type === 'added') {
        this._visibilitySet.set(identifier, true);
        this.identifierAdded(identifier);
      } else if (type === 'removed') {
        this._visibilitySet.set(identifier, false);
        this.identifierRemoved(identifier);
      } else if (type === 'state') {
        this.identifierChanged(identifier);
      }
    });
  }
  _syncArray(array) {
    const pending = this._pending.get(array);
    if (!pending || this.isDestroying || this.isDestroyed) {
      return;
    }
    sync(array, pending, this._set.get(array));
    this._pending.delete(array);
  }
  mutate(mutation) {
    this.store.cache.mutate(mutation);
  }

  /**
    Get the `RecordArray` for a modelName, which contains all loaded records of
    given modelName.
     @method liveArrayFor
    @internal
    @param {String} modelName
    @return {RecordArray}
  */
  liveArrayFor(type) {
    let array = this._live.get(type);
    const identifiers = [];
    const staged = this._staged.get(type);
    if (staged) {
      staged.forEach((value, key) => {
        if (value === 'add') {
          identifiers.push(key);
        }
      });
      this._staged.delete(type);
    }
    if (!array) {
      array = new IdentifierArray({
        type,
        identifiers,
        store: this.store,
        allowMutation: false,
        manager: this
      });
      this._live.set(type, array);
      this._set.set(array, new Set(identifiers));
    }
    return array;
  }
  createArray(config) {
    const options = {
      type: config.type,
      links: config.doc?.links || null,
      meta: config.doc?.meta || null,
      query: config.query || null,
      identifiers: config.identifiers || [],
      isLoaded: !!config.identifiers?.length,
      allowMutation: false,
      store: this.store,
      manager: this
    };
    const array = new Collection(options);
    this._managed.add(array);
    this._set.set(array, new Set(options.identifiers || []));
    if (config.identifiers) {
      associate(this._identifiers, array, config.identifiers);
    }
    return array;
  }
  dirtyArray(array, delta) {
    if (array === FAKE_ARR) {
      return;
    }
    const tag = array[ARRAY_SIGNAL];
    if (!tag.shouldReset) {
      tag.shouldReset = true;
      addTransactionCB(array[NOTIFY]);
    } else if (delta > 0 && !tag.t) {
      addTransactionCB(array[NOTIFY]);
    }
  }
  _getPendingFor(identifier, includeManaged, isRemove) {
    if (this.isDestroying || this.isDestroyed) {
      return;
    }
    const liveArray = this._live.get(identifier.type);
    const allPending = this._pending;
    const pending = new Map();
    if (includeManaged) {
      const managed = this._identifiers.get(identifier);
      if (managed) {
        managed.forEach(arr => {
          let changes = allPending.get(arr);
          if (!changes) {
            changes = new Map();
            allPending.set(arr, changes);
          }
          pending.set(arr, changes);
        });
      }
    }

    // during unloadAll we can ignore removes since we've already
    // cleared the array.
    if (liveArray && liveArray[SOURCE].length === 0 && isRemove) {
      const pendingLive = allPending.get(liveArray);
      if (!pendingLive || pendingLive.size === 0) {
        return pending;
      }
    }
    if (!liveArray) {
      // start building a changeset for when we eventually
      // do have a live array
      let changes = this._staged.get(identifier.type);
      if (!changes) {
        changes = new Map();
        this._staged.set(identifier.type, changes);
      }
      pending.set(FAKE_ARR, changes);
    } else {
      let changes = allPending.get(liveArray);
      if (!changes) {
        changes = new Map();
        allPending.set(liveArray, changes);
      }
      pending.set(liveArray, changes);
    }
    return pending;
  }
  populateManagedArray(array, identifiers, payload) {
    this._pending.delete(array);
    const source = array[SOURCE];
    const old = source.slice();
    source.length = 0;
    fastPush(source, identifiers);
    this._set.set(array, new Set(identifiers));
    notifyArray(array);
    array.meta = payload.meta || null;
    array.links = payload.links || null;
    array.isLoaded = true;
    disassociate(this._identifiers, array, old);
    associate(this._identifiers, array, identifiers);
  }
  identifierAdded(identifier) {
    const changeSets = this._getPendingFor(identifier, false);
    if (changeSets) {
      changeSets.forEach((changes, array) => {
        const existing = changes.get(identifier);
        if (existing === 'del') {
          changes.delete(identifier);
        } else {
          changes.set(identifier, 'add');
          this.dirtyArray(array, changes.size);
        }
      });
    }
  }
  identifierRemoved(identifier) {
    const changeSets = this._getPendingFor(identifier, true, true);
    if (changeSets) {
      changeSets.forEach((changes, array) => {
        const existing = changes.get(identifier);
        if (existing === 'add') {
          changes.delete(identifier);
        } else {
          changes.set(identifier, 'del');
          this.dirtyArray(array, changes.size);
        }
      });
    }
  }
  identifierChanged(identifier) {
    const newState = this.store._instanceCache.recordIsLoaded(identifier, true);

    // if the change matches the most recent direct added/removed
    // state, then we can ignore it
    if (this._visibilitySet.get(identifier) === newState) {
      return;
    }
    if (newState) {
      this.identifierAdded(identifier);
    } else {
      this.identifierRemoved(identifier);
    }
  }
  clear(isClear = true) {
    this._live.forEach(array => array.destroy(isClear));
    this._managed.forEach(array => array.destroy(isClear));
    this._managed.clear();
    this._identifiers.clear();
    this._pending.clear();
    this._set.forEach(set => set.clear());
    this._visibilitySet.clear();
  }
  destroy() {
    this.isDestroying = true;
    this.clear(false);
    this._live.clear();
    this.isDestroyed = true;
    this.store.notifications.unsubscribe(this._subscription);
  }
}
function associate(ArraysCache, array, identifiers) {
  for (let i = 0; i < identifiers.length; i++) {
    const identifier = identifiers[i];
    let cache = ArraysCache.get(identifier);
    if (!cache) {
      cache = new Set();
      ArraysCache.set(identifier, cache);
    }
    cache.add(array);
  }
}
function disassociate(ArraysCache, array, identifiers) {
  for (let i = 0; i < identifiers.length; i++) {
    disassociateIdentifier(ArraysCache, array, identifiers[i]);
  }
}
function disassociateIdentifier(ArraysCache, array, identifier) {
  const cache = ArraysCache.get(identifier);
  if (cache) {
    cache.delete(array);
  }
}
function sync(array, changes, arraySet) {
  const state = array[SOURCE];
  const adds = [];
  const removes = [];
  changes.forEach((value, key) => {
    if (value === 'add') {
      // likely we want to keep a Set along-side
      if (arraySet.has(key)) {
        return;
      }
      adds.push(key);
      arraySet.add(key);
    } else {
      if (arraySet.has(key)) {
        removes.push(key);
        arraySet.delete(key);
      }
    }
  });
  if (removes.length) {
    if (removes.length === state.length) {
      state.length = 0;
      // changing the reference breaks the Proxy
      // state = array[SOURCE] = [];
    } else {
      removes.forEach(i => {
        const index = state.indexOf(i);
        if (index !== -1) {
          state.splice(index, 1);
          arraySet.delete(i);
        }
      });
    }
  }
  if (adds.length) {
    fastPush(state, adds);
    // changing the reference breaks the Proxy
    // else we could do this
    /*
    if (state.length === 0) {
      array[SOURCE] = adds;
    } else {
      array[SOURCE] = state.concat(adds);
    }
    */
  }
}

/**
 * @module @ember-data/store
 */

const Touching = getOrSetGlobal('Touching', Symbol('touching'));
const RequestPromise = getOrSetGlobal('RequestPromise', Symbol('promise'));
const EMPTY_ARR = macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? Object.freeze([]) : [];
function hasRecordIdentifier(op) {
  return 'recordIdentifier' in op;
}

/**
 * The RequestStateService is used to track the state of requests
 * for fetching or updating known resource identifies that are inflight.
 *
 * @class RequestStateService
 * @public
 */
class RequestStateService {
  _pending = new Map();
  _done = new Map();
  _subscriptions = new Map();
  _toFlush = [];
  _store;
  constructor(store) {
    this._store = store;
  }
  _clearEntries(identifier) {
    this._done.delete(identifier);
  }
  _enqueue(promise, queryRequest) {
    const query = queryRequest.data[0];
    if (hasRecordIdentifier(query)) {
      const identifier = query.recordIdentifier;
      const type = query.op === 'saveRecord' ? 'mutation' : 'query';
      if (!this._pending.has(identifier)) {
        this._pending.set(identifier, []);
      }
      const request = {
        state: 'pending',
        request: queryRequest,
        type
      };
      request[Touching] = [query.recordIdentifier];
      request[RequestPromise] = promise;
      this._pending.get(identifier).push(request);
      this._triggerSubscriptions(request);
      return promise.then(result => {
        this._dequeue(identifier, request);
        const finalizedRequest = {
          state: 'fulfilled',
          request: queryRequest,
          type,
          response: {
            data: result
          }
        };
        finalizedRequest[Touching] = request[Touching];
        this._addDone(finalizedRequest);
        this._triggerSubscriptions(finalizedRequest);
        return result;
      }, error => {
        this._dequeue(identifier, request);
        const finalizedRequest = {
          state: 'rejected',
          request: queryRequest,
          type,
          response: {
            data: error
          }
        };
        finalizedRequest[Touching] = request[Touching];
        this._addDone(finalizedRequest);
        this._triggerSubscriptions(finalizedRequest);
        throw error;
      });
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`Expected a well formed  query`);
      }
    })() : {};
  }
  _triggerSubscriptions(req) {
    if (req.state === 'pending') {
      this._flushRequest(req);
      return;
    }
    this._toFlush.push(req);
    if (this._toFlush.length === 1) {
      this._store.notifications._onNextFlush(() => {
        this._flush();
      });
    }
  }
  _flush() {
    this._toFlush.forEach(req => {
      this._flushRequest(req);
    });
    this._toFlush = [];
  }
  _flushRequest(req) {
    req[Touching].forEach(identifier => {
      const subscriptions = this._subscriptions.get(identifier);
      if (subscriptions) {
        subscriptions.forEach(callback => callback(req));
      }
    });
  }
  _dequeue(identifier, request) {
    const pending = this._pending.get(identifier);
    this._pending.set(identifier, pending.filter(req => req !== request));
  }
  _addDone(request) {
    request[Touching].forEach(identifier => {
      // TODO add support for multiple
      const requestDataOp = request.request.data[0].op;
      let requests = this._done.get(identifier);
      if (requests) {
        requests = requests.filter(req => {
          // TODO add support for multiple
          let data;
          if (Array.isArray(req.request.data)) {
            data = req.request.data[0];
          } else {
            data = req.request.data;
          }
          return data.op !== requestDataOp;
        });
      }
      requests = requests || [];
      requests.push(request);
      this._done.set(identifier, requests);
    });
  }

  /**
   * Subscribe to requests for a given resource identity.
   *
   * The callback will receive the current state of the request.
   *
   * ```ts
   * interface RequestState {
   *   state: 'pending' | 'fulfilled' | 'rejected';
   *   type: 'query' | 'mutation';
   *   request: Request;
   *   response?: { data: unknown };
   * }
   * ```
   *
   * Note: It should be considered dangerous to use this API for more than simple
   * state derivation or debugging. The `request` and `response` properties are poorly
   * spec'd and may change unexpectedly when shifting what Handlers are in use or how
   * requests are issued from the Store.
   *
   * We expect to revisit this API in the near future as we continue to refine the
   * RequestManager ergonomics, as a simpler but more powerful direct integration
   * with the RequestManager for these purposes is likely to be a better long-term
   * design.
   *
   * @method subscribeForRecord
   * @public
   * @param {StableRecordIdentifier} identifier
   * @param {(state: RequestState) => void} callback
   */
  subscribeForRecord(identifier, callback) {
    let subscriptions = this._subscriptions.get(identifier);
    if (!subscriptions) {
      subscriptions = [];
      this._subscriptions.set(identifier, subscriptions);
    }
    subscriptions.push(callback);
  }

  /**
   * Retrieve all active requests for a given resource identity.
   *
   * @method getPendingRequestsForRecord
   * @public
   * @param {StableRecordIdentifier} identifier
   * @return {RequestState[]} an array of request states for any pending requests for the given identifier
   */
  getPendingRequestsForRecord(identifier) {
    return this._pending.get(identifier) || EMPTY_ARR;
  }

  /**
   * Retrieve the last completed request for a given resource identity.
   *
   * @method getLastRequestForRecord
   * @public
   * @param {StableRecordIdentifier} identifier
   * @return {RequestState | null} the state of the most recent request for the given identifier
   */
  getLastRequestForRecord(identifier) {
    const requests = this._done.get(identifier);
    if (requests) {
      return requests[requests.length - 1];
    }
    return null;
  }
}
function isNonEmptyString(str) {
  return Boolean(str && typeof str === 'string');
}
function constructResource(type, id, lid) {
  if (typeof type === 'object' && type !== null) {
    const resource = type;
    if (isStableIdentifier(resource)) {
      return resource;
    }
    if ('id' in resource) {
      resource.id = coerceId(resource.id);
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error('Expected either id or lid to be a valid string');
      }
    })('id' in resource && isNonEmptyString(resource.id) || isNonEmptyString(resource.lid)) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error('if id is present, the type must be a string');
      }
    })(!('id' in resource) || typeof resource.type === 'string') : {};
    return resource;
  } else {
    const trueId = coerceId(id);
    if (!isNonEmptyString(trueId)) {
      if (isNonEmptyString(lid)) {
        return {
          lid
        };
      }
      throw new Error('Expected either id or lid to be a valid string');
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error('type must be a string');
      }
    })(typeof type === 'string') : {};
    if (isNonEmptyString(lid)) {
      return {
        type,
        id: trueId,
        lid
      };
    }
    return {
      type,
      id: trueId
    };
  }
}

/**
  @module @ember-data/store
 */
// this import location is deprecated but breaks in 4.8 and older

// @ts-expect-error adding to globalThis
globalThis.setWarpDriveLogging = setLogging;

// @ts-expect-error adding to globalThis
globalThis.getWarpDriveRuntimeConfig = getRuntimeConfig;
if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_METRIC_COUNTS)) {
  if (getGlobalConfig().WarpDrive.debug.LOG_METRIC_COUNTS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_METRIC_COUNTS) {
    // @ts-expect-error adding to globalThis
    // eslint-disable-next-line
    globalThis.__WarpDriveMetricCountData = globalThis.__WarpDriveMetricCountData || {};

    // @ts-expect-error adding to globalThis
    globalThis.getWarpDriveMetricCounts = () => {
      // @ts-expect-error
      // eslint-disable-next-line
      return structuredClone(globalThis.__WarpDriveMetricCountData);
    };

    // @ts-expect-error adding to globalThis
    globalThis.resetWarpDriveMetricCounts = () => {
      // @ts-expect-error
      globalThis.__WarpDriveMetricCountData = {};
    };
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.__INTERNAL_LOG_NATIVE_MAP_SET_COUNTS)) {
      if (getGlobalConfig().WarpDrive.debug.__INTERNAL_LOG_NATIVE_MAP_SET_COUNTS || globalThis.getWarpDriveRuntimeConfig().debug.__INTERNAL_LOG_NATIVE_MAP_SET_COUNTS) {
        // @ts-expect-error adding to globalThis
        globalThis.__primitiveInstanceId = 0;
        function interceptAndLog(klassName, methodName) {
          const klass = globalThis[klassName];
          if (methodName === 'constructor') {
            const instantiationLabel = `new ${klassName}()`;
            // @ts-expect-error
            globalThis[klassName] = class extends klass {
              // @ts-expect-error
              constructor(...args) {
                // eslint-disable-next-line
                super(...args);
                // @ts-expect-error

                const instanceId = globalThis.__primitiveInstanceId++;
                // @ts-expect-error
                // eslint-disable-next-line
                globalThis.__WarpDriveMetricCountData[instantiationLabel] =
                // @ts-expect-error
                // eslint-disable-next-line
                (globalThis.__WarpDriveMetricCountData[instantiationLabel] || 0) + 1;
                // @ts-expect-error
                this.instanceName = `${klassName}:${instanceId} - ${new Error().stack?.split('\n')[2]}`;
              }
            };
          } else {
            // @ts-expect-error
            // eslint-disable-next-line
            const original = klass.prototype[methodName];
            const logName = `${klassName}.${methodName}`;

            // @ts-expect-error
            klass.prototype[methodName] = function (...args) {
              // @ts-expect-error
              // eslint-disable-next-line
              globalThis.__WarpDriveMetricCountData[logName] = (globalThis.__WarpDriveMetricCountData[logName] || 0) + 1;
              // @ts-expect-error
              const {
                instanceName
              } = this;
              if (!instanceName) {
                // @ts-expect-error
                const instanceId = globalThis.__primitiveInstanceId++;
                // @ts-expect-error
                this.instanceName = `${klassName}.${methodName}:${instanceId} - ${new Error().stack?.split('\n')[2]}`;
              }
              const instanceLogName = `${logName} (${instanceName})`;
              // @ts-expect-error
              // eslint-disable-next-line
              globalThis.__WarpDriveMetricCountData[instanceLogName] =
              // @ts-expect-error
              // eslint-disable-next-line
              (globalThis.__WarpDriveMetricCountData[instanceLogName] || 0) + 1;
              // eslint-disable-next-line
              return original.apply(this, args);
            };
          }
        }
        interceptAndLog('Set', 'constructor');
        interceptAndLog('Set', 'add');
        interceptAndLog('Set', 'delete');
        interceptAndLog('Set', 'has');
        interceptAndLog('Set', 'set');
        interceptAndLog('Set', 'get');
        interceptAndLog('Map', 'constructor');
        interceptAndLog('Map', 'set');
        interceptAndLog('Map', 'delete');
        interceptAndLog('Map', 'has');
        interceptAndLog('Map', 'add');
        interceptAndLog('Map', 'get');
        interceptAndLog('WeakSet', 'constructor');
        interceptAndLog('WeakSet', 'add');
        interceptAndLog('WeakSet', 'delete');
        interceptAndLog('WeakSet', 'has');
        interceptAndLog('WeakSet', 'set');
        interceptAndLog('WeakSet', 'get');
        interceptAndLog('WeakMap', 'constructor');
        interceptAndLog('WeakMap', 'set');
        interceptAndLog('WeakMap', 'delete');
        interceptAndLog('WeakMap', 'has');
        interceptAndLog('WeakMap', 'add');
        interceptAndLog('WeakMap', 'get');
      }
    }
  }
}

// `AwaitedKeys` is needed here to resolve any promise types like `PromiseBelongsTo`.

/**
 * Currently only records that extend object can be created via
 * store.createRecord. This is a limitation of the current API,
 * but can be worked around by creating a new identifier, running
 * the cache.clientDidCreate method, and then peeking the record
 * for the identifier.
 *
 * To assign primary key to a record during creation, only `id` will
 * work correctly for `store.createRecord`, other primary key may be
 * handled by updating the record after creation or using the flow
 * described above.
 *
 * TODO: These are limitations we want to (and can) address. If you
 * have need of lifting these limitations, please open an issue.
 *
 * @typedoc
 */

/**
 * A Store coordinates interaction between your application, a [Cache](https://api.emberjs.com/ember-data/release/classes/%3CInterface%3E%20Cache),
 * and sources of data (such as your API or a local persistence layer)
 * accessed via a [RequestManager](https://github.com/emberjs/data/tree/main/packages/request).
 *
 * ```app/services/store.js
 * import Store from '@ember-data/store';
 *
 * export default class extends Store {}
 * ```
 *
 * Most Applications will only have a single `Store` configured as a Service
 * in this manner. However, setting up multiple stores is possible, including using
 * each as a unique service or within a specific context.
 *

  @class Store
  @public
*/
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
const EmptyClass = class {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(args) {}
};
const _BaseClass = macroCondition(dependencySatisfies('ember-source', '*')) ? macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_STORE_EXTENDS_EMBER_OBJECT) ? importSync('@ember/object') : EmptyClass : EmptyClass;
const BaseClass = _BaseClass.default ? _BaseClass.default : _BaseClass;
if (BaseClass !== EmptyClass) {
  deprecate(`The Store class extending from EmberObject is deprecated.
Please remove usage of EmberObject APIs and mark your class as not requiring it.

To mark the class as no longer extending from EmberObject, in ember-cli-build.js
set the following config:

\`\`\`js
const app = new EmberApp(defaults, {
  emberData: {
    deprecations: {
      DEPRECATE_STORE_EXTENDS_EMBER_OBJECT: false
    }
  }
});
\`\`\`
`, false, {
    id: 'ember-data:deprecate-store-extends-ember-object',
    until: '6.0',
    for: 'ember-data',
    since: {
      available: '4.13',
      enabled: '5.4'
    }
  });
}
class Store extends BaseClass {
  /**
   * Provides access to the NotificationManager associated
   * with this Store instance.
   *
   * The NotificationManager can be used to subscribe to
   * changes to the cache.
   *
   * @property {NotificationManager} notifications
   * @public
   */

  /**
   * Provides access to the SchemaService instance
   * for this Store instance.
   *
   * The SchemaService can be used to query for
   * information about the schema of a resource.
   *
   * @property {SchemaService} schema
   * @public
   */
  get schema() {
    if (!this._schema) {
      this._schema = this.createSchemaService();
    }
    return this._schema;
  }

  /**
   * Provides access to the IdentifierCache instance
   * for this store.
   *
   * The IdentifierCache can be used to generate or
   * retrieve a stable unique identifier for any resource.
   *
   * @property {IdentifierCache} identifierCache
   * @public
   */

  /**
   * Provides access to the requestManager instance associated
   * with this Store instance.
   *
   * When using `ember-data` this property is automatically
   * set to an instance of `RequestManager`. When not using `ember-data`
   * you must configure this property yourself, either by declaring
   * it as a service or by initializing it.
   *
   * ```ts
   * import Store, { CacheHandler } from '@ember-data/store';
   * import RequestManager from '@ember-data/request';
   * import Fetch from '@ember-data/request/fetch';
   *
   * class extends Store {
   *   requestManager = new RequestManager()
   *    .use([Fetch])
   *    .useCache(CacheHandler);
   * }
   * ```
   *
   * @public
   * @property {RequestManager} requestManager
   */

  /**
   * A Property which an App may set to provide a CachePolicy
   * to control when a cached request becomes stale.
   *
   * Note, when defined, these methods will only be invoked if a
   * cache key exists for the request, either because the request
   * contains `cacheOptions.key` or because the [IdentifierCache](/ember-data/release/classes/IdentifierCache)
   * was able to generate a key for the request using the configured
   * [generation method](/ember-data/release/functions/@ember-data%2Fstore/setIdentifierGenerationMethod).
   *
   * `isSoftExpired` will only be invoked if `isHardExpired` returns `false`.
   *
   * ```ts
   * store.lifetimes = {
   *   // make the request and ignore the current cache state
   *   isHardExpired(identifier: StableDocumentIdentifier): boolean {
   *     return false;
   *   }
   *
   *   // make the request in the background if true, return cache state
   *   isSoftExpired(identifier: StableDocumentIdentifier): boolean {
   *     return false;
   *   }
   * }
   * ```
   *
   * @public
   * @property {CachePolicy|undefined} lifetimes
   */

  // Private

  /**
   * Async flush buffers notifications until flushed
   * by finalization of a future configured by store.request
   *
   * This is useful for ensuring that notifications are delivered
   * prior to the promise resolving but without risk of promise
   * interleaving.
   *
   * @internal
   */

  // DEBUG-only properties

  get isDestroying() {
    return this._isDestroying;
  }
  set isDestroying(value) {
    this._isDestroying = value;
  }
  get isDestroyed() {
    return this._isDestroyed;
  }
  set isDestroyed(value) {
    this._isDestroyed = value;
  }

  /**
    @method init
    @private
  */
  constructor(createArgs) {
    super(createArgs);
    Object.assign(this, createArgs);
    this.identifierCache = new IdentifierCache();
    this.notifications = new NotificationManager(this);

    // private but maybe useful to be here, somewhat intimate
    this.recordArrayManager = new RecordArrayManager({
      store: this
    });

    // private
    this._requestCache = new RequestStateService(this);
    this._instanceCache = new InstanceCache(this);
    this._documentCache = new Map();
    this.isDestroying = false;
    this.isDestroyed = false;
  }
  _run(cb) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`EmberData should never encounter a nested run`);
      }
    })(!this._cbs) : {};
    const _cbs = this._cbs = {};
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      try {
        cb();
        if (_cbs.coalesce) {
          _cbs.coalesce();
        }
        if (_cbs.sync) {
          _cbs.sync();
        }
        if (_cbs.notify) {
          _cbs.notify();
        }
      } finally {
        this._cbs = null;
      }
    } else {
      cb();
      if (_cbs.coalesce) {
        _cbs.coalesce();
      }
      if (_cbs.sync) {
        _cbs.sync();
      }
      if (_cbs.notify) {
        _cbs.notify();
      }
      this._cbs = null;
    }
  }

  /**
   * Executes the callback, ensurng that any work that calls
   * store._schedule is executed after in the right order.
   *
   * When queues already exist, scheduled callbacks will
   * join the existing queue.
   *
   * @internal
   */
  _join(cb) {
    if (this._cbs) {
      cb();
    } else {
      this._run(cb);
    }
  }
  _schedule(name, cb) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`EmberData expects to schedule only when there is an active run`);
      }
    })(!!this._cbs) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`EmberData expects only one flush per queue name, cannot schedule ${name}`);
      }
    })(!this._cbs[name]) : {};
    this._cbs[name] = cb;
  }

  /**
   * Retrieve the RequestStateService instance
   * associated with this Store.
   *
   * This can be used to query the status of requests
   * that have been initiated for a given identifier.
   *
   * @method getRequestStateService
   * @return {RequestStateService}
   * @public
   */
  getRequestStateService() {
    return this._requestCache;
  }
  _getAllPending() {
    if (macroCondition(getGlobalConfig().WarpDrive.env.TESTING)) {
      const all = [];
      const pending = this._requestCache._pending;
      pending.forEach(requests => {
        all.push(...requests.map(v => v[RequestPromise]));
      });
      this.requestManager._pending.forEach(v => all.push(v));
      const promise = Promise.allSettled(all);
      promise.length = all.length;
      return promise;
    }
  }

  /**
   * Issue a request via the configured RequestManager,
   * inserting the response into the cache and handing
   * back a Future which resolves to a ResponseDocument
   *
   * ## Cache Keys
   *
   * Only GET requests with a url or requests with an explicit
   * cache key (`cacheOptions.key`) will have the request result
   * and document cached.
   *
   * The cache key used is `requestConfig.cacheOptions.key`
   * if present, falling back to `requestConfig.url`.
   *
   * Params are not serialized as part of the cache-key, so
   * either ensure they are already in the url or utilize
   * `requestConfig.cacheOptions.key`. For queries issued
   * via the `POST` method `requestConfig.cacheOptions.key`
   * MUST be supplied for the document to be cached.
   *
   * ## Requesting Without a Cache Key
   *
   * Resource data within the request is always updated in the cache,
   * regardless of whether a cache key is present for the request.
   *
   * ## Fulfilling From Cache
   *
   * When a cache-key is determined, the request may fulfill
   * from cache provided the cache is not stale.
   *
   * Cache staleness is determined by the configured CachePolicy
   * with priority given to the `cacheOptions.reload` and
   * `cacheOptions.backgroundReload` on the request if present.
   *
   * If the cache data has soft expired or the request asks for a background
   * reload, the request will fulfill from cache if possible and
   * make a non-blocking request in the background to update the cache.
   *
   * If the cache data has hard expired or the request asks for a reload,
   * the request will not fulfill from cache and will make a blocking
   * request to update the cache.
   *
   * ## The Response
   *
   * The primary difference between `requestManager.request` and `store.request`
   * is that `store.request` will attempt to hydrate the response content into
   * a response Document containing RecordInstances.
   *
   * @method request
   * @param {StoreRequestInput} requestConfig
   * @return {Future}
   * @public
   */
  request(requestConfig) {
    // we lazily set the cache handler when we issue the first request
    // because constructor doesn't allow for this to run after
    // the user has had the chance to set the prop.
    const opts = {
      store: this,
      [EnableHydration]: requestConfig[EnableHydration] ?? true
    };
    if (requestConfig.records) {
      const identifierCache = this.identifierCache;
      opts.records = requestConfig.records.map(r => identifierCache.getOrCreateRecordIdentifier(r));
    }
    if (macroCondition(getGlobalConfig().WarpDrive.env.TESTING)) {
      if (this.DISABLE_WAITER) {
        opts.disableTestWaiter = typeof requestConfig.disableTestWaiter === 'boolean' ? requestConfig.disableTestWaiter : true;
      }
    }
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_REQUESTS)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_REQUESTS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_REQUESTS) {
        let options;
        try {
          options = JSON.parse(JSON.stringify(requestConfig));
        } catch {
          options = requestConfig;
        }
        // eslint-disable-next-line no-console
        console.log(`request: [[START]] ${requestConfig.op && !requestConfig.url ? '(LEGACY) ' : ''}${requestConfig.op || '<unknown operation>'} ${requestConfig.url || '<empty url>'}  ${requestConfig.method || '<empty method>'}`, options);
      }
    }
    const request = Object.assign({}, requestConfig, opts);
    const future = this.requestManager.request(request);
    future.onFinalize(() => {
      if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_REQUESTS)) {
        if (getGlobalConfig().WarpDrive.debug.LOG_REQUESTS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_REQUESTS) {
          // eslint-disable-next-line no-console
          console.log(`request: [[FINALIZE]] ${requestConfig.op && !requestConfig.url ? '(LEGACY) ' : ''}${requestConfig.op || '<unknown operation>'} ${requestConfig.url || '<empty url>'}  ${requestConfig.method || '<empty method>'}`);
        }
      }
      // skip flush for legacy belongsTo
      if (requestConfig.op === 'findBelongsTo' && !requestConfig.url) {
        return;
      }
      this.notifications._flush();
    });
    return future;
  }

  /**
   * A hook which an app or addon may implement. Called when
   * the Store is attempting to create a Record Instance for
   * a resource.
   *
   * This hook can be used to select or instantiate any desired
   * mechanism of presenting cache data to the ui for access
   * mutation, and interaction.
   *
   * @method instantiateRecord (hook)
   * @param identifier
   * @param createRecordArgs
   * @param recordDataFor deprecated use this.cache
   * @param notificationManager deprecated use this.notifications
   * @return A record instance
   * @public
   */

  /**
   * A hook which an app or addon may implement. Called when
   * the Store is destroying a Record Instance. This hook should
   * be used to teardown any custom record instances instantiated
   * with `instantiateRecord`.
   *
   * @method teardownRecord (hook)
   * @public
   * @param record
   */

  /**
    Returns the schema for a particular resource type (modelName).
     When used with Model from @ember-data/model the return is the model class,
    but this is not guaranteed.
     If looking to query attribute or relationship information it is
    recommended to use `getSchemaDefinitionService` instead. This method
    should be considered legacy and exists primarily to continue to support
    Adapter/Serializer APIs which expect it's return value in their method
    signatures.
     The class of a model might be useful if you want to get a list of all the
    relationship names of the model, see
    [`relationshipNames`](/ember-data/release/classes/Model?anchor=relationshipNames)
    for example.
     @method modelFor
    @public
    @deprecated
    @param {string} type
    @return {ModelSchema}
    */

  modelFor(type) {
    // FIXME add deprecation and deprecation stripping
    // FIXME/TODO update RFC to remove this method
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyedStoreOnly(this, 'modelFor');
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You need to pass <type> to the store's modelFor method`);
      }
    })(typeof type === 'string' && type.length) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`No model was found for '${type}' and no schema handles the type`);
      }
    })(this.schema.hasResource({
      type
    })) : {};
    return getShimClass(this, type);
  }

  /**
    Create a new record in the current store. The properties passed
    to this method are set on the newly created record.
     To create a new instance of a `Post`:
     ```js
    store.createRecord('post', {
      title: 'Ember is awesome!'
    });
    ```
     To create a new instance of a `Post` that has a relationship with a `User` record:
     ```js
    let user = this.store.peekRecord('user', '1');
    store.createRecord('post', {
      title: 'Ember is awesome!',
      user: user
    });
    ```
     @method createRecord
    @public
    @param {String} type the name of the resource
    @param {Object} inputProperties a hash of properties to set on the
      newly created record.
    @return {Model} record
  */

  createRecord(type, inputProperties) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyingStore(this, 'createRecord');
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You need to pass a model name to the store's createRecord method`);
      }
    })(type) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${type}`);
      }
    })(typeof type === 'string') : {};

    // This is wrapped in a `run.join` so that in test environments users do not need to manually wrap
    //   calls to `createRecord`. The run loop usage here is because we batch the joining and updating
    //   of record-arrays via ember's run loop, not our own.
    //
    //   to remove this, we would need to move to a new `async` API.
    let record;
    this._join(() => {
      const normalizedModelName = normalizeModelName(type);
      const properties = {
        ...inputProperties
      };

      // If the passed properties do not include a primary key,
      // give the adapter an opportunity to generate one. Typically,
      // client-side ID generators will use something like uuid.js
      // to avoid conflicts.
      let id = null;
      if (properties.id === null || properties.id === undefined) {
        const adapter = this.adapterFor?.(normalizedModelName, true);
        if (adapter && adapter.generateIdForRecord) {
          id = properties.id = coerceId(adapter.generateIdForRecord(this, normalizedModelName, properties));
        } else {
          id = properties.id = null;
        }
      } else {
        id = properties.id = coerceId(properties.id);
      }
      const resource = {
        type: normalizedModelName,
        id
      };
      if (resource.id) {
        const identifier = this.identifierCache.peekRecordIdentifier(resource);
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          if (!test) {
            throw new Error(`The id ${String(properties.id)} has already been used with another '${normalizedModelName}' record.`);
          }
        })(!identifier) : {};
      }
      const identifier = this.identifierCache.createIdentifierForNewRecord(resource);
      const cache = this.cache;
      const createOptions = normalizeProperties(this, identifier, properties);
      const resultProps = cache.clientDidCreate(identifier, createOptions);
      record = this._instanceCache.getRecord(identifier, resultProps);
    });
    return record;
  }

  /**
    For symmetry, a record can be deleted via the store.
     Example
     ```javascript
    let post = store.createRecord('post', {
      title: 'Ember is awesome!'
    });
     store.deleteRecord(post);
    ```
     @method deleteRecord
    @public
    @param {unknown} record
  */
  deleteRecord(record) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyingStore(this, 'deleteRecord');
    }
    const identifier = peekRecordIdentifier(record);
    const cache = this.cache;
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`expected the record to be connected to a cache`);
      }
    })(identifier) : {};
    this._join(() => {
      cache.setIsDeleted(identifier, true);
      if (cache.isNew(identifier)) {
        this._instanceCache.unloadRecord(identifier);
      }
    });
  }

  /**
    For symmetry, a record can be unloaded via the store.
    This will cause the record to be destroyed and freed up for garbage collection.
     Example
     ```javascript
    const { content: { data: post } } = await store.request(findRecord({ type: 'post', id: '1' }));
    store.unloadRecord(post);
    ```
     @method unloadRecord
    @public
    @param {Model} record
  */
  unloadRecord(record) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyingStore(this, 'unloadRecord');
    }
    const identifier = peekRecordIdentifier(record);
    if (identifier) {
      this._instanceCache.unloadRecord(identifier);
    }
  }

  /**
    This method returns a record for a given identifier or type and id combination.
     The `findRecord` method will always resolve its promise with the same
    object for a given identifier or type and `id`.
     The `findRecord` method will always return a **promise** that will be
    resolved with the record.
     **Example 1**
     ```app/routes/post.js
    export default class PostRoute extends Route {
      model({ post_id }) {
        return this.store.findRecord('post', post_id);
      }
    }
    ```
     **Example 2**
     `findRecord` can be called with a single identifier argument instead of the combination
    of `type` (modelName) and `id` as separate arguments. You may recognize this combo as
    the typical pairing from [JSON:API](https://jsonapi.org/format/#document-resource-object-identification)
     ```app/routes/post.js
    export default class PostRoute extends Route {
      model({ post_id: id }) {
        return this.store.findRecord({ type: 'post', id });
      }
    }
    ```
     **Example 3**
     If you have previously received an lid via an Identifier for this record, and the record
    has already been assigned an id, you can find the record again using just the lid.
     ```app/routes/post.js
    store.findRecord({ lid });
    ```
     If the record is not yet available, the store will ask the adapter's `findRecord`
    method to retrieve and supply the necessary data. If the record is already present
    in the store, it depends on the reload behavior _when_ the returned promise
    resolves.
     ### Preloading
     You can optionally `preload` specific attributes and relationships that you know of
    by passing them via the passed `options`.
     For example, if your Ember route looks like `/posts/1/comments/2` and your API route
    for the comment also looks like `/posts/1/comments/2` if you want to fetch the comment
    without also fetching the post you can pass in the post to the `findRecord` call:
     ```app/routes/post-comments.js
    export default class PostRoute extends Route {
      model({ post_id, comment_id: id }) {
        return this.store.findRecord({ type: 'comment', id, { preload: { post: post_id }} });
      }
    }
    ```
     In your adapter you can then access this id without triggering a network request via the
    snapshot:
     ```app/adapters/application.js
    export default class Adapter {
       findRecord(store, schema, id, snapshot) {
        let type = schema.modelName;
         if (type === 'comment')
          let postId = snapshot.belongsTo('post', { id: true });
           return fetch(`./posts/${postId}/comments/${id}`)
            .then(response => response.json())
        }
      }
       static create() {
        return new this();
      }
    }
    ```
     This could also be achieved by supplying the post id to the adapter via the adapterOptions
    property on the options hash.
     ```app/routes/post-comments.js
    export default class PostRoute extends Route {
      model({ post_id, comment_id: id }) {
        return this.store.findRecord({ type: 'comment', id, { adapterOptions: { post: post_id }} });
      }
    }
    ```
     ```app/adapters/application.js
    export default class Adapter {
      findRecord(store, schema, id, snapshot) {
        let type = schema.modelName;
         if (type === 'comment')
          let postId = snapshot.adapterOptions.post;
           return fetch(`./posts/${postId}/comments/${id}`)
            .then(response => response.json())
        }
      }
       static create() {
        return new this();
      }
    }
    ```
     If you have access to the post model you can also pass the model itself to preload:
     ```javascript
    let post = await store.findRecord('post', '1');
    let comment = await store.findRecord('comment', '2', { post: myPostModel });
    ```
     ### Reloading
     The reload behavior is configured either via the passed `options` hash or
    the result of the adapter's `shouldReloadRecord`.
     If `{ reload: true }` is passed or `adapter.shouldReloadRecord` evaluates
    to `true`, then the returned promise resolves once the adapter returns
    data, regardless if the requested record is already in the store:
     ```js
    store.push({
      data: {
        id: 1,
        type: 'post',
        revision: 1
      }
    });
     // adapter#findRecord resolves with
    // [
    //   {
    //     id: 1,
    //     type: 'post',
    //     revision: 2
    //   }
    // ]
    store.findRecord('post', '1', { reload: true }).then(function(post) {
      post.revision; // 2
    });
    ```
     If no reload is indicated via the above mentioned ways, then the promise
    immediately resolves with the cached version in the store.
     ### Background Reloading
     Optionally, if `adapter.shouldBackgroundReloadRecord` evaluates to `true`,
    then a background reload is started, which updates the records' data, once
    it is available:
     ```js
    // app/adapters/post.js
    import ApplicationAdapter from "./application";
     export default class PostAdapter extends ApplicationAdapter {
      shouldReloadRecord(store, snapshot) {
        return false;
      },
       shouldBackgroundReloadRecord(store, snapshot) {
        return true;
      }
    });
     // ...
     store.push({
      data: {
        id: 1,
        type: 'post',
        revision: 1
      }
    });
     let blogPost = store.findRecord('post', '1').then(function(post) {
      post.revision; // 1
    });
     // later, once adapter#findRecord resolved with
    // [
    //   {
    //     id: 1,
    //     type: 'post',
    //     revision: 2
    //   }
    // ]
     blogPost.revision; // 2
    ```
     If you would like to force or prevent background reloading, you can set a
    boolean value for `backgroundReload` in the options object for
    `findRecord`.
     ```app/routes/post/edit.js
    export default class PostEditRoute extends Route {
      model(params) {
        return this.store.findRecord('post', params.post_id, { backgroundReload: false });
      }
    }
    ```
     If you pass an object on the `adapterOptions` property of the options
    argument it will be passed to your adapter via the snapshot
     ```app/routes/post/edit.js
    export default class PostEditRoute extends Route {
      model(params) {
        return this.store.findRecord('post', params.post_id, {
          adapterOptions: { subscribe: false }
        });
      }
    }
    ```
     ```app/adapters/post.js
    import MyCustomAdapter from './custom-adapter';
     export default class PostAdapter extends MyCustomAdapter {
      findRecord(store, type, id, snapshot) {
        if (snapshot.adapterOptions.subscribe) {
          // ...
        }
        // ...
      }
    }
    ```
     See [peekRecord](../methods/peekRecord?anchor=peekRecord) to get the cached version of a record.
     ### Retrieving Related Model Records
     If you use an adapter such as Ember's default
    [`JSONAPIAdapter`](/ember-data/release/classes/JSONAPIAdapter)
    that supports the [JSON API specification](http://jsonapi.org/) and if your server
    endpoint supports the use of an
    ['include' query parameter](http://jsonapi.org/format/#fetching-includes),
    you can use `findRecord()` or `findAll()` to automatically retrieve additional records related to
    the one you request by supplying an `include` parameter in the `options` object.
     For example, given a `post` model that has a `hasMany` relationship with a `comment`
    model, when we retrieve a specific post we can have the server also return that post's
    comments in the same request:
     ```app/routes/post.js
    export default class PostRoute extends Route {
      model(params) {
        return this.store.findRecord('post', params.post_id, { include: ['comments'] });
      }
    }
    ```
     ```app/adapters/application.js
    export default class Adapter {
      findRecord(store, schema, id, snapshot) {
        let type = schema.modelName;
         if (type === 'post')
          let includes = snapshot.adapterOptions.include;
           return fetch(`./posts/${postId}?include=${includes}`)
            .then(response => response.json())
        }
      }
       static create() {
        return new this();
      }
    }
    ```
     In this case, the post's comments would then be available in your template as
    `model.comments`.
     Multiple relationships can be requested using an `include` parameter consisting of a
    list of relationship names, while nested relationships can be specified
    using a dot-separated sequence of relationship names. So to request both the post's
    comments and the authors of those comments the request would look like this:
     ```app/routes/post.js
    export default class PostRoute extends Route {
      model(params) {
        return this.store.findRecord('post', params.post_id, { include: ['comments','comments.author'] });
      }
    }
    ```
     ### Retrieving Specific Fields by Type
     If your server endpoint supports the use of a ['fields' query parameter](https://jsonapi.org/format/#fetching-sparse-fieldsets),
    you can use pass those fields through to your server.  At this point in time, this requires a few manual steps on your part.
     1. Implement `buildQuery` in your adapter.
     ```app/adapters/application.js
    buildQuery(snapshot) {
      let query = super.buildQuery(...arguments);
       let { fields } = snapshot.adapterOptions;
       if (fields) {
        query.fields = fields;
      }
       return query;
    }
    ```
     2. Then pass through the applicable fields to your `findRecord` request.
     Given a `post` model with attributes body, title, publishDate and meta, you can retrieve a filtered list of attributes.
     ```app/routes/post.js
    export default class extends Route {
      model(params) {
        return this.store.findRecord('post', params.post_id, { adapterOptions: { fields: { post: 'body,title' } });
      }
    }
    ```
     Moreover, you can filter attributes on related models as well. If a `post` has a `belongsTo` relationship to a user,
    just include the relationship key and attributes.
     ```app/routes/post.js
    export default class extends Route {
      model(params) {
        return this.store.findRecord('post', params.post_id, { adapterOptions: { fields: { post: 'body,title', user: 'name,email' } });
      }
    }
    ```
     @since 1.13.0
    @method findRecord
    @public
    @param {String|object} type - either a string representing the name of the resource or a ResourceIdentifier object containing both the type (a string) and the id (a string) for the record or an lid (a string) of an existing record
    @param {(String|Integer|Object)} id - optional object with options for the request only if the first param is a ResourceIdentifier, else the string id of the record to be retrieved
    @param {Object} [options] - if the first param is a string this will be the optional options for the request. See examples for available options.
    @return {Promise} promise
  */

  findRecord(resource, id, options) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyingStore(this, 'findRecord');
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You need to pass a modelName or resource identifier as the first argument to the store's findRecord method`);
      }
    })(resource) : {};
    if (isMaybeIdentifier(resource)) {
      options = id;
    } else {
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${resource}`);
        }
      })(typeof resource === 'string') : {};
      const type = normalizeModelName(resource);
      const normalizedId = ensureStringId(id);
      resource = constructResource(type, normalizedId);
    }
    const identifier = this.identifierCache.getOrCreateRecordIdentifier(resource);
    options = options || {};
    if (options.preload) {
      // force reload if we preload to ensure we don't resolve the promise
      // until we are complete, else we will end up background-reloading
      // even for initial load.
      if (!this._instanceCache.recordIsLoaded(identifier)) {
        options.reload = true;
      }
      this._join(() => {
        preloadData(this, identifier, options.preload);
      });
    }
    const promise = this.request({
      op: 'findRecord',
      data: {
        record: identifier,
        options
      },
      cacheOptions: {
        [SkipCache]: true
      }
    });
    return promise.then(document => {
      return document.content;
    });
  }

  /**
    Get the reference for the specified record.
     Example
     ```javascript
    let userRef = store.getReference('user', '1');
     // check if the user is loaded
    let isLoaded = userRef.value() !== null;
     // get the record of the reference (null if not yet available)
    let user = userRef.value();
     // get the identifier of the reference
    if (userRef.remoteType() === 'id') {
    let id = userRef.id();
    }
     // load user (via store.find)
    userRef.load().then(...)
     // or trigger a reload
    userRef.reload().then(...)
     // provide data for reference
    userRef.push({ id: 1, username: '@user' }).then(function(user) {
      userRef.value() === user;
    });
    ```
     @method getReference
    @public
    @param {String|object} resource - modelName (string) or Identifier (object)
    @param {String|Integer} id
    @since 2.5.0
    @return {RecordReference}
  */
  // TODO @deprecate getReference (and references generally)
  getReference(resource, id) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyingStore(this, 'getReference');
    }
    let resourceIdentifier;
    if (arguments.length === 1 && isMaybeIdentifier(resource)) {
      resourceIdentifier = resource;
    } else {
      const type = normalizeModelName(resource);
      const normalizedId = ensureStringId(id);
      resourceIdentifier = constructResource(type, normalizedId);
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error('getReference expected to receive either a resource identifier or type and id as arguments');
      }
    })(isMaybeIdentifier(resourceIdentifier)) : {};
    const identifier = this.identifierCache.getOrCreateRecordIdentifier(resourceIdentifier);
    return this._instanceCache.getReference(identifier);
  }

  /**
    Get a record by a given type and ID without triggering a fetch.
     This method will synchronously return the record if it is available in the store,
    otherwise it will return `null`. A record is available if it has been fetched earlier, or
    pushed manually into the store.
     See [findRecord](../methods/findRecord?anchor=findRecord) if you would like to request this record from the backend.
     _Note: This is a synchronous method and does not return a promise._
     **Example 1**
     ```js
    let post = store.peekRecord('post', '1');
     post.id; // '1'
    ```
     `peekRecord` can be called with a single identifier argument instead of the combination
    of `type` (modelName) and `id` as separate arguments. You may recognize this combo as
    the typical pairing from [JSON:API](https://jsonapi.org/format/#document-resource-object-identification)
     **Example 2**
     ```js
    let post = store.peekRecord({ type: 'post', id });
    post.id; // '1'
    ```
     If you have previously received an lid from an Identifier for this record, you can lookup the record again using
    just the lid.
     **Example 3**
     ```js
    let post = store.peekRecord({ lid });
    post.id; // '1'
    ```
      @since 1.13.0
    @method peekRecord
    @public
    @param {String|object} modelName - either a string representing the modelName or a ResourceIdentifier object containing both the type (a string) and the id (a string) for the record or an lid (a string) of an existing record
    @param {String|Integer} id - optional only if the first param is a ResourceIdentifier, else the string id of the record to be retrieved.
    @return {Model|null} record
  */

  peekRecord(identifier, id) {
    if (arguments.length === 1 && isMaybeIdentifier(identifier)) {
      const stableIdentifier = this.identifierCache.peekRecordIdentifier(identifier);
      const isLoaded = stableIdentifier && this._instanceCache.recordIsLoaded(stableIdentifier);
      // TODO come up with a better mechanism for determining if we have data and could peek.
      // this is basically an "are we not empty" query.
      return isLoaded ? this._instanceCache.getRecord(stableIdentifier) : null;
    }
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyingStore(this, 'peekRecord');
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You need to pass a model name to the store's peekRecord method`);
      }
    })(identifier) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${String(identifier)}`);
      }
    })(typeof identifier === 'string') : {};
    const type = normalizeModelName(identifier);
    const normalizedId = ensureStringId(id);
    const resource = {
      type,
      id: normalizedId
    };
    const stableIdentifier = this.identifierCache.peekRecordIdentifier(resource);
    const isLoaded = stableIdentifier && this._instanceCache.recordIsLoaded(stableIdentifier);
    return isLoaded ? this._instanceCache.getRecord(stableIdentifier) : null;
  }

  /**
    This method delegates a query to the adapter. This is the one place where
    adapter-level semantics are exposed to the application.
     Each time this method is called a new request is made through the adapter.
     Exposing queries this way seems preferable to creating an abstract query
    language for all server-side queries, and then require all adapters to
    implement them.
     ---
     If you do something like this:
     ```javascript
    store.query('person', { page: 1 });
    ```
     The request made to the server will look something like this:
     ```
    GET "/api/v1/person?page=1"
    ```
     ---
     If you do something like this:
     ```javascript
    store.query('person', { ids: ['1', '2', '3'] });
    ```
     The request made to the server will look something like this:
     ```
    GET "/api/v1/person?ids%5B%5D=1&ids%5B%5D=2&ids%5B%5D=3"
    decoded: "/api/v1/person?ids[]=1&ids[]=2&ids[]=3"
    ```
     This method returns a promise, which is resolved with a
    [`Collection`](/ember-data/release/classes/Collection)
    once the server returns.
     @since 1.13.0
    @method query
    @public
    @param {String} type the name of the resource
    @param {object} query a query to be used by the adapter
    @param {Object} options optional, may include `adapterOptions` hash which will be passed to adapter.query
    @return {Promise} promise
  */

  query(type, query, options = {}) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyingStore(this, 'query');
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You need to pass a model name to the store's query method`);
      }
    })(type) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You need to pass a query hash to the store's query method`);
      }
    })(query) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${type}`);
      }
    })(typeof type === 'string') : {};
    const promise = this.request({
      op: 'query',
      data: {
        type: normalizeModelName(type),
        query,
        options: options
      },
      cacheOptions: {
        [SkipCache]: true
      }
    });
    return promise.then(document => document.content);
  }

  /**
    This method makes a request for one record, where the `id` is not known
    beforehand (if the `id` is known, use [`findRecord`](../methods/findRecord?anchor=findRecord)
    instead).
     This method can be used when it is certain that the server will return a
    single object for the primary data.
     Each time this method is called a new request is made through the adapter.
     Let's assume our API provides an endpoint for the currently logged in user
    via:
     ```
    // GET /api/current_user
    {
      user: {
        id: 1234,
        username: 'admin'
      }
    }
    ```
     Since the specific `id` of the `user` is not known beforehand, we can use
    `queryRecord` to get the user:
     ```javascript
    store.queryRecord('user', {}).then(function(user) {
      let username = user.username;
      // do thing
    });
    ```
     The request is made through the adapters' `queryRecord`:
     ```app/adapters/user.js
    import Adapter from '@ember-data/adapter';
    import $ from 'jquery';
     export default class UserAdapter extends Adapter {
      queryRecord(modelName, query) {
        return $.getJSON('/api/current_user');
      }
    }
    ```
     Note: the primary use case for `store.queryRecord` is when a single record
    is queried and the `id` is not known beforehand. In all other cases
    `store.query` and using the first item of the array is likely the preferred
    way:
     ```
    // GET /users?username=unique
    {
      data: [{
        id: 1234,
        type: 'user',
        attributes: {
          username: "unique"
        }
      }]
    }
    ```
     ```javascript
    store.query('user', { username: 'unique' }).then(function(users) {
      return users.firstObject;
    }).then(function(user) {
      let id = user.id;
    });
    ```
     This method returns a promise, which resolves with the found record.
     If the adapter returns no data for the primary data of the payload, then
    `queryRecord` resolves with `null`:
     ```
    // GET /users?username=unique
    {
      data: null
    }
    ```
     ```javascript
    store.queryRecord('user', { username: 'unique' }).then(function(user) {
       // user is null
    });
    ```
     @since 1.13.0
    @method queryRecord
    @public
    @param {string} type
    @param {object} query an opaque query to be used by the adapter
    @param {object} options optional, may include `adapterOptions` hash which will be passed to adapter.queryRecord
    @return {Promise} promise which resolves with the found record or `null`
  */

  queryRecord(type, query, options) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyingStore(this, 'queryRecord');
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You need to pass a model name to the store's queryRecord method`);
      }
    })(type) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You need to pass a query hash to the store's queryRecord method`);
      }
    })(query) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${type}`);
      }
    })(typeof type === 'string') : {};
    const promise = this.request({
      op: 'queryRecord',
      data: {
        type: normalizeModelName(type),
        query,
        options: options || {}
      },
      cacheOptions: {
        [SkipCache]: true
      }
    });
    return promise.then(document => document.content);
  }

  /**
    `findAll` asks the adapter's `findAll` method to find the records for the
    given type, and returns a promise which will resolve with all records of
    this type present in the store, even if the adapter only returns a subset
    of them.
     ```app/routes/authors.js
    export default class AuthorsRoute extends Route {
      model(params) {
        return this.store.findAll('author');
      }
    }
    ```
     _When_ the returned promise resolves depends on the reload behavior,
    configured via the passed `options` hash and the result of the adapter's
    `shouldReloadAll` method.
     ### Reloading
     If `{ reload: true }` is passed or `adapter.shouldReloadAll` evaluates to
    `true`, then the returned promise resolves once the adapter returns data,
    regardless if there are already records in the store:
     ```js
    store.push({
      data: {
        id: 'first',
        type: 'author'
      }
    });
     // adapter#findAll resolves with
    // [
    //   {
    //     id: 'second',
    //     type: 'author'
    //   }
    // ]
    store.findAll('author', { reload: true }).then(function(authors) {
      authors.getEach('id'); // ['first', 'second']
    });
    ```
     If no reload is indicated via the above mentioned ways, then the promise
    immediately resolves with all the records currently loaded in the store.
     ### Background Reloading
     Optionally, if `adapter.shouldBackgroundReloadAll` evaluates to `true`,
    then a background reload is started. Once this resolves, the array with
    which the promise resolves, is updated automatically so it contains all the
    records in the store:
     ```app/adapters/application.js
    import Adapter from '@ember-data/adapter';
     export default class ApplicationAdapter extends Adapter {
      shouldReloadAll(store, snapshotsArray) {
        return false;
      },
       shouldBackgroundReloadAll(store, snapshotsArray) {
        return true;
      }
    });
     // ...
     store.push({
      data: {
        id: 'first',
        type: 'author'
      }
    });
     let allAuthors;
    store.findAll('author').then(function(authors) {
      authors.getEach('id'); // ['first']
       allAuthors = authors;
    });
     // later, once adapter#findAll resolved with
    // [
    //   {
    //     id: 'second',
    //     type: 'author'
    //   }
    // ]
     allAuthors.getEach('id'); // ['first', 'second']
    ```
     If you would like to force or prevent background reloading, you can set a
    boolean value for `backgroundReload` in the options object for
    `findAll`.
     ```app/routes/post/edit.js
    export default class PostEditRoute extends Route {
      model() {
        return this.store.findAll('post', { backgroundReload: false });
      }
    }
    ```
     If you pass an object on the `adapterOptions` property of the options
    argument it will be passed to you adapter via the `snapshotRecordArray`
     ```app/routes/posts.js
    export default class PostsRoute extends Route {
      model(params) {
        return this.store.findAll('post', {
          adapterOptions: { subscribe: false }
        });
      }
    }
    ```
     ```app/adapters/post.js
    import MyCustomAdapter from './custom-adapter';
     export default class UserAdapter extends MyCustomAdapter {
      findAll(store, type, sinceToken, snapshotRecordArray) {
        if (snapshotRecordArray.adapterOptions.subscribe) {
          // ...
        }
        // ...
      }
    }
    ```
     See [peekAll](../methods/peekAll?anchor=peekAll) to get an array of current records in the
    store, without waiting until a reload is finished.
     ### Retrieving Related Model Records
     If you use an adapter such as Ember's default
    [`JSONAPIAdapter`](/ember-data/release/classes/JSONAPIAdapter)
    that supports the [JSON API specification](http://jsonapi.org/) and if your server
    endpoint supports the use of an
    ['include' query parameter](http://jsonapi.org/format/#fetching-includes),
    you can use `findAll()` to automatically retrieve additional records related to
    those requested by supplying an `include` parameter in the `options` object.
     For example, given a `post` model that has a `hasMany` relationship with a `comment`
    model, when we retrieve all of the post records we can have the server also return
    all of the posts' comments in the same request:
     ```app/routes/posts.js
    export default class PostsRoute extends Route {
      model() {
        return this.store.findAll('post', { include: ['comments'] });
      }
    }
    ```
    Multiple relationships can be requested using an `include` parameter consisting of a
    list or relationship names, while nested relationships can be specified
    using a dot-separated sequence of relationship names. So to request both the posts'
    comments and the authors of those comments the request would look like this:
     ```app/routes/posts.js
    export default class PostsRoute extends Route {
      model() {
        return this.store.findAll('post', { include: ['comments','comments.author'] });
      }
    }
    ```
     See [query](../methods/query?anchor=query) to only get a subset of records from the server.
     @since 1.13.0
    @method findAll
    @public
    @param {string} type the name of the resource
    @param {object} options
    @return {Promise} promise
  */

  findAll(type, options = {}) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyingStore(this, 'findAll');
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You need to pass a model name to the store's findAll method`);
      }
    })(type) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${type}`);
      }
    })(typeof type === 'string') : {};
    const promise = this.request({
      op: 'findAll',
      data: {
        type: normalizeModelName(type),
        options: options || {}
      },
      cacheOptions: {
        [SkipCache]: true
      }
    });
    return promise.then(document => document.content);
  }

  /**
    This method returns a filtered array that contains all of the
    known records for a given type in the store.
     Note that because it's just a filter, the result will contain any
    locally created records of the type, however, it will not make a
    request to the backend to retrieve additional records. If you
    would like to request all the records from the backend please use
    [store.findAll](../methods/findAll?anchor=findAll).
     Also note that multiple calls to `peekAll` for a given type will always
    return the same `RecordArray`.
     Example
     ```javascript
    let localPosts = store.peekAll('post');
    ```
     @since 1.13.0
    @method peekAll
    @public
    @param {string} type the name of the resource
    @return {RecordArray}
  */

  peekAll(type) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyingStore(this, 'peekAll');
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You need to pass a model name to the store's peekAll method`);
      }
    })(type) : {};
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${type}`);
      }
    })(typeof type === 'string') : {};
    return this.recordArrayManager.liveArrayFor(normalizeModelName(type));
  }

  /**
    This method unloads all records in the store.
    It schedules unloading to happen during the next run loop.
     Optionally you can pass a type which unload all records for a given type.
     ```javascript
    store.unloadAll();
    store.unloadAll('post');
    ```
     @method unloadAll
    @param {string} type the name of the resource
    @public
  */

  unloadAll(type) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyedStoreOnly(this, 'unloadAll');
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Passing classes to store methods has been removed. Please pass a dasherized string instead of ${String(type)}`);
      }
    })(!type || typeof type === 'string') : {};
    this._join(() => {
      if (type === undefined) {
        // destroy the graph before unloadAll
        // since then we avoid churning relationships
        // during unload
        this._graph?.identifiers.clear();
        this.recordArrayManager.clear();
        this._instanceCache.clear();
      } else {
        this._instanceCache.clear(normalizeModelName(type));
      }
    });
  }

  /**
    Push some data for a given type into the store.
     This method expects normalized [JSON API](http://jsonapi.org/) document. This means you have to follow [JSON API specification](http://jsonapi.org/format/) with few minor adjustments:
    - record's `type` should always be in singular, dasherized form
    - members (properties) should be camelCased
     [Your primary data should be wrapped inside `data` property](http://jsonapi.org/format/#document-top-level):
     ```js
    store.push({
      data: {
        // primary data for single record of type `Person`
        id: '1',
        type: 'person',
        attributes: {
          firstName: 'Daniel',
          lastName: 'Kmak'
        }
      }
    });
    ```
     [Demo.](http://ember-twiddle.com/fb99f18cd3b4d3e2a4c7)
     `data` property can also hold an array (of records):
     ```js
    store.push({
      data: [
        // an array of records
        {
          id: '1',
          type: 'person',
          attributes: {
            firstName: 'Daniel',
            lastName: 'Kmak'
          }
        },
        {
          id: '2',
          type: 'person',
          attributes: {
            firstName: 'Tom',
            lastName: 'Dale'
          }
        }
      ]
    });
    ```
     [Demo.](http://ember-twiddle.com/69cdbeaa3702159dc355)
     There are some typical properties for `JSONAPI` payload:
    * `id` - mandatory, unique record's key
    * `type` - mandatory string which matches `model`'s dasherized name in singular form
    * `attributes` - object which holds data for record attributes - `attr`'s declared in model
    * `relationships` - object which must contain any of the following properties under each relationships' respective key (example path is `relationships.achievements.data`):
      - [`links`](http://jsonapi.org/format/#document-links)
      - [`data`](http://jsonapi.org/format/#document-resource-object-linkage) - place for primary data
      - [`meta`](http://jsonapi.org/format/#document-meta) - object which contains meta-information about relationship
     For this model:
     ```app/models/person.js
    import Model, { attr, hasMany } from '@ember-data/model';
     export default class PersonRoute extends Route {
      @attr('string') firstName;
      @attr('string') lastName;
       @hasMany('person') children;
    }
    ```
     To represent the children as IDs:
     ```js
    {
      data: {
        id: '1',
        type: 'person',
        attributes: {
          firstName: 'Tom',
          lastName: 'Dale'
        },
        relationships: {
          children: {
            data: [
              {
                id: '2',
                type: 'person'
              },
              {
                id: '3',
                type: 'person'
              },
              {
                id: '4',
                type: 'person'
              }
            ]
          }
        }
      }
    }
    ```
     [Demo.](http://ember-twiddle.com/343e1735e034091f5bde)
     To represent the children relationship as a URL:
     ```js
    {
      data: {
        id: '1',
        type: 'person',
        attributes: {
          firstName: 'Tom',
          lastName: 'Dale'
        },
        relationships: {
          children: {
            links: {
              related: '/people/1/children'
            }
          }
        }
      }
    }
    ```
     If you're streaming data or implementing an adapter, make sure
    that you have converted the incoming data into this form. The
    store's [normalize](../methods/normalize?anchor=normalize) method is a convenience
    helper for converting a json payload into the form Ember Data
    expects.
     ```js
    store.push(store.normalize('person', data));
    ```
     This method can be used both to push in brand new
    records, as well as to update existing records.
     @method push
    @public
    @param {Object} data
    @return the record(s) that was created or
      updated.
  */

  push(data) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyingStore(this, 'push');
    }
    const pushed = this._push(data, false);
    if (Array.isArray(pushed)) {
      return pushed.map(identifier => this._instanceCache.getRecord(identifier));
    }
    if (pushed === null) {
      return null;
    }
    return this._instanceCache.getRecord(pushed);
  }

  /**
    Push some data in the form of a json-api document into the store,
    without creating materialized records.
     @method _push
    @private
    @param {Object} jsonApiDoc
    @return {StableRecordIdentifier|Array<StableRecordIdentifier>|null} identifiers for the primary records that had data loaded
  */
  _push(jsonApiDoc, asyncFlush) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyingStore(this, '_push');
    }
    if (macroCondition(getGlobalConfig().WarpDrive.activeLogging.LOG_PAYLOADS)) {
      if (getGlobalConfig().WarpDrive.debug.LOG_PAYLOADS || globalThis.getWarpDriveRuntimeConfig().debug.LOG_PAYLOADS) {
        try {
          const data = JSON.parse(JSON.stringify(jsonApiDoc));
          // eslint-disable-next-line no-console
          console.log('EmberData | Payload - push', data);
        } catch {
          // eslint-disable-next-line no-console
          console.log('EmberData | Payload - push', jsonApiDoc);
        }
      }
    }
    if (asyncFlush) {
      this._enableAsyncFlush = true;
    }
    let ret;
    this._join(() => {
      ret = this.cache.put({
        content: jsonApiDoc
      });
    });
    this._enableAsyncFlush = null;
    return 'data' in ret ? ret.data : null;
  }

  /**
   * Trigger a save for a Record.
   *
   * Returns a promise resolving with the same record when the save is complete.
   *
   * @method saveRecord
   * @public
   * @param {unknown} record
   * @param options
   * @return {Promise<record>}
   */
  saveRecord(record, options = {}) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      assertDestroyingStore(this, 'saveRecord');
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Unable to initiate save for a record in a disconnected state`);
      }
    })(storeFor(record)) : {};
    const identifier = recordIdentifierFor(record);
    const cache = this.cache;
    if (!identifier) {
      // this commonly means we're disconnected
      // but just in case we reject here to prevent bad things.
      return Promise.reject(new Error(`Record Is Disconnected`));
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Cannot initiate a save request for an unloaded record: ${identifier.lid}`);
      }
    })(this._instanceCache.recordIsLoaded(identifier)) : {};
    if (resourceIsFullyDeleted(this._instanceCache, identifier)) {
      return Promise.resolve(record);
    }
    if (!options) {
      options = {};
    }
    let operation = 'updateRecord';
    if (cache.isNew(identifier)) {
      operation = 'createRecord';
    } else if (cache.isDeleted(identifier)) {
      operation = 'deleteRecord';
    }
    const request = {
      op: operation,
      data: {
        options,
        record: identifier
      },
      records: [identifier],
      cacheOptions: {
        [SkipCache]: true
      }
    };
    return this.request(request).then(document => document.content);
  }

  /**
   * Instantiation hook allowing applications or addons to configure the store
   * to utilize a custom Cache implementation.
   *
   * This hook should not be called directly by consuming applications or libraries.
   * Use `Store.cache` to access the Cache instance.
   *
   * @method createCache (hook)
   * @public
   * @param storeWrapper
   * @return {Cache}
   */

  /**
   * Returns the cache instance associated to this Store, instantiates the Cache
   * if necessary via `Store.createCache`
   *
   * @property {Cache} cache
   * @public
   */
  get cache() {
    let {
      cache
    } = this._instanceCache;
    if (!cache) {
      cache = this._instanceCache.cache = this.createCache(this._instanceCache._storeWrapper);
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        cache = new CacheManager(cache);
      }
    }
    return cache;
  }
  destroy() {
    if (this.isDestroyed) {
      // @ember/test-helpers will call destroy multiple times
      return;
    }
    this.isDestroying = true;
    this._graph?.destroy();
    this._graph = undefined;
    this.notifications.destroy();
    this.recordArrayManager.destroy();
    this.identifierCache.destroy();
    this.unloadAll();
    this.isDestroyed = true;
  }
  static create(args) {
    return new this(args);
  }
}
if (macroCondition(getGlobalConfig().WarpDrive.deprecations.ENABLE_LEGACY_SCHEMA_SERVICE)) {
  Store.prototype.getSchemaDefinitionService = function () {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You must registerSchemaDefinitionService with the store to use custom model classes`);
      }
    })(this._schema) : {};
    deprecate(`Use \`store.schema\` instead of \`store.getSchemaDefinitionService()\``, false, {
      id: 'ember-data:schema-service-updates',
      until: '6.0',
      for: 'ember-data',
      since: {
        available: '4.13',
        enabled: '5.4'
      }
    });
    return this._schema;
  };
  Store.prototype.registerSchemaDefinitionService = function (schema) {
    deprecate(`Use \`store.createSchemaService\` instead of \`store.registerSchemaDefinitionService()\``, false, {
      id: 'ember-data:schema-service-updates',
      until: '6.0',
      for: 'ember-data',
      since: {
        available: '4.13',
        enabled: '5.4'
      }
    });
    this._schema = schema;
  };
  Store.prototype.registerSchema = function (schema) {
    deprecate(`Use \`store.createSchemaService\` instead of \`store.registerSchema()\``, false, {
      id: 'ember-data:schema-service-updates',
      until: '6.0',
      for: 'ember-data',
      since: {
        available: '4.13',
        enabled: '5.4'
      }
    });
    this._schema = schema;
  };
}
let assertDestroyingStore;
let assertDestroyedStoreOnly;
if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  assertDestroyingStore = function assertDestroyingStore(store, method) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Attempted to call store.${method}(), but the store instance has already been destroyed.`);
      }
    })(!(store.isDestroying || store.isDestroyed)) : {};
  };
  // eslint-disable-next-line @typescript-eslint/no-shadow
  assertDestroyedStoreOnly = function assertDestroyedStoreOnly(store, method) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Attempted to call store.${method}(), but the store instance has already been destroyed.`);
      }
    })(!store.isDestroyed) : {};
  };
}
function isMaybeIdentifier(maybeIdentifier) {
  return Boolean(maybeIdentifier !== null && typeof maybeIdentifier === 'object' && ('id' in maybeIdentifier && 'type' in maybeIdentifier && maybeIdentifier.id && maybeIdentifier.type || maybeIdentifier.lid));
}
function normalizeProperties(store, identifier, properties) {
  // assert here
  if (properties !== undefined) {
    if ('id' in properties) {
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`expected id to be a string or null`);
        }
      })(properties.id !== undefined) : {};
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You passed '${typeof properties}' as properties for record creation instead of an object.`);
      }
    })(typeof properties === 'object' && properties !== null) : {};
    const {
      type
    } = identifier;

    // convert relationship Records to RecordDatas before passing to RecordData
    const defs = store.schema.fields({
      type
    });
    if (defs.size) {
      const keys = Object.keys(properties);
      for (let i = 0; i < keys.length; i++) {
        const prop = keys[i];
        const field = defs.get(prop);
        if (!field) continue;
        if (field.kind === 'hasMany') {
          if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
            assertRecordsPassedToHasMany(properties[prop]);
          }
          properties[prop] = extractIdentifiersFromRecords$1(properties[prop]);
        } else if (field.kind === 'belongsTo') {
          properties[prop] = extractIdentifierFromRecord$1(properties[prop]);
        }
      }
    }
  }
  return properties;
}
function assertRecordsPassedToHasMany(records) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You must pass an array of records to set a hasMany relationship`);
    }
  })(Array.isArray(records)) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`All elements of a hasMany relationship must be instances of Model, you passed ${records.map(r => `${typeof r}`).join(', ')}`);
    }
  })(function () {
    return records.every(record => {
      try {
        recordIdentifierFor(record);
        return true;
      } catch {
        return false;
      }
    });
  }()) : {};
}
function extractIdentifiersFromRecords$1(records) {
  return records.map(record => extractIdentifierFromRecord$1(record));
}
function extractIdentifierFromRecord$1(recordOrPromiseRecord) {
  if (!recordOrPromiseRecord) {
    return null;
  }
  const extract = recordIdentifierFor;
  return extract(recordOrPromiseRecord);
}

/**
 * @module @ember-data/store
 */
function urlFromLink(link) {
  if (typeof link === 'string') return link;
  return link.href;
}

/**
 * A Document is a class that wraps the response content from a request to the API
 * returned by `Cache.put` or `Cache.peek`, converting resource-identifiers into
 * record instances.
 *
 * It is not directly instantiated by the user, and its properties should not
 * be directly modified. Whether individual properties are mutable or not is
 * determined by the record instance itself.
 *
 * @public
 * @class Document
 */
class Document {
  /**
   * The links object for this document, if any
   *
   * e.g.
   *
   * ```
   * {
   *   self: '/articles?page[number]=3',
   * }
   * ```
   *
   * @property links
   * @type {object|undefined} - a links object
   * @public
   */

  /**
   * The primary data for this document, if any.
   *
   * If this document has no primary data (e.g. because it is an error document)
   * this property will be `undefined`.
   *
   * For collections this will be an array of record instances,
   * for single resource requests it will be a single record instance or null.
   *
   * @property data
   * @public
   * @type {object|Array<object>|null|undefined} - a data object
   */

  /**
   * The errors returned by the API for this request, if any
   *
   * @property errors
   * @public
   * @type {object|undefined} - an errors object
   */

  /**
   * The meta object for this document, if any
   *
   * @property meta
   * @public
   * @type {object|undefined} - a meta object
   */

  /**
   * The identifier associated with this document, if any
   *
   * @property identifier
   * @public
   * @type {StableDocumentIdentifier|null}
   */

  #store;
  constructor(store, identifier) {
    this.#store = store;
    this.identifier = identifier;
  }
  async #request(link, options) {
    const href = this.links?.[link];
    if (!href) {
      return null;
    }
    options.method = options.method || 'GET';
    Object.assign(options, {
      url: urlFromLink(href)
    });
    const response = await this.#store.request(options);
    return response.content;
  }

  /**
   * Fetches the related link for this document, returning a promise that resolves
   * with the document when the request completes. If no related link is present,
   * will fallback to the self link if present
   *
   * @method fetch
   * @public
   * @param {object} options
   * @return Promise<Document>
   */
  fetch(options = {}) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`No self or related link`);
      }
    })(this.links?.related || this.links?.self) : {};
    options.cacheOptions = options.cacheOptions || {};
    options.cacheOptions.key = this.identifier?.lid;
    return this.#request(this.links.related ? 'related' : 'self', options);
  }

  /**
   * Fetches the next link for this document, returning a promise that resolves
   * with the new document when the request completes, or null  if there is no
   * next link.
   *
   * @method next
   * @public
   * @param {object} options
   * @return Promise<Document | null>
   */
  next(options = {}) {
    return this.#request('next', options);
  }

  /**
   * Fetches the prev link for this document, returning a promise that resolves
   * with the new document when the request completes, or null if there is no
   * prev link.
   *
   * @method prev
   * @public
   * @param {object} options
   * @return Promise<Document | null>
   */
  prev(options = {}) {
    return this.#request('prev', options);
  }

  /**
   * Fetches the first link for this document, returning a promise that resolves
   * with the new document when the request completes, or null if there is no
   * first link.
   *
   * @method first
   * @public
   * @param {object} options
   * @return Promise<Document | null>
   */
  first(options = {}) {
    return this.#request('first', options);
  }

  /**
   * Fetches the last link for this document, returning a promise that resolves
   * with the new document when the request completes, or null if there is no
   * last link.
   *
   * @method last
   * @public
   * @param {object} options
   * @return Promise<Document | null>
   */
  last(options = {}) {
    return this.#request('last', options);
  }

  /**
   * Implemented for `JSON.stringify` support.
   *
   * Returns the JSON representation of the document wrapper.
   *
   * This is a shallow serialization, it does not deeply serialize
   * the document's contents, leaving that to the individual record
   * instances to determine how to do, if at all.
   *
   * @method toJSON
   * @public
   * @return
   */
  toJSON() {
    const data = {};
    data.identifier = this.identifier;
    if (this.data !== undefined) {
      data.data = this.data;
    }
    if (this.links !== undefined) {
      data.links = this.links;
    }
    if (this.errors !== undefined) {
      data.errors = this.errors;
    }
    if (this.meta !== undefined) {
      data.meta = this.meta;
    }
    return data;
  }
}
defineSignal(Document.prototype, 'data');
defineSignal(Document.prototype, 'links');
defineSignal(Document.prototype, 'errors');
defineSignal(Document.prototype, 'meta');
const MUTATION_OPS = new Set(['createRecord', 'updateRecord', 'deleteRecord']);
function calcShouldFetch(store, request, hasCachedValue, identifier) {
  const {
    cacheOptions
  } = request;
  return request.op && MUTATION_OPS.has(request.op) || cacheOptions?.reload || !hasCachedValue || (store.lifetimes && identifier ? store.lifetimes.isHardExpired(identifier, store) : false);
}
function calcShouldBackgroundFetch(store, request, willFetch, identifier) {
  const {
    cacheOptions
  } = request;
  return cacheOptions?.backgroundReload || (store.lifetimes && identifier ? store.lifetimes.isSoftExpired(identifier, store) : false);
}
function isMutation(request) {
  return Boolean(request.op && MUTATION_OPS.has(request.op));
}
function copyDocumentProperties(target, source) {
  if ('links' in source) {
    target.links = source.links;
  }
  if ('meta' in source) {
    target.meta = source.meta;
  }
  if ('errors' in source) {
    target.errors = source.errors;
  }
}
function isCacheAffecting(document) {
  if (!isMutation(document.request)) {
    return true;
  }
  // a mutation combined with a 204 has no cache impact when no known records were involved
  // a createRecord with a 201 with an empty response and no known records should similarly
  // have no cache impact

  if (document.request.op === 'createRecord' && document.response?.status === 201) {
    return document.content ? Object.keys(document.content).length > 0 : false;
  }
  return document.response?.status !== 204;
}
function isAggregateError(error) {
  return error instanceof AggregateError || error.name === 'AggregateError' && Array.isArray(error.errors);
}
// TODO @runspired, consider if we should deep freeze errors (potentially only in debug) vs cloning them
function cloneError(error) {
  const isAggregate = isAggregateError(error);
  const cloned = isAggregate ? new AggregateError(structuredClone(error.errors), error.message) : new Error(error.message);
  cloned.stack = error.stack;
  cloned.error = error.error;

  // copy over enumerable properties
  Object.assign(cloned, error);
  return cloned;
}
function isErrorDocument(document) {
  return 'errors' in document;
}
function getPriority(identifier, deduped, priority) {
  if (identifier) {
    const existing = deduped.get(identifier);
    if (existing) {
      return existing.priority;
    }
  }
  return priority;
}

/**
 * @module @ember-data/store
 */

/**
 * A CacheHandler that adds support for using an EmberData Cache with a RequestManager.
 *
 * This handler will only run when a request has supplied a `store` instance. Requests
 * issued by the store via `store.request()` will automatically have the `store` instance
 * attached to the request.
 *
 * ```ts
 * requestManager.request({
 *   store: store,
 *   url: '/api/posts',
 *   method: 'GET'
 * });
 * ```
 *
 * When this handler elects to handle a request, it will return the raw `StructuredDocument`
 * unless the request has `[EnableHydration]` set to `true`. In this case, the handler will
 * return a `Document` instance that will automatically update the UI when the cache is updated
 * in the future and will hydrate any identifiers in the StructuredDocument into Record instances.
 *
 * When issuing a request via the store, [EnableHydration] is automatically set to `true`. This
 * means that if desired you can issue requests that utilize the cache without needing to also
 * utilize Record instances if desired.
 *
 * Said differently, you could elect to issue all requests via a RequestManager, without ever using
 * the store directly, by setting [EnableHydration] to `true` and providing a store instance. Not
 * necessarily the most useful thing, but the decoupled nature of the RequestManager and incremental-feature
 * approach of EmberData allows for this flexibility.
 *
 * ```ts
 * import { EnableHydration } from '@warp-drive/core-types/request';
 *
 * requestManager.request({
 *   store: store,
 *   url: '/api/posts',
 *   method: 'GET',
 *   [EnableHydration]: true
 * });
 *
 * @typedoc
 */
const CacheHandler = {
  request(context, next) {
    // if we have no cache or no cache-key skip cache handling
    if (!context.request.store || context.request.cacheOptions?.[SkipCache]) {
      return next(context.request);
    }
    const {
      store
    } = context.request;
    const identifier = store.identifierCache.getOrCreateDocumentIdentifier(context.request);
    if (identifier) {
      context.setIdentifier(identifier);
    }

    // used to dedupe existing requests that match
    const DEDUPE = store.requestManager._deduped;
    const activeRequest = identifier && DEDUPE.get(identifier);
    const peeked = identifier ? store.cache.peekRequest(identifier) : null;

    // determine if we should skip cache
    if (calcShouldFetch(store, context.request, !!peeked, identifier)) {
      if (activeRequest) {
        activeRequest.priority = {
          blocking: true
        };
        return activeRequest.promise;
      }
      let promise = fetchContentAndHydrate(next, context, identifier, {
        blocking: true
      });
      if (identifier) {
        promise = promise.finally(() => {
          DEDUPE.delete(identifier);
          store.notifications.notify(identifier, 'state');
        });
        DEDUPE.set(identifier, {
          priority: {
            blocking: true
          },
          promise
        });
        store.notifications.notify(identifier, 'state');
      }
      return promise;
    }

    // if we have not skipped cache, determine if we should update behind the scenes
    if (calcShouldBackgroundFetch(store, context.request, false, identifier)) {
      let promise = activeRequest?.promise || fetchContentAndHydrate(next, context, identifier, {
        blocking: false
      });
      if (identifier && !activeRequest) {
        promise = promise.finally(() => {
          DEDUPE.delete(identifier);
          store.notifications.notify(identifier, 'state');
        });
        DEDUPE.set(identifier, {
          priority: {
            blocking: false
          },
          promise
        });
        store.notifications.notify(identifier, 'state');
      }
      store.requestManager._pending.set(context.id, promise);
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected a peeked request to be present`);
      }
    })(peeked) : {};
    const shouldHydrate = context.request[EnableHydration] || false;
    context.setResponse(peeked.response);
    if ('error' in peeked) {
      const content = shouldHydrate ? maybeUpdateErrorUiObjects(store, {
        shouldHydrate,
        identifier
      }, peeked.content, true) : peeked.content;
      const newError = cloneError(peeked);
      newError.content = content;
      throw newError;
    }
    const result = shouldHydrate ? maybeUpdateUiObjects(store, context.request, {
      shouldHydrate,
      identifier
    }, peeked.content, true) : peeked.content;
    return result;
  }
};
function maybeUpdateUiObjects(store, request, options, document, isFromCache) {
  const {
    identifier
  } = options;
  if (!document) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`The CacheHandler expected response content but none was found`);
      }
    })(!options.shouldHydrate) : {};
    return document;
  }
  if (Array.isArray(document.data)) {
    const {
      recordArrayManager
    } = store;
    if (!identifier) {
      if (!options.shouldHydrate) {
        return document;
      }
      const data = recordArrayManager.createArray({
        type: request.url,
        identifiers: document.data,
        doc: document,
        query: request
      });
      const doc = new Document(store, null);
      doc.data = data;
      doc.meta = document.meta;
      doc.links = document.links;
      return doc;
    }
    let managed = recordArrayManager._keyedArrays.get(identifier.lid);
    if (!managed) {
      managed = recordArrayManager.createArray({
        type: identifier.lid,
        identifiers: document.data,
        doc: document
      });
      recordArrayManager._keyedArrays.set(identifier.lid, managed);
      const doc = new Document(store, identifier);
      doc.data = managed;
      doc.meta = document.meta;
      doc.links = document.links;
      store._documentCache.set(identifier, doc);
      return options.shouldHydrate ? doc : document;
    } else {
      const doc = store._documentCache.get(identifier);
      if (!isFromCache) {
        recordArrayManager.populateManagedArray(managed, document.data, document);
        doc.data = managed;
        doc.meta = document.meta;
        doc.links = document.links;
      }
      return options.shouldHydrate ? doc : document;
    }
  } else {
    if (!identifier && !options.shouldHydrate) {
      return document;
    }
    const data = document.data ? store.peekRecord(document.data) : null;
    let doc;
    if (identifier) {
      doc = store._documentCache.get(identifier);
    }
    if (!doc) {
      doc = new Document(store, identifier);
      doc.data = data;
      copyDocumentProperties(doc, document);
      if (identifier) {
        store._documentCache.set(identifier, doc);
      }
    } else if (!isFromCache) {
      doc.data = data;
      copyDocumentProperties(doc, document);
    }
    return options.shouldHydrate ? doc : document;
  }
}
function maybeUpdateErrorUiObjects(store, options, document, isFromCache) {
  const {
    identifier
  } = options;

  // TODO investigate why ResourceErrorDocument is insufficient for expressing all error types
  if (!isErrorDocument(document) || !identifier && !options.shouldHydrate) {
    return document;
  }
  let doc;
  if (identifier) {
    doc = store._documentCache.get(identifier);
  }
  if (!doc) {
    doc = new Document(store, identifier);
    copyDocumentProperties(doc, document);
    if (identifier) {
      store._documentCache.set(identifier, doc);
    }
  } else if (!isFromCache) {
    doc.data = undefined;
    copyDocumentProperties(doc, document);
  }
  return options.shouldHydrate ? doc : document;
}
function updateCacheForSuccess(store, request, options, document) {
  let response = null;
  if (isMutation(request)) {
    const record = request.data?.record || request.records?.[0];
    if (record) {
      response = store.cache.didCommit(record, document);

      // a mutation combined with a 204 has no cache impact when no known records were involved
      // a createRecord with a 201 with an empty response and no known records should similarly
      // have no cache impact
    } else if (isCacheAffecting(document)) {
      response = store.cache.put(document);
    }
  } else {
    response = store.cache.put(document);
  }
  return maybeUpdateUiObjects(store, request, options, response, false);
}
function handleFetchSuccess(store, context, options, document) {
  const {
    request
  } = context;
  store.requestManager._pending.delete(context.id);
  store._enableAsyncFlush = true;
  let response;
  store._join(() => {
    response = updateCacheForSuccess(store, request, options, document);
  });
  store._enableAsyncFlush = null;
  if (store.lifetimes?.didRequest) {
    store.lifetimes.didRequest(context.request, document.response, options.identifier, store);
  }
  const finalPriority = getPriority(options.identifier, store.requestManager._deduped, options.priority);
  if (finalPriority.blocking) {
    return response;
  } else {
    store.notifications._flush();
  }
}
function updateCacheForError(store, context, options, error) {
  let response;
  if (isMutation(context.request)) {
    // TODO similar to didCommit we should spec this to be similar to cache.put for handling full response
    // currently we let the response remain undefiend.
    const errors = error && error.content && typeof error.content === 'object' && 'errors' in error.content && Array.isArray(error.content.errors) ? error.content.errors : undefined;
    const record = context.request.data?.record || context.request.records?.[0];
    store.cache.commitWasRejected(record, errors);
  } else {
    response = store.cache.put(error);
    return maybeUpdateErrorUiObjects(store, options, response, false);
  }
}
function handleFetchError(store, context, options, error) {
  store.requestManager._pending.delete(context.id);
  if (context.request.signal?.aborted) {
    throw error;
  }
  store._enableAsyncFlush = true;
  let response;
  store._join(() => {
    response = updateCacheForError(store, context, options, error);
  });
  store._enableAsyncFlush = null;
  if (options.identifier && store.lifetimes?.didRequest) {
    store.lifetimes.didRequest(context.request, error.response, options.identifier, store);
  }
  if (isMutation(context.request)) {
    throw error;
  }
  const finalPriority = getPriority(options.identifier, store.requestManager._deduped, options.priority);
  if (finalPriority.blocking) {
    const newError = cloneError(error);
    newError.content = response;
    throw newError;
  } else {
    store.notifications._flush();
  }
}
function fetchContentAndHydrate(next, context, identifier, priority) {
  const {
    store
  } = context.request;
  const shouldHydrate = context.request[EnableHydration] || false;
  const options = {
    shouldHydrate,
    identifier,
    priority
  };
  let isMut = false;
  if (isMutation(context.request)) {
    isMut = true;
    // TODO should we handle multiple records in request.records by iteratively calling willCommit for each
    const record = context.request.data?.record || context.request.records?.[0];
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected to receive a list of records included in the ${context.request.op} request`);
      }
    })(record || !shouldHydrate) : {};
    if (record) {
      store.cache.willCommit(record, context);
    }
  }
  if (store.lifetimes?.willRequest) {
    store.lifetimes.willRequest(context.request, identifier, store);
  }
  const promise = next(context.request).then(document => {
    return handleFetchSuccess(store, context, options, document);
  }, error => {
    return handleFetchError(store, context, options, error);
  });
  if (!isMut) {
    return promise;
  }
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected a mutation`);
    }
  })(isMutation(context.request)) : {};

  // for mutations we need to enqueue the promise with the requestStateService
  // TODO should we enque a request per record in records?
  const record = context.request.data?.record || context.request.records?.[0];
  return store._requestCache._enqueue(promise, {
    data: [{
      op: 'saveRecord',
      recordIdentifier: record,
      options: undefined
    }]
  });
}

/**
  @module @ember-data/store
*/
/**
  A `ManyArray` is a `MutableArray` that represents the contents of a has-many
  relationship.

  The `ManyArray` is instantiated lazily the first time the relationship is
  requested.

  This class is not intended to be directly instantiated by consuming applications.

  ### Inverses

  Often, the relationships in Ember Data applications will have
  an inverse. For example, imagine the following models are
  defined:

  ```app/models/post.js
  import Model, { hasMany } from '@ember-data/model';

  export default class PostModel extends Model {
    @hasMany('comment') comments;
  }
  ```

  ```app/models/comment.js
  import Model, { belongsTo } from '@ember-data/model';

  export default class CommentModel extends Model {
    @belongsTo('post') post;
  }
  ```

  If you created a new instance of `Post` and added
  a `Comment` record to its `comments` has-many
  relationship, you would expect the comment's `post`
  property to be set to the post that contained
  the has-many.

  We call the record to which a relationship belongs-to the
  relationship's _owner_.

  @class ManyArray
  @public
*/
class RelatedCollection extends IdentifierArray {
  /**
    The loading state of this array
     @property {Boolean} isLoaded
    @public
    */

  /**
    `true` if the relationship is polymorphic, `false` otherwise.
     @property {Boolean} isPolymorphic
    @private
    */

  /**
    Metadata associated with the request for async hasMany relationships.
     Example
     Given that the server returns the following JSON payload when fetching a
    hasMany relationship:
     ```js
    {
      "comments": [{
        "id": 1,
        "comment": "This is the first comment",
      }, {
    // ...
      }],
       "meta": {
        "page": 1,
        "total": 5
      }
    }
    ```
     You can then access the meta data via the `meta` property:
     ```js
    let comments = await post.comments;
    let meta = comments.meta;
     // meta.page => 1
    // meta.total => 5
    ```
     @property {Object | null} meta
    @public
    */

  /**
     * Retrieve the links for this relationship
     *
     @property {Object | null} links
     @public
     */

  constructor(options) {
    super(options);
    this.isLoaded = options.isLoaded || false;
    this.isAsync = options.isAsync || false;
    this.isPolymorphic = options.isPolymorphic || false;
    this.identifier = options.identifier;
    this.key = options.key;
  }
  [MUTATE](target, receiver, prop, args, _SIGNAL) {
    switch (prop) {
      case 'length 0':
        {
          Reflect.set(target, 'length', 0);
          mutateReplaceRelatedRecords(this, [], _SIGNAL);
          return true;
        }
      case 'replace cell':
        {
          const [index, prior, value] = args;
          target[index] = value;
          mutateReplaceRelatedRecord(this, {
            value,
            prior,
            index
          }, _SIGNAL);
          return true;
        }
      case 'push':
        {
          const newValues = extractIdentifiersFromRecords(args);
          assertNoDuplicates(this, target, currentState => currentState.push(...newValues), `Cannot push duplicates to a hasMany's state.`);
          if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_MANY_ARRAY_DUPLICATES)) {
            // dedupe
            const seen = new Set(target);
            const unique = new Set();
            args.forEach(item => {
              const identifier = recordIdentifierFor(item);
              if (!seen.has(identifier)) {
                seen.add(identifier);
                unique.add(item);
              }
            });
            const newArgs = Array.from(unique);
            const result = Reflect.apply(target[prop], receiver, newArgs);
            if (newArgs.length) {
              mutateAddToRelatedRecords(this, {
                value: extractIdentifiersFromRecords(newArgs)
              }, _SIGNAL);
            }
            return result;
          }

          // else, no dedupe, error on duplicates
          const result = Reflect.apply(target[prop], receiver, args);
          if (newValues.length) {
            mutateAddToRelatedRecords(this, {
              value: newValues
            }, _SIGNAL);
          }
          return result;
        }
      case 'pop':
        {
          const result = Reflect.apply(target[prop], receiver, args);
          if (result) {
            mutateRemoveFromRelatedRecords(this, {
              value: recordIdentifierFor(result)
            }, _SIGNAL);
          }
          return result;
        }
      case 'unshift':
        {
          const newValues = extractIdentifiersFromRecords(args);
          assertNoDuplicates(this, target, currentState => currentState.unshift(...newValues), `Cannot unshift duplicates to a hasMany's state.`);
          if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_MANY_ARRAY_DUPLICATES)) {
            // dedupe
            const seen = new Set(target);
            const unique = new Set();
            args.forEach(item => {
              const identifier = recordIdentifierFor(item);
              if (!seen.has(identifier)) {
                seen.add(identifier);
                unique.add(item);
              }
            });
            const newArgs = Array.from(unique);
            const result = Reflect.apply(target[prop], receiver, newArgs);
            if (newArgs.length) {
              mutateAddToRelatedRecords(this, {
                value: extractIdentifiersFromRecords(newArgs),
                index: 0
              }, _SIGNAL);
            }
            return result;
          }

          // else, no dedupe, error on duplicates
          const result = Reflect.apply(target[prop], receiver, args);
          if (newValues.length) {
            mutateAddToRelatedRecords(this, {
              value: newValues,
              index: 0
            }, _SIGNAL);
          }
          return result;
        }
      case 'shift':
        {
          const result = Reflect.apply(target[prop], receiver, args);
          if (result) {
            mutateRemoveFromRelatedRecords(this, {
              value: recordIdentifierFor(result),
              index: 0
            }, _SIGNAL);
          }
          return result;
        }
      case 'sort':
        {
          const result = Reflect.apply(target[prop], receiver, args);
          mutateSortRelatedRecords(this, result.map(recordIdentifierFor), _SIGNAL);
          return result;
        }
      case 'splice':
        {
          const [start, deleteCount, ...adds] = args;

          // detect a full replace
          if (start === 0 && deleteCount === this[SOURCE].length) {
            const newValues = extractIdentifiersFromRecords(adds);
            assertNoDuplicates(this, target, currentState => currentState.splice(start, deleteCount, ...newValues), `Cannot replace a hasMany's state with a new state that contains duplicates.`);
            if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_MANY_ARRAY_DUPLICATES)) {
              // dedupe
              const current = new Set(adds);
              const unique = Array.from(current);
              const newArgs = [start, deleteCount].concat(unique);
              const result = Reflect.apply(target[prop], receiver, newArgs);
              mutateReplaceRelatedRecords(this, extractIdentifiersFromRecords(unique), _SIGNAL);
              return result;
            }

            // else, no dedupe, error on duplicates
            const result = Reflect.apply(target[prop], receiver, args);
            mutateReplaceRelatedRecords(this, newValues, _SIGNAL);
            return result;
          }
          const newValues = extractIdentifiersFromRecords(adds);
          assertNoDuplicates(this, target, currentState => currentState.splice(start, deleteCount, ...newValues), `Cannot splice a hasMany's state with a new state that contains duplicates.`);
          if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_MANY_ARRAY_DUPLICATES)) {
            // dedupe
            const currentState = target.slice();
            currentState.splice(start, deleteCount);
            const seen = new Set(currentState);
            const unique = [];
            adds.forEach(item => {
              const identifier = recordIdentifierFor(item);
              if (!seen.has(identifier)) {
                seen.add(identifier);
                unique.push(item);
              }
            });
            const newArgs = [start, deleteCount, ...unique];
            const result = Reflect.apply(target[prop], receiver, newArgs);
            if (deleteCount > 0) {
              mutateRemoveFromRelatedRecords(this, {
                value: result.map(recordIdentifierFor),
                index: start
              }, _SIGNAL);
            }
            if (unique.length > 0) {
              mutateAddToRelatedRecords(this, {
                value: extractIdentifiersFromRecords(unique),
                index: start
              }, _SIGNAL);
            }
            return result;
          }

          // else, no dedupe, error on duplicates
          const result = Reflect.apply(target[prop], receiver, args);
          if (deleteCount > 0) {
            mutateRemoveFromRelatedRecords(this, {
              value: result.map(recordIdentifierFor),
              index: start
            }, _SIGNAL);
          }
          if (newValues.length > 0) {
            mutateAddToRelatedRecords(this, {
              value: newValues,
              index: start
            }, _SIGNAL);
          }
          return result;
        }
      default:
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          {
            throw new Error(`unable to convert ${prop} into a transaction that updates the cache state for this record array`);
          }
        })() : {};
    }
  }
  notify() {
    const signal = this[ARRAY_SIGNAL];
    signal.shouldReset = true;
    notifyArray(this);
  }

  /**
    Reloads all of the records in the manyArray. If the manyArray
    holds a relationship that was originally fetched using a links url
    EmberData will revisit the original links url to repopulate the
    relationship.
     If the ManyArray holds the result of a `store.query()` reload will
    re-run the original query.
     Example
     ```javascript
    let user = store.peekRecord('user', '1')
    await login(user);
     let permissions = await user.permissions;
    await permissions.reload();
    ```
     @method reload
    @public
  */
  reload(options) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected the manager for ManyArray to implement reloadHasMany`);
      }
    })(typeof this._manager.reloadHasMany === 'function') : {};
    // TODO this is odd, we don't ask the store for anything else like this?
    return this._manager.reloadHasMany(this.key, options);
  }

  /**
    Saves all of the records in the `ManyArray`.
     Note: this API can only be used in legacy mode with a configured Adapter.
     Example
     ```javascript
    const { content: { data: inbox } } = await store.request(findRecord({ type: 'inbox', id: '1' }));
     let messages = await inbox.messages;
    messages.forEach((message) => {
      message.isRead = true;
    });
    messages.save();
    ```
     @method save
    @public
    @return {PromiseArray} promise
  */

  /**
    Create a child record within the owner
     @method createRecord
    @public
    @param {Object} hash
    @return {Model} record
  */
  createRecord(hash) {
    const {
      store
    } = this;
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected modelName to be set`);
      }
    })(this.modelName) : {};
    const record = store.createRecord(this.modelName, hash);
    this.push(record);
    return record;
  }
  destroy() {
    super.destroy(false);
  }
}
RelatedCollection.prototype.isAsync = false;
RelatedCollection.prototype.isPolymorphic = false;
RelatedCollection.prototype.identifier = null;
RelatedCollection.prototype.cache = null;
RelatedCollection.prototype._inverseIsAsync = false;
RelatedCollection.prototype.key = '';
RelatedCollection.prototype.DEPRECATED_CLASS_NAME = 'ManyArray';
function assertRecordPassedToHasMany(record) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`All elements of a hasMany relationship must be instances of Model, you passed ${typeof record}`);
    }
  })(function () {
    try {
      recordIdentifierFor(record);
      return true;
    } catch {
      return false;
    }
  }()) : {};
}
function extractIdentifiersFromRecords(records) {
  return records.map(extractIdentifierFromRecord);
}
function extractIdentifierFromRecord(recordOrPromiseRecord) {
  assertRecordPassedToHasMany(recordOrPromiseRecord);
  return recordIdentifierFor(recordOrPromiseRecord);
}
function assertNoDuplicates(collection, target, callback, reason) {
  const state = target.slice();
  callback(state);
  if (state.length !== new Set(state).size) {
    const duplicates = state.filter((currentValue, currentIndex) => state.indexOf(currentValue) !== currentIndex);
    if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_MANY_ARRAY_DUPLICATES)) {
      deprecate(`${reason} This behavior is deprecated. Found duplicates for the following records within the new state provided to \`<${collection.identifier.type}:${collection.identifier.id || collection.identifier.lid}>.${collection.key}\`\n\t- ${Array.from(new Set(duplicates)).map(r => isStableIdentifier(r) ? r.lid : recordIdentifierFor(r).lid).sort((a, b) => a.localeCompare(b)).join('\n\t- ')}`, false, {
        id: 'ember-data:deprecate-many-array-duplicates',
        for: 'ember-data',
        until: '6.0',
        since: {
          enabled: '5.3',
          available: '4.13'
        }
      });
    } else {
      throw new Error(`${reason} Found duplicates for the following records within the new state provided to \`<${collection.identifier.type}:${collection.identifier.id || collection.identifier.lid}>.${collection.key}\`\n\t- ${Array.from(new Set(duplicates)).map(r => isStableIdentifier(r) ? r.lid : recordIdentifierFor(r).lid).sort((a, b) => a.localeCompare(b)).join('\n\t- ')}`);
    }
  }
}
function mutateAddToRelatedRecords(collection, operationInfo, _SIGNAL) {
  mutate(collection, {
    op: 'addToRelatedRecords',
    record: collection.identifier,
    field: collection.key,
    ...operationInfo
  }, _SIGNAL);
}
function mutateRemoveFromRelatedRecords(collection, operationInfo, _SIGNAL) {
  mutate(collection, {
    op: 'removeFromRelatedRecords',
    record: collection.identifier,
    field: collection.key,
    ...operationInfo
  }, _SIGNAL);
}
function mutateReplaceRelatedRecord(collection, operationInfo, _SIGNAL) {
  mutate(collection, {
    op: 'replaceRelatedRecord',
    record: collection.identifier,
    field: collection.key,
    ...operationInfo
  }, _SIGNAL);
}
function mutateReplaceRelatedRecords(collection, value, _SIGNAL) {
  mutate(collection, {
    op: 'replaceRelatedRecords',
    record: collection.identifier,
    field: collection.key,
    value
  }, _SIGNAL);
}
function mutateSortRelatedRecords(collection, value, _SIGNAL) {
  mutate(collection, {
    op: 'sortRelatedRecords',
    record: collection.identifier,
    field: collection.key,
    value
  }, _SIGNAL);
}
function mutate(collection, mutation, _SIGNAL) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected the manager for ManyArray to implement mutate`);
    }
  })(typeof collection._manager.mutate === 'function') : {};
  collection._manager.mutate(mutation);
  addToTransaction(_SIGNAL);
}
export { ARRAY_SIGNAL as A, CacheHandler as C, IdentifierArray as I, MUTATE as M, RecordArrayManager as R, Store as S, _clearCaches as _, setIdentifierGenerationMethod as a, setIdentifierUpdateMethod as b, setIdentifierForgetMethod as c, setIdentifierResetMethod as d, setKeyInfoForResource as e, constructResource as f, coerceId as g, ensureStringId as h, isStableIdentifier as i, Collection as j, SOURCE as k, fastPush as l, removeRecordDataFor as m, notifyArray as n, setRecordIdentifier as o, peekCache as p, StoreMap as q, recordIdentifierFor as r, storeFor as s, setCacheFor as t, normalizeModelName as u, RelatedCollection as v, log as w, logGroup as x };