import ApplicationAdapter from "./application";

export default class NetworkAdapter extends ApplicationAdapter {
  urlForCreateRecord(_, snapshot) {
    return `${this.baseUri()}/network/create?context=${snapshot.belongsTo("project").id}`;
  }

  urlForDeleteRecord(id, _, snapshot) {
    return `${this.baseUri()}/network/delete?context=${snapshot.belongsTo("project").id}&networkid=${id}`;
  }
}
