import { template as template_bc4f42174a5849d2aaf08e55ddf8b2a2 } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import colors from "glesys-controlpanel/helpers/colors";
export default template_bc4f42174a5849d2aaf08e55ddf8b2a2(`
  <span
    class="text-2xs px-1 leading-none py-1 font-bold uppercase tracking-wide rounded {{colors 'red'}}"
    {{mergeClasses}}
    ...attributes
  >
    {{@text}}
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
