import { template as template_05f6d7c1ab8c435c834f1e840287bc35 } from "@ember/template-compiler";
import Box from "glesys-controlpanel/components/box";
import IconsCheckCircle from "glesys-controlpanel/components/icons/check-circle";
import IconsCrossCircle from "glesys-controlpanel/components/icons/cross-circle";
import GButtonEdit from "glesys-controlpanel/components/g-button/edit";
import GButtonCreate from "glesys-controlpanel/components/g-button/create";
import canManage from "glesys-controlpanel/helpers/can-manage";
import modal from "glesys-controlpanel/helpers/modal";
import { on } from "@ember/modifier";
import { t } from "ember-intl";
export default template_05f6d7c1ab8c435c834f1e840287bc35(`
  <Box @showFooter={{(canManage)}} data-test-server-scheduled-backups ...attributes>
    <:default>
      <p class="text-gray-dark text-sm">{{t "title.scheduled-backups"}}</p>

      <div class="text-2xl text-black mt-2 flex items-center gap-2" data-test-server-scheduled-backups-status>
        {{#if @server.backup.enabled}}
          {{t "label.enabled"}}
          <IconsCheckCircle class="size-6 text-ocean-green" />
        {{else}}
          {{t "label.disabled"}}
          <IconsCrossCircle class="size-6 text-red" />
        {{/if}}
      </div>
    </:default>
    <:footer>
      {{#if (canManage)}}
        {{#if @server.backup.enabled}}
          <GButtonEdit
            @type="button"
            @disabled={{@server.status.isLocked}}
            @display="compact"
            data-test-server-backup-configuration-button
            {{on "click" (modal "modals/kvm-edit-backup-configuration" server=@server project=@server.project)}}
          >
            {{t "server.details.backup.reconfigure"}}
          </GButtonEdit>
        {{else}}
          <GButtonCreate
            @type="button"
            @disabled={{@server.status.isLocked}}
            @display="compact"
            data-test-enable-backup
            {{on "click" (modal "modals/kvm-edit-backup-configuration" server=@server project=@server.project)}}
          >
            {{t "server.details.backup.enable"}}
          </GButtonCreate>
        {{/if}}
      {{/if}}
    </:footer>
  </Box>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
