/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { service } from "@ember/service";
import { dropTask, timeout } from "ember-concurrency";
import { tracked } from "@glimmer/tracking";
import { modifier } from "ember-modifier";

export default class SidebarOrganizations extends Component {
  @service keyboard;
  @service router;
  @service storage;

  @tracked showShortcutHints = false;
  @tracked _isOpen = null;

  constructor() {
    super(...arguments);
    // JSON.parse because localStorage.getItem returns string and we want a boolean
    this.isOpen = this.getOrganizationlistStatus == null ? true : JSON.parse(this.getOrganizationlistStatus);
  }

  get isOpen() {
    return this._isOpen;
  }

  set isOpen(value) {
    this._isOpen = value;
  }

  get organizations() {
    return this.args.organizations;
  }

  get getOrganizationlistStatus() {
    return this.storage.get("glesys:organization-list-is-visible");
  }

  shortcutFor(index) {
    return index + 1;
  }

  registerListener = modifier(() => {
    this.keyboard.listenFor({ ctrlKey: true }, () => {
      this.hintShortcuts.perform();
    });

    this.keyboard.listenFor({ ctrlKey: true, key: "h" }, () => {
      this.isOpen = !this.isOpen;
      this.storage.set("glesys:organization-list-is-visible", this.isOpen);
    });

    let listeners = [];

    this.organizations.forEach((organization, index) => {
      let key = `${index + 1}`.split("").join(" ");
      let listener = { ctrlKey: true, key };
      listeners.push(listener);
      this.keyboard.listenFor(listener, () => {
        this.router.transitionTo("organization", organization.id);
      });
    });

    return () => {
      this.keyboard.stopListenFor({ ctrlKey: true });
      this.keyboard.stopListenFor({ ctrlKey: true, key: "h" });

      listeners.forEach((listener) => this.keyboard.stopListenFor(listener));
    };
  });

  @action
  maybeHideShortcutHint(event) {
    if (event.key === "Control" || !document.hasFocus() || event.type === "contextmenu") {
      document.removeEventListener("keyup", this.maybeHideShortcutHint);
      window.removeEventListener("blur", this.maybeHideShortcutHint);
      document.removeEventListener("contextmenu", this.maybeHideShortcutHint);

      this.hintShortcuts.cancelAll();
      this.showShortcutHints = false;
    }
  }

  hintShortcuts = dropTask(async () => {
    document.addEventListener("keyup", this.maybeHideShortcutHint);
    window.addEventListener("blur", this.maybeHideShortcutHint);
    document.addEventListener("contextmenu", this.maybeHideShortcutHint);

    this.showShortcutHints = false;
    await timeout(500);
    this.showShortcutHints = true;
  });
}
