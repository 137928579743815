/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from "@glimmer/component";
import { tracked } from "@glimmer/tracking";
import { action } from "@ember/object";
import Bugsnag from "@bugsnag/js";
import { modifier } from "ember-modifier";

export default class NetworkStatusComponent extends Component {
  @tracked networkStatus = "online";

  registerEventListeners = modifier(() => {
    window.addEventListener("offline", this.toggleNetworkStatus);
    window.addEventListener("online", this.toggleNetworkStatus);

    return () => {
      window.removeEventListener("offline", this.toggleNetworkStatus);
      window.removeEventListener("online", this.toggleNetworkStatus);
    };
  });

  @action
  toggleNetworkStatus() {
    this.networkStatus = navigator.onLine ? "online" : "offline";

    Bugsnag.leaveBreadcrumb(`Network status changed: ${this.networkStatus}`);
  }
}
