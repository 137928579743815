import { template as template_be7ee2ecbae3442fb996d1518d5d6bad } from "@ember/template-compiler";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import colors from "glesys-controlpanel/helpers/colors";
export default template_be7ee2ecbae3442fb996d1518d5d6bad(`
  <span
    class="text-2xs px-1 leading-none py-1 font-bold uppercase tracking-wide rounded {{colors 'green'}}"
    {{mergeClasses}}
    ...attributes
  >
    {{@text}}
  </span>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
