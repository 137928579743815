import { service } from "@ember/service";
import Route from "@ember/routing/route";
import { action } from "@ember/object";
import RSVP from "rsvp";
import { updateUrlFor } from "glesys-controlpanel/utils/url";

export default class DashboardRoute extends Route {
  @service metrics;
  @service session;
  @service current;
  @service store;
  @service theme;
  @service router;
  @service errorHandler;

  activate() {
    this.theme.setup();

    let metrics = this.metrics;
    let currentUser = this.session.currentUser;

    if (currentUser.get("isIndividual")) {
      let companies = this.store.peekAll("organization").map((organization) => {
        return { id: organization.get("id"), name: organization.get("name") };
      });

      metrics.identify("Intercom", {
        companies,
        created_at: currentUser.get("createdAt"),
        email: currentUser.get("username"),
        name: currentUser.get("name"),
      });
    }
  }

  beforeModel(transition) {
    return this.session.requireAuthentication(transition);
  }

  model() {
    let organizations = this.store.peekAll("organization");
    let dataCenter = this.store.findAll("data-center");

    return RSVP.hash({
      dataCenter,
      organizations,
      user: this.session.currentUser,
      projects: RSVP.all(
        organizations.map((organization) => {
          return organization.get("projects").then((projects) => {
            return RSVP.all(
              projects.map((project) => {
                return project;
              }),
            );
          });
        }),
      ).then((map) => [].concat(...map)),
    });
  }

  @action
  error(error, transition) {
    this.errorHandler.handleError(error);
    updateUrlFor(transition, this.router);
    this.intermediateTransitionTo("dashboard.error", error);
    return false;
  }

  @action
  loading(transition) {
    // eslint-disable-next-line ember/no-controller-access-in-routes
    let controller = this.controllerFor("dashboard");
    controller.set("isLoading", true);
    transition.promise.finally(() => controller.set("isLoading", false));
  }
}
