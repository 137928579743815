/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import { tracked } from "@glimmer/tracking";
import { task } from "ember-concurrency";
import { service } from "@ember/service";
import Component from "@glimmer/component";

export default class extends Component {
  @service notification;
  @service http;
  @service intl;
  @service modal;

  @tracked newDescription = null;

  get project() {
    return this.args.params.project;
  }

  get description() {
    return this.args.params.instance.description;
  }

  get instanceId() {
    return this.args.params.instance.id;
  }

  get onChange() {
    return this.args.params.onChange;
  }

  onFormChange = ({ description }) => (this.newDescription = description);

  editInstance = task(async () => {
    await this.http
      .request({
        url: "/objectstorage/editinstance",
        method: "POST",
        body: {
          instanceid: this.instanceId,
          description: this.newDescription,
        },
      })
      .then(() => {
        this.args.params.refreshTask?.perform();
        this.onChange?.(this.newDescription);
        this.modal.close();
        this.notification.add(
          "success",
          this.intl.t("object-storage.notifications.instance-updated.title"),
          this.intl.t("object-storage.notifications.instance-updated.text"),
        );
      });
  });
}
