import { template as template_8784a4d9f85b476fb5e191cc352d6e40 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import colors from "glesys-controlpanel/helpers/colors";
export default class Pill extends Component {
    get text() {
        return this.args.text || null;
    }
    get color() {
        return this.args.color || "gray";
    }
    static{
        template_8784a4d9f85b476fb5e191cc352d6e40(`
    <div ...attributes>
      <span
        class="inline-flex items-center text-xs px-3 py-1 font-bold uppercase tracking-wide rounded-full
          {{colors this.color}}"
      >
        {{yield}}
        {{this.text}}
      </span>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
