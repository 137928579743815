import { template as template_6412a7bb01254f3cbaaabbf494be8e20 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import mergeClasses from "glesys-controlpanel/modifiers/merge-classes";
import IconsQuestionMark from "glesys-controlpanel/components/icons/question-mark";
import AdapterError from "@ember-data/adapter/error";
import { instanceOf } from "prop-types";
import { arg, forbidExtraArgs } from "ember-arg-types";
export default @forbidExtraArgs
class RouteErrorHttpError extends Component {
    @service
    intl;
    @arg(instanceOf(Error))
    error;
    get defaultMessage() {
        return this.intl.t("component.route-error.default");
    }
    get message() {
        return this.error instanceof AdapterError ? this.defaultMessage : this.error?.message || this.defaultMessage;
    }
    static{
        template_6412a7bb01254f3cbaaabbf494be8e20(`
    <div
      class="flex flex-col items-center justify-center h-full"
      data-test-route-error-http-error
      {{mergeClasses}}
      ...attributes
    >
      <IconsQuestionMark class="w-20 h-20 animate-bounce repeat-[2.5]" />
      <h1 class="text-xl text-gray-darker pt-4" data-test-route-error-http-error-message>
        {{this.message}}
      </h1>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
