import Route from "@ember/routing/route";
import { service } from "@ember/service";
import { task } from "ember-concurrency";
import products from "./products";
import Bugsnag from "@bugsnag/js";
import { NotFoundError } from "glesys-controlpanel/utils/errors";

export default class ServerCreateDedicatedRoute extends Route {
  @service http;
  @service notification;
  @service session;
  @service current;
  @service intl;
  @service abilities;

  model() {
    return {
      items: products.all().filter((product) => product.locations.some((location) => location.in_stock)),
      task: this.createOrderTask,
      countryFlagFor: (location) => {
        switch (location) {
          case "dc-fbg1":
          case "dc-sto1":
            return "🇸🇪";
          case "dc-oul1":
            return "🇫🇮";
          default:
            return "";
        }
      },
    };
  }

  beforeModel() {
    let { project } = this.modelFor("organization");
    if (this.abilities.cannot("order dedicated server for project", project)) {
      throw new NotFoundError();
    }
  }

  createOrderTask = task(async (product, location, operatingsystem, storage, mrc) => {
    try {
      await this.http.request({
        url: "io.php/server/dedicated",
        method: "POST",
        body: {
          project: this.current.project.id,
          user: this.session.currentUser.username,
          package: product.id,
          location,
          operatingsystem,
          storage,
          mrc,
        },
      });

      this.notification.add(
        "success",
        this.intl.t("servers.create.dedicated.notification.success.title"),
        this.intl.t("servers.create.dedicated.notification.success.content"),
      );

      return true;
    } catch (error) {
      Bugsnag.notify(error);
      this.notification.add(
        "error",
        this.intl.t("servers.create.dedicated.notification.error.title"),
        this.intl.t("servers.create.dedicated.notification.error.content"),
      );

      return false;
    }
  });
}
