import { template as template_03d183c3bddb4981872ad9cc280ae2bf } from "@ember/template-compiler";
export default template_03d183c3bddb4981872ad9cc280ae2bf(`
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    ...attributes
  >
    <path d="M8.249 7a4.25 4.25 0 1 1 5.678 5.789C12.943 13.29 12 14.145 12 15.25M12 19v.25"></path>
  </svg>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
