import { template as template_45c49b63db264600acc2fc59dc1350f9 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { action } from "@ember/object";
import { tracked } from "@glimmer/tracking";
import Label from "glesys-controlpanel/components/label";
import projectColorClass from "glesys-controlpanel/helpers/projectColorClass";
import { eq } from "ember-truth-helpers";
import { on } from "@ember/modifier";
import { fn } from "@ember/helper";
import { t } from "ember-intl";
export default class ColorPicker extends Component {
    colors = [
        "skyblue",
        "midnightblue",
        "lightcoral",
        "silver",
        "mediumaquamarine",
        "sandybrown",
        "lemonchiffon",
        "rebeccapurple"
    ];
    @tracked
    color = this.args.color || "silver";
    get onChange() {
        return this.args.onChange;
    }
    @action
    setColor(color) {
        this.color = color;
        this.onChange(color);
    }
    static{
        template_45c49b63db264600acc2fc59dc1350f9(`
    <div data-test-color-picker ...attributes>
      <Label class="whitespace-nowrap mb-5 -mt-2 pt-2">
        {{t "label.choose-project-color"}}
      </Label>

      <div class="flex flex-wrap">
        {{#each this.colors as |color|}}
          <div
            role="button"
            title={{color}}
            class="{{if
                (eq this.color color)
                'ring ring-powder-blue ring-offset-gray-lightest ring-offset-2'
                'border-0'
              }}
              size-8 rounded-full mr-4 cursor-pointer
              {{projectColorClass color}}"
            data-test-color-picker-color-selected={{eq this.color color}}
            data-test-color-picker-color={{color}}
            {{on "click" (fn this.setColor color)}}
          ></div>
        {{/each}}
      </div>

      {{#if (has-block)}}
        {{yield}}
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
